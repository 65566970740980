import { carrierChargeSerializeMap } from "lib/serializers/carrierCharges";
import { draftingAttributesMap } from "lib/serializers/draftingAttributes";
import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

import { addressSerializeMap } from "./address";

const consignmentSerializeMap = {
  NVD: "NVD",
  NVD_status: "NVD_status",
  additional_properties: "additional_properties",
  attachments: "attachments",
  brands: "brands",
  carrierCharge: carrierChargeSerializeMap,
  carrierChargeId: "carrier_charge_id",
  consignedFromId: "consigned_from_id",
  consignmentType: "consignment_type",
  created: "created",
  declaration: "declaration",
  deployment_sale: "deployment_sale",
  draftingAttributes: draftingAttributesMap,
  earliestPickupDate: "earliest_pickup_date",
  hasArrived: "has_arrived",
  id: "id",
  last_modified: "last_modified",
  latestPickupDate: "latest_pickup_date",
  livestock_agency_code: "livestock_agency_code",
  livestocksale_id: "livestocksale_id",
  nlisPrograms: "nlis_programs",
  nlisProgramsLastUpdated: "nlis_programs_last_updated",
  nlis_take_file_status: "nlis_take_file_status",
  nominationId: "nomination_id",
  notes: "notes",
  parentId: "parent_id",
  pickupAddress: addressSerializeMap,
  pricing_entries: "pricing_entries",
  quantity: "quantity",
  quantity_NVD: "quantity_NVD",
  quantity_dead: "quantity_dead",
  receivalLotIds: "receival_lot_ids",
  receiving_pen: "receiving_pen",
  receiving_reference: "receiving_reference",
  scanned: "scanned",
  useVendorAddressAsPickupAddress: "use_vendor_address_as_pickup_address",
  vendorCommission: "vendor_commission",
  vendorNote: "vendor_note",
  vendorNumber: "vendor_number",
  vendor_id: "vendor_id",
  vendor_property_id: "vendor_property_id",
};

const nestedObjectMap = {
  pickup_address: "pickupAddress",
  drafting_attributes: "draftingAttributes",
  carrier_charge: "carrierCharge",
};

export const serializeConsignment = buildSerializer(
  consignmentSerializeMap,
  "Consignment",
  nestedObjectMap,
  {},
);

export const deserializeConsignment = buildDeserializer(
  consignmentSerializeMap,
  "Consignment",
  nestedObjectMap,
);
