import { isHelpHeroActive } from "./config";
import { getHelpHero } from "./initialize";

export const identifyUserToHelpHero = (currentUser, activeRole) => {
  if (!isHelpHeroActive) {
    return;
  }

  if (activeRole.username) {
    getHelpHero().identify(activeRole.username, {
      name: activeRole.username,
      role: activeRole.type,
      email: currentUser.email,
    });
  }
};
