import queryString from "query-string";

import { EMAIL } from "constants/actionTypes";

import { serializeEmail } from "lib/serializers/email";
import { convertDateTimeToUTCDateTime } from "lib/timeFormats";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({
  action,
  billingRunId,
  changesSince,
  createdGte,
  createdLte,
  livestockSaleId,
  id,
  recipientEmail,
  reportJobIds,
  hasReportJob,
} = {}) => {
  return queryString.stringifyUrl(
    {
      url: urlCheckAndAddIdAndAction("/v2/emails/", id, action),
      query: {
        billing_document_email_jobs__billing_document__billing_run__cbid:
          billingRunId,
        changesSince,
        created__gte: createdGte
          ? convertDateTimeToUTCDateTime(createdGte)
          : "",
        created__lte: createdLte
          ? convertDateTimeToUTCDateTime(createdLte)
          : "",
        livestock_sale_id: livestockSaleId,
        recipient_email: recipientEmail,
        report_job_attachments__cbid__in: reportJobIds,
        report_job_attachments__isnull: hasReportJob ? false : undefined,
      },
    },
    { arrayFormat: "comma" },
  );
};
export const EmailAction = offlineActionCreator(
  EMAIL,
  serializeEmail,
  urlCreator,
  false,
);

// This method intended only to be used by the email changes since request saga.
// instead use the custom EmailAction.requestChanges
EmailAction.requestChangesInternal = EmailAction.requestChanges;

// Action used to intercept a changes since request for emails. This allows for the filters
// requested in the non-changes since request to also be applied to the request
EmailAction.requestChanges = options => {
  return {
    type: EMAIL.FETCH_CHANGES.ACTION,
    options,
  };
};
