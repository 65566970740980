import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { patchSaleLot, SaleLotAction } from "actions";

import { addSaleLot } from "actions/offline";

import { SplitLotModal } from "components/SplitLotModal";

import {
  KEYWORD_BUSINESS_NAME_NCV,
  KEYWORD_BUSINESS_NAME_TRANSIT,
  KEYWORD_CATEGORY_CRIPPLE,
  KEYWORD_CATEGORY_NCV,
  KEYWORD_CATEGORY_TRANSIT,
  SplitType,
} from "constants/saleLots";

import { calculateSplitPricingData, removeNulls } from "lib";

import { findBusinessWithName } from "lib/businesses";
import { doesSaleLotHaveWeight, getSplitComment } from "lib/saleLot";
import { attributeSpeciesFilter } from "lib/speciesAttributes";

import {
  selectPropertyEnrichedBusinessByBusinessIdLookup,
  getCategories,
  getSingleWeighWeightBySaleLotId,
} from "selectors";

const mapDispatchToProps = {
  addSaleLot,
  patchSaleLot,
  createSaleLotComment: SaleLotAction.comment,
};

// Helper function to find a category approximately matching a value
function findCategorySimilar(value) {
  return category => category.name.toLowerCase().indexOf(value) > -1;
}

// Returns the required split defaults for a cripple type split
function getCrippleValues(saleLot, businesses, speciesCategories) {
  const crippleCategory = speciesCategories.find(
    findCategorySimilar(KEYWORD_CATEGORY_CRIPPLE),
  );
  return {
    categoryId: crippleCategory && crippleCategory.id,
  };
}

// Returns the required split defaults for an ncv type split
function getNCVValues(saleLot, businessesList, speciesCategories) {
  const ncvBuyer = Object.values(businessesList).find(
    findBusinessWithName(KEYWORD_BUSINESS_NAME_NCV),
  );
  const ncvCategory = speciesCategories.find(
    findCategorySimilar(KEYWORD_CATEGORY_NCV),
  );
  return {
    buyerId: ncvBuyer && ncvBuyer.id,
    categoryId: ncvCategory && ncvCategory.id,
    totalPriceCents: 0,
  };
}

// Returns the required split defaults for a transit event split
function getTransitValues(saleLot, businessesList, speciesCategories) {
  const transitBuyer = Object.values(businessesList).find(
    findBusinessWithName(KEYWORD_BUSINESS_NAME_TRANSIT),
  );
  const transitCategory = speciesCategories.find(
    findCategorySimilar(KEYWORD_CATEGORY_TRANSIT),
  );
  return {
    buyerId: transitBuyer && transitBuyer.id,
    categoryId: transitCategory && transitCategory.id,
  };
}

function mapStateToProps(state, props) {
  const { saleLotId, splitType } = props;

  const { saleLots } = state.saleLots;
  const sales = state.sales.byId;

  const saleLot = saleLots[saleLotId];
  if (!saleLot) {
    return;
  }

  const shouldMoveWeights =
    getSingleWeighWeightBySaleLotId(saleLotId)(state) === 0;
  const sale = sales[saleLot.livestocksale_id];
  const speciesId = sale.species_id;
  const speciesCategories = Object.values(getCategories(state)).filter(
    attributeSpeciesFilter(speciesId),
  );

  const businesses = selectPropertyEnrichedBusinessByBusinessIdLookup(state);

  let splitDefaults = {};

  switch (splitType) {
    case SplitType.CRIPPLE:
      splitDefaults = getCrippleValues(saleLot, businesses, speciesCategories);
      break;
    case SplitType.NCV:
      splitDefaults = getNCVValues(saleLot, businesses, speciesCategories);
      break;
    case SplitType.TRANSIT:
      splitDefaults = getTransitValues(saleLot, businesses, speciesCategories);
      break;
    default:
      break;
  }

  const existingHead = saleLot.quantity;

  const buyerId = splitDefaults.buyerId || saleLot.buyer_id;
  const categoryId =
    splitDefaults.categoryId || saleLot.category_id || undefined;
  const totalPriceCents =
    typeof splitDefaults.totalPriceCents === "number"
      ? splitDefaults.totalPriceCents
      : null;

  const buyer = businesses[buyerId];

  return {
    saleLot,
    buyer,
    categoryId,
    existingHead,
    totalPriceCents,
    shouldMoveWeights,
    splitType,
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    saleLot,
    existingHead,
    buyer,
    categoryId,
    totalPriceCents,
    shouldMoveWeights,
    splitType,
  } = stateProps || {};

  const { patchSaleLot, addSaleLot, createSaleLotComment } = dispatchProps;

  const { fullScreen, returnTo } = ownProps;

  function splitSaleLot(splitCount, newLotData = {}) {
    // Now the total price and weight with the reduced head count
    const pricingData = calculateSplitPricingData(
      saleLot,
      splitCount,
      shouldMoveWeights,
    );
    const patch = pricingData.originalLot;

    const cloned = removeNulls(saleLot);
    const saleLotId = uuidv4();
    const destinationPropertyId = buyer?.defaultProperty?.id;
    const buyerId = buyer?.id;

    const splitSaleLot = {
      ...cloned,
      buyer_id: buyerId,
      category_id: categoryId,
      id: undefined,
      lot_number: undefined,
      destination_property_id: destinationPropertyId,
      ...pricingData.splitLot,
      // If we are doing a predefined NCV split or similar (Cripple),
      // use the pre-defined price.
      total_price_cents:
        typeof totalPriceCents === "number"
          ? totalPriceCents
          : pricingData.splitLot.total_price_cents,
      quantity_tags_used: 0,
      ...newLotData,
    };

    patchSaleLot({ id: saleLot.id, ...patch }, { changeReason: "Split" });
    addSaleLot(splitSaleLot, saleLotId);

    createSaleLotComment(saleLotId, {
      comment: getSplitComment(saleLot, splitCount, saleLot.auctionPen),
      resolvedDate: new Date(),
    });
  }

  return {
    returnTo,
    existingHead,
    splitSaleLot,
    fullScreen,
    splitType,
    shouldMoveWeights,
    doesLotHaveWeight: doesSaleLotHaveWeight(saleLot),
    saleLot,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SplitLotModal);
