import React from "react";

import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

import { ViewDocumentForm } from "components/Billing/LedgerEntry/DocumentForm";
import { LedgerEntryPricingForm } from "components/Billing/LedgerEntry/LedgerEntryPricingForm";
import { Button } from "components/Form";
import {
  Input,
  OptionTogglerField,
  SelectField,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";
import { Link } from "components/Link";

import { groupedLineItemToolTipText, TaxTypeOptions } from "constants/billing";
import { ModalTypes } from "constants/navigation";
import { RuleValueFormatOptionsByRawValueFormat } from "constants/ruleBooks";

import { getModalLink } from "lib/navigation";

import {
  getLedgerEntryById,
  selectBillingTagOptions,
  selectLedgerAccountOptionsForLedgerEntryForm,
} from "selectors";

import { useFieldSetter, useFieldState, useFieldValue } from "hooks";

const ShowOnInvoiceOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const ShowOnInvoiceAs = {
  GROUPED: "GROUPED",
  INDIVIDUAL: "INDIVIDUAL",
};

const ShowOnInvoiceAsOptions = [
  {
    label: "Individual Line Item",
    value: ShowOnInvoiceAs.INDIVIDUAL,
  },
  {
    label: "Grouped Line Item",
    value: ShowOnInvoiceAs.GROUPED,
  },
];

const RuleLink = ({ ruleId, ruleName }) => {
  if (ruleId) {
    return (
      <Link
        to={getModalLink(ModalTypes.EditRule, { ruleId }, window.location.hash)}
      >
        {ruleName}
      </Link>
    );
  } else {
    return ruleName;
  }
};

export function LedgerEntryForm(props) {
  const {
    ledgerEntryId,
    namespace: ns = "",
    // resetLedgerEntry,
    zeroLedgerEntry,
  } = props;
  const ledgerEntry = useSelector(getLedgerEntryById(ledgerEntryId));
  const isEdit = Boolean(ledgerEntry);
  const { documentId, notes } = ledgerEntry || {};

  const ledgerEntryNs = withNamespace(ns, "ledgerEntry");
  const pricingModelNs = withNamespace(ns, "pricingModel");

  const showOnInvoiceAs = useFieldValue(
    withNamespace(ledgerEntryNs, "showOnInvoiceAs"),
  );

  const quantityRawFormat = useFieldValue(
    withNamespace(ledgerEntryNs, "quantityRawFormat"),
  );
  const unitAmountRawFormat = useFieldValue(
    withNamespace(ledgerEntryNs, "unitAmountRawFormat"),
  );

  const [includeIfZero, setIncludeIfZero] = useFieldState(
    withNamespace(ledgerEntryNs, "includeIfZero"),
  );

  const billingTags = useSelector(selectBillingTagOptions);

  const ruleName = useFieldValue(withNamespace(ledgerEntryNs, "ruleName"));
  const ruleId = useFieldValue(withNamespace(ledgerEntryNs, "ruleId"));

  const description = useFieldValue(
    withNamespace(ledgerEntryNs, "description"),
  );

  const setInvoiceCategory = useFieldSetter(
    withNamespace(ledgerEntryNs, "invoiceCategory"),
  );

  const totalInc = useFieldValue(withNamespace(pricingModelNs, "totalInc"));

  function onAfterPricingModelChanged(pricingModel) {
    if (pricingModel.totalInc.toString() === "0") {
      setIncludeIfZero(false);
    } else if (!includeIfZero) {
      setIncludeIfZero(true);
    }
  }

  function onAfterLineItemGroupingChanged(lineItemGrouping) {
    if (lineItemGrouping === ShowOnInvoiceAs.INDIVIDUAL) {
      setInvoiceCategory(null);
    }
  }

  // function onClickReset() {
  //   typeof resetLedgerEntry === "function" && resetLedgerEntry(ledgerEntryId);
  // }

  function onClickZero() {
    typeof zeroLedgerEntry === "function" && zeroLedgerEntry(ledgerEntryId);
  }

  const LedgerAccountOptions = useSelector(
    selectLedgerAccountOptionsForLedgerEntryForm,
  );

  const quantityOutputFormatOptions =
    RuleValueFormatOptionsByRawValueFormat[quantityRawFormat] || [];
  const unitAmountOutputFormatOptions =
    RuleValueFormatOptionsByRawValueFormat[unitAmountRawFormat] || [];

  return (
    <Grid container spacing={2}>
      <ViewDocumentForm documentId={documentId} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item sm={7}>
        <SelectField
          label="Line Item Grouping"
          labelPosition="top"
          name={withNamespace(ledgerEntryNs, "showOnInvoiceAs")}
          options={ShowOnInvoiceAsOptions}
          onChangeExtra={onAfterLineItemGroupingChanged}
          required
        />
      </Grid>
      <Grid item xs={5}>
        <strong>Generated From:</strong>
        <div className="p-2">
          <RuleLink ruleName={ruleName} ruleId={ruleId} />
        </div>
      </Grid>
      {showOnInvoiceAs === ShowOnInvoiceAs.GROUPED && (
        <Grid item sm={12}>
          <Input
            helpText={description}
            label="Line Item Group Title"
            name={withNamespace(ledgerEntryNs, "invoiceCategory")}
            required
            tooltip={groupedLineItemToolTipText}
          />
        </Grid>
      )}
      {showOnInvoiceAs === ShowOnInvoiceAs.INDIVIDUAL && (
        <Grid item sm={12}>
          <Input
            label="Line Item Title"
            name={withNamespace(ledgerEntryNs, "description")}
            required
            tooltip="When shown as Individual Line Items, the value of this field will be used as the Line Item Title on the Invoice."
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {isEdit && notes && (
        <Grid item xs={12}>
          <strong>Last Change:</strong>
          <div className="p-2">
            <p style={{ whiteSpace: "pre-line" }}>{notes}</p>
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextArea
          label="Notes"
          name={withNamespace(ledgerEntryNs, "notes")}
          required
          tooltip="Provide a descriptive reason as to why this Ledger Entry is being manually adjusted"
        />
      </Grid>
      <LedgerEntryPricingForm
        namespace={withNamespace(ns, "pricingModel")}
        onAfterPricingModelChanged={onAfterPricingModelChanged}
      />
      {+totalInc === 0 && (
        <Grid item xs={6} justifyContent="flex-end">
          <OptionTogglerField
            label="Show on Invoice"
            labelPosition="top"
            name={withNamespace(ledgerEntryNs, "includeIfZero")}
            options={ShowOnInvoiceOptions}
            required
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <SelectField
          label="Units Format"
          name={withNamespace(ledgerEntryNs, "quantityOutputFormat")}
          required
          options={quantityOutputFormatOptions}
          menuPortalTarget={document.body}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectField
          label="Unit Price Format"
          name={withNamespace(ledgerEntryNs, "unitAmountOutputFormat")}
          required
          options={unitAmountOutputFormatOptions}
          menuPortalTarget={document.body}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item sm={6}>
        <SelectField
          label="GL Code"
          name={withNamespace(ledgerEntryNs, "glCode")}
          required
          labelPosition="top"
          options={LedgerAccountOptions}
          menuPortalTarget={document.body}
        />
      </Grid>
      <Grid item sm={6}>
        <SelectField
          label="Tax Type"
          name={withNamespace(ledgerEntryNs, "taxType")}
          required
          labelPosition="top"
          options={TaxTypeOptions}
          menuPortalTarget={document.body}
        />
      </Grid>
      <Grid item sm={12}>
        <SelectField
          label="Billing Tags"
          labelPosition="top"
          name={withNamespace(ledgerEntryNs, "tagIds")}
          options={billingTags}
          isMulti
          menuPortalTarget={document.body}
        />
      </Grid>
      {isEdit && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* TODO Implement Reset Ledger Entry when time permits, for now they can just delete it  */}
          {/* <Grid item xs={3}> */}
          {/*  <Button type="button" onClick={onClickReset}> */}
          {/*    Reset Ledger Entry */}
          {/*  </Button> */}
          {/* </Grid> */}
          {/* <Grid item xs={8}> */}
          {/*  Resetting will revert any changes made to this Ledger Entry since it */}
          {/*  was generated. All value will be updated if the Ledger Entry is */}
          {/*  re-generated. */}
          {/* </Grid> */}
          <Grid item xs={3}>
            <Button type="button" onClick={onClickZero}>
              Set to $0.00
            </Button>
          </Grid>
          <Grid item xs={8}>
            This will set the Units to 0, the Unit Price and Total Price to
            $0.00 and set to not appear as a Line Item on any Invoices
            generated. These values will not be updated if Ledger Entries are
            re-generated.
          </Grid>
        </>
      )}
    </Grid>
  );
}
