export const UNKNOWN_BUSINESS_NAME = "Unknown Business";

export const VENDOR = "VENDOR";
export const TRANSPORTER = "TRANSPORTER";
export const BUYER = "BUYER";
export const LIVESTOCK_AGENT = "LIVESTOCK_AGENT";
export const DEPLOYMENT = "DEPLOYMENT";

export const BUSINESS_ROLE_FILTERS = {
  [VENDOR]: "isVendor",
  [TRANSPORTER]: "isTransporter",
  [BUYER]: "isBuyer",
  [LIVESTOCK_AGENT]: "businessLivestockAgentId",
  [DEPLOYMENT]: "businessDeploymentId",
};

export const BUSINESS_ROLE_PLURAL = {
  [BUYER]: "Buyers",
  [DEPLOYMENT]: "Agencies",
  [LIVESTOCK_AGENT]: "Livestock Agents",
  [TRANSPORTER]: "Transporters",
  [VENDOR]: "Vendors",
};

export const businessRelations = {
  BUYERS_PURCHASE_REBATE_AGENT: "Buyer's Purchase Rebate Agent",
  CARTAGE_COLLECTION: "Cartage Collection",
  LISTING_AGENT: "Listing Agent",
  LIVESTOCK_CONSULTANT: "Livestock Consultant",
  OTHER: "Other",
  OUTSIDE_AGENT: "Outside Agent",
  REBATE_AGENT: "Rebate Agent",
  STOCK_AGENT: "Stock Agent",
};

export const businessRelationsDescriptions = {
  BUYERS_PURCHASE_REBATE_AGENT:
    "An outside agency/Agent that introduces buyers to a sale and is paid a rebate.",
  CARTAGE_COLLECTION:
    "A relationship between a Transport operator and the Livestock Agency where Cartage Collection is charged for account administration.",
  LIVESTOCK_CONSULTANT:
    "A person or company that provides livestock advice to a producer and is incentivised for their services on a per transaction basis.",
  LISTING_AGENT:
    "An employee that services clients of your company and receives no commission.",
  OTHER: "A business relationship between the contact and a 3rd party.",
  OUTSIDE_AGENT:
    "A fellow Stock Agent that consigns animals for our branch to sell on their behalf and collects a % of gross commission income (GCI).",
  REBATE_AGENT:
    "An external company that is paid a Rebate/Commission for transactions processed by your company on behalf of the contact.",
  STOCK_AGENT:
    "An employee or regular “book agent” that services your company's clients and receives a commission.",
};

export const BUSINESS_RELATION_OPTIONS = [
  {
    label: `${businessRelations.REBATE_AGENT} - ${businessRelationsDescriptions.REBATE_AGENT}`,
    value: businessRelations.REBATE_AGENT,
  },
  {
    label: `${businessRelations.BUYERS_PURCHASE_REBATE_AGENT} - ${businessRelationsDescriptions.BUYERS_PURCHASE_REBATE_AGENT}`,
    value: businessRelations.BUYERS_PURCHASE_REBATE_AGENT,
  },
  {
    label: `${businessRelations.LISTING_AGENT} - ${businessRelationsDescriptions.LISTING_AGENT}`,
    value: businessRelations.LISTING_AGENT,
  },
  {
    label: `${businessRelations.STOCK_AGENT} - ${businessRelationsDescriptions.STOCK_AGENT} `,
    value: businessRelations.STOCK_AGENT,
  },
  {
    label: `${businessRelations.CARTAGE_COLLECTION} - ${businessRelationsDescriptions.CARTAGE_COLLECTION} `,
    value: businessRelations.CARTAGE_COLLECTION,
  },
  {
    label: `${businessRelations.OUTSIDE_AGENT} - ${businessRelationsDescriptions.OUTSIDE_AGENT}`,
    value: businessRelations.OUTSIDE_AGENT,
  },
  {
    label: `${businessRelations.LIVESTOCK_CONSULTANT} - ${businessRelationsDescriptions.LIVESTOCK_CONSULTANT}`,
    value: businessRelations.LIVESTOCK_CONSULTANT,
  },
  {
    label: `${businessRelations.OTHER} - ${businessRelationsDescriptions.OTHER}`,
    value: businessRelations.OTHER,
  },
];

export const BUSINESS_RELATIONS_BUSINESS_ROLE = {
  [businessRelations.REBATE_AGENT]: LIVESTOCK_AGENT,
  [businessRelations.LISTING_AGENT]: LIVESTOCK_AGENT,
  [businessRelations.STOCK_AGENT]: LIVESTOCK_AGENT,
  [businessRelations.CARTAGE_COLLECTION]: TRANSPORTER,
  [businessRelations.OTHER]: LIVESTOCK_AGENT,
};

export const SuggestDuplicateBusinessFilter = {
  LIVESTOCK_SALE_ID: "LIVESTOCK_SALE_ID",
};

export const AlternativeType = {
  Deployment: "Deployment",
  Saleyard: "Saleyard",
};

export const AlternativeComparableKeys = [
  "address",
  "email",
  "first_name",
  "isActive",
  "isTopBuyerCattle",
  "isTopBuyerSheep",
  "is_commercial_report_recipient",
  "is_compliance_report_recipient",
  "is_envd_recipient",
  "last_name",
  "name",
  "phone_number",
  "publicDisplayName",
  "shortCode",
  "shortCodeAuctionsPlus",
  "shortCodeSaleyard",
  "slug",
];

export const AlternativeComparableFields = {
  shortCode: "Short Code",
  name: "Name",
  shortCodeSaleyard: "Short Code Saleyard",
  publicDisplayName: "Public Display Name",
  shortCodeAuctionsPlus: "Short Code Auctions Plus",
  address: "Address",
  isTopBuyerCattle: "Top Buyer Cattle?",
  isTopBuyerSheep: "Top Buyer Sheep?",
};
