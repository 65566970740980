import { RuleValueFormat } from "constants/ruleBooks";

export const ValueSource = {
  CONSTANT: "CONSTANT",
  FIELD: "FIELD",
  GLOBAL_BUSINESS: "GLOBAL_BUSINESS",
  SUM: "SUM",
  COUNT: "COUNT",
};

export const ValueSourceExt = {
  CONSTANT: "CONSTANT",
  FIELD: "FIELD",
  SUM: "SUM",
  COUNT: "COUNT",
  GLOBAL_BUSINESS: "GLOBAL_BUSINESS",
};

export const CUSTOM_QUERY_FIELD_ID = "{{__CUSTOM__}}";
export const ALL_VALUES_FILTER_PATH = "[*]";

export const ALL_VALUE_SOURCES = Object.values(ValueSource);

export const ValueSourceDisplayNameMap = {
  [ValueSource.FIELD]: "The field",
  [ValueSource.CONSTANT]: "The constant",
  [ValueSource.SUM]: "The sum of",
  [ValueSource.COUNT]: "The number of",
  [ValueSource.GLOBAL_BUSINESS]: "The global business",
};

export const CheckComparator = {
  EQUALS: "=",
  NOT_EQUAL: "!=",
  LESS_THAN: "<",
  GREATER_THAN: ">",
  LESS_THAN_OR_EQUAL: "<=",
  GREATER_THAN_OR_EQUAL: ">=",
  CONTAINS: "contains",
  NOT_CONTAINS: "!contains",
  STARTS_WITH: "startswith",
  ENDS_WITH: "endswith",
};

export const CheckComparatorDisplayNameMap = {
  [CheckComparator.EQUALS]: "equals",
  [CheckComparator.NOT_EQUAL]: "doesn't equal",
  [CheckComparator.LESS_THAN]: "is less than",
  [CheckComparator.GREATER_THAN]: "is greater than",
  [CheckComparator.LESS_THAN_OR_EQUAL]: "is less than or equal to",
  [CheckComparator.GREATER_THAN_OR_EQUAL]: "is greater than or equal to",
  [CheckComparator.CONTAINS]: "contains",
  [CheckComparator.NOT_CONTAINS]: "doesn't contain",
  [CheckComparator.STARTS_WITH]: "starts with",
  [CheckComparator.ENDS_WITH]: "ends with",
};

export const FieldType = {
  NUMERIC: "NUMERIC",
  BUSINESS: "BUSINESS",
  BOOLEAN: "BOOLEAN",
};

export const FieldValueType = {
  BOOLEAN: "boolean",
  BUSINESS: "business",
  BUSINESS_NAME: "business_name",
  GST_APPLICABILITY_BOOLEAN: "gst_applicability_boolean",
  CURRENCY_CENTS: "currency_cents",
  CURRENCY_DOLLARS: "currency_dollars",
  PERCENTAGE: "percentage",
  INTEGER: "integer",
  DECIMAL: "decimal",
  WEIGHT_GRAMS: "weight_grams",
  WEIGHT_KILOGRAMS: "weight_kilograms",
  TEXT: "text",
};

export const FieldValueTypesByFieldType = {
  [FieldType.NUMERIC]: [
    FieldValueType.CURRENCY_CENTS,
    FieldValueType.CURRENCY_DOLLARS,
    FieldValueType.PERCENTAGE,
    FieldValueType.INTEGER,
    FieldValueType.DECIMAL,
    FieldValueType.WEIGHT_GRAMS,
    FieldValueType.WEIGHT_KILOGRAMS,
  ],
  [FieldType.BUSINESS]: [FieldValueType.BUSINESS],
  [FieldType.BOOLEAN]: [
    FieldValueType.BOOLEAN,
    FieldValueType.GST_APPLICABILITY_BOOLEAN,
  ],
};

export const FieldValueTypeDisplayNameMap = {
  [FieldValueType.CURRENCY_CENTS]: "\xA2",
  [FieldValueType.CURRENCY_DOLLARS]: "$",
  [FieldValueType.PERCENTAGE]: "%",
  [FieldValueType.INTEGER]: "units - 0",
  [FieldValueType.DECIMAL]: "units - 0.00",
  [FieldValueType.WEIGHT_GRAMS]: "g",
  [FieldValueType.WEIGHT_KILOGRAMS]: "kg",
  // Not Shown to User
  // [FieldValueType.BOOLEAN]: "text",
  // [FieldValueType.BUSINESS]: "text",
  // [FieldValueType.BUSINESS_NAME]: "text",
  // [FieldValueType.GST_APPLICABILITY_BOOLEAN]: "GST Applicability",
  // [FieldValueType.TEXT]: "text",
};

export const FieldValueTypeRuleValueFormatMap = {
  [FieldValueType.CURRENCY_CENTS]: RuleValueFormat.CENTS,
  [FieldValueType.CURRENCY_DOLLARS]: RuleValueFormat.DOLLARS,
  [FieldValueType.PERCENTAGE]: RuleValueFormat.PERCENT,
  [FieldValueType.INTEGER]: RuleValueFormat.UNITS,
  [FieldValueType.DECIMAL]: RuleValueFormat.UNITS,
  [FieldValueType.WEIGHT_GRAMS]: RuleValueFormat.GRAMS,
  [FieldValueType.WEIGHT_KILOGRAMS]: RuleValueFormat.KILOGRAMS,
};

export const FeeOperator = {
  MULTIPLY: "*",
  DIVIDE: "/",
  ADD: "+",
  SUBTRACT: "-",
  ASSIGN: "=",
};

export const GST_FEE_NAME = "GST";
export const GST_FEE_EXCLUSIVE_MULTIPLIER = 0.1;
export const GST_FEE_INCLUSIVE_MULTIPLIER = 1 - 1 / 1.1;
export const GST_FEE_FIXED_FIELD = "gst_cents";

export const GstPricingType = {
  AUTO: "AUTO",
  EXCLUSIVE: "EXCLUSIVE",
  EXEMPT: "EXEMPT",
  INCLUSIVE: "INCLUSIVE",
  NOT_SET: "",
  FIELD_GST_CENTS: "FIELD_GST_CENTS",
};
