import { sortBy } from "lodash";
import sumBy from "lodash/sumBy";
import { createSelector } from "reselect";

import {
  ClearingSalePricingTypeDisplayNameLkp,
  LivestockSalePricingTypeDisplayNameLkp,
  pricingTypeString,
} from "constants/pricingTypes";
import {
  NonConsignableSaleyards,
  SaleRoundName,
  SaleTypes,
} from "constants/sale";

import { EMPTY_ARRAY, EMPTY_OBJECT } from "lib";

import {
  agrinousLogo,
  baseImageURL,
  isLocalEnvironment,
} from "lib/deployments";
import { hasWeight, isOpenAuctionLot } from "lib/saleLot";
import {
  getFirstAndLastScans,
  getNotRegisteredTags,
  getSaleyardTags,
  isScanWeighed,
} from "lib/scans";
import {
  dateTimeStringToDateTime,
  formatHeaderDateString,
  formatUTCToLocalDateString,
  getDecimalDifferenceFromDateTimeFrames,
  getHourDifferenceFromDateTimeFrames,
} from "lib/timeFormats";

import {
  createIdByKeySelector,
  createLookupCombiner,
  createLookupSelectors,
  getAges,
  getAuctionPens,
  selectAuctionPenIdBySaleLotIdLookup,
  getBreeds,
  getBusinesses,
  getConsignableSales,
  getConsignments,
  getCurrentDeploymentSalesList,
  getCurrentRoundsList,
  getCurrentSale,
  getRounds,
  getSaleLots,
  getSales,
  getScans,
  getSexes,
  getSpecies,
  reduceXByZId,
  selectCurrentDeploymentSales,
  selectDeploymentIdsByAgencyIdLookup,
  selectDeploymentsByAgencyIdLookup,
  getCurrentSaleyard,
  getDraftingInformation,
  selectDraftingInformationIdsBySelectableOnSaleWatcher,
  selectSaleLotIdsByLastSeenAtDraftIdLookup,
  selectIsSoldBySaleLotIdLookup,
} from "selectors";

import { selectShowCheckPoints } from "./auth";
import { getIsOnline } from "./root";

// TODO - This is the 'correct' fix for ASY-1051, including the full property
// info with the related businesses (vendor/buyer) but, may have knock on
// effects.
// import { getActiveBusinessesWithProperties } from "./businesses";

export const getSaleById = saleId => state => getSales(state)[saleId] || null;

export const selectDeploymentSaleIdByAgencyIdLookup = createSelector(
  [selectDeploymentsByAgencyIdLookup, getCurrentDeploymentSalesList],
  (deploymentsByAgencyIdLookup, deploymentSalesList) => {
    return Object.entries(deploymentsByAgencyIdLookup).reduce(
      (acc, [agencyId, deployments]) => {
        const agencyDeploymentIds = deployments.map(
          deployment => deployment.id,
        );

        const deploymentSale =
          deploymentSalesList?.find(deploymentSale =>
            agencyDeploymentIds.includes(deploymentSale.deployment_id),
          ) || {};

        acc[agencyId] = deploymentSale?.deployment_sale_id;
        return acc;
      },
      {},
    );
  },
);

export const getDeploymentSaleIdByAgencyId = agencyId => state =>
  selectDeploymentSaleIdByAgencyIdLookup(state)[agencyId] || null;

export const scansBySaleLotSelector = createSelector([getScans], scans => {
  const scansBySaleLot = {};
  for (const eid in scans) {
    const scan = scans[eid];
    if (!Array.isArray(scansBySaleLot[scan.sale_lot_id])) {
      scansBySaleLot[scan.sale_lot_id] = [];
    }
    scansBySaleLot[scan.sale_lot_id].push(scan);
  }
  return scansBySaleLot;
});

const getSaleLotsWithBusinesses = (state, saleLots) =>
  saleLots.map(lot => ({
    ...lot,
    buyer: getBusinesses(state)[lot.buyer_id] || {},
    vendor: getBusinesses(state)[lot.vendor_id] || {},
    saleyardId: getSales(state)[lot.livestocksale_id].saleyard_id,
    age: getAges(state)[lot.age_id] || {},
    breed: getBreeds(state)[lot.breed_id] || {},
    sex: getSexes(state)[lot.sex_id] || {},
  }));

// TODO - this function previously only included items within a given saleyard,
//  and this behaviour should be REinstated when the user roles stuff comes
//  through (and a user has a single saleyard at any time).
// WIth the updates we don't have a way to delineate the different locations,
// other than the extra information... but most users only have a single sale
// yard for now, anyway...

export const selectSaleOptions = createSelector(
  [getSales, getSpecies, selectShowCheckPoints],
  (sales, species, showCheckPoints) =>
    Object.values(sales)
      .sort((a, b) => (a.date < b.date ? 1 : -1))
      .map(sale => {
        // change date from yyyy-mm-dd to 5 Oct
        const date = dateTimeStringToDateTime(sale.date);
        const namedDate = formatHeaderDateString(date);
        const fullDate = formatUTCToLocalDateString(date);
        // Primary display of
        // Species - Date - Index
        // Secondary drop down info of
        // Type, Saleyard, Pricing
        // Later we could add vendor count, head count, etc, etc
        const metadata = [
          { title: "Type", value: sale.sale_type },
          { title: "Date", value: fullDate },
          sale.sale_type === SaleTypes.SALEYARD_AUCTION && {
            title: "Saleyard",
            value: sale.saleyard_name,
          },
          {
            title: "Pricing",
            value: pricingTypeString(
              sale.sale_type === SaleTypes.CLEARING
                ? ClearingSalePricingTypeDisplayNameLkp
                : LivestockSalePricingTypeDisplayNameLkp,
            )(sale.pricing_type_id),
          },
          {
            title: "Species",
            value: species[sale.species_id]?.name || "",
          },
          sale.sale_title && {
            title: "Sale Name",
            value: sale.sale_title || "",
          },
          sale.watch_link && {
            title: "Watcher",
            value: "AgriNous Sale Watcher",
            textToCopy: sale.watch_link,
          },
          showCheckPoints && {
            title: "Compliance Changes",
            value: sale.has_compliance_changes ? "Yes" : "No",
          },
          showCheckPoints && {
            title: "Commercial Changes",
            value: sale.has_commercial_changes ? "Yes" : "No",
          },
        ].filter(Boolean);

        // default name shows the sale type
        let nameWithoutDate = `${sale.sale_type}`;
        let dataTour = sale.sale_code;
        if (sale.sale_title) {
          // if the sale has a custom title, display the custom title
          dataTour = sale.sale_title;
          nameWithoutDate = `${sale.sale_title}`;
        } else if (sale.sale_type === SaleTypes.BOBBYCALF) {
          // Bobby Calf gets Saleyard Name
          nameWithoutDate = `${sale.saleyard_name}`;
        } else if (
          sale.sale_type === SaleTypes.SALEYARD_AUCTION ||
          sale.sale_type === SaleTypes.SALEYARD_AUCTION_SPECIAL
        ) {
          nameWithoutDate = `${sale.saleyard_name} ${sale.sale_type}`;
        }

        const name = `${namedDate} ${nameWithoutDate}`;

        return {
          id: sale.livestocksale_id,
          name,
          nameWithoutDate,
          preText: `${sale.sale_code}: `,
          metadata,
          date: new Date(sale.date),
          namedDate,
          dataTour,
          sale,
        };
      }),
);

export const selectSaleOptionsV2 = createSelector(
  [selectSaleOptions],
  saleOptions =>
    saleOptions.map(saleOption => ({
      value: parseInt(saleOption.id, 10),
      label: `${saleOption.preText}${saleOption.name}`,
    })),
);

const selectSaleOptionByIdLookup = createSelector([selectSaleOptions], sales =>
  sales.reduce((acc, sale) => {
    acc[sale.id] = sale;
    return acc;
  }, {}),
);

export const getSaleOptionById = saleId => state =>
  selectSaleOptionByIdLookup(state)[saleId] || null;

const saleTitleByIdCombiner = ({
  sale_code,
  sale_title,
  date,
  saleyard_name,
  sale_type,
}) =>
  `${sale_code}: ${formatHeaderDateString(
    dateTimeStringToDateTime(date),
  )} ${sale_title || `${saleyard_name} ${sale_type}`}`;

export const [selectSaleTitleByIdLookup, getSaleTitleById] =
  createLookupSelectors(
    [getSales, getSpecies],
    createLookupCombiner(saleTitleByIdCombiner),
  );

export const selectDeploymentSaleOptionsByLivestockSaleId = createSelector(
  [getSales],
  sales =>
    Object.values(sales).reduce((map, sale) => {
      map[sale.livestocksale_id] = sale.deployment_sales.map(ds => ({
        value: ds.deployment_sale_id,
        label: ds.livestock_agency_name,
      }));
      return map;
    }, {}),
);

export const selectSaleOptionsByFutureSales = createSelector(
  [selectSaleOptions],
  selectedSales => {
    const now = new Date();
    return selectedSales.filter(sale => {
      return sale.date >= now;
    });
  },
);

export const currentSaleSelector = createSelector(
  [getCurrentSale, getRounds],
  (currentSale, rounds) => {
    return {
      ...currentSale,
      rounds:
        currentSale?.rounds.map((roundId, order) => {
          const round = rounds[roundId] || null;
          return {
            ...round,
            order,
          };
        }) || EMPTY_ARRAY,
    };
  },
);

/**
 * Returns a Deployment Sale Id, keyed by Sale Lot Id
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): Object<string, number>}
 */
export const selectDeploymentSaleIdBySaleLotIdLookup = createSelector(
  [
    createIdByKeySelector(getConsignments, "id", "deployment_sale"),
    createIdByKeySelector(getSaleLots, "id", "consignment_id"),
  ],
  reduceXByZId,
);

export const getDeploymentSaleIdBySaleLotId = saleLotId => state =>
  selectDeploymentSaleIdBySaleLotIdLookup(state)[saleLotId];

/**
 * Returns a Deployment Sale, keyed by Sale Lot Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, DeploymentSale>}
 */
export const selectDeploymentSaleBySaleLotIdLookup = createSelector(
  [selectCurrentDeploymentSales, selectDeploymentSaleIdBySaleLotIdLookup],
  reduceXByZId,
);

export const selectCurrentSaleRounds = createSelector(
  [getCurrentRoundsList, getRounds],
  (saleRounds, rounds) =>
    saleRounds.reduce((acc, cur, order) => {
      const round = rounds[cur];
      if (round) {
        acc[round.id] = { ...round, order };
      }
      return acc;
    }, {}) || EMPTY_OBJECT,
);

export const getSaleLotsByAuctionPenId = (state, auctionPenId) =>
  Object.values(getSaleLots(state)).filter(
    lot => lot.auction_pen_id === auctionPenId,
  );

const getRoundId = (state, props) => props.roundId;

export const currentRoundSelector = createSelector(
  [getRounds, getRoundId],
  (rounds, roundId) => {
    return rounds[roundId];
  },
);

export const getAuctionPenWithSaleLots = (state, auctionPenId) => {
  const auctionPen = getAuctionPens(state)[auctionPenId] || {};
  const saleLots = getSaleLotsByAuctionPenId(state, auctionPenId);
  const auctionPenOverflow = saleLots.find(
    lot => lot.overflowPen && lot.overflowPen.length > 0,
  );

  return {
    ...auctionPen,
    sale_lots: getSaleLotsWithBusinesses(state, saleLots),
    overflowPen: auctionPenOverflow ? auctionPenOverflow.overflowPen : "",
    overflowQuantity: auctionPenOverflow
      ? auctionPenOverflow.overflowQuantity
      : null,
  };
};

const getSaleRoundIdByNameSelector = (state, saleRoundName) => saleRoundName;

export const getSaleRoundIdByName = createSelector(
  [getRounds, getSaleRoundIdByNameSelector],
  (rounds, saleRoundName) => {
    const round = Object.values(rounds).find(r => r.name === saleRoundName);
    return round ? round.id : -1;
  },
);

export const getBobbyCalfSaleRoundId = createSelector([getRounds], rounds => {
  const round = Object.values(rounds).find(
    r => r.name === SaleRoundName.BobbyCalf,
  );
  return round ? round.id : -1;
});

export const selectConsignableSaleyardSaleIds = createSelector(
  [getConsignableSales],
  sales =>
    sortBy(
      Object.values(sales).filter(
        sale => !NonConsignableSaleyards.includes(sale.saleyardName),
      ),
      ["date"],
    ).map(sale => sale.livestockSaleId),
);

export const getConsignableSaleById = livestockSaleId => state =>
  getConsignableSales(state)[livestockSaleId] || null;

export const selectConsignableDeploymentSaleByIdLookup = createSelector(
  [getConsignableSales],
  sales =>
    Object.values(sales).reduce((acc, sale) => {
      sale.deploymentSales.forEach(deploymentSale => {
        acc[deploymentSale.deployment_sale_id] = deploymentSale;
      });
      return acc;
    }, {}),
);

export const getConsignableDeploymentSaleById = deploymentSaleId => state =>
  selectConsignableDeploymentSaleByIdLookup(state)[deploymentSaleId] || null;

export const selectConsignableSaleByDeploymentSaleIdLookup = createSelector(
  [getConsignableSales],
  sales =>
    Object.values(sales).reduce((acc, sale) => {
      sale.deploymentSales.forEach(deploymentSale => {
        acc[deploymentSale.deployment_sale_id] = sale;
      });
      return acc;
    }, {}),
);

export const getConsignableSaleByDeploymentSaleId = deploymentSaleId => state =>
  selectConsignableSaleByDeploymentSaleIdLookup(state)[deploymentSaleId] ||
  null;

/**
 * Determines the Deployment Sale identifiable by the combination of Livestock Sale Id and Agency Id
 * @param {object} state
 * @param {number} livestockSaleId
 * @param {number} agencyId
 * @returns {number}
 */
export const selectDeploymentSaleIdByLivestockSaleIdAndAgencyId =
  createSelector(
    [
      (state, livestockSaleId) => getSaleById(livestockSaleId)(state),
      (state, _, agencyId) =>
        selectDeploymentIdsByAgencyIdLookup(state)[agencyId],
    ],
    (livestockSale, deploymentIds = []) => {
      if (livestockSale === null) {
        return null;
      }
      const deploymentSales = livestockSale.deployment_sales || [];

      const deploymentSale =
        deploymentSales.find(deploymentSale =>
          deploymentIds.includes(deploymentSale.deployment_id),
        ) || {};
      return deploymentSale.deployment_sale_id;
    },
  );

/**
 * @param {number} livestockSaleId
 * @param {number} agencyId
 * @returns {function(state: object): number}
 */
export const getDeploymentSaleIdByLivestockSaleIdAndAgencyId =
  (livestockSaleId, agencyId) => state =>
    selectDeploymentSaleIdByLivestockSaleIdAndAgencyId(
      state,
      livestockSaleId,
      agencyId,
    );

export const selectSingleWeighWeightBySaleLotIdLookup = createSelector(
  [scansBySaleLotSelector],
  scansBySaleLot =>
    Object.entries(scansBySaleLot).reduce((acc, [saleLotId, scans]) => {
      const singleWeighedScans = scans?.filter(isScanWeighed);
      const totalSingleWeighedWeight = sumBy(
        singleWeighedScans,
        "total_mass_grams",
      );
      acc[saleLotId] = totalSingleWeighedWeight;
      return acc;
    }, {}),
);

export const getSingleWeighWeightBySaleLotId = saleLotId => state =>
  selectSingleWeighWeightBySaleLotIdLookup(state)[saleLotId] || 0;

export const selectCurrentSaleStatisticData = createSelector(
  [
    getSaleLots,
    getScans,
    selectAuctionPenIdBySaleLotIdLookup,
    selectIsSoldBySaleLotIdLookup,
  ],
  (saleLots, scans, auctionPenIdBySaleLotIdLookup, isSoldBySaleLotId) => {
    const saleLotsList = Object.values(saleLots);
    const head = sumBy(saleLotsList, "quantity");
    const progeny = sumBy(saleLotsList, "quantityProgeny");
    const animalsScanned = Object.values(scans).length;
    const lotQuantityCount = saleLotsList.length;
    const averageLotSize =
      head && lotQuantityCount ? Math.round(head / lotQuantityCount) : 0;
    // we only care about bulk weighed lots
    const lotsWeighedCount = saleLotsList.filter(sl =>
      hasWeight(sl.total_mass_grams),
    ).length;
    const lotsUnweighedCount = saleLotsList.length - lotsWeighedCount;
    const lotsSold = Object.values(isSoldBySaleLotId).filter(
      isSold => isSold,
    ).length;
    const openAuctionLotsCount = saleLotsList.filter(saleLot =>
      isOpenAuctionLot(saleLot),
    ).length;
    const pensUsedCount = new Set(
      Object.values(auctionPenIdBySaleLotIdLookup).filter(Boolean),
    ).size;
    const averagePenDensity =
      head && pensUsedCount ? Math.round(head / pensUsedCount) : 0;

    return {
      head,
      progeny,
      animalsScanned,
      lotQuantity: lotQuantityCount,
      averageLotSize,
      lotsWeighed: lotsWeighedCount,
      lotsUnweighed: lotsUnweighedCount,
      lotsSold,
      openAuctionLots: openAuctionLotsCount,
      pensUsed: pensUsedCount,
      averagePenDensity,
    };
  },
);

export const selectCurrentScaleStatisticData = createSelector(
  [
    getSaleLots,
    selectDraftingInformationIdsBySelectableOnSaleWatcher,
    getDraftingInformation,
    selectSaleLotIdsByLastSeenAtDraftIdLookup,
  ],
  (
    saleLots,
    draftingInformationBySelectableOnSaleWatcher,
    draftingInformation,
    saleLotIdsByLastSeenAtDraft,
  ) => {
    const scaleIds = draftingInformationBySelectableOnSaleWatcher.true;
    return scaleIds.map(scaleId => {
      const scale = draftingInformation[scaleId];
      const scaleSaleLotIds = saleLotIdsByLastSeenAtDraft[scaleId];
      const scaleSaleLots =
        scaleSaleLotIds?.map(saleLotId => saleLots[saleLotId]) || [];
      const lotHeadQuantity = sumBy(scaleSaleLots, "quantity");
      const { firstScan, lastScan } = getFirstAndLastScans(
        scaleSaleLots,
        "lastSeenAtTime",
      );
      const weighingTime = getDecimalDifferenceFromDateTimeFrames(
        firstScan,
        lastScan,
      );
      const lotQuantityCount = scaleSaleLotIds?.length || 0;

      return {
        id: scale.id,
        lotQuantity: lotQuantityCount,
        weighingTime,
        lotsPerHour:
          scaleSaleLotIds?.length && weighingTime
            ? lotQuantityCount / weighingTime
            : 0,
        headPerHour:
          lotHeadQuantity && weighingTime ? lotHeadQuantity / weighingTime : 0,
        lotHeadQuantity,
      };
    });
  },
);

export const selectCurrentScannerStatisticData = createSelector(
  [
    getCurrentSaleyard,
    getSaleLots,
    getScans,
    selectDraftingInformationIdsBySelectableOnSaleWatcher,
    getDraftingInformation,
  ],
  (
    saleyard,
    saleLots,
    scanByIdLookup,
    draftingInformationIdsBySelectableOnSaleWatcher,
    draftingInformationByIdLookup,
  ) => {
    const draftingInformationId =
      draftingInformationIdsBySelectableOnSaleWatcher.false;
    const saleLotQuantity = sumBy(Object.values(saleLots), "quantity") || 0;
    const scans = Object.values(scanByIdLookup);

    return [...draftingInformationId, null]
      .map(scannerId => {
        const draftingInformation = draftingInformationByIdLookup[
          scannerId
        ] || {
          id: null,
        };
        const scannerScans = scans.filter(
          sl => sl.drafting_id === draftingInformation.id,
        );

        const { firstScan, lastScan } = getFirstAndLastScans(
          scannerScans,
          "last_modified",
        );
        const weighingTime = getHourDifferenceFromDateTimeFrames(
          firstScan,
          lastScan,
        );
        const syTagsUsed = getSaleyardTags(scannerScans, saleyard.saleyard_pic);
        const notRegistered = getNotRegisteredTags(scannerScans);

        const scanCount = scannerScans.length || 0;

        return {
          id: draftingInformation.id,
          eidsScanned: scannerScans.length,
          eidPercentage: saleLotQuantity ? scanCount / saleLotQuantity : 0,

          eidsPerHour: scanCount && weighingTime ? scanCount / weighingTime : 0,
          syTagsUsed: syTagsUsed.length,
          notRegistered: notRegistered.length,
        };
      })
      .filter(data => data.eidsScanned > 0);
  },
);

export const getSalePlacardLogo = createSelector(
  [getCurrentSale, getIsOnline],
  (sale, isOnline) => {
    let placardLogo = agrinousLogo;
    if (sale?.placard_logo_url && isOnline && !isLocalEnvironment) {
      placardLogo = `${baseImageURL}${sale.placard_logo_url}`;
    }
    return placardLogo;
  },
);
