import React, {
  memo,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from "react";

import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Grid, Switch } from "@material-ui/core";
import { uniq } from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  confirmStartDraft,
  resendScan,
  SaleLotAction,
  setSetting,
  startDraft,
  uploadScansAndDraftingInformation,
} from "actions";

import IconTextButton from "components/Button/IconTextButton";
import { SecondaryButton } from "components/Form";
import { Label } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import { SmHeading, StatusText } from "components/Text";

import { ScanStatus, UNALLOCATED } from "constants/scanner";
import { Settings } from "constants/settings";

import { formatPercentage } from "lib";

import { pluralize } from "lib/pluralize";
import { getScanStatusColour, mapUnassignedScanToSaleLotScan } from "lib/scans";

import {
  getConnectedDeviceId,
  getSaleLotById,
  getScannedPercentBySaleLotId,
  getScannedStatusBySaleLotId,
  getScansBySaleLotId,
  getSettings,
  getUnassignedScans,
  selectSaleLotIdByEidLookup,
} from "selectors";

import {
  WeighBridgeSaleDispatchContext,
  WeighBridgeSaleStateContext,
} from "./WeighBridgeSaleContext";
import WeighBridgeScanningColumn from "./WeighBridgeScanningColumn";

export function useWeighBridgeEidsUpdater() {
  const { updateRecentlyWeighedSaleLotIds } = useContext(
    WeighBridgeSaleDispatchContext,
  );
  const { eids } = useContext(WeighBridgeSaleStateContext);
  const connectedDeviceId = useSelector(getConnectedDeviceId);
  const unassignedScans = useSelector(getUnassignedScans);
  const dispatch = useDispatch();

  return useCallback(
    (saleLotId, updateRecent = true) => {
      const newScans = eids.map(eid =>
        mapUnassignedScanToSaleLotScan(unassignedScans[eid]),
      );

      // Assume same worked, clear the EIDs. Yikes!
      dispatch(uploadScansAndDraftingInformation(newScans, saleLotId));
      // Start draft will clear the local scans as well as send a message to the connected device
      dispatch(startDraft(connectedDeviceId));
      // Clear the devices internal "don't send this EID again" timer, if it's supported
      dispatch(resendScan(connectedDeviceId));

      updateRecent && updateRecentlyWeighedSaleLotIds(saleLotId);
    },
    [
      connectedDeviceId,
      dispatch,
      eids,
      unassignedScans,
      updateRecentlyWeighedSaleLotIds,
    ],
  );
}

export function WeighbridgeScanningComponent(props) {
  const { eids, isEidsDirty, isEidsValid, saleLotId } = props;

  const dispatch = useDispatch();

  const connectedDeviceId = useSelector(getConnectedDeviceId);
  const saleLotScans = useSelector(getScansBySaleLotId(saleLotId)) || [];
  const updateSaleLotEids = useWeighBridgeEidsUpdater();
  const unallocatedScans = useSelector(getUnassignedScans);
  const scannedPercent = useSelector(getScannedPercentBySaleLotId(saleLotId));
  const scannedStatus = useSelector(getScannedStatusBySaleLotId(saleLotId));
  const salelotQuantity = useSelector(getSaleLotById(saleLotId))?.quantity;

  const [previousUnallocatedCount, setPreviousUnallocatedCount] = useState(0);
  const isAutoSelectLotEnabled =
    useSelector(getSettings)[Settings.isAutoSelectLotEnabled] || false;

  function setIsAutoSelectLotEnabled() {
    dispatch(
      setSetting(Settings.isAutoSelectLotEnabled, !isAutoSelectLotEnabled),
    );
  }
  const { updateSelectedSaleLotId } = useContext(
    WeighBridgeSaleDispatchContext,
  );

  const saleLotIdByEidLookup = useSelector(selectSaleLotIdByEidLookup);

  const anyKnownEids = eids.length > 0;
  const unallocatedScanEids = Object.keys(unallocatedScans);
  const unallocatedScanCount = unallocatedScanEids.length;
  const unallocatedScanSaleLotIds = uniq(
    unallocatedScanEids.map(
      unallocatedEid => saleLotIdByEidLookup[unallocatedEid],
    ),
  );
  const unallocatedScanSaleLotId =
    unallocatedScanSaleLotIds.length === 1 &&
    unallocatedScanSaleLotIds[0] &&
    // If the SaleLot is "UNALLOCATED", flat the EID and not in a Sale Lot,
    // otherwise `"UNALLOCATED"` may be put in the Draft Sighting request URL as the Sale Lot Id
    unallocatedScanSaleLotIds[0] !== UNALLOCATED
      ? unallocatedScanSaleLotIds[0]
      : null;

  const firstEid = eids.length > 0 ? eids[0] : null;

  // When the first scan in the Unallocated Scans list changes (and the scan belongs to a Sale Lot),
  // update the Drafting Sighting info for the associated Sale Lot
  // This should only fire once per batch of Scans. i.e. A multi-weigh lot is expected to be updated as many times as there are weighing events for the lot.
  // This will also fire if the first EID does not have a known lot, however a subsequent Unallocated Scan does have one.
  useLayoutEffect(() => {
    if (firstEid && unallocatedScanSaleLotId) {
      // Thought - should we debounce here, or the action itself?  We're only firing on the first, so, probably
      // not necessary, but worth considering.
      dispatch(SaleLotAction.draftSighting(unallocatedScanSaleLotId, firstEid));
    }
  }, [dispatch, unallocatedScanSaleLotId, firstEid]);

  useLayoutEffect(() => {
    // Only consider auto selecting when scan counts change.
    if (previousUnallocatedCount !== unallocatedScanCount) {
      if (
        !anyKnownEids &&
        isAutoSelectLotEnabled &&
        unallocatedScanCount &&
        unallocatedScanSaleLotId &&
        unallocatedScanSaleLotId !== saleLotId
      ) {
        updateSelectedSaleLotId(unallocatedScanSaleLotId);
      }
      setPreviousUnallocatedCount(unallocatedScanCount);
    }
  }, [
    anyKnownEids,
    isAutoSelectLotEnabled,
    saleLotId,
    unallocatedScanSaleLotIds,
    unallocatedScanCount,
    updateSelectedSaleLotId,
    previousUnallocatedCount,
    setPreviousUnallocatedCount,
    unallocatedScanSaleLotId,
  ]);

  const saleLotEids = saleLotScans.map(scan => scan.EID);

  const newEids = eids.filter(eid => saleLotEids.indexOf(eid) === -1);
  const existingEids = eids.filter(eid => saleLotEids.indexOf(eid) > -1);

  function onClickClearEids() {
    dispatch(confirmStartDraft(connectedDeviceId));
  }

  function onClickSave() {
    updateSaleLotEids(saleLotId);
  }

  const isSaveDisabled = !isEidsDirty || !saleLotId || !isEidsValid;
  let saveTitle = `Save ${eids.length} EID${
    eids.length === 1 ? "" : "s"
  } (including ${newEids.length} new EID${
    newEids.length === 1 ? "" : "s"
  }) to the selected Sale Lot`;
  if (!saleLotId) {
    saveTitle = "You must select a Sale Lot before you can save EIDs";
  } else if (!isEidsDirty) {
    saveTitle =
      "You must capture EIDs before you can record it against a Sale Lot";
  } else if (!isEidsValid) {
    saveTitle = "You must Keep or Ignore any EIDs requiring action.";
  }

  const statusTextTitle = `Received ${existingEids.length} of ${
    saleLotScans.length
  } EIDS with ${newEids.length} new ${pluralize("entry", newEids.length)}`;

  const isError =
    (newEids.length > 0 && saleLotEids.length > 0) || !isEidsValid;
  const isSuccess =
    existingEids.length === saleLotEids.length &&
    newEids.length === 0 &&
    isEidsValid;

  return (
    <Grid container spacing={2} height="100%">
      <Grid item xs={12}>
        <h3>
          <Row whiteSpaceNoWrap>
            <div>
              {isError && <StatusText status="error" />}
              {isSuccess && <StatusText status="success" />}
              <SmHeading
                error={isError}
                success={isSuccess}
                bold
                title={statusTextTitle}
              >
                {existingEids.length}/{saleLotScans.length} (+{newEids.length})
              </SmHeading>
            </div>
            {saleLotId ? (
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Label>Live</Label>
                  <SmHeading
                    title="The live scan compliance rate of the sale lot."
                    data-tour="liveScanRate"
                    color={getScanStatusColour(
                      newEids.length === salelotQuantity
                        ? ScanStatus.PASS
                        : ScanStatus.ERROR,
                    )}
                  >
                    {formatPercentage(newEids.length / salelotQuantity)}
                  </SmHeading>
                </Grid>
                <Grid item>
                  <Label>Saved</Label>
                  <SmHeading
                    title="The saved scan compliance rate of the sale lot."
                    data-tour="savedScanRate"
                    color={getScanStatusColour(scannedStatus)}
                  >
                    {formatPercentage(scannedPercent)}
                  </SmHeading>
                </Grid>
              </Grid>
            ) : null}
          </Row>
        </h3>
      </Grid>
      <Grid item xs={6} container justifyContent="center">
        <Label>
          Auto Select Lot
          <Switch
            onChange={setIsAutoSelectLotEnabled}
            checked={isAutoSelectLotEnabled}
            color="primary"
          />
        </Label>
      </Grid>
      <Grid item xs={6} container alignContent="center" justifyContent="center">
        <Grid
          item
          sm={12}
          container
          alignContent="center"
          justifyContent="center"
        >
          <IconTextButton
            dataTour="clearEIDs"
            icon={faTrash}
            onClick={onClickClearEids}
          >
            Clear EIDs
          </IconTextButton>
        </Grid>
        <Grid
          item
          sm={12}
          container
          alignContent="center"
          justifyContent="center"
        >
          <SecondaryButton
            disabled={isSaveDisabled}
            title={saveTitle}
            data-tour="saveEIDs"
            onClick={onClickSave}
          >
            Save {eids.length > 0 ? eids.length : ""} EID
            {eids.length === 1 ? "" : "s"}
          </SecondaryButton>
        </Grid>
      </Grid>
      <Column fullWidth fullHeight>
        <WeighBridgeScanningColumn />
      </Column>
    </Grid>
  );
}
WeighbridgeScanningComponent.propTypes = {
  eids: PropTypes.arrayOf(PropTypes.string).isRequired,
  isEidsDirty: PropTypes.bool.isRequired,
  saleLotId: PropTypes.string,
};

const WeighbridgeScanningInner = memo(WeighbridgeScanningComponent);

function StateAdapter() {
  const { eids, isEidsDirty, isEidsValid, saleLot, selectedSaleLotId } =
    useContext(WeighBridgeSaleStateContext);

  return (
    <WeighbridgeScanningInner
      eids={eids}
      isEidsDirty={isEidsDirty}
      isEidsValid={isEidsValid}
      saleLotId={selectedSaleLotId}
      consignmentId={saleLot?.consignmentId}
    />
  );
}

export default StateAdapter;
