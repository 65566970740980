import React, { useMemo, useRef, useState } from "react";

import { faMobile } from "@fortawesome/free-solid-svg-icons";
import { faCodeMerge } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { openDispatchingModal, screenResize } from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { Button } from "components/Form";
import { Column as LayoutColumn } from "components/Layout";
import { OnlineOnly } from "components/OnlineOnly";

import { AgGridTables } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";
import { SaleyardPermissions } from "constants/permissions";

import {
  getLivestockSaleId,
  getPenScanLotCardsRoute,
  getSaleyardName,
} from "lib/navigation";

import { getCurrentSaleyard, selectEidsByPenScanLotIdLookup } from "selectors";

import { selectPenScanLotData } from "selectors/aggrid/penScanLots";

import { useDebounceSelector, useHasPermission, useTheme } from "hooks";

import { penScanLotColumns } from "./columnDefinitions";

const getRowId = params => params.data.penScanLot.id;

export const PenScanLotsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rowData = useDebounceSelector(selectPenScanLotData);
  const agGridApiRef = useRef();
  const [selectedPenScanLotIds, setSelectedPenScanLotIds] = useState([]);
  const theme = useTheme();

  const hasPenScanLotPermission = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.featurePenScanLots,
  );

  const eidsByPenScanLotIdLookup = useSelector(selectEidsByPenScanLotIdLookup);

  function onClickAllocateSaleLots() {
    dispatch(
      openDispatchingModal(
        ModalTypes.AllocatePenScanLots,
        window.location.hash,
        {
          mapping: selectedPenScanLotIds.reduce((acc, penScanLotId) => {
            acc[penScanLotId] = null;
            return acc;
          }, {}),
        },
      ),
    );
  }
  function onRowSelectionChanged(selectedRowNodes) {
    setSelectedPenScanLotIds(
      selectedRowNodes.map(rowNode => rowNode.penScanLot.id),
    );
  }
  const allocateSaleLots = (
    <LayoutColumn padding={1}>
      <OnlineOnly>
        <Button
          inline
          onClick={onClickAllocateSaleLots}
          disabled={selectedPenScanLotIds.length === 0}
        >
          <FontAwesomeIcon icon={faCodeMerge} /> Allocate Sale Lots
        </Button>
      </OnlineOnly>
    </LayoutColumn>
  );

  function onGridReady({ api }) {
    agGridApiRef.current = api;
  }

  const additionalActions = useMemo(
    () => [
      {
        title: "Mobile View",
        icon: faMobile,
        onClick: () => {
          dispatch(screenResize(theme.breakpoints[2] - 1));
          history.push(
            getPenScanLotCardsRoute(getSaleyardName(), getLivestockSaleId()),
          );
        },
        dataTour: "mobileView",
      },
    ],
    [dispatch, history, theme.breakpoints],
  );

  // Highlight rows that need attention.
  const rowClassRules = {
    "row-warning": params => {
      if (hasPenScanLotPermission) {
        const {
          penScanLot: { id: penScanLotId, quantity },
        } = params.data || {};

        const eidsByPenScanLot = eidsByPenScanLotIdLookup[penScanLotId] || [];

        const penScanLotEidsCount = eidsByPenScanLot.length || 0;

        // if the pen scan lot is scan count is not equal to the quantity
        // highlight the row

        return penScanLotEidsCount !== quantity;
      }
      return false;
    },
  };

  return (
    <AgGridTable
      additionalActions={additionalActions}
      columnDefs={penScanLotColumns}
      extraHeaderComponents={allocateSaleLots}
      getRowId={getRowId}
      onGridReady={onGridReady}
      onRowSelectionChanged={onRowSelectionChanged}
      rowData={rowData}
      rowSelection="multiple"
      rowSelectionId="penScanLot.id"
      tableName={AgGridTables.PEN_SCAN_LOT}
      rowClassRules={rowClassRules}
    />
  );
};
