import { get, isEmpty } from "lodash";

import { MAX, MIN } from "components/AgGrid/constants";

import { Column } from "constants/columns";
import { NLIS_NOT_FOUND } from "constants/nlis";
import {
  LivestockSalePricingTypeDisplayNameLkp,
  PricingTypes,
} from "constants/pricingTypes";
import {
  MasterRuleStatusTooltip,
  ruleInputLabelBySaleExportFieldMap,
} from "constants/rules";

import { getUnitPriceString, roundAgGridNumber } from "lib";

import { formatAddress } from "lib/address";
import { formatRelationship } from "lib/agGrid/renderers/businessRelationshipRenderer";
import { getLPAStatusFromPrograms } from "lib/properties";

const dateGetter = ({ column, data, node }) => {
  if (node.group) {
    return null;
  }
  const dateStr = get(data, column.colDef.field);
  return !isEmpty(dateStr) ? new Date(dateStr) : null;
};

// This must be used with dollars formatter,
// or it will display without a $ and numerous decimal places.
const dollarsGetter = params => {
  if (!params.data) {
    return null;
  }
  const value = get(params.data, params.colDef.field);
  return typeof value === "number" ? value / 100 : null;
};

const kilogramsGetter = params => {
  if (!params.data) {
    return null;
  }
  const value = get(params.data, params.colDef.field);
  return typeof value === "number" ? value / 1000 : 0;
};

const unitPriceGetter = params => {
  if (!params.data) {
    return null;
  }
  const saleLot = get(params.data, params.colDef.field);
  if (!saleLot) {
    return null;
  }
  return getUnitPriceString(saleLot);
};

const scanUnitPriceGetter = params => {
  if (!params.data) {
    return null;
  }

  const saleLot = get(params.data, params.colDef.field);

  if (!saleLot?.pricing_type_id) {
    return null;
  }

  if (saleLot.pricing_type_id === PricingTypes.GROSS) {
    // In the case of a scan, the gross price is the per head price.
    return getUnitPriceString({
      ...saleLot,
      pricing_type_id: PricingTypes.PER_HEAD,
    });
  }

  return getUnitPriceString(saleLot);
};

const nlisProgramsGetter = params => {
  if (!params.data) {
    return null;
  }
  const nlisPrograms = get(params.data, params.colDef.field) || [];
  return nlisPrograms
    .map(program => `${program.code} ${program.status}`)
    .join(", ");
};

const integerOrDefaultGetter = defaultValue => params => {
  if (!params.data) {
    return null;
  }
  const value = parseInt(get(params.data, params.colDef.field), 10);
  if (Number.isNaN(value)) {
    return defaultValue;
  } else {
    return value;
  }
};

export const floatGetter = params => {
  const { column, data, node, defaultValue } = params;
  if (node.group) {
    return null;
  }
  const { field, valueGetterParams } = column.colDef;
  const { multiplier = 1 } = valueGetterParams || {};
  const value = parseFloat(get(data, field));
  if (Number.isNaN(value)) {
    return defaultValue;
  } else {
    return value * multiplier;
  }
};

const attachmentsGetter = params => {
  if (!params.data) {
    return null;
  }
  const attachments = get(params.data, params.colDef.field) || [];
  return attachments.map(a => a.image_url).join(", ");
};

const marksGetter = ({ data, colDef }) => {
  if (!data) {
    return null;
  }
  const marks = get(data, colDef.field) || [];
  return marks.map(mark => mark.location).join(", ");
};

const xeroBusinessGetter = params => {
  if (!params.data) {
    return null;
  }
  const value = get(params.data, params.colDef.field) || null;
  return value?.name || "";
};

const lpaStatusGetter = ({ data, colDef }) => {
  if (!data) {
    return null;
  }

  const programs = get(data, colDef.field) || [];
  return getLPAStatusFromPrograms(programs);
};

const draftingDeviceGetter = ({ data, colDef }) => {
  if (!data) {
    return null;
  }

  const scanDraftingInformation = get(data, colDef.field);
  if (scanDraftingInformation) {
    return `${scanDraftingInformation.deviceId} - ${
      scanDraftingInformation.commonName || scanDraftingInformation.deviceName
    }`;
  }
};

const nlisIdGetter = ({ data, colDef }) => {
  if (!data) {
    return null;
  }

  const value = get(data, colDef.field);

  if (value === NLIS_NOT_FOUND) {
    return "EID NOT NLIS REGISTERED";
  }
  return value;
};

const erpGetter = ({ api, data, node, colDef }) => {
  if (!data) {
    return null;
  }
  const nlisId = api.getValue(Column.NLIS_ID, node);

  if (nlisId === "EID NOT NLIS REGISTERED") {
    return null;
  }
  return get(data, colDef.field);
};

const euGetter = ({ api, data, node, colDef }) => {
  if (!data) {
    return null;
  }
  const nlisId = api.getValue(Column.NLIS_ID, node);

  if (nlisId === "EID NOT NLIS REGISTERED") {
    return null;
  }
  return get(data, colDef.field);
};

const ltGetter = ({ api, data, node, colDef }) => {
  if (!data) {
    return null;
  }
  const nlisId = api.getValue(Column.NLIS_ID, node);

  if (nlisId === "EID NOT NLIS REGISTERED") {
    return null;
  }
  return get(data, colDef.field);
};

const abnCheckGetter = ({ node, api }) => {
  if (node.group) {
    return null;
  }
  const isHobbyFarmer = Boolean(
    api.getValue(Column.Consignment.IS_HOBBY_FARMER, node),
  );
  const hasAbn = Boolean(api.getValue(Column.Consignment.ABN, node));

  return (hasAbn && !isHobbyFarmer) || (!hasAbn && !!isHobbyFarmer);
};

const percentageGetter = ({ data, colDef }) => {
  const percentageDecimal = get(data, colDef.field);
  // If we want to include trailing zeros
  if (colDef.valueGetterParams?.includeDecimals) {
    return parseFloat((percentageDecimal * 100).toFixed(2)).toFixed(2);
  } else {
    return parseFloat((percentageDecimal * 100).toFixed(2));
  }
};

const emailRecipientGetter = ({ data, colDef }) => {
  const emailRecipients = get(data, colDef.field) || [];
  return emailRecipients.map(er => er.email);
};

const emailRecipientPhoneGetter = ({ data, colDef }) => {
  const emailRecipients = get(data, colDef.field) || [];
  return emailRecipients.map(er => er.phoneNumber).filter(Boolean);
};

const addressGetter = ({ data, colDef }) => {
  const address = get(data, colDef.field);
  if (address) {
    return formatAddress(address);
  }
};

const isDefinedGetter = ({ data, colDef }) => {
  const value = get(data, colDef.field);
  return !isEmpty(value);
};

const hasValueGetter = ({ data, colDef }) => {
  const value = get(data, colDef.field);
  return value !== undefined && value !== null;
};

const pricingTypeGetter = ({ data, colDef }) => {
  const pricingTypeId = get(data, colDef.field);
  if (pricingTypeId) {
    return LivestockSalePricingTypeDisplayNameLkp[pricingTypeId];
  }
};

const arrayLengthGetter = ({ data, colDef }) => {
  const value = get(data, colDef.field, []);
  return value.length;
};

// When grouping this allows you to use a different value for blank keys or add a prefix
const groupingKeyGetter = ({ data, colDef: { field }, column }) => {
  const valueParams = column.colDef.valueGetterParams;
  return (
    data &&
    `${valueParams?.preFixText ? valueParams.preFixText : ""} ${
      data[field] || valueParams?.blankText || "Unknown"
    }`
  );
};

const centsPerKgTotalsGetter = param => {
  if (!param.data) {
    return null;
  }
  const weightRange = get(param.data, param.colDef.field) || {};
  if (isEmpty(weightRange)) {
    return null;
  }

  const minKg = parseFloat(weightRange.weightRangeMinKg);
  const maxKg = parseFloat(
    weightRange.weightRangeMaxKg || weightRange.weightRangeMinKg,
  );

  const { quantity, total_price_cents: totalPriceCents } = param.data;
  const valueParams = param.column.colDef.valueGetterParams;

  if (!quantity) {
    return null;
  }

  // The min cents come from the min value in the weight range
  // It is the min possible price per cents if they all weighed at the min range value
  const minRangeCents =
    minKg !== 0 ? totalPriceCents / (quantity * minKg) : null;

  // The max cents comes from the max value in the weight range
  // It is the max possible price per cents if they all weighed at the max range value
  const maxRangeCents =
    maxKg !== 0 ? totalPriceCents / (quantity * maxKg) : null;

  // This isn't really an average. It is the approx price if the weights are all the average value of the weight range.
  const averageCentKg = minKg
    ? totalPriceCents / (quantity * ((minKg + maxKg) / 2))
    : null;

  let value = null;
  if (valueParams === MIN) {
    value = roundAgGridNumber(minRangeCents);
  } else if (valueParams === MAX) {
    value = roundAgGridNumber(maxRangeCents);
  } else if (param.node.group) {
    // If its a grouped row, return the object
    value = weightRange;
  } else {
    value = roundAgGridNumber(averageCentKg);
  }
  return value;
};

// Given an array of objects, return the value of the coldef field in each object.
export const keyInObjArrayGetter = ({ colDef, data }) => {
  if (!data) {
    return null;
  }
  const objArray = colDef.namespace ? get(data, colDef.namespace) : data;
  return objArray.map(obj => get(obj, colDef.field));
};

const businessRelationshipGetter = ({ colDef, data }) => {
  const value = get(data, colDef.field) || [];
  if (Array.isArray(value) && value.length > 0) {
    return value.map(formatRelationship);
  }
  return null;
};

const businessesNamesGetter = ({ colDef, data, context }) => {
  const businessIds = get(data, colDef.field) || [];
  return (
    businessIds.map(
      businessId => context.businessByIdLookup[businessId]?.name,
    ) || null
  );
};

const livestockSaleCodeGetter = ({ colDef, data, context }) => {
  const livestockSaleId = get(data, colDef.field);

  return context.saleByIdLookup[livestockSaleId]?.sale_code;
};

const billingTagGetter = ({ colDef, data, context }) => {
  const tagIds = get(data, colDef.field) || [];
  return tagIds.map(tagId => context.billingTags[tagId]?.name) || null;
};

export function isAgriNousManagedGetter({ data, colDef }) {
  return Boolean(get(data, colDef.field) || null);
}

const ruleInputField = ({ colDef, data, node }) => {
  if (node.group) {
    return null;
  }
  const value = get(data, colDef.field);
  return ruleInputLabelBySaleExportFieldMap?.[value] || value || "";
};

const ruleSyncTooltipGetter = ({ colDef, data, node }) => {
  if (node.group) {
    return null;
  }
  const value = get(data, colDef.field);
  return MasterRuleStatusTooltip[value]?.tooltip || "";
};

export default {
  abnCheckGetter,
  addressGetter,
  arrayLengthGetter,
  attachmentsGetter,
  billingTagGetter,
  businessRelationshipGetter,
  businessesNamesGetter,
  centsPerKgTotalsGetter,
  dateGetter,
  dollarsGetter,
  draftingDeviceGetter,
  emailRecipientGetter,
  emailRecipientPhoneGetter,
  erpGetter,
  euGetter,
  groupingKeyGetter,
  hasValueGetter,
  integerOrDefaultGetter,
  isAgriNousManagedGetter,
  isDefinedGetter,
  keyInObjArrayGetter,
  kilogramsGetter,
  lpaStatusGetter,
  livestockSaleCodeGetter,
  ltGetter,
  marksGetter,
  nlisIdGetter,
  nlisProgramsGetter,
  percentageGetter,
  pricingTypeGetter,
  ruleInputField,
  ruleSyncTooltipGetter,
  scanUnitPriceGetter,
  unitPriceGetter,
  xeroBusinessGetter,
};
