import React from "react";

import Grid from "@material-ui/core/Grid";
import { Form, Formik, getIn, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addBidderRegistration, bulkUpdateSaleLotsSerializer } from "actions";

import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import { SlimButton, SlimSecondaryButton } from "components/Button";
import { Button } from "components/Form";
import { BidderNumberInput } from "components/Form/BidderNumberInput";
import { BuyerAwareBuyerWayField } from "components/Form/Fields";
import {
  FormikAwareBusinessPICSelector,
  withNamespace,
} from "components/Form/FormikControls";
import { CompactLotCard } from "components/LotCard/CompactLotCard";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { BUYER } from "constants/businesses";

import { getBuyerInformationString } from "lib/businesses";
import { closeAllHashModals, returnToLocation } from "lib/navigation";

import {
  getBuyerWaysByBusinessId,
  getHasWriteAccessInCurrentSale,
  getSaleLotsBySale,
  selectBidderByBuyerInformation,
} from "selectors";

const businessRoles = [BUYER];

function UpdateBuyerForm(props) {
  const { namespace: ns, buyerId } = props;

  const formikProps = useFormikContext();
  const { setFieldValue, values } = formikProps;

  function clearBuyerWay(businessId) {
    if (businessId !== getIn(values, withNamespace(ns, "buyerId"))) {
      setFieldValue(withNamespace(ns, "buyerWay"), null);
    }
  }

  const onClickSetBuyerAsThirdParty = () => {
    // Note, this sets to the INITIAL buyer, so if the user is opening this form up
    // and starts updating, this can be quickly done without having to remember/search.
    setFieldValue(withNamespace(ns, "thirdPartyId"), buyerId);
  };

  const onClickClearThirdParty = () => {
    setFieldValue(withNamespace(ns, "thirdPartyId"), null);
  };

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  return (
    <>
      <Grid xs={12} item>
        <StateAwareBusinessPICSelector
          label="Buyer"
          businessFieldName={withNamespace(ns, "buyerId")}
          propertyFieldName={withNamespace(ns, "destinationPropertyId")}
          formikProps={formikProps}
          businessRoles={businessRoles}
          onAfterSelect={clearBuyerWay}
          disabled={!hasWriteAccessInCurrentSale}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <BuyerAwareBuyerWayField
          name="buyerWay"
          label="Buyer Way"
          buyerField="buyerId"
          readOnly={!hasWriteAccessInCurrentSale}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <StateAwareBusinessPICSelector
          label="Third Party"
          businessFieldName={withNamespace(ns, "thirdPartyId")}
          tooltip="Commission Buyer"
          formikProps={formikProps}
          businessRoles={businessRoles}
          optional
          disabled={!hasWriteAccessInCurrentSale}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SlimSecondaryButton
              fullWidth
              disabled={!hasWriteAccessInCurrentSale}
              onClick={onClickClearThirdParty}
              type="button"
            >
              Remove 3rd Party Buyer
            </SlimSecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <SlimButton
              disabled={!hasWriteAccessInCurrentSale}
              fullWidth
              onClick={onClickSetBuyerAsThirdParty}
              type="button"
            >
              Set as original buyer
            </SlimButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <BidderNumberInput disabled={!hasWriteAccessInCurrentSale} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormikAwareBusinessPICSelector
          label="Invoice To"
          businessFieldName={withNamespace(ns, "invoiceToBusinessId")}
          tooltip="Invoice To"
          businessRoles={businessRoles}
          optional
          clearable
        />
      </Grid>
    </>
  );
}

UpdateBuyerForm.propTypes = {
  buyerId: PropTypes.string.isRequired,
};

function UpdateBuyerActions(props) {
  const { onClickClose } = props;
  const { dirty, isValid } = useFormikContext();

  const isSubmitDisabled = !(dirty && isValid);

  return (
    <DialogActions>
      <Button onClick={onClickClose}>Back</Button>
      <Button type="submit" disabled={isSubmitDisabled}>
        Submit
      </Button>
    </DialogActions>
  );
}

UpdateBuyerActions.propTypes = {
  onClickClose: PropTypes.func.isRequired,
};

export const UpdateBuyerModal = ({
  buyerId,
  destinationPropertyId,
  buyerWayId,
  thirdPartyId,
  invoiceToBusinessId,
  returnTo,
}) => {
  const dispatch = useDispatch();

  const closeSelf = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  const buyerWays = useSelector(getBuyerWaysByBusinessId(buyerId)) || [];

  const boughtLots = useSelector(state => {
    return getSaleLotsBySale(state).filter(
      sl =>
        sl.buyer_id === buyerId &&
        ((sl.buyer_way === null && !buyerWayId) ||
          sl.buyer_way?.id === buyerWayId) &&
        ((!sl.thirdPartyId && !thirdPartyId) ||
          sl.thirdPartyId === thirdPartyId),
    );
  });

  const bidderByBuyerInformationLookup = useSelector(
    selectBidderByBuyerInformation,
  );

  const buyerWay = buyerWays.find(bw => bw.id === buyerWayId);

  const buyerInformationString = getBuyerInformationString(
    buyerId,
    destinationPropertyId,
    buyerWay?.name,
  );

  const bidder = bidderByBuyerInformationLookup[buyerInformationString];

  const initialValues = {
    bidderNumber: bidder ? bidder.registrationNumber : null,
    buyerId,
    destinationPropertyId,
    buyerWay: buyerWays.find(bw => bw.id === buyerWayId),
    thirdPartyId,
    invoiceToBusinessId,
  };

  const handleSubmit = values => {
    let currentBuyerWay = null;
    if (
      values.buyerWay &&
      values.buyerWay.id &&
      values.buyerWay.name &&
      values.buyerWay.id !== -1
    ) {
      currentBuyerWay = values.buyerWay;
    }

    const bulkPayload = boughtLots.map(sl => ({
      buyer_id: values.buyerId,
      destination_property_id: values.destinationPropertyId,
      buyer_way: currentBuyerWay,
      thirdPartyId: values.thirdPartyId,
      invoiceToBusinessId: values.invoiceToBusinessId,
      id: sl.id,
    }));

    dispatch(
      bulkUpdateSaleLotsSerializer(bulkPayload, {
        changeReason: "Bulk updated buyer",
      }),
    );

    if (values.bidderNumber !== initialValues.bidderNumber) {
      const buyerInformationString = getBuyerInformationString(
        values.buyerId,
        values.destinationPropertyId,
        values.buyerWay?.name,
      );

      const associatedBidder =
        bidderByBuyerInformationLookup[buyerInformationString];

      if (!associatedBidder) {
        // if a bidder does not exist for the current buyer/way/pic combination, create one
        dispatch(
          addBidderRegistration(uuidv4(), {
            registrationNumber: values.bidderNumber,
            businessId: values.buyerId,
            defaultPropertyId: values.destinationPropertyId,
            buyerWay: values?.buyerWay?.name,
          }),
        );
      }
    }
    closeSelf();
  };

  return (
    <ZoomyDialog open onClose={closeSelf} maxWidth="sm" fullWidth>
      <DialogTitle onClose={closeSelf}>
        Bulk Update Buyer for {boughtLots.length} Sale Lot
        {boughtLots.length !== 1 && "s"}
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Form>
          <DialogContent dividers shrink={0}>
            <Grid container spacing={1}>
              <UpdateBuyerForm buyerId={buyerId} />
            </Grid>
          </DialogContent>
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {boughtLots.map(saleLot => (
                  <CompactLotCard {...saleLot} key={saleLot.id} />
                ))}
              </Grid>
            </Grid>
          </DialogContent>

          <UpdateBuyerActions onClickClose={closeSelf} />
        </Form>
      </Formik>
    </ZoomyDialog>
  );
};
