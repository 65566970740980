import React, { useRef } from "react";

import {
  faCheckCircle,
  faEnvelopeOpen,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import IconTextButton from "components/Button/IconTextButton";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { Button, SecondaryButton } from "components/Form";
import {
  Input,
  LabelText,
  SelectField,
  useSubmitHandler,
} from "components/Form/FormikControls";
import { HelpText } from "components/Form/FormikControls/HelpText";
import { ExclamationIcon } from "components/Icons";
import { Row } from "components/Layout";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import { DescriptiveOption } from "components/SearchableSelector";
import { userTypeConfigMap } from "components/UserRoles/constants";
import { getValidationSchema } from "components/UserRoles/Modal/validationSchema";

import { getUserTypeDisplayName } from "constants/users";

import { closeAllHashModals, returnToLocation } from "lib/navigation";
import { hasPermission } from "lib/permissions";
import {
  dateTimeStringToDateTime,
  formatUTCToLocalDateTimeString,
} from "lib/timeFormats";

import {
  getActiveRole,
  getUserLevels,
  selectConsignableSaleyardsAsOptions,
  selectNameByDeploymentIdLookup,
} from "selectors";

import { useTheme, useToggle } from "hooks";

function ReceiveConsignedToEmailsFromSelector() {
  const consignableSaleyardsAsOptions = useSelector(
    selectConsignableSaleyardsAsOptions,
  );

  return (
    <SelectField
      label="Receive Consigned To Emails From"
      isMulti
      options={consignableSaleyardsAsOptions}
      name="receiveConsignedToEmailsFrom"
      menuPortalTarget={document.body}
      tooltip="This agent will receive emails regarding stock consigned to these locations."
    />
  );
}

function SaleyardSelector() {
  // TODO - we need a better source of available saleyards, but this is pretty close for now
  const currentRole = useSelector(getActiveRole);
  const saleyardOptions = currentRole.saleyards.map(saleyard => ({
    label: saleyard.name,
    value: saleyard.saleyard_id,
  }));

  return (
    <SelectField
      label="Saleyards"
      isMulti
      options={saleyardOptions}
      name="saleyardIds"
      menuPortalTarget={document.body}
    />
  );
}

function DeploymentSelector() {
  // TODO - we need a better source of available deployments, but this is pretty close for now
  // Especially given an agent shouldn't have multiple deployments....
  // in fact I'm pretty sure this will end up being a single value drop down in practice (and once that
  // database correlation is fixed, in theory too)
  const currentRole = useSelector(getActiveRole);
  const deploymentNameByDeploymentIdLookup = useSelector(
    selectNameByDeploymentIdLookup,
  );
  const deploymentOptions = currentRole.deployments.map(deployment => ({
    label: deploymentNameByDeploymentIdLookup[deployment.id],
    value: deployment.id,
  }));

  return (
    <SelectField
      label="Deployments"
      options={deploymentOptions}
      name="deploymentId"
      menuPortalTarget={document.body}
    />
  );
}

function UserLevelSelector() {
  const userLevels = useSelector(getUserLevels);

  const userLevelOptions = Object.values(userLevels).map(userLevel => ({
    value: userLevel.id,
    label: userLevel.name,
    description: userLevel.description,
  }));
  return (
    <SelectField
      label="User Level"
      options={userLevelOptions}
      name="userLevelId"
      menuPortalTarget={document.body}
      components={{ Option: DescriptiveOption }}
      tooltip="Select the role this user has within your organization."
    />
  );
}

function EditUserRoleForm({ userRoleType, userRoleId, setIsSubmitEnabled }) {
  useSubmitHandler(false, setIsSubmitEnabled);

  const inputsDisabled = userRoleId !== null;
  return (
    <Grid container spacing={2}>
      {userRoleId ? (
        <Grid item xs={12}>
          <HelpText>
            Once a user has been created please contact AgriNous to update their
            user details.
          </HelpText>
        </Grid>
      ) : null}
      <Grid item xs={12} sm={6}>
        <Input name="firstName" label="First Name" disabled={inputsDisabled} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input name="lastName" label="Last Name" disabled={inputsDisabled} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input name="email" label="Email" disabled={inputsDisabled} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          name="phoneNumber"
          label="Phone Number"
          disabled={inputsDisabled}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {userTypeConfigMap[userRoleType].showSaleyards ? (
        <Grid item xs={12}>
          <SaleyardSelector />
        </Grid>
      ) : null}
      {userTypeConfigMap[userRoleType].showDeployments ? (
        <>
          <Grid item xs={12}>
            <DeploymentSelector />
          </Grid>
          <Grid item xs={12}>
            <ReceiveConsignedToEmailsFromSelector />
          </Grid>
        </>
      ) : null}
      <Grid item xs={12}>
        <UserLevelSelector />
      </Grid>
    </Grid>
  );
}

function DeleteSection({ userRoleType, userRoleId, onAfterDelete, slug }) {
  const [showDeleteDialog, toggleDeleteDialog] = useToggle(false);

  const { slug: currentRoleSlug } = useSelector(getActiveRole);

  const dispatch = useDispatch();
  function onDelete() {
    dispatch(userTypeConfigMap[userRoleType].action.delete(userRoleId));
    toggleDeleteDialog();
    onAfterDelete();
  }

  return (
    <DialogContent dividers>
      <IconTextButton
        icon={faTrash}
        dataTour="deleteThisUserRole"
        onClick={toggleDeleteDialog}
        disabled={slug === currentRoleSlug}
      >
        Delete This User Role{" "}
        {slug === currentRoleSlug && " (You cannot delete your current role.)"}
      </IconTextButton>

      <ConfirmDialog
        title={createModalTitle(`this ${getUserTypeDisplayName(userRoleType)}`)}
        isOpen={showDeleteDialog}
        onCancel={toggleDeleteDialog}
        onDelete={onDelete}
      />
    </DialogContent>
  );
}

function UserActiveInfo({ userRoleType, userRoleId }) {
  const theme = useTheme();

  const existingRole =
    useSelector(
      state =>
        userTypeConfigMap[userRoleType].getDataSelector(state)[userRoleId],
    ) || {};
  if (existingRole.isActive) {
    if (existingRole.roleInvitationAcceptedDate) {
      return (
        <>
          <FontAwesomeIcon icon={faCheckCircle} color={theme.colors.success} />
          &nbsp; User Active
        </>
      );
    } else {
      return (
        <>
          <ExclamationIcon color="warning" />
          &nbsp; Role Invitation Pending
        </>
      );
    }
  } else {
    return (
      <>
        <ExclamationIcon color="warning" />
        &nbsp; User Inactive
      </>
    );
  }
}

function UserStatusSection({ userRoleType, userRoleId, onAfterReinvite }) {
  const [showResendDialog, toggleResendDialog] = useToggle(false);

  const dispatch = useDispatch();
  const existingRole =
    useSelector(
      state =>
        userTypeConfigMap[userRoleType].getDataSelector(state)[userRoleId],
    ) || {};
  const createPermissionsObject = useSelector(state =>
    userTypeConfigMap[userRoleType].createPermissionObjectSelector(state),
  );

  const allowReinvite =
    !existingRole.roleInvitationAcceptedDate &&
    hasPermission(
      createPermissionsObject,
      userTypeConfigMap[userRoleType].createPermission,
    );

  function onClickReinvite() {
    dispatch(userTypeConfigMap[userRoleType].action.reinvite(userRoleId));
    typeof onAfterReinvite === "function" && onAfterReinvite();
  }

  const theme = useTheme();
  return (
    <DialogContent>
      <Row justifyBetween fullWidth>
        <LabelText>User Status</LabelText>
        <Row alignCenter>
          <UserActiveInfo userRoleId={userRoleId} userRoleType={userRoleType} />
        </Row>
      </Row>

      <p>
        <FontAwesomeIcon icon={faCheckCircle} color={theme.colors.success} />
        &nbsp;User was added to AgriNous at:&nbsp;
        {formatUTCToLocalDateTimeString(
          dateTimeStringToDateTime(existingRole.dateJoined),
        )}
      </p>
      {existingRole.roleInvitationSentDate ? (
        <p>
          <FontAwesomeIcon icon={faCheckCircle} color={theme.colors.success} />
          &nbsp; User was invited to join your organisation at:&nbsp;
          {formatUTCToLocalDateTimeString(
            dateTimeStringToDateTime(existingRole.roleInvitationSentDate),
          )}
        </p>
      ) : (
        <p>
          <ExclamationIcon color="warning" />
          &nbsp;Unknown invitation status of user
        </p>
      )}

      {existingRole.roleInvitationAcceptedDate ? (
        <p>
          <FontAwesomeIcon icon={faCheckCircle} color={theme.colors.success} />
          &nbsp; User accepted invitation to join your organisation at:&nbsp;
          {formatUTCToLocalDateTimeString(
            dateTimeStringToDateTime(existingRole.roleInvitationAcceptedDate),
          )}
        </p>
      ) : (
        <p>
          <ExclamationIcon color="warning" />
          &nbsp;User has not yet accepted invitation.
        </p>
      )}
      {allowReinvite ? (
        <>
          <IconTextButton icon={faEnvelopeOpen} onClick={toggleResendDialog}>
            Resend invitation
          </IconTextButton>
          <ConfirmDialog
            title="Resend invitation to this user."
            message={`This will resend an invitation to this role to "${existingRole.email}"`}
            buttonMessage="Resend"
            isOpen={showResendDialog}
            onCancel={toggleResendDialog}
            onDelete={onClickReinvite}
          />
        </>
      ) : null}
    </DialogContent>
  );
}

export const EditUserRoleModal = ({ userRoleType, userRoleId, returnTo }) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  const existingRole =
    useSelector(
      state =>
        userTypeConfigMap[userRoleType].getDataSelector(state)[userRoleId],
    ) || {};
  const showSubmitButton = userRoleId
    ? hasPermission(
        existingRole,
        userTypeConfigMap[userRoleType].updatePermission,
      )
    : true;

  const closeSelf = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  function handleSubmit(values) {
    if (userRoleId === null) {
      dispatch(
        userTypeConfigMap[userRoleType].action.create({
          ...values,
          id: uuidv4(), // Bit of a misnomer - it's an integer - we just want something unique.
        }),
      );
    } else {
      dispatch(
        userTypeConfigMap[userRoleType].action.update({
          ...values,
          id: userRoleId,
        }),
      );
    }
    closeSelf();
  }

  function onClickSubmit() {
    formRef.current.submitForm();
  }

  const initialValues = {};
  if (userRoleId) {
    initialValues.firstName = existingRole.firstName;
    initialValues.lastName = existingRole.lastName;
    initialValues.phoneNumber = existingRole.phoneNumber;
    initialValues.email = existingRole.email;
    initialValues.userLevelId = existingRole.userLevelId;

    if (userTypeConfigMap[userRoleType].showSaleyards) {
      initialValues.saleyardIds = existingRole.saleyards.map(
        sy => sy.saleyard_id,
      );
    }
    if (userTypeConfigMap[userRoleType].showDeployments) {
      // Map their list into a single value.
      initialValues.deploymentId = existingRole.deployments?.[0].id;
      initialValues.receiveConsignedToEmailsFrom =
        existingRole.receiveConsignedToEmailsFrom;
    }
  } else if (userTypeConfigMap[userRoleType].showSaleyards) {
    initialValues.saleyardIds = [];
  } else if (userTypeConfigMap[userRoleType].showDeployments) {
    initialValues.receiveConsignedToEmailsFrom = [];
  }

  const validationSchema = getValidationSchema(userRoleType, userRoleId);

  return (
    <ZoomyDialog open onClose={closeSelf}>
      <DialogTitle onClose={closeSelf}>
        {userRoleId === null ? "Create" : "Edit"}{" "}
        {getUserTypeDisplayName(userRoleType)}
      </DialogTitle>
      <DialogContent dividers>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          innerRef={formRef}
          initialValues={initialValues}
        >
          <EditUserRoleForm
            userRoleType={userRoleType}
            userRoleId={userRoleId}
            setIsSubmitEnabled={setIsSubmitEnabled}
          />
        </Formik>
      </DialogContent>
      {userRoleId ? (
        <UserStatusSection
          userRoleType={userRoleType}
          userRoleId={userRoleId}
          onAfterReinvite={closeSelf}
        />
      ) : null}

      {userRoleId &&
      hasPermission(
        existingRole,
        userTypeConfigMap[userRoleType].deletePermission,
      ) ? (
        <DeleteSection
          userRoleType={userRoleType}
          userRoleId={userRoleId}
          onAfterDelete={closeSelf}
          slug={existingRole.slug}
        />
      ) : null}

      <DialogActions>
        <SecondaryButton data-tour="back" onClick={closeSelf}>
          Back
        </SecondaryButton>
        {showSubmitButton ? (
          <Button
            data-tour={userRoleId === null ? "create" : "save"}
            onClick={onClickSubmit}
            disabled={!isSubmitEnabled}
          >
            {userRoleId === null ? "Create" : "Save"}
          </Button>
        ) : null}
      </DialogActions>
    </ZoomyDialog>
  );
};
