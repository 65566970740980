import { useEffect, useRef } from "react";

import Pusher from "pusher-js";
import { useDispatch, useSelector } from "react-redux";

import { queueSocketMessage, receiveSocketId } from "actions";

import { forceHeartbeatOnNextTick } from "lib/heartBeat";
import { channelEvents } from "lib/socket";

import { getIsAuthenticated, selectChannels } from "selectors";

const env = process.env.REACT_APP_ENVIRONMENT;
const pusherLocalActive = !["0", "false"].includes(
  (process.env.REACT_APP_PUSHER_LOCAL_ACTIVE || "").toLowerCase(),
);
const pusherKey =
  process.env.REACT_APP_PUSHER_KEY || process.env.REACT_APP_PUSHER_KEY_LOCAL;

const cluster = process.env.REACT_APP_PUSHER_CLUSTER;

if (env === "local" || env === "development") {
  Pusher.logToConsole = true;
}

function useSocketListener() {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const desiredChannels = useSelector(selectChannels);

  const pusherRef = useRef(null);

  const dispatch = useDispatch();
  /**
   * To listen for new events, put the event name and action dispatcher in
   * the `channelEvents` array below
   */
  useEffect(() => {
    if (env === "local" && !pusherLocalActive) {
      return;
    }

    if (isAuthenticated) {
      let pusher = pusherRef.current;
      if (!pusher) {
        pusher = new Pusher(pusherKey, {
          cluster,
          forceTLS: true,
        });

        pusher.connection.bind("connected", () => {
          dispatch(receiveSocketId(pusher.connection.socket_id));

          // Resync each time connection established to catchup on
          // lost messages
          forceHeartbeatOnNextTick();
        });

        pusher.connection.bind("error", args => {
          const { error, data, type } = args || {};
          const { code } = error || data || { code: "Unknown Code" };
          const pusherErrorType =
            (error ? error.type : type) || "PusherBindError";

          // eslint-disable-next-line no-console
          console.error("%s: %s.", pusherErrorType, code, { args });
        });
        pusherRef.current = pusher;
      }

      // Subscribe to channels I'm not on, but should be, and unsubscribe from channels I am, but shouldn't be.
      const subscribedChannels = Object.keys(pusher.channels.channels);
      const subscribeTo = desiredChannels.filter(
        channel => !subscribedChannels.includes(channel),
      );
      const unsubscribeFrom = subscribedChannels.filter(
        channel => !desiredChannels.includes(channel),
      );

      subscribeTo.forEach(channelName => {
        const channel = pusher.subscribe(channelName);

        Object.keys(channelEvents).forEach(eventName => {
          channel.bind(eventName, data => {
            dispatch(queueSocketMessage(eventName, data));
          });
        });
      });

      unsubscribeFrom.forEach(channelName => {
        pusher.unsubscribe(channelName);
      });
    } else if (pusherRef.current) {
      // disconnect on logout
      pusherRef.current.disconnect && pusherRef.current.disconnect();
      pusherRef.current = null;
    }
  }, [pusherRef, desiredChannels, isAuthenticated, dispatch]);
}
export default useSocketListener;
