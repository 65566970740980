import { max, sortBy, sum, uniq, uniqBy } from "lodash";
import { createSelector } from "reselect";

import { sortFunctionLookup } from "constants/auctionModes";
import { PenTypes } from "constants/auctionPens";
import { DocumentTypes } from "constants/documentTypes";
import { PricingTypes } from "constants/pricingTypes";
import { saleLotStatuses } from "constants/sale";
import { ScanStatus } from "constants/scanner";
import { Settings } from "constants/settings";
import { Status } from "constants/status";

import {
  calculateUnitPriceCents,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  formatDecimal,
  getUnitPriceString,
} from "lib";

import {
  getAuctionPenDisplayName,
  getSortedRoundsFromAuctionPens,
  getStartPenComponents,
  resolvePenGroupStatus,
  splicePensWithArchetypes,
} from "lib/auctionPens";
import { getBuyerHashFromSaleLot, getSaleLotStatus } from "lib/saleLot";
import { aggregateText } from "lib/textUtils";

import {
  createLookupCombiner,
  createLookupSelectors,
  currentRoundSelector,
  getAgencies,
  getAuctionPens,
  getBusinesses,
  getConsignments,
  getFiles,
  getPenArchetypes,
  getSaleLots,
  getSaleLotsBySale,
  getSetting,
  getSettings,
  getStatusForListOfStatuses,
  selectAgencyIdBySaleLotId,
  selectAllBuyerAndBuyerWayIdsByBuyerHashLookup,
  selectAttachmentIdsBySaleLotIdLookup,
  selectBuyerHashBySaleLotIdLookup,
  selectDeliveryPenIdBySaleLotIdLookup,
  selectDeploymentIdByConsignmentIdLookup,
  selectDeploymentIdBySaleLotIdLookup,
  selectDeploymentMarkOrderLookup,
  selectEidsByCurrentAuctionPenIdLookup,
  selectFilteredDeliveryPenIds,
  selectHasUnregisteredEidsBySaleLotIdLookup,
  selectImageCountBySaleLotIdLookup,
  selectIsEuEligibleBySaleLotIdLookup,
  selectIsWeighedBySaleLotIdLookup,
  selectNameByDeploymentIdLookup,
  selectPenArchetypeIdByAuctionPenIdLookup,
  selectPenIdsByPenArchetypeIdLookup,
  selectPenIdsByPenTypeLookup,
  selectPenIdsByRoundIdLookup,
  selectPenRangesByAuctionPenIdLookup,
  selectSaleLotIdByEidLookup,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotIdsByDeliveryPenIdLookup,
  selectScannedCountBySaleLotIdLookup,
  selectScannedStatusBySaleLotIdLookup,
  selectVendorIdBySaleLotIdLookup,
} from "selectors";

export const getAuctionPenById = auctionPenId => state =>
  getAuctionPens(state)[auctionPenId] || null;

export const getPenIdsByRoundId = roundId => state =>
  selectPenIdsByRoundIdLookup(state)[roundId] || EMPTY_ARRAY;

export const getPenIdsByPenType = penType => state =>
  selectPenIdsByPenTypeLookup(state)[penType] || EMPTY_ARRAY;

export const selectAutoDraftPenByIdLookup = createSelector(
  [getPenIdsByPenType(PenTypes.AUTO_DRAFT), getAuctionPens],
  (autoDraftPenIds, auctionPens) =>
    autoDraftPenIds.reduce((acc, autoDraftPenId) => {
      acc[autoDraftPenId] = auctionPens[autoDraftPenId];
      return acc;
    }, {}),
);

export const getAuctionPensWithSaleLotsBySaleAndRound = createSelector(
  [
    getAuctionPens,
    getSaleLotsBySale,
    currentRoundSelector,
    selectIsEuEligibleBySaleLotIdLookup,
  ],
  (auctionPens, saleLots, round, euStatusBySaleLotId) => {
    const results = {};
    Object.values(saleLots).forEach(saleLot => {
      if (saleLot.auction_pen_id && saleLot.sale_round_id === round.id) {
        if (!results[saleLot.auction_pen_id]) {
          results[saleLot.auction_pen_id] = {
            ...auctionPens[saleLot.auction_pen_id],
            sale_lots: [],
          };
        }
        saleLot.euStatus = euStatusBySaleLotId[saleLot.id];
        results[saleLot.auction_pen_id].sale_lots.push(saleLot);
      }
    });

    return Object.values(results).map(auctionPen => ({
      ...auctionPen,
      sale_lots: sortBy(auctionPen.sale_lots, "markOrder"),
    }));
  },
);

export const selectModeSortedAuctionPensBySaleAndRound = createSelector(
  [
    getAuctionPensWithSaleLotsBySaleAndRound,
    getSetting(Settings.auctionScreenMode),
  ],
  (auctionPens, mode) => {
    const sortFunction = sortFunctionLookup[mode];
    return sortFunction(auctionPens);
  },
);

export const sortedRoundsSelector = (state, props) => {
  const auctionPens = getAuctionPensWithSaleLotsBySaleAndRound(state, props);
  return getSortedRoundsFromAuctionPens(auctionPens);
};

const selectNextAuctionPenIdByAuctionPenIdLookup = createSelector(
  [selectModeSortedAuctionPensBySaleAndRound],
  auctionPens =>
    auctionPens.reduce((acc, auctionPen, idx) => {
      if (idx < auctionPens.length - 1) {
        acc[auctionPen.id] = auctionPens[idx + 1].id;
      }
      return acc;
    }, {}),
);

export const getNextAuctionPenIdByAuctionPenId =
  auctionPenId => (state, props) =>
    selectNextAuctionPenIdByAuctionPenIdLookup(state, props)[auctionPenId] ||
    null;

const selectPreviousAuctionPenIdByAuctionPenIdLookup = createSelector(
  [selectModeSortedAuctionPensBySaleAndRound],
  auctionPens =>
    auctionPens.reduce((acc, auctionPen, idx) => {
      if (idx > 0) {
        acc[auctionPen.id] = auctionPens[idx - 1].id;
      }
      return acc;
    }, {}),
);

export const getPreviousAuctionPenIdByAuctionPenId =
  auctionPenId => (state, props) =>
    selectPreviousAuctionPenIdByAuctionPenIdLookup(state, props)[
      auctionPenId
    ] || null;

export const getNextAvailableAuctionPen = createSelector(
  [getSaleLotsBySale],
  saleLots => {
    let biggest = 1;
    saleLots.forEach(saleLot => {
      if (saleLot.auction_pen && +saleLot.auction_pen.start_pen >= biggest) {
        biggest = +saleLot.auction_pen.start_pen + 1;
      }
      if (saleLot.auction_pen && +saleLot.auction_pen.end_pen >= biggest) {
        biggest = +saleLot.auction_pen.end_pen + 1;
      }
    });
    return biggest;
  },
);

const selectAutoDraftDecisionCodeByAuctionPenIdLookup = createSelector(
  [selectPenArchetypeIdByAuctionPenIdLookup, getPenArchetypes],
  (penArchetypeIdByAuctionPenIdLookup, penArchetypes) =>
    Object.entries(penArchetypeIdByAuctionPenIdLookup).reduce(
      (lookup, [auctionPenId, penArchetypeId]) => {
        lookup[auctionPenId] = penArchetypeId
          ? penArchetypes[penArchetypeId].decisionCode
          : null;
        return lookup;
      },
      {},
    ),
);
export const getAutoDraftDecisionCodeByAuctionPenIdLookup =
  auctionPenId => state =>
    selectAutoDraftDecisionCodeByAuctionPenIdLookup(state)[auctionPenId] ||
    null;

export const selectEidCountByCurrentAuctionPenIdLookup = createSelector(
  [selectEidsByCurrentAuctionPenIdLookup],
  eidsByCurrentAuctionPenIdLookup =>
    Object.entries(eidsByCurrentAuctionPenIdLookup).reduce(
      (lookup, [auctionPenId, eids]) => {
        lookup[auctionPenId] = eids.length;
        return lookup;
      },
      {},
    ),
);

export const getEidCountByCurrentAuctionPenId = auctionPenId => state =>
  selectEidCountByCurrentAuctionPenIdLookup(state)[auctionPenId] || 0;

export const selectPensByPenTypeLookup = createSelector(
  [selectPenIdsByPenTypeLookup, getAuctionPens],
  (auctionPenIdsByPenTypeLookup, pens) =>
    Object.entries(auctionPenIdsByPenTypeLookup).reduce(
      (lookup, [penType, penIds]) => {
        lookup[penType] = penIds.map(penId => pens[penId]);
        return lookup;
      },
      {},
    ),
);

export const getPensByPenType = penType => state =>
  selectPensByPenTypeLookup(state)[penType] || null;

export const selectHeadCountByAuctionPenIdLookup = createSelector(
  [selectSaleLotIdsByAuctionPenIdLookup, getSaleLots],
  (saleLotIdsByAuctionPenIdLookup, saleLots) =>
    Object.entries(saleLotIdsByAuctionPenIdLookup).reduce(
      (lookup, [auctionPenId, saleLotIds]) => {
        lookup[auctionPenId] = saleLotIds.reduce(
          (acc, saleLotId) => acc + saleLots[saleLotId].quantity,
          0,
        );
        return lookup;
      },
      {},
    ),
);

export const selectHeadCountByDeliveryPenIdLookup = createSelector(
  [selectSaleLotIdsByDeliveryPenIdLookup, getSaleLots],
  (saleLotIdsByDeliveryPenIdLookup, saleLots) =>
    Object.entries(saleLotIdsByDeliveryPenIdLookup).reduce(
      (lookup, [auctionPenId, saleLotIds]) => {
        lookup[auctionPenId] = saleLotIds.reduce(
          (acc, saleLotId) => acc + saleLots[saleLotId].quantity,
          0,
        );
        return lookup;
      },
      {},
    ),
);

export const selectPenOwnersByStartPenLookup = createSelector(
  [getAuctionPens],
  auctionPens =>
    Object.values(auctionPens).reduce((lookup, auctionPen) => {
      if (!Array.isArray(lookup[auctionPen.start_pen])) {
        lookup[auctionPen.start_pen] = [];
      }
      if (auctionPen.penOwners) {
        lookup[auctionPen.start_pen] = lookup[auctionPen.start_pen].concat(
          auctionPen.penOwners,
        );
      }
      return lookup;
    }, {}),
);

export const getPenOwnersByStartPen = startPen => state =>
  selectPenOwnersByStartPenLookup(state)[startPen] || EMPTY_ARRAY;

export const selectOwnedPenIdsByBuyerHashLookup = createSelector(
  [getAuctionPens],
  auctionPens =>
    Object.values(auctionPens).reduce((lookup, auctionPen) => {
      if (Array.isArray(auctionPen.penOwners)) {
        auctionPen.penOwners.forEach(owner => {
          const hash = getBuyerHashFromSaleLot({
            buyer_id: owner.businessId,
            buyer_way: owner.buyerWay,
          });
          if (!Array.isArray(lookup[hash])) {
            lookup[hash] = [];
          }
          lookup[hash].push(auctionPen.id);
        });
      }
      return lookup;
    }, {}),
);

export const getOwnedPenIdsByBuyerHash = buyerHash => state =>
  selectOwnedPenIdsByBuyerHashLookup(state)[buyerHash] || EMPTY_ARRAY;

export const selectDeliveryPenIds = createSelector(
  [getAuctionPens, getSaleLots, selectFilteredDeliveryPenIds],
  (auctionPens, saleLots, filteredDeliveryPenIds) => {
    // A bit of a fallback - get all pens that are explicitly a delivery pen, or assigned to a sale lot as the
    // delivery pen - this may never be the actual case, but it's a bit safer
    const assignedAsDeliveryPen = new Set(
      Object.values(saleLots)
        .map(sl => sl.deliveryPenId)
        .filter(Boolean),
    );
    return Object.values(auctionPens)
      .filter(
        auctionPen =>
          filteredDeliveryPenIds.includes(auctionPen.id) &&
          (auctionPen.penType === PenTypes.DELIVERY ||
            assignedAsDeliveryPen.has(auctionPen.id)),
      )
      .map(auctionPen => auctionPen.id);
  },
);

export const selectBuyerHashesByDeliveryPenIdLookup = createSelector(
  [selectSaleLotIdsByDeliveryPenIdLookup, selectBuyerHashBySaleLotIdLookup],
  (saleLotIdsByDeliveryPenIdLookup, buyerAndBuyerWayHashBySaleLotIdLookup) =>
    Object.entries(saleLotIdsByDeliveryPenIdLookup).reduce(
      (acc, [deliveryPenId, saleLotIds]) => {
        const buyerAndBuyerWayHashes = saleLotIds.map(
          saleLotId => buyerAndBuyerWayHashBySaleLotIdLookup[saleLotId],
        );
        if (buyerAndBuyerWayHashes.length > 1) {
          acc[deliveryPenId] = Array.from(new Set(buyerAndBuyerWayHashes));
        } else {
          acc[deliveryPenId] = buyerAndBuyerWayHashes;
        }

        return acc;
      },
      {},
    ),
);
const selectBuyerAndBuyerWayIdsByDeliveryPenIdLookup = createSelector(
  [
    getSaleLots,
    selectSaleLotIdsByDeliveryPenIdLookup,
    selectAllBuyerAndBuyerWayIdsByBuyerHashLookup,
  ],
  (saleLots, saleLotIdsByDeliveryPenId, buyerAndBuyerWayIdsByBuyerHashLookup) =>
    Object.entries(saleLotIdsByDeliveryPenId).reduce(
      (lookup, [deliveryPenId, saleLotIds]) => {
        lookup[deliveryPenId] = uniqBy(
          saleLotIds
            .map(saleLotId => saleLots[saleLotId])
            .filter(saleLot => saleLot.buyer_id)
            .map(
              saleLot =>
                buyerAndBuyerWayIdsByBuyerHashLookup[
                  getBuyerHashFromSaleLot(saleLot)
                ],
            ),

          "buyerHash",
        );
        return lookup;
      },
      {},
    ),
);

export const getBuyerAndBuyerWayIdsByDeliveryPenId = deliveryPenId => state =>
  selectBuyerAndBuyerWayIdsByDeliveryPenIdLookup(state)[deliveryPenId];

export const selectDeliveryPenByEidLookup = createSelector(
  [
    selectSaleLotIdByEidLookup,
    selectDeliveryPenIdBySaleLotIdLookup,
    getAuctionPens,
  ],
  (saleLotIdByEidLookup, deliveryPenIdBySaleLotIdLookup, auctionPens) =>
    Object.entries(saleLotIdByEidLookup).reduce((lookup, [eid, saleLotId]) => {
      // Only include pens we actually know exist - otherwise they might as well be null
      lookup[eid] =
        (deliveryPenIdBySaleLotIdLookup[saleLotId] &&
          auctionPens[deliveryPenIdBySaleLotIdLookup[saleLotId]]?.id) ||
        null;
      return lookup;
    }, {}),
);

export const selectDistrictByAuctionPenIdLookup = createSelector(
  [getSaleLotsBySale],
  saleLots =>
    saleLots.reduce((acc, saleLot) => {
      const properties =
        saleLot.vendor.properties?.map(property => property.district) || [];
      if (saleLot.auction_pen_id in acc) {
        acc[saleLot.auction_pen_id] =
          acc[saleLot.auction_pen_id].concat(properties);
      } else {
        acc[saleLot.auction_pen_id] = properties;
      }
      return acc;
    }, []),
);

export const selectAllMarksByAuctionPenIdLookup = createSelector(
  [
    getAuctionPens,
    getSaleLots,
    selectDeploymentMarkOrderLookup,
    selectDeploymentIdByConsignmentIdLookup,
    selectSaleLotIdsByAuctionPenIdLookup,
  ],
  (
    auctionPens,
    saleLots,
    deploymentMarkOrder,
    deploymentIdByConsignment,
    saleLotIdsByAuctionPenId,
  ) =>
    Object.entries(auctionPens).reduce((acc, [auctionPenId]) => {
      const saleLotIds = saleLotIdsByAuctionPenId[auctionPenId] || [];
      acc[auctionPenId] = saleLotIds.map(saleLotId => {
        const saleLot = saleLots[saleLotId];
        const marks = saleLot.marks
          .map(mark => ({
            mark,
            order:
              deploymentMarkOrder[
                deploymentIdByConsignment[saleLot.consignment_id]
              ][mark.location],
          }))
          .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
          .map(mark => mark.mark.location);
        if (auctionPenId in acc) {
          return (acc[auctionPenId] = acc[auctionPenId].concat(marks));
        } else {
          return (acc[auctionPenId] = marks);
        }
      });
      return acc;
    }, []),
);

export const selectVendorNameByAuctionPenIdLookup = createSelector(
  [
    selectSaleLotIdsByAuctionPenIdLookup,
    selectVendorIdBySaleLotIdLookup,
    getBusinesses,
    getSaleLots,
    getSettings,
  ],
  (
    saleLotIdsByAuctionPenIdLookup,
    vendorIdBySaleLotIdLookup,
    businesses,
    saleLots,
    settings,
  ) =>
    Object.entries(saleLotIdsByAuctionPenIdLookup).reduce(
      (acc, [auctionPenId, saleLotIds]) => {
        const { round } = settings;
        const vendors = saleLotIds
          .map(saleLotId => {
            const lot = saleLots[saleLotId];
            if (auctionPenId !== "null" || round === lot.sale_round_id) {
              return businesses[vendorIdBySaleLotIdLookup[saleLotId]];
            } else {
              return false;
            }
          })
          .filter(Boolean);
        acc[auctionPenId] = aggregateText(vendors, "name", "vendors");
        return acc;
      },
      {},
    ),
);

export const getVendorNameByAuctionPenId = auctionPenId => state =>
  selectVendorNameByAuctionPenIdLookup(state)[auctionPenId];

const selectBuyerNameByAuctionPenIdLookup = createSelector(
  [selectSaleLotIdsByAuctionPenIdLookup, getSaleLots, getBusinesses],
  (saleLotIdsByAuctionPenIdLookup, saleLots, businesses) =>
    Object.entries(saleLotIdsByAuctionPenIdLookup).reduce(
      (acc, [auctionPenId, saleLotIds]) => {
        const buyers = saleLotIds
          .map(saleLotId => businesses[saleLots[saleLotId].buyer_id])
          .filter(Boolean);

        acc[auctionPenId] = aggregateText(buyers, "name", "buyers");
        return acc;
      },
      {},
    ),
);

export const getBuyerNameByAuctionPenId = auctionPenId => state =>
  selectBuyerNameByAuctionPenIdLookup(state)[auctionPenId];

const selectBuyerWayNameByAuctionPenIdLookup = createSelector(
  [selectSaleLotIdsByAuctionPenIdLookup, getSaleLots],
  (saleLotIdsByAuctionPenIdLookup, saleLots) =>
    Object.entries(saleLotIdsByAuctionPenIdLookup).reduce(
      (acc, [auctionPenId, saleLotIds]) => {
        // Fall back to 'emtpy' as it IS different to /something/
        const buyerWays = saleLotIds.map(
          saleLotId => saleLots[saleLotId].buyer_way || {},
        );

        acc[auctionPenId] = aggregateText(buyerWays, "name", "buyer ways");
        return acc;
      },
      {},
    ),
);

export const getBuyerWayNameByAuctionPenId = auctionPenId => state =>
  selectBuyerWayNameByAuctionPenIdLookup(state)[auctionPenId];

const priceNameByAuctionPenIdReducer = (saleLotIds, saleLots) => {
  // Yuck.
  const priceParts = {
    unit: "",
    prepend: "",
    prices: [],
  };
  if (
    saleLotIds.every(
      saleLotId =>
        saleLots[saleLotId].pricing_type_id === PricingTypes.PER_KILO,
    )
  ) {
    priceParts.unit = PricingTypes.toString(PricingTypes.PER_KILO);
    priceParts.prices = uniq(
      saleLotIds
        .map(saleLotId => getUnitPriceString(saleLots[saleLotId]))
        .filter(Boolean),
    );
  } else {
    priceParts.prepend = "$";
    priceParts.prices = uniq(
      saleLotIds
        .map(saleLotId => calculateUnitPriceCents(saleLots[saleLotId]))
        .map(priceCents => formatDecimal(priceCents / 100)),
    );
  }
  if (priceParts.prices.length === 0) {
    return "";
  } else if (priceParts.prices.length === 1) {
    return `${priceParts.prepend}${priceParts.prices[0]} ${priceParts.unit}`;
  } else {
    return `${priceParts.prices.length} prices`;
  }
};

export const [selectPriceNameByAuctionPenIdLookup, getPriceNameByAuctionPenId] =
  createLookupSelectors(
    [selectSaleLotIdsByAuctionPenIdLookup, getSaleLots],
    createLookupCombiner(priceNameByAuctionPenIdReducer),
  );

const agencyIdsByAuctionPenIdReducer = (saleLotIds, agencyIdBySaleLotId) =>
  uniq(saleLotIds.map(saleLotId => agencyIdBySaleLotId[saleLotId]));

export const [selectAgencyIdsByAuctionPenIdLookup, getAgencyIdsByAuctionPenId] =
  createLookupSelectors(
    [selectSaleLotIdsByAuctionPenIdLookup, selectAgencyIdBySaleLotId],
    createLookupCombiner(agencyIdsByAuctionPenIdReducer),
  );

const agencyShortNameByAuctionPenIdReducer = (agencyIds, agencies) => {
  return (
    (agencyIds.every(Boolean) &&
      aggregateText(
        agencyIds.map(agencyId => agencies[agencyId] || {}),
        "shortName",
        "agencies",
      )) ||
    "Agencies Loading..."
  );
};

export const [
  selectAgencyShortNameByAuctionPenIdLookup,
  getAgencyShortNameByAuctionPenId,
] = createLookupSelectors(
  [selectAgencyIdsByAuctionPenIdLookup, getAgencies],
  createLookupCombiner(agencyShortNameByAuctionPenIdReducer),
);

const statusByAuctionPenIdLookupReducer = (
  saleLotIds,
  saleLots,
  consignments,
) =>
  getStatusForListOfStatuses(
    saleLotIds
      .map(saleLotId => saleLots[saleLotId])
      .filter(Boolean)
      .map(saleLot =>
        getSaleLotStatus(saleLot, consignments[saleLot.consignment_id]),
      )
      .map(status =>
        status === saleLotStatuses.PENNED ? saleLotStatuses.NOT_SOLD : status,
      ),
  );

export const [selectStatusByAuctionPenIdLookup, getStatusByAuctionPenId] =
  createLookupSelectors(
    [selectSaleLotIdsByAuctionPenIdLookup, getSaleLots, getConsignments],
    createLookupCombiner(statusByAuctionPenIdLookupReducer),
  );

const hasUnregisteredEidsByAuctionPenIdCombiner = (
  saleLotIds,
  hasUnregisteredEidsBySaleLotId,
) => saleLotIds.some(saleLotId => hasUnregisteredEidsBySaleLotId[saleLotId]);

export const [
  selectHasUnregisteredEidsByAuctionPenIdLookup,
  getHasUnregisteredEidsByAuctionPenId,
] = createLookupSelectors(
  [
    selectSaleLotIdsByAuctionPenIdLookup,
    selectHasUnregisteredEidsBySaleLotIdLookup,
  ],
  createLookupCombiner(hasUnregisteredEidsByAuctionPenIdCombiner),
);

const imageSummaryByAuctionPenIdReducer = (
  saleLotIds,
  imageCountBySaleLotIdLookup,
) => {
  const imageCounts = saleLotIds.map(
    saleLotId => imageCountBySaleLotIdLookup[saleLotId],
  );
  let status = Status.NONE;
  if (imageCounts.every(c => c > 0)) {
    status = Status.ALL;
  } else if (imageCounts.some(c => c > 0)) {
    status = Status.SOME;
  }

  return {
    count: sum(imageCounts),
    status,
  };
};

export const [
  selectImageSummaryByAuctionPenIdLookup,
  getImageSummaryByAuctionPenId,
] = createLookupSelectors(
  [selectSaleLotIdsByAuctionPenIdLookup, selectImageCountBySaleLotIdLookup],
  createLookupCombiner(imageSummaryByAuctionPenIdReducer),
  EMPTY_OBJECT,
);

export const getImageCountByAuctionPenId = auctionPenId => state =>
  selectImageSummaryByAuctionPenIdLookup(state)[auctionPenId]?.count || 0;
export const getImageStatusByAuctionPenId = auctionPenId => state =>
  selectImageSummaryByAuctionPenIdLookup(state)[auctionPenId]?.status ||
  Status.NONE;

const saleLotSummaryByAuctionPenIdReducer = (
  saleLotIds,
  scannedCountBySaleLotIdLookup,
  isWeighedBySaleLotIdLookup,
  saleLots,
  attachmentIds,
  attachments,
  settings,
) => {
  const result = saleLotIds.reduce(
    (result, saleLotId) => {
      const { round } = settings;
      const lot = saleLots[saleLotId];
      if (!lot.auction_pen_id && round === lot.sale_round_id) {
        result.unpennedQuantity += saleLots[saleLotId].quantity;
      }

      result.scanned += scannedCountBySaleLotIdLookup[saleLotId];
      result.quantity += saleLots[saleLotId].quantity;
      result.quantityDelivered += saleLots[saleLotId].quantity_delivered;
      result.quantityProgeny += saleLots[saleLotId].quantityProgeny;
      result.quantityIncludingProgeny +=
        saleLots[saleLotId].quantity + saleLots[saleLotId].quantityProgeny;
      result.totalMassGrams += saleLots[saleLotId].total_mass_grams;

      const hasYotubeVideo = saleLots[saleLotId].youtube_link !== "";

      const videoCount =
        attachmentIds[saleLotId]?.filter(
          attachmentId =>
            attachments[attachmentId]?.type === DocumentTypes.VIDEO,
        )?.length || 0;

      if (isWeighedBySaleLotIdLookup[saleLotId]) {
        result.isAnyWeighed = true;
      } else {
        result.isAnyUnweighed = true;
      }
      if (videoCount || hasYotubeVideo) {
        result.hasVideo = true;
      }
      // Only show the first lots overflow pen, weird, but how it is.
      if (
        saleLots[saleLotId].overflowPen &&
        typeof result.overflowPen !== "undefined"
      ) {
        result.hasOverflowPen = true;
        result.overflowPen = saleLots[saleLotId].overflowPen;
        result.overflowQuantity = saleLots[saleLotId].overflowQuantity;
      }
      result.videoCount = hasYotubeVideo ? videoCount + 1 : videoCount;
      return result;
    },
    {
      scanned: 0,
      quantity: 0,
      quantityDelivered: 0,
      quantityProgeny: 0,
      quantityIncludingProgeny: 0,
      hasVideo: false,
      videoCount: 0,
      hasOverflowPen: false,
      overflowPen: null,
      overflowQuantity: null,
      totalMassGrams: 0,
      isAnyUnweighed: false,
      isAnyWeighed: false,
      hasLotWithAllScansWeighed: false,
      hasLotWithUnweighedScans: false,
      unpennedQuantity: 0,
    },
  );

  result.scannedPercentage = result.quantityIncludingProgeny
    ? result.scanned / result.quantityIncludingProgeny
    : 0;

  return result;
};

export const [
  selectSaleLotSummaryByAuctionPenIdLookup,
  getSaleLotSummaryByAuctionPenId,
] = createLookupSelectors(
  [
    selectSaleLotIdsByAuctionPenIdLookup,
    selectScannedCountBySaleLotIdLookup,
    selectIsWeighedBySaleLotIdLookup,
    getSaleLots,
    selectAttachmentIdsBySaleLotIdLookup,
    getFiles,
    getSettings,
  ],
  createLookupCombiner(saleLotSummaryByAuctionPenIdReducer),
  0,
);

export const getScannedCountByAuctionPenId = auctionPenId => state =>
  selectSaleLotSummaryByAuctionPenIdLookup(state)[auctionPenId]?.scanned || 0;
export const getQuantityByAuctionPenId = auctionPenId => state =>
  selectSaleLotSummaryByAuctionPenIdLookup(state)[auctionPenId]?.quantity || 0;
export const getUnpennedQuantityByAuctionPenId = auctionPenId => state =>
  selectSaleLotSummaryByAuctionPenIdLookup(state)[auctionPenId]
    ?.unpennedQuantity || 0;
export const getQuantityDeliveredByAuctionPenId = auctionPenId => state =>
  selectSaleLotSummaryByAuctionPenIdLookup(state)[auctionPenId]
    ?.quantityDelivered || 0;
export const getQuantityProgenyByAuctionPenId = auctionPenId => state =>
  selectSaleLotSummaryByAuctionPenIdLookup(state)[auctionPenId]
    ?.quantityProgeny || 0;
export const getQuantityIncludingProgenyByAuctionPenId =
  auctionPenId => state =>
    selectSaleLotSummaryByAuctionPenIdLookup(state)[auctionPenId]
      ?.quantityIncludingProgeny || 0;
export const getScannedPercentageByAuctionPenId = auctionPenId => state =>
  selectSaleLotSummaryByAuctionPenIdLookup(state)[auctionPenId]
    ?.scannedPercentage || 0;
export const getHasVideoByAuctionPenId = auctionPenId => state =>
  selectSaleLotSummaryByAuctionPenIdLookup(state)[auctionPenId]?.hasVideo ||
  false;
export const getVideoCountByAuctionPenId = auctionPenId => state =>
  selectSaleLotSummaryByAuctionPenIdLookup(state)[auctionPenId]?.videoCount ||
  false;

export const getOverflowPenByAuctionPenId = auctionPenId => state =>
  selectSaleLotSummaryByAuctionPenIdLookup(state)[auctionPenId]?.overflowPen ||
  undefined;
export const getOverflowQuantityByAuctionPenId = auctionPenId => state =>
  selectSaleLotSummaryByAuctionPenIdLookup(state)[auctionPenId]
    ?.overflowQuantity || null;

// Gets the highest level (ERROR > WARNING > PASS) scan status from the
// sale lots statuses given.
const scannedStatusByAuctionPenIdReducer = (
  saleLotIds,
  scannedStatusBySaleLotIdLookup,
) =>
  max(saleLotIds.map(saleLotId => scannedStatusBySaleLotIdLookup[saleLotId]));
export const [
  selectScannedStatusByAuctionPenIdLookup,
  getScannedStatusByAuctionPenId,
] = createLookupSelectors(
  [selectSaleLotIdsByAuctionPenIdLookup, selectScannedStatusBySaleLotIdLookup],
  createLookupCombiner(scannedStatusByAuctionPenIdReducer),
  ScanStatus.PASS,
);

export const getAuctionPenRangesByAuctionPenId = auctionPenId => state =>
  selectPenRangesByAuctionPenIdLookup(state)[auctionPenId] || EMPTY_OBJECT;

export const selectSingleDeploymentOptionLabelByPenId = createSelector(
  [getAuctionPens, selectHeadCountByAuctionPenIdLookup],
  createLookupCombiner(
    (auctionPen, headCountByAuctionPenIdLookup) =>
      `${getAuctionPenDisplayName(auctionPen)} (${
        headCountByAuctionPenIdLookup[auctionPen.id] || 0
      } hd)`,
  ),
);

export const selectMultiDeploymentOptionLabelByPenId = createSelector(
  [
    getAuctionPens,
    selectSingleDeploymentOptionLabelByPenId,
    selectSaleLotIdsByAuctionPenIdLookup,
    selectDeploymentIdBySaleLotIdLookup,
    selectNameByDeploymentIdLookup,
  ],
  createLookupCombiner(
    (
      auctionPen,
      labelByPenId,
      saleLotIdsByPenIdLookup,
      deploymentIdBySaleLotIdLookup,
      nameByDeploymentIdLookup,
    ) => {
      const saleLotIds = saleLotIdsByPenIdLookup[auctionPen.id] || [];
      const deploymentIds = uniq(
        saleLotIds.map(saleLotId => deploymentIdBySaleLotIdLookup[saleLotId]),
      );
      return `${labelByPenId[auctionPen.id]} (${deploymentIds
        .map(deploymentId => nameByDeploymentIdLookup[deploymentId])
        .join(", ")})`;
    },
  ),
);

export const selectPenStatusByIdReducer = (
  auctionPen,
  lotIdsByPenIdLookup,
  lotStatusByIdLookup,
) => {
  // Find the worst scenario for lots within this pen.
  const statuses = (lotIdsByPenIdLookup[auctionPen.id] || EMPTY_ARRAY).map(
    lotId => lotStatusByIdLookup[lotId],
  );
  return resolvePenGroupStatus(statuses);
};

export const selectOrderedPenIdsByPenType = createSelector(
  [
    getAuctionPens,
    getPenArchetypes,
    selectPenIdsByPenArchetypeIdLookup,
    selectPenIdsByPenTypeLookup,
  ],
  (
    auctionPens,
    penArchetypes,
    penIdsByPenArchetypeIdLookup,
    penIdsByTypeLookup,
  ) => {
    const sellingPenArchetypes = sortBy(
      Object.values(penArchetypes).filter(
        archetype => archetype.penType === PenTypes.SELLING,
      ),
      "order",
    );

    const receivalPenArchetypes = sortBy(
      Object.values(penArchetypes).filter(
        archetype => archetype.penType === PenTypes.RECEIVING,
      ),
      "order",
    );

    const sellingPens =
      penIdsByTypeLookup[PenTypes.SELLING]?.map(penId => auctionPens[penId]) ||
      [];

    const receivalPens =
      penIdsByTypeLookup[PenTypes.RECEIVING]?.map(
        penId => auctionPens[penId],
      ) || [];

    const orderedSellingPens = splicePensWithArchetypes(
      sellingPens,
      sellingPenArchetypes,
    ).reduce((acc, penObject) => {
      let penId = null;
      let { penArchetypeId } = penObject;
      if (auctionPens[penObject.id]) {
        penId = penObject.id;
      } else {
        penId = penIdsByPenArchetypeIdLookup[penObject.id]?.[0] || null;
        penArchetypeId = penObject.id;
      }

      acc.push({
        penArchetypeId,
        penId,
      });
      return acc;
    }, []);

    orderedSellingPens.concat(
      (penIdsByPenArchetypeIdLookup.null || []).map(penId => {
        if (auctionPens[penId].penType === PenTypes.SELLING) {
          return {
            penArchetypeId: null,
            penId,
          };
        } else {
          return null;
        }
      }),
    );

    const orderedReceivalPens = splicePensWithArchetypes(
      receivalPens,
      receivalPenArchetypes,
    ).reduce((acc, penObject) => {
      let penId = null;
      let penArchetypeId = null;
      if (auctionPens[penObject.id]) {
        penId = penObject.id;
        // I do what I want
        // eslint-disable-next-line prefer-destructuring
        penArchetypeId = penObject.penArchetypeId;
      } else {
        penId = penIdsByPenArchetypeIdLookup[penObject.id]?.[0] || null;
        penArchetypeId = penObject.id;
      }

      acc.push({
        penArchetypeId,
        penId,
      });
      return acc;
    }, []);

    orderedReceivalPens.concat(
      (penIdsByPenArchetypeIdLookup.null || []).map(penId => {
        if (auctionPens[penId].penType === PenTypes.RECEIVING) {
          return {
            penArchetypeId: null,
            penId,
          };
        } else {
          return null;
        }
      }),
    );

    return {
      [PenTypes.SELLING]: orderedSellingPens,
      [PenTypes.RECEIVING]: orderedReceivalPens,
    };
  },
);

/**
 * Get next pen number incremented from the highest existing pen number
 * from a list of auction pens that have associated sale lots
 * @returns {Number}
 */
export const getNextEmptyPenNumber = createSelector(
  [getAuctionPens, selectSaleLotIdsByAuctionPenIdLookup],
  (auctionPensByIdLookup, saleLotIdsByAuctionPenIdLookup) => {
    const usedAuctionPens = Object.values(auctionPensByIdLookup).filter(
      auctionPen => saleLotIdsByAuctionPenIdLookup[auctionPen.id]?.length,
    );

    const highestStartPenNumber = max(
      usedAuctionPens.map(
        auctionPen => getStartPenComponents(auctionPen.start_pen).start_pen,
      ),
    );

    return highestStartPenNumber ? highestStartPenNumber + 1 : 1;
  },
);
