import { withStyles } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";

import { AuditLogFieldNames, AuditLogTypes } from "constants/auditLog";

import { formatDollarNumberWithExtraDecimals, formatWeightKg } from "lib";

import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

export const FieldNameTableCell = withStyles(() => ({
  root: {
    textAlign: "right",
    width: "30%",
  },
}))(TableCell);

// TODOs - go through and add relevant types.

const AuditLogDataTypes = {
  WeightGrams: "weightGrams",
  CurrencyCents: "currencyCents",
  UTCDateTime: "utcDatetime",
};

const AuditLogTypeFormatters = {
  [AuditLogDataTypes.WeightGrams]: formatWeightKg,
  [AuditLogDataTypes.CurrencyCents]: currencyCents =>
    `${formatDollarNumberWithExtraDecimals(currencyCents / 100)}`,
  [AuditLogDataTypes.UTCDateTime]: formatUTCToLocalDateTimeString,
};

const AuditLogFieldTypes = {
  [AuditLogTypes.SALE_LOT]: {
    total_price_cents: AuditLogDataTypes.CurrencyCents,
    total_mass_grams: AuditLogDataTypes.WeightGrams,
    first_sold: AuditLogDataTypes.UTCDateTime,
    estimated_average_mass_grams: AuditLogDataTypes.WeightGrams,
  },
  [AuditLogTypes.CONSIGNMENT]: {
    earliest_pickup_date: AuditLogDataTypes.UTCDateTime,
    last_modified: AuditLogDataTypes.UTCDateTime,
    created: AuditLogDataTypes.UTCDateTime,
    database_last_modified: AuditLogDataTypes.UTCDateTime,
    database_created: AuditLogDataTypes.UTCDateTime,
  },
  [AuditLogTypes.SCAN]: {
    last_modified: AuditLogDataTypes.UTCDateTime,
    created: AuditLogDataTypes.UTCDateTime,
    database_last_modified: AuditLogDataTypes.UTCDateTime,
    database_created: AuditLogDataTypes.UTCDateTime,
    time_weighed: AuditLogDataTypes.UTCDateTime,
    total_mass_grams: AuditLogDataTypes.WeightGrams,
  },
  [AuditLogTypes.DEPLOYMENT_BUSINESS]: {
    last_modified: AuditLogDataTypes.UTCDateTime,
    created: AuditLogDataTypes.UTCDateTime,
    database_last_modified: AuditLogDataTypes.UTCDateTime,
    database_created: AuditLogDataTypes.UTCDateTime,
  },
  [AuditLogTypes.CLEARING_SALE_ATTRIBUTES]: {
    last_modified: AuditLogDataTypes.UTCDateTime,
    created: AuditLogDataTypes.UTCDateTime,
    database_last_modified: AuditLogDataTypes.UTCDateTime,
    database_created: AuditLogDataTypes.UTCDateTime,
  },
  [AuditLogTypes.DRAFTING_ATTRIBUTES]: {
    last_modified: AuditLogDataTypes.UTCDateTime,
    created: AuditLogDataTypes.UTCDateTime,
    database_last_modified: AuditLogDataTypes.UTCDateTime,
    database_created: AuditLogDataTypes.UTCDateTime,
    max_mass_grams: AuditLogDataTypes.WeightGrams,
    min_mass_grams: AuditLogDataTypes.WeightGrams,
    est_carcass_weight: AuditLogDataTypes.WeightGrams,
  },
  [AuditLogTypes.RECEIVAL_LOT]: {
    last_modified: AuditLogDataTypes.UTCDateTime,
    created: AuditLogDataTypes.UTCDateTime,
    database_last_modified: AuditLogDataTypes.UTCDateTime,
    database_created: AuditLogDataTypes.UTCDateTime,
  },
  [AuditLogTypes.PEN_SCAN_LOT]: {
    last_modified: AuditLogDataTypes.UTCDateTime,
    created: AuditLogDataTypes.UTCDateTime,
    database_last_modified: AuditLogDataTypes.UTCDateTime,
    database_created: AuditLogDataTypes.UTCDateTime,
  },
  [AuditLogTypes.LEDGER_ENTRY]: {
    subtotal: AuditLogDataTypes.CurrencyCents,
    tax_amount: AuditLogDataTypes.CurrencyCents,
    unit_amount: AuditLogDataTypes.CurrencyCents,
  },
  [AuditLogTypes.WEIGH_LOT]: {
    last_modified: AuditLogDataTypes.UTCDateTime,
    created: AuditLogDataTypes.UTCDateTime,
    database_last_modified: AuditLogDataTypes.UTCDateTime,
    database_created: AuditLogDataTypes.UTCDateTime,
  },
  [AuditLogTypes.WEIGH_LOT_SCAN]: {
    last_modified: AuditLogDataTypes.UTCDateTime,
    created: AuditLogDataTypes.UTCDateTime,
    database_last_modified: AuditLogDataTypes.UTCDateTime,
    database_created: AuditLogDataTypes.UTCDateTime,
  },
};

export const FieldValue = ({ dataType, fieldName, fieldValue }) => {
  // If the value is an object, it's likely a foreign key value - for now, just interrogate and pull out display
  // Another day we may be able to use the raw value to link off to the relevant object.  But not this day.
  if (typeof fieldValue === "object") {
    return fieldValue?.display || "-";
  }

  if (typeof fieldValue === "boolean") {
    return fieldValue ? "Yes" : "No";
  }

  const formatterType = AuditLogFieldTypes[dataType]?.[fieldName];
  if (!formatterType) {
    return fieldValue;
  }

  const formatter = AuditLogTypeFormatters[formatterType];
  if (typeof formatter !== "function") {
    return fieldValue;
  }

  try {
    return formatter(fieldValue);
  } catch (e) {
    // It should all work... but if it's blank/unexpected/weird better to show something badly formatted than nothing,
    // or raise an error.
    return fieldValue;
  }
};
export const FieldName = ({ dataType, fieldName }) => {
  return AuditLogFieldNames[dataType]?.[fieldName] || fieldName;
};
