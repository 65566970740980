import React from "react";

import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faFileCsv, faFileExport } from "@fortawesome/pro-solid-svg-icons";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { BillingDocumentAction } from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { AgGridPreview } from "components/AgGrid/AgGridPreview";
import { ModalTableWrapper } from "components/AgGrid/TableWrapper";
import { Button } from "components/Form";
import { Column } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { AgGridTables } from "constants/aggrid";
import {
  BillingDocumentColId,
  BillingDocumentColumnDef,
  BusinessBankDetailsColId,
  BusinessBankDetailsColumnDef,
  BusinessColId,
  BusinessColumnDef,
} from "constants/columnDefinitions";
import { DeploymentPermissions } from "constants/permissions";

import { hasPermission } from "lib/permissions";

import {
  getActiveBillingRunId,
  getActiveLivestockAgentDeployment,
  selectBillingDocumentsAggridData,
  selectCanSendToXeroByBillingDocumentIdLookup,
} from "selectors";

import { useHasDeploymentPermission, useToggle } from "hooks";
import { useBillingDocumentAggridGetContextMenuItems } from "hooks/useBillingDocumentAggridGetContextMenuItems";

const rowSelectionId = "billingDocument.id";
const getRowId = params => get(params.data, rowSelectionId);

const commonBillingTableColumnDefs = [
  BillingDocumentColumnDef[BillingDocumentColId.DOCUMENT_TYPE],
  BillingDocumentColumnDef[BillingDocumentColId.DOCUMENT_NUMBER],
  BusinessColumnDef[BusinessColId.NAME],
  BusinessColumnDef[BusinessColId.PRIMARY_CONTACT_EMAIL],
  BillingDocumentColumnDef[BillingDocumentColId.STATUS],
  BillingDocumentColumnDef[BillingDocumentColId.WARNINGS],
  BillingDocumentColumnDef[BillingDocumentColId.DUE_DATE],
  BillingDocumentColumnDef[BillingDocumentColId.TOTAL_AMOUNT_DOLLARS],
  BillingDocumentColumnDef[BillingDocumentColId.ACTIONS],
];

// add relevant fields for Xero exports
const xeroBillingTableColumnDefs = [
  BusinessColumnDef[BusinessColId.XERO_INTEGRATION_BUSINESS],
  BillingDocumentColumnDef[BillingDocumentColId.XERO_INVOICE_ID],
];

// add relevant fields for MYOB exports
const myobBillingTableColumnDefs = [
  BusinessColumnDef[BusinessColId.SHORT_CODE],
  BusinessBankDetailsColumnDef[BusinessBankDetailsColId.HAS_BANK_DETAILS],
  BusinessColumnDef[BusinessColId.MYOB_CUSTOMER_CARD_ID],
  BusinessColumnDef[BusinessColId.MYOB_SUPPLIER_CARD_ID],
];

const ConfirmExport = ({ toggleShowConfirm, rowData }) => {
  const dispatch = useDispatch();

  const exportToXero = () => {
    dispatch(
      BillingDocumentAction.exportXeroInvoiceApi(
        rowData.map(row => row.billingDocument.id),
      ),
    );
    toggleShowConfirm();
  };
  const columnDefs = commonBillingTableColumnDefs.concat(
    xeroBillingTableColumnDefs,
  );

  return (
    <Dialog open onClose={toggleShowConfirm} maxWidth="lg" fullWidth>
      <DialogTitle onClose={toggleShowConfirm}>
        Are you sure you wish to export the following documents to Xero?
      </DialogTitle>
      <DialogContent>
        <ModalTableWrapper>
          <AgGridPreview
            rowData={rowData}
            columnDefs={columnDefs}
            tableName={AgGridTables.BILLING_DOCUMENTS_EXPORT_CONFIRMATION}
          />
        </ModalTableWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleShowConfirm}>Cancel</Button>
        <Button onClick={exportToXero}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export const ExportAPI = () => {
  const hasXeroIntegrationFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureXeroIntegration,
  );
  const hasMyobExportsFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureMyobIntegration,
  );
  const rowData = useSelector(selectBillingDocumentsAggridData);
  const getContextMenuItems = useBillingDocumentAggridGetContextMenuItems();

  const [selectedRows, setSelectedRows] = React.useState([]);

  const [showConfirm, toggleShowConfirm] = useToggle(false);

  const billingRunId = useSelector(getActiveBillingRunId);
  const dispatch = useDispatch();
  const exportXeroBills = () => {
    dispatch(BillingDocumentAction.exportXeroBillCsv(billingRunId));
  };

  const exportXeroInvoices = () => {
    dispatch(BillingDocumentAction.exportXeroInvoiceCsv(billingRunId));
  };

  const exportMyobCustomers = () => {
    dispatch(BillingDocumentAction.exportMyobCustomerTsv(billingRunId));
  };
  const exportMyobSuppliers = () => {
    dispatch(BillingDocumentAction.exportMyobSupplierTsv(billingRunId));
  };
  const exportMyobSales = () => {
    dispatch(BillingDocumentAction.exportMyobSales(billingRunId));
  };
  const exportMyobPurchases = () => {
    dispatch(BillingDocumentAction.exportMyobPurchases(billingRunId));
  };
  const exportMyobClassicSales = () => {
    dispatch(BillingDocumentAction.exportMyobClassicSales(billingRunId));
  };
  const exportMyobClassicPurchases = () => {
    dispatch(BillingDocumentAction.exportMyobClassicPurchases(billingRunId));
  };

  const exportSaleyardBulkBilling = () => {
    dispatch(BillingDocumentAction.exportSaleyardBulkBillingXml(billingRunId));
  };

  const exportAgentBulkBilling = () => {
    dispatch(BillingDocumentAction.exportAgentBulkBillingXml(billingRunId));
  };

  const canSendToXeroLookup = useSelector(
    selectCanSendToXeroByBillingDocumentIdLookup,
  );
  const isRowSelectable = ({ data }) =>
    canSendToXeroLookup[data.billingDocument.id];

  const isExportDisabled = selectedRows.length < 1;

  const customExport = [
    {
      title: selectedRows.length
        ? `Send (${selectedRows.length}) to Xero`
        : "Send to Xero",
      isDisabled: isExportDisabled,
      onClick: toggleShowConfirm,
      icon: faPaperPlane,
      tooltip: "Select approved Invoices/RCTIs (without warnings) to send.",
      permission: DeploymentPermissions.featureXeroIntegration,
    },
    {
      title: "Export Invoices for Xero",
      onClick: exportXeroInvoices,
      icon: faFileCsv,
      tooltip: "Download all approved Invoices as a CSV to be uploaded to Xero",
      permission: DeploymentPermissions.featureXeroIntegration,
    },
    {
      title: "Export Account Sales/RCTIs for Xero",
      onClick: exportXeroBills,
      icon: faFileCsv,
      tooltip:
        "Download all approved Account Sales/RCTIs as a CSV to be uploaded to Xero",
      permission: DeploymentPermissions.featureXeroIntegration,
    },
    {
      title: "Export Customer Cards for MYOB",
      onClick: exportMyobCustomers,
      icon: faFileCsv,
      tooltip:
        "Download customer cards for businesses in this sale, as a CSV to be imported into MYOB",
      permission: DeploymentPermissions.featureMyobIntegration,
    },
    {
      title: "Export Supplier Cards for MYOB",
      onClick: exportMyobSuppliers,
      icon: faFileCsv,
      tooltip:
        "Download supplier cards for businesses in this sale, as a CSV to be imported into MYOB",
      permission: DeploymentPermissions.featureMyobIntegration,
    },
    {
      title: "Export Agent Bulk Billing XML",
      onClick: exportAgentBulkBilling,
      icon: faFileExport,
      tooltip: "Download all agent bulk billing Buyer Fees, as an XML file",
      permission: DeploymentPermissions.featureBulkBillingExport,
    },
    {
      title: "Export Saleyard Bulk Billing XML",
      onClick: exportSaleyardBulkBilling,
      icon: faFileExport,
      tooltip: "Download all saleyard bulk billing Buyer Fees, as an XML file",
      permission: DeploymentPermissions.featureBulkBillingExport,
    },
  ];

  const activeDeployment = useSelector(getActiveLivestockAgentDeployment);
  const allowedCustomExports = customExport.filter(customExp => {
    return hasPermission(activeDeployment, customExp.permission);
  });

  if (
    hasPermission(
      activeDeployment,
      DeploymentPermissions.featureMyobClassicIntegration,
    )
  ) {
    allowedCustomExports.push(
      {
        title: "Export Sales for MYOB AccountRight Classic",
        onClick: exportMyobClassicSales,
        icon: faFileCsv,
        tooltip:
          "Download all approved Invoices as a CSV to be imported into MYOB as Sales - Miscellaneous",
        permission: DeploymentPermissions.featureMyobClassicIntegration,
      },
      {
        title: "Export Purchases for MYOB AccountRight Classic",
        onClick: exportMyobClassicPurchases,
        icon: faFileCsv,
        tooltip:
          "Download all approved Account Sales/RCTIs as a CSV to be imported into MYOB as Purchases - Miscellaneous",
        permission: DeploymentPermissions.featureMyobClassicIntegration,
      },
    );
  } else if (
    hasPermission(
      activeDeployment,
      DeploymentPermissions.featureMyobIntegration,
    )
  ) {
    allowedCustomExports.push(
      {
        title: "Export Sales for MYOB",
        onClick: exportMyobSales,
        icon: faFileCsv,
        tooltip:
          "Download all approved Invoices as a CSV to be imported into MYOB as Sales - Miscellaneous",
        permission: DeploymentPermissions.featureMyobIntegration,
      },
      {
        title: "Export Purchases for MYOB",
        onClick: exportMyobPurchases,
        icon: faFileCsv,
        tooltip:
          "Download all approved Account Sales/RCTIs as a CSV to be imported into MYOB as Purchases - Miscellaneous",
        permission: DeploymentPermissions.featureMyobIntegration,
      },
    );
  }

  const columnDefs = commonBillingTableColumnDefs.concat(
    hasXeroIntegrationFeature ? xeroBillingTableColumnDefs : [],
    hasMyobExportsFeature ? myobBillingTableColumnDefs : [],
  );

  return (
    <Column full>
      <AgGridTable
        onRowSelectionChanged={setSelectedRows}
        columnDefs={columnDefs}
        getRowId={getRowId}
        rowSelectionId={rowSelectionId}
        rowData={rowData}
        showGlobalSearchFilter={false}
        tableName={AgGridTables.BILLING_DOCUMENTS_EXPORT}
        rowSelection="multiple"
        getContextMenuItems={getContextMenuItems}
        isRowSelectable={isRowSelectable}
        rowsSelectable
        customExport={allowedCustomExports}
      />
      {showConfirm && (
        <ConfirmExport
          rowData={selectedRows}
          toggleShowConfirm={toggleShowConfirm}
        />
      )}
    </Column>
  );
};
