import { BIDDER_REGISTRATION } from "constants/actionTypes";

import { deserialiseRegistration } from "lib/bidders";

/**
 * @typedef {Object} BidderRegistration
 * @property {string} id offline capable UUID
 * @property {string} notes
 * @property {number} registrationNumber
 * @property {string} businessId master business UUID
 * @property {string} buyerWay free text buyer way/account name
 * @property {string} defaultPropertyId deployment business property
 * @property {boolean} useBusinessAddress whether to refernce the businsses address.
 * @property {Object} provisionalData user provided registration values
 * @property {boolean} syncing always set if true
 * @property {boolean} syncError always set if true
 */

const initialState = {
  isFetching: false,
  registrations: {},
};

function bidderRegistrations(state = initialState, action) {
  switch (action.type) {
    case BIDDER_REGISTRATION.FETCH_BULK.REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };

    case BIDDER_REGISTRATION.FETCH_BULK.SUCCESS:
      const registrations = action.registrations.reduce((acc, registration) => {
        acc[registration.id] = deserialiseRegistration(registration);
        return acc;
      }, {});

      return {
        ...state,
        registrations,
        isFetching: false,
      };

    case BIDDER_REGISTRATION.FETCH_BULK.FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case BIDDER_REGISTRATION.UPDATE.REQUEST: {
      const { tempId } = action.meta;
      // If provisionalData isn't provided, make sure it exists.
      const existingRegistration = state.registrations[tempId];
      const registration = {
        provisionalData: {},
        ...existingRegistration,
        ...action.payload,
      };

      const newRegistrations = { ...state.registrations };
      newRegistrations[tempId] = {
        ...registration,
        id: tempId,
        syncing: true,
      };

      return {
        ...state,
        registrations: newRegistrations,
      };
    }
    case BIDDER_REGISTRATION.CREATE.REQUEST:
    case BIDDER_REGISTRATION.CREATE_WITHOUT_INCREMENTING_BIDDER_NUMBER
      .REQUEST: {
      const { tempId } = action.meta;
      // If provisionalData isn't provided, make sure it exists.
      const registration = {
        provisionalData: {},
        ...action.payload,
      };

      const newRegistrations = { ...state.registrations };
      delete newRegistrations[tempId];
      newRegistrations[tempId] = {
        ...registration,
        id: tempId,
        syncing: true,
      };

      return {
        ...state,
        registrations: newRegistrations,
      };
    }

    case BIDDER_REGISTRATION.DELETE.REQUEST: {
      const { id } = action.meta;
      const newRegistrations = { ...state.registrations };
      delete newRegistrations[id];
      return {
        ...state,
        registrations: newRegistrations,
      };
    }

    case BIDDER_REGISTRATION.UPDATE.FAILURE:
    case BIDDER_REGISTRATION.CREATE.FAILURE: {
      const { tempId } = action.meta;

      const newRegistrations = { ...state.registrations };
      if (newRegistrations[tempId]) {
        newRegistrations[tempId].syncing = false;
        newRegistrations[tempId].syncError = true;
      }
      return {
        ...state,
        registrations: newRegistrations,
      };
    }

    case BIDDER_REGISTRATION.CREATE_WITHOUT_INCREMENTING_BIDDER_NUMBER
      .FAILURE: {
      // this is different to the other bidder registration failure action
      // as we do not want to keep a failed bidder registration in state.

      // the backend checks if the bidder registration number exists
      // see raise_validation_on_duplicate_bidder_number because this
      // is a put. If we hit an error on the server side, remove the
      // bidder from state.
      const { tempId } = action.meta;

      const registrations = { ...state.registrations };
      if (registrations[tempId]) {
        delete registrations[tempId];
      }
      return {
        ...state,
        registrations,
      };
    }

    case BIDDER_REGISTRATION.UPDATE.SUCCESS:
    case BIDDER_REGISTRATION.CREATE.SUCCESS:
    case BIDDER_REGISTRATION.CREATE_WITHOUT_INCREMENTING_BIDDER_NUMBER
      .SUCCESS: {
      const { tempId } = action.meta;
      const registration = deserialiseRegistration(action.payload);
      const onlineId = registration.id;

      const newRegistrations = { ...state.registrations };
      if (newRegistrations[tempId]) {
        delete newRegistrations[tempId];
      }
      newRegistrations[onlineId] = registration;

      return {
        ...state,
        registrations: newRegistrations,
      };
    }
    case BIDDER_REGISTRATION.UPDATE.FROM_SOCKET:
    case BIDDER_REGISTRATION.CREATE.FROM_SOCKET: {
      const registration = deserialiseRegistration(action.payload);
      const onlineId = registration.id;

      const newRegistrations = { ...state.registrations };
      newRegistrations[onlineId] = registration;

      return {
        ...state,
        registrations: newRegistrations,
      };
    }

    default:
      return state;
  }
}

export default bidderRegistrations;
