import React from "react";

import { Grid } from "@material-ui/core";
import Big from "big.js";
import { useField } from "formik";
import { useSelector } from "react-redux";

import { SubtleBadge } from "components/Badge";
import { CollapseLabel, FormCollapse } from "components/Form";
import { withNamespace } from "components/Form/FormikControls";
import { VendorCommissionBands } from "components/Form/FormikControls/VendorCommissionBands";
import { Row } from "components/Layout";

import { VendorCommissionBandTypes } from "constants/vendorCommissionBands";

import { getSaleSubTypeById, getSpeciesById } from "selectors";

function VendorCommissionEntry(props) {
  const { entry } = props;
  const { saleSubTypeId, commissionType, commissionValue } = entry;

  const saleSubType = useSelector(getSaleSubTypeById(saleSubTypeId));
  const species = useSelector(getSpeciesById(saleSubType?.speciesId));

  const saleSubTypeText =
    saleSubTypeId === null || saleSubTypeId === undefined
      ? "Fallback Sale Sub Type"
      : `${species.name} - ${saleSubType.name}`;

  const amountText =
    commissionType === VendorCommissionBandTypes.DollarsPerHead
      ? `$${Big(commissionValue).div(100)}/Head`
      : `${Big(commissionValue).times(100)}%`;

  return `${saleSubTypeText} @ ${amountText}. `;
}

function VendorCommissionsHeader(props) {
  const { namespace: ns = "" } = props;
  const [{ value }] = useField(withNamespace(ns, "vendorCommissionBands"));
  const numberOfBands = value?.length || 0;

  const body =
    numberOfBands > 2
      ? `${numberOfBands} Rates`
      : value?.map(entry => {
          return <VendorCommissionEntry entry={entry} key={entry.id} />;
        });

  return (
    <Row>
      <div>
        <SubtleBadge>Vendor Commissions</SubtleBadge>
      </div>
      <CollapseLabel>{body}</CollapseLabel>
    </Row>
  );
}

function VendorCommissions(props) {
  const { isOpen, namespace: ns = "", onToggle } = props;

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<VendorCommissionsHeader namespace={ns} />}
      dataTour="vendorCommissionsCollapse"
    >
      <Grid item xs={12}>
        <VendorCommissionBands
          name="vendorCommissionBands"
          namespace={ns}
          toolip="Enter commission rates specific to this vendor.  These will override the defaults set for your agency."
        />
      </Grid>
      {/* AG-3741 - hiding vendor commion fields until further notice */}
      {/* <CommissionOffset namespace={ns} /> */}
    </FormCollapse>
  );
}

export default VendorCommissions;
