import { cloneDeep, merge } from "lodash";
import reduceReducers from "reduce-reducers";

import { SET_ACTIVE_ROLE } from "constants/actionTypes";
import { UNIX_START_TIME } from "constants/time";

export const reduceById = (array, fieldToAdd = {}, serializer) => {
  return array.reduce((acc, cur) => {
    acc[cur.id] = { ...cur, ...fieldToAdd };
    if (typeof serializer === "function") {
      acc[cur.id] = serializer(acc[cur.id]);
    }
    return acc;
  }, {});
};

export const createSimpleReducer =
  (name, actionType, includeOrder = true, serializer = null) =>
  (state = {}, action) => {
    switch (action.type) {
      case actionType:
        return action[name].reduce((map, item, index) => {
          if (typeof serializer === "function") {
            map[item.id] = serializer(item);
          } else {
            map[item.id] = { ...item };
          }
          if (includeOrder) {
            map[item.id].order = index + 1;
          }
          return map;
        }, {});
      default:
        return state;
    }
  };

export const defaultInitialState = {
  isFetching: false,
  byId: {},
  lastModifiedTimestamp: UNIX_START_TIME,
};

export const apiModelReducer =
  (
    name,
    actionType,
    { deserializer, includeOrder, clearOnRequest } = {
      deserializer: null,
      includeOrder: false,
      clearOnRequest: true,
    },
  ) =>
  (
    state = { ...defaultInitialState, byId: { ...defaultInitialState.byId } },
    action,
  ) => {
    switch (action.type) {
      case actionType.SUCCESS:
        return {
          ...state,
          isFetching: false,
          byId: action[name].reduce(
            (map, item, index) => {
              if (typeof deserializer === "function") {
                map[item.id] = deserializer(item);
              } else {
                map[item.id] = item;
              }
              if (includeOrder) {
                map[item.id].order = index + 1;
              }
              return map;
            },
            { ...state.byId },
          ),
        };

      case actionType.REQUEST:
        return {
          ...state,
          isFetching: true,
          byId: clearOnRequest ? {} : state.byId,
        };

      case actionType.FAILURE:
        return {
          ...state,
          isFetching: false,
          byId: {},
        };

      case actionType.FROM_SOCKET:
        const payload = { action };
        return {
          ...state,
          isFetching: false,
          byId: {
            ...state.byId,
            [payload.id]:
              typeof deserializer === "function"
                ? deserializer(payload)
                : payload,
          },
        };

      case actionType.RESET:
        return {
          ...defaultInitialState,
          byId: { ...defaultInitialState.byId },
        };

      default:
        return state;
    }
  };

export const apiModelOfflineCreateReducer =
  (
    actionType,
    { deserializer } = {
      deserializer: null,
    },
  ) =>
  (state = { ...defaultInitialState }, action) => {
    switch (action.type) {
      case actionType.CREATE_BULK.REQUEST: {
        const payload =
          typeof deserializer === "function"
            ? action.payload.map(p => deserializer(p))
            : action.payload;

        const createdData = payload.reduce((acc, cur) => {
          acc[cur.id] = {
            ...cur,
            ...state.byId[cur.id],
            syncing: true,
          };
          return acc;
        }, {});

        return {
          ...state,
          byId: {
            ...state.byId,
            ...createdData,
          },
        };
      }

      case actionType.CREATE_BULK.FROM_SOCKET:
      case actionType.CREATE_BULK.SUCCESS: {
        const { tempIds, payload } = action;
        const newState = { ...state.byId };
        tempIds.forEach(tempId => delete newState[tempId]);

        const data =
          typeof deserializer === "function"
            ? payload.map(p => deserializer(p))
            : payload;

        const createdData = data.reduce((acc, cur) => {
          acc[cur.id] = {
            ...cur,
            ...state.byId[cur.id],
            syncing: false,
          };
          return acc;
        }, {});

        return {
          ...state,
          byId: {
            ...newState,
            ...createdData,
          },
        };
      }

      case actionType.CREATE_BULK.FAILURE: {
        const { tempIds } = action;

        const newState = { ...state.byId };

        tempIds.forEach(tempId => delete newState[tempId]);

        return {
          ...state,
          byId: newState,
        };
      }

      case actionType.CREATE.REQUEST: {
        const payload =
          typeof deserializer === "function"
            ? deserializer(action.payload)
            : action.payload;

        const data = {
          ...payload,
          ...state.byId[payload.id],
          syncing: true,
        };

        return {
          ...state,
          byId: {
            ...state.byId,
            [data.id]: data,
          },
        };
      }

      case actionType.CREATE.FROM_SOCKET:
      case actionType.CREATE.SUCCESS: {
        const { tempId, payload } = action;
        const newState = { ...state.byId };
        delete newState[tempId];

        const data = {
          ...(typeof deserializer === "function"
            ? deserializer(payload)
            : payload),
          syncing: false,
        };

        return {
          ...state,
          byId: {
            ...newState,
            [data.id]: data,
          },
        };
      }

      case actionType.CREATE.FAILURE: {
        const { tempId } = action;

        const newState = { ...state.byId };

        delete newState[tempId];

        return {
          ...state,
          byId: newState,
        };
      }

      default:
        return state;
    }
  };

export const apiModelOfflineFetchReducer =
  (
    actionType,
    { deserializer, clearOnRequest } = {
      deserializer: null,
      clearOnRequest: false,
    },
  ) =>
  (state = { ...defaultInitialState }, action) => {
    switch (action.type) {
      case actionType.FETCH_BULK.REQUEST: {
        return {
          ...state,
          byId: clearOnRequest ? {} : state.byId,
          isFetching: true,
        };
      }

      case actionType.FETCH.SUCCESS: {
        // Handle fetching one, useful for fetching after update/delete failure.
        const lastModifiedTimestamp =
          action.meta?.lastModifiedTimestamp || state.lastModifiedTimestamp;
        return {
          ...state,
          isFetching: false,
          byId: {
            ...state.byId,
            ...reduceById([deserializer(action.payload)]),
          },
          lastModifiedTimestamp,
        };
      }

      case actionType.FETCH_BULK.SUCCESS: {
        const lastModifiedTimestamp =
          action.meta?.lastModifiedTimestamp || state.lastModifiedTimestamp;
        return {
          ...state,
          isFetching: false,
          byId: reduceById(action.payload.map(deserializer)),
          lastModifiedTimestamp,
        };
      }

      case actionType.FETCH_BULK.FAILURE:
      case actionType.FETCH_SOME.FAILURE:
      case actionType.FETCH_CHANGES.FAILURE: {
        return {
          ...state,
          isFetching: false,
        };
      }

      case actionType.FETCH_SOME.SUCCESS:
        return {
          ...state,
          byId: {
            ...state.byId,
            ...reduceById(action.payload.map(deserializer)),
          },
        };

      case actionType.FETCH_CHANGES.SUCCESS: {
        // Update state when fetching changes.
        const lastModifiedTimestamp =
          action.meta?.lastModifiedTimestamp || state.lastModifiedTimestamp;

        const newState =
          action.payload.length > 0
            ? action.payload.map(deserializer).reduce(
                (map, obj) => {
                  if (obj.deleted === true) {
                    delete map[obj.id];
                  } else {
                    map[obj.id] = obj;
                  }
                  return map;
                },
                { ...state.byId },
              )
            : state.byId;

        return {
          ...state,
          byId: newState,
          isFetching: false,
          lastModifiedTimestamp,
        };
      }

      case actionType.FETCH_BULK.RESET: {
        return {
          ...defaultInitialState,
          byId: { ...defaultInitialState.byId },
        };
      }

      default:
        return state;
    }
  };

export const apiModelOfflineUpdateReducer =
  (
    actionType,
    { deserializer } = {
      deserializer: null,
    },
  ) =>
  (state = { ...defaultInitialState }, action) => {
    switch (action.type) {
      case actionType.UPDATE.REQUEST: {
        const { id } = action.payload;
        const data =
          typeof deserializer === "function"
            ? deserializer(action.payload, state.byId[id] || {})
            : action.payload;
        return {
          ...state,
          byId: {
            ...state.byId,
            [id]: merge(cloneDeep(state.byId[id]), {
              ...data,
              syncing: true,
              REDUX_OFFLINE_TIMESTAMP: action.REDUX_OFFLINE_TIMESTAMP,
            }),
          },
        };
      }

      case actionType.UPDATE.SUCCESS:
      case actionType.UPDATE.FROM_SOCKET: {
        const { payload, REDUX_OFFLINE_TIMESTAMP } = action;
        // Only take most recent request success
        if (
          typeof REDUX_OFFLINE_TIMESTAMP === "number" &&
          REDUX_OFFLINE_TIMESTAMP !==
            state.byId[payload.id].REDUX_OFFLINE_TIMESTAMP
        ) {
          return state;
        }
        const data =
          typeof deserializer === "function" ? deserializer(payload) : payload;

        return {
          ...state,
          byId: {
            ...state.byId,
            [data.id]: data,
          },
        };
      }

      case actionType.UPDATE.FAILURE: {
        // TBD, probably nothing actionable here, need to refetch in a saga.

        return state;
      }

      default:
        return state;
    }
  };

export const apiModelOfflineDeleteReducer =
  actionType =>
  (state = { ...defaultInitialState }, action) => {
    switch (action.type) {
      case actionType.DELETE.REQUEST:
      case actionType.DELETE.FROM_SOCKET: {
        const { id } = action;
        const newState = { ...state.byId };
        delete newState[id];

        return {
          ...state,
          byId: newState,
        };
      }

      // case actionType.DELETE.SUCCESS: {
      // // Nothing to do here

      //   return state;
      // }

      // case actionType.DELETE.FAILURE: {
      //   // Refetch in a saga.
      //   return state;
      // }

      default:
        return state;
    }
  };

export const apiModelOfflineCommentReducer =
  actionType =>
  (state = { ...defaultInitialState }, action) => {
    switch (action.type) {
      case actionType.COMMENT.REQUEST:
        const { payload, id } = action;
        return {
          ...state,
          byId: {
            ...state.byId,
            [id]: {
              ...state.byId[id],
              commentIds: [...state.byId[id].commentIds, payload.id],
            },
          },
        };

      default:
        return state;
    }
  };

export const bulkUpdateCases = (
  state,
  action,
  key,
  deserializer,
  defaults = {},
) => {
  const { payload } = action;
  const syncing = action.type.includes("REQUEST");
  const updatedState = payload.reduce(
    (acc, cur) => {
      acc[cur.id] = {
        ...defaults,
        ...state[key][cur.id],
        ...deserializer(cur, { syncing }),
      };
      return acc;
    },
    { ...state[key] },
  );

  return {
    ...state,
    [key]: updatedState,
  };
};

export const resetStateOnRoleChangeReducer = (state, action) => {
  switch (action.type) {
    case SET_ACTIVE_ROLE.REQUEST: {
      return defaultInitialState;
    }
    default:
      return state;
  }
};

export const offlineCrudReducer = (actionType, props) => {
  const createReducer = apiModelOfflineCreateReducer(actionType, props);
  const requestReducer = apiModelOfflineFetchReducer(actionType, props);
  const updateReducer = apiModelOfflineUpdateReducer(actionType, props);
  const deleteReducer = apiModelOfflineDeleteReducer(actionType);

  return reduceReducers(
    createReducer,
    requestReducer,
    updateReducer,
    deleteReducer,
  );
};

export const passThroughDeserializer = data => data;
