import React, { memo } from "react";

import { useSelector } from "react-redux";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { QuickCreateLivestockSaleLotPanel } from "components/AgGrid/panels";
import {
  saleLotCommentCountColumnRenderer,
  saleLotCommentsColumnId,
} from "components/SaleLotsTable/columns/commentsColumn";

import {
  ACTION_COLUMN_NAME,
  AgGridPanels,
  AgGridTables,
} from "constants/aggrid";
import { CommentTypes } from "constants/comments";
import { SaleyardPermissions } from "constants/permissions";

import {
  getters,
  formatters,
  createNamespacedColumnDefinitions,
} from "lib/agGrid";
import { BaseColumnDefinitions } from "lib/agGrid/columnDefinitions";
import { BusinessRenderer } from "lib/agGrid/columns/shortCode";
import { PrivateSaleLotVendorSplitGetter } from "lib/agGrid/columns/vendorSplit";
import { openCommentsModal } from "lib/navigation";

import {
  currentSaleSelector,
  getActiveLivestockAgentDeployment,
  getCurrentSaleyard,
  selectPrivateSalesSaleLotsData,
  selectVendorSplitByKeyLookup,
} from "selectors";

import { useDebounceSelector, useHasPermission } from "hooks";

import { actionsColumnRenderer } from "./ActionColumn";

const SaleLotsColumn = createNamespacedColumnDefinitions(
  BaseColumnDefinitions,
  "saleLot",
);

const SaleLotColIds = {
  NOTE: "note",
  XERO_BUYER: "xeroBuyer",
  XERO_BUYER_NAME: "xeroBuyer.name",
  XERO_VENDOR: "xeroVendor",
  XERO_VENDOR_NAME: "xeroVendor.name",
};

const columnDefs = [
  {
    headerName: "Vendor",
    children: [
      {
        field: "vendor.name",
        headerName: "Name",
      },
      {
        field: "vendor.shortCode",
        headerName: "Code",
        cellRenderer: BusinessRenderer,
        cellRendererParams: {
          businessKey: "vendor.id",
          complainIfMissing: true,
        },
      },
      {
        field: "vendorProperty.PIC",
        headerName: "PIC",
      },
      {
        field: "consignment.NVD",
        headerName: "NVD",
      },
      {
        colId: "727ca183-79c2-40f0-8e2b-057096e2eaf7",
        headerName: "Splits",
        valueGetter: PrivateSaleLotVendorSplitGetter,
        width: 100,
      },
    ],
  },
  {
    headerName: "Buyer",
    children: [
      {
        field: "buyer.name",
        headerName: "Name",
      },
      {
        field: "buyer.shortCode",
        headerName: "Code",
        cellRenderer: BusinessRenderer,
        cellRendererParams: {
          businessKey: "buyer.id",
          complainIfMissing: true,
        },
      },
      {
        field: "buyerProperty.PIC",
        headerName: "PIC",
      },
    ],
  },
  {
    field: "saleLot.total_mass_grams",
    headerName: "Total Weight (kg)",
    type: "numericColumn",
    valueFormatter: formatters.kilogramsFormatter,
    valueGetter: getters.kilogramsGetter,
  },
  {
    field: "saleLot.averageWeightGrams",
    headerName: "Average Weight (kg)",
    type: "numericColumn",
    valueFormatter: formatters.kilogramsFormatter,
    valueGetter: getters.kilogramsGetter,
  },
  {
    field: "saleLot",
    colId: "unitPrice",
    filter: "agNumberColumnFilter",
    headerValueGetter: getters.unitPriceHeader,
    type: "numericColumn",
    valueFormatter: formatters.unitPriceFormatter,
    valueGetter: getters.unitPriceGetter,
  },
  {
    field: "saleLot.draftingAttributes.skinPriceCents",
    headerName: "Skin Price",
    type: "numericColumn",
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
  },
  {
    field: "livestockSale.sale_title",
    headerName: "Sale Name",
  },
  {
    field: "livestockSale.date",
    filter: "agDateColumnFilter",
    headerName: "Sale Date",
    valueFormatter: formatters.dateFormatter,
    valueGetter: getters.dateGetter,
  },
  {
    field: "saleLot.quantity",
    headerName: "Headcount",
    type: "numericColumn",
  },
  {
    field: "saleLot.lot_number",
    headerName: "Lot Number",
  },

  {
    headerName: "Carrier Information",
    children: [
      {
        field: "carrier.name",
        headername: "Name",
      },
      {
        field: "consignment.carrierCharge.invoiceNumber",
        headerName: "Invoice #",
      },
      {
        field: "consignment.carrierCharge.deliveryDate",
        headerName: "Delivery Date",
      },
      {
        field: "consignment.carrierCharge.invoiceAmount",
        headerName: "Invoice Amount",
        valueGetter: getters.dollarsGetter,
        valueFormatter: formatters.dollarsFormatter,
        type: "numericColumn",
      },
      {
        field: "consignment.carrierCharge.deliveryNotes",
        headerName: "Delivery Notes",
      },
    ],
  },
  {
    field: "age.name",
    headerName: "Age",
  },
  {
    field: "sex.name",
    headerName: "Sex",
  },
  {
    field: "breed.name",
    headerName: "Breed",
  },
  { field: "scanCount", headerName: "EIDs" },
  {
    field: "scanPercent",
    headerName: "Act. Level",
  },
  SaleLotsColumn[SaleLotColIds.NOTE],
  SaleLotsColumn[SaleLotColIds.XERO_VENDOR],
  SaleLotsColumn[SaleLotColIds.XERO_VENDOR_NAME],
  SaleLotsColumn[SaleLotColIds.XERO_BUYER],
  SaleLotsColumn[SaleLotColIds.XERO_BUYER_NAME],
  {
    field: "saleLot",
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: actionsColumnRenderer,
    pinned: "right",
    menuTabs: ["columnsMenuTab"],
  },
  {
    colId: saleLotCommentsColumnId,
    field: "unresolvedSaleLotCommentCount",
    headerName: "Comments",
    width: 86,
    cellRenderer: saleLotCommentCountColumnRenderer,
    pinned: "right",
    menuTabs: [],
    filter: "agNumberColumnFilter",
  },
];

const getRowId = params => params.data.saleLot.id;

function P2PSaleLotsTableComponent() {
  const rowData = useDebounceSelector(selectPrivateSalesSaleLotsData, 500);
  const deployment = useSelector(getActiveLivestockAgentDeployment);
  const vendorSplitByKeyLookup = useSelector(selectVendorSplitByKeyLookup);

  const sale = useSelector(currentSaleSelector);
  const { date: saleDate, sale_title: saleTitle } = sale;

  const handleClickSaleLotComments = ({ saleLot }) =>
    openCommentsModal(CommentTypes.SALE_LOT, saleLot.id, window.location.hash);

  const P2PContext = {
    deployment,
    handleClickSaleLotComments,
    vendorSplitByKeyLookup,
  };

  const allowLivestockQuickCreate = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.featureQuickCreateLivestock,
  );

  const panels = React.useMemo(() => {
    return [
      allowLivestockQuickCreate && {
        id: AgGridPanels.QUICK_CREATE,
        labelDefault: "Quick Create",
        iconKey: "save",
        toolPanel: QuickCreateLivestockSaleLotPanel,
        width: 600,
      },
    ].filter(Boolean);
  }, [allowLivestockQuickCreate]);

  return (
    <AgGridTable
      downloadFilename={`${saleDate}-${saleTitle}-SaleLotReport.csv`}
      columnDefs={columnDefs}
      getRowId={getRowId}
      context={P2PContext}
      rowData={rowData}
      tableName={AgGridTables.PRIVATE_SALE_SALELOT}
      panels={panels}
      defaultToolPanel={AgGridPanels.QUICK_CREATE}
    />
  );
}

P2PSaleLotsTableComponent.propTypes = {};

export default memo(P2PSaleLotsTableComponent);
