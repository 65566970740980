import { invert } from "lodash";
import flatten from "lodash/flatten";

import {
  BUYER,
  DEPLOYMENT,
  LIVESTOCK_AGENT,
  TRANSPORTER,
  VENDOR,
} from "constants/businesses";

import { AgGridTables } from "./aggrid";

export const Settings = {
  addScansToSaleLotButton: "addScansToSaleLotButton",
  agencyId: "agencyId",
  allSalesLoaded: "allSalesLoaded",
  auctionScreenMode: "auctionScreenMode",
  billingRunId: "billingRunId",
  billingRuns: "billingRunId",
  businessPICSelectorAttrFilters: {
    [VENDOR]: "businessPICSelectorVendor",
    [TRANSPORTER]: "businessPICSelectorTransporter",
    [BUYER]: "businessPICSelectorBuyer",
    [LIVESTOCK_AGENT]: "businessPICSelectorLivestockAgent",
    [DEPLOYMENT]: "businessPICSelectorDeployment",
  },
  buyersOnlyAuctionScreen: "buyersOnlyAuctionScreen",
  consignmentAttachmentTypeSelection: "consignmentAttachmentTypeSelection",
  consignmentGroupBy: "consignmentGroupBy",
  failedRequestModalDismissedUntil: "failedRequestModalDismissedUntil",
  fullScreen: "fullScreen",
  globalSearch: "globalSearch",
  saleSearch: "saleSearch",
  showQuickSellDrafting: "showQuickSellDrafting",
  showDeduplicatedWeighLotScans: "showDeduplicatedWeighLotScans",
  isAutoSelectLotEnabled: "isAutoSelectLotEnabled",
  isFailedRequestsModalShowing: "isFailedRequestsModalShowing",
  lastAddedConsignmentId: "lastAddedConsignmentId",
  markColor: "markColor",
  penningMode: "penningMode",
  penView: "penView",
  preferredScanningMode: "preferredScanningMode",
  scanPenOrdering: "scanPenOrdering",
  round: "round",
  savedScanLotRoundId: "savedScanLotRoundId",
  savedScanLotDeploymentId: "savedScanLotDeploymentId",
  saleLotAttachmentTypeSelection: "saleLotAttachmentTypeSelection",
  saleWatcherDraftingInformationId: "saleWatcherDraftingInformationId",
  saveWeightDefaultAction: "saveWeightDefaultAction",
  showConsignmentStepper: "showConsignmentStepper",
  speciesId: "speciesId",
  stickySale: "stickySale",
  watcherViewMode: "watcherViewMode",
  weighLotScaleName: "weighLotScaleName",
  studWatcher: "studWatcher",
  tableViews: "tableViews",
  saleProgress: "saleProgress",
  penScanningGroupType: "penScanningGroupType",
  resolveAlternatives: {
    filterByLastReviewed: "resolveAlternatives.filterByLastReviewed",
    filterByLastModified: "resolveAlternatives.filterByLastModified",
    filterEmptyValues: "resolveAlternatives.filterEmptyValues",
    filterByRoleInSale: "resolveAlternatives.filterByRoleInSale",
    filterByAlternativeSource: "resolveAlternatives.filterByAlternativeSource",
    filteredFields: "resolveAlternatives.filteredFields",
  },
  livestockQuickCreateValues: "livestockQuickCreateValues",
  storedSaleLotFormData: "storedSaleLotFormData",
  mtHostSettings: "mtHostSettings",
  useQuickBuyerInput: "useQuickBuyerInput",
  ...invert(AgGridTables),
};

export const allowedSettings = [
  ...Object.keys(Settings),
  ...flatten(
    Object.values(Settings)
      .filter(v => typeof v === "object")
      .map(s => Object.values(s)),
  ),
];

// Finance Settings

export const HeaderLayoutOptions = [
  // Corresponds to agrinous.constants.py:HeaderLayoutOptions
  {
    label: "Simple Agency 1",
    value: "Simple Agency 1",
    description:
      "A basic agency template with Addressee, Logo and Document Information, in that order.",
  },
  {
    label: "Simple Agency 2",
    value: "Simple Agency 2",
    description:
      "A basic agency template with Document Information, Addressee and Logo, in that order.",
  },
  {
    label: "Simple Agency 3",
    value: "Simple Agency 3",
    description:
      "A basic agency template with Logo, Addressee and Document Information, in that order.",
  },
  {
    label: "Simple Agency 4",
    value: "Simple Agency 4",
    description:
      "A basic agency template with To business, Document Information, Logo, From business, in that order.",
  },
  {
    label: "Envelope",
    value: "Envelope",
    description: "A header template that can folded to fit in a DL Envelope.",
  },
  {
    label: "Envelope V 2",
    value: "Envelope V 2",
    description:
      "A second version header template that can folded to fit in a DL Envelope.",
  },
];

export const BaseFooter = {
  label: "Base Footer",
  value: "BASE",
  description: "A default footer that contains general information.",
};

export const BaseFooterV2 = {
  label: "Base Footer V 2",
  value: "BASE_V_2",
  description: "A grey agrinous logo footer with payment information",
};

export const RemittanceFooter = {
  label: "Remittance Footer",
  value: "REMITTANCE",
  description:
    "A footer that contains a tear off section with payment instructions.",
};

export const RemittanceFooterwithPayment = {
  label: "Remittance Footer with Payment",
  value: "REMITTANCE_WITH_PAYMENT",
  description:
    "A footer that contains a tear off section with payment instructions and payment recording.",
};

export const InvoiceFooterOptions = [
  BaseFooter,
  BaseFooterV2,
  RemittanceFooter,
  RemittanceFooterwithPayment,
];

export const RCTIFooterOptions = [BaseFooter, BaseFooterV2, RemittanceFooter];

export const FinanceSubModalSection = {
  PAYMENTS: "PAYMENTS",
  RCTI: "RCTI",
  TAX_INVOICE: "TAX_INVOICE",
  INSURANCE: "INSURANCE",
  INTEREST: "INTEREST",
  RETAINED_PROCEEDS: "RETAINED_PROCEEDS",
  TRADING_TERMS: "TRADING_TERMS",
  VENDOR_COMMISSION: "VENDOR_COMMISSION",
};
