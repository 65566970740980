import React, { memo, useCallback, useContext } from "react";

import { sum } from "lodash/math";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addSaleLotWithPens, patchSaleLot } from "actions";

import { SecondaryButton } from "components/Form";
import { Column, Row } from "components/Layout";
import InlineCreateSaleLotForm from "components/SaleLotForms/InlineCreateSaleLotForm";

import {
  ConsignmentPermissions,
  SaleLotPermissions,
} from "constants/permissions";
import { SplitType } from "constants/saleLots";

import { calculateTotalPriceCents } from "lib";

import { openSplitSaleLotModal } from "lib/navigation";

import {
  getConsignmentById,
  getSaleLotById,
  getSaleLots,
  getVendorIdBySaleLotId,
  getVendorPropertyIdBySaleLotId,
  selectAgencyBusinessByBusinessId,
  selectAgencyIdByConsignmentIdLookup,
} from "selectors";

import { useHasPermission } from "hooks/useHasPermission";

import {
  WeighBridgeSaleDispatchContext,
  WeighBridgeSaleStateContext,
} from "./WeighBridgeSaleContext";

export function useWeighBridgeSaleLotUpdater() {
  const {
    accumulatedWeights,
    saleLot: saleLotValues,
    sellingDetails,
    isSellingDetailsDirty,
  } = useContext(WeighBridgeSaleStateContext);

  const {
    updateRecentlyWeighedSaleLotIds,
    updateSelectedSaleLotId,
    clearSaleLotFormDirty,
  } = useContext(WeighBridgeSaleDispatchContext);
  const dispatch = useDispatch();
  const saleLots = useSelector(getSaleLots);
  const agencyBusinessLookup = useSelector(selectAgencyBusinessByBusinessId);
  const agencyIdByConsignmentIdLookup = useSelector(
    selectAgencyIdByConsignmentIdLookup,
  );

  return useCallback(
    (
      saleLotId,
      autoSelect = true,
      updateRecent = true,
      updateWeight = false,
      scrollToTopOf = "#current-lot-details-layout",
    ) => {
      // TODO submission should be done via a saga, chaining and waiting on other add/patchSaleLot, add/patchAuctionPen, then the saga and all of the validation can be reused
      let updatedSaleLotId = saleLotId;

      const saleLotPayload = {
        ...saleLotValues,
        buyer_id:
          sellingDetails.buyerId === undefined
            ? saleLotValues?.buyer_id
            : sellingDetails.buyerId,
        destination_property_id:
          sellingDetails.propertyId === undefined
            ? saleLotValues?.destination_property_id
            : sellingDetails.propertyId,
        pricing_type_id:
          sellingDetails.pricingTypeId === undefined
            ? saleLotValues?.pricing_type_id
            : sellingDetails.pricingTypeId,
      };

      const saleLot = saleLots[updatedSaleLotId];
      const agencyId =
        agencyIdByConsignmentIdLookup[saleLotPayload.consignment_id] ||
        agencyIdByConsignmentIdLookup[saleLot?.consignment_id];

      // Check if the user has provided any auction pen information.
      // When any property is set other than start_pen, validation rules set start_pen as mandatory
      // Meaning we only need to test for the presence of start_pen for all possible combinations

      let totalMassGrams = saleLot?.total_mass_grams;
      if (updateWeight) {
        totalMassGrams = sum(accumulatedWeights);
        saleLotPayload.total_mass_grams = totalMassGrams;
        saleLotPayload.time_weighed = new Date().toISOString();
      }

      if (
        // When the user has entered a new price (or pricing type)
        (isSellingDetailsDirty && sellingDetails.price !== undefined) ||
        // or when the sale lot already has a price
        (saleLot && saleLot.total_price_cents !== undefined)
      ) {
        // be sure to update the total price with the latest price, taking in to the account the pending weight
        saleLotPayload.total_price_cents = calculateTotalPriceCents({
          unitPrice: parseFloat(sellingDetails.price) || 0,
          quantity:
            saleLotPayload.quantity === undefined
              ? saleLot.quantity
              : saleLotPayload.quantity,
          pricing_type_id: sellingDetails.pricingTypeId,
          total_mass_grams: totalMassGrams,
        });
      }

      // default behaviour is not to change buyer way, by inheriting existing values from `saleLotPayload`
      if (sellingDetails.buyerWayId === null) {
        // handle case where buyer way is removed
        saleLotPayload.buyer_way = null;
      } else if (sellingDetails.buyerWayId !== undefined) {
        const buyer = agencyBusinessLookup[saleLotPayload.buyer_id][agencyId];
        const buyerWay = buyer.buyerWays.find(
          ({ id }) => id === sellingDetails.buyerWayId,
        );
        // handle case where buyer way in added/changed values
        saleLotPayload.buyer_way = {
          ...buyerWay,
        };
      }

      if (updatedSaleLotId === null) {
        // Handle creating a new sale lot
        // Capture a temporary lot id
        updatedSaleLotId = uuidv4();
        // Create the new Sale Lot with the values from the form
        dispatch(addSaleLotWithPens(updatedSaleLotId, saleLotPayload));
      } else {
        dispatch(
          patchSaleLot(
            { id: saleLot.id, ...saleLotPayload },
            { changeReason: "Updated from weighbridge" },
          ),
        );
      }

      autoSelect && updateSelectedSaleLotId(updatedSaleLotId);

      updateRecent && updateRecentlyWeighedSaleLotIds(updatedSaleLotId);

      clearSaleLotFormDirty();

      if (scrollToTopOf) {
        // If asked (which we are by default) scroll to the top of the weighbridge form.
        // Note - behaviour: "smooth" is touted as an option to scrollTo, but, does not work and stops the function
        // from working!  So - don't add that later.
        document.querySelector(scrollToTopOf).scrollTo({ top: 0 });
      }

      return updatedSaleLotId;
    },
    [
      accumulatedWeights,
      agencyBusinessLookup,
      dispatch,
      isSellingDetailsDirty,
      saleLots,
      saleLotValues,
      sellingDetails,
      updateRecentlyWeighedSaleLotIds,
      updateSelectedSaleLotId,
      agencyIdByConsignmentIdLookup,
      clearSaleLotFormDirty,
    ],
  );
}

function FormControlsComponent(props) {
  const {
    isSaleLotDirty,
    isSaleLotValid,
    isSellingDetailsDirty,
    onSaveSaleLot,
    selectedSaleLotId,
    onSetNoSale,
    selectedConsignmentId,
  } = props;

  function onClickSaveSaleLot() {
    onSaveSaleLot(selectedSaleLotId);
  }

  function onClickSplitSaleLot() {
    openSplitSaleLotModal(selectedSaleLotId, SplitType.STANDARD);
  }
  function onClickNoSale() {
    onSetNoSale();
  }

  let saveTitle = "Save changes to Sale Lot";
  if (!isSaleLotDirty && !isSellingDetailsDirty) {
    saveTitle = "You must make changes before you can save";
  } else if (!isSaleLotValid && isSaleLotDirty) {
    saveTitle = "There are errors with the entered values";
  }

  // disabled when Sale Lot is not valid (unless it's not valid due to there being no changes)
  // TODO - this does not validate un-created lots, and there is a bug causing valid lots to not be editable.
  // Address this when touching AG-3087 - we may not even have an update form here.

  const hasChangeSaleLotPermission = useHasPermission(
    getSaleLotById(selectedSaleLotId),
    SaleLotPermissions.update,
  );

  const hasAddSaleLotPermission = useHasPermission(
    getConsignmentById(selectedConsignmentId),
    ConsignmentPermissions.canAddSaleLot,
  );

  const hasSaleLotPermission = selectedSaleLotId
    ? hasChangeSaleLotPermission
    : hasAddSaleLotPermission;

  const formHasChanged = isSaleLotDirty || isSellingDetailsDirty;

  const areAllFormPartsValid = isSaleLotDirty
    ? // use the existing validity flag when the sale lot is dirty
      isSaleLotValid
    : // Assume the selling Details are always valid
      // when edited and the lot has not been edited.
      !!isSellingDetailsDirty;

  const isSaveDisabled = !(
    hasSaleLotPermission &&
    formHasChanged &&
    areAllFormPartsValid
  );

  const canPerformSaleLotActions =
    hasChangeSaleLotPermission && Boolean(selectedSaleLotId);

  return (
    <Row justifyEnd>
      <div>
        <SecondaryButton
          disabled={!canPerformSaleLotActions}
          title="Split Sale Lot"
          onClick={onClickSplitSaleLot}
        >
          Split Sale Lot
        </SecondaryButton>
      </div>
      <div>
        <SecondaryButton
          disabled={!canPerformSaleLotActions}
          title="No Sale"
          onClick={onClickNoSale}
        >
          No Sale
        </SecondaryButton>
      </div>

      <div>
        <SecondaryButton
          disabled={isSaveDisabled}
          title={saveTitle}
          data-tour={
            selectedSaleLotId === null ? "createSaleLot" : "updateSaleLot"
          }
          onClick={onClickSaveSaleLot}
        >
          {selectedSaleLotId === null ? "Create" : "Update"} Sale Lot
        </SecondaryButton>
      </div>
    </Row>
  );
}

const FormControls = memo(FormControlsComponent);

function WeighBridgeCurrentLotFormComponent() {
  const {
    key,
    isSaleLotDirty,
    isSaleLotValid,
    isSellingDetailsDirty,
    selectedSaleLotId,
    saleLot,
  } = useContext(WeighBridgeSaleStateContext);

  const vendorId = useSelector(getVendorIdBySaleLotId(selectedSaleLotId));
  const vendorPropertyId =
    useSelector(getVendorPropertyIdBySaleLotId(selectedSaleLotId)) || null;

  const createOrUpdateSaleLot = useWeighBridgeSaleLotUpdater();

  const { updateSaleLotSellingDetails } = useContext(
    WeighBridgeSaleDispatchContext,
  );

  // TODO: move to useCallback
  const onSetNoSale = () => {
    updateSaleLotSellingDetails({
      price: 0,
      buyerId: vendorId,
      propertyId: vendorPropertyId,
      buyerWayId: null,
    });
  };

  return (
    <Column padding={2}>
      <FormControls
        isSaleLotDirty={isSaleLotDirty}
        isSaleLotValid={isSaleLotValid}
        isSellingDetailsDirty={isSellingDetailsDirty}
        onSaveSaleLot={createOrUpdateSaleLot}
        selectedSaleLotId={selectedSaleLotId}
        onSetNoSale={onSetNoSale}
        selectedConsignmentId={saleLot?.consignment_id}
      />
      <InlineCreateSaleLotForm
        key={key}
        saleLotId={selectedSaleLotId}
        showAuctionPenAtTop
        ignoreHeadCountForVendorSearch
      />
      <FormControls
        isSaleLotDirty={isSaleLotDirty}
        isSaleLotValid={isSaleLotValid}
        isSellingDetailsDirty={isSellingDetailsDirty}
        onSaveSaleLot={createOrUpdateSaleLot}
        selectedSaleLotId={selectedSaleLotId}
        selectedConsignmentId={saleLot?.consignment_id}
      />
    </Column>
  );
}

export default memo(WeighBridgeCurrentLotFormComponent);
