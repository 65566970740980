import React from "react";

import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { sum } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
  bulkCreateAutoSellPossessions,
  requestAllSellFiles,
  requestAllSellReversals,
  requestScansChanges,
  reverseCorrectableSells,
} from "actions";

import { AgGridPreview } from "components/AgGrid/AgGridPreview";
import TableWrapper, {
  CollapseTableWrapper,
} from "components/AgGrid/TableWrapper";
import { SlimButton } from "components/Button";
import { Action, ActionIcon } from "components/Button/Actions";
import { Button } from "components/Form";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { AgGridTables } from "constants/aggrid";
import { Column as AgGridColumn } from "constants/columns";
import { ApiModel } from "constants/loading";
import { NLISFileTypes, TRANSFER_STATUS } from "constants/nlis";

import { RollBackAlert } from "containers/NLISFiles/RollbackAlert";

import { withNestedField } from "lib/agGrid";
import { BaseColumnDefinitions } from "lib/agGrid/columnDefinitions";
import { pluralize } from "lib/pluralize";

import {
  currentSaleSelector,
  getSaleLots,
  getSellableScans,
  getSellCorrectableScans,
  selectSellableSaleLotIdsByBuyerHashLookup,
  selectTransferOutTransactions,
} from "selectors";

import { getIsFetchingNLISInformation } from "selectors/loading";

import { useMountEffect, useToggle } from "hooks";

import { NLISFileGrid } from "./NLISFileGrid";

const columnState = [
  {
    colId: "transferType",
  },
  {
    colId: "transactionId",
  },
  {
    colId: "status",
  },
  {
    colId: "eidStatus",
  },
  { colId: "vendorDisplay", hide: true },
  { colId: "buyerDisplay" },
  {
    colId: "created",
  },
  {
    colId: "submitted",
  },
  {
    colId: "processed",
  },
  {
    colId: "relatedTransaction",
  },
  {
    colId: "actions",
  },
];

const confirmationColumnDefs = [
  {
    headerName: "EID",
    field: "scan.EID",
    minWidth: 80,
  },
  withNestedField(AgGridColumn.PEN, "saleLot"),
  withNestedField(AgGridColumn.BUYER_NAME, "saleLot"),
  withNestedField(AgGridColumn.BUYER_WAY_NAME, "saleLot"),
  withNestedField(AgGridColumn.DESTINATION_PIC, "saleLot"),
  BaseColumnDefinitions[AgGridColumn.NVD],
  BaseColumnDefinitions[AgGridColumn.VENDOR_PIC],
  {
    field: "nlisSaleyardCredentials.nlis_saleyard_id",
    headerName: "Saleyard PIC",
  },
];

const ConfirmTransfer = ({ toggleShowConfirm }) => {
  const dispatch = useDispatch();

  const sellableScans = useSelector(getSellableScans);

  const handleTransfer = () => {
    dispatch(
      bulkCreateAutoSellPossessions(
        sellableScans.map(scanRow => scanRow.scan.EID),
      ),
    );
    toggleShowConfirm();
  };

  return (
    <Dialog open onClose={toggleShowConfirm} maxWidth="lg" fullWidth>
      <DialogTitle onClose={toggleShowConfirm}>
        Are you sure you wish to Sell the following EIDs
      </DialogTitle>
      <DialogContent>
        <CollapseTableWrapper>
          <AgGridPreview
            rowData={sellableScans}
            columnDefs={confirmationColumnDefs}
            tableName={AgGridTables.NLIS_SELL_PREVIEW}
          />
        </CollapseTableWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleShowConfirm}>Cancel</Button>
        <Button data-tour="submit" onClick={handleTransfer}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NLISSellFileInfo = React.memo(() => {
  const dispatch = useDispatch();
  const correctableScans = useSelector(getSellCorrectableScans);

  const sellableSaleLotIdsByBuyerHashLookup = useSelector(
    selectSellableSaleLotIdsByBuyerHashLookup,
  );
  const sale = useSelector(currentSaleSelector);
  const isFetching = useSelector(getIsFetchingNLISInformation);
  const saleLots = useSelector(getSaleLots);
  const rowData = useSelector(selectTransferOutTransactions);

  const handleRefresh = React.useCallback(() => {
    if (!isFetching) {
      dispatch(requestAllSellReversals(sale));
      dispatch(requestAllSellFiles(sale));
      dispatch(requestScansChanges(sale));
    }
  }, [isFetching, sale, dispatch]);

  useMountEffect(() => {
    // If we have no rows on mount, refresh from the backend.
    // (If we run this when rowData changes, it will continually refresh if there is actually no data.)
    if (rowData.length === 0) {
      handleRefresh();
    }
  });

  const saleLotCount = Object.keys(saleLots).length;

  const numBuyers = Object.keys(sellableSaleLotIdsByBuyerHashLookup).length;
  const sellableLotCount = sum(
    Object.values(sellableSaleLotIdsByBuyerHashLookup).map(
      saleLotIds => saleLotIds.length,
    ),
  );
  const [showConfirm, toggleShowConfirm] = useToggle(false);

  const sellableScanCount = useSelector(getSellableScans).length;

  const transferText = `You currently have ${sellableScanCount} Sellable ${pluralize(
    "EID",
    sellableScanCount,
  )} in ${sellableLotCount} (of ${saleLotCount}) Sale ${pluralize(
    "Lot",
    sellableLotCount,
  )} for ${numBuyers} ${pluralize("Buyer", numBuyers)}.`;

  // If there are any unsubmitted transactions, don't allow the user to action any more.
  const hasUnsubmittedTransfers = rowData.some(
    nlisTransaction => nlisTransaction.status === TRANSFER_STATUS.UNSUBMITTED,
  );
  const hasActionable = sellableLotCount > 0 && sellableScanCount > 0;
  const showAction = hasActionable && !hasUnsubmittedTransfers;
  const hasCorrectable = correctableScans.length > 0;
  const showRollback = hasCorrectable && !hasUnsubmittedTransfers;

  const reverseAll = () => {
    dispatch(reverseCorrectableSells(sale));
  };

  const tooltipText = hasUnsubmittedTransfers
    ? "Transfer in progress, please wait"
    : isFetching
      ? "Refreshing data, please wait"
      : "Transfer Sellable EIDs";
  const AlertAction = (
    <Tooltip title={tooltipText}>
      <SlimButton
        data-tour="transferNow"
        onClick={toggleShowConfirm}
        disabled={hasUnsubmittedTransfers || isFetching}
      >
        Transfer Now
      </SlimButton>
    </Tooltip>
  );

  return (
    <WaitForSync
      requiredData={[
        ApiModel.NLIS_SELL_FILES,
        ApiModel.NLIS_SELL_REVERSALS,
        ApiModel.SCANS,
      ]}
    >
      <Row flexGrow justifyBetween>
        <Column fullWidth padding={2}>
          {showRollback && (
            <RollBackAlert
              correctableTransfers={correctableScans}
              disableAction={hasUnsubmittedTransfers || isFetching}
              handleCorrection={reverseAll}
              nlisFileType={NLISFileTypes.NLISSellFile}
            />
          )}

          {showAction && (
            <Grid item xs={12}>
              <Alert severity="error" action={AlertAction}>
                {transferText}
              </Alert>
            </Grid>
          )}

          {!hasCorrectable && !hasActionable && (
            <Grid item xs={12}>
              <Alert severity="success">
                All available (taken) sale lots have been transferred.
              </Alert>
            </Grid>
          )}
        </Column>
        <Action
          data-tour="refresh"
          onClick={handleRefresh}
          disabled={isFetching}
        >
          <ActionIcon icon={faRedo} />
          Refresh
        </Action>
      </Row>

      <Column fullHeight>
        <TableWrapper>
          <NLISFileGrid rowData={rowData} columnState={columnState} />
        </TableWrapper>
      </Column>
      {showConfirm && <ConfirmTransfer toggleShowConfirm={toggleShowConfirm} />}
    </WaitForSync>
  );
});
