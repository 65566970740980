import React from "react";

import { Formik, useFormikContext } from "formik";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { BottomSticky, Button, SecondaryButton } from "components/Form";
import { SelectField } from "components/Form/FormikControls";

import { getConsignmentOptionsByDeploymentSaleId } from "selectors";

import {
  CornerCloseButton,
  GridRow,
  QuestionnaireRow,
  StyledForm,
} from "./Components";

const validationSchema = Yup.object().shape({
  consignment: Yup.string()
    .nullable()
    .required("Please select a consignment")
    .test("valid", "Please select a consignment", val => val !== "-1"),
});

export const ConsignmentAttachmentForm = ({
  deploymentSaleId,
  selectedAttachment,
  updateAttachment,
  getNextAttachment,
  documentType,
  DocumentSelector,
  handleGoBack,
}) => {
  const consignmentOptions = useSelector(
    getConsignmentOptionsByDeploymentSaleId(deploymentSaleId),
  );

  const initialValues = {
    consignment: selectedAttachment?.consignment,
  };

  const onSubmit = values => {
    updateAttachment(
      {
        ...selectedAttachment,
        consignment: values.consignment,
        type: documentType,
      },
      null,
    );
    getNextAttachment();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <FormComponent
        handleGoBack={handleGoBack}
        DocumentSelector={DocumentSelector}
        consignmentOptions={consignmentOptions}
      />
    </Formik>
  );
};

const FormComponent = ({
  handleGoBack,
  DocumentSelector,
  consignmentOptions,
}) => {
  const formikProps = useFormikContext();
  const submitDisabled =
    Object.keys(formikProps.touched).length > 0 && !formikProps.isValid;

  return (
    <StyledForm>
      <CornerCloseButton handleClose={handleGoBack} />
      <QuestionnaireRow>
        <DocumentSelector />
      </QuestionnaireRow>
      <QuestionnaireRow>
        <SelectField
          name="consignment"
          options={consignmentOptions}
          label="Owner / Vendor"
        />
      </QuestionnaireRow>
      <BottomSticky>
        <GridRow>
          <SecondaryButton onClick={handleGoBack} type="button">
            Back
          </SecondaryButton>
          <Button type="submit" disabled={submitDisabled}>
            Submit
          </Button>
        </GridRow>
      </BottomSticky>
    </StyledForm>
  );
};

ConsignmentAttachmentForm.propTypes = {
  documentType: PropTypes.string.isRequired,
  selectedAttachment: PropTypes.object,
  updateAttachment: PropTypes.func.isRequired,
  getNextAttachment: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
};
