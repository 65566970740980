import React from "react";

import { faWifiSlash } from "@fortawesome/pro-regular-svg-icons";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import Button from "components/Button";
import { BoldErrorMessage } from "components/ErrorMessage";
import { Fieldset } from "components/Form/Fieldset";
import Input from "components/Input";

const Form = styled.form`
  ${Button} {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.space[3]}px;
    font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  }
`;

const FieldsetGroup = styled.div`
  ${Fieldset} {
    &:last-child {
      margin-bottom: ${({ theme }) => theme.space[2]}px;
    }
  }
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.gray40};
`;

const InnerForm = ({ errors, disableButton }) => {
  const { handleSubmit, handleChange, handleBlur, values, touched } =
    useFormikContext();

  return (
    <Form onSubmit={handleSubmit}>
      <FieldsetGroup>
        <Fieldset flexDirection="column">
          <Label htmlFor="email">EMAIL</Label>
          <Input
            data-tour="email"
            autoComplete="email"
            name="email"
            inputMode="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            isInvalid={touched.email && errors.email}
          />
        </Fieldset>
        <Fieldset flexDirection="column">
          <Label htmlFor="password">PASSWORD</Label>
          <Input
            data-tour="password"
            type="password"
            autoComplete="current-password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            isInvalid={touched.password && errors.password}
          />
          {errors.password ? (
            <BoldErrorMessage
              icon={errors.password?.showWifiIcon ? faWifiSlash : null}
            >
              {errors.password.message}
            </BoldErrorMessage>
          ) : null}
        </Fieldset>
      </FieldsetGroup>
      <Button type="submit" disabled={disableButton} data-tour="submit">
        {disableButton ? "..." : "Sign in"}
      </Button>
    </Form>
  );
};

InnerForm.propTypes = {
  errors: PropTypes.object,
  disableButton: PropTypes.bool,
};

export default InnerForm;
