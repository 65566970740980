import React, { useState } from "react";

import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import { SavedViewAction, setTableView } from "actions";

import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { Button } from "components/Form";
import { Row, Column } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { Paper } from "components/Paper";
import { ContentTypeNames } from "components/UserRoles/constants";

import { OwnerTypeLabels, OwnerTypes } from "constants/aggrid";
import { ApiModel } from "constants/loading";
import {
  LivestockAgentPermissions,
  SaleyardAdminPermissions,
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { userTypes } from "constants/users";

import { ForStaff } from "containers/ForUserType";

import { hasPermission } from "lib/permissions";
import { sortSavedViewCategories } from "lib/savedViews";
import toast from "lib/toast";

import {
  getActiveRole,
  getCategorisedSavedViewsByTableName,
  getActiveLivestockAgentDeployment,
  getTableSetting,
  selectCurrentSaleyard,
} from "selectors";

import { useToggle } from "hooks";

import { SavedViewsDialog } from "./SavedViewsDialog";

const SavedViewClicker = styled.button(
  ({ theme, active, width = "auto" }) => `
  padding: ${theme.space[1]}px;
  background: ${active ? theme.colors.primaryActive : "inherit"};
  color: ${active ? theme.colors.white : "inherit"};
  box-shadow: 0 0 0 1px ${theme.colors.controlBorder};
  text-align: center;
  cursor: pointer;
  &:hover {
    color: ${theme.colors.white};
    background-color: ${theme.colors.primaryHighlight};
    box-shadow: 0 0 0 1px ${theme.colors.primaryHighlight};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${width};
	border: none;
	outline: inherit;
`,
);

const ViewCategoryList = ({
  heading,
  views = [],
  handleApplyView,
  handleDelete = null,
  currentViewId,
}) => {
  if (views.length === 0) {
    return null;
  }

  return (
    <Column>
      <h2>{heading}</h2>
      {sortBy(views, "name").map(view => (
        <Row key={view.id}>
          <SavedViewClicker
            onClick={() => handleApplyView(view)}
            width="100%"
            active={view.id === currentViewId}
          >
            {view.name}
          </SavedViewClicker>
          {handleDelete && (
            <SavedViewClicker onClick={() => handleDelete(view.id)}>
              <FontAwesomeIcon icon={faTrash} />
            </SavedViewClicker>
          )}
        </Row>
      ))}
    </Column>
  );
};

const userTypeConfigMap = (userRole, saleyard, deployment) => {
  // Not all userRoles currently have permissions returned.  This should be simplified
  // when it is available.
  if (userRole.type === userTypes.SALEYARD_ADMIN) {
    return {
      canCreateSelf: hasPermission(
        userRole,
        SaleyardAdminPermissions.canAddSavedView,
      ),
      canCreateSaleyard: hasPermission(
        saleyard,
        SaleyardPermissions.canAddSavedView,
      ),
      canCreateDeployment: false,
      shareableLabel: saleyard.name,
    };
  }

  if (userRole.type === userTypes.LIVESTOCK_AGENT) {
    return {
      canCreateSelf: hasPermission(
        userRole,
        LivestockAgentPermissions.canAddSavedView,
      ),
      canCreateSaleyard: false,
      canCreateDeployment: hasPermission(
        deployment,
        DeploymentPermissions.canAddSavedView,
      ),
      shareableLabel: deployment.name,
    };
  }

  return {};
};

const SavedViewsPanel = ({ api, columnApi, tableName }) => {
  const [isSaveDialogOpen, toggleIsSaveDialogOpen] = useToggle(false);
  const [confirmDeleteSavedView, setConfirmDeleteSavedView] = useState(null);

  const activeRole = useSelector(getActiveRole);
  const tableState = useSelector(getTableSetting(tableName));
  const currentViewId = tableState?.viewId;
  const categorisedSavedViews = useSelector(
    getCategorisedSavedViewsByTableName(tableName),
  );

  const dispatch = useDispatch();

  const applySavedView = ({ id }) => {
    dispatch(setTableView(tableName, id));
  };

  const userRole = useSelector(getActiveRole);
  const currentSaleyard = useSelector(selectCurrentSaleyard);
  const activeDeployment = useSelector(getActiveLivestockAgentDeployment);

  const deleteSavedView = id => {
    dispatch(SavedViewAction.delete(id));
    setConfirmDeleteSavedView(null);
  };

  const save = values => {
    let ownerId = null;
    let { ownerType } = values;
    if (ownerType === OwnerTypes.DEPLOYMENT) {
      ownerId = activeDeployment.id;
    } else if (ownerType === OwnerTypes.SALEYARD) {
      ownerId = currentSaleyard.id;
    } else {
      // Assume the owner is a userrole
      ownerType = ContentTypeNames[activeRole.type];
      ownerId = activeRole.id;
    }

    dispatch(
      SavedViewAction.create({
        name: values.name,
        content: JSON.stringify(columnApi.getColumnState()),
        filters: api.getFilterModel(),
        table: tableName,
        ownerId,
        ownerType,
        id: uuidv4(),
      }),
    );
    toggleIsSaveDialogOpen();
  };

  const configMap = userTypeConfigMap(
    userRole,
    currentSaleyard,
    activeDeployment,
  );

  const permissionToCreate = configMap
    ? configMap.canCreateDeployment ||
      configMap.canCreateSaleyard ||
      configMap.canCreateSelf
    : false;

  const copyColumnsToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(columnApi.getColumnState()));
    toast.success("Columns copied to clipboard.");
  };

  const copyFiltersToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(api.getFilterModel()));
    toast.success("Filters copied to clipboard.");
  };

  return (
    <>
      <Paper>
        <ForStaff>
          <Button onClick={copyColumnsToClipboard}>
            {`Copy "${tableName}" Columns`}
          </Button>
          <Button onClick={copyFiltersToClipboard}>
            {`Copy "${tableName}" Filters`}
          </Button>
        </ForStaff>
        <Button onClick={toggleIsSaveDialogOpen} disabled={!permissionToCreate}>
          Save Current View
        </Button>

        {Object.keys(categorisedSavedViews)
          .sort(sortSavedViewCategories)
          .map(categoryLabel => (
            <ViewCategoryList
              key={categoryLabel}
              heading={categoryLabel}
              views={categorisedSavedViews[categoryLabel]}
              handleApplyView={applySavedView}
              handleDelete={
                categoryLabel === OwnerTypeLabels[OwnerTypes.GLOBAL]
                  ? undefined
                  : setConfirmDeleteSavedView
              }
              currentViewId={currentViewId}
            />
          ))}
      </Paper>

      {isSaveDialogOpen && (
        <SavedViewsDialog
          handleSave={save}
          handleClose={toggleIsSaveDialogOpen}
          configMap={configMap}
        />
      )}
      {confirmDeleteSavedView && (
        <ConfirmDialog
          title={createModalTitle("this View")}
          isOpen
          onCancel={() => setConfirmDeleteSavedView(null)}
          onDelete={() => deleteSavedView(confirmDeleteSavedView)}
        />
      )}
    </>
  );
};

const LoadingWrapper = props => (
  <WaitForSync requiredData={[ApiModel.DEPLOYMENTS, ApiModel.SALEYARDS]}>
    <SavedViewsPanel {...props} />
  </WaitForSync>
);

export default LoadingWrapper;
