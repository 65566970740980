import {
  APP_UPDATE_STATUS,
  REFRESH_INTERVAL,
  SERVER_HEARTBEAT,
} from "constants/actionTypes";
import { appUpdateStatus } from "constants/system";

const initialState = {
  refreshIntervalMs: NaN,
  serverTimeDriftMs: 0,
  appUpdateStatus: appUpdateStatus.UP_TO_DATE,
};

export default function systemReducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_INTERVAL.ACTION:
      return {
        ...state,
        refreshIntervalMs: action.refreshIntervalMs,
      };
    case SERVER_HEARTBEAT.FETCH_BULK.SUCCESS:
      const {
        payload: {
          server_timestamp: timeOnServer,
          global_announcement: globalAnnouncement,
        },
      } = action;
      const drift = Date.now() - timeOnServer;
      return {
        ...state,
        serverTimeDriftMs: drift,
        globalAnnouncement,
      };

    case APP_UPDATE_STATUS.LOGOUT_REQUIRED:
      return {
        ...state,
        appUpdateStatus: appUpdateStatus.LOGOUT_REQUIRED,
      };

    case APP_UPDATE_STATUS.REFRESH_REQUIRED:
      return {
        ...state,
        appUpdateStatus: appUpdateStatus.REFRESH_REQUIRED,
      };

    case APP_UPDATE_STATUS.REFRESH_OPTIONAL:
      return {
        ...state,
        appUpdateStatus: appUpdateStatus.REFRESH_OPTIONAL,
      };

    case APP_UPDATE_STATUS.INVISIBLE:
      return {
        ...state,
        appUpdateStatus: appUpdateStatus.INVISIBLE,
      };

    case APP_UPDATE_STATUS.COMPLETE:
      return {
        ...state,
        appUpdateStatus: appUpdateStatus.UP_TO_DATE,
      };

    default:
      return state;
  }
}
