import React, { useCallback, useState } from "react";

import { Grid } from "@material-ui/core";
import { max, sortBy } from "lodash";
import isEqual from "lodash/isEqual";
import min from "lodash/min";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { AddSaleLotButton } from "components/Button/AddSaleLotButton";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { Paper } from "components/Paper";
import {
  LivestockSaleForm,
  ClearingSaleForm,
} from "components/QuickEditSaleLotForm";

import { AgGridPanels } from "constants/aggrid";
import { ApiModel } from "constants/loading";
import { SaleLotPermissions } from "constants/permissions";
import { SaleTypes } from "constants/sale";

import {
  getCurrentSaleType,
  getHasWriteAccessInCurrentSale,
  getSaleLots,
  selectSaleLotIdsByAuctionPenIdLookup,
} from "selectors";

import {
  useAllHasPermission,
  useBoolean,
  useIsAgGridToolPanelVisible,
  useMountEffect,
} from "hooks";
import { useHasReceivalOrPenScanLotsPermission } from "hooks/useHasPermission";

export const CreateAndSelectSaleLotButton = ({
  createNewLotParams,
  initialValues = {},
}) => {
  const { api, saleLotId } = createNewLotParams;
  const hasReceivalOrPenScanPermission =
    useHasReceivalOrPenScanLotsPermission();

  const newId = uuidv4();
  const setNewSaleLotId = useCallback(() => {
    api.getRowNode(newId)?.setSelected(true, true);
  }, [api, newId]);

  React.useEffect(() => {
    api.addEventListener("rowDataUpdated", setNewSaleLotId);
    if (newId === saleLotId) {
      return () => api.removeEventListener("rowDataUpdated", setNewSaleLotId);
    }
  }, [api, setNewSaleLotId, saleLotId, newId]);
  return hasReceivalOrPenScanPermission ? (
    <AddSaleLotButton
      initialValues={initialValues}
      options={{
        newId,
      }}
    />
  ) : null;
};

const QuickEditSaleLotPanel = ({ api }) => {
  const [saleLotIds, setSaleLotIds] = useState([]);

  const hasChangeSaleLotPermission = useAllHasPermission(
    getSaleLots,
    SaleLotPermissions.update,
    saleLot => saleLotIds.includes(saleLot.id),
  );

  const saleType = useSelector(getCurrentSaleType);

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const saleLotIdsByAuctionPenIdLookup = useSelector(
    selectSaleLotIdsByAuctionPenIdLookup,
  );

  const isReadOnly =
    !hasWriteAccessInCurrentSale || !hasChangeSaleLotPermission;

  const [isAutoFocusing, enableAutoFocusing, disableAutoFocusing] =
    useBoolean(false);

  const updateSelectedRows = useCallback(() => {
    setSaleLotIds(
      sortBy(api.getSelectedNodes(), "rowIndex").map(node => node.id),
    );
  }, [api]);

  useMountEffect(() => {
    updateSelectedRows();
  });

  React.useEffect(() => {
    api.addEventListener("selectionChanged", updateSelectedRows);
    return () => {
      api.removeEventListener("selectionChanged", updateSelectedRows);
    };
  }, [api, updateSelectedRows]);

  const changeFocus = useCallback(
    offset => {
      // Handle changing selected row with pagination.
      const selectedIndexes = api.getSelectedNodes().map(node => node.rowIndex);
      let nextIndex = null;

      if (offset > 0) {
        nextIndex = max(selectedIndexes) + offset;
      } else {
        nextIndex = min(selectedIndexes) + offset;
      }

      // Find the next row by index, or go to the start
      const nextRow =
        api.getDisplayedRowAtIndex(nextIndex) || api.getDisplayedRowAtIndex(0);

      const { id: nextRowId, data: nextRowData } = nextRow;

      const saleLotIdsToSelect = [nextRowId];
      if (
        [SaleTypes.SALEYARD_AUCTION, SaleTypes.ON_FARM_AUCTION].includes(
          saleType,
        )
      ) {
        // And any other sale lots that share the same pen id and mark.
        saleLotIdsByAuctionPenIdLookup[nextRowData.auction_pen_id].forEach(
          saleLotId => {
            const {
              data: { marks },
            } = api.getRowNode(saleLotId);
            if (isEqual(marks, nextRowData.marks)) {
              saleLotIdsToSelect.push(saleLotId);
            }
          },
        );
      }

      // Set all these sale lot ids to selected; with a clearSelection = true on the first iteration.
      saleLotIdsToSelect.forEach((saleLotId, idx) => {
        api.getRowNode(saleLotId).setSelected(true, idx === 0);
      });

      // Make sure the first row is visible
      const rowCount = api.paginationGetRowCount();
      if (nextIndex < rowCount) {
        const pageSize = api.paginationGetPageSize();
        const currentPage = api.paginationGetCurrentPage();
        const nextIndexPage = Math.floor(nextIndex / pageSize);
        if (nextIndexPage !== currentPage) {
          api.paginationGoToPage(nextIndexPage);
        }
        api.ensureIndexVisible(nextIndex);
      }
    },
    [api, saleLotIdsByAuctionPenIdLookup, saleType],
  );

  const onSave = () => {
    changeFocus(1);
  };

  const closeToolPanel = () => api.closeToolPanel();

  const handlePageUpPageDown = React.useCallback(
    e => {
      if (!e.repeat && e.key === "PageUp") {
        enableAutoFocusing();
        changeFocus(-1);
        e.preventDefault();
      }
      if (!e.repeat && e.key === "PageDown") {
        enableAutoFocusing();
        changeFocus(1);
        e.preventDefault();
      }
    },
    [changeFocus, enableAutoFocusing],
  );

  React.useEffect(() => {
    window.addEventListener("keydown", handlePageUpPageDown);
    return () => {
      window.removeEventListener("keydown", handlePageUpPageDown);
    };
  }, [handlePageUpPageDown]);

  const createNewLotParams = {
    api,
    saleLotIds,
  };

  const QuickSellPanelContentComponent =
    saleType === SaleTypes.CLEARING ? ClearingSaleForm : LivestockSaleForm;

  return (
    <Paper data-tour="quickSellForm">
      {saleLotIds.length > 0 ? (
        <QuickSellPanelContentComponent
          closeToolPanel={closeToolPanel}
          createNewLotParams={createNewLotParams}
          disableAutoFocusing={disableAutoFocusing}
          enableAutoFocusing={enableAutoFocusing}
          isAutoFocusing={isAutoFocusing}
          isReadOnly={isReadOnly}
          onSave={onSave}
          saleLotIds={saleLotIds}
        />
      ) : (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={8}>
            Nothing selected.
          </Grid>
          <Grid item xs={4}>
            <CreateAndSelectSaleLotButton
              createNewLotParams={createNewLotParams}
            />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

const LoadingWrapper = ({ api }) => {
  const isToolPanelVisible = useIsAgGridToolPanelVisible(
    api,
    AgGridPanels.QUICK_EDIT,
  );
  if (isToolPanelVisible) {
    return (
      <WaitForSync requiredData={[ApiModel.DEPLOYMENTS, ApiModel.SALEYARDS]}>
        <QuickEditSaleLotPanel api={api} />
      </WaitForSync>
    );
  } else {
    return <div>Not visible.</div>;
  }
};

export default LoadingWrapper;
