import React, { memo, useCallback, useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { connectToDevice, connectToHub, updateAvailableDevices } from "actions";

import ErrorMessage from "components/ErrorMessage";
import { DataTour } from "components/HelpHeroDataTour";
import { ReactSelect } from "components/SearchableSelector";

import {
  getAvailableDevices,
  getConnectedDeviceId,
  getIsHubConnected,
} from "selectors";

import HubStatusIndicator from "./HubStatusIndicator";
import ScannerActionsInline from "./ScannerActionsInline";

const ScannerSelectInlineWrapper = styled(Grid)`
  place-items: center;
  min-width: 250px;
`;

const ScannerListSelect = styled(ReactSelect)`
  flex-grow: 1;
`;

const MESSAGE_NO_DEVICES_AVAILABLE = "No devices available.";
const MESSAGE_HUB_NOT_CONNECTED = "AgriNous Hub not connected.";
const MESSAGE_DEVICES_AVAILABLE = "Select a device to connect to...";
const MESSAGE_CONNECTION_FAILED = "Could not connect to device.";
const TIMEOUT_CONNECTION_FAILED_MESSAGE = 2500;

export function ScannerSelectInlineComponent(props) {
  const {
    listPosition = "below",
    onAfterSelectScanner,
    onDeviceConnectFailed,
    showIndicator,
    showFailedMessage = true,
  } = props;

  const isHubConnected = useSelector(getIsHubConnected);
  const connectedDeviceId = useSelector(getConnectedDeviceId);
  const availableDevices = useSelector(getAvailableDevices);
  const availableDeviceOptions = availableDevices.map(device => ({
    value: device.deviceId,
    label: device.name,
  }));

  const [isConnecting, setIsConnecting] = useState(false);
  const [connectFailed, setConnectFailed] = useState(false);

  const connectingDeviceId = availableDevices.find(
    device => device.isConnecting,
  )?.deviceId;

  const availableDeviceCount = availableDeviceOptions.length;
  const dispatch = useDispatch();

  // Connect to hub, then  refresh the list of all available devices
  useEffect(() => {
    if (!isHubConnected) {
      dispatch(connectToHub());
    } else if (!availableDeviceCount) {
      dispatch(updateAvailableDevices());
    }
  }, [availableDeviceCount, dispatch, isHubConnected]);

  // call onDeviceConnectFailed if connection failed
  useEffect(() => {
    if (isConnecting) {
      if (!connectingDeviceId) {
        setIsConnecting(false);
        if (!connectedDeviceId) {
          setConnectFailed(true);
          setTimeout(
            () => setConnectFailed(false),
            TIMEOUT_CONNECTION_FAILED_MESSAGE,
          );
          typeof onDeviceConnectFailed === "function" &&
            onDeviceConnectFailed();
        }
      }
    }
  }, [
    connectedDeviceId,
    connectingDeviceId,
    onDeviceConnectFailed,
    isConnecting,
    setConnectFailed,
    setIsConnecting,
  ]);

  const onSelectedScannerChanged = useCallback(
    option => {
      dispatch(connectToDevice(option.value));
      setIsConnecting(true);
      typeof onAfterSelectScanner === "function" &&
        onAfterSelectScanner(option.value);
    },
    [dispatch, onAfterSelectScanner, setIsConnecting],
  );

  let scannerListPrompt = MESSAGE_HUB_NOT_CONNECTED;
  if (isHubConnected) {
    if (availableDeviceOptions.length) {
      scannerListPrompt = MESSAGE_DEVICES_AVAILABLE;
    } else {
      scannerListPrompt = MESSAGE_NO_DEVICES_AVAILABLE;
    }
  }

  const connectFailedMessageVisible = connectFailed && showFailedMessage;

  return (
    <ScannerSelectInlineWrapper item container>
      {showIndicator === true && (
        <Grid item>
          <HubStatusIndicator />
        </Grid>
      )}
      {connectedDeviceId === null && !connectFailedMessageVisible && (
        <DataTour dataTour="scanner-list-options">
          <ScannerListSelect
            placeholder={scannerListPrompt}
            isDisabled={isConnecting}
            isLoading={isConnecting}
            isSearchable
            onChange={onSelectedScannerChanged}
            options={availableDeviceOptions}
            menuPlacement={listPosition === "above" ? "top" : "bottom"}
          />
        </DataTour>
      )}

      <Grid item>
        {connectFailedMessageVisible && (
          <ErrorMessage visible>{MESSAGE_CONNECTION_FAILED}</ErrorMessage>
        )}
        {!connectFailedMessageVisible && connectedDeviceId !== null && (
          <ScannerActionsInline />
        )}
      </Grid>
    </ScannerSelectInlineWrapper>
  );
}
ScannerSelectInlineComponent.propTypes = {
  listPosition: PropTypes.string,
  onAfterSelectScanner: PropTypes.func,
  onDeviceConnectFailed: PropTypes.func,
  showIndicator: PropTypes.bool,
  showFailedMessage: PropTypes.bool,
};
export default memo(ScannerSelectInlineComponent);
