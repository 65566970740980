import LogRocket from "logrocket";

import {
  LOGROCKET_IGNORE_ACTIONS,
  LOGROCKET_TRUNCATE_ACTIONS,
} from "constants/actionTypes";

import { isLogRocketActive } from "./config";

export const getLogRocketMiddleware = () => {
  if (!isLogRocketActive) {
    return null;
  }

  return LogRocket.reduxMiddleware({
    stateSanitizer(state) {
      // Pull out some (valuable) large info.
      return {
        ...state,
        auth: {
          ...state.auth,
          authentication: {
            ...(state.auth?.authentication || {}),
          },
          saleyardAdmins: "<<LONG BLOB>>",
          livestockAgents: "<<LONG BLOB>>",
          saleWatchers: "<<LONG BLOB>>",
          scaleOperators: "<<LONG BLOB>>",
          businessUsers: "<<LONG BLOB>>",
        },
        businessesV2: "<<LONG BLOB>>",
        deploymentAttributes: "<<LONG BLOB>>",
        draftingInformation: "<<LONG BLOB>>",
        nlisFiles: "<<LONG BLOB>>",
        properties: "<<LONG BLOB>>",
        reports: "<<LONG BLOB>>",
        sales: "<<LONG BLOB>>",
        savedViews: "<<LONG BLOB>>",
        scanners: { ...state.scanners, scans: "<<LONG BLOB>>" },
      };
    },
    actionSanitizer(action) {
      // Ditch our GET actions - they're
      const { type: actionType } = action;
      if (LOGROCKET_IGNORE_ACTIONS.includes(actionType)) {
        return null;
      } else if (LOGROCKET_TRUNCATE_ACTIONS.includes(actionType)) {
        return {
          type: actionType,
        };
      }
      return action;
    },
  });
};
