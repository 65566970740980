import React from "react";

import Grid from "@material-ui/core/Grid";
import { Form, Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { addAuctionPen, AuctionPenAction } from "actions";

import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import { Button, SecondaryButton } from "components/Form/Button";
import { BuyerAwareBuyerWayField } from "components/Form/Fields";
import { Label } from "components/Form/FormikControls";
import { Column } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import PenningSaleLotFormComponent from "components/PenningSaleLotForm";
import penValidationSchema from "components/PenningSaleLotForm/validationSchema";

import { PenTypes } from "constants/auctionPens";
import { BUYER } from "constants/businesses";

import {
  getAuctionPenDisplayName,
  getAuctionPenPayload,
} from "lib/auctionPens";
import { closeAllHashModals, returnToLocation } from "lib/navigation";

import { getAuctionPenById } from "selectors";

const businessRoles = [BUYER];

function DeliveryPenOwnerForm({ deliveryPenId }) {
  const formikProps = useFormikContext();

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item container>
        {deliveryPenId ? null : (
          <Column fullWidth>
            <Label htmlFor="deliveryPen">Delivery Pen</Label>
            <Grid xs={12} item container spacing={2}>
              <PenningSaleLotFormComponent
                autoFocusStartPen
                namespace="deliveryPen"
                penType={PenTypes.DELIVERY}
                showOwnerPrompt
              />
            </Grid>
          </Column>
        )}
      </Grid>

      <Grid xs={12} item>
        <StateAwareBusinessPICSelector
          label="Buyer"
          businessFieldName="buyerId"
          formikProps={formikProps}
          businessRoles={businessRoles}
          allowBusinessOnly
        />
      </Grid>
      <Grid item xs={12}>
        <BuyerAwareBuyerWayField
          name="buyerWay"
          label="Buyer Way"
          buyerField="buyerId"
        />
      </Grid>
    </Grid>
  );
}
export function DeliveryPenOwnersModal({ deliveryPenId, returnTo }) {
  const dispatch = useDispatch();
  const deliveryPen = useSelector(getAuctionPenById(deliveryPenId));

  const closeSelf = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  const handleSubmit = values => {
    let submissionDeliveryPenId = deliveryPenId;
    if (!deliveryPen) {
      submissionDeliveryPenId = uuidv4();

      dispatch(
        addAuctionPen(
          getAuctionPenPayload(values, "deliveryPen", PenTypes.DELIVERY),
          submissionDeliveryPenId,
        ),
      );
    }
    const penOwner = {
      businessId: values.buyerId,
      buyerWay: values.buyerWay,
    };
    dispatch(
      AuctionPenAction.penOwner.create(submissionDeliveryPenId, penOwner),
    );
    closeSelf();
  };

  const initialValues = {
    deliveryPen: null,
  };

  const validationSchema = {
    buyerId: Yup.string().required("Buyer is required"),
  };
  if (!deliveryPenId) {
    validationSchema.deliveryPen = Yup.object().shape(penValidationSchema);
  }

  return (
    <Dialog open onClose={closeSelf} maxWidth="sm" fullWidth>
      <DialogTitle onClose={closeSelf}>
        {deliveryPen
          ? `Add New Owner For Delivery Pen ${getAuctionPenDisplayName(
              deliveryPen,
            )}`
          : "Create New Pen Owner"}
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Yup.object(validationSchema)}
      >
        <Form>
          <DialogContent dividers shrink={0}>
            <DeliveryPenOwnerForm deliveryPenId={deliveryPenId} />
          </DialogContent>
          <DialogActions>
            <SecondaryButton type="button" onClick={closeSelf}>
              Close
            </SecondaryButton>

            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}
