import React, { useCallback, useEffect, useMemo } from "react";

import Grid from "@material-ui/core/Grid";
import { useFormikContext, getIn, useField } from "formik";
import * as Yup from "yup";

import { SubtleBadge } from "components/Badge";
import { SlimButton, SlimSecondaryButton } from "components/Button";
import { CollapseLabel } from "components/Form";
import {
  Input,
  withNamespace,
  withNamespaces,
} from "components/Form/FormikControls";
import { Row } from "components/Layout";
import Table, { CellRightAlign } from "components/Table";

import { caseInsensitiveCompare } from "lib/compare";
import { pluralize } from "lib/pluralize";

const DEFAULT_SORT = [
  {
    id: "name",
    direction: "asc",
  },
];

export const BuyerWayValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

export const BuyerWaysHeader = ({ namespace: ns }) => {
  const [{ value }] = useField(ns);
  const filteredValues = value.filter(buyerWay => buyerWay.isShown);

  return (
    <Row>
      <SubtleBadge>Buyer Ways</SubtleBadge>
      <CollapseLabel>
        {filteredValues.length} Buyer {pluralize("Way", filteredValues.length)}
      </CollapseLabel>
    </Row>
  );
};

export function BuyerWaysTable(props) {
  const { namespace: ns, onEndEditing, onStartEditing } = props;
  const formikContext = useFormikContext();
  const { dirty, isValid, values, setFieldValue, setFieldTouched } =
    formikContext;

  const buyerWays = useMemo(
    () =>
      getIn(values, withNamespace(ns, "values")).filter(
        buyerWay => buyerWay.isShown,
      ),
    [ns, values],
  );
  const editingBuyerWay = getIn(values, withNamespace(ns, "editingValue"));
  const isEditingExistingBuyerWay =
    editingBuyerWay &&
    Array.isArray(buyerWays) &&
    buyerWays.some(buyerWay => buyerWay.id === editingBuyerWay.id);

  const onEditBuyerWayIndex = useCallback(
    index => {
      setFieldValue(withNamespace(ns, "editingValue"), buyerWays[index]);

      onStartEditing();
    },
    [buyerWays, ns, onStartEditing, setFieldValue],
  );

  const onRemoveBuyerWayIndex = useCallback(
    index => {
      const newState = buyerWays.slice();
      newState.splice(index, 1);
      setFieldValue(withNamespace(ns, "values"), newState);

      onStartEditing();
    },
    [buyerWays, ns, onStartEditing, setFieldValue],
  );

  function onClickCommitBuyerWayChanges() {
    const updatingBuyerWayId = editingBuyerWay.id;
    const newState = buyerWays.slice();
    const existingIndex = newState.findIndex(
      buyerWay => buyerWay.id === updatingBuyerWayId,
    );
    newState.splice(existingIndex === -1 ? newState.length : existingIndex, 1, {
      ...editingBuyerWay,
      isShown: true,
    });

    setFieldValue(withNamespace(ns, "values"), newState);
    setFieldValue(withNamespace(ns, "editingValue"), null);

    onEndEditing();
    return false;
  }

  function onClickClearBuyerWayChanges() {
    setFieldValue(withNamespace(ns, "editingValue"), null);
    onEndEditing();
  }

  function autoAllocateOfflineBuyerWayId() {
    if (!editingBuyerWay?.id) {
      setFieldValue(
        withNamespaces([ns, "editingValue"], "id"),
        -Date.now(),
        true,
      );
      setFieldTouched(withNamespaces([ns, "editingValue"], "name"));

      onStartEditing();
    }
  }

  useEffect(() => {
    !dirty && onEndEditing();
  }, [dirty, onEndEditing]);

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Name",
        id: "name",
        sortMethod: caseInsensitiveCompare,
      },
      {
        Cell: ({ index }) => (
          <CellRightAlign>
            <SlimButton
              type="button"
              onClick={() => onEditBuyerWayIndex(index)}
            >
              Edit
            </SlimButton>{" "}
            <SlimButton
              color="deleteRed"
              type="button"
              onClick={() => onRemoveBuyerWayIndex(index)}
            >
              Delete
            </SlimButton>
          </CellRightAlign>
        ),
        Header: "Actions",
        id: "actions",
        width: 150,
      },
    ],
    [onEditBuyerWayIndex, onRemoveBuyerWayIndex],
  );

  const isClearEnabled = editingBuyerWay !== null;
  const isAddUpdateEnabled = editingBuyerWay !== null && dirty && isValid;

  const isRevertEnabled = dirty;
  const isSubmitEnabled = dirty && isValid && editingBuyerWay === null;

  return (
    <>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={5} md={4}>
          <Input
            label="Buyer Way Name"
            name={withNamespaces([ns, "editingValue"], "name")}
            onChangeExtra={autoAllocateOfflineBuyerWayId}
          />
        </Grid>
        <Grid item xs={7} md={4}>
          <SlimSecondaryButton
            disabled={!isClearEnabled}
            onClick={onClickClearBuyerWayChanges}
            type="button"
          >
            Clear
          </SlimSecondaryButton>
          <SlimButton
            disabled={!isAddUpdateEnabled}
            onClick={onClickCommitBuyerWayChanges}
            type="submit"
            data-tour={`${
              isEditingExistingBuyerWay ? "update" : "add"
            }BuyerWay`}
          >
            {isEditingExistingBuyerWay ? "Update" : "Add"} Buyer Way
          </SlimButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={buyerWays}
            defaultSorted={DEFAULT_SORT}
            noResultsMessage="This Business does not have any Buyer Ways yet."
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid container item xs={12}>
          <SlimSecondaryButton type="reset" disabled={!isRevertEnabled}>
            Revert Changes
          </SlimSecondaryButton>
          <SlimButton
            data-tour="acceptChanges"
            type="submit"
            disabled={!isSubmitEnabled}
          >
            Accept Changes
          </SlimButton>
        </Grid>
      </Grid>
    </>
  );
}
