import {
  auctionPenStatuses,
  consignmentStatuses,
  saleLotStatuses,
} from "constants/sale";
import { WeighLotScanStatus } from "constants/weighScanning";

export const breakpoints = [512, 768, 960, 1280];

export const space = [0, 6, 12, 24, 36, 48, 60, 72];

export const fluidSpacing = ["1rem"];

export const fontSizes = {
  alpha: 12,
  beta: 14,
  charlie: 16,
  gamma: 18,
  delta: 20,
  epsilon: 24,
  zeta: 32,
  eta: 48,
  theta: 64,
  iota: 72,
  kappa: 96,
};

export const fontWeights = {
  thin: 100,
  normal: 400,
  medium: 500,
  bold: 700,
};

export const baseLineHeight = 1.5;

const lightCoral = "#F48080";
const blueHighlight = "#D8E4EF";
const burntSienna = "#E27E51";
const deleteRed = "#DD0101";
const puertoRico = "#40C1AC";
const warningOrange = "#DB7E4B";
const successGreen = "#83D97C";
const errorRed = "#F24A4A";
const cornflowerBlue = "#52A6F8";
const pictonBlue = "#4A92D6";
const steelBlue = "#427DB3";
const selectiveYellow = "#FFB700";
const lightGreen = "#91CD80";
const lightGreen2 = "#C6EA9F";
const red = "#DB2E2D";
const black = "#000000";
const gray13 = "#131313";
const gray40 = "#666";
const gray44 = "#717171";
const gray7A = "#7A7A7A";
const gray8C = "#8C8C8C";
const gray9C = "#9C9C9C";
const grayAE = "#AEAEAE";
const gray78 = "#C8C8C8";
const gray85 = "#D9D9D9";
const grayDisabled = "#ebebe4";
const gray95 = "#F2F2F2";
const grayF3 = "#f3f3f3";
const grayF7 = "#f7f7f7";
const white = "#FFF";
const lavender = "#b19cd9";

const noProgress = "#AAAAAA";
const titleDark = "#363636";
const titleMedium = "#666666";
const titleLight = "#979797";

const collapseBackground = "#fafafa";
const controlBackground = white;
const controlBorder = gray78;
const surfaceBackground = grayF3;
const surfaceBorder = gray78;

const materialToolTipBackground = "#616161";

const separatorBlue = "#4a90e2";

const highContrastYellow = "#FFFF00";
const highContrastBlue = "#33FFFF";
const highContrastGreen = "#33FF11";

const activeGreen = "#7ed321";
const activeGreenQuarterOpaque = "rgba(126, 211, 33, 0.25)";

const darkOverlay = "rgba(0,0,0,0.8);";
const primarySelectedOverlay = "rgba(66, 125, 179, 0.2)";

const angusAustraliaBackground = "#1a1a1a";
const angusAustraliaText = "#F0332D";

const pending = "#DB7E4B";
const approved = "#83D97C";
const activeColour = "#4a90e2";
const withdrawn = "#DB7E4B";
const passedIn = "#FFB700";
const sold = "#91CD80";
const paid = "#DB2E2D";
const forSale = "#6a0dad";
const noBids = "#FFB700";
const wanted = "#273F87";
const inProgress = "#7E9EB2";
const complete = "#5A92D5";

// https://developer.xero.com/static/otherfiles/xero-app-partner-brand-guidelines.pdf
const xero = "#13B5EA";

export const colors = {
  activeColour,
  activeGreen,
  angusAustraliaBackground,
  angusAustraliaText,
  approved,
  black,
  blueHighlight,
  collapseBackground,
  complete,
  controlBackground,
  controlBorder,
  darkOverlay,
  deleteRed,
  deliveryPenBackground: lightGreen,
  error: burntSienna,
  errorRed,
  forSale,
  gold: selectiveYellow,
  gray13,
  gray40,
  gray44,
  gray78,
  gray7A,
  gray85,
  gray8C,
  gray95,
  gray9C,
  grayAE,
  grayDisabled,
  grayF3,
  grayF7,
  highContrastCurrent: highContrastGreen,
  highContrastDefault: highContrastYellow,
  highContrastLastSold: highContrastBlue,
  highContrastNextUp: highContrastBlue,
  inactive: gray9C,
  inactiveStep: grayAE,
  inProgress,
  inputError: lightCoral,
  lavender,
  lightCoral,
  lightGreen,
  materialToolTipBackground,
  newUpdateText: lightGreen2,
  newUpdateBackground: activeGreenQuarterOpaque,
  noBids,
  noProgress,
  ongoing: gray78,
  paid,
  passedIn,
  pending,
  PICChipBackground: steelBlue,
  PICChipText: grayF7,
  primary: steelBlue,
  primaryActive: cornflowerBlue,
  primaryHighlight: pictonBlue,
  primarySelectedOverlay,
  ready: cornflowerBlue,
  red,
  selectiveYellow,
  separatorBlue,
  sold,
  success: puertoRico,
  successGreen,
  surfaceBackground,
  surfaceBorder,
  syncing: puertoRico,
  titleDark,
  titleLight,
  titleMedium,
  wanted,
  warning: selectiveYellow,
  warningOrange,
  white,
  withdrawn,
  xero,
};

export const statusColors = {
  [consignmentStatuses.BOOKED]: "#6EABAF",
  [consignmentStatuses.ARRIVED]: colors.success,
  [saleLotStatuses.NOT_PENNED]: colors.warning,
  [saleLotStatuses.PENNED]: colors.lightGreen,
  [saleLotStatuses.SOLD]: colors.success,
  [saleLotStatuses.STACKED]: colors.inProgress,
  [saleLotStatuses.NO_SALE]: colors.noProgress,
  [saleLotStatuses.NOT_SOLD]: colors.inProgress,
  [saleLotStatuses.DELIVERED]: colors.complete,
  [auctionPenStatuses.NOT_PENNED]: colors.noProgress,
  [auctionPenStatuses.PARTIALLY_SOLD]: colors.success,
  [auctionPenStatuses.PARTIALLY_DELIVERED]: colors.complete,
  [WeighLotScanStatus.DEDUPLICATED]: colors.inProgess,
  [WeighLotScanStatus.IGNORED]: colors.success,
  [WeighLotScanStatus.REWEIGHED]: colors.success,
  [WeighLotScanStatus.MAPPED]: colors.success,
  [WeighLotScanStatus.UNRESOLVED]: colors.error,
  [WeighLotScanStatus.MAPPED_TO_SALE_LOT]: colors.success,
  [WeighLotScanStatus.NEW_EID]: colors.error,
};

const statusBackgroundColors = {
  inputError: "rgba(244, 128, 128, 0.1)",
  success: `${colors.success}10`,
  TEMP: `${colors.separatorBlue}10`,
};

export const radii = [0, 2, 3, 4, 6];

export const fonts = {
  0: "Roboto, system-ui, sans-serif",
  sans: "system-ui, sans-serif",
  mono: '"SF Mono", "Roboto Mono", Menlo, monospace',
};

export const shadows = [
  "none",
  `0 1px 4px 0 ${colors.gray78}`,
  `0 1px 3px 0 rgba(200,200,200,0.59)`,
  `0 2px 4px 0 rgba(0,0,0,0.5)`,
];

export const transitions = [
  "all 200ms ease-in-out",
  "all 100ms ease-in-out",
  "all 50ms ease-in-out",
];

export const active = `&:active { opacity: 0.8; }`;

const getLogoColor = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "staging":
      return "#FFB700";
    case "development":
      return "#E25151";
    case "local":
      return "#FFF";
    default:
  }
};

export const logoColor = getLogoColor();

export default {
  active,
  breakpoints,
  space,
  statusBackgroundColors,
  fontSizes,
  fontWeights,
  fonts,
  baseLineHeight,
  colors,
  radii,
  shadows,
  transitions,
  statusColors,
  logoColor,
  fluidSpacing,
};
