import { auctionPenStatuses } from "constants/sale";
import { ScanStatus } from "constants/scanner";

export const TYPE_MULTISELECT = "MULTISELECT";

export const applyFilters = (filterOptions, filterValues, dataset) =>
  filterOptions.reduce((dataset, filterOption) => {
    if (typeof filterValues[filterOption.name] === "undefined") {
      return dataset;
    } else {
      return filterOption.doFilter(
        dataset,
        filterValues[filterOption.name],
        filterOption,
      );
    }
  }, dataset);

const consignmentFilterArrived = (consignments, show) =>
  show
    ? consignments.filter(c => c.hasArrived)
    : consignments.filter(c => !c.hasArrived);

const consignmentEidCompliant = (groupedConsignments, show) =>
  show
    ? groupedConsignments.filter(group =>
        group.consignments.every(consignment =>
          consignment.saleLots.every(
            saleLots => saleLots.scannedStatus === ScanStatus.PASS,
          ),
        ),
      )
    : groupedConsignments.filter(
        group =>
          !group.consignments.every(consignment =>
            consignment.saleLots.every(
              saleLots => saleLots.scannedStatus === ScanStatus.PASS,
            ),
          ),
      );

const consignmentFilterExceptions = (consignments, show) =>
  show
    ? consignments.filter(c => c.hasExceptions)
    : consignments.filter(c => !c.hasExceptions);
const consignmentFilterBalanced = (consignments, show) =>
  show
    ? consignments.filter(c => c.isPreSaleBalanced && c.hasArrived)
    : consignments.filter(c => !(c.isPreSaleBalanced && c.hasArrived));

const consignmentFilterSaleyardScans = (groupedConsignments, show) => {
  return show
    ? groupedConsignments.filter(
        group => group.consignmentsScannedCount !== group.scannedCount,
      )
    : groupedConsignments.filter(
        group => group.consignmentsScannedCount === group.scannedCount,
      );
};

export const consignmentFilterOptions = [
  {
    name: "arrived",
    label: "ARRIVED",
    doFilter: consignmentFilterArrived,
  },

  {
    name: "exceptions",
    label: "EXCEPTIONS",
    doFilter: consignmentFilterExceptions,
  },
  {
    name: "balanced",
    label: "BALANCED",
    doFilter: consignmentFilterBalanced,
  },
  {
    name: "saleyardscans",
    label: "CONSIGNMENT SCANS",
    doFilter: consignmentFilterSaleyardScans,
  },
  {
    name: "eidcompliant",
    label: "EID COMPLIANT",
    doFilter: consignmentEidCompliant,
  },
];

const auctionPenFilterSold = (auctionPens, show) =>
  show
    ? auctionPens.filter(ap =>
        [
          auctionPenStatuses.SOLD,
          auctionPenStatuses.PARTIALLY_DELIVERED,
          auctionPenStatuses.DELIVERED,
        ].includes(ap.status),
      )
    : auctionPens.filter(
        ap =>
          ![
            auctionPenStatuses.SOLD,
            auctionPenStatuses.PARTIALLY_DELIVERED,
            auctionPenStatuses.DELIVERED,
          ].includes(ap.status),
      );

const auctionPenFilterInProgress = (auctionPens, show) =>
  show
    ? auctionPens.filter(
        ap =>
          ![
            auctionPenStatuses.NOT_PENNED,
            auctionPenStatuses.NOT_SOLD,
            auctionPenStatuses.DELIVERED,
          ].includes(ap.status),
      )
    : auctionPens.filter(ap =>
        [
          auctionPenStatuses.NOT_PENNED,
          auctionPenStatuses.NOT_SOLD,
          auctionPenStatuses.DELIVERED,
        ].includes(ap.status),
      );

const auctionPenFilterDelivered = (auctionPens, show) =>
  show
    ? auctionPens.filter(ap =>
        [auctionPenStatuses.DELIVERED].includes(ap.status),
      )
    : auctionPens.filter(
        ap => ![auctionPenStatuses.DELIVERED].includes(ap.status),
      );
const auctionPenFilterHasOverflow = (auctionPens, show) =>
  show
    ? auctionPens.filter(c => c.hasOverflowPen)
    : auctionPens.filter(c => !c.hasOverflowPen);

const auctionPenEidCompliant = (auctionPens, show) =>
  show
    ? auctionPens.filter(ap => ap.scannedStatus === ScanStatus.PASS)
    : auctionPens.filter(ap => ap.scannedStatus !== ScanStatus.PASS);

// NOT COUNTED is the highest order status, so we can filter based on it.
const auctionPenFilterIsCounted = (auctionPens, show) =>
  show
    ? auctionPens.filter(c => c.status !== auctionPenStatuses.NOT_COUNTED)
    : auctionPens.filter(c => c.status === auctionPenStatuses.NOT_COUNTED);

const auctionPenFilterLane = (auctionPens, lanes, filterOption) => {
  const laneValues = filterOption.options
    .filter(opt => lanes.includes(opt.value))
    .map(opt => opt.label);
  return auctionPens.filter(ap => laneValues.includes(ap.lane));
};

const auctionPenAllSaleLotScansHaveWeight = (auctionPens, show) => {
  return show
    ? auctionPens.filter(c => c.isAnyWeighed)
    : auctionPens.filter(c => c.isAnyUnweighed);
};

export const auctionPenFilterOptions = [
  {
    name: "sold",
    label: "SOLD",
    doFilter: auctionPenFilterSold,
  },
  {
    name: "inprogress",
    label: "IN PROGRESS",
    doFilter: auctionPenFilterInProgress,
  },
  {
    name: "delivered",
    label: "DELIVERED",
    doFilter: auctionPenFilterDelivered,
  },
  {
    name: "hasoverflow",
    label: "HAS OVERFLOW",
    doFilter: auctionPenFilterHasOverflow,
  },
  {
    name: "eidcompliant",
    label: "EID COMPLIANT",
    doFilter: auctionPenEidCompliant,
  },
  {
    name: "counted",
    label: "COUNTED",
    doFilter: auctionPenFilterIsCounted,
  },
  {
    name: "has weight",
    label: "HAS WEIGHT",
    doFilter: auctionPenAllSaleLotScansHaveWeight,
  },
  {
    name: "lane",
    label: "LANE",
    type: TYPE_MULTISELECT,
    doFilter: auctionPenFilterLane,
    options: [],
  },
];

const auctionPenBuyersSaleLotsHaveWeight = (buyers, show) => {
  return show
    ? buyers.filter(b => b.isAnyWeighed)
    : buyers.filter(b => b.isAnyUnweighed);
};

const auctionPenBuyersFilterHasExceptions = (auctionPens, show) =>
  show
    ? auctionPens.filter(ap => ap.hasExceptions)
    : auctionPens.filter(ap => !ap.hasExceptions);

// These are kind of auction pens, kind of sale lots, kind of just an object
// made up for this.  At the end of the day, they call out to the auction pen
// status function, so we can re-use some of the filters from there.
export const auctionPenBuyersFilterOptions = [
  {
    name: "inprogress",
    label: "IN PROGRESS",
    doFilter: auctionPenFilterInProgress,
  },
  {
    name: "delivered",
    label: "DELIVERED",
    doFilter: auctionPenFilterDelivered,
  },
  {
    name: "hasoverflow",
    label: "HAS OVERFLOW",
    doFilter: auctionPenFilterHasOverflow,
  },
  {
    name: "hasexceptions",
    label: "HAS EXCEPTIONS",
    doFilter: auctionPenBuyersFilterHasExceptions,
  },
  {
    name: "hasweight",
    label: "HAS WEIGHT",
    doFilter: auctionPenBuyersSaleLotsHaveWeight,
  },
];

/**
 * Returns the true if the gived saleLot sell values match a bidder being tested
 * based on businessId, defaultPropertyId and buyerWay
 * @param saleLot
 * @returns {function(BidderRegistration): boolean}
 */
export function saleLotBidderFilter(saleLot) {
  return bidder => {
    const saleLotBuyerWayName = saleLot.buyer_way?.name || "";
    const saleLotBuyer = saleLot.buyer_id || "";
    const saleLotDestination = saleLot.destination_property_id || null;

    return (
      saleLotBuyer === bidder.businessId &&
      saleLotDestination === bidder.defaultPropertyId &&
      saleLotBuyerWayName === bidder.buyerWay
    );
  };
}

export function uniqueByPropertyFilter(propertyName) {
  return (value, index, array) =>
    array.findIndex(
      otherValue => otherValue[propertyName] === value[propertyName],
    ) === index;
}

export function unsoldSaleLotFilter(saleLot) {
  return !saleLot.buyer_id;
}

const buyerWaySaleLotsHaveWeight = (buyerWays, show) => {
  return show
    ? buyerWays.filter(bw => bw.isAnyWeighed)
    : buyerWays.filter(bw => bw.isAnyUnweighed);
};

export const groupedBuyersFilterOptions = [
  {
    name: "hasweight",
    label: "HAS WEIGHT",
    doFilter: buyerWaySaleLotsHaveWeight,
  },
];

const saleLotsHaveWeight = (saleLots, show) => {
  return show
    ? saleLots.filter(sl => sl.isWeighed)
    : saleLots.filter(sl => !sl.isWeighed);
};

export const saleLotFilterOptions = [
  {
    name: "hasweight",
    label: "HAS WEIGHT",
    doFilter: saleLotsHaveWeight,
  },
];
