import React, { useEffect, useMemo, useState } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import { uniq } from "lodash";
import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { patchSaleLot, sellWithBidderRegistration, setSetting } from "actions";

import { CreateAndSelectSaleLotButton } from "components/AgGrid/panels/QuickEditSaleLot";
import { Button } from "components/Form";
import {
  BusinessField,
  BuyerAwareBuyerWayField,
  SaleRoundPickerField,
} from "components/Form/Fields";
import { AgeField } from "components/Form/Fields/AgeField";
import { BreedField } from "components/Form/Fields/BreedField";
import { BusinessPICField } from "components/Form/Fields/PICField";
import { PricingTypeField } from "components/Form/Fields/PricingType";
import { SexField } from "components/Form/Fields/SexField";
import { UnitPrice } from "components/Form/FormikControls";
import { QuickSellBidderNumberInput } from "components/Form/QuickSellBidderNumberInput";
import { SettingSwitch } from "components/Form/Settings";
import { Row } from "components/Layout";
import { MinimalSaleLotCard } from "components/LotCard/MinimalSaleLotCard";

import { SaleTypes } from "constants/sale";
import { Settings } from "constants/settings";

import { calculateTotalPriceCents, getUnitPriceString } from "lib";

import {
  getBidders,
  getCurrentSale,
  getRounds,
  getSaleLots,
  getSetting,
  getSpeciesAttributeOptions,
  selectBidderIdBySaleLotIdLookup,
  selectConsignmentIdBySaleLotIdLookup,
  selectDeploymentIdBySaleLotIdLookup,
  selectPropertyEnrichedBusinessByBusinessIdLookup,
  selectVendorPropertyIdBySaleLotIdLookup,
} from "selectors";

import { useFieldSetter } from "hooks";

const validationSchema = Yup.object().shape({
  bidderNumber: Yup.number()
    .nullable()
    .positive("This field be a positive number")
    .typeError("This field must be a number"),
  buyerId: Yup.string()
    .nullable()
    // Ensure we either have a buyer or a bidder number
    .test(
      "buyerMustExistIfUnitPriceAndNoBidderNumber",
      "Required",
      (buyerId, testContext) => !!buyerId || !!testContext.parent.bidderNumber,
    ),
  buyerWay: Yup.object()
    .shape({
      id: Yup.string(),
      string: Yup.string(),
    })
    .nullable(),
  destinationPropertyId: Yup.string().nullable().typeError("Invalid PIC"),
  unitPrice: Yup.number()
    .nullable()
    .typeError("This field must be a number")
    .required("Please enter a price"),
});

const SubmitButton = ({ onClick, type, label, disabled, dataTour }) => {
  // Combine form submit with an extra side effect.
  const { submitForm, isValid } = useFormikContext();
  const submitWithExtra = () => {
    submitForm().then(() => {
      isValid && onClick();
    });
  };

  return (
    <Button
      data-tour={dataTour}
      type={type}
      onClick={submitWithExtra}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

const BuyerFields = ({
  isAutoFocusing,
  readOnly,
  buyerInlineButton = null,
  isUsingRegisteredBidders = false,
}) => {
  const setDestinationPropertyId = useFieldSetter("destinationPropertyId");
  const setBuyerWay = useFieldSetter("buyerWay");
  const setBidderNumber = useFieldSetter("bidderNumber");
  const businesses = useSelector(
    selectPropertyEnrichedBusinessByBusinessIdLookup,
  );

  function onAfterBuyerChanged(buyerId) {
    setBuyerWay(null);
    if (!buyerId) {
      setDestinationPropertyId(null);
      return;
    }
    const business = businesses[buyerId] || {};
    setDestinationPropertyId(business.defaultProperty?.id || null);
    // clear the bidder number when we change buyer details
    // because we are not updating bidder rego info here
    setBidderNumber(null);
  }

  return (
    <>
      <Grid item xs={12}>
        <BusinessField
          name="buyerId"
          label="Buyer"
          autoFocus={isAutoFocusing}
          disabled={readOnly}
          onChangeExtra={onAfterBuyerChanged}
          inlineButton={buyerInlineButton}
          activeOnly
        />
      </Grid>
      <Grid item xs={12}>
        <BuyerAwareBuyerWayField
          name="buyerWay"
          label={isUsingRegisteredBidders ? "Buyer Way/Account" : "Way"}
          buyerField="buyerId"
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessPICField
          name="destinationPropertyId"
          label="PIC"
          businessField="buyerId"
          disabled={readOnly}
        />
      </Grid>
    </>
  );
};

const QuickSellBidderAndBuyerSaleLotFields = React.memo(() => {
  const dispatch = useDispatch();

  const useQuickBuyerInput = useSelector(
    getSetting(Settings.useQuickBuyerInput),
  );

  const setQuickBidderInput = () =>
    dispatch(setSetting(Settings.useQuickBuyerInput, false));

  const setQuickBuyerInput = () =>
    dispatch(setSetting(Settings.useQuickBuyerInput, true));

  return (
    <>
      <BuyerFields
        readOnly={!useQuickBuyerInput}
        buyerInlineButton={
          useQuickBuyerInput
            ? {
                label: "Enter Bidder instead",
                onClick: setQuickBidderInput,
              }
            : null
        }
        isAutoFocusing={useQuickBuyerInput}
        isUsingRegisteredBidders
      />
      <Grid item xs={12}>
        <QuickSellBidderNumberInput
          autoFocus
          inlineButton={
            !useQuickBuyerInput
              ? {
                  label: "Enter Buyer Business instead",
                  onClick: setQuickBuyerInput,
                }
              : null
          }
          enableTemporaryBusinessCreation
          setBidderNumberFromBuyerDetails
          disabled={useQuickBuyerInput}
        />
      </Grid>
    </>
  );
});

function FormInner(props) {
  const { isAutoFocusing, readOnly, createNewLotParams } = props;
  const isUsingRegisteredBidders =
    useSelector(getCurrentSale)?.using_registered_bidders;
  const { values, setFieldValue } = useFormikContext();
  const { saleRoundId } = values;
  const showDraftingFields = useSelector(
    getSetting(Settings.showQuickSellDrafting),
  );
  const { saleLotIds } = createNewLotParams;

  const consignmentId = useSelector(state => {
    const lookup = selectConsignmentIdBySaleLotIdLookup(state);
    const consignmentIds = uniq(saleLotIds.map(id => lookup[id]));
    if (consignmentIds.length === 1) {
      return consignmentIds[0];
    } else {
      return undefined;
    }
  });

  const deploymentId = useSelector(state => {
    const lookup = selectDeploymentIdBySaleLotIdLookup(state);
    const deploymentIds = uniq(saleLotIds.map(id => lookup[id]));
    if (deploymentIds.length === 1) {
      return deploymentIds[0];
    }
  });

  const sexOptions = useSelector(getSpeciesAttributeOptions).sexes;
  const speciesId = useSelector(getCurrentSale).species_id;
  const currentSaleType = useSelector(state => getCurrentSale(state).sale_type);
  const isClearingSale = currentSaleType === SaleTypes.CLEARING;
  const roundLookup = useSelector(getRounds);

  // If there is a matching sex when a round is selected, we want to auto select it
  useEffect(() => {
    const round = roundLookup[saleRoundId] || {};
    const sex = sexOptions.find(sex => sex.label === round.name);
    if (sex) {
      setFieldValue("sexId", sex.value);
    }
  }, [saleRoundId, roundLookup, sexOptions, setFieldValue]);

  return (
    <>
      <Grid item xs={8}>
        <SettingSwitch
          label="Show Drafting Information"
          dataTour="showDraftingInformation"
          setting={Settings.showQuickSellDrafting}
        />
      </Grid>
      <Grid item xs={4}>
        <CreateAndSelectSaleLotButton
          createNewLotParams={createNewLotParams}
          initialValues={{ consignment_id: consignmentId }}
        />
      </Grid>
      <Grid item xs={12}>
        <PricingTypeField
          name="pricingTypeId"
          label="Pricing Type"
          disabled={readOnly}
        />
      </Grid>

      {isUsingRegisteredBidders ? (
        <QuickSellBidderAndBuyerSaleLotFields />
      ) : (
        <BuyerFields
          isAutoFocusing={isAutoFocusing}
          isUsingRegisteredBidders={isUsingRegisteredBidders}
          readOnly={readOnly}
        />
      )}

      <Grid item xs={12}>
        <UnitPrice name="unitPrice" disabled={readOnly} />
      </Grid>
      {showDraftingFields && (
        <>
          {!isClearingSale && (
            <>
              <Grid item xs={12}>
                <SaleRoundPickerField
                  name="saleRoundId"
                  disabled={readOnly}
                  forceAutoComplete
                />
              </Grid>
              <Grid item xs={12}>
                <SexField
                  label="Sex"
                  name="sexId"
                  disabled={readOnly}
                  deploymentId={deploymentId}
                  speciesId={speciesId}
                />
              </Grid>
              <Grid item xs={12}>
                <BreedField
                  label="Breed"
                  name="breedId"
                  disabled={readOnly}
                  deploymentId={deploymentId}
                  speciesId={speciesId}
                />
              </Grid>
              <Grid item xs={12}>
                <AgeField
                  label="Age"
                  name="ageId"
                  disabled={readOnly}
                  deploymentId={deploymentId}
                  speciesId={speciesId}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}

function ActionButtons(props) {
  const {
    closeToolPanel,
    disableAutoFocusing,
    enableAutoFocusing,
    onSave,
    readOnly,
    saleLotIds,
  } = props;

  const dispatch = useDispatch();

  const saleLotLookup = useSelector(getSaleLots);

  const vendorPropertyIdBySaleLotIdLookup = useSelector(
    selectVendorPropertyIdBySaleLotIdLookup,
  );

  const { values } = useFormikContext();

  const handleSaveAndClose = () => {
    closeToolPanel();
    disableAutoFocusing();
  };

  const handleUnsell = values => {
    saleLotIds.forEach(saleLotId => {
      const patch = {
        total_price_cents: 0,
        buyer_id: null,
        buyer_way: null,
        destination_property_id: null,
        id: saleLotId,
        sale_round_id: values.saleRoundId,
        sex_id: values.sexId,
        breed_id: values.breedId,
        age_id: values.ageId,
      };
      dispatch(
        patchSaleLot(patch, {
          changeReason: "Unsell from Quick edit sale lot",
        }),
      );
    });
    onSave();
  };

  const handleNoSale = values => {
    saleLotIds.forEach(saleLotId => {
      const saleLot = saleLotLookup[saleLotId];
      const patch = {
        buyer_id: saleLot.vendor_id,
        buyer_way: null,
        destination_property_id:
          vendorPropertyIdBySaleLotIdLookup[saleLotId] || null,
        total_price_cents: 0,
        id: saleLotId,
        sale_round_id: values.saleRoundId,
        sex_id: values.sexId,
        breed_id: values.breedId,
        age_id: values.ageId,
      };
      dispatch(
        patchSaleLot(patch, {
          changeReason: "No Sale from Quick edit sale lot",
        }),
      );
    });
    onSave();
  };

  const handleSaveAndNext = () => {
    enableAutoFocusing();
  };

  return (
    <Row fullWidth>
      <Button
        data-tour="unsell"
        type="button"
        onClick={() => handleUnsell(values)}
        disabled={readOnly}
      >
        Unsell
      </Button>
      <Button
        data-tour="noSale"
        type="button"
        onClick={() => handleNoSale(values)}
        disabled={readOnly}
      >
        No Sale
      </Button>
      <SubmitButton
        dataTour="save&Close"
        type="button"
        onClick={handleSaveAndClose}
        label="Save & Close"
        disabled={readOnly}
      />
      <Button
        data-tour="save&Next"
        type="submit"
        onClick={handleSaveAndNext}
        disabled={readOnly}
        title="Ensure screen is sorted by pen, then marks for best results."
      >
        Save &amp; Next
      </Button>
    </Row>
  );
}

export const LivestockSaleForm = ({
  saleLotIds,
  onSave,
  isAutoFocusing,
  disableAutoFocusing,
  enableAutoFocusing,
  closeToolPanel,
  readOnly,
  createNewLotParams,
}) => {
  const saleLotLookup = useSelector(getSaleLots);

  const bidderIdBySaleLotIdLookup = useSelector(
    selectBidderIdBySaleLotIdLookup,
  );
  const bidderLookup = useSelector(getBidders);

  const initialValues = useMemo(() => {
    const initialValuesForEachSaleLot = saleLotIds.map(saleLotId => {
      const saleLot = saleLotLookup[saleLotId];
      const bidderId = bidderIdBySaleLotIdLookup[saleLotId];
      const bidder = bidderLookup[bidderId] || {};

      return {
        bidderNumber: bidder ? bidder.registrationNumber : null,
        buyerId: saleLot.buyer_id,
        buyerWay: saleLot.buyer_way,
        destinationPropertyId: saleLot.destination_property_id,
        id: saleLot.id,
        pricingTypeId: saleLot.pricing_type_id,
        totalPriceCents: saleLot.total_price_cents,
        unitPrice: saleLot.total_price_cents
          ? parseFloat(getUnitPriceString(saleLot))
          : null,
        saleRoundId: saleLot.sale_round_id,
        sexId: saleLot.sex_id,
        breedId: saleLot.breed_id,
        ageId: saleLot.age_id,
      };
    });

    // If the current values are the same for all selected lots, use them as the defaults, otherwise dont.
    return initialValuesForEachSaleLot.reduce((acc, cur) => {
      Object.entries(cur).forEach(([key, value]) => {
        // Use isEqual to handle diffing objects (buyerWay)
        if (!isEqual(acc[key], value)) {
          // Default to undefined so we dont overwrite unless explicitly told to do so.
          acc[key] = undefined;
        }
      });
      return acc;
    }, initialValuesForEachSaleLot[0]);
  }, [saleLotIds, bidderIdBySaleLotIdLookup, bidderLookup, saleLotLookup]);

  const dispatch = useDispatch();

  const onSubmit = values => {
    saleLotIds.forEach(saleLotId => {
      const saleLot = saleLotLookup[saleLotId];

      const totalPriceCents = calculateTotalPriceCents({
        unitPrice: values.unitPrice ? parseFloat(values.unitPrice) : 0,
        quantity: saleLot.quantity,
        pricing_type_id: values.pricingTypeId,
        total_mass_grams: saleLot.total_mass_grams,
      });

      const patch = {
        total_price_cents: totalPriceCents,
        quantity: saleLot.quantity,
        destination_property_id: values.destinationPropertyId || null,
        buyer_id: values.buyerId || null,
        buyer_way: values.buyerWay || null,
        pricing_type_id: values.pricingTypeId,
        id: saleLot.id,
        sale_round_id: values.saleRoundId,
        sex_id: values.sexId,
        breed_id: values.breedId,
        age_id: values.ageId,
        bidderNumber: values.bidderNumber,
      };

      // if we entered a bidder number use the bidder
      // registration action that handles adding temporary
      // businesses.
      // Otherwise just run through the update sale lot machinery.
      const updateSaleLotAction = patch.bidderNumber
        ? sellWithBidderRegistration
        : patchSaleLot;

      dispatch(
        updateSaleLotAction(patch, {
          changeReason: "Quick edit sale lot",
          createTempBusinessesForBidders: true,
        }),
      );
    });

    onSave();
  };

  // This ensures that the form is completely reset when
  // the selected Sale Lots change
  const formKey = saleLotIds.join("-");

  const [cardEl, setCardEl] = useState(null);

  return (
    <Formik
      key={formKey}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Grid spacing={2} container>
          <Grid item xs={12}>
            {saleLotIds.map(saleLotId => (
              <MinimalSaleLotCard
                id={saleLotId}
                key={saleLotId}
                slim={saleLotIds.length > 1}
                cardEl={cardEl}
                setCardEl={setCardEl}
              />
            ))}
          </Grid>

          <FormInner
            isAutoFocusing={isAutoFocusing}
            readOnly={readOnly}
            createNewLotParams={createNewLotParams}
          />
          <Grid item xs={12}>
            <ActionButtons
              closeToolPanel={closeToolPanel}
              disableAutoFocusing={disableAutoFocusing}
              enableAutoFocusing={enableAutoFocusing}
              onSave={onSave}
              readOnly={readOnly}
              saleLotIds={saleLotIds}
            />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};
