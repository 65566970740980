import React from "react";

import { Formik } from "formik";
import queryString from "query-string";
import { connect } from "react-redux";

import { requestLogin } from "actions";

import { LoginCard } from "components/Layout/Login";

import { appUpdateStatus } from "constants/system";

import {
  getIsAuthenticated,
  getIsFetchingAuth,
  getAppUpdateStatus,
} from "selectors";

import { checkForSWUpdates } from "registerServiceWorker";

import InnerForm from "./InnerForm";

const initialValues = {
  email: "",
  password: "",
};
class Login extends React.Component {
  componentDidMount() {
    if (document.readyState === "complete") {
      this.checkForUpdates();
    }
  }

  handleSubmit = values => {
    const { requestLogin } = this.props;
    const query = queryString.parse(window.location.search);
    requestLogin(values, query?.returnTo);
  };

  validate = values => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email address is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  checkForUpdates = () => {
    if (navigator.serviceWorker?.getRegistrations()) {
      checkForSWUpdates();
    }
  };

  render() {
    const { isFetching, currentAppUpdateStatus } = this.props;

    const responseError = {};
    // eslint-disable-next-line
    responseError.email = responseError.password = this.props.responseError;
    const updateRequired = [
      appUpdateStatus.LOGOUT_REQUIRED,
      appUpdateStatus.REFRESH_REQUIRED,
    ].includes(currentAppUpdateStatus);

    const disableButton = isFetching || updateRequired;

    return (
      <LoginCard showForgotten disableLoginButton={disableButton}>
        <Formik
          initialValues={initialValues}
          validate={this.validate}
          onSubmit={this.handleSubmit}
        >
          <InnerForm errors={responseError} disableButton={disableButton} />
        </Formik>
      </LoginCard>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: getIsAuthenticated(state),
    isFetching: getIsFetchingAuth(state),
    responseError: state.auth.authentication.errorMessage,
    currentAppUpdateStatus: getAppUpdateStatus(state),
  };
}

const mapDispatchToProps = {
  requestLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
