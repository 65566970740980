import React from "react";

import { faEye, faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "lodash";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";
import { userTypeConfigMap } from "components/UserRoles/constants";

import { ACTION_COLUMN_NAME } from "constants/aggrid";

import { datetimeFormatter } from "lib/agGrid/columns/datetime";
import { openEditUserRoleModal } from "lib/navigation";
import { hasPermission } from "lib/permissions";

function actionsColumnRenderer(props) {
  const { data, userRoleType } = props;

  const { id } = data;

  let icon = faEye;
  let action = "View";
  if (hasPermission(data, userTypeConfigMap[userRoleType].updatePermission)) {
    icon = faPencil;
    action = "Edit";
  }
  const onOpenEditUserRoleModal = () => {
    openEditUserRoleModal(userRoleType, id);
  };
  return (
    <ActionButtonContainer>
      <ActionButton
        data-tour="action-edit-userrole"
        onClick={onOpenEditUserRoleModal}
      >
        <FontAwesomeIcon icon={icon} />
        &nbsp;{action}
      </ActionButton>
    </ActionButtonContainer>
  );
}

function saleyardsGetter(params) {
  const value = get(params.data, params.colDef.field);
  // A bit yuck - we don't know anything about saleyards we're offline, the model doesn't yet
  // have all the data.  (We could rummage in current active user role?)
  if (value) {
    return value.map(sy => sy.name).join(", ");
  }
  return "-";
}

function nameArrayGetter(params) {
  const value = get(params.data, params.colDef.field);
  if (value) {
    return value.join(", ");
  }
  return "-";
}

export const getColumnDefs = userRoleType => {
  return [
    {
      headerName: "AgriNous User Details",
      children: [
        {
          headerName: "Email",
          field: "email",
        },
        {
          headerName: "Last Name",
          field: "lastName",
          sort: "asc",
        },
        {
          headerName: "First Name",
          field: "firstName",
          sort: "asc",
        },
        {
          headerName: "Phone",
          field: "phoneNumber",
        },
        // Hidden for now - need to consider what this means to a user and if it provides any m ore use.
        // {
        //   headerName: "User Active?",
        //   field: "isActive",
        //   cellRenderer: booleanRenderer,
        // },
        {
          headerName: "Date Joined",
          field: "dateJoined",
          valueFormatter: datetimeFormatter,
          filter: "agDateColumnFilter",
        },
      ],
    },
    {
      headerName: "Role Within Your Organisation",
      children: [
        {
          headerName: "User Level",
          field: "userLevel",
        },
        {
          headerName: "Role Invitation Sent",
          field: "roleInvitationSentDate",
          valueFormatter: datetimeFormatter,
          filter: "agDateColumnFilter",
        },
        {
          headerName: "Role Invitation Accepted",
          field: "roleInvitationAcceptedDate",
          valueFormatter: datetimeFormatter,
          filter: "agDateColumnFilter",
        },
        userTypeConfigMap[userRoleType].showSaleyards && {
          headerName: "Saleyards",
          field: "saleyards",
          valueGetter: saleyardsGetter,
        },

        userTypeConfigMap[userRoleType].showDeployments && {
          headerName: "Deployments",
          field: "deploymentNames",
          valueGetter: nameArrayGetter,
        },

        userTypeConfigMap[userRoleType].showDeployments && {
          headerName: "Emails From",
          field: "receiveConsignedToEmailsFromNames",
          valueGetter: nameArrayGetter,
        },
      ].filter(Boolean),
    },
    {
      field: "userRoleType",
      headerName: ACTION_COLUMN_NAME,
      cellRenderer: actionsColumnRenderer,
      cellRendererParams: {
        userRoleType,
      },
      pinned: "right",
      menuTabs: ["columnsMenuTab"],
    },
  ].filter(Boolean);
};
export const defaultColumnDef = {
  sortable: true,
  resizable: true,
  enableCellChangeFlash: true,
  filter: true,
};
