import {
  ADD_SALE_FAILURE,
  ADD_SALE_SUCCESS,
  DELETE_SALE_FAILURE,
  DELETE_SALE_SUCCESS,
  DEPLOYMENT_SALE,
  GET_CONSIGNABLE_SALES,
  GET_DASHBOARD_DATA,
  RECEIVE_WATCHER_ENTRY,
  SALE,
  UPDATE_SALE_FAILURE,
  UPDATE_SALE_SUCCESS,
} from "constants/actionTypes";
import { UNIX_START_TIME } from "constants/time";

import { deserializeLivestockSale } from "lib/sale";

const initialState = {
  byId: {},
  consignableSales: {
    isFetching: false,
    data: {},
  },
  isFetching: false,
  errorMessage: "",
  lastModifiedTimestamp: UNIX_START_TIME,
};

const getLivestockSaleIdByDeploymentSaleId = deploymentSaleId => state => {
  const sales = state.byId;
  const livestockSale = Object.values(sales || {}).find(sale =>
    sale.deployment_sales.find(
      deploymentSale => deploymentSale.deployment_sale_id === deploymentSaleId,
    ),
  );
  return livestockSale ? livestockSale.livestocksale_id : null;
};

const sales = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONSIGNABLE_SALES.REQUEST:
      return {
        ...state,
        consignableSales: {
          ...initialState.consignableSales,
          isFetching: true,
        },
      };
    case GET_CONSIGNABLE_SALES.SUCCESS:
      const consignableSales = action.sales.reduce((acc, sale) => {
        acc[sale.livestocksale_id] = deserializeLivestockSale(sale);
        return acc;
      }, {});
      return {
        ...state,
        consignableSales: {
          isFetching: false,
          data: consignableSales,
        },
      };

    case GET_CONSIGNABLE_SALES.FAILURE:
      return {
        ...state,
        consignableSales: {
          ...initialState.consignableSales,
          isFetching: false,
        },
      };

    case SALE.FETCH_BULK.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case SALE.FETCH.SUCCESS: {
      const livestockSale = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [livestockSale.livestocksale_id]: livestockSale,
        },
      };
    }

    case SALE.FETCH_BULK.SUCCESS: {
      const allSales = action.payload.reduce((map, sale) => {
        // Dont completely overwrite the sale object because we are also storing
        // the sale summary information in the same location.
        map[sale.livestocksale_id] = {
          ...state.byId[sale.livestocksale_id],
          ...sale,
        };

        return map;
      }, {});

      return {
        ...state,
        byId: allSales,
        isFetching: false,
        lastModifiedTimestamp: action.meta.lastModifiedTimestamp,
      };
    }

    case SALE.FETCH_CHANGES.SUCCESS: {
      // Update state when fetching changes.
      const { lastModifiedTimestamp } = action.meta;

      const newState =
        action.payload.length > 0
          ? action.payload.reduce(
              (map, obj) => {
                if (obj.deleted === true) {
                  delete map[obj.id];
                } else {
                  map[obj.livestocksale_id] = {
                    ...state.byId[obj.livestocksale_id],
                    ...obj,
                  };
                }
                return map;
              },
              { ...state.byId },
            )
          : state.byId;

      return {
        ...state,
        byId: newState,
        isFetching: false,
        lastModifiedTimestamp,
      };
    }

    case SALE.FETCH_BULK.FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case ADD_SALE_SUCCESS: {
      if (action.sale.livestocksale_id) {
        const { sale } = action;
        const { livestocksale_id, deployment_sales, ...updatedSale } = sale;
        // A saleyard admin receives a new deployment sale as a new "livestock sale".
        // Keep the deployment sales that are here already, and update everything else.
        const newDeploymentSaleIds = deployment_sales.map(
          ds => ds.deployment_sale_id,
        );
        const mergedDeploymentSales = (
          state.byId[livestocksale_id]?.deployment_sales || []
        )
          .filter(ds => !newDeploymentSaleIds.includes(ds.deployment_sale_id))
          .concat(deployment_sales);

        return {
          ...state,
          byId: {
            ...state.byId,
            [livestocksale_id]: {
              ...updatedSale,
              deployment_sales: mergedDeploymentSales,
              livestocksale_id,
            },
          },
        };
      }
      return state;
    }

    case DELETE_SALE_SUCCESS: {
      const newSales = { ...state.byId };
      delete newSales[action.saleID];

      return {
        ...state,
        byId: newSales,
      };
    }

    case UPDATE_SALE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.livestocksale_id]: action.payload,
        },
      };

    case UPDATE_SALE_FAILURE:
    case DELETE_SALE_FAILURE:
    case ADD_SALE_FAILURE:
      // do nothing
      return state;

    case GET_DASHBOARD_DATA.REQUEST:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          isFetching: true,
        },
      };

    case GET_DASHBOARD_DATA.SUCCESS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          data: action.dashboard,
          isFetching: false,
        },
      };
    case RECEIVE_WATCHER_ENTRY: {
      // If we're coming from a watcher entry point, make sure the attached sale data is available.
      const { livestockSale } = action;
      if (livestockSale) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [livestockSale.livestocksale_id]: {
              ...livestockSale,
            },
          },
        };
      } else {
        return state;
      }
    }
    case DEPLOYMENT_SALE.UPDATE.REQUEST: {
      const { deploymentSaleId, deploymentSale, livestockSaleId } = action.meta;
      return {
        ...state,
        byId: {
          ...state.byId,
          [livestockSaleId]: {
            ...state.byId[livestockSaleId],
            deployment_sales: state.byId[livestockSaleId].deployment_sales.map(
              ds =>
                ds.deployment_sale_id === deploymentSaleId
                  ? { ...ds, ...deploymentSale }
                  : ds,
            ),
          },
        },
      };
    }

    case DEPLOYMENT_SALE.COMMENT.REQUEST: {
      const { payload, id } = action;
      const livestockSaleId = getLivestockSaleIdByDeploymentSaleId(id)(state);
      const updatedLivestockSale = {
        ...state.byId[livestockSaleId],
        deployment_sales: state.byId[livestockSaleId].deployment_sales.map(
          ds =>
            ds.deployment_sale_id === id
              ? {
                  ...ds,
                  comment_ids: ds.comment_ids
                    ? [...ds.comment_ids, payload.id]
                    : [payload.id],
                }
              : ds,
        ),
      };

      return {
        ...state,
        byId: {
          ...state.byId,
          [livestockSaleId]: updatedLivestockSale,
        },
      };
    }

    case DEPLOYMENT_SALE.DELETE.REQUEST: {
      const { deploymentSaleId, livestockSaleId } = action.meta;
      return {
        ...state,
        byId: {
          ...state.byId,
          [livestockSaleId]: {
            ...state.byId[livestockSaleId],
            deployment_sales: state.byId[
              livestockSaleId
            ].deployment_sales.filter(
              ds => ds.deployment_sale_id !== deploymentSaleId,
            ),
          },
        },
      };
    }

    default:
      return state;
  }
};

export default sales;
