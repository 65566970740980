import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import { createSelector } from "reselect";

import {
  getFieldsWithAlternativeValues,
  getNamespacedCompareFieldNames,
} from "components/BusinessForm/lib";

import {
  AlternativeComparableKeys,
  UNKNOWN_BUSINESS_NAME,
} from "constants/businesses";

import { EMPTY_ARRAY, EMPTY_OBJECT } from "lib";

import { isBusinessActive } from "lib/businesses";
import { getLivestockSaleId } from "lib/navigation";
import { getDefaultProperty } from "lib/properties";
import {
  getBuyerHashFromBusinessAndBuyerWay,
  getBuyerHashFromPenOwner,
  getBuyerHashFromSaleLot,
} from "lib/saleLot";

import {
  createLookupCombiner,
  createLookupSelectors,
  getActiveLivestockAgentDeployment,
  getAgencies,
  getAuctionPens,
  getBusinesses,
  getCurrentDeploymentSalesList,
  getProperties,
  getSaleLots,
  getSelectableBranches,
  getSelectedSaleyard,
  selectBusinessIdsByAgencyIdLookup,
  selectBusinessIdsByDeploymentMasterBusinessIdLookup,
  selectBuyerIdBySaleLotIdLookup,
  selectSaleLotIdsByBuyerIdLookup,
  selectVendorIdBySaleLotIdLookup,
} from "selectors";

export const getBusinessById = businessId => state =>
  getBusinesses(state)[businessId] || null;

export const getBuyerIdBySaleLotId = saleLotId => state =>
  selectBuyerIdBySaleLotIdLookup(state)[saleLotId] || null;

/**
 * Returns a Deployment Business, keyed by Sale Lot Id
 * Requires:
 *  - `Businesses`
 *  - `Sale Lots`
 * @type {function(state): Object<string, object>}
 */
export const [selectBuyerBySaleLotIdLookup, getBuyerBySaleLotIdLookup] =
  createLookupSelectors(
    [selectBuyerIdBySaleLotIdLookup, getBusinesses],
    createLookupCombiner(
      (buyerId, businessByIdLookup) => businessByIdLookup[buyerId],
    ),
  );

export const getPropertiesArray = createSelector([getProperties], properties =>
  Object.values(properties),
);

export const getBranchMap = createSelector([getSelectableBranches], branches =>
  branches.reduce((lookup, branch) => {
    lookup[branch.id] = branch.name;
    return lookup;
  }, {}),
);

export const selectPropertiesByBusinessIdLookup = createSelector(
  [getBusinesses, getProperties],
  (businesses, properties) =>
    Object.entries(businesses).reduce((acc, [businessId, business]) => {
      acc[businessId] = business.properties
        .filter(property => property.isShown)
        .map(property => ({
          ...properties[property.id],
          ...property,
          defaultForSaleyardIds:
            business.defaultDestinations
              ?.filter(destination => destination.propertyId === property.id)
              .map(destination => destination.saleyardId) || [],
        }));
      return acc;
    }, {}),
);

export const getPropertiesByBusinessId = businessId => state =>
  selectPropertiesByBusinessIdLookup(state)[businessId] || EMPTY_ARRAY;

export const selectPropertyEnrichedBusinessByBusinessIdLookup = createSelector(
  [
    getBusinesses,
    selectPropertiesByBusinessIdLookup,
    getSelectedSaleyard,
    getBranchMap,
  ],
  (businesses, propertiesByBusinessIdLookup, saleyard, branchMap) =>
    Object.values(businesses).reduce((acc, business) => {
      const businessWithProperties = {
        ...business,
        branchName: branchMap[business.branchId] || "",
        properties: propertiesByBusinessIdLookup[business.id],
      };

      // Note - outside of a saleyard context (eg edit businesses screen) this will apply a blank
      // value.
      businessWithProperties.defaultProperty =
        getDefaultProperty(businessWithProperties, saleyard.saleyard_id) || {};

      acc[business.id] = businessWithProperties;
      return acc;
    }, {}),
);

export const getPropertyEnrichedBusinessByBusinessId = businessId => state =>
  selectPropertyEnrichedBusinessByBusinessIdLookup(state)[businessId] ||
  EMPTY_OBJECT;

// getLivestockSaleId() is NOT part of state, so a change to it doesn't invalidate a
// selector by default - force it as a dependency into the second argument.
const selectActivePropertyEnrichedBusinessByBusinessIdLookupInternal =
  createSelector(
    [
      selectPropertyEnrichedBusinessByBusinessIdLookup,
      (state, livestockSaleId) => livestockSaleId,
    ],
    (business, livestockSaleId) =>
      Object.entries(business).reduce((acc, [businessId, business]) => {
        if (isBusinessActive(business, livestockSaleId)) {
          acc[businessId] = business;
        }
        return acc;
      }, {}),
  );

export const selectActivePropertyEnrichedBusinessByBusinessIdLookup = state =>
  selectActivePropertyEnrichedBusinessByBusinessIdLookupInternal(
    state,
    getLivestockSaleId(),
  );

// Builds a set of lookups of typeoflookup => { keyword => [ {business, property}, ... ], ... }
export const getKeywordLookups = createSelector(
  [selectActivePropertyEnrichedBusinessByBusinessIdLookup],
  businesses => {
    const addToLookup = (lkp, kw, business, property) => {
      // Don't search for "Unknown Business"
      const kwLower = kw?.toLowerCase() || "";
      if (business && business.name === UNKNOWN_BUSINESS_NAME) {
        return;
      }
      if (!lkp[kwLower]) {
        lkp[kwLower] = [];
      }
      lkp[kwLower].push({
        business,
        property,
      });
    };

    const lookups = {
      businesses: {},
      PICs: {},
      businessPICs: {},
    };

    Object.values(businesses).forEach(business => {
      addToLookup(lookups.businesses, business.name, business, null);
      addToLookup(
        lookups.businesses,
        business.publicDisplayName,
        business,
        null,
      );
      business.name?.split(/\s+/).forEach(token => {
        // Return just the business for each token.
        addToLookup(lookups.businesses, token, business, null);
        if (business.shortCode) {
          business.shortCode.split(/\s+/).forEach(shortCodeToken => {
            addToLookup(lookups.businesses, shortCodeToken, business, null);
          });
        }
        if (business.shortCodeSaleyard) {
          business.shortCodeSaleyard.split(/\s+/).forEach(shortCodeToken => {
            addToLookup(lookups.businesses, shortCodeToken, business, null);
          });
        }
        business.properties.forEach(property => {
          // Return the business and property for each token
          addToLookup(lookups.businessPICs, token, business, property);
        });
      });
      business.properties.forEach(property => {
        // Return business and property for each PIC
        addToLookup(lookups.businessPICs, property.PIC, business, property);
        // And full business name.
        addToLookup(lookups.businessPICs, business.name, business, property);
        // And Public Display Name
        addToLookup(
          lookups.businessPICs,
          business.publicDisplayName,
          business,
          property,
        );
        // Return just the property for each PIC
        addToLookup(lookups.PICs, property.PIC, null, property);
        // And name if relevant
        if (property.name) {
          addToLookup(lookups.businessPICs, property.name, business, property);
          addToLookup(lookups.PICs, property.name, null, property);
        }
      });
    });
    return lookups;
  },
);

export const selectAgencyBusinessByBusinessId = createSelector(
  [getBusinesses, getAgencies],
  (businessByIdLookup, agencyByIdLookup) => {
    const agencies = Object.values(agencyByIdLookup);
    return Object.entries(businessByIdLookup).reduce(
      (acc, [masterBusinessId, masterBusiness]) => {
        const relatedBusinessIds = masterBusiness.supplementary_ids || [];
        const relatedBusinesses = relatedBusinessIds.map(
          businessId => businessByIdLookup[businessId],
        );
        acc[masterBusinessId] = agencies.reduce(
          (acc, agency) => {
            acc[agency.id] =
              relatedBusinesses.find(
                business => business.agencyId === agency.id,
              ) || masterBusiness;
            return acc;
          },
          { master: masterBusiness },
        );
        return acc;
      },
      {},
    );
  },
);

export const getAgencyBusinessById =
  (businessId, agencyId = null) =>
  state => {
    const businesses = selectAgencyBusinessByBusinessId(state)[businessId];
    return businesses ? businesses[agencyId] : null;
  };

export const selectActiveLivestockAgentDeploymentMasterBusinessId =
  createSelector(
    [
      getActiveLivestockAgentDeployment,
      selectBusinessIdsByDeploymentMasterBusinessIdLookup,
    ],
    (deployment, businessIdsByDeploymentMasterBusinessIdLookup) => {
      // should ship this off to its own selector a la selectCurrentEffectiveDeploymentId
      const activeDeploymentId = deployment.id;

      // get the list of Master Business Ids which relate to the current role's first Deployment's Id
      const deploymentMasterBusinessIds =
        businessIdsByDeploymentMasterBusinessIdLookup[activeDeploymentId] || [];
      return deploymentMasterBusinessIds[0] || null;
    },
  );

// Get Names from buyer id and sort alphabetically then return array of sorted Ids
// (converted to lowercase so lodash sorts properly)
export const selectAlphabeticalBuyerIds = createSelector(
  [selectSaleLotIdsByBuyerIdLookup, getBusinesses],
  (saleLotsByBuyerId, businesses) =>
    sortBy(
      Object.keys(saleLotsByBuyerId)
        .map(buyerId => businesses[buyerId])
        .filter(Boolean),
      o => o.name.toLowerCase(),
    ).map(business => business.id),
);

export const selectBuyerWaysByBusinessIdLookup = createSelector(
  [getBusinesses],
  businesses =>
    Object.entries(businesses).reduce((lookup, [businessId, business]) => {
      // Collect all BuyerWays for deployment/saleyard business
      lookup[businessId] = Object.values(business.buyerWays).filter(
        buyerWay => buyerWay.isShown,
      );
      return lookup;
    }, {}),
);

export const getBuyerWaysByBusinessId = businessId => state =>
  selectBuyerWaysByBusinessIdLookup(state)[businessId] || EMPTY_ARRAY;

const selectUnknownBusinessesList = createSelector(
  [getBusinesses],
  businesses =>
    Object.values(businesses).filter(
      business => business.name === UNKNOWN_BUSINESS_NAME,
    ),
);

export const selectUnknownBusinessIds = createSelector(
  [selectUnknownBusinessesList],
  unknownBusinessesList =>
    uniq(unknownBusinessesList.map(business => business.id)),
);

export const selectUnknownBusinessIdsByAgencyIdLookup = createSelector(
  [getCurrentDeploymentSalesList, selectUnknownBusinessesList],
  (currentDeploymentSalesList, unknownBusinesses) =>
    currentDeploymentSalesList.reduce((lookup, deploymentSale) => {
      lookup[deploymentSale.livestock_agency_id] = Object.values(
        unknownBusinesses,
      )
        .filter(
          business => business.deployment_id === deploymentSale.deployment_id,
        )
        .map(business => business.id);
      return lookup;
    }, {}),
);

export const getUnknownBusinessIdsByAgencyId = deploymentSaleId => state =>
  selectUnknownBusinessIdsByAgencyIdLookup(state)[deploymentSaleId];

export const selectUnknownBusinessIdsByDeploymentSaleIdLookup = createSelector(
  [getCurrentDeploymentSalesList, selectUnknownBusinessesList],
  (currentDeploymentSalesList, unknownBusinesses) =>
    currentDeploymentSalesList.reduce((lookup, deploymentSale) => {
      lookup[deploymentSale.deployment_sale_id] = Object.values(
        unknownBusinesses,
      )
        .filter(
          business => business.deployment_id === deploymentSale.deployment_id,
        )
        .map(business => business.id);
      return lookup;
    }, {}),
);

export const selectVendorNameBySaleLotIdLookup = createSelector(
  [selectVendorIdBySaleLotIdLookup, getBusinesses],
  createLookupCombiner(
    (vendorId, businessesLookup) => businessesLookup[vendorId]?.name,
  ),
);

export const selectBuyerDisplayNameBySaleLotIdLookup = createSelector(
  [getSaleLots, getBusinesses],
  (saleLots, businesses) => {
    return Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      if (saleLot.buyer_id) {
        const buyerName = businesses[saleLot.buyer_id]?.name;
        acc[saleLotId] = [buyerName, saleLot.buyer_way?.name]
          .filter(Boolean)
          .join(" - ");
      }
      return acc;
    }, {});
  },
);

export const getBuyerDisplayNameBySaleLotId = saleLotId => state =>
  selectBuyerDisplayNameBySaleLotIdLookup(state)[saleLotId];

const selectBusinessBuyerAndBuyerWayIdsByBuyerHashLookup = createSelector(
  [getBusinesses],
  businesses =>
    Object.values(businesses).reduce((lookup, business) => {
      const noBuyerWayBuyerHash = getBuyerHashFromBusinessAndBuyerWay(
        business,
        {},
      );
      lookup[noBuyerWayBuyerHash] = {
        buyerId: business.id,
        buyerWayId: undefined,
        buyerWayName: undefined,
        buyerHash: noBuyerWayBuyerHash,
      };

      business.buyerWays.forEach(buyerWay => {
        const buyerHash = getBuyerHashFromBusinessAndBuyerWay(
          business,
          buyerWay,
        );
        lookup[buyerHash] = {
          buyerId: business.id,
          buyerWayId: buyerWay?.id,
          buyerWayName: buyerWay?.name,
          buyerHash,
        };
      });
      return lookup;
    }, {}),
);

const selectSaleLotBuyerAndBuyerWayIdsByBuyerHashLookup = createSelector(
  [getSaleLots],
  saleLots =>
    Object.values(saleLots).reduce((lookup, saleLot) => {
      const buyerHash = getBuyerHashFromSaleLot(saleLot);

      lookup[buyerHash] = {
        buyerId: saleLot.buyer_id,
        buyerWayId: saleLot.buyer_way?.id,
        buyerWayName: saleLot.buyer_way?.name,
        buyerHash,
      };
      return lookup;
    }, {}),
);

const selectPenOwnerBuyerAndBuyerWayIdsByBuyerHashLookup = createSelector(
  [getAuctionPens],
  auctionPens =>
    Object.values(auctionPens).reduce((lookup, auctionPen) => {
      (auctionPen?.penOwners || []).forEach(penOwner => {
        const buyerHash = getBuyerHashFromPenOwner(penOwner);
        lookup[buyerHash] = {
          buyerId: penOwner.businessId,
          buyerWayId: penOwner.buyerWay?.id,
          buyerWayName: penOwner.buyerWay?.name,
          buyerHash,
        };
      });
      return lookup;
    }, {}),
);

// A buyer/buyer way combination may exist on a sale lot, or a pen owner, even if it has been deleted from the
// business itself - as such we need to source the buyer hashes from all possible locations.
export const selectAllBuyerAndBuyerWayIdsByBuyerHashLookup = createSelector(
  [
    selectBusinessBuyerAndBuyerWayIdsByBuyerHashLookup,
    selectSaleLotBuyerAndBuyerWayIdsByBuyerHashLookup,
    selectPenOwnerBuyerAndBuyerWayIdsByBuyerHashLookup,
  ],
  (
    businessBuyerAndBuyerWayIdsByBuyerHashLookup,
    saleLotBuyerAndBuyerWayIdsByBuyerHashLookup,
    penOwnerBuyerAndBuyerWayIdsByBuyerHashLookup,
  ) => ({
    ...businessBuyerAndBuyerWayIdsByBuyerHashLookup,
    ...saleLotBuyerAndBuyerWayIdsByBuyerHashLookup,
    ...penOwnerBuyerAndBuyerWayIdsByBuyerHashLookup,
  }),
);
export const getBuyerAndBuyerWayIdsByBuyerHash = buyerHash => state =>
  selectAllBuyerAndBuyerWayIdsByBuyerHashLookup(state)[buyerHash] || null;

export const selectBusinessesByAgencyIdLookup = createSelector(
  [selectBusinessIdsByAgencyIdLookup, getBusinesses],
  (businessIdsByAgencyIdLookup, businesses) => {
    const result = {};

    const businessMapfn = id => businesses[id];

    for (const agencyId of Object.keys(businessIdsByAgencyIdLookup)) {
      result[agencyId] =
        businessIdsByAgencyIdLookup[agencyId]?.map(businessMapfn) || [];
    }
    return result;
  },
);

export const getBusinessesByAgencyId = agencyId => state =>
  selectBusinessesByAgencyIdLookup(state)[agencyId];

export const selectOutOfSyncFieldNamesByBusinessId = createSelector(
  [getBusinesses],
  createLookupCombiner(business => {
    const outOfSyncFields = [].concat(
      getFieldsWithAlternativeValues(AlternativeComparableKeys, business),
      ...business.properties.map((property, idx) =>
        getFieldsWithAlternativeValues(
          getNamespacedCompareFieldNames(
            "properties",
            AlternativeComparableKeys,
            idx,
          ),
          property,
        ),
      ),
      ...business.emailRecipients.map((emailRecipient, idx) =>
        getFieldsWithAlternativeValues(
          getNamespacedCompareFieldNames(
            "emailRecipients",
            AlternativeComparableKeys,
            idx,
          ),
          emailRecipient,
        ),
      ),
    );
    return outOfSyncFields;
  }),
);

export const getOutOfSyncFieldNamesByBusinessId = businessId => state =>
  selectOutOfSyncFieldNamesByBusinessId(state)[businessId];

const shouldChargeGSTReducer = business => {
  // shouldChargeGSTOverride overrides everything
  if (typeof business.shouldChargeGSTOverride === "boolean") {
    return business.shouldChargeGSTOverride;
  }
  // HobbyFarmers are GST exempt
  if (business.isHobbyFarmer) {
    return false;
  }
  // return the isGSTRegistered flag when explicitly set true or false
  if (typeof business.isGSTRegistered === "boolean") {
    return business.isGSTRegistered;
  }
  // otherwise, do not charge GST
  return false;
};

export const [
  selectShouldChargeGSTByBusinessIdLookup,
  getShouldChargeGSTByBusinessId,
] = createLookupSelectors(
  [getBusinesses],
  createLookupCombiner(shouldChargeGSTReducer),
);

// getLivestockSaleId() is NOT part of state, so a change to it doesn't invalidate a
// selector by default - force it as a dependency into the second argument.
const selectActiveBusinessesInternal = createSelector(
  [getBusinesses, (state, livestockSaleId) => livestockSaleId],
  (businesses, livestockSaleId) =>
    Object.fromEntries(
      Object.entries(businesses).filter(([, business]) =>
        isBusinessActive(business, livestockSaleId),
      ),
    ),
);

export const getActiveBusinesses = state =>
  selectActiveBusinessesInternal(state, getLivestockSaleId());
