import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import { object } from "yup";
import { v4 as uuidv4 } from "uuid";

import { useCounter, useFieldSetter } from "hooks";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import {
  AutoIncrementingPenInput,
  CheckBox,
  Input,
  useSubmitHandler,
  withNamespace,
} from "components/Form/FormikControls";
import Grid from "@material-ui/core/Grid";
import { Paper } from "components/Paper";

import { Button, SecondaryButton } from "components/Form";
import { Row } from "components/Layout";
import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import { PricingType } from "components/SellingSaleLotForm/PricingType";
import { useDispatch, useSelector } from "react-redux";
import { addSaleLotWithPens, setSetting } from "actions";
import { calculateTotalPriceCents } from "lib";
import {
  AgencyInputField,
  BusinessField,
  BuyerAwareBuyerWayField,
  HasDeploymentMarks,
  OtherMarkingField,
  ProductSelectField,
  SaleRoundPickerField,
} from "components/Form/Fields";
import { BreedField } from "components/Form/Fields/BreedField";
import {
  getCurrentAgenciesOptions,
  getCurrentSale,
  getCurrentSaleType,
  getCurrentSpeciesId,
  getDeploymentSaleById,
  getNextEmptyPenNumber,
  getSaleRoundsOptions,
  getSetting,
  selectConsignmentIdsByVendorIdLookup,
  selectDeploymentSaleIdByAgencyIdLookup,
  selectPropertyEnrichedBusinessByBusinessIdLookup,
} from "selectors";
import { positiveWhenSet } from "components/GeneralSaleLotForm/validationSchema";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { ApiModel } from "constants/loading";
import PenningConflictNotice from "components/PenningSaleLotForm/PenningConflictNotice";
import { PenTypes } from "constants/auctionPens";
import { ForSaleyardAdmin } from "containers/ForUserType";
import { requiredWhenSet } from "lib/validation";
import { Settings } from "constants/settings";
import { isEmpty } from "lodash";
import { LotNumber } from "components/DetailedSaleLotModal/LotNumber";
import {
  ForExternalAgentSale,
  ForHooksOrPaddockOrExternalAgentSales,
  ForNotHooksOrPaddockOrExternalAgentSales,
} from "containers/ForSaleType";
import { SaleTypes, TransactionalSaleTypes } from "constants/sale";
import InlineConsignmentSelect from "components/SaleLotForms/InlineConsignmentSelect";
import { BusinessPICField } from "components/Form/Fields/PICField";
import MarkingField from "components/Form/Fields/MarkingField";

type FormValues = Partial<SaleLot> & {
  auction_pen?: Partial<AuctionPen>;
  vendor_id: string;
  vendor_property_id: string;
  rememberRoundVendorProductBreedAndBuyer: boolean;
  rememberAgency: boolean;
  agency_id: number;
};

interface CalculatePriceOption {
  unitPrice: number;
  quantity: number;
  pricing_type_id: number;
  total_mass_grams: number;
}

function QuickCreateForm(): React.JSX.Element {
  const formikProps = useFormikContext<FormValues>();
  const { touched, errors, values, setFieldValue, setFieldTouched } =
    formikProps;

  const storedValues = useSelector(
    getSetting(Settings.livestockQuickCreateValues),
  );

  // on submit we store the remembered values in global state. if values exist in state,
  // apply them to untouched fields.
  // This was once done in initial values which was cleaner but wouldn't work without
  // enableReinitialize being set to true
  // https://github.com/jaredpalmer/formik/issues/3129
  if (storedValues) {
    Object.entries(storedValues).forEach(([fieldName, storedValue]) => {
      const value = values[fieldName];
      const isTouched = touched[fieldName];
      if (!isTouched && value !== storedValue) {
        setFieldValue(fieldName, storedValue).then(() =>
          setFieldTouched(fieldName, true),
        );
      }
    });
  }

  const saleAgencies = useSelector(getCurrentAgenciesOptions(false));

  const setBuyerWay = useFieldSetter("buyerWay");

  const setDestinationPropertyId = useFieldSetter("destinationPropertyId");

  const speciesId = useSelector(getCurrentSpeciesId);
  const rounds = useSelector(getSaleRoundsOptions());

  const { agency_id: agencyId } = values;

  const deploymentSaleByAgencyLookup = useSelector(
    selectDeploymentSaleIdByAgencyIdLookup,
  );

  const businesses = useSelector(
    selectPropertyEnrichedBusinessByBusinessIdLookup,
  );

  const deploymentSaleId = deploymentSaleByAgencyLookup[agencyId] || null;

  const deploymentSale =
    useSelector(getDeploymentSaleById(deploymentSaleId)) || {};

  const deploymentId = deploymentSale.deployment_id;

  const saleType = useSelector(getCurrentSaleType);

  function onAfterBuyerChanged(buyerId) {
    setBuyerWay(null);
    if (!buyerId) {
      setDestinationPropertyId(null);
      return;
    }
    const business = businesses[buyerId] || {};
    const defaultProperty = business.defaultProperty || {};
    const defaultPropertyId = defaultProperty.id || null;
    setDestinationPropertyId(defaultPropertyId);
  }
  const {
    auction_pen: { start_pen: startPen },
  }: FormValues = values;

  return (
    <>
      <ForSaleyardAdmin>
        <Grid item xs={12}>
          <AgencyInputField name="agency_id" required isCurrentSaleOnly />
        </Grid>
      </ForSaleyardAdmin>
      {/* Lot numbers are important for private and hooks sales as they use it for internal referencing */}
      <ForHooksOrPaddockOrExternalAgentSales>
        <Grid item xs={12}>
          <LotNumber fullWidth />
        </Grid>
      </ForHooksOrPaddockOrExternalAgentSales>
      {/* Pen numbers are not used for paddock and hooks - it is not even shown in the ui */}
      <ForNotHooksOrPaddockOrExternalAgentSales>
        <Grid item xs={12}>
          <AutoIncrementingPenInput
            label="Pen Number"
            name={withNamespace("auction_pen", "start_pen")}
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <WaitForSync
            requiredData={[ApiModel.SALE_LOTS, ApiModel.AUCTION_PENS]}
          >
            <PenningConflictNotice
              saleRoundId={values.sale_round_id}
              penType={PenTypes.SELLING}
              startPenNumber={+startPen}
              startPenPrefix=""
              startPenSuffix=""
              endPenSuffix=""
            />
          </WaitForSync>
        </Grid>
      </ForNotHooksOrPaddockOrExternalAgentSales>
      {rounds.length > 1 && (
        <Grid item xs={12}>
          <SaleRoundPickerField name="sale_round_id" required />
        </Grid>
      )}

      <Grid item xs={12}>
        <Input
          label="Head"
          required
          name="quantity"
          autoFocus={[
            SaleTypes.EXTERNAL_AGENT_SALE,
            ...TransactionalSaleTypes,
          ].includes(saleType)}
        />
      </Grid>
      {deploymentId && (
        <ForNotHooksOrPaddockOrExternalAgentSales>
          <HasDeploymentMarks deploymentId={deploymentId}>
            <Grid item xs={12}>
              <MarkingField deploymentId={deploymentId} name="marks" />
            </Grid>
          </HasDeploymentMarks>
          <Grid item xs={12}>
            <OtherMarkingField
              deploymentId={deploymentId}
              name="marks"
              dataTour="addCustomMarkQuickCreate"
            />
          </Grid>
        </ForNotHooksOrPaddockOrExternalAgentSales>
      )}
      <ForHooksOrPaddockOrExternalAgentSales>
        <Grid item xs={12}>
          <StateAwareBusinessPICSelector
            label="Vendor"
            businessFieldName="vendor_id"
            propertyFieldName="vendor_property_id"
            formikProps={formikProps}
            error={touched.vendor_id && errors.vendor_id}
            showLinkedAgent
            optional={false}
            openOnFocus
            showPrefillSummary={false}
          />
        </Grid>
      </ForHooksOrPaddockOrExternalAgentSales>
      <ForNotHooksOrPaddockOrExternalAgentSales>
        <Grid item xs={12}>
          <InlineConsignmentSelect
            name="consignment_id"
            label="Consignment"
            includeCreateConsignmentButton={false}
            includeCreateUnknownConsignment={false}
            ignoreHeadCountForVendorSearch
          />
        </Grid>
      </ForNotHooksOrPaddockOrExternalAgentSales>

      <Grid item xs={12}>
        <ProductSelectField
          hideQuickSelect
          deploymentId={deploymentId}
          bestMatchAutoSelect={false}
        />
      </Grid>
      <Grid item xs={12}>
        <BreedField
          name="breed_id"
          label="Breed"
          speciesId={speciesId}
          deploymentId={deploymentId}
          showQuickCode
        />
      </Grid>

      <Grid item xs={12}>
        <PricingType
          quantity={null}
          skipTab
          hideFields={{ quantity_delivered: true }}
        />
      </Grid>

      <ForExternalAgentSale>
        <Grid container item xs={12}>
          <CheckBox
            name="is_gst_exempt"
            label="Is GST Exempt?"
            dataTour={undefined}
            tooltip={undefined}
            disabled={undefined}
          />
        </Grid>
      </ForExternalAgentSale>

      <ForHooksOrPaddockOrExternalAgentSales>
        <Grid item xs={12}>
          <StateAwareBusinessPICSelector
            label="Buyer"
            businessFieldName="buyer_id"
            propertyFieldName="destination_property_id"
            formikProps={formikProps}
            error={touched.buyer_id && errors.buyer_id}
            optional={!values.unit_price}
            openOnFocus
            showPrefillSummary={false}
          />
        </Grid>
      </ForHooksOrPaddockOrExternalAgentSales>
      <ForNotHooksOrPaddockOrExternalAgentSales>
        <Grid item xs={12}>
          <BusinessField
            name="buyer_id"
            label="Buyer"
            autoFocus={false}
            disabled={false}
            onChangeExtra={onAfterBuyerChanged}
            activeOnly
          />
        </Grid>
        <Grid item xs={12}>
          <BuyerAwareBuyerWayField
            name="buyer_way"
            label="Way"
            buyerField="buyer_id"
            readOnly={false}
            onChangeExtra={undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <BusinessPICField
            name="destination_property_id"
            label="PIC"
            businessField="buyer_id"
            onChangeExtra={undefined}
            staticOptionLabel={null}
          />
        </Grid>
      </ForNotHooksOrPaddockOrExternalAgentSales>
      <Grid container item xs={12}>
        <CheckBox
          tooltip={`Setting this will remember what has been set for ${
            rounds.length > 1 ? "Round, " : ""
          } Vendor, Product, Breed and Buyer when creating the next Sale Lot`}
          label={`Remember ${
            rounds.length > 1 ? "Round, " : ""
          }  Vendor, Product, Breed and Buyer`}
          dataTour="rememberRoundVendorProductBreedAndBuyer"
          disabled={false}
          name="rememberRoundVendorProductBreedAndBuyer"
        />
      </Grid>
      <ForSaleyardAdmin>
        {saleAgencies.length > 1 && (
          <Grid container item xs={12}>
            <CheckBox
              tooltip="Setting this will remember the set Agency when creating the next Sale Lot"
              label="Remember Agency"
              dataTour="rememberAgency"
              disabled={false}
              name="rememberAgency"
            />
          </Grid>
        )}
      </ForSaleyardAdmin>
    </>
  );
}

function FormButtons(): React.JSX.Element {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  useSubmitHandler(isSubmitEnabled, setIsSubmitEnabled);

  const currentSale = useSelector(getCurrentSale);

  const { setValues } = useFormikContext<FormValues>();

  const clearValues = () => {
    setValues({
      agency_id: null,
      auction_pen: {
        start_pen: null,
      },
      breed_id: null,
      buyer_id: null,
      destination_property_id: null,
      product_id: null,
      quantity: null,
      sale_round_id: null,
      unit_price: null,
      vendor_id: null,
      vendor_property_id: null,
      pricing_type_id: currentSale.pricing_type_id,
      rememberRoundVendorProductBreedAndBuyer: false,
      rememberAgency: false,
    });
  };

  return (
    <Row>
      <SecondaryButton type="button" onClick={clearValues} tabIndex="-1">
        Clear
      </SecondaryButton>
      <Button type="submit" disabled={!isSubmitEnabled}>
        Create
      </Button>
    </Row>
  );
}

const validationSchema = object().shape(
  {
    agency_id: Yup.mixed().required("An agency must be selected."),
    auction_pen: Yup.object().shape({
      start_pen: Yup.number()
        .typeError("Pen number must be a number.")
        .nullable(),
    }),
    consignment_id: Yup.string().when("vendor_id", {
      is: vendorId => !!vendorId,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .typeError("A consignment must be selected.")
        .required("A consignment must be selected."),
    }),
    total_mass_grams: Yup.number()
      .nullable()
      .min(0, "Total Mass must be greater than 0.")
      .integer("Only 3 decimals allowed."),
    buyer_id: Yup.string().nullable().when("unit_price", requiredWhenSet),
    destination_property_id: Yup.string().nullable(),
    breed_id: Yup.number().nullable(),
    product_id: Yup.string().nullable(),
    quantity: Yup.number()
      .min(0, "Head count must be 0 or more.")
      .typeError("Head count required.")
      .required("Head count required.")
      .when("unit_price", positiveWhenSet),
    sale_round_id: Yup.number()
      .typeError("Sale round required.")
      .required("Sale round required.")
      .positive("Sale round required."),
    quantityProgeny: Yup.number()
      .integer("Progeny count must be a whole number.")
      .min(0, "Progeny count must be 0 or more."),
    vendor_id: Yup.string().when("consignment_id", {
      is: consignmentId => !!consignmentId,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .typeError("A vendor must be selected.")
        .required("A vendor must be selected."),
    }),
  },
  [["consignment_id", "vendor_id"]],
);

function QuickCreateLivestockSaleLotPanel(): React.JSX.Element {
  const dispatch = useDispatch();

  const [invalidationCount, invalidate] = useCounter();

  const nextPenNumber = useSelector(getNextEmptyPenNumber);
  const currentSale = useSelector(getCurrentSale);

  const agencies = useSelector(getCurrentAgenciesOptions(false)) || [];
  const rounds = useSelector(getSaleRoundsOptions()) || [];

  const defaultBuyerId = currentSale.default_buyer_id;
  const defaultBuyerPropertyId = currentSale.default_property_id;
  const defaultVendorId = currentSale.default_vendor_id;
  const defaultVendorPropertyId = currentSale.default_vendor_property_id;

  const storedValues =
    useSelector(getSetting(Settings.livestockQuickCreateValues)) || {};

  const initialValues: FormValues = {
    agency_id: agencies.length === 1 ? agencies[0].value : null,
    auction_pen: {
      start_pen: nextPenNumber || null,
    },
    breed_id: null,
    buyer_id: defaultBuyerId || null,
    destination_property_id: defaultBuyerPropertyId || null,
    is_gst_exempt: false,
    product_id: null,
    quantity: null,
    sale_round_id: rounds.length === 1 ? rounds[0].value : null,
    unit_price: null,
    vendor_id: defaultVendorId || null,
    vendor_property_id: defaultVendorPropertyId || null,
    pricing_type_id: currentSale.pricing_type_id,
    rememberRoundVendorProductBreedAndBuyer: false,
    rememberAgency: false,
    ...storedValues,
  };

  const consignmentIdsByVendorIdLookup = useSelector(
    selectConsignmentIdsByVendorIdLookup as (state: any) => {
      [vendorId: string]: string[];
    },
  );

  const onSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ) => {
    const newSaleLotId = uuidv4();

    const priceObject: CalculatePriceOption = {
      unitPrice: values.unit_price,
      quantity: values.quantity,
      pricing_type_id: values.pricing_type_id,
      total_mass_grams: values.total_mass_grams,
    };

    const totalPriceCents = calculateTotalPriceCents(priceObject);

    const {
      age_id: ageId,
      agency_id: agencyId,
      vendor_id: vendorId,
      consignment_id: consignmentId,
      vendor_property_id: vendorPropertyId,
      buyer_id: buyerId,
      buyer_way: buyerWay,
      destination_property_id: destinationPropertyId,
      product_id: productId,
      breed_id: breedId,
      sale_round_id: saleRoundId,
      sex_id: sexId,
      rememberRoundVendorProductBreedAndBuyer,
      rememberAgency,
    } = values;

    const savedSettings: Partial<FormValues> = {};

    if (rememberRoundVendorProductBreedAndBuyer) {
      savedSettings.age_id = ageId;
      savedSettings.sale_round_id = saleRoundId;
      savedSettings.vendor_id = vendorId;
      savedSettings.consignment_id = consignmentId;
      savedSettings.vendor_property_id = vendorPropertyId;
      savedSettings.buyer_id = buyerId;
      savedSettings.buyer_way = buyerWay;
      savedSettings.destination_property_id = destinationPropertyId;
      savedSettings.product_id = productId;
      savedSettings.breed_id = breedId;
      savedSettings.sex_id = sexId;
      savedSettings.rememberRoundVendorProductBreedAndBuyer =
        rememberRoundVendorProductBreedAndBuyer;
    }

    if (rememberAgency) {
      savedSettings.agency_id = agencyId;
      savedSettings.rememberAgency = rememberAgency;
    }

    if (rememberRoundVendorProductBreedAndBuyer || rememberAgency) {
      dispatch(setSetting(Settings.livestockQuickCreateValues, savedSettings));
    } else if (!isEmpty(storedValues)) {
      dispatch(setSetting(Settings.livestockQuickCreateValues, null));
    }

    const vendorConsignmentIds = consignmentIdsByVendorIdLookup[vendorId] || [];

    const firstVendorConsignmentId = vendorConsignmentIds[0];

    if (firstVendorConsignmentId) {
      values.consignment_id = firstVendorConsignmentId;
    }

    dispatch(
      addSaleLotWithPens(newSaleLotId, {
        ...values,
        total_price_cents: totalPriceCents,
      }),
    );
    formikHelpers.resetForm();
  };

  const onReset = useCallback(() => {
    // Invalidate will cause the QuickCreateForm to re-mount and allow it to present
    // as a new form, including focus on the preferred input and any other initial presentation logic.
    invalidate();
  }, [invalidate]);

  return (
    <Formik
      onSubmit={onSubmit}
      onReset={onReset}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      <Form>
        <Paper>
          <Grid container spacing={2} justifyContent="center">
            <Grid item container xs={12} spacing={1}>
              <QuickCreateForm key={invalidationCount} />
            </Grid>
            <Grid item xs={12}>
              <FormButtons />
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </Formik>
  );
}

export default QuickCreateLivestockSaleLotPanel;
