import {
  ADD_SALE_LOT_COMMIT,
  ADD_SALE_LOT_FROM_SOCKET,
  ADD_SALE_LOT_OFFLINE,
  ADD_SALE_LOT_ROLLBACK,
  AUCTION_PEN,
  BUSINESS,
  DELETE_SALE_LOT_FROM_SOCKET,
  DELETE_SALE_LOT_NO_LONGER_EXISTS,
  DELETE_SALE_LOT_OFFLINE,
  GET_SALE_LOT_SUCCESS,
  MERGE_SALE_LOT_OFFLINE,
  PATCH_SALE_LOT_FROM_SOCKET,
  SALE_LOT,
} from "constants/actionTypes";
import { SaleLotType } from "constants/saleLots";
import { UNIX_START_TIME } from "constants/time";

import { calculateTotalPriceCents, getUnitPriceString } from "lib";

import { bulkUpdateCases } from "lib/reducers";
import {
  deserializeBulkUpdateSaleLot,
  deserializeSaleLot,
  deserializeSaleyardScanSaleLot,
  saleLotDefaults,
} from "lib/serializers/saleLot";

const getSaleLotDeserializerForType = saleLotType => {
  if (
    !saleLotType ||
    [SaleLotType.AUCTION, SaleLotType.VENDOR_SPLIT].includes(saleLotType)
  ) {
    return deserializeSaleLot;
  } else if (saleLotType === SaleLotType.SALEYARD_SCAN) {
    return deserializeSaleyardScanSaleLot;
  }
};

const getDirtySaleLotSliceForType = (saleLotType, state) => {
  if (saleLotType === SaleLotType.AUCTION || !saleLotType) {
    state.saleLots = { ...state.saleLots };
    return state.saleLots;
  } else if (saleLotType === SaleLotType.SALEYARD_SCAN) {
    state.saleyardScanSaleLots = { ...state.saleyardScanSaleLots };
    return state.saleyardScanSaleLots;
  } else if (saleLotType === SaleLotType.VENDOR_SPLIT) {
    state.vendorSplitSaleLots = { ...state.vendorSplitSaleLots };
    return state.vendorSplitSaleLots;
  }
};

const getDirtySaleLotSliceForSaleLotId = (saleLotId, state) => {
  if (state.saleLots[saleLotId]) {
    return getDirtySaleLotSliceForType(SaleLotType.AUCTION, state);
  } else if (state.saleyardScanSaleLots[saleLotId]) {
    return getDirtySaleLotSliceForType(SaleLotType.SALEYARD_SCAN, state);
  } else if (state.vendorSplitSaleLots[saleLotId]) {
    return getDirtySaleLotSliceForType(SaleLotType.VENDOR_SPLIT, state);
  }
};

const initialState = {
  saleLots: {},
  saleyardScanSaleLots: {},
  vendorSplitSaleLots: {},
  isFetching: false,
  isError: false,
  errorMessage: "",
  lastModifiedTimestamp: UNIX_START_TIME,
};

const saleLots = (state = initialState, action) => {
  switch (action.type) {
    case SALE_LOT.FETCH_BULK.REQUEST:
      return {
        ...state,
        saleLots: {},
        saleyardScanSaleLots: {},
        isFetching: true,
      };

    case ADD_SALE_LOT_OFFLINE: {
      const { meta, payload } = action;
      const { tempId } = meta.offline.commit.meta;
      const { salelot_type: saleLotType } = payload;

      const newState = { ...state };

      const saleLotSlice = getDirtySaleLotSliceForType(saleLotType, newState);
      const saleLotDeserialiser = getSaleLotDeserializerForType(saleLotType);
      saleLotSlice[tempId] = saleLotDeserialiser(payload, { syncing: true });

      return newState;
    }
    case ADD_SALE_LOT_COMMIT:
    case ADD_SALE_LOT_FROM_SOCKET: {
      const newState = {
        ...state,
      };
      const { meta, payload } = action;
      const { tempId } = meta;
      const { id, salelot_type: saleLotType } = payload;

      const saleLotDeserialiser = getSaleLotDeserializerForType(saleLotType);
      const newSaleLot = saleLotDeserialiser(payload, { syncing: false });

      const saleLotSlice = getDirtySaleLotSliceForType(saleLotType, newState);
      // Delete the originally created temp sale lot - this may not delete anything as tempId can
      // be the new  sale lot id from the socket.
      delete saleLotSlice[tempId];
      saleLotSlice[id] = newSaleLot;

      return newState;
    }
    case ADD_SALE_LOT_ROLLBACK: {
      if (action.meta.tempId === null) {
        return state;
      }
      const { meta, payload } = action;
      const { tempId } = meta;
      const { salelot_type: saleLotType } = payload;

      const newState = { ...state };

      const saleLotStore = getDirtySaleLotSliceForType(saleLotType, newState);
      delete saleLotStore[tempId];

      return newState;
    }

    case GET_SALE_LOT_SUCCESS: {
      const { saleLot } = action;
      const { id, salelot_type: saleLotType } = saleLot;
      const newState = { ...state };

      const saleLotSlice = getDirtySaleLotSliceForType(saleLotType, newState);
      const deserialiser = getSaleLotDeserializerForType(saleLotType);
      saleLotSlice[id] = deserialiser(saleLot, { syncing: false });

      return newState;
    }

    case SALE_LOT.FETCH_BULK.SUCCESS:
    case SALE_LOT.FETCH_CHANGES.SUCCESS: {
      const { meta, payload: saleLots } = action;
      const { lastModifiedTimestamp } = meta;

      if (
        action.type === SALE_LOT.FETCH_CHANGES.SUCCESS &&
        saleLots.length === 0
      ) {
        // If nothing has changed, don't force a state update.
        return state;
      }

      const newState = { ...state };
      newState.isFetching = false;
      newState.lastModifiedTimestamp = lastModifiedTimestamp;
      newState.saleyardScanSaleLots = { ...state.saleyardScanSaleLots };
      newState.saleLots = { ...state.saleLots };

      saleLots.forEach(saleLot => {
        const { deleted, id, salelot_type: saleLotType } = saleLot;
        if (deleted === true) {
          // Erase all traces of the sale lot
          const saleLotSlice = getDirtySaleLotSliceForSaleLotId(id, newState);
          if (saleLotSlice) {
            delete saleLotSlice[id];
          }
          return;
        }

        const saleLotSlice = getDirtySaleLotSliceForType(saleLotType, newState);
        const deserialiser = getSaleLotDeserializerForType(saleLotType);

        saleLotSlice[id] = deserialiser(saleLot, { syncing: false });
      });

      return newState;
    }
    case SALE_LOT.FETCH_BULK.FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.error && action.error.statusText,
      };

    case SALE_LOT.UPDATE.SUCCESS: {
      const { meta, payload } = action;
      const { saleLotId } = meta;

      const newState = { ...state };
      const saleLotSlice = getDirtySaleLotSliceForSaleLotId(
        saleLotId,
        newState,
      );
      // use the new sale lot type from the patch, if specified
      let { salelot_type: saleLotType } = payload;
      const existingSaleLotType = saleLotSlice[saleLotId].saleLotType;
      if (!saleLotType) {
        saleLotType = existingSaleLotType;
      }

      // TODO Allow for migration between sale lot types, or not. Maybe that's a better idea.
      if (existingSaleLotType !== saleLotType) {
        throw new Error(
          "PATCH_SALE_LOT_COMMIT attempted to change a sale lot type",
        );
      }

      const deserialiser = getSaleLotDeserializerForType(saleLotType);
      saleLotSlice[saleLotId] = deserialiser(payload, { syncing: false });

      return newState;
    }

    case SALE_LOT.UPDATE.REQUEST:
    case PATCH_SALE_LOT_FROM_SOCKET: {
      const { patch, saleLot } = action.payload;

      const newState = { ...state };

      const saleLotSlice = getDirtySaleLotSliceForSaleLotId(
        saleLot.id,
        newState,
      );

      if (saleLotSlice[saleLot.id]) {
        const syncing = action.type === SALE_LOT.UPDATE.REQUEST;

        if (patch.buyer_way === null) {
          patch.buyer_way_name = null;
          patch.buyer_way = null;
        }

        saleLotSlice[saleLot.id] = {
          ...saleLotSlice[saleLot.id],
          ...patch,
          clearingSaleAttributes: {
            ...saleLotSlice[saleLot.id].clearingSaleAttributes,
            ...patch?.clearingSaleAttributes,
          },
          draftingAttributes: {
            ...saleLotSlice[saleLot.id].draftingAttributes,
            ...patch?.draftingAttributes,
          },
          syncing,
        };

        return newState;
      }
      return state;
    }
    case MERGE_SALE_LOT_OFFLINE: {
      const { fromSaleLotId, toSaleLotId } = action.payload;

      const toLot = state.saleLots[toSaleLotId];
      const fromLot = state.saleLots[fromSaleLotId];

      const updatedQuantity = toLot.quantity + fromLot.quantity;
      const updatedTotalMassGrams =
        toLot.total_mass_grams + fromLot.total_mass_grams;

      const updatedTotalPriceCents = calculateTotalPriceCents({
        unitPrice: parseFloat(getUnitPriceString(toLot)),
        quantity: updatedQuantity,
        pricing_type_id: toLot.pricing_type_id,
        total_mass_grams: toLot.total_mass_grams,
      });

      const updatedSaleLot = {
        ...toLot,
        quantity: updatedQuantity,
        total_mass_grams: updatedTotalMassGrams,
        total_price_cents: updatedTotalPriceCents,
      };

      const newSaleLots = { ...state.saleLots, [toLot.id]: updatedSaleLot };
      delete newSaleLots[fromLot.id];

      return {
        ...state,
        saleLots: newSaleLots,
      };
    }

    case BUSINESS.CREATE.SUCCESS: {
      const { meta } = action;
      const businessId = action.payload.id;
      const { tempId } = meta;

      // Look for any instances of the tempId and replace with the real one.
      const updatedSaleLots = Object.values(state.saleLots)
        .map(c => {
          if (c.buyer_id === tempId) {
            return {
              ...c,
              buyer_id: businessId,
            };
          } else {
            return c;
          }
        })
        .reduce((map, saleLot) => {
          map[saleLot.id] = saleLot;
          return map;
        }, {});

      return {
        ...state,
        saleLots: updatedSaleLots,
      };
    }
    case DELETE_SALE_LOT_OFFLINE: {
      const { saleLot } = action.meta.offline.commit.meta;

      const saleLotId = saleLot.id;

      const newState = { ...state };
      const saleLotSlice = getDirtySaleLotSliceForSaleLotId(
        saleLotId,
        newState,
      );

      if (saleLotSlice) {
        delete saleLotSlice[saleLotId];
        return newState;
      }
      return state;
    }
    case DELETE_SALE_LOT_NO_LONGER_EXISTS:
    case DELETE_SALE_LOT_FROM_SOCKET: {
      const { saleLot } = action.meta;

      const newState = { ...state };
      newState.saleLots = { ...state.saleLots };
      newState.saleyardScanSaleLots = { ...state.saleyardScanSaleLots };

      delete newState.saleLots[saleLot.id];
      delete newState.saleyardScanSaleLots[saleLot.id];

      return newState;
    }

    case AUCTION_PEN.CREATE.SUCCESS: {
      const { saleLotIds } = action.meta;
      const newAuctionPenId = action.payload.id;

      const auctionPenSaleLotSet = new Set(saleLotIds);

      const updatedSaleLots = Object.values(state.saleLots)
        .map(s => {
          if (auctionPenSaleLotSet.has(s.id)) {
            return {
              ...s,
              auction_pen_id: newAuctionPenId,
            };
          } else {
            return s;
          }
        })
        .reduce((map, saleLot) => {
          map[saleLot.id] = saleLot;
          return map;
        }, {});

      return {
        ...state,
        saleLots: updatedSaleLots,
      };
    }

    case SALE_LOT.UPDATE_BULK.REQUEST:
    case SALE_LOT.UPDATE_BULK.SUCCESS:
    case SALE_LOT.UPDATE_BULK.FROM_SOCKET: {
      return bulkUpdateCases(
        state,
        action,
        "saleLots",
        deserializeBulkUpdateSaleLot,
        saleLotDefaults,
      );
    }

    case SALE_LOT.COMMENT.REQUEST: {
      const { payload, id } = action;
      return {
        ...state,
        saleLots: {
          ...state.saleLots,
          [id]: {
            ...state.saleLots[id],
            commentIds: [...state.saleLots[id].commentIds, payload.id],
          },
        },
      };
    }
    case SALE_LOT.SET_OFFLINE_VALUES.ACTION: {
      const { id, values } = action;
      return {
        ...state,
        saleLots: {
          ...state.saleLots,
          [id]: {
            ...state.saleLots[id],
            ...values,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default saleLots;
