import React from "react";

import { faBackspace } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@material-ui/core";
import { createFilterOptions } from "@material-ui/lab";
import { Field, getIn, setIn, useField } from "formik";
import startCase from "lodash/startCase";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Autocomplete, withNamespace } from "components/Form/FormikControls";

import { EMPTY_OBJECT } from "lib";

import { getGlobalBusinesses } from "selectors";

import { useFieldState, useFieldValue } from "hooks";

import {
  ALL_VALUE_SOURCES,
  CUSTOM_QUERY_FIELD_ID,
  FieldType,
  FieldValueType,
  FieldValueTypeDisplayNameMap,
  FieldValueTypesByFieldType,
  ValueSource,
  ValueSourceDisplayNameMap,
} from "./constants";
import {
  getSchemaField,
  getSchemaFieldValues,
  resolveAttributeValueType,
  useAutoSizeInput,
} from "./lib";
import { useRuleFieldSchema } from "./schemaContext";

export function DataSourcePicker(props) {
  const {
    namespace: ns,
    onAfterChanged,
    showAggregations,
    dataTour,
    includeGlobalBusinesses = false,
  } = props;
  const sourceFieldName = withNamespace(ns, "source");

  const sourceRef = useAutoSizeInput();

  const field = useField(ns);

  function onChangeSource({ target: selectEl }) {
    const { selectedIndex } = selectEl;

    const source =
      selectedIndex > -1 ? selectEl.options[selectedIndex].value : "";

    const updatedField = {
      source,
      attribute: "",
      index: "",
      fieldId: "",
      customQuery: "",
      valueType:
        {
          // When has selected Global Business as the data source, the value type will be a business.
          [ValueSource.GLOBAL_BUSINESS]: FieldValueType.BUSINESS,
          // When the user has selected "the number of" as their data source, it will always be an integer.
          [ValueSource.COUNT]: FieldValueType.INTEGER,
        }[source] ||
        // All other data source will fill out their own value type as part of their inner workings.
        null,
    };
    field[2].setValue(updatedField);
    typeof onAfterChanged === "function" && onAfterChanged(updatedField);
  }

  function getValueSources(showAggregations, includeGlobalBusinesses) {
    let valueSources;
    if (showAggregations && includeGlobalBusinesses) {
      valueSources = ALL_VALUE_SOURCES;
    } else if (showAggregations && !includeGlobalBusinesses) {
      valueSources = ALL_VALUE_SOURCES.filter(
        valuesSource => valuesSource !== ValueSource.GLOBAL_BUSINESS,
      );
    } else if (includeGlobalBusinesses && !showAggregations) {
      valueSources = [
        ValueSource.FIELD,
        ValueSource.CONSTANT,
        ValueSource.GLOBAL_BUSINESS,
      ];
    } else {
      valueSources = [ValueSource.FIELD, ValueSource.CONSTANT];
    }
    return valueSources;
  }

  const valueSources = getValueSources(
    showAggregations,
    includeGlobalBusinesses,
  );

  return (
    <Field
      data-tour={dataTour}
      as="select"
      className="p-2"
      innerRef={sourceRef}
      name={sourceFieldName}
      onChange={onChangeSource}
      required
    >
      <option disabled value="">
        Select a Data Source
      </option>
      {valueSources.map((value, index) => (
        <option value={value} key={index}>
          {ValueSourceDisplayNameMap[value]}
        </option>
      ))}
    </Field>
  );
}

export function ConstantDataSource(props) {
  const { fieldType, namespace: ns, onAfterChanged, dataTour } = props;

  const name = withNamespace(ns, "value");
  const valueRef = useAutoSizeInput();
  const [value, setValue] = useFieldState(ns);

  function onChangeValue({ target }) {
    const updatedField = {
      ...value,
      value: target.value,
      valueType:
        fieldType === FieldType.NUMERIC ? FieldValueType.DECIMAL : null,
    };

    setValue(updatedField);
    typeof onAfterChanged === "function" && onAfterChanged(updatedField);
  }

  return (
    <Field
      data-tour={`${dataTour}Value`}
      className="p-2"
      innerRef={valueRef}
      name={name}
      onChange={onChangeValue}
      placeholder="(constant)"
      required
    />
  );
}

const GlobalBusinessContainer = styled.div`
  flex-grow: 1;
  min-width: 250px;
`;

const filterOptions = createFilterOptions();

export function GlobalBusinessDataSource(props) {
  const { namespace: ns, dataTour } = props;

  const businesses = useSelector(getGlobalBusinesses) || EMPTY_OBJECT;

  const name = withNamespace(ns, "value");

  const options = Object.values(businesses);

  return (
    <GlobalBusinessContainer>
      <Autocomplete
        className="p-2"
        data-tour={`${dataTour}Value`}
        getOptionValue={option => `${option.id}`}
        getOptionLabel={option => option.name || ""}
        filterOptions={filterOptions}
        name={name}
        options={options}
      />
    </GlobalBusinessContainer>
  );
}

export function getPropertyDisplayName(property) {
  const propertyName =
    typeof property === "object" && typeof property.name === "string"
      ? property.name
      : property || "";

  if (typeof property !== "string") {
    return "";
  }
  return startCase(
    propertyName.replaceAll(/_/g, " ").replaceAll(/[^A-Za-z ]/g, ""),
  );
}

function getFieldTypeMapList(fieldType) {
  return FieldValueTypesByFieldType[fieldType] || [];
}

export function SchemaFieldPropertyDataSource(props) {
  const {
    dataTour,
    fieldType,
    namespace: ns,
    onAfterAttributeChanged,
    parentFieldId,
  } = props;

  const schema = useRuleFieldSchema();

  const field = getSchemaField(parentFieldId, schema) || {};

  const fieldProperties = field.properties || [];
  const options = fieldProperties
    .map(property =>
      typeof property === "string"
        ? { id: property, name: property }
        : { id: property.name, name: property.name, type: property.type },
    )
    .filter(
      option =>
        // When no filter is specified, all pass
        fieldType === undefined ||
        // When the field doesn't have a type, we don't know whether it IS a fieldType or not
        option.type === undefined ||
        // A field type filter is specified and the field has a type, make sure they match
        getFieldTypeMapList(fieldType).includes(option.type),
    );

  const attributeRef = useAutoSizeInput();

  function onChangeAttributeSelect(event) {
    const selectEl = event.target;
    const { selectedIndex } = selectEl;

    const selectedValue =
      selectedIndex > -1 ? selectEl.options[selectedIndex].value : "";

    typeof onAfterAttributeChanged === "function" &&
      onAfterAttributeChanged(selectedValue);
  }

  function onChangeAttribute(event) {
    typeof onAfterAttributeChanged === "function" &&
      onAfterAttributeChanged(event.target.value);
  }

  const attribute = useFieldValue(withNamespace(ns, "attribute"));
  const valueType = resolveAttributeValueType(
    null,
    parentFieldId,
    attribute,
    schema,
  );
  const valueTypeDisplayName = FieldValueTypeDisplayNameMap[valueType];

  return fieldProperties.length > 0 ? (
    <>
      <Field
        data-tour={`${dataTour}Attribute`}
        as="select"
        className="p-2"
        innerRef={attributeRef}
        name={withNamespace(ns, "attribute")}
        onChange={onChangeAttributeSelect}
        placeholder="(attribute name)"
        required
      >
        <option disabled value="">
          Select an Attribute
        </option>
        {options.map((option, index) => (
          <option value={option.id} key={index}>
            {getPropertyDisplayName(option.name)}
          </option>
        ))}
      </Field>
      {valueTypeDisplayName && <span> ({valueTypeDisplayName})</span>}
    </>
  ) : (
    <Field
      className="p-2"
      innerRef={attributeRef}
      name={withNamespace(ns, "attribute")}
      onChange={onChangeAttribute}
      placeholder="(attribute name)"
      required
    />
  );
}

export function CustomQueryField(props) {
  const {
    namespace: ns,
    options,
    onChangeDropdown,
    valueFieldName = withNamespace(ns, "fieldId"),
    dataTour,
  } = props;

  const [
    { value },
    // eslint-disable-next-line no-unused-vars
    ignored,
    { setValue },
  ] = useField(ns);
  const fieldId = getIn(value, "fieldId");
  const valueType = getIn(value, "valueType");

  const selectRef = useAutoSizeInput();
  const customQueryRef = useAutoSizeInput();

  const {
    onClickClear = function onClickClear() {
      setValue({
        ...value,
        attribute: "",
        customQuery: "",
        fieldId: "",
        valueType: null,
      });
    },
  } = props;

  const additionalProps = {};
  if (typeof onChangeDropdown === "function") {
    // If you specify an onChange, EVEN IF ITS `undefined`!!! @*&%^!
    // Formik will not handle updating the field's value in the Formik Context
    additionalProps.onChange = onChangeDropdown;
  }
  const valueTypeDisplayName = FieldValueTypeDisplayNameMap[valueType];

  return (
    <>
      {fieldId !== CUSTOM_QUERY_FIELD_ID ? (
        <>
          <Field
            data-tour={dataTour}
            as="select"
            className="p-2"
            innerRef={selectRef}
            name={valueFieldName}
            required
            value={
              value.uniq || value.via || value.fieldId || value.attribute || ""
            }
            {...additionalProps}
          >
            <option disabled value="">
              Select a Field
            </option>
            {options.map((option, index) => (
              <option
                value={option.uniq || option.via || option.id}
                key={index}
              >
                {option.name
                  ? option.name
                  : getPropertyDisplayName(option.via || option.id)}
              </option>
            ))}
            <option value={CUSTOM_QUERY_FIELD_ID}>Other</option>
          </Field>
          {valueTypeDisplayName && !value.fieldId && (
            <span> ({valueTypeDisplayName})</span>
          )}
        </>
      ) : (
        <>
          <Field
            className="p-2"
            name={withNamespace(ns, "customQuery")}
            placeholder="(advanced query)"
            innerRef={customQueryRef}
            required
          />
          <Tooltip title="Clear and select a new field">
            <IconButton onClick={onClickClear}>
              <FontAwesomeIcon icon={faBackspace} size="xs" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
}

export function SchemaFieldDataSource(props) {
  const {
    fieldType,
    namespace: ns,
    onAfterChanged,
    parentFieldId,
    dataTour,
  } = props;

  const fieldIdFieldName = withNamespace(ns, "fieldId");
  const attributeFieldName = withNamespace(ns, "attribute");

  const [
    { value },
    // eslint-disable-next-line no-unused-vars
    ignored,
    { setValue },
  ] = useField(ns);

  const { fieldId, uniq } = value || {};

  const schema = useRuleFieldSchema();

  const dataTypes = getFieldTypeMapList(fieldType);

  const options = getSchemaFieldValues(
    parentFieldId,
    schema,
    false,
    false,
  ).filter(
    option =>
      fieldType === undefined ||
      (option.isRelation
        ? getSchemaField(option.id, schema)?.properties.some(
            property =>
              property.type === undefined || dataTypes.includes(property.type),
          )
        : option.type === undefined || dataTypes.includes(option.type)),
  );

  const isAttribute = options.every(option =>
    uniq ? option.uniq !== uniq : option.id !== fieldId,
  );
  // When the value set in `fieldId` is a related model to the parent model, the dropdown should be editing the `fieldId` values,
  // when the value set in `fieldId` is a property of the parent model, this dropdown just presents the available fields from the parent model
  const valueFieldName = isAttribute ? attributeFieldName : fieldIdFieldName;

  function onChangeDropdown(event) {
    const fieldIdOrAttribute = event.target.value;

    let nextValue = value;

    if (fieldIdOrAttribute === CUSTOM_QUERY_FIELD_ID) {
      // When this is a custom query, set the field to be "custom" so that the dropdown is replaced with a text input
      nextValue = {
        ...value,
        fieldId: CUSTOM_QUERY_FIELD_ID,
        customQuery: "",
        valueType: null,
      };
    } else {
      const selectedOption = options.find(option =>
        option.uniq
          ? option.uniq === fieldIdOrAttribute
          : option.id === fieldIdOrAttribute,
      );

      if (selectedOption.isProperty) {
        // When the option selected from the dropdown is a property of the parent model, store the selected value in the "attribute" section
        nextValue = {
          ...value,
          attribute: fieldIdOrAttribute,
          fieldId: "",
          customQuery: "",
          valueType: selectedOption.type,
        };
      } else if (selectedOption.isRelation) {
        // When the option selected from the dropdown is a relation from the parent model to another model, store the selected value in the "fieldId" and reset the "attribute" value
        nextValue = {
          ...value,
          fieldId: selectedOption.id,
          uniq: selectedOption.uniq,
          attribute: "",
          customQuery: "",
          valueType: null,
        };
      }
    }
    if (nextValue !== value) {
      setValue(nextValue);
      typeof onAfterChanged === "function" && onAfterChanged(nextValue);
    }
  }

  function onClickClear() {
    const updatedField = {
      ...value,
      attribute: "",
      customQuery: "",
      fieldId: "",
      valueType: null,
    };

    setValue(updatedField);

    typeof onAfterChanged === "function" && onAfterChanged(updatedField);
  }

  function onAfterAttributeChanged(attribute) {
    const updatedField = {
      ...value,
      attribute,
      valueType: resolveAttributeValueType(
        parentFieldId,
        fieldId,
        attribute,
        schema,
      ),
    };
    setValue(updatedField);

    typeof onAfterChanged === "function" && onAfterChanged(updatedField);
  }

  return (
    <>
      <CustomQueryField
        dataTour={`${dataTour}Field`}
        namespace={ns}
        onChangeDropdown={onChangeDropdown}
        onClickClear={onClickClear}
        options={options}
        valueFieldName={valueFieldName}
      />
      {fieldId && (
        <SchemaFieldPropertyDataSource
          dataTour={dataTour}
          fieldType={fieldType}
          namespace={ns}
          onAfterAttributeChanged={onAfterAttributeChanged}
          parentFieldId={fieldId}
        />
      )}
    </>
  );
}

function SumDataSource(props) {
  const { namespace: ns, onAfterChanged, options = [] } = props;

  const [
    { value },
    // eslint-disable-next-line no-unused-vars
    ignored,
    { setValue },
  ] = useField(ns);

  const schema = useRuleFieldSchema();

  const indexRef = useAutoSizeInput();

  function onChangeIndex({ target: selectEl }) {
    const { selectedIndex } = selectEl;

    const selectedValue =
      selectedIndex > -1 ? selectEl.options[selectedIndex].value : "";

    const updatedField = {
      ...value,
      attribute: "",
      index: selectedValue,
      valueType: null,
    };

    setValue(updatedField);

    typeof onAfterChanged === "function" && onAfterChanged(updatedField);
  }

  function onAfterAttributeChanged(attribute) {
    const updatedField = {
      ...value,
      attribute,
      valueType: resolveAttributeValueType(
        null,
        options[value.index].fieldId,
        attribute,
        schema,
      ),
    };
    setValue(updatedField);

    typeof onAfterChanged === "function" && onAfterChanged(updatedField);
  }

  const areOptionsAvailable = Array.isArray(options) && options.length > 0;

  const selectedCriteria = options[value.index];

  return (
    <>
      <Field
        as="select"
        className="p-2"
        disabled={!areOptionsAvailable}
        innerRef={indexRef}
        name={withNamespace(ns, "index")}
        onChange={onChangeIndex}
        placeholder="Criteria number"
        required
      >
        <option value="" disabled>
          {areOptionsAvailable
            ? "Select a Criteria"
            : "No options are available"}
        </option>
        {options.map((option, index) => (
          <option key={index} value={index}>
            {getSchemaField(option.fieldId, schema)?.plural} from {option.name}
          </option>
        ))}
      </Field>
      <span className="p-2"> by </span>
      <SchemaFieldPropertyDataSource
        fieldType={FieldType.NUMERIC}
        namespace={ns}
        onAfterAttributeChanged={onAfterAttributeChanged}
        parentFieldId={selectedCriteria?.fieldId}
      />
    </>
  );
}

function CountDataSource(props) {
  const { namespace: ns, onAfterChanged, options = [] } = props;
  const indexRef = useAutoSizeInput();
  const schema = useRuleFieldSchema();
  const [
    { value },
    // eslint-disable-next-line no-unused-vars
    ignored,
    { setValue },
  ] = useField(ns);

  const areOptionsAvailable = Array.isArray(options) && options.length > 0;

  function onChangeIndex({ target: selectEl }) {
    const { selectedIndex } = selectEl;

    const selectedValue =
      selectedIndex > -1 ? selectEl.options[selectedIndex].value : "";

    const updatedField = setIn(value, "index", selectedValue);
    setValue(updatedField);

    typeof onAfterChanged === "function" && onAfterChanged(updatedField);
  }

  return (
    <>
      <Field
        as="select"
        className="p-2"
        disabled={!areOptionsAvailable}
        innerRef={indexRef}
        name={withNamespace(ns, "index")}
        onChange={onChangeIndex}
        required
      >
        <option value="" disabled>
          {areOptionsAvailable
            ? "Select a Criteria"
            : "No options are available"}
        </option>
        {options.map((option, index) => (
          <option key={index} value={index}>
            {getSchemaField(option.fieldId, schema)?.plural} from {option.name}
          </option>
        ))}
      </Field>{" "}
      ({FieldValueTypeDisplayNameMap[FieldValueType.INTEGER]})
    </>
  );
}

export function DataSource(props) {
  const {
    dataTour,
    criteriaOptions,
    fieldType,
    namespace: ns,
    onAfterChanged,
    parentFieldId,
    includeGlobalBusinesses = false,
  } = props;

  const { value } = useField(ns)[0];

  const valueSource = getIn(value, "source");

  function onAfterDataSourceValueChanged(dataSource) {
    typeof onAfterChanged === "function" && onAfterChanged(dataSource);
  }

  const showAggregations =
    fieldType === "" || fieldType === undefined || fieldType === "NUMERIC";

  return (
    <span>
      <DataSourcePicker
        dataTour={`${dataTour}DataSource`}
        namespace={ns}
        onAfterChanged={onAfterDataSourceValueChanged}
        showAggregations={showAggregations}
        includeGlobalBusinesses={includeGlobalBusinesses}
      />
      {valueSource === ValueSource.FIELD && (
        <SchemaFieldDataSource
          dataTour={dataTour}
          fieldType={fieldType}
          namespace={ns}
          onAfterChanged={onAfterDataSourceValueChanged}
          parentFieldId={parentFieldId}
        />
      )}
      {valueSource === ValueSource.CONSTANT && (
        <ConstantDataSource
          dataTour={dataTour}
          fieldType={fieldType}
          namespace={ns}
          onAfterValueChanged={onAfterDataSourceValueChanged}
        />
      )}
      {includeGlobalBusinesses &&
        valueSource === ValueSource.GLOBAL_BUSINESS && (
          <GlobalBusinessDataSource
            dataTour={dataTour}
            namespace={ns}
            onAfterAttributeChanged={onAfterDataSourceValueChanged}
          />
        )}
      {showAggregations && valueSource === ValueSource.SUM && (
        <SumDataSource
          options={criteriaOptions}
          namespace={ns}
          onAfterChanged={onAfterDataSourceValueChanged}
          parentFieldId={parentFieldId}
        />
      )}
      {showAggregations && valueSource === ValueSource.COUNT && (
        <CountDataSource
          options={criteriaOptions}
          namespace={ns}
          onAfterChanged={onAfterDataSourceValueChanged}
          parentFieldId={parentFieldId}
        />
      )}
    </span>
  );
}
