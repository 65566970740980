// Corresponds to agrinous/constants.py ALL_AGGRID_TABLES
export const AgGridTables = {
  BILLING: "billing",
  BILLING_DOCUMENTS: "billing_documents",
  BILLING_DOCUMENTS_EXPORT: "billing_documents_export",
  BILLING_DOCUMENTS_EXPORT_CONFIRMATION:
    "billing_documents_export_confirmation",
  BILLING_DOCUMENTS_SEND: "billing_documents_send",
  BILLING_LEDGER_ENTRIES: "billing_ledger_entries",
  BILLING_LEDGER_ENTRIES_DETAIL: "billing_ledger_entries_detail",
  BILLING_RUN: "billing_run",
  BUSINESSES: "businesses",
  BUSINESSES_IN_SALE: "businesses_in_sale",
  CLEARING_SALE_SALELOT: "clearing_sale_salelot",
  CONSIGNMENT: "consignment",
  CONTACT_BILLING_DOCUMENTS: "contact_billing_documents",
  EMAIL: "email",
  ENVD_MAPPING: "envd_mapping",
  INTEGRATION_BUSINESSES: "integration_businesses",
  LEDGER_ACCOUNTS: "ledger_accounts",
  LEDGER_ENTRY: "ledger_entry",
  MANUAL_ADJUSTMENT: "manual_adjustment",
  MONEY: "money",
  NOMINATIONS: "nominations",
  NLIS_SELL_PREVIEW: "nlis_sell_preview",
  NLIS_TAKE_PREVIEW: "nlis_take_preview",
  NLIS_CONFIRM_TRANSFER: "nlis_confirm_transfer",
  PEN_SCAN_LOT: "pen_scan_lot",
  PRIVATE_SALE_SALELOT: "private_sale_salelot",
  RECEIVAL_LOT: "receival_lot",
  REPORTS: "reports",
  REPORT_JOB: "report_job",
  RULE_BOOK: "rule_book",
  RULE_BOOKS: "rule_books",
  SALE_LOT: "salelot",
  SCAN: "scan",
  SCAN_SELECTION: "scan_selection",
  TEST_RULEBOOK: "test_rulebook",
  TEST_RULEBOOK_ANIMALS: "test_rulebook__animals",
  TEST_RULEBOOK_BUYERS: "test_rulebook__buyers",
  TEST_RULEBOOK_CONSIGNMENTS: "test_rulebook__consignments",
  TEST_RULEBOOK_SALELOTS: "test_rulebook__salelots",
  TEST_RULEBOOK_VENDORS: "test_rulebook__vendors",
  WEIGH_LOT: "weigh_lot",
};

export const OwnerTypes = {
  DEPLOYMENT: "deployment",
  GLOBAL: "global",
  SALEYARD: "saleyard",
  USER: "user",
};

export const OwnerTypeLabels = {
  [OwnerTypes.USER]: "My Views",
  [OwnerTypes.GLOBAL]: "Global",
  // Saleyard and Deployment use the respective names of those objects.
};

export const AgGridPanels = {
  COLUMNS: "columns",
  FILTERS: "filters",
  SAVED_VIEWS: "savedViews",
  QUICK_CREATE: "quickCreate",
  QUICK_EDIT: "quickEdit",
};

export const ACTION_COLUMN_ID = "actions";
export const ACTION_COLUMN_NAME = "Actions";

export const SELECT_COLUMN_ID = "706e59c7-11ed-4fd6-b4c6-dbe2fb72f232";
export const SELECT_COLUMN_NAME = "Select";
