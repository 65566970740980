import reduceReducers from "reduce-reducers";

import { BILLING_RUN, SET_CURRENT_LIVESTOCKSALE } from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  resetStateOnRoleChangeReducer,
} from "lib/reducers";
import { deserializeBillingRun } from "lib/serializers/billingRuns";

const create = apiModelOfflineCreateReducer(BILLING_RUN, {
  deserializer: deserializeBillingRun,
});

const fetch = apiModelOfflineFetchReducer(BILLING_RUN, {
  deserializer: deserializeBillingRun,
  clearOnRequest: false,
});

const update = apiModelOfflineUpdateReducer(BILLING_RUN, {
  deserializer: deserializeBillingRun,
});

const deleteReducer = apiModelOfflineDeleteReducer(BILLING_RUN);

function extraBillingRunReducer(state, action) {
  switch (action.type) {
    case SET_CURRENT_LIVESTOCKSALE.REQUEST:
      if (action.shouldNavigate) {
        // If we are navigating to a different livestock sale, clear the active billing run.
        // This event also fires on mount (ie refreshing), so we dont want to clear the selected billing run if that's the case.
        return { ...state, isEnabled: false, activeBillingRunId: null };
      }
      return state;
    case BILLING_RUN.ENABLE.ACTION:
      return { ...state, isEnabled: true };
    case BILLING_RUN.DISABLE.ACTION:
      return { ...state, isEnabled: false };
    case BILLING_RUN.SUBSCRIBE.ACTION:
      return { ...state, activeBillingRunId: action.id };
    case BILLING_RUN.CREATE.REQUEST: {
      return { ...state, activeBillingRunId: null };
    }
    case BILLING_RUN.UNSUBSCRIBE.ACTION:
      return {
        ...state,
        activeBillingRunId: null,
        lastActiveBillingRunId: state.activeBillingRunId,
      };

    default:
      return state;
  }
}

const billingRunReducer = reduceReducers(
  create,
  fetch,
  update,
  deleteReducer,
  extraBillingRunReducer,
  resetStateOnRoleChangeReducer,
);

export default billingRunReducer;
