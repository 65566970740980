import { kebabCase, uniq } from "lodash";
import queryString from "query-string";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import {
  BusinessAction,
  mergeBusinessFailure,
  mergeBusinessSuccess,
} from "actions";

import {
  ADD_CONSIGNMENT_OFFLINE,
  CREATE_BUSINESS_FROM_IMPORT,
  GET_DUPLICATE_BUSINESS_SUGGESTIONS,
  MERGE_BUSINESSES,
  PATCH_BUSINESS_FROM_IMPORT,
  PATCH_CONSIGNMENT_OFFLINE,
  RECONCILE_BUYER_WAY_DEPLOYMENT_BUSINESS_PROPERTY_NAMES,
  REQUERY_ABN_FOR_BUSINESS,
} from "constants/actionTypes";
import { SuggestDuplicateBusinessFilter } from "constants/businesses";

import { splitName } from "lib";

// import { confirmDialogWithSubAction } from "lib/confirmDialog";
import { getSaleyardName } from "lib/navigation";
import { serializeAddress } from "lib/serializers/address";
import { cleanBusinessPayload } from "lib/serializers/businesses";
import toast from "lib/toast";

import {
  getBusinessById,
  getSaleLotById,
  selectPropertyEnrichedBusinessByBusinessIdLookup,
  selectPropertyIdByPICLookup,
} from "selectors";

import { api } from "./api";

function* fetchDuplicateBusinessSuggestions(action) {
  const { filters, options } = action;
  const { onSuccess, onError } = {
    ...options,
  };

  const {
    [SuggestDuplicateBusinessFilter.LIVESTOCK_SALE_ID]: livestockSaleId,
  } = filters;

  const endpoint = queryString.stringifyUrl({
    url: `/v2/${getSaleyardName()}/businesses/suggest-duplicates/`,
    query: { livestock_sale_id: livestockSaleId },
  });

  try {
    const suggestionsResponsePromise = yield call(api.get, endpoint);
    const suggestionsResponse = yield suggestionsResponsePromise;
    const duplicateBusinessSuggestions = yield suggestionsResponse;

    typeof onSuccess === "function" && onSuccess(duplicateBusinessSuggestions);
  } catch (e) {
    try {
      typeof onError === "function" && onError(e);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("There was an error", e);
    }
    // eslint-disable-next-line no-console
    console.log("There was an error", e);
    yield call(
      api.handleFetchError,
      e,
      "duplicate Business suggestions.",
      action,
    );
  }
}

function* onMergeBusinessRequest(action) {
  const { fromMasterBusinessId, options, toMasterBusinessId } = action;
  const { onSuccess, onError } = {
    ...options,
  };

  const endpoint = queryString.stringifyUrl({
    url: `/v2/${getSaleyardName()}/businesses/${toMasterBusinessId}/merge/${fromMasterBusinessId}/`,
  });

  try {
    yield call(api.post, endpoint);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("There was an error", e);

    try {
      // Call synchronous error callback
      typeof onError === "function" && onError(e);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    yield call(api.handleFetchError, e, "merge Business", action);
    yield put(mergeBusinessFailure(action, e));
    return;
  }
  try {
    // Call synchronous success callback
    typeof onSuccess === "function" &&
      onSuccess(toMasterBusinessId, fromMasterBusinessId);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  yield put(mergeBusinessSuccess(toMasterBusinessId, fromMasterBusinessId));
}

function onMergeBusinessSucceeded() {
  toast.success("Businesses merged successfully.");
}

function onMergeBusinessFailed(action) {
  const { error } = action;
  toast.error(`An error occurred merging Businesses. ${error}`);
}

function* createBusinessFromImport(action) {
  const tempBusinessId = uuidv4();
  const { payload } = action;
  const {
    businessName,
    businessShortCode,
    businessPIC,
    abn,
    email,
    phoneNumber,
    altPhoneNumber,
    auctionsPlusId,
    contactFullName,
  } = payload;
  const values = {
    id: tempBusinessId,
    name: businessName,
  };
  if (businessShortCode) {
    // Set both the saleyard code and the agent code to the same thing.
    values.shortCodeSaleyard = businessShortCode;
    values.shortCode = businessShortCode;
  }

  if (auctionsPlusId) {
    values.shortCodeAuctionsPlus = auctionsPlusId;
  }
  if (abn) {
    values.abn = abn.replace(/\D/g, "");
  }
  if (businessPIC) {
    values.properties = [
      {
        PIC: businessPIC,
        name: null,
      },
    ];
  }

  const address = {};

  [
    "addressingInformation",
    "route",
    "state",
    "streetNumber",
    "locality",
    "latitude",
    "longitude",
    "postalCode",
  ].forEach(addressField => {
    if (payload[addressField]) {
      address[addressField] = payload[addressField];
    }
  });
  values.address = serializeAddress(address);

  if (email) {
    values.emailRecipients = [];
    // If there are contact details, and add if they don't exist.
    // Some values may come in as a comma, pipe or semicolon
    email
      .split(/[;|,]/)
      .map(email_address => email_address.trim())
      .forEach(email_address => {
        // TODO - serialized or no?
        const emailRecipient = {
          email: email_address,
          phone_number: phoneNumber || altPhoneNumber || undefined,
          is_commercial_report_recipient: true,
          is_compliance_report_recipient: true,
          first_name: "-",
          last_name: "-",
        };
        // Try and break full name up.
        if (contactFullName) {
          const splitFullName = splitName(contactFullName);
          emailRecipient.first_name = splitFullName.firstName || "-";
          emailRecipient.last_name = splitFullName.lastName || "-";
        }
        values.emailRecipients.push(emailRecipient);
      });
  }

  yield put(BusinessAction.create(values));
}

function* patchBusinessFromImport(action) {
  const { businessId, payload } = action;

  const state = yield select();
  const business =
    selectPropertyEnrichedBusinessByBusinessIdLookup(state)[businessId];

  const {
    businessShortCode,
    businessPIC,
    auctionsPlusId,
    phoneNumber,
    contactFullName,
    email,
  } = payload;

  // TODO - what that?
  const patch = {
    ...cleanBusinessPayload(business, true),
  };
  patch.id = businessId;

  // If a shortcode was provided, use it.
  if (businessShortCode) {
    // If the business doesnt have an (agent) (short_) code set, set it to the same value.
    if (!business.shortCode) {
      patch.shortCode = businessShortCode;
    }
    patch.shortCodeSaleyard = businessShortCode;
  }

  // If we've got AuctionsPlus data, use it.
  if (auctionsPlusId) {
    patch.shortCodeAuctionsPlus = auctionsPlusId;
  }

  // If a PIC was provided, and it's not already there, add it to the business.
  if (businessPIC) {
    const propertyId = selectPropertyIdByPICLookup(state)[businessPIC];
    if (propertyId) {
      // Known property - see if it's already attached to the business.
      if (!business.properties.find(property => property.id === propertyId)) {
        patch.properties = [
          ...business.properties,
          {
            id: propertyId,
            default: false,
            name: "",
          },
        ];
      }
    } else {
      // Totally new property (to this user)
      patch.properties = [
        ...business.properties,
        {
          PIC: businessPIC,
          default: false,
          name: "",
        },
      ];
    }
  }

  // If we have addressing information, add it, but if don't overwrite existing values.
  // XX what if they move?
  if (
    !patch.address ||
    (!patch.address.street_number &&
      !patch.address.postal_code &&
      !patch.address.state)
  ) {
    patch.address = {};
  }
  [
    "addressingInformation",
    "route",
    "state",
    "streetNumber",
    "locality",
    "latitude",
    "longitude",
    "postalCode",
  ].forEach(addressField => {
    if (payload[addressField] && !patch.address[addressField]) {
      patch.address[addressField] = payload[addressField];
    }
  });
  patch.address = serializeAddress(patch.address);

  // If there are contact details, and add if they don't exist.
  // Some values may come in as a comma, pipe or semicolon
  email
    .split(/[;|,]/)
    .map(email_address => email_address.trim())
    .forEach(email_address => {
      if (
        email_address &&
        !patch.email_recipients.find(er => er.email === email)
      ) {
        // TODO - serialized?
        const emailRecipient = {
          email: email_address,
          phone_number: phoneNumber,
          is_commercial_report_recipient: true,
          is_compliance_report_recipient: true,
          first_name: "-",
          last_name: "-",
        };
        // Try and break full name up.
        if (contactFullName) {
          const splitFullName = splitName(contactFullName);
          emailRecipient.first_name = splitFullName.firstName || "-";
          emailRecipient.last_name = splitFullName.lastName || "-";
        }
        patch.email_recipients.push(emailRecipient);
      }
    });

  yield put(BusinessAction.update(patch));
}

function* updateVendorDefaultConsigningDeploymentId(
  vendorId,
  saleyardName,
  deploymentId,
) {
  // TODO - test/check/validate this comment from the old code.
  // We must use the sale from the action, as the Consign To
  // action may be creating a consignment in a different Livestock Sale
  // saleyardName,
  yield put(
    BusinessAction.update({
      id: vendorId,
      default_consigning_deployment_id: deploymentId,
    }),
  );
}

function* onAddConsignment(action) {
  const { meta, payload, options = {} } = action;
  const { consigned_from_id: consignedFromId, vendor_id: vendorId } = payload;
  const { sale } = meta.offline.commit.meta;

  const { shouldUpdateVendorDefaultConsigningDeployment } = options;

  if (consignedFromId && shouldUpdateVendorDefaultConsigningDeployment) {
    yield updateVendorDefaultConsigningDeploymentId(
      vendorId,
      sale.saleyard_name,
      consignedFromId,
    );
  }
}

function* onPatchConsignment(action) {
  const { options = {}, payload } = action;
  const { vendor_id: vendorId, consigned_from_id: consignedFromId } = payload;

  const { shouldUpdateVendorDefaultConsigningDeployment } = options;
  // a patch may not contain the vendor
  if (vendorId) {
    // only update the Vendor's defaultConsigningDeploymentId property when there is an updated value in the patch
    if (consignedFromId && shouldUpdateVendorDefaultConsigningDeployment) {
      yield updateVendorDefaultConsigningDeploymentId(
        vendorId,
        getSaleyardName(),
        consignedFromId,
      );
    }
  }
}

// BAU-1600 and that it's not useful to anyone other than CVLX and Bendigo
// function* promptConfirmUpdateDeploymentBusinessProperties(
//   businessId,
//   propertyId,
//   buyerWayName,
// ) {
//   const state = yield select();

//   const property = getPropertyById(propertyId)(state);
//   const business = getBusinessById(businessId)(state);

//   const message = `Would you like to update the name for ${property.PIC} to match the Buyer Way "${buyerWayName}"?`;

//   const updatedProperties = business.properties.map(property =>
//     property.id === propertyId ? { ...property, name: buyerWayName } : property,
//   );

//   // Prompt the user to update all of the candidate DeploymentBusinessProperties for this DeploymentBusiness
//   yield confirmDialogWithSubAction(
//     {
//       title: "Update Property Name",
//       message,
//     },
//     BusinessV2Action.update({
//       id: businessId,
//       properties: updatedProperties,
//     }),
//   );
// }

function* onReconcileBuyerWayPropertyNames(action) {
  const { saleLotIds } = action;
  const state = yield select();

  const saleLots = saleLotIds
    // Get the Sale Lots for all of the supplied Sale Lot Ids
    .map(saleLotId => getSaleLotById(saleLotId)(state))
    // Remove any Sale Lots which don't have a Buyer Way, or the Buyer Way doesn't have a Name
    .filter(saleLot => saleLot.buyer_way?.name)
    // Remove any Sale Lots which don't have a Destination Property
    .filter(saleLot => saleLot.destination_property_id);

  const saleLotPropertyIds = uniq(
    saleLots.map(saleLot => saleLot.destination_property_id),
  );
  const saleLotBuyerWayNames = uniq(
    saleLots.map(saleLot => saleLot.buyer_way.name),
  );
  const saleLotBuyerIds = uniq(saleLots.map(saleLot => saleLot.buyer_id));

  // We should only ever have one of each.  If we don't, we've been called from somewhere new.
  // Log a message and bail
  if (
    saleLotPropertyIds.length !== 1 ||
    saleLotBuyerWayNames.length !== 1 ||
    saleLotBuyerIds.length !== 1
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      "Called onReconcileBuyerWayPropertyNames with a list of sale lots containing multiple buyers/property/buyer ways",
    );
    return;
  }

  const businessId = saleLotBuyerIds[0];
  const buyerWayName = saleLotBuyerWayNames[0];
  const propertyId = saleLotPropertyIds[0];

  const business = getBusinessById(businessId)(state);
  const specificProperty = business.properties.find(
    property => property.id === propertyId,
  );
  // If the property name is already equal, nothing to do.
  if (!specificProperty || specificProperty.name !== buyerWayName) {
    // Otherwise, ask if they want to update.
    // BAU-1600 and that it's not useful to anyone other than CVLX and Bendigo
    // yield promptConfirmUpdateDeploymentBusinessProperties(
    //   businessId,
    //   propertyId,
    //   buyerWayName,
    // );
  }
}

function onRequeryAbnForBusinessRequest() {
  toast.syncing("Re-Checking ABN Status", {
    autoClose: false,
  });
}

function* onRequeryAbnForBusinessSuccess(action) {
  const { meta: { id } = {} } = action;
  yield put(BusinessAction.requestOne({ id }));
  toast.dismiss(kebabCase("Re-Checking ABN Status"));
  toast.success("ABN Status Synced");
}

function onRequeryAbnForBusinessFailure(action) {
  const { error } = action;
  toast.dismiss(kebabCase("Re-Checking ABN Status"));
  toast.error(`An error occurred requerying abn. ${error}`);
}

export default function* rootSaga() {
  yield takeEvery(
    GET_DUPLICATE_BUSINESS_SUGGESTIONS.REQUEST,
    fetchDuplicateBusinessSuggestions,
  );
  yield takeEvery(
    REQUERY_ABN_FOR_BUSINESS.REQUEST,
    onRequeryAbnForBusinessRequest,
  );
  yield takeEvery(
    REQUERY_ABN_FOR_BUSINESS.SUCCESS,
    onRequeryAbnForBusinessSuccess,
  );
  yield takeEvery(
    REQUERY_ABN_FOR_BUSINESS.FAILURE,
    onRequeryAbnForBusinessFailure,
  );
  yield takeEvery(MERGE_BUSINESSES.REQUEST, onMergeBusinessRequest);
  yield takeEvery(MERGE_BUSINESSES.SUCCESS, onMergeBusinessSucceeded);
  yield takeEvery(MERGE_BUSINESSES.FAILURE, onMergeBusinessFailed);

  yield takeEvery(CREATE_BUSINESS_FROM_IMPORT, createBusinessFromImport);
  yield takeEvery(PATCH_BUSINESS_FROM_IMPORT, patchBusinessFromImport);

  yield takeLatest(ADD_CONSIGNMENT_OFFLINE, onAddConsignment);
  yield takeLatest(PATCH_CONSIGNMENT_OFFLINE, onPatchConsignment);

  yield takeEvery(
    RECONCILE_BUYER_WAY_DEPLOYMENT_BUSINESS_PROPERTY_NAMES,
    onReconcileBuyerWayPropertyNames,
  );
}
