import {
  GET_PROPERTIES,
  GET_PROPERTIES_SUCCESS,
  GET_PROPERTIES_CHANGES_SUCCESS,
  GET_PROPERTIES_FAILURE,
  ADD_PROPERTY_OFFLINE,
  ADD_PROPERTY_ROLLBACK,
  ADD_PROPERTY_COMMIT,
  ADD_PROPERTY_FROM_SOCKET,
  UPDATE_PROPERTY_FROM_SOCKET,
  UPDATE_PROPERTIES,
  PROPERTY,
} from "constants/actionTypes";
import { UNIX_START_TIME } from "constants/time";

import { deserializeProperty } from "lib/serializers/properties";

const initialState = {
  isFetching: false,
  properties: {},
  lastModifiedTimestamp: UNIX_START_TIME,
};

const properties = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROPERTIES:
      return {
        ...state,
        isFetching: true,
      };

    case GET_PROPERTIES_SUCCESS:
    case GET_PROPERTIES_CHANGES_SUCCESS:
      const addedProperties = action.properties.filter(
        property => property.deleted !== true,
      );
      const deletedProperties = action.properties.filter(
        property => property.deleted === true,
      );

      const remainProperties =
        action.type === GET_PROPERTIES_CHANGES_SUCCESS
          ? deletedProperties.reduce(
              (map, property) => {
                delete map[property.id];
                return map;
              },
              { ...state.properties },
            )
          : {};
      const newProperties = addedProperties.reduce((map, property) => {
        map[property.id] = deserializeProperty(property);
        return map;
      }, remainProperties);

      return {
        ...state,
        properties: newProperties,
        isFetching: false,
        lastModifiedTimestamp: action.lastModifiedTimestamp,
      };

    case GET_PROPERTIES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case ADD_PROPERTY_OFFLINE: {
      const { tempId } = action.meta;

      return {
        ...state,
        properties: {
          ...state.properties,
          [tempId]: deserializeProperty(action.payload),
        },
      };
    }

    case ADD_PROPERTY_COMMIT:
    case ADD_PROPERTY_FROM_SOCKET:
    case UPDATE_PROPERTY_FROM_SOCKET:
    case PROPERTY.ERP_QUERY.SUCCESS: {
      const { meta, payload } = action;
      const { tempId } = meta;

      const newProperties = { ...state.properties };
      delete newProperties[tempId];

      return {
        ...state,
        properties: {
          ...newProperties,
          [payload.id]: deserializeProperty(payload),
        },
      };
    }

    case ADD_PROPERTY_ROLLBACK: {
      const { tempId } = action.meta;

      const newState = { ...state };
      newState.properties = { ...newState.properties };

      delete newState.properties[tempId];

      return newState;
    }

    case UPDATE_PROPERTIES: {
      const { properties } = action;
      if (properties.length < 1) {
        return state;
      }
      const updatedProperties = properties.reduce((map, property) => {
        map[property.id] = deserializeProperty(property);
        return map;
      }, {});

      return {
        ...state,
        properties: {
          ...state.properties,
          ...updatedProperties,
        },
      };
    }

    default:
      return state;
  }
};

export default properties;
