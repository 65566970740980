import React from "react";

import Grid from "@material-ui/core/Grid";

import { CollapseHeader, FormCollapse } from "components/Form";
import {
  Input,
  SelectField,
  withNamespace,
  withNamespaces,
} from "components/Form/FormikControls";
import { DescriptiveOption } from "components/SearchableSelector";

import {
  FinanceSubModalSection,
  HeaderLayoutOptions,
  InvoiceFooterOptions,
} from "constants/settings";

import { useSectionHelper } from "hooks";

export const InvoiceSettings = ({ sectionHelper, namespace: ns }) => {
  const { isOpen, onToggle } = useSectionHelper(
    sectionHelper,
    FinanceSubModalSection.TAX_INVOICE,
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<CollapseHeader title="Tax Invoice" />}
    >
      <Grid item xs={12} md={6}>
        <Input
          label="Invoice Prefix"
          name={withNamespace(ns, "invoicePrefix")}
          tooltip="The prefix used for Invoice documents. EG INV-1234"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Next Invoice Number"
          name={withNamespace(ns, "nextInvoiceNumber")}
          tooltip="The next Invoice document created by your organization will use this number, combined with your current Invoice Prefix."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Primary Color"
          name={withNamespaces([ns, "taxInvoiceSettings"], "primaryColor")}
          type="color"
          tooltip="This colour is used in the header of several reports."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Secondary Color"
          name={withNamespace(`${ns}.taxInvoiceSettings`, "secondaryColor")}
          type="color"
          tooltip="This colour is used in the header of several reports."
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          label="Header Layout"
          name={withNamespaces([ns, "taxInvoiceSettings"], "headerLayout")}
          options={HeaderLayoutOptions}
          tooltip="Select the header layout of the logo on the document"
          components={{ Option: DescriptiveOption }}
        />
        <SelectField
          label="Footer Layout"
          name={withNamespaces([ns, "taxInvoiceSettings"], "footerLayout")}
          options={InvoiceFooterOptions}
          tooltip="Select the footer layout of the logo on the document"
          components={{ Option: DescriptiveOption }}
        />
      </Grid>
    </FormCollapse>
  );
};
