import React from "react";

import { Grid } from "@material-ui/core";
import { Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { addSale } from "actions";

import { InputDateRange } from "components/DateRangePicker/dateRangePicker";
import { Button } from "components/Form";
import { LabelText, OptionTogglerField } from "components/Form/FormikControls";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import {
  BillingDocumentNames,
  BillingDocumentType,
} from "constants/billingDocuments";
import { PricingTypes } from "constants/pricingTypes";
import { SaleSubTypeName, SaleTypes } from "constants/sale";
import { Species } from "constants/species";

import { formatPercentage } from "lib";

import { calculateInterestChargeCentsAndDaysToCharge } from "lib/interest";
import { closeAllHashModals } from "lib/navigation";
import { pluralize } from "lib/pluralize";
import { formatDateString, formatISO8601DateString } from "lib/timeFormats";

import {
  getActiveLivestockAgentDeployment,
  getAvailableSaleyards,
  getBillingDocuments,
  getDeployments,
  getPayments,
  getSales,
  getSaleSubTypes,
  selectInterestSetttingsByBillingDocumentIdLookup,
  selectSaleTitleByIdLookup,
} from "selectors";

const validationSchema = Yup.lazy(() =>
  Yup.object().shape({
    createdLte: Yup.date().required("An end date is requried").nullable(),
    createdGte: Yup.date()
      .nullable()
      .required("A start date is required")
      .when("createdLte", createdLte => {
        if (createdLte) {
          return Yup.date()
            .nullable()
            .test("validRange", "Start date is after end date", createdGte => {
              if (!createdGte) {
                return true;
              }
              return createdGte < createdLte;
            })
            .test(
              "dateNotInFuture",
              "Date range is in the future",
              createdGte => {
                if (!createdGte) {
                  return true;
                }
                const today = new Date();
                return createdGte < today;
              },
            );
        }
      }),
  }),
);

const documentOptions = [
  {
    value: BillingDocumentType.INVOICE,
    label: "Invoice",
  },
  {
    value: BillingDocumentType.RCTI,
    label: "RCTI",
  },
  {
    value: null,
    label: "Invoice and RCTI",
  },
];

const inclusiveToolTip =
  "This Date range runs from the start of the first day, to the end of the last day. Eg. If 1/1/23 - 1/2/23 is selected, then the range is 1/1/23 00:00:00 - 31/1/23 23:59:59";

export const InterestModal = () => {
  const billingDocuments = useSelector(getBillingDocuments);
  const activeDeployment = useSelector(getActiveLivestockAgentDeployment);
  const availableSaleyards = useSelector(getAvailableSaleyards);
  const deployments = useSelector(getDeployments);
  const interestSetttingsByBillingDocumentIdLookup = useSelector(
    selectInterestSetttingsByBillingDocumentIdLookup,
  );
  const saleByIdLookup = useSelector(getSales);
  const saleSubTypeByIdLookup = useSelector(getSaleSubTypes);

  const { setValues, values, isValid } = useFormikContext();

  const onClose = () => closeAllHashModals();

  const updateDates = (createdGte, createdLte) => {
    const updates = {
      createdLte,
      createdGte,
    };
    setValues({
      ...values,
      ...updates,
    });
  };

  const dispatch = useDispatch();

  const startDate = new Date(values.createdGte);
  const endDate = new Date(values.createdLte);
  const filteredDocumentType = values.documentType;
  const livestockSaleTitles = useSelector(selectSaleTitleByIdLookup);

  const interestSaleSubType =
    Object.values(useSelector(getSaleSubTypes)).find(
      e => e.name === SaleSubTypeName.INTEREST,
    ) || {};
  const paymentsByIdLookup = useSelector(getPayments);
  const sundryData = Object.values(billingDocuments)
    .filter(billingDocument => {
      const interestSettings =
        interestSetttingsByBillingDocumentIdLookup[billingDocument.id];

      if (
        filteredDocumentType &&
        filteredDocumentType !== billingDocument.type
      ) {
        return false;
      }
      // Billing documents at the moment generally only have one related livestock sale, but if they have more, we only need to check the first one
      const livestockSale = saleByIdLookup[billingDocument.livestockSaleIds[0]];
      const saleSubType =
        saleSubTypeByIdLookup[livestockSale?.sale_sub_type_id];
      return (
        calculateInterestChargeCentsAndDaysToCharge(
          billingDocument,
          interestSettings,
          startDate,
          endDate,
          activeDeployment,
          saleSubType,
          paymentsByIdLookup,
        ).interestToChargeCents > 0
      );
    })
    .map(billingDocument => {
      const interestSettings =
        interestSetttingsByBillingDocumentIdLookup[billingDocument.id];
      // Billing documents at the moment generally only have one related livestock sale, but if they have more, we only need to check the first one
      const livestockSale = saleByIdLookup[billingDocument.livestockSaleIds[0]];
      const saleSubType =
        saleSubTypeByIdLookup[livestockSale?.sale_sub_type_id];
      const isFinanicalSubType = saleSubType?.isFinancialSaleSubType;

      const interestCharge = calculateInterestChargeCentsAndDaysToCharge(
        billingDocuments[billingDocument.id],
        interestSettings,
        startDate,
        endDate,
        activeDeployment,
        saleSubType,
        paymentsByIdLookup,
      );
      return {
        to_business_id: billingDocument.recipientBusinessId,
        from_business_id: billingDocument.issuerBusinessId,
        created_from_document_id: billingDocument.id,
        subtotal_cents: interestCharge.interestToChargeCents.toFixed(4),
        note: `${
          isFinanicalSubType ? "Compounding" : ""
        } Interest charged for ${
          interestCharge.daysOfInterest
        } days (${formatDateString(
          interestCharge.chargeFromDate,
        )} - ${formatDateString(
          interestCharge.chargeToDate,
        )}) @ ${formatPercentage(interestSettings.interestRate, {
          decimalPlaces: 2,
          roundingMethod: null,
        })} - ${billingDocument.type}-${billingDocument.number} - ${
          livestockSaleTitles[billingDocument.livestockSaleIds[0]]
        }`,
        deployment_id: activeDeployment.id,
      };
    });
  const salePayload = {
    sale_type: SaleTypes.SUNDRY_SALE,
    sale_sub_type_id: interestSaleSubType.id,
    date: formatISO8601DateString(endDate),
    pricing_type_id: PricingTypes.PER_HEAD,
    species_id: Species.SUNDRY_SALE,
    sale_title: `Interest Charges ${formatDateString(endDate)}`,
    deployment_ids: Object.values(deployments).map(value => value.id),
    deployment_sales: Object.values(deployments).map(deployment => ({
      deployment_id: deployment.id,
    })),
    manual_adjustments: sundryData,
  };

  const onSubmit = () => {
    dispatch(addSale(salePayload, availableSaleyards[0]));
    onClose();
  };

  const disabled = !isValid || sundryData.length === 0;

  const documentTypeText = documentType => {
    const invoiceText = pluralize(BillingDocumentNames.INVOICE);
    const rctiText = pluralize(BillingDocumentNames.RCTI);
    if (documentType === BillingDocumentType.INVOICE) {
      return invoiceText;
    }
    if (documentType === BillingDocumentType.RCTI) {
      return rctiText;
    }
    return `${invoiceText} and ${rctiText}`;
  };

  return (
    <Dialog maxWidth="sm" open onClose={onClose}>
      <DialogTitle onClose={onClose}>Generate Interest charges</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LabelText>
              Generate a new Sundry Sale containing suggested Interest charges
              on overdue {documentTypeText(filteredDocumentType)} for all
              Businesses within the given date range. Charges are suggested, and
              you can modify them before generating{" "}
              {documentTypeText(filteredDocumentType)}.
            </LabelText>
          </Grid>
          <InputDateRange
            updateDates={updateDates}
            startDateFieldName="createdGte"
            endDateFieldName="createdLte"
            includeTime={false}
            lastDayInclusive
            tooltip={inclusiveToolTip}
            label="Date Range"
          />
          <Grid item xs={12}>
            <OptionTogglerField
              name="documentType"
              label="Document Type"
              labelPosition="top"
              options={documentOptions}
              tooltip="The type of Documents for which Interest charges will be generated."
            />
          </Grid>
          <Grid item xs={12}>
            <LabelText>
              If {documentTypeText(filteredDocumentType)} are considered to be
              subject to interest charges according to your settings within this
              date range, it will be included in suggested Interest charges.
            </LabelText>
          </Grid>
          <Grid item xs={12}>
            {isValid ? sundryData.length : 0} charges will be created.
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button disabled={disabled} onClick={onSubmit} type="submit">
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const InterestModalAdapter = () => {
  return (
    <Formik
      initialValues={{
        createdGte: null,
        createdLte: null,
        documentType: BillingDocumentType.INVOICE,
      }}
      validationSchema={validationSchema}
    >
      <InterestModal />
    </Formik>
  );
};
