import React from "react";

import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { sumBy } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Button } from "components/Form/Button";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { Paper } from "components/Paper";
import { SaleLotModalTypes } from "components/SaleLotForms/constants";

import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";

import { getDollarPriceStringFromCents } from "lib";

import {
  getLivestockSaleId,
  openImportExternalAgentXml,
  openModalLink,
  openSaleModal,
} from "lib/navigation";
import { getApiSale } from "lib/sale";

import { selectExternalAgentSaleOverviewDataByBusinessField } from "selectors/externalAgentSaleOverview";

const Table = styled.table(
  ({ theme }) => `
  border-collapse: collapse;
  border: 1px solid ${theme.colors.gray44};
  th, td {
    border: 1px solid ${theme.colors.gray44};
    padding: ${theme.space[1]}px;
  }
  tfoot td {
    border-top: 3px double ${theme.colors.gray44};
    font-weight: bold;
  }
  thead th {
    border-bottom: 2px solid ${theme.colors.gray44};
    font-weight: bold; 
  }
`,
);

const Summary = ({ data, showAccountNote = false }) => {
  return (
    <Table>
      <thead>
        <tr>
          {showAccountNote && <th className="text-left">Account Note</th>}
          <th className="text-left">Business</th>
          <th className="text-left">Hd</th>
          <th className="text-left">Lots</th>
          <th className="text-left">Gross</th>
        </tr>
      </thead>
      <tbody>
        {data.map((d, index) => {
          return (
            <tr key={`${index}_${d.businessName}_${d.accountNote}`}>
              {showAccountNote && (
                <td className="text-left">{d.accountNote}</td>
              )}
              <td className="text-left">
                {d.isLoading ? (
                  <Skeleton animation="wave" variant="text" />
                ) : (
                  d.businessName
                )}
              </td>
              <td className="text-right">{d.quantity.toLocaleString()}</td>
              <td className="text-right">{d.count.toLocaleString()}</td>
              <td className="text-right">
                <Row justifyBetween>
                  <span>$</span>
                  <span>
                    {getDollarPriceStringFromCents(d.gross, "0", true)}
                  </span>
                </Row>
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          {showAccountNote && <td className="text-left" />}
          <td className="text-left" />
          <td className="text-right">
            {sumBy(data, "quantity").toLocaleString()}
          </td>
          <td className="text-right">
            {sumBy(data, "count").toLocaleString()}
          </td>
          <td className="text-right">
            <Row justifyBetween>
              <span>$</span>
              <span>
                {getDollarPriceStringFromCents(sumBy(data, "gross"), "0", true)}
              </span>
            </Row>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

const ExternalAgentSaleOverviewComponent = () => {
  const summaryByVendor = useSelector(
    selectExternalAgentSaleOverviewDataByBusinessField,
  ).vendor_id;
  const summaryByBuyer = useSelector(
    selectExternalAgentSaleOverviewDataByBusinessField,
  ).buyer_id;

  return (
    <Column padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={6} container>
          <Button
            onClick={() =>
              openModalLink(ModalTypes.SaleLot, {
                type: SaleLotModalTypes.EXTERNAL_AGENT_SALE,
              })
            }
          >
            Add Lot
          </Button>
        </Grid>
        <Grid item xs={6} container>
          <Button onClick={() => openImportExternalAgentXml(getApiSale())}>
            Import XML
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <h2>Coming From (Agent)</h2>
            <Summary data={summaryByVendor} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <h2>Going To (Buyer)</h2>
            <Summary data={summaryByBuyer} showAccountNote />
          </Paper>
        </Grid>
      </Grid>
    </Column>
  );
};

export const ExternalAgentSaleOverview = () => {
  return (
    <WaitForSync requiredData={[ApiModel.SALE_LOTS]}>
      <ExternalAgentSaleOverviewComponent />
      <Row>
        <Button onClick={() => openSaleModal(getLivestockSaleId())}>
          Edit Sale
        </Button>
      </Row>
    </WaitForSync>
  );
};
