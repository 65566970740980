const iframeId = "printFile";

const initializeIframe = () => {
  const iframe = document.createElement("iframe");
  iframe.id = iframeId;
  iframe.style.display = "none";
  document.body.appendChild(iframe);
  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
  };
  return iframe;
};

export const iframePrintFile = url => {
  const iframe = document.getElementById(iframeId) || initializeIframe();
  iframe.src = url;
};
