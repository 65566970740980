export const BillingDocumentType = {
  INVOICE: "INVOICE",
  RCTI: "RCTI",
};

export const BillingDocumentNames = {
  INVOICE: "Invoice",
  RCTI: "RCTI",
};

export const BillingDocumentTypeOptions = [
  {
    label: BillingDocumentNames.RCTI,
    value: BillingDocumentType.RCTI,
  },
  {
    label: BillingDocumentNames.INVOICE,
    value: BillingDocumentType.INVOICE,
  },
];

export const BillingDocumentStatus = {
  APPROVED: "APPROVED",
  DRAFT: "DRAFT",
  EXPORTED: "EXPORTED",
  EXPORTING: "EXPORTING",
  EXPORT_ERROR: "EXPORT_ERROR",
  EXPORTED_WITH_WARNING: "EXPORTED_WITH_WARNING",
  NEEDS_ATTENTION: "NEEDS_ATTENTION",
  PAID: "PAID",
  SENT: "SENT",
  AUTHORISED: "AUTHORISED",
};

export const BillingDocumentStatusOptions = [
  {
    label: "Draft",
    value: BillingDocumentStatus.DRAFT,
  },
  {
    label: "Approved",
    value: BillingDocumentStatus.APPROVED,
  },
  {
    label: "Sent",
    value: BillingDocumentStatus.SENT,
  },
  {
    label: "Paid",
    value: BillingDocumentStatus.PAID,
  },
  {
    label: "Exporting",
    value: BillingDocumentStatus.EXPORTING,
  },
  {
    label: "Exported",
    value: BillingDocumentStatus.EXPORTED,
  },
  {
    label: "Export Error",
    value: BillingDocumentStatus.EXPORT_ERROR,
  },
  {
    label: "Exported with Warning",
    value: BillingDocumentStatus.EXPORTED_WITH_WARNING,
  },
];

export const BillingDocumentStatusColorMap = {
  [BillingDocumentStatus.DRAFT]: "ongoing",
  [BillingDocumentStatus.APPROVED]: "approved",
  [BillingDocumentStatus.SENT]: "passedIn",
  [BillingDocumentStatus.PAID]: "paid",
  [BillingDocumentStatus.EXPORTING]: "activeColour",
  [BillingDocumentStatus.EXPORTED]: "activeGreen",
  [BillingDocumentStatus.EXPORT_ERROR]: "errorRed",
  [BillingDocumentStatus.EXPORTED_WITH_WARNING]: "withdrawn",
  [BillingDocumentStatus.AUTHORISED]: "primary",
};

export const BillingDocumentTypeColorMap = {
  [BillingDocumentType.RCTI]: "lavender",
  [BillingDocumentType.INVOICE]: "lightCoral",
};

export const BillingDocumentReportJobStatus = {
  COMMITTED: "COMMITTED",
  TEMPORARY: "TEMPORARY",
};

export const BillingDocumentException = {
  VENDOR_MISSING_BANKING_DETAILS: "Missing Bank Details",
  BUSINESSES_WITH_UNKNOWN_TAX_STATUS: "Unknown Tax Status",
  MISSING_INVOICE_TO: "Missing 'Invoice To'",
};

export const BillingDocumentIntegrationStatus = {
  DRAFT: "DRAFT",
  SUBMITTED: "SUBMITTED",
  DELETED: "DELETED",
  PAID: "PAID",
  AUTHORISED: "AUTHORISED",
  VOIDED: "VOIDED",
};

export const BillingDocumentIntegrationStatusColorMap = {
  [BillingDocumentIntegrationStatus.DRAFT]: "ongoing",
  [BillingDocumentIntegrationStatus.SUBMITTED]: "approved",
  [BillingDocumentIntegrationStatus.DELETED]: "errorRed",
  [BillingDocumentIntegrationStatus.PAID]: "paid",
  [BillingDocumentIntegrationStatus.AUTHORISED]: "activeColour",
  [BillingDocumentIntegrationStatus.VOIDED]: "withdrawn",
};
