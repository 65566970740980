import React from "react";

import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Row } from "components/Layout";

import { Species } from "constants/species";

import { currentSaleSelector } from "selectors";

import { BuyerCard } from "./BuyerCard";

const Wrapper = styled(Row)`
  flex-wrap: wrap;
  margin: 9px;
`;

const BuyerCardGrid = ({ buyers, setBuyerValues }) => {
  // Show livestock agents their deployment name, and admins the base
  // business name.
  const speciesId = useSelector(currentSaleSelector).species_id;

  const buyerCards = buyers.map(buyer => (
    <BuyerCard
      data-tour={buyer.name}
      key={buyer.id}
      onClick={() => {
        setBuyerValues({
          buyerId: buyer.id,
          buyerWayId: null,
          propertyId: buyer.defaultProperty?.id,
        });
      }}
      isTopBuyer={Species.topBuyerFilter(speciesId)(buyer)}
    >
      {buyer.name}
    </BuyerCard>
  ));
  return <Wrapper data-tour="topBuyerTable">{buyerCards}</Wrapper>;
};

BuyerCardGrid.propTypes = {
  buyers: PropTypes.array,
  setBuyerValues: PropTypes.func.isRequired,
};

// Stop unnecessary renders
const buyers = buyerArray =>
  buyerArray.map(business => ({
    name: business.name,
    id: business.id,
    property: business.defaultProperty?.id,
  }));
const areEqual = (prevProps, nextProps) =>
  isEqual(buyers(prevProps.buyers), buyers(nextProps.buyers));

export default React.memo(BuyerCardGrid, areEqual);
