import reduceReducers from "reduce-reducers";

import { BILLING_DOCUMENT } from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  bulkUpdateCases,
  reduceById,
  resetStateOnRoleChangeReducer,
} from "lib/reducers";
import {
  deserializeBillingDocument,
  deserializeBulkUpdateBillingDocument,
  deserializeRetainedProceedsBillingDocument,
} from "lib/serializers/billingDocuments";

const create = apiModelOfflineCreateReducer(BILLING_DOCUMENT, {
  deserializer: deserializeBillingDocument,
});

const fetch = apiModelOfflineFetchReducer(BILLING_DOCUMENT, {
  deserializer: deserializeBillingDocument,
  clearOnRequest: false,
});

const update = apiModelOfflineUpdateReducer(BILLING_DOCUMENT, {
  deserializer: deserializeBillingDocument,
});

const deleteReducer = apiModelOfflineDeleteReducer(BILLING_DOCUMENT);

const setIsFetchingForPayloadId = isFetching => (state, action) => {
  const billingDocumentId = action.payload.id;
  return {
    ...state,
    byId: {
      ...state.byId,
      [billingDocumentId]: {
        ...state.byId[billingDocumentId],
        ...deserializeBillingDocument(action.payload, { isFetching }),
      },
    },
  };
};

function billingDocumentChangesReducers(state, action) {
  switch (action.type) {
    case BILLING_DOCUMENT.REJECT_CHANGES.REQUEST:
    case BILLING_DOCUMENT.ACCEPT_CHANGES.REQUEST:
    case BILLING_DOCUMENT.ACCEPT_AND_REVERSE.REQUEST: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            isFetching: true,
          },
        },
      };
    }

    case BILLING_DOCUMENT.ACCEPT_CHANGES.SUCCESS:
    case BILLING_DOCUMENT.REJECT_CHANGES.SUCCESS: {
      return setIsFetchingForPayloadId(false)(state, action);
    }
    case BILLING_DOCUMENT.ACCEPT_AND_REVERSE.SUCCESS: {
      // Functionally reversing a document deletes it - the replacement will have a new id.
      const byId = { ...state.byId };
      delete byId[action.payload.id];
      return {
        ...state,
        byId,
      };
    }

    /*
     * TODO Move these case statements into their own reducer - this is the "ChangesReducer"
     * */
    case BILLING_DOCUMENT.EXPORT_XERO_INVOICE.SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...reduceById(action.payload.map(deserializeBillingDocument)),
        },
      };
    }

    case BILLING_DOCUMENT.UPDATE_BULK.REQUEST:
    case BILLING_DOCUMENT.UPDATE_BULK.SUCCESS:
    case BILLING_DOCUMENT.UPDATE_BULK.FROM_SOCKET: {
      return bulkUpdateCases(
        state,
        action,
        "byId",
        deserializeBulkUpdateBillingDocument,
        {},
      );
    }

    default:
      return state;
  }
}

function billingDocumentRetainProceedsReducer(state, action) {
  switch (action.type) {
    case BILLING_DOCUMENT.RETAIN_PROCEEDS.REQUEST: {
      const { id } = action.payload;
      const billingDocument = {
        ...(state.byId[id] || {}),
        syncing: true,
      };

      const retainedProceeds = deserializeRetainedProceedsBillingDocument(
        action.payload,
      );

      billingDocument.proceedsRetainedByBillingDocument = {
        ...(billingDocument.proceedsRetainedByBillingDocument || null),
        ...retainedProceeds,
      };

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: billingDocument,
        },
      };
    }

    case BILLING_DOCUMENT.RETAIN_PROCEEDS.SUCCESS: {
      const { payload } = action;
      const data = deserializeBillingDocument(payload);

      return {
        ...state,
        byId: {
          ...state.byId,
          [data.id]: data,
        },
      };
    }

    case BILLING_DOCUMENT.RETAIN_PROCEEDS.FAILURE: {
      const { id } = action.payload;
      // We...really should figure out what to do here
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            syncing: false,
          },
        },
      };
    }

    default:
      return state;
  }
}

const billingDocumentReducer = reduceReducers(
  create,
  fetch,
  update,
  deleteReducer,
  billingDocumentChangesReducers,
  billingDocumentRetainProceedsReducer,
  resetStateOnRoleChangeReducer,
);

export default billingDocumentReducer;
