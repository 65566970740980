import React, { memo, useState } from "react";

import { faLongArrowAltRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox as SecondaryCheckbox, Grid } from "@material-ui/core";
import { getIn, useFormikContext } from "formik";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { setSetting } from "actions";

import { HideShowButton } from "components/Button";
import {
  CheckBox,
  Input,
  Label,
  LabelledControl,
  withNamespace,
} from "components/Form/FormikControls";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import PenningConflictNotice from "components/PenningSaleLotForm/PenningConflictNotice";
import ReactSelect from "components/SearchableSelector/ReactSelect";

import { PenTypes } from "constants/auctionPens";
import { ApiModel } from "constants/loading";
import { Settings } from "constants/settings";

import { expandAuctionPen, getAuctionPenDisplayName } from "lib/auctionPens";

import {
  getAuctionPens,
  getBusinessById,
  getBuyerAndBuyerWayIdsByBuyerHash,
  getOwnedPenIdsByBuyerHash,
  getSettings,
} from "selectors";

const ToContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  margin: auto;
`;

export function isPenningFormDirty(initialValues, values, namespace = "") {
  return ![
    withNamespace(namespace, "start_pen_prefix"),
    withNamespace(namespace, "start_pen"),
    withNamespace(namespace, "start_pen_suffix"),
    withNamespace(namespace, "end_pen"),
    withNamespace(namespace, "end_pen_suffix"),
    withNamespace(namespace, "overflowPen"),
    withNamespace(namespace, "overflowQuantity"),
  ].every(key => {
    const a = get(values, key);
    const b = get(initialValues, key);
    return (
      a === b || // used for start_pen and end_pen, where they are numbers or strings
      (!a && !b) // used for all optional values, which may show up as "", or null, or undefined;
    );
  });
}

export const PenningMode = {
  SIMPLIFIED: 0,
  ADVANCED: 1,
};

function PenningSaleLotFormComponent(props) {
  const {
    namespace: ns,
    autoFocusStartPen,
    penType = PenTypes.SELLING,
    saleLotId,
    saleRoundId,
    label,
    penningMode: initialPenningMode,
    required = false,
    showOwnerPrompt = false,
    buyerHash,
    readOnly,
  } = props;
  const { values, setFieldValue, setFieldTouched, validateForm } =
    useFormikContext();
  const startPen = getIn(values, withNamespace(ns, "start_pen"));
  const endPen = getIn(values, withNamespace(ns, "end_pen"));
  const isLocked = getIn(values, withNamespace(ns, "isLocked"));
  const currentPenType = getIn(values, withNamespace(ns, "penType"));
  const startPenPrefix =
    getIn(values, withNamespace(ns, "start_pen_prefix")) || "";
  const startPenSuffix =
    getIn(values, withNamespace(ns, "start_pen_suffix")) || "";
  const endPenSuffix = getIn(values, withNamespace(ns, "end_pen_suffix")) || "";

  // If any of the advanced fields are used, show the advanced regardless of settings.
  const penModeOverride =
    !!endPen ||
    ["start_pen_prefix", "start_pen_suffix", "end_pen_suffix"].some(
      fieldName => !!getIn(values, withNamespace(ns, fieldName)),
    )
      ? PenningMode.ADVANCED
      : null;
  const penningModeSetting = useSelector(getSettings)[Settings.penningMode];
  const [tempPenChecked, setTempPenChecked] = useState(
    currentPenType === PenTypes.TEMP,
  );
  const ownedPenIds = useSelector(getOwnedPenIdsByBuyerHash(buyerHash));
  const buyerDetails = useSelector(
    getBuyerAndBuyerWayIdsByBuyerHash(buyerHash),
  );
  const buyerBusiness = useSelector(getBusinessById(buyerDetails?.buyerId));
  const auctionPens = useSelector(getAuctionPens);

  const dispatch = useDispatch();

  const [penningMode, setPenningMode] = useState(
    penModeOverride ||
      initialPenningMode ||
      penningModeSetting ||
      PenningMode.SIMPLIFIED,
  );

  function checkIfPenIsLocked(e) {
    if (penType === PenTypes.DELIVERY) {
      const deliveryPen = Object.values(auctionPens).find(
        auctionPen =>
          auctionPen.start_pen === e.target.value &&
          auctionPen.penType === PenTypes.DELIVERY,
      );
      if (deliveryPen?.isLocked) {
        setFieldValue(withNamespace(ns, "isLocked"), true);
      } else {
        setFieldValue(withNamespace(ns, "isLocked"), false);
      }
    }
  }

  function onClickToggleAdvanced() {
    const newPenningMode =
      penningMode === PenningMode.ADVANCED
        ? PenningMode.SIMPLIFIED
        : PenningMode.ADVANCED;

    if (newPenningMode !== penningModeSetting) {
      dispatch(setSetting(Settings.penningMode, newPenningMode));
    }

    setPenningMode(newPenningMode);
  }

  function onSelectOwnedPen({ value: penId }) {
    // Expand the auction pen into its parts, then set each of them as the
    // field value, except penType

    const expandedFields = {
      ...expandAuctionPen(null),
      ...expandAuctionPen(auctionPens[penId]),
    };
    Object.entries(expandedFields)
      .filter(([fieldName, ignored]) => fieldName !== "penType")
      .forEach(([fieldName, fieldValue]) => {
        setFieldValue(
          withNamespace(ns, fieldName),
          typeof fieldValue === "string" ? fieldValue || "" : fieldValue,
          false,
        ).then(() => {
          setFieldTouched(withNamespace(ns, fieldName), true, false);
        });
      });
    validateForm();
  }

  const ownedPenOptions = ownedPenIds.map(penId => ({
    label: getAuctionPenDisplayName(auctionPens[penId]),
    value: penId,
  }));

  function setTempPen() {
    if (tempPenChecked) {
      setTempPenChecked(false);
      setFieldValue(withNamespace(ns, "penType"), PenTypes.SELLING);
    } else {
      setTempPenChecked(true);
      setFieldValue(withNamespace(ns, "penType"), PenTypes.TEMP);
    }
  }
  return (
    <>
      <Grid item sm={3}>
        <Input
          type="number"
          label={label}
          name={withNamespace(ns, "start_pen")}
          required={required}
          autoFocus={autoFocusStartPen}
          onChangeExtra={checkIfPenIsLocked}
          disabled={readOnly}
        />
      </Grid>
      <Grid item sm={9} container align="center">
        <LabelledControl label="&nbsp;">
          <HideShowButton
            text="Advanced Penning"
            onToggle={onClickToggleAdvanced}
            isOpen={penningMode === PenningMode.ADVANCED}
          />
        </LabelledControl>
      </Grid>
      {penningMode === PenningMode.ADVANCED && (
        <>
          <Grid item sm={2}>
            <Input
              label="Prefix"
              name={withNamespace(ns, "start_pen_prefix")}
              disabled={readOnly}
            />
          </Grid>
          <Grid item sm={3}>
            <Input
              type="number"
              label="Pen"
              name={withNamespace(ns, "start_pen")}
              required={required}
              disabled={readOnly}
            />
          </Grid>
          <Grid item sm={2}>
            <Input
              label="Suffix"
              name={withNamespace(ns, "start_pen_suffix")}
              disabled={readOnly}
            />
          </Grid>
          <Grid item sm={1}>
            <ToContainer>
              To
              <FontAwesomeIcon icon={faLongArrowAltRight} />
            </ToContainer>
          </Grid>
          <Grid item sm={2}>
            <Input
              type="number"
              label="Pen"
              name={withNamespace(ns, "end_pen")}
              emptyValue={null}
              disabled={readOnly}
            />
          </Grid>
          <Grid item sm={2}>
            <Input
              label="Suffix"
              name={withNamespace(ns, "end_pen_suffix")}
              disabled={readOnly}
            />
          </Grid>
          {penType === PenTypes.DELIVERY && (
            <Grid item sm={12}>
              <CheckBox
                name={withNamespace(ns, "isLocked")}
                label="Is Locked?"
                disabled={readOnly}
              />
            </Grid>
          )}
          {penType !== PenTypes.DELIVERY && (
            <Grid item sm={12}>
              <Label>Temporary Pen</Label>
              <SecondaryCheckbox
                data-tour="temporaryPen"
                checked={tempPenChecked}
                onChange={setTempPen}
                color="secondary"
                disabled={readOnly}
              />
            </Grid>
          )}
        </>
      )}
      {showOwnerPrompt && ownedPenIds.length > 0 ? (
        <Grid item xs={12}>
          <strong>
            {buyerDetails
              ? `${buyerBusiness?.name}${
                  buyerDetails?.buyerWayName
                    ? ` - ${buyerDetails.buyerWayName}`
                    : ""
                } `
              : "This buyer "}
          </strong>
          has currently assigned pens.
          <ReactSelect
            options={ownedPenOptions}
            onChange={onSelectOwnedPen}
            placeholder="Select an existing pen..."
            isDisabled={readOnly}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <WaitForSync requiredData={[ApiModel.SALE_LOTS, ApiModel.AUCTION_PENS]}>
          <PenningConflictNotice
            saleLotId={saleLotId}
            saleRoundId={saleRoundId}
            endPenNumber={+endPen}
            penType={penType}
            startPenNumber={+startPen}
            startPenPrefix={startPenPrefix}
            startPenSuffix={startPenSuffix}
            endPenSuffix={endPenSuffix}
            showOwnerPrompt={showOwnerPrompt}
            isLocked={isLocked}
          />
        </WaitForSync>
      </Grid>
    </>
  );
}
PenningSaleLotFormComponent.propTypes = {
  autoFocusStartPen: PropTypes.bool,
  namespace: PropTypes.string,
  penningMode: PropTypes.number,
  penType: PropTypes.string,
  saleLotId: PropTypes.string,
  saleRoundId: PropTypes.number,
  showOwnerPrompt: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default memo(PenningSaleLotFormComponent);
