import { getBounceCount, getErrorCount } from "lib/email";
import { pluralize } from "lib/pluralize";

export const emailCountRenderer = props => {
  const { value, data } = props;

  // ugh, why can't you be normal, reportJobs!
  const emails = data.reportJob ? data.reportJob.emails : data.emails;

  if (value > 0 && emails && emails.length) {
    // If we have more than 0 emails, and a reference to these emails, summarize any errors states
    const errorCount = getErrorCount(emails);
    const bounceCount = getBounceCount(emails);
    const emailCountSummary = [`${value}`];
    if (errorCount > 0) {
      emailCountSummary.push(
        `(${errorCount} ${pluralize("error", errorCount)})`,
      );
    }
    if (bounceCount > 0) {
      emailCountSummary.push(`(${bounceCount} bounced)`);
    }
    return emailCountSummary.join(" ");
  }
  return value;
};
