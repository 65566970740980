import React, { useCallback } from "react";

import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { NumberSchema, ObjectSchema, StringSchema } from "yup";

import { DialogContent } from "components/MaterialDialog";
import { FormValues, RetainProceedsForm } from "components/RetainProceeds/Form";

import {
  BillingDocumentIntegrationStatus,
  BillingDocumentType,
} from "constants/billingDocuments";
import { DeploymentPermissions } from "constants/permissions";

import {
  getActiveLivestockAgentDeployment,
  getBillingDocumentById,
} from "selectors";

import { useHasPermission } from "hooks";

import { BillingDocumentAction } from "actions";
import { ModalActions } from "./DialogActions";

interface ModalContentProps {
  billingDocumentId: string;
  onClose: () => void;
}

export function ModalContent(props: ModalContentProps): React.ReactNode {
  const { billingDocumentId, onClose } = props;

  const billingDocument: BillingDocument | null = useSelector(
    getBillingDocumentById(billingDocumentId) as (
      any,
    ) => BillingDocument | null,
  );

  const existingRetainedProceeds: RetainedProceeds | null =
    billingDocument?.proceedsRetainedByBillingDocument || null;
  const totalAmountCents = (billingDocument.totalAmountDollars || 0) * 100;
  const owingAmountCents =
    totalAmountCents - (billingDocument.totalPaidCents || 0);

  const deployment = useSelector(getActiveLivestockAgentDeployment);
  const { defaultRetainedProceedsGlCode = null } =
    deployment.deploymentSettings || {};

  const hasRetainProceedsPermission = useHasPermission(
    getActiveLivestockAgentDeployment,
    DeploymentPermissions.featureRetainedProceeds,
  );

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: FormValues) => {
      dispatch(
        BillingDocumentAction.retainProceeds({
          id: billingDocumentId,
          ...values,
        }),
      );
      onClose();
    },
    [billingDocumentId, dispatch, onClose],
  );

  const getValidationSchema = useCallback(
    () =>
      new ObjectSchema().shape({
        totalAmountCents: new NumberSchema()
          .positive("must be a positive number")
          .min(0, "Must not be less than 0")
          .max(
            owingAmountCents,
            "Must not be more than the amount owing on the RCTI",
          )
          .required("Required"),
        glCode: new StringSchema().required("Required"),
      }),
    [owingAmountCents],
  );

  if (!hasRetainProceedsPermission) {
    return "You do not have permission to access this feature.";
  }

  if (billingDocument.type !== BillingDocumentType.RCTI) {
    return "This feature is only available for RCTIs.";
  }

  const isEditable =
    !existingRetainedProceeds ||
    ![
      BillingDocumentIntegrationStatus.AUTHORISED,
      BillingDocumentIntegrationStatus.PAID,
    ].includes(existingRetainedProceeds.integrationStatus);

  const defaultInitialValues: FormValues = {
    glCode: defaultRetainedProceedsGlCode,
    totalAmountCents: 0,
  };

  const existingValues: FormValues = existingRetainedProceeds
    ? {
        glCode: existingRetainedProceeds.glCode,
        totalAmountCents: existingRetainedProceeds.totalAmountCents,
      }
    : null;

  const initialValues: FormValues = {
    ...defaultInitialValues,
    ...existingValues,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
    >
      <Form>
        <DialogContent>
          <Grid container spacing={2}>
            <RetainProceedsForm
              billingDocumentId={billingDocumentId}
              readOnly={!isEditable}
            />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {isEditable
                ? "A Credit Note will be generated for the amount to retain with the selected GL code. The same amount will be added as a charge to the document you’re retaining proceeds from, resulting in a lower total amount due."
                : "A Credit Note has already been allocated to other invoices and cannot be changed"}
            </Grid>
          </Grid>
        </DialogContent>
        <ModalActions
          isCreate={!existingRetainedProceeds}
          onClose={onClose}
          readOnly={!isEditable}
        />
      </Form>
    </Formik>
  );
}
