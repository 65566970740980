import React, { useMemo, useRef, useState } from "react";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import { syncNlis } from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { EditScansModal } from "components/EditScansModal/EditScansModal";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { AgGridTables } from "constants/aggrid";
import { PenTypes } from "constants/auctionPens";
import { Column } from "constants/columns";
import { CommentTypes } from "constants/comments";
import { ApiModel } from "constants/loading";
import { userTypes } from "constants/users";

import {
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getSaleyardName,
  openAnimalModal,
  openCommentsModal,
  openScanModal,
} from "lib/navigation";

import {
  currentSaleSelector,
  getCurrentSale,
  getCurrentSaleyard,
  getHasWriteAccessInCurrentSale,
  getIsBusinessUser,
  getIsFetchingBusinesses,
  getIsFetchingConsignments,
  getIsFetchingSaleLots,
  getIsFetchingScans,
  getIsScaleOperator,
  scanList,
  selectIsUserOfType,
} from "selectors";

import {
  useDebounceSelector,
  useHasReceivalOrPenScanLotsPermission,
} from "hooks";

import getScanColumnDefs from "./columnDefinitions";

export const isLoadingScanList = state =>
  getIsFetchingScans(state) ||
  getIsFetchingBusinesses(state) ||
  getIsFetchingSaleLots(state) ||
  getIsFetchingConsignments(state);

const getRowId = params => params.data.scan.EID;

// check if the vendor pic exists in another consignment
const vendorPicExistsInAnotherConsignment = params => {
  const { api, node } = params;
  const devicePic = api.getValue(Column.CURRENT_PIC, node);

  let picPresentInAnotherConsignment = false;

  api.forEachNode(thisNode => {
    const rowVendorPic = api.getValue(Column.VENDOR_PIC, thisNode);
    if (rowVendorPic === devicePic && thisNode.id !== node.id) {
      picPresentInAnotherConsignment = true;
    }
  });
  return picPresentInAnotherConsignment;
};

// ORANGE = DEVICE PIC does not equal CONSIGNEMNT PIC, no other CONSIGNMENTS in the sale with that PIC
// YELLOW = DEVICE PIC does not equal CONSIGNEMNT PIC, there's another Consignment in sale with that PIC
const rowClassRules = {
  "row-warning": params => {
    const { api, node } = params;
    const vendorPic = api.getValue(Column.VENDOR_PIC, node);
    const devicePic = api.getValue(Column.CURRENT_PIC, node);

    if (devicePic && vendorPic) {
      // DEVICE PIC does not equal CONSIGNEMNT PIC
      if (devicePic !== vendorPic) {
        // there's not another Consignment in sale with that PIC
        return !vendorPicExistsInAnotherConsignment(params);
      }
    }
    return false;
  },
  "row-yellow": params => {
    const { api, node } = params;
    const vendorPic = api.getValue(Column.VENDOR_PIC, node);
    const devicePic = api.getValue(Column.CURRENT_PIC, node);

    if (devicePic && vendorPic) {
      // DEVICE PIC does not equal CONSIGNEMNT PIC
      if (devicePic !== vendorPic) {
        // there's another Consignment in sale with that PIC
        return vendorPicExistsInAnotherConsignment(params);
      }
    }
    return false;
  },
};

const ScanTable = () => {
  const dispatch = useDispatch();
  const { date, saleyard_name } = useSelector(currentSaleSelector);

  const [agGridApi, setAgGridApi] = useState(null);

  const isScaleOperator = useSelector(getIsScaleOperator);
  const canEditScans = useSelector(
    selectIsUserOfType([userTypes.SALEYARD_ADMIN, userTypes.LIVESTOCK_AGENT]),
  );

  const scans = useDebounceSelector(scanList, 500);

  const [editScansOpen, setEditScansOpen] = useState(false);
  const [selectedScans, setSelectedScans] = useState([]);
  // Use a ref for this, rather than state, as the callback that uses it gets "frozen" down in
  // aggrid, so the referenced state value is unchanged when we come back up here.
  const scansForEditRef = useRef([]);

  const openEditScans = canEditScans
    ? clickedEID => {
        scansForEditRef.current = [clickedEID];
        setEditScansOpen(true);
      }
    : null;

  const editSelectedScans = canEditScans
    ? () => {
        scansForEditRef.current = selectedScans;
        setEditScansOpen(true);
      }
    : null;

  const closeEditScan = () => {
    setEditScansOpen(false);
  };

  const onRowSelectionChange = rows => {
    setSelectedScans(rows.map(r => r.scan.EID));
  };

  function handleClickSaleLotComments(data) {
    const saleLotId = data?.saleLot?.id;
    if (saleLotId) {
      openCommentsModal(CommentTypes.SALE_LOT, saleLotId, window.location.hash);
    }
  }

  const openEditAnimal = isScaleOperator
    ? eid => {
        // TODO - Until we have a hash modal, just send them via the 'take' page.
        const livestockSaleURL = getSaleyardAuctionRoute(
          getSaleyardName(),
          getLivestockSaleId(),
        );
        openAnimalModal(`${livestockSaleURL}/take`, eid);
      }
    : null;

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const isBusinessUser = useSelector(getIsBusinessUser);

  const currentSaleyard = useSelector(getCurrentSaleyard);
  const currentSale = useSelector(getCurrentSale);

  const columnDefs = useMemo(
    () =>
      getScanColumnDefs(
        isBusinessUser,
        hasWriteAccessInCurrentSale,
        currentSaleyard,
        currentSale,
      ),
    [isBusinessUser, hasWriteAccessInCurrentSale, currentSaleyard, currentSale],
  );

  const onFilterChangedExtra = event => {
    const { api } = event;
    api.expireValueCache();
    api.refreshCells({
      columns: [Column.TOTAL_MASS_GRAMS_OUTLIER, Column.AVERAGE_WEIGHT_OUTLIER],
    });
  };

  const onClickGenerateSellCsv = () => {
    if (!agGridApi) {
      return;
    }
    if (agGridApi.getLastDisplayedRow() !== -1) {
      agGridApi.exportDataAsCsv({
        allColumns: true,
        columnKeys: [
          "nlisSaleyardCredentials.nlis_saleyard_id",
          Column.EID,
          Column.DESTINATION_PIC,
          Column.NVD,
          "saleDate",
          "sellFile.saleType",
        ],

        fileName: "SellPossession",
        skipColumnGroupHeaders: true,
        skipRowGroups: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,
      });
    }
  };

  const onClickGenerateTakeCsv = () => {
    if (!agGridApi || agGridApi.getLastDisplayedRow() === -1) {
      return;
    }

    agGridApi.exportDataAsCsv({
      allColumns: true,
      columnKeys: [
        "nlisSaleyardCredentials.nlis_saleyard_id",
        Column.EID,
        Column.VENDOR_PIC,
        Column.NVD,
        "saleDate",
        "takeFile.saleType",
      ],
      fileName: "TakePossession",
      skipColumnGroupHeaders: true,
      skipRowGroups: true,
      skipPinnedTop: true,
      skipPinnedBottom: true,
    });
  };

  const onClickGenerateP2PNwaCsv = () => {
    if (!agGridApi || agGridApi.getLastDisplayedRow() === -1) {
      return;
    }

    agGridApi.exportDataAsCsv({
      allColumns: true,
      columnKeys: [
        Column.EID,
        Column.VENDOR_PIC,
        Column.DESTINATION_PIC,
        Column.NVD,
        "saleDate",
        "p2pFile.authorisationLevel",
        "currentUser.firstName",
        "currentUser.lastName",
        "p2pFile.declarationAccepted",
      ],
      fileName: "P2PNWATransfer",
      skipColumnGroupHeaders: true,
      skipRowGroups: true,
      skipPinnedTop: true,
      skipPinnedBottom: true,
    });
  };

  const additionalActions = hasWriteAccessInCurrentSale
    ? [
        {
          title: "Get Scans",
          onClick: () =>
            openScanModal(null, null, null, null, PenTypes.SELLING),
        },
        {
          icon: faDownload,
          title: "Take CSV",
          onClick: onClickGenerateTakeCsv,
        },
        {
          icon: faDownload,
          title: "Sell CSV",
          onClick: onClickGenerateSellCsv,
        },
        {
          icon: faDownload,
          title: "P2P NWA CSV",
          onClick: onClickGenerateP2PNwaCsv,
        },
      ]
    : [];

  const handleNLISRefresh = () => dispatch(syncNlis());

  const onGridReady = agGrid => setAgGridApi(agGrid.api);

  const context = {
    dispatch,
    handleClickSaleLotComments,
    handleNLISRefresh,
    openEditAnimal,
    openEditScans,
    showCurrentAgencyFilter: true,
  };

  const hasReceivalOrPenScanPermission =
    useHasReceivalOrPenScanLotsPermission();

  return (
    <>
      <AgGridTable
        additionalActions={additionalActions}
        downloadFilename={`${date}-${saleyard_name}-EIDs.csv`}
        columnDefs={columnDefs}
        context={context}
        onGridReady={onGridReady}
        rowData={scans}
        tableName={AgGridTables.SCAN}
        rowSelection="multiple"
        suppressRowClickSelection
        onRowSelectionChanged={onRowSelectionChange}
        editSelected={hasWriteAccessInCurrentSale && editSelectedScans}
        rowSelectionId="scan.EID"
        getRowId={getRowId}
        onFilterChangedExtra={onFilterChangedExtra}
        paginationAutoPageSize
        rowClassRules={
          hasReceivalOrPenScanPermission ? rowClassRules : undefined
        }
        rowsSelectable
      />
      {editScansOpen && (
        <EditScansModal
          eids={scansForEditRef.current}
          closeSelf={closeEditScan}
        />
      )}
    </>
  );
};

const LoadingWrapper = () => (
  <WaitForSync
    requiredData={[
      ApiModel.BUSINESSES,
      ApiModel.SALE_LOTS,
      ApiModel.SCANS,
      ApiModel.CONSIGNMENTS,
    ]}
    subjectName="EIDs"
  >
    <ScanTable />
  </WaitForSync>
);

export default LoadingWrapper;
