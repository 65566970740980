import React, { useEffect } from "react";

import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { faSignIn } from "@fortawesome/pro-regular-svg-icons";
import {
  faCopy,
  faPencil,
  faSignOut,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import Grid from "@material-ui/core/Grid";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";

import {
  closeConfirmModal,
  MasterRuleAction,
  MasterRuleBookAction,
  openConfirmModal,
  RuleBookAction,
} from "actions";

import { MultiButton } from "components/Button";
import { Chip, ChipWrapper } from "components/Chip";
import { CollapseIcon } from "components/Icons/CollapseIcon";
import { Paper } from "components/Paper";
import { StatusWrapper } from "components/ScanningPenCards/Card/elements";

import { RuleValueFormat } from "constants/ruleBooks";

import { ValueSource } from "containers/Settings/RuleBooks/constants";
import { CustomiseManagedRuleBookLibraryForm } from "containers/Settings/RuleBooks/Library/CustomiseManagedRuleBookLibraryForm";
import { getGstFee } from "containers/Settings/RuleBooks/Rules/CustomRules/ValueClause";

import { EMPTY_ARRAY } from "lib";

import {
  getIsStaff,
  getMasterRuleBookById,
  getMasterRuleIdsByMasterRuleBookId,
  getMasterRulesByMasterRuleBookId,
  getRuleBookIdsByMasterRuleBookId,
} from "selectors";

import { useToggle } from "hooks";

function getDefaultRuleContent() {
  return JSON.stringify({
    criteria: [],
    from_business: { source: "", attribute: "", fieldId: "", index: "" },
    input_field: { attribute: "", fieldId: "" },
    price_multiplier: 1,
    to_business: { source: "", attribute: "", fieldId: "", index: "" },
    value: {
      amount: { source: ValueSource.CONSTANT, value: 0 },
      offset: { source: ValueSource.CONSTANT, value: 0 },
      rounding_value: 0,
      unit_offset: { source: ValueSource.CONSTANT, value: 0 },
      unit_limit: { source: ValueSource.CONSTANT, value: 0 },
      units: { source: "", attribute: "", fieldId: "", index: "" },
      fees: [getGstFee("exclusive")],
    },
    include_if_zero: false,
  });
}

export function MasterRuleBookLibraryCard(props) {
  const { masterRuleBookId, ruleBookId, masterRuleId, showSoftDeleted } = props;

  const masterRuleIds =
    useSelector(getMasterRuleIdsByMasterRuleBookId(masterRuleBookId)) ||
    EMPTY_ARRAY;

  const [isExpanded, toggleIsExpanded] = useToggle(false);
  useEffect(() => {
    const isRuleBookActive = masterRuleIds.includes(masterRuleId);
    if (!isExpanded && isRuleBookActive) {
      toggleIsExpanded();
    }
  }, [isExpanded, masterRuleId, masterRuleIds, toggleIsExpanded]);

  const dispatch = useDispatch();

  const masterRuleBook = useSelector(getMasterRuleBookById(masterRuleBookId));

  const isStaff = useSelector(getIsStaff);

  const isSubscribed = useSelector(state => {
    return (
      getRuleBookIdsByMasterRuleBookId(masterRuleBookId)(state) || []
    ).includes(ruleBookId);
  });

  const nextMasterRuleOrder = useSelector(
    state =>
      (getMasterRulesByMasterRuleBookId(masterRuleBookId)(state) || []).length,
  );

  function onClickAddMasterRule() {
    dispatch(
      MasterRuleAction.create({
        billing_tags: [],
        category: "",
        comment: "",
        content: getDefaultRuleContent(),
        gl_code: "",
        id: v4(),
        name: "New Master Rule",
        order: nextMasterRuleOrder,
        quantity_output_format: RuleValueFormat.UNITS,
        quantity_raw_format: RuleValueFormat.UNITS,
        rule_book_id: masterRuleBookId,
        unit_amount_output_format: RuleValueFormat.DOLLARS,
        unit_amount_raw_format: RuleValueFormat.CENTS,
      }),
    );
  }

  function onClickCopyMasterRuleBook() {
    dispatch(MasterRuleBookAction.copy(masterRuleBookId));
  }

  function onClickEditMasterRuleBook() {
    window.open(
      queryString.stringifyUrl({
        url: `/admin/agrinous_rules/masterrulebook/${masterRuleBookId}/change/?_to_field=cbid`,
        query: { q: masterRuleBook.name },
      }),
      "_blank",
    );
  }

  function onClickConfirmDeleteMasterRule() {
    dispatch(MasterRuleBookAction.delete(masterRuleBookId));

    dispatch(closeConfirmModal());
  }

  function onClickCancelConfirmDeleteMasterRuleBook() {
    dispatch(closeConfirmModal());
  }

  function onClickDeleteMasterRuleBook() {
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message: "Are you sure you want to delete this Master Rule Book?",
        actions: [
          {
            label: "Cancel",
            onClick: onClickCancelConfirmDeleteMasterRuleBook,
            secondary: true,
          },
          {
            label: "Delete Master Rule Book",
            onClick: onClickConfirmDeleteMasterRule,
            secondary: false,
          },
        ],
      }),
    );
  }

  function onClickSubscribe() {
    dispatch(
      RuleBookAction.subscribeToMasterRuleBook(ruleBookId, masterRuleBookId),
    );
  }

  function onClickConfirmUnsubscribe() {
    dispatch(
      RuleBookAction.unsubscribeFromMasterRuleBook(
        ruleBookId,
        masterRuleBookId,
      ),
    );
    dispatch(closeConfirmModal());
  }

  function onClickCancelUnsubscribe() {
    dispatch(closeConfirmModal());
  }

  function onClickUnsubscribe() {
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message:
          "Are you sure you want to unsubscribe from this and all other rules from Master Rule Book?",
        actions: [
          {
            label: "Cancel",
            onClick: onClickCancelUnsubscribe,
            secondary: true,
          },
          {
            label: "Unsubscribe from Master Rule Book",
            onClick: onClickConfirmUnsubscribe,
            secondary: false,
          },
        ],
      }),
    );
  }

  function onClickUpdateRulebook() {
    dispatch(
      MasterRuleBookAction.updateManagedRule(masterRuleBookId, ruleBookId),
    );
  }

  const buttons = [
    {
      title: "Subscribe",
      isDisabled: isSubscribed,
      onClick: onClickSubscribe,
      default: !isSubscribed,
      icon: faSignIn,
      dataTour: "subscribeToRuleBook",
    },
    {
      title: "Unsubscribe",
      isDisabled: !isSubscribed,
      onClick: onClickUnsubscribe,
      default: isSubscribed,
      icon: faSignOut,
      dataTour: "unsubscribeFromRuleBook",
    },
    {
      title: "Update",
      isDisabled: !isSubscribed,
      onClick: onClickUpdateRulebook,
      default: isSubscribed,
      icon: faSync,
      dataTour: "updateRuleBook",
    },
  ];

  if (isStaff) {
    buttons.push({
      title: "Edit Master Rule Book",
      isDisabled: false,
      onClick: onClickEditMasterRuleBook,
      default: false,
      icon: faPencil,
      dataTour: "editMasterRuleBook",
    });
    buttons.push({
      title: "Add Master Rule",
      isDisabled: !isSubscribed,
      onClick: onClickAddMasterRule,
      default: false,
      icon: faPlus,
      dataTour: "addMasterRule",
    });
    buttons.push({
      title: "Copy Master Rule Book",
      isDisabled: false,
      onClick: onClickCopyMasterRuleBook,
      default: false,
      icon: faCopy,
      dataTour: "copyMasterRuleBook",
    });
    buttons.push({
      title: "Delete Master Rule Book",
      isDisabled: false,
      onClick: onClickDeleteMasterRuleBook,
      default: false,
      icon: faTrash,
      dataTour: "deleteMasterRuleBook",
    });
  }

  return (
    <StatusWrapper color={isSubscribed ? "primary" : "grayDisabled"}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={2} sm={1}>
            <ChipWrapper>
              {isSubscribed ? (
                <Chip backgroundColor="primary" color="white">
                  Subscribed
                </Chip>
              ) : (
                <Chip backgroundColor="grayDisabled">Not Subscribed</Chip>
              )}
            </ChipWrapper>
          </Grid>
          <Grid item xs={8}>
            {masterRuleBook.name}
          </Grid>

          <Grid item xs={3} container justifyContent="flex-end">
            <MultiButton buttons={buttons} />
          </Grid>

          <Grid item xs={10}>
            <blockquote>{masterRuleBook.description}</blockquote>
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
            {isSubscribed && (
              <CollapseIcon
                icon={isExpanded ? faChevronUp : faChevronDown}
                onClick={toggleIsExpanded}
              />
            )}
          </Grid>

          {isSubscribed && isExpanded && (
            <Grid item xs={12}>
              <CustomiseManagedRuleBookLibraryForm
                masterRuleBookId={masterRuleBookId}
                onAfterSave={toggleIsExpanded}
                ruleBookId={ruleBookId}
                masterRuleId={masterRuleId}
                showSoftDeleted={showSoftDeleted}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </StatusWrapper>
  );
}
