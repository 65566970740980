import { intersection } from "lodash/array";
import { createSelector } from "reselect";

import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { SystemSaleyards } from "constants/sale";
import { SYSTEM_PUSHER_CHANNEL } from "constants/system";
import { ROLE_TYPES, userTypes } from "constants/users";

import { EMPTY_ARRAY, EMPTY_OBJECT } from "lib";

import { getInitialsFromUserInfo, isUserOfType } from "lib/auth";
import { getSaleyardName } from "lib/navigation";
import { hasPermission, someHasPermission } from "lib/permissions";
import { getPusherChannelName } from "lib/socket";

import {
  getActiveLivestockAgentDeployment,
  getActiveNLISAgentCredentials,
  getActiveNLISSaleyardCredentials,
  getActiveRole,
  getAuth,
  getBusinessUserRoles,
  getCurrentDeploymentSalesList,
  getCurrentUser,
  getDeployments,
  getEmail,
  getFirstName,
  getLastName,
  getLivestockAgentRoles,
  getSaleWatcherRoles,
  getSaleyardAdminRoles,
  getSaleyards,
  getScaleOperatorRoles,
  selectCurrentDeploymentIds,
} from "selectors";

import { selectCurrentSaleyard } from "selectors/saleyards";

export const getName = createSelector(
  [getFirstName, getLastName],
  (firstName, lastName) =>
    [firstName, lastName].filter(val => val).join(" ") || "",
);

export const getInitials = createSelector(
  [getEmail, getFirstName, getLastName],
  (email, firstName, lastName) => {
    const initials = getInitialsFromUserInfo({ firstName, lastName });
    if (initials) {
      return initials;
    }

    // Try and split the first half of the email
    // farmer.brown@brownsfarm.com = FB
    // farmer_brown@dmd = FB
    // farmerbrown@brownsfarm.com = F
    if (email) {
      const emailBits = email.split("@")[0].split(/[-|_|.]/);
      let emailInitials = emailBits[0].substring(0, 1).toUpperCase();
      if (emailBits.length > 1) {
        emailInitials += emailBits[emailBits.length - 1]
          .substring(0, 1)
          .toUpperCase();
      } else if (emailBits[0].length > 1) {
        emailInitials += emailBits[0].substring(1, 1).toUpperCase();
      }
      return emailInitials;
    }
    return "-";
  },
);

export const selectAvailableRolesList = createSelector(
  [
    getSaleyardAdminRoles,
    getLivestockAgentRoles,
    getSaleWatcherRoles,
    getScaleOperatorRoles,
    getBusinessUserRoles,
  ],
  (
    saleyardAdmins,
    livestockAgents,
    saleWatchers,
    scaleOperators,
    businessUsers,
  ) =>
    [].concat(
      Object.values(saleyardAdmins),
      Object.values(livestockAgents),
      Object.values(saleWatchers),
      Object.values(scaleOperators),
      Object.values(businessUsers),
    ),
);

export const selectRoleByRoleSlugLookup = createSelector(
  [
    getSaleyardAdminRoles,
    getLivestockAgentRoles,
    getSaleWatcherRoles,
    getScaleOperatorRoles,
    getBusinessUserRoles,
  ],
  (
    saleyardAdmins,
    livestockAgents,
    saleWatchers,
    scaleOperators,
    businessUsers,
  ) => {
    const lookup = {};
    const slugger = role => {
      lookup[role.slug] = role;
    };
    Object.values(saleyardAdmins).forEach(slugger);
    Object.values(livestockAgents).forEach(slugger);
    Object.values(saleWatchers).forEach(slugger);
    Object.values(scaleOperators).forEach(slugger);
    Object.values(businessUsers).forEach(slugger);
    return lookup;
  },
);

export const getRoleBySlug = slug => state =>
  selectRoleByRoleSlugLookup(state)[slug] || null;

/**
 * @param {Object} state - The Redux state.
 * @param {AuthUserContext} state.auth
 * @returns {Boolean}
 */
export const getIsStaff = state => getCurrentUser(state).is_staff || false;

/**
 * @returns boolean
 */
export const getIsLivestockAgent = state =>
  isUserOfType(userTypes.LIVESTOCK_AGENT, getAuth(state));

/**
 * @returns boolean
 */
export const getIsSaleyardAdmin = state =>
  isUserOfType(userTypes.SALEYARD_ADMIN, getAuth(state));

/**
 * @returns boolean
 */
export const getIsBusinessUser = state =>
  isUserOfType(userTypes.BUSINESS_USER, getAuth(state));

/**
 * @returns boolean
 */
export const getIsScaleOperator = state =>
  isUserOfType(userTypes.SCALE_OPERATOR, getAuth(state));

/**
 * @returns boolean
 */
export const getIsSaleWatcher = state =>
  isUserOfType(userTypes.SALE_WATCHER, getAuth(state));

/**
 * @returns function
 */
export const selectIsUserOfType = userTypes => state =>
  isUserOfType(userTypes, getAuth(state));

/**
 * @param {Object} state - The Redux state.
 * @param {AuthUserContext} state.auth
 * @returns {Agency}
 */
export const getPrimaryAgency = ({ auth }) =>
  auth &&
  auth.activeRole &&
  auth.activeRole.agencies &&
  auth.activeRole.agencies.length > 0
    ? auth.activeRole.agencies[0]
    : EMPTY_OBJECT;

/**
 * @param {Object} state - The Redux state.
 * @param {AuthUserContext} state.auth
 * @returns {String}
 */
export const getOrganizationName = state => {
  const { auth } = state;
  if (!auth || !auth.activeRole) {
    return "";
  }
  if (auth.activeRole.type === userTypes.BUSINESS_USER) {
    const { business } = auth.activeRole;
    return business && business.name ? business.name : "";
  }
  if (auth.activeRole.type === userTypes.LIVESTOCK_AGENT) {
    const agency = getPrimaryAgency(state);
    return agency && agency.name ? agency.name : "";
  }
  if (auth.activeRole.type === userTypes.SALEYARD_ADMIN) {
    const { saleyards } = auth.activeRole;
    return saleyards && saleyards.length > 0 && saleyards[0].name
      ? saleyards[0].name
      : "";
  }
};
/**
 * @returns {Array<Saleyard>}
 */
export const getAvailableSaleyards = createSelector(
  [getActiveRole],
  activeRole => {
    let availableSaleyards = EMPTY_ARRAY;
    if (
      activeRole &&
      Array.isArray(activeRole.saleyards) &&
      activeRole.saleyards.length
    ) {
      availableSaleyards = activeRole.saleyards;
    }
    // Filter out system saleyards (eg Over Hooks / Paddock)
    availableSaleyards = availableSaleyards.filter(
      s => !SystemSaleyards.includes(s.name),
    );

    return availableSaleyards;
  },
);

export const getAllAvailableSaleyards = createSelector(
  [getActiveRole],
  activeRole => {
    let availableSaleyards = EMPTY_ARRAY;
    if (
      activeRole &&
      Array.isArray(activeRole.saleyards) &&
      activeRole.saleyards.length
    ) {
      availableSaleyards = activeRole.saleyards;
    }
    return availableSaleyards;
  },
);

function saleyardParam() {
  return getSaleyardName();
}

/**
 * @return {Saleyard}
 */
export const getSelectedSaleyard = createSelector(
  [getAllAvailableSaleyards, saleyardParam],
  (availableSaleyards, saleyardParam) => {
    if (!saleyardParam) {
      return EMPTY_OBJECT;
    }
    const saleyardParamLower = saleyardParam.toLowerCase();
    return (
      availableSaleyards.find(
        saleyard => saleyard.name.toLowerCase() === saleyardParamLower,
      ) || EMPTY_OBJECT
    );
  },
);

export const getActiveSaleyardId = state => {
  const activeRole = getActiveRole(state) || {};
  if (activeRole.type === ROLE_TYPES.SALEYARD_OPERATOR) {
    return activeRole.saleyards[0].saleyard_id;
  }
  return null;
};

export const getActiveSaleyardAdminSaleyard = createSelector(
  [getSaleyards, getActiveSaleyardId],
  (saleyardByIdLookup, activeSaleyardId) =>
    saleyardByIdLookup[activeSaleyardId],
);

export const getLivestockAgentHasNlisSaleyard = createSelector(
  [getActiveRole, getSaleyards],
  (activeRole, saleyards) =>
    activeRole.type === ROLE_TYPES.STOCK_AGENT
      ? activeRole.saleyards.some(activeYard =>
          hasPermission(
            saleyards[activeYard.saleyard_id],
            SaleyardPermissions.featureNlis,
          ),
        )
      : false,
);

export const selectChannels = createSelector(
  [
    getActiveRole,
    state => state.billingRuns.isEnabled,
    state => state.billingRuns.activeBillingRunId,
    state => state.reportJobs.doPusherSubscribe || false,
  ],
  (
    activeRole,
    isBillingRunsEnabled,
    billingRunId,
    subscribeReportJobPusher,
  ) => {
    if (
      !Array.isArray(activeRole.channels) ||
      activeRole.channels.length === 0
    ) {
      return EMPTY_ARRAY;
    }
    const channels = [];
    for (const channel of activeRole.channels) {
      channels.push(channel);
      if (channel !== SYSTEM_PUSHER_CHANNEL) {
        if (isBillingRunsEnabled) {
          channels.push(getPusherChannelName(channel, "BILLING"));

          if (billingRunId) {
            channels.push(
              getPusherChannelName(channel, "BILLING_RUN", billingRunId),
            );
          }
        }
        if (subscribeReportJobPusher) {
          channels.push(getPusherChannelName(channel, "REPORT_JOB"));
        }
      }
    }
    return channels;
  },
);

export const getHasEnteredNlisCreds = state => {
  const saleyardCredentials = getActiveNLISSaleyardCredentials(state) || {};
  const agentCredentials = getActiveNLISAgentCredentials(state) || {};
  // TODO - Remove after new production release
  const oldNlisCredentials = getActiveRole(state)?.nlis_credentials || {};

  return (
    (saleyardCredentials.nlis_user && saleyardCredentials.nlis_email) ||
    (agentCredentials.nlis_user && agentCredentials.nlis_email) ||
    (oldNlisCredentials.nlis_user && oldNlisCredentials.nlis_email)
  );
};

export const numberOfRolesSelector = createSelector(
  [selectAvailableRolesList],
  availableRolesList => availableRolesList.length,
);

export const getSelectableBranches = createSelector(
  [getActiveRole],
  activeRole => {
    if (activeRole.type === ROLE_TYPES.STOCK_AGENT) {
      return activeRole.agencies.reduce(
        (branches, agency) =>
          agency.branches && agency.branches.length > 0
            ? branches.concat(agency.branches)
            : branches,
        [],
      );
    } else {
      return EMPTY_ARRAY;
    }
  },
);

export const hasBranches = createSelector(
  [getSelectableBranches],
  selectableBranches => selectableBranches.length > 0,
);

export const getActiveRoleDeployments = state =>
  getActiveRole(state).deployments || EMPTY_ARRAY;

export const selectRoleDeploymentIds = createSelector(
  [getActiveRoleDeployments],
  roleDeployments => roleDeployments.map(deployment => deployment.id),
);

export const selectRoleDeployments = createSelector(
  [selectRoleDeploymentIds, getDeployments],
  (deploymentIds, deploymentByIdLookup) =>
    deploymentIds.map(deploymentId => deploymentByIdLookup[deploymentId]),
);

export const selectRoleCurrentDeploymentIds = createSelector(
  [selectRoleDeploymentIds, selectCurrentDeploymentIds],
  (roleDeploymentIds, currentDeploymentIds) =>
    intersection(roleDeploymentIds, currentDeploymentIds),
);

export const selectCurrentDeployments = createSelector(
  [selectCurrentDeploymentIds, getDeployments],
  (currentDeploymentIds, deploymentByIdLookup) =>
    currentDeploymentIds.map(
      deploymentId => deploymentByIdLookup[deploymentId],
    ),
);

export const selectRoleCurrentDeployments = createSelector(
  [selectRoleCurrentDeploymentIds, getDeployments],
  (roleCurrentDeploymentIds, deploymentByIdLookup) => {
    return roleCurrentDeploymentIds.map(
      deploymentId => deploymentByIdLookup[deploymentId],
    );
  },
);

export const selectRoleCurrentDeploymentSales = createSelector(
  [selectRoleCurrentDeploymentIds, getCurrentDeploymentSalesList],
  (roleDeploymentIds, currentDeploymentSales) =>
    currentDeploymentSales?.filter(deploymentSale =>
      roleDeploymentIds.includes(deploymentSale.deployment_id),
    ) || EMPTY_ARRAY,
);

export const getHasMultipleDeploymentsInCurrentSale = state =>
  !getIsLivestockAgent(state) ||
  selectRoleCurrentDeploymentIds(state).length > 1;

// If I am an agent that has NO deployments in the sale, it must
// mean I am consigning to the sale.
export const getIsConsigningAgent = state =>
  getIsLivestockAgent(state) &&
  selectRoleCurrentDeploymentIds(state).length === 0;

export const getEffectiveDeploymentId = state =>
  // When the user has a Deployment in the current LivestockSale, return that
  selectRoleCurrentDeploymentIds(state)[0] ||
  // Fall back to the user's first available Deployment
  selectRoleDeploymentIds(state)[0] ||
  // Y U no have Deployment?
  null;

// Should a consigning agent have access to edit sale - this is a different access
// tier to write access, though in many cases does ALLOW write access.
export const getIsConsigningAgentAndSaleUnlocked = state =>
  getIsConsigningAgent(state) &&
  !getCurrentDeploymentSalesList(state).some(
    deploymentSale => deploymentSale.is_sale_locked,
  );

// Having a deployment in the livestock sale is implicit for anyone but an agent - they can
// only see sales they are involved in.  For an agent, they may be consigning to another
// Note that this does NOT cover a consigning agent - they have a slightly restricted role in the
// sale and don't have "their own" deployment sale.  Many checks will take both this and
// `getIsConsigningAgentAndSaleUnlocked` into account.
export const getHasWriteAccessInCurrentSale = state => {
  // When any of the User Roles accessible Deployment Sales in the current Livestock Sale are locked, fall back to ready only mode
  if (getIsBusinessUser(state)) {
    return false;
  }

  if (getIsSaleyardAdmin(state)) {
    return true;
  }

  const isSaleLocked = selectRoleCurrentDeploymentSales(state).some(
    deploymentSale => deploymentSale.is_sale_locked,
  );

  return isSaleLocked
    ? false
    : !getIsLivestockAgent(state) ||
        selectRoleCurrentDeploymentIds(state).length > 0;
};

export const selectShowCheckPoints = createSelector(
  [getActiveLivestockAgentDeployment, selectCurrentSaleyard],
  (activeLivestockAgentDeployment, currentSaleyard) => {
    return (
      hasPermission(currentSaleyard, SaleyardPermissions.canCreateCheckpoint) ||
      hasPermission(
        activeLivestockAgentDeployment,
        DeploymentPermissions.canCreateCheckpoint,
      )
    );
  },
);

export const getIsRolePermissionsEnabled = state =>
  [ROLE_TYPES.STOCK_AGENT, ROLE_TYPES.SALEYARD_OPERATOR].includes(
    getActiveRole(state).type,
  );

export const getCurrentDeploymentsSomeHasPermission = permission => state =>
  someHasPermission(selectRoleCurrentDeployments(state), permission);

export const getIsSaleLocked = state =>
  selectRoleCurrentDeploymentSales(state).some(
    deploymentSale => deploymentSale.is_sale_locked,
  );
