import React, { memo } from "react";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@material-ui/core";
import Fuse from "fuse.js";
import sortedUniqBy from "lodash/sortedUniqBy";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import { BusinessAction, setSetting } from "actions";

import TopBuyerGrid from "components/AuctionPenSaleView/BuyerGrid";
import { SecondaryButton } from "components/Form";
import { Column, Row } from "components/Layout";
import { Dialog, DialogContent, DialogTitle } from "components/MaterialDialog";

import { Settings } from "constants/settings";

import {
  getSettings,
  selectActivePropertyEnrichedBusinessByBusinessIdLookup,
} from "selectors";

const SearchInputWrapper = styled(Row)`
  color: #666;
  font-size: 48px;
  line-height: 84px;
  border-bottom: 1px solid #979797;
  margin: 0 48px;
`;

const SearchInput = styled.input`
  border: none;
  width: 100%;
  color: #666;
  font-size: 60px;
  padding: 0 24px;
  &:focus {
    outline: none;
  }
`;

const WrapperAddBuyer = styled(Column)`
  align-items: stretch;
`;

const InfoGreyText = styled.span`
  color: #c2c2c2;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  padding: 60px 0 48px;
`;

const InfoText = styled.span`
  color: #666666;
  font-size: 18px;
  line-height: 24px;
  padding-top: 20px;
  margin: 0 30px 0 22px;
  text-align: center;
`;

const fuseOptions = {
  shouldSort: true,
  threshold: 0.5,
  keys: ["name", "shortCode", "publicDisplayName"],
  distance: 4,
};

const BuyerSearchModal = ({ closeSelf, fullScreen, setBuyerValues }) => {
  const [filterValue, setFilterValue] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [searchTimeout, setSearchTimeout] = React.useState(undefined);

  const buyers = Object.values(
    useSelector(selectActivePropertyEnrichedBusinessByBusinessIdLookup),
  );
  const dispatch = useDispatch();

  const buyersOnly =
    useSelector(
      state => getSettings(state)[Settings.buyersOnlyAuctionScreen],
    ) || false;

  const toggleBuyersOnly = () =>
    dispatch(setSetting(Settings.buyersOnlyAuctionScreen, !buyersOnly));

  const handleChange = event => {
    const { value } = event.target;
    setSearchText(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const newFilterValue = value.trim().toLowerCase();
    // Immediately update the filter if it was just cleared
    if (!filterValue) {
      setFilterValue(newFilterValue);
    } else {
      setSearchTimeout(
        setTimeout(() => {
          setFilterValue(newFilterValue);
        }, 400),
      );
    }
  };
  const onSetValues = values => {
    setBuyerValues(values);
    closeSelf();
  };

  const handleBuyerAndPropertySet = (buyerId, propertyId = null) => {
    onSetValues({ buyerId, buyerWayId: null, propertyId });
  };

  const handleAddBusiness = () => {
    const tempId = uuidv4();
    dispatch(
      BusinessAction.create({
        id: tempId,
        name: searchText.trim(),
      }),
    );
    handleBuyerAndPropertySet(tempId);
  };

  const uniqueBuyers = sortedUniqBy(buyers, "name").filter(
    buyer => !buyersOnly || buyer.isBuyer,
  );

  const fuse = new Fuse(uniqueBuyers, fuseOptions);
  const searchResults = fuse
    .search(searchText)
    .map(fuseResult => fuseResult.item);
  const clampedSearchResults = searchResults.slice(0, 25);
  const resultsHidden = searchResults.length - clampedSearchResults.length;

  // If the business name (searchText) is an exact match with one of the businesses,
  // we should just set it.
  const exactMatch = buyers.find(
    b => b.name?.toLowerCase() === searchText.toLowerCase(),
  );
  const setExactMatch = () =>
    handleBuyerAndPropertySet(exactMatch.id, exactMatch.defaultProperty?.id);

  return (
    <Dialog open onClose={closeSelf} fullScreen={fullScreen}>
      <DialogTitle onClose={closeSelf} />
      <DialogContent>
        <SearchInputWrapper alignCenter>
          <FontAwesomeIcon icon={faSearch} />
          <SearchInput
            type="search"
            data-tour="search"
            onChange={handleChange}
            value={searchText}
            autoFocus
          />
        </SearchInputWrapper>
        <Row justifyCenter>
          <label htmlFor="buyersOnly">
            Flagged Buyers Only
            <Switch
              checked={buyersOnly}
              onChange={toggleBuyersOnly}
              color="primary"
              id="buyersOnly"
            />
          </label>
        </Row>

        <WrapperAddBuyer justifyCenter alignCenter>
          <SecondaryButton
            type="button"
            disabled={filterValue.length === 0}
            onClick={exactMatch ? setExactMatch : handleAddBusiness}
            data-tour={
              exactMatch
                ? `useExistingBuyer:${searchText}`
                : "addAsTemporaryBuyer"
            }
          >
            {exactMatch
              ? `Use existing buyer "${searchText}"`
              : "Add as temporary buyer"}
          </SecondaryButton>
          <InfoText>
            You will be required to complete the buyer creation or matching with
            an existing client before confirming the auction.
          </InfoText>
        </WrapperAddBuyer>
        {filterValue.length > 0 && searchResults.length === 0 ? (
          <WrapperAddBuyer justifyCenter alignCenter>
            <InfoGreyText>
              Your search didn&apos;t match any existing buyer
            </InfoGreyText>
          </WrapperAddBuyer>
        ) : (
          <TopBuyerGrid
            buyers={clampedSearchResults}
            setBuyerValues={onSetValues}
          />
        )}
        {resultsHidden > 0 && (
          <InfoText>
            +{resultsHidden} more. Refine your search criteria to improve
            results.
          </InfoText>
        )}
      </DialogContent>
    </Dialog>
  );
};

BuyerSearchModal.propTypes = {
  closeSelf: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  setBuyerValues: PropTypes.func.isRequired,
};

export default memo(BuyerSearchModal);
