import {
  ADD_CONSIGNMENT_COMMIT,
  ADD_CONSIGNMENT_FROM_SOCKET,
  ADD_CONSIGNMENT_OFFLINE,
  ADD_CONSIGNMENT_ROLLBACK,
  BUSINESS,
  CLEAR_NVD_UPLOADS,
  DELETE_CONSIGNMENT_COMMIT,
  DELETE_CONSIGNMENT_FROM_SOCKET,
  DELETE_CONSIGNMENT_NO_LONGER_EXISTS,
  DELETE_CONSIGNMENT_OFFLINE,
  GET_ALL_NVD_UPLOADS,
  GET_CONSIGNMENTS,
  GET_CONSIGNMENTS_CHANGES_SUCCESS,
  GET_CONSIGNMENTS_FAILURE,
  GET_CONSIGNMENTS_SUCCESS,
  GET_NVD_UPLOAD,
  PATCH_CONSIGNMENT_COMMIT,
  PATCH_CONSIGNMENT_FROM_SOCKET,
  PATCH_CONSIGNMENT_OFFLINE,
  PATCH_CONSIGNMENT_ROLLBACK,
  SET_ADDITIONAL_PICS_SUCCESS,
  SUBMIT_NVD_UPLOAD_OFFLINE,
  UPDATE_DECLARATION,
} from "constants/actionTypes";
import { ConsignmentType } from "constants/consignments";
import { TRANSFER_STATUS } from "constants/nlis";
import { OfflineConsignmentPermissions } from "constants/permissions";
import { UNIX_START_TIME } from "constants/time";

import { deserializeNVDUpload } from "lib/nvdUpload";
import { deserializeConsignment } from "lib/serializers/consignments";

function getDirtyConsignmentSliceForType(consignmentType, state) {
  if (consignmentType === ConsignmentType.PRIMARY || !consignmentType) {
    state.consignments = { ...state.consignments };
    return state.consignments;
  } else if (consignmentType === ConsignmentType.VENDOR_SPLIT) {
    state.vendorSplitConsignments = { ...state.vendorSplitConsignments };
    return state.vendorSplitConsignments;
  }
}

function getDirtyConsignmentSliceForConsignmentId(
  consignmentId,
  state,
  consignmentType,
) {
  if (state.consignments[consignmentId]) {
    return getDirtyConsignmentSliceForType(ConsignmentType.PRIMARY, state);
  } else if (state.vendorSplitConsignments[consignmentId]) {
    return getDirtyConsignmentSliceForType(ConsignmentType.VENDOR_SPLIT, state);
  } else if (consignmentType === ConsignmentType.PRIMARY) {
    return getDirtyConsignmentSliceForType(ConsignmentType.PRIMARY, state);
  }
}

const initialState = {
  isFetching: null,
  isNLISFetching: null,
  consignments: {},
  vendorSplitConsignments: {},
  takeStatuses: {},
  nvdUploads: {
    isFetching: null,
    data: {},
  },
  lastModifiedTimestamp: UNIX_START_TIME,
};

const consignments = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONSIGNMENTS:
      return {
        ...initialState,
        isFetching: true,
      };

    case GET_CONSIGNMENTS_CHANGES_SUCCESS:
    case GET_CONSIGNMENTS_SUCCESS: {
      const { consignments } = action;

      if (
        action.type === GET_CONSIGNMENTS_CHANGES_SUCCESS &&
        consignments.length === 0
      ) {
        // If nothing has changed, don't force a state update.
        return state;
      }

      const newState = {
        ...state,
      };

      newState.isFetching = false;
      newState.lastModifiedTimestamp =
        action.lastModifiedTimestamp || state.lastModifiedTimestamp;
      newState.consignments = { ...state.consignments };
      newState.vendorSplitConsignments = { ...state.vendorSplitConsignments };
      newState.takeStatuses =
        action.type === GET_CONSIGNMENTS_CHANGES_SUCCESS
          ? state.takeStatuses
          : {};

      consignments.forEach(consignment => {
        const { deleted, id, consignment_type: consignmentType } = consignment;
        if (deleted === true) {
          // Erase all traces of the sale lot
          const consignmentSlice = getDirtyConsignmentSliceForConsignmentId(
            id,
            newState,
          );
          if (consignmentSlice) {
            delete consignmentSlice[id];
          }
          return;
        }

        const consignmentSlice = getDirtyConsignmentSliceForType(
          consignmentType,
          newState,
        );
        consignmentSlice[id] = deserializeConsignment(consignment);
      });

      return newState;
    }
    case GET_CONSIGNMENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case UPDATE_DECLARATION:
      const { declaration, consignmentId } = action;
      return {
        ...state,
        consignments: {
          ...state.consignments,
          [consignmentId]: {
            ...state.consignments[consignmentId],
            declaration,
          },
        },
      };

    case SET_ADDITIONAL_PICS_SUCCESS:
      const { pics } = action;
      return {
        ...state,
        consignments: {
          ...state.consignments,
          [action.consignmentId]: {
            ...state.consignments[action.consignmentId],
            additional_properties: pics,
          },
        },
      };

    // Note - no ADD_BUSINESS_ROLLBACK, as the business here
    // is a required field - creation of the whole consignment will
    // fail.
    case BUSINESS.CREATE.SUCCESS: {
      const { meta } = action;
      const businessId = action.payload.id;
      const { tempId } = meta;

      const updateStore = store =>
        Object.values(store)
          .map(c => {
            if (c.vendor_id === tempId) {
              return {
                ...c,
                vendor_id: businessId,
              };
            } else {
              return c;
            }
          })
          .reduce((map, consignment) => {
            map[consignment.id] = consignment;
            return map;
          }, {});

      // Look for any instances of the tempId and replace with the real one.
      const updatedConsignments = updateStore(state.consignments);
      const updatedVendorSplitConsignments = updateStore(
        state.vendorSplitConsignments,
      );

      return {
        ...state,
        consignments: updatedConsignments,
        vendorSplitConsignments: updatedVendorSplitConsignments,
      };
    }

    case ADD_CONSIGNMENT_OFFLINE: {
      const { tempId } = action.meta.offline.commit.meta;
      const { payload } = action;
      const { consignment_type: consignmentType } = payload;
      const newState = { ...state };
      const consignmentSlice = getDirtyConsignmentSliceForType(
        consignmentType,
        newState,
      );
      consignmentSlice[tempId] = {
        ...deserializeConsignment({
          ...payload,
          permissions: [...OfflineConsignmentPermissions],
        }),
        syncing: true,
      };
      return newState;
    }

    case ADD_CONSIGNMENT_COMMIT:
    case ADD_CONSIGNMENT_FROM_SOCKET: {
      const newState = {
        ...state,
      };
      const { meta, payload } = action;
      const { tempId } = meta;
      const { id, consignment_type: consignmentType } = payload;

      const newConsignment = {
        ...deserializeConsignment(payload),
        attachmentParams: undefined, // remove attachmentParams
        syncing: false,
      };

      const consignmentStore = getDirtyConsignmentSliceForType(
        consignmentType,
        newState,
      );
      // Delete the originally created temp sale lot - this may not delete anything as tempId can
      // be the new  sale lot id from the socket.
      delete consignmentStore[tempId];
      consignmentStore[id] = newConsignment;

      return newState;
    }

    case ADD_CONSIGNMENT_ROLLBACK: {
      if (action.meta.tempId === null) {
        return state;
      }
      const { tempId } = action.meta;

      const { [tempId.toString()]: ignored, ...withoutTemp } =
        state.consignments;

      return {
        ...state,
        consignments: {
          ...withoutTemp,
        },
      };
    }

    case PATCH_CONSIGNMENT_OFFLINE: {
      const { id } = action.meta;
      const newState = { ...state };
      const consignmentStore = getDirtyConsignmentSliceForConsignmentId(
        id,
        newState,
      );
      consignmentStore[id] = {
        ...consignmentStore[id],
        ...deserializeConsignment(action.payload),
        syncing: true,
      };

      return newState;
    }

    case PATCH_CONSIGNMENT_COMMIT:
    case PATCH_CONSIGNMENT_FROM_SOCKET: {
      const { id } = action.meta;

      const newState = { ...state };
      const consignmentStore = getDirtyConsignmentSliceForConsignmentId(
        id,
        newState,
        action.payload.consignment_type,
      );
      consignmentStore[id] = {
        ...deserializeConsignment({
          ...action.payload,
        }),
        syncing: false,
      };
      return newState;
    }

    case PATCH_CONSIGNMENT_ROLLBACK: {
      return state;
    }

    case DELETE_CONSIGNMENT_OFFLINE: {
      const { id } = action.meta.offline.commit.meta.consignment;
      const newState = { ...state };
      const consignmentStore = getDirtyConsignmentSliceForConsignmentId(
        id,
        newState,
      );
      delete consignmentStore[id];
      return newState;
    }

    case DELETE_CONSIGNMENT_COMMIT:
    case DELETE_CONSIGNMENT_NO_LONGER_EXISTS:
    case DELETE_CONSIGNMENT_FROM_SOCKET: {
      const { id } = action.meta.consignment;
      const newState = { ...state };
      const consignmentStore = getDirtyConsignmentSliceForConsignmentId(
        id,
        newState,
      );
      if (consignmentStore) {
        delete consignmentStore[id];
        return newState;
      } else {
        return state;
      }
    }

    case CLEAR_NVD_UPLOADS: {
      return {
        ...state,
        nvdUploads: initialState.nvdUploads,
      };
    }

    case GET_ALL_NVD_UPLOADS.REQUEST: {
      return {
        ...state,
        nvdUploads: {
          ...state.nvdUploads,
          isFetching: true,
        },
      };
    }
    case GET_ALL_NVD_UPLOADS.SUCCESS: {
      const { uploads } = action;
      const nvdUploads = uploads.reduce((acc, upload) => {
        upload.consignments.forEach(consignmentId => {
          if (!acc[consignmentId]) {
            acc[consignmentId] = [];
          }
          acc[consignmentId].push(deserializeNVDUpload(upload));
        });
        return acc;
      }, {});
      return {
        ...state,
        nvdUploads: {
          data: nvdUploads,
          isFetching: false,
        },
      };
    }
    case GET_NVD_UPLOAD.SUCCESS: {
      const { status: upload } = action;
      const nvdUploads = { ...state.nvdUploads.data };

      upload.consignments.forEach(consignmentId => {
        if (!nvdUploads[consignmentId]) {
          nvdUploads[consignmentId] = [];
        }
        const existingIndex = nvdUploads[consignmentId].findIndex(nvdUpload => {
          return nvdUpload.id === upload.id;
        });
        if (existingIndex >= 0) {
          nvdUploads[consignmentId][existingIndex] =
            deserializeNVDUpload(upload);
        } else {
          nvdUploads[consignmentId].push(deserializeNVDUpload(upload));
        }
      });

      return {
        ...state,
        nvdUploads: {
          data: nvdUploads,
          isFetching: false,
        },
      };
    }

    case SUBMIT_NVD_UPLOAD_OFFLINE.REQUEST: {
      const {
        payload: { consignments: consignmentIds },
      } = action;
      // Add a 'syncing' upload for each.
      const nvdUploads = { ...state.nvdUploads.data };
      consignmentIds.forEach(consignmentId => {
        if (!nvdUploads[consignmentId]) {
          nvdUploads[consignmentId] = [];
        }
        nvdUploads[consignmentId].push({ status: TRANSFER_STATUS.SYNCING });
      });
      return state;
    }

    case SUBMIT_NVD_UPLOAD_OFFLINE.SUCCESS: {
      const { payload: uploads } = action;
      const nvdUploads = uploads.reduce(
        (acc, upload) => {
          upload.consignments.forEach(consignmentId => {
            if (!acc[consignmentId]) {
              acc[consignmentId] = [];
            } else {
              // Remove any syncing entries.
              acc[consignmentId] = acc[consignmentId].filter(
                u => u.status !== TRANSFER_STATUS.SYNCING,
              );
            }
            acc[consignmentId].push(deserializeNVDUpload(upload));
          });
          return acc;
        },
        { ...state.nvdUploads.data },
      );
      return {
        ...state,
        nvdUploads: {
          data: nvdUploads,
        },
      };
    }

    case SUBMIT_NVD_UPLOAD_OFFLINE.FAILURE: {
      const {
        meta: { consignmentIds },
      } = action;
      // Remove all syncing entries for the given consignments
      const nvdUploads = { ...state.nvdUploads.data };
      consignmentIds.forEach(consignmentId => {
        if (nvdUploads[consignmentId]) {
          nvdUploads[consignmentId] = nvdUploads[consignmentId].filter(
            n => n.status !== TRANSFER_STATUS.SYNCING,
          );
          if (nvdUploads[consignmentId].length === 0) {
            delete nvdUploads[consignmentId];
          }
        }
      });
      return state;
    }

    default:
      return state;
  }
};

export default consignments;
