import React from "react";

import { ModalTypes } from "constants/navigation";

import Dialog from "./container";

function Adapter(props) {
  const { hash } = props;
  const consignmentId = hash[ModalTypes.KillSheet] || null;
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return (
    <Dialog
      consignmentId={consignmentId}
      returnTo={decodedReturnTo}
      {...props}
    />
  );
}

export default Adapter;
