import React, { useState } from "react";

import { useField } from "formik";
import { useSelector } from "react-redux";

import { Autocomplete } from "components/Form/FormikControls";

import { caseInsensitiveCompare } from "lib/compare";

import { getBusinessById, getBuyerWaysByBusinessId } from "selectors";

const useBuyerWayOptions = (masterBusinessId, field) => {
  const [tempBuyerWays, setTempBuyerWays] = useState([]);

  const [_ignored, _ignored2, helpers] = useField(field);

  const addTempBuyerWay = name => {
    const newBuyerWay = { id: -Date.now(), name };
    setTempBuyerWays([...tempBuyerWays, newBuyerWay]);
    helpers.setValue(newBuyerWay);
    return newBuyerWay;
  };
  const buyerWays = useSelector(getBuyerWaysByBusinessId(masterBusinessId));

  const options = buyerWays
    .concat(tempBuyerWays)
    .map(bw => ({
      value: bw,
      label: bw.name,
    }))
    .sort((a, b) => caseInsensitiveCompare(a.label, b.label));

  return [options, addTempBuyerWay];
};

const getOptionLabel = o => o.name || "";

export const BuyerAwareBuyerWayField = ({
  buyerField,
  label,
  name,
  onChangeExtra,
  readOnly = false,
}) => {
  // A alternative implementation using autocomplete.
  const [{ value: buyerId }] = useField(buyerField);
  const masterBusinessId = useSelector(getBusinessById(buyerId))?.id;

  const [options, addTempBuyerWay] = useBuyerWayOptions(masterBusinessId, name);

  // Use less magic in the options, use getOptionLabel and getOptionSelected for more explicitly matching.
  const simpleOptions = options.map(o => o.value);

  // Customised getOptionSelected function because the value is a object/not shallow equal.
  const [field] = useField(name);
  const { value } = field;
  // This needs to return a dict of { id: ..., name: .... } most scenarios, so keep value as the object.
  // But that object cannot be used in a flat comparison, so special case the is selected comparison
  const getOptionSelected = option => option?.id === value?.id;

  const getOptionValue = option => option;

  return (
    <Autocomplete
      name={name}
      selectOnFocus
      handleHomeEndKeys
      label={label}
      options={simpleOptions}
      onChangeExtra={onChangeExtra}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      handleNew={addTempBuyerWay}
      disabled={!masterBusinessId || readOnly}
      getOptionValue={getOptionValue}
    />
  );
};
