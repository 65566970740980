import React from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";
import { ColumnType } from "components/AgGrid/constants";

import { ACTION_COLUMN_NAME } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";

import { datetimeFormatter } from "lib/agGrid/columns/datetime";
import { openModalLink } from "lib/navigation";

export function EditManualAdjustmentRenderer(props) {
  const { manualAdjustment } = props.data;

  const onClickEdit = () => {
    const modalProps = {
      id: manualAdjustment.id,
    };
    openModalLink(ModalTypes.ManualAdjustmentForm, modalProps);
  };

  return (
    <ActionButtonContainer>
      <ActionButton
        data-tour="action-edit-manual-adjustment"
        onClick={onClickEdit}
      >
        <FontAwesomeIcon icon={faPencil} />
        &nbsp;Edit
      </ActionButton>
    </ActionButtonContainer>
  );
}

export const ManualAdjustmentColId = {
  CREATED: "acb207dd-54e5-4a8f-9e82-f2d4548372b7",
  GST_CENTS: "132ff995-19d9-433c-9708-d8a43df9fd62",
  SUBTOTAL_CENTS: "be516b65-1f41-4882-93ca-8d717946f9e1",
  TOTAL_CENTS: "9ad3a324-3a18-4576-b6e0-1356b9887ffa",
  LABELS: "c9d6eda4-0f32-41b1-8e77-10cb71c9f1ec",
  GST_METHOD: "8de9b652-20dd-4b28-9f0a-73c666f16d81",
  NOTE: "f447b90f-9f2a-4b33-b06b-fbb23d03500c",
  ACTIONS: "c72ee1b9-0e47-4d71-a40a-fa90405fa8f8",
  FROM_BUSINESS: "9cf2213e-7d8c-4af4-ac49-4ab1e74595fe",
  TO_BUSINESS: "3785146b-f119-4bb1-9cd9-77d0d4590112",
};

export const ManualAdjustmentColumnDef = {
  [ManualAdjustmentColId.CREATED]: {
    colId: ManualAdjustmentColId.CREATED,
    field: "manualAdjustment.created",
    headerName: "Created",
    valueFormatter: datetimeFormatter,
  },
  [ManualAdjustmentColId.SUBTOTAL_CENTS]: {
    colId: ManualAdjustmentColId.SUBTOTAL_CENTS,
    field: "manualAdjustment.subtotalCents",
    headerName: "Amount ($)",
    aggFunc: "sum",
    valueFormatterParams: {
      decimalPlaces: 2,
      formatToMinTwoDecimalPlaces: false,
    },
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
  [ManualAdjustmentColId.GST_CENTS]: {
    colId: ManualAdjustmentColId.GST_CENTS,
    field: "manualAdjustment.gstCents",
    headerName: "GST ($)",
    aggFunc: "sum",
    valueFormatterParams: {
      decimalPlaces: 2,
      formatToMinTwoDecimalPlaces: false,
    },
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
  [ManualAdjustmentColId.TOTAL_CENTS]: {
    colId: ManualAdjustmentColId.TOTAL_CENTS,
    field: "manualAdjustment.totalCents",
    headerName: "Total ($)",
    aggFunc: "sum",
    valueFormatterParams: {
      decimalPlaces: 2,
      formatToMinTwoDecimalPlaces: false,
    },
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
  [ManualAdjustmentColId.LABELS]: {
    colId: ManualAdjustmentColId.LABELS,
    field: "labels",
    headerName: "Labels",
  },
  [ManualAdjustmentColId.GST_METHOD]: {
    colId: ManualAdjustmentColId.GST_METHOD,
    field: "manualAdjustment.gstMethod",
    headerName: "GST Method",
  },
  [ManualAdjustmentColId.NOTE]: {
    colId: ManualAdjustmentColId.NOTE,
    field: "manualAdjustment.note",
    headerName: "Note",
  },
  [ManualAdjustmentColId.ACTIONS]: {
    colId: ManualAdjustmentColId.ACTIONS,
    field: "edit",
    headerName: ACTION_COLUMN_NAME,
    pinned: "right",
    cellRenderer: EditManualAdjustmentRenderer,
  },
};
