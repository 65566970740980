import React from "react";

import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";

import AgGridContainer from "components/AgGrid/AgGridContainer";
import { MultiButton } from "components/Button";
import { Column as LayoutColumn } from "components/Layout";

import { AgGridTables } from "constants/aggrid";
import { LedgerAccountColumnDef } from "constants/columnDefinitions/ledgerAccount";
import { ModalTypes } from "constants/navigation";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";

import { AutoCreateInAgrinousConfirmation } from "containers/Settings/Billing/Accounts/AutoCreateInAgrinousConfirmation";
import { AutoCreateInXeroConfirmation } from "containers/Settings/Billing/Accounts/AutoCreateInXeroConfirmation";

import { openModalLink } from "lib/navigation";

import { selectLedgerAccountsAgGridData } from "selectors/aggrid/ledgerAccounts";

import { useHasSaleyardOrDeploymentPermission, useToggle } from "hooks";

export const columnDefs = Object.values(LedgerAccountColumnDef);

const onClickCreateNew = () => openModalLink(ModalTypes.EditLedgerAccount, {});

export const MyAccountsTable = () => {
  const rowData = useSelector(selectLedgerAccountsAgGridData);

  const hasCreatePermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.canCreateLedgerAccount,
    SaleyardPermissions.canCreateLedgerAccount,
  );

  const [showConfirmCreateInAgrinous, toggleShowConfirmCreateInAgrinous] =
    useToggle(false);

  const [showConfirmCreateInXero, toggleShowConfirmCreateInXero] =
    useToggle(false);

  const headerButtons = [
    {
      title: "Create New Account",
      isDisabled: !hasCreatePermission,
      onClick: onClickCreateNew,
      default: true,
      icon: faPlus,
    },
    {
      title: "Auto Create (in AgriNous)",
      isDisabled: !hasCreatePermission,
      onClick: toggleShowConfirmCreateInAgrinous,
      icon: faPlus,
    },
    {
      title: "Auto Create (in Xero)",
      isDisabled: !hasCreatePermission,
      onClick: toggleShowConfirmCreateInXero,
      icon: faPlus,
    },
  ];

  const extraHeaderComponents = (
    <LayoutColumn padding={1}>
      <MultiButton buttons={headerButtons} />
    </LayoutColumn>
  );

  return (
    <>
      <AgGridContainer
        rowData={rowData}
        columnDefs={columnDefs}
        tableName={AgGridTables.LEDGER_ACCOUNTS}
        extraHeaderComponents={extraHeaderComponents}
      />
      <AutoCreateInAgrinousConfirmation
        toggleShowConfirm={toggleShowConfirmCreateInAgrinous}
        open={showConfirmCreateInAgrinous}
      />

      <AutoCreateInXeroConfirmation
        toggleShowConfirm={toggleShowConfirmCreateInXero}
        open={showConfirmCreateInXero}
      />
    </>
  );
};
