import React, { useMemo, useState } from "react";

import { Skeleton } from "@material-ui/lab";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components/macro";

import AuctionPenCard from "components/AuctionPens/Cards/AuctionPenCard";
import { TempPenCards } from "components/AuctionPens/Cards/TempPenCards";
import { LaneSetup } from "components/AuctionPens/LaneSetup";
import PenOrder from "components/AuctionPens/PenOrder";
import StatusCard from "components/Card/StatusCard";
import DeferredRender from "components/DeferredRender";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MessageBox from "components/MessageBox";
import RenderData from "components/RenderData";
import { GlobalSearchHeader } from "components/SearchInput/GlobalSearch";

import { PenTypes } from "constants/auctionPens";
import { ApiModel } from "constants/loading";
import { ConsignmentPermissions } from "constants/permissions";
import { Settings } from "constants/settings";

import { sortPenByOrder } from "lib/auctionPens";
import {
  getSaleyardAuctionRoute,
  openInlineCreateSaleLotModal,
} from "lib/navigation";

import {
  getAuctionPenById,
  getAuctionPens,
  getConsignments,
  getHasWriteAccessInCurrentSale,
  getPenIdsByRoundId,
  getRounds,
  getSaleLots,
  getSetting,
  getUnpennedSaleLotIdsBySaleRoundId,
  selectAuctionPenSearchIndex,
  selectFilteredAuctionPenIds,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotSummaryByAuctionPenIdLookup,
} from "selectors";

import { useSomeHasPermission } from "hooks/useHasPermission";

import PenActions from "./PenActions";
import { LaneStartLine, PenRangeIndicator } from "./PenBreaks";

const PEN_DETAIL_ROUTE = `${getSaleyardAuctionRoute(
  ":saleyard",
  ":saleId",
)}/auction-pen-cards`;
const REORDER_ROUTE = `${PEN_DETAIL_ROUTE}/reorder`;
const LANE_SETUP_ROUTE = `${PEN_DETAIL_ROUTE}/lane-setup`;

const BATCH_RENDER_COUNT = 40;

const PenWrapper = styled.div`
  padding-bottom: 12px;
`;

function PenPlaceholder() {
  return (
    <PenWrapper>
      <StatusCard>
        <Skeleton animation="wave" variant="text" />
        <Skeleton animation="wave" variant="rect" height={60} />
      </StatusCard>
    </PenWrapper>
  );
}

function PenComponent({
  auctionPenId,
  basePath,
  isOpen,
  setOpenCard,
  weighedOnly,
}) {
  const auctionPen = useSelector(getAuctionPenById(auctionPenId));
  return (
    <PenWrapper>
      {auctionPenId ? (
        <>
          {auctionPen.isLaneStart && <LaneStartLine />}
          <PenRangeIndicator auctionPenId={auctionPenId} />
        </>
      ) : null}
      <AuctionPenCard
        auctionPenId={auctionPenId}
        key={auctionPenId}
        basePath={basePath}
        isOpen={isOpen}
        onExpand={setOpenCard}
        weighedOnly={weighedOnly}
      />
    </PenWrapper>
  );
}

export const PenCard = React.memo(PenComponent);

function PenList() {
  const [openCard, setOpenCard] = useState(undefined);
  const [openTempCard, setOpenTempCard] = useState(undefined);
  const selectedRoundId = useSelector(getSetting(Settings.round));

  const auctionPenIds = useSelector(getPenIdsByRoundId(selectedRoundId));
  const auctionPenSearchIndex = useSelector(selectAuctionPenSearchIndex);

  const auctionPens = useSelector(getAuctionPens);

  const saleLotIdsByAuctionPenId = useSelector(
    selectSaleLotIdsByAuctionPenIdLookup,
  );

  const globalFilteredAuctionPenIds = useSelector(selectFilteredAuctionPenIds);

  const unpennedSaleLotIds = useSelector(
    getUnpennedSaleLotIdsBySaleRoundId(selectedRoundId),
  );

  const [fusePenIds, setFusePenIds] = useState(null);

  const [visibleAuctionPenIds, tempPensIds] = useMemo(() => {
    const tempPensIds = [];
    // Apply filters to the full set - we then get a list of ids that should be included.

    const unsortedAuctionPens = auctionPenIds
      .filter(
        // That have sale lots.
        auctionPenId =>
          saleLotIdsByAuctionPenId[auctionPenId]?.length > 0 &&
          // Global filter
          globalFilteredAuctionPenIds.includes(auctionPenId) &&
          // Satisfies search criteria
          (fusePenIds === null || fusePenIds.has(auctionPenId)),
      )
      // Map to the object for sorting.
      .map(auctionPenId => auctionPens[auctionPenId]);
    const unsortedAuctionPensWithRemovedTempPens = unsortedAuctionPens.filter(
      pen => {
        if (pen.penType === PenTypes.TEMP) {
          tempPensIds.push(pen.id);
          return false;
        } else {
          return true;
        }
      },
    );
    return [
      sortPenByOrder(unsortedAuctionPensWithRemovedTempPens).map(
        auctionPen => auctionPen.id,
      ),
      tempPensIds,
    ];
  }, [
    auctionPenIds,
    saleLotIdsByAuctionPenId,
    fusePenIds,
    auctionPens,
    globalFilteredAuctionPenIds,
  ]);

  const showUnpenned =
    fusePenIds !== null
      ? // there is a search criteria
        fusePenIds.has(null)
      : // there are unpenned lots
        unpennedSaleLotIds?.length > 0;

  const quantityByAuctionPenId = useSelector(
    selectSaleLotSummaryByAuctionPenIdLookup,
  );

  const headInTempPen = useMemo(() => {
    return tempPensIds.length > 0
      ? tempPensIds.reduce((acc, penId) => {
          return acc + quantityByAuctionPenId[penId]?.quantity || 0;
        }, 0)
      : null;
  }, [quantityByAuctionPenId, tempPensIds]);

  function onBasicSearchTextChanged(searchText) {
    setFusePenIds(
      searchText
        ? new Set(
            auctionPenSearchIndex
              .search(searchText)
              .map(result => result.item.id),
          )
        : null,
    );
  }

  return (
    <>
      <GlobalSearchHeader
        actionButton={
          <PenActions hasVisiblePens={visibleAuctionPenIds.length > 0} />
        }
        onBasicSearchTextChanged={onBasicSearchTextChanged}
        includeRoundSelector
        showBasicSearch
      />

      {visibleAuctionPenIds.length === 0 && unpennedSaleLotIds?.length === 0 ? (
        <MessageBox>No pens found for the selected round</MessageBox>
      ) : (
        <>
          <TempPenCards
            tempPensIds={tempPensIds}
            quantity={headInTempPen}
            setOpenCard={setOpenCard}
            openCard={openCard}
            setOpenTempCard={setOpenTempCard}
            openTempCard={openTempCard}
          />
          {showUnpenned && (
            <PenCard
              auctionPenId={null}
              setOpenCard={setOpenCard}
              isOpen={openCard === null}
            />
          )}
          {visibleAuctionPenIds.map((auctionPenId, idx) => (
            <DeferredRender
              deferRenderBy={
                idx < BATCH_RENDER_COUNT
                  ? null
                  : Math.round(idx / BATCH_RENDER_COUNT)
              }
              placeHolder={<PenPlaceholder />}
              key={auctionPenId}
            >
              <PenCard
                auctionPenId={auctionPenId}
                setOpenCard={setOpenCard}
                isOpen={auctionPenId === openCard}
              />
            </DeferredRender>
          ))}
        </>
      )}
    </>
  );
}

function AuctionPenCardListComponent() {
  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );
  const selectedRoundId = useSelector(getSetting(Settings.round));
  const hasSaleLots = useSelector(
    state => Object.keys(getSaleLots(state)).length > 0,
  );

  const openCreateSaleLotModal = () => {
    openInlineCreateSaleLotModal(
      { sale_round_id: selectedRoundId },
      {
        showDeliveryPen: false,
        showAuctionPenAtTop: true,
        includeCreateUnknownConsignment: true,
      },
    );
  };

  const hasAddSaleLotPermission = useSomeHasPermission(
    state => Object.values(getConsignments(state)),
    ConsignmentPermissions.canAddSaleLot,
  );

  const actionText = hasAddSaleLotPermission ? "Add New" : null;

  return (
    <RenderData
      test={hasSaleLots}
      infoText="No sale lots yet"
      onClick={hasWriteAccessInCurrentSale && openCreateSaleLotModal}
      actionText={actionText}
      dataTour="addAuctionPen"
      desktopRedirect="salelots"
    >
      <Switch>
        {hasWriteAccessInCurrentSale ? (
          <Route exact path={LANE_SETUP_ROUTE} component={LaneSetup} />
        ) : null}
        {hasWriteAccessInCurrentSale ? (
          <Route exact path={REORDER_ROUTE} component={PenOrder} />
        ) : null}
        <Route path={PEN_DETAIL_ROUTE} component={PenList} />
      </Switch>
    </RenderData>
  );
}

function AuctionPenCardList() {
  const rounds = useSelector(getRounds);
  if (isEmpty(rounds)) {
    // If we have no rounds, dont continue, it will crash.
    return;
  }

  return (
    <WaitForSync
      requiredData={[
        ApiModel.AGENCIES,
        ApiModel.CONSIGNMENTS,
        ApiModel.DEPLOYMENTS,
        ApiModel.BUSINESSES,
        ApiModel.AUCTION_PENS,
        ApiModel.SALE_LOTS,
        ApiModel.ROUNDS,
      ]}
    >
      <div id="pen-wrapper">
        <AuctionPenCardListComponent />
      </div>
    </WaitForSync>
  );
}

export default AuctionPenCardList;
