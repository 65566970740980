/* eslint-disable import/order */
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";

import {
  appUpdateLogoutRequired,
  appUpdateRefreshOptional,
  appUpdateRefreshRequired,
  requestLogout,
  screenResize,
} from "actions";
import { muiTheme } from "constants/muiTheme";
import { appUpdateStatus } from "constants/system";
import theme from "constants/theme";

import { GlobalStyles } from "globalStyles";
import "lib/logRocket/initialize";
import "lib/sentry/initialize";
import React from "react";

import { createRoot } from "react-dom/client";
import { Router } from "react-router-dom";
import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-table/react-table.css";
import "react-toastify/dist/ReactToastify.css";

import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import "typeface-roboto";
import history from "./appHistory";
import "./components/PageShell/animations.css";
import Root from "./containers/Root";
import { GOOGLE_MAPS_API_KEY } from "./lib/googleMaps";
import {
  setGoogleMapsApiKey,
  setGoogleMapsApiLibraries,
} from "./lib/googleMaps/serviceManager";
import toast, {
  UPDATE_TOAST_MESSAGE,
  UPDATE_TOAST_MESSAGE_LOGOUT,
} from "./lib/toast";
import registerServiceWorker from "./registerServiceWorker";
import sagas from "./sagas";
import configureStore from "./store/configureStore";

export const store = configureStore();
sagas.forEach(saga => store.runSaga(saga));

setGoogleMapsApiKey(GOOGLE_MAPS_API_KEY);
setGoogleMapsApiLibraries(["places"]);

window.addEventListener("resize", () => {
  const state = store.getState();
  if (state && state.screen.width !== window.innerWidth) {
    store.dispatch(screenResize(window.innerWidth));
  }
});

const container = document.getElementById("root");
const root = createRoot(container);

const handleUpdateFound = status => {
  switch (status) {
    case appUpdateStatus.LOGOUT_REQUIRED:
      store.dispatch(requestLogout());
      store.dispatch(appUpdateLogoutRequired());
      toast.refreshPrompt(UPDATE_TOAST_MESSAGE_LOGOUT);
      break;

    case appUpdateStatus.REFRESH_REQUIRED:
      store.dispatch(appUpdateRefreshRequired());
      toast.refreshPrompt(UPDATE_TOAST_MESSAGE);
      break;

    case appUpdateStatus.REFRESH_OPTIONAL:
      store.dispatch(appUpdateRefreshOptional());
      break;

    case appUpdateStatus.INVISIBLE:
      // Do nothing
      /* eslint-disable-next-line no-console */
      console.log("App updates are ready to install");
      break;

    default:
  }
};

export function persistCallback() {
  root.render(
    <StyledComponentsThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={muiTheme}>
          <GlobalStyles />
          <Router history={history}>
            <Root store={store} />
          </Router>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </StyledComponentsThemeProvider>,
  );
}

registerServiceWorker(handleUpdateFound);
