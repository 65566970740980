import React, { Component } from "react";

import { faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { faExpandAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import sum from "lodash/sum";
import sumBy from "lodash/sumBy";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import Dialog from "components/Dialog";
import { Column } from "components/Layout";
import Swipeable from "components/Swipeable";

import { AuctionScreenMode } from "constants/auctionModes";
import { PricingTypes } from "constants/pricingTypes";

import {
  calculateSplitPricingData,
  calculateTotalPriceCents,
  firstIfAllEqual,
  getUnitPriceString,
  removeNulls,
} from "lib";

import { getTotalSaleLotQuantity, isSoldOrDelivered } from "lib/auctionPens";
import { getSplitComment, isNoSale, isSold } from "lib/saleLot";

import ActionButtons from "./ActionButtons";
import AddBuyerWayModal from "./AddBuyerWayModal";
import AuctionPenNavigation from "./AuctionPenNavigation";
import AuctionPenSummary from "./AuctionPenSummary";
import BuyerPicker from "./BuyerPicker";
import BuyerSearchModal from "./BuyerSearchModal";
import { BuyerTypes } from "./BuyerTypeTabs";
import { Card } from "./Layout";
import { KeypadInput, PricingSelect } from "./PricingInput";
import { SaleLotsView } from "./SaleLotsView";

const Wrapper = styled.div`
  max-width: 800px;
  min-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 76px;
  @media only screen and (max-width: 500px) {
    zoom: ${window.innerWidth / 500};
    transform: scale;
    -webkit-text-size-adjust: auto;
  }
`;

const Content = styled.div`
  flex: 1;
`;

const ViewSplitsCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  color: #777;
  font-weight: bold;
  font-size: 24px;
  background: ${({ theme }) => theme.colors.white};
  transition: ${({ theme }) => theme.transitions[0]};
  cursor: pointer;
  &:active {
    opacity: 0.15;
  }
`;

const MAX_PRICE_DIGITS = 10;
const MAX_PRICE_DP = 2;

const initialState = {
  bidderNumberSearch: "",
  buyerId: null,
  propertyId: null,
  buyerWayId: null,
  isBuyerModal: false,
  isPriceDirty: false,
  kilograms: "",
  price: "",
  selectedSaleLotIds: [],
  selectedSaleLots: [],
  showSaleLots: false,
  selectedInput: KeypadInput.PRICE,
  selectedSaleLotQuantity: 0,
  vendorId: null,
  isBuyerSearchVisible: false,
  isAddBuyerWayVisible: false,
  isUnsellDialog: false,
  selectedPricingType: 1,
  selectedBuyerType: BuyerTypes.REGISTERED,
  editMode: false,
};

class AuctionPenSaleView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  componentDidMount() {
    this.selectDefaultLotsAndUpdate();
  }

  componentDidUpdate() {
    const { offlineLookup } = this.props;
    const { buyerId } = this.state;

    if (offlineLookup[buyerId]) {
      this.setState({
        buyerId: offlineLookup[buyerId],
      });
    }
  }

  getSaleLot = saleLotId => {
    const { saleLots } = this.props;
    return saleLots.find(saleLot => saleLot.id === saleLotId);
  };

  updateSaleLotQuantity = () =>
    this.setState({
      selectedSaleLotQuantity: this.getSelectedSaleLotQuantity(),
    });

  setSelectedSaleLots = saleLotIds => {
    const { selectedSaleLotIds } = this.state;

    if (!isEqual(selectedSaleLotIds, saleLotIds)) {
      this.setState(
        { selectedSaleLotIds: saleLotIds },
        this.updateSaleLotQuantity,
      );
      this.updateBasedOnSelectedSaleLots(saleLotIds);
    }
  };

  enableEditMode = () => this.setState({ editMode: true });

  getBuyer = () => {
    const { businesses } = this.props;
    const { buyerId } = this.state;
    if (businesses && buyerId) {
      return businesses[buyerId];
    }
  };

  getTotalSaleLotCounts = () => {
    const { auctionPen, roundId } = this.props;
    const filteredSaleLots = auctionPen?.sale_lots?.filter(
      lot => lot?.sale_round.id === roundId,
    );
    return {
      quantity: sumBy(filteredSaleLots, "quantity") || 0,
      quantityProgeny: sumBy(filteredSaleLots, "quantityProgeny") || 0,
    };
  };

  getSelectedSaleLotQuantity = () => {
    const { selectedSaleLotIds } = this.state;
    return selectedSaleLotIds.reduce((total, saleLotId) => {
      const saleLot = this.getSaleLot(saleLotId);
      return saleLot ? total + parseInt(saleLot.quantity, 10) : total;
    }, 0);
  };

  showUnsellDialog = () => {
    this.setState({ isUnsellDialog: true });
  };

  getSaleResultFromSaleLots = saleLots => {
    const { sale } = this.props;
    if (saleLots && saleLots.length) {
      const buyerIds = [];
      const buyerWayIds = [];
      const prices = [];
      const pricingTypes = [];
      const propertyIds = [];
      const vendorIds = [];
      const total_mass_grams = [];

      saleLots.forEach(saleLot => {
        pricingTypes.push(saleLot.pricing_type_id);
        buyerIds.push(saleLot.buyer_id);
        buyerWayIds.push(
          saleLot.buyer_way ? parseInt(saleLot.buyer_way.id, 10) : null,
        );
        prices.push(
          getUnitPriceString({
            ...saleLot,
          }),
        );
        propertyIds.push(saleLot.destination_property_id);
        total_mass_grams.push(saleLot.total_mass_grams);
        vendorIds.push(saleLot.vendor_id);
      });

      const pricingType = firstIfAllEqual(pricingTypes, sale.pricing_type_id);

      // If the pricing type is gross, we need to sum the prices if there are multiple.
      const price =
        pricingType === PricingTypes.GROSS
          ? sum(prices.map(p => parseFloat(p))).toString()
          : firstIfAllEqual(prices, "");

      return {
        buyerId: firstIfAllEqual(buyerIds, null),
        buyerWayId: firstIfAllEqual(buyerWayIds, null),
        propertyId: firstIfAllEqual(propertyIds, null),
        // Grab out just the first vendor, this is only used for the BuyerPicker component for display purposes
        // it's used to determine the visibility of "No Sale" - the actual no sale logic is located in `this.handleSell`.
        vendorId: vendorIds[0],
        price,
        pricingType,
        kilograms: (sum(total_mass_grams) / 1000).toString() || "",
      };
    }

    return {
      buyerId: initialState.buyerId,
      propertyId: initialState.propertyId,
      buyerWayId: initialState.buyerWayId,
      vendorId: initialState.vendorId,
      price: "",
    };
  };

  getUnsoldSaleLots = () => {
    const { saleLots } = this.props;
    return saleLots.filter(saleLot => !(saleLot.buyer && saleLot.buyer.id));
  };

  getUnsoldSaleLotIds = () => {
    return this.getUnsoldSaleLots().map(saleLot => saleLot.id);
  };

  getWeighModeDefaultSaleLotId = () => {
    const unsoldLots = this.getUnsoldSaleLots();
    if (unsoldLots.length === 0) {
      return [];
    }
    return [sortBy(unsoldLots, "timeWeighed")[0].id];
  };

  getLastSeenModeDefaultSaleLotId = () => {
    const unsoldLots = this.getUnsoldSaleLots();
    if (unsoldLots.length === 0) {
      return [];
    }
    return [sortBy(unsoldLots, "lastSeenAtTime")[0].id];
  };

  selectDefaultLotsAndUpdate = () => {
    const { saleLots, mode } = this.props;

    const selectedSaleLotIds = [];
    // If there is only one salelot, select it regardless of its status.
    if (saleLots.length === 1) {
      selectedSaleLotIds.push(saleLots[0].id);
    } else if (mode === AuctionScreenMode.Weigh) {
      // In Weigh mode, select only the first weighed, unsold lot.
      selectedSaleLotIds.push(...this.getWeighModeDefaultSaleLotId());
    } else if (mode === AuctionScreenMode.LastSeen) {
      // In Last Seen mode, select only the first seen, unsold lot.
      selectedSaleLotIds.push(...this.getLastSeenModeDefaultSaleLotId());
    } else {
      // In Selling Order mode (and default), select everything not sold.
      selectedSaleLotIds.push(...this.getUnsoldSaleLotIds());
    }
    this.updateBasedOnSelectedSaleLots(selectedSaleLotIds);
  };

  setPriceDirty = () => this.setState({ isPriceDirty: true });

  setPriceClean = () => this.setState({ isPriceDirty: false });

  updateBasedOnSelectedSaleLots = selectedSaleLotIds => {
    const { saleLots } = this.props;
    const {
      editMode: stateEditMode,
      isPriceDirty,
      kilograms: stateKilograms,
      price: statePrice,
      selectedPricingType: stateSelectedPricingType,
      selectedSaleLotIds: stateSelectedSaleLotIds,
    } = this.state;

    const selectedSaleLots = saleLots.filter(s =>
      selectedSaleLotIds.includes(s.id),
    );

    const sameSaleLots = isEqual(selectedSaleLotIds, stateSelectedSaleLotIds);

    const {
      buyerId,
      buyerWayId,
      price,
      pricingType,
      propertyId,
      vendorId,
      kilograms,
    } = this.getSaleResultFromSaleLots(selectedSaleLots);

    const updateSaleLotQuantity = () => {
      this.setState({
        selectedSaleLotQuantity: this.getSelectedSaleLotQuantity(),
      });
    };

    const updatedState = {
      buyerId,
      buyerWayId,
      propertyId,
      selectedSaleLotIds,
      selectedSaleLots,
      selectedInput: KeypadInput.PRICE,
      vendorId,
      // If there is already some state set, dont override it if the same sale lots are selected.
      kilograms: (sameSaleLots && stateKilograms) || kilograms,
      selectedPricingType:
        (sameSaleLots && stateSelectedPricingType) || pricingType,
      editMode: (sameSaleLots && stateEditMode) || false,
    };
    if (!isPriceDirty) {
      // retain the existing price when the lots are unselected (by navigating to the Sale Lots view)
      updatedState.price = (sameSaleLots && statePrice) || price;
    }

    this.setState(updatedState, updateSaleLotQuantity);
  };

  patchOrSplit = (saleLotId, patch) => {
    const { selectedSaleLotQuantity, selectedPricingType } = this.state;
    const {
      saleLots,
      patchSaleLot,
      addSaleLot,
      createSaleLotComment,
      singleWeighWeightBySaleLotIdLookup,
      auctionPen,
    } = this.props;
    const saleLot = saleLots.find(s => s.id === saleLotId);
    const isSplitPen = saleLot.quantity > selectedSaleLotQuantity;

    if (isSplitPen) {
      // If its a split and change, we should patch the original/split from lot
      // to just update the headcount and recalculated total_price_cents & total_mass_grams.
      const splitCount = selectedSaleLotQuantity;
      // If any animals are single weighed, DO NOT move any weights over - they remain with the animals.
      const shouldMoveWeights =
        singleWeighWeightBySaleLotIdLookup[saleLotId] === 0;
      const pricingData = calculateSplitPricingData(
        saleLot,
        splitCount,
        shouldMoveWeights,
      );
      const newSaleLotId = uuidv4();

      // The calculateSplitPricingData function will put the higher headcount of the
      // two split lots on the original lot.  At the end of the day, we want to ensure
      // that the PATCH method is applied to the part of the split with the HIGHER
      // headcount and the POST method is applied to the part with the lower headcount.
      // This is to reduce the need for rescanning as the scans will remain on the original lot.
      const patchOriginal = pricingData.originalLot.quantity === splitCount;
      if (patchOriginal) {
        // If the modified (buyer/price) part of the split has a HIGHER headcount,
        // patch it and add the remainder to a new lot.
        patch = {
          ...patch,
          ...pricingData.originalLot,
          id: saleLotId,
          total_price_cents: calculateTotalPriceCents({
            unitPrice: patch.price ? parseFloat(patch.price) : 0,
            quantity: splitCount,
            pricing_type_id: selectedPricingType,
            total_mass_grams: patch.total_mass_grams,
          }),
        };
        patchSaleLot(patch, { changeReason: "Split from auction screen" });

        const newLot = removeNulls({
          ...saleLot,
          ...pricingData.splitLot,
        });
        addSaleLot(newLot, newSaleLotId);
        // If there are weights involved, create a comment to make sure there is acknowledgement a rescan/weigh, etc
        // may be required.
        createSaleLotComment(newSaleLotId, {
          comment: getSplitComment(saleLot, splitCount, auctionPen),
          resolvedDate: new Date(),
        });
      } else {
        // If the modified (buyer/price) part of the split has a LOWER headcount,
        // add it to a new lot and patch the remainder to the existing lot.
        patch = removeNulls({
          ...saleLot,
          ...patch,
          ...pricingData.splitLot,
          total_price_cents: calculateTotalPriceCents({
            unitPrice: patch.price ? parseFloat(patch.price) : 0,
            quantity: splitCount,
            pricing_type_id: selectedPricingType,
            total_mass_grams: patch.total_mass_grams,
          }),
        });
        addSaleLot(patch, newSaleLotId);

        patchSaleLot(
          { id: saleLotId, ...pricingData.originalLot },
          { changeReason: "Split from auction screen" },
        );

        createSaleLotComment(newSaleLotId, {
          comment: getSplitComment(saleLot, splitCount, auctionPen),
          resolvedDate: new Date(),
        });
      }
    } else {
      patch.total_price_cents = calculateTotalPriceCents({
        unitPrice: patch.price ? parseFloat(patch.price) : 0,
        quantity: saleLot.quantity,
        pricing_type_id: selectedPricingType,
        total_mass_grams: patch.total_mass_grams,
      });
      patchSaleLot(
        { id: saleLotId, ...patch },
        { changeReason: "Sold from auction screen" },
      );
    }
  };

  handleSell = () => {
    const {
      buyerId,
      price,
      kilograms,
      buyerWayId,
      propertyId,
      selectedSaleLotIds,
      selectedPricingType,
      selectedSaleLots,
      vendorId,
    } = this.state;
    const { auctionPen, mode } = this.props;

    const auction_pen = auctionPen.id;
    const isUnsell = !buyerId && !price;

    if (isNoSale(vendorId, buyerId)) {
      selectedSaleLotIds.forEach(saleLotId => {
        const saleLot = this.getSaleLot(saleLotId);
        const patch = {
          saleLotId,
          auction_pen,
          // Send them back to where they came from...
          buyer_id: saleLot.vendor_id,
          destination_property_id: saleLot.consignment.vendor_property_id,
          price,
          pricing_type_id: selectedPricingType,
          quantity_delivered: 0,
          total_mass_grams: saleLot.total_mass_grams,
          total_price_cents: 0,
        };
        this.patchOrSplit(saleLotId, patch);
      });
    } else if ((buyerId && price) || isUnsell) {
      const existingTotalGrams = sumBy(selectedSaleLots, "total_mass_grams");
      const totalHeadCount = sumBy(selectedSaleLots, "quantity");
      const totalGrams = +kilograms * 1000;
      const gramsPerHead = totalGrams / totalHeadCount;
      const pricePerHead = price / totalHeadCount;

      const hasWeightChanged = existingTotalGrams !== totalGrams;
      const shouldUpdateWeight =
        hasWeightChanged &&
        selectedPricingType === PricingTypes.PER_KILO &&
        !isUnsell;

      selectedSaleLots.forEach(saleLot => {
        // this patch object _looks_ a lot like a Sale Lot, but it isn't one, be cautious
        const patch = {
          saleLotId: saleLot.id,
          auction_pen,
          price,
          buyer_id: buyerId,
          pricing_type_id: selectedPricingType,
          destination_property_id: propertyId,
          quantity_delivered: 0,
          // the call to `this.patchOrSplit` below requires `total_mass_grams` to be present, or it won't record a price.
          total_mass_grams: saleLot.total_mass_grams,
        };

        // If there are multiple salelots being sold, and the pricing type is per head or gross,
        // we need to calculate averages.
        if (selectedSaleLots.length > 1) {
          if (selectedPricingType === PricingTypes.GROSS) {
            patch.price = pricePerHead * saleLot.quantity;
          }
        }
        if (shouldUpdateWeight) {
          patch.total_mass_grams = Math.round(gramsPerHead * saleLot.quantity);
        }

        try {
          const buyerWay = this.getBuyer().buyerWays.filter(
            way => way.id === buyerWayId,
          )[0];
          patch.buyer_way = { name: buyerWay.name, id: buyerWay.id };
        } catch (e) {
          /* clear the buyer way */
          patch.buyer_way = null;
        }

        this.patchOrSplit(saleLot.id, patch);
      });
    }
    // In weigh, or scan order mode, select the next lot ready to go.
    if ([AuctionScreenMode.Weigh, AuctionScreenMode.LastSeen].includes(mode)) {
      this.selectDefaultLotsAndUpdate();
    } else {
      // In selling order mode, return back to the list view after selling.
      this.toggleView();
    }
    this.setState({ editMode: false });
    this.setPriceClean();
  };

  displayUnsellDialog = isUnsellDialog =>
    isUnsellDialog && (
      <Dialog
        closeSelf={() => this.setState({ isUnsellDialog: false })}
        handleSubmit={this.handleUnSell}
        title="Unsell lot?"
        subtitle="Are you sure you want to undo this sale?"
        submitButtonText="Unsell"
        rejectButtonText="Cancel"
        icon={faUndoAlt}
      />
    );

  setNoSale = () => {
    const { vendorId } = this.state;
    this.setBuyerValues({
      buyerId: vendorId,
      buyerWayId: null,
      propertyId: null,
    });
    this.setState({
      price: "0",
      selectedSaleLotQuantity: this.getSelectedSaleLotQuantity(),
    });
  };

  hasMultipleSaleLots = () => {
    const { selectedSaleLotIds } = this.state;
    return selectedSaleLotIds.length > 1;
  };

  setSelectedInput = selectedInput => this.setState({ selectedInput });

  setBuyerValues = values => {
    const { buyerId, buyerWayId, propertyId } = this.state;
    const updatedState = ["buyerId", "propertyId", "buyerWayId"].reduce(
      (acc, key) => {
        if (values[key] !== undefined) {
          acc[key] = values[key];
        }
        return acc;
      },
      {
        buyerId,
        buyerWayId,
        propertyId,
      },
    );
    this.setState({
      ...updatedState,
    });
  };

  openBuyerSearch = () => this.setState({ isBuyerSearchVisible: true });

  closeBuyerSearch = () => this.setState({ isBuyerSearchVisible: false });

  openAddBuyerWay = () => this.setState({ isAddBuyerWayVisible: true });

  closeAddBuyerWay = () => this.setState({ isAddBuyerWayVisible: false });

  // Toggle between sale lot and selling screen view.
  toggleView = () => {
    const { showSaleLots } = this.state;

    const nextView = !showSaleLots;

    if (!nextView) {
      this.setState({
        showSaleLots: nextView,
      });
    } else {
      this.setState(
        {
          showSaleLots: nextView,
          selectedSaleLotIds: [],
          selectedSaleLots: [],
        },
        this.updateBasedOnSelectedSaleLots([]),
      );
    }
  };

  handleNumPress = key => {
    const {
      bidderNumberSearch,
      price,
      selectedSaleLotQuantity,
      selectedInput,
      kilograms,
    } = this.state;
    const totalSaleLotQuantity = this.getSelectedSaleLotQuantity();
    if (selectedInput === KeypadInput.HEADCOUNT) {
      if (key !== ".") {
        let newSaleLotQuantity =
          selectedSaleLotQuantity === 0 ? key : selectedSaleLotQuantity + key;
        newSaleLotQuantity > totalSaleLotQuantity &&
          (newSaleLotQuantity = totalSaleLotQuantity.toString());
        this.setState({
          selectedSaleLotQuantity: parseInt(newSaleLotQuantity, 10),
        });
      }
    } else if (
      selectedInput === KeypadInput.PRICE &&
      !(price.includes(".") && price.split(".")[1].length >= MAX_PRICE_DP) &&
      !(price.length > MAX_PRICE_DIGITS) &&
      !(price.includes(".") && key === ".")
    ) {
      const newPrice = price === "0" ? key : price + key;
      this.setState({ price: newPrice });
      this.setPriceDirty();
    } else if (selectedInput === KeypadInput.MASS) {
      const newValue = kilograms === "0" ? key : kilograms + key;
      this.setState({ kilograms: newValue });
    } else if (selectedInput === KeypadInput.BIDDER_SEARCH) {
      if (key !== ".") {
        this.setState({ bidderNumberSearch: bidderNumberSearch + key });
      }
    }
  };

  handleDeletePress = () => {
    const {
      bidderNumberSearch,
      price,
      kilograms,
      selectedSaleLotQuantity,
      selectedInput,
    } = this.state;
    if (
      selectedInput === KeypadInput.HEADCOUNT &&
      selectedSaleLotQuantity > 0
    ) {
      this.setState({
        selectedSaleLotQuantity: 0,
      });
    } else if (selectedInput === KeypadInput.PRICE && price.length > 0) {
      this.setState({ price: price.slice(0, -1) });
    } else if (selectedInput === KeypadInput.MASS && kilograms.length > 0) {
      this.setState({ kilograms: kilograms.slice(0, -1) });
    } else if (selectedInput === KeypadInput.BIDDER_SEARCH) {
      this.setState({ bidderNumberSearch: bidderNumberSearch.slice(0, -1) });
    }
  };

  handlePriceTypeClick = value => {
    this.setState({
      selectedPricingType: value,
    });
  };

  handleReset = () => {
    this.setState({
      kilograms: initialState.kilograms,
      price: initialState.price,
      buyerId: initialState.buyerId,
      propertyId: initialState.propertyId,
      editMode: true,
      bidderNumberSearch: initialState.bidderNumberSearch,
      buyerWayId: initialState.buyerWayId,
    });
  };

  handleUnSell = () => {
    // Reset the state and update on server
    this.setState(
      {
        price: initialState.price,
        buyerId: initialState.buyerId,
        propertyId: initialState.propertyId,
        vendorId: initialState.vendorId,
        bidderNumberSearch: initialState.bidderNumberSearch,
        buyerWayId: initialState.buyerWayId,
        kilograms: initialState.kilograms,
      },
      () => {
        this.handleSell();
      },
    );
  };

  setBuyerWayId = buyerWayId => this.setBuyerValues({ buyerWayId });

  render() {
    const {
      agencyIdByConsignmentIdLookup,
      handleBack,
      auctionPen,
      hasNext,
      hasPrev,
      incrementAuctionPen,
      saleLots,
      patchSaleLot,
      addSaleLot,
    } = this.props;
    const {
      editMode,
      price,
      kilograms,
      showSaleLots,
      bidderNumberSearch,
      buyerWayId,
      buyerId,
      isAddBuyerWayVisible,
      isBuyerSearchVisible,
      isUnsellDialog,
      selectedInput,
      selectedPricingType,
      selectedSaleLotQuantity,
      selectedSaleLotIds,
      selectedSaleLots,
      vendorId,
      propertyId,
    } = this.state;

    const counts = this.getTotalSaleLotCounts();
    const parsePrice = parseInt(price, 10) || 0;
    const saleHasMultipleSaleLots = this.hasMultipleSaleLots();

    const multipleBuyers =
      [...new Set(selectedSaleLots.map(saleLot => saleLot.buyer_id))].length !==
      1;

    const allSold = selectedSaleLots.every(isSoldOrDelivered);
    const someSold = selectedSaleLots.some(isSoldOrDelivered);
    const enableSell = isNoSale(vendorId, buyerId) || isSold(buyerId, vendorId);
    const selectedSaleLotsQuantity = getTotalSaleLotQuantity(selectedSaleLots);

    const enableUnSell = !saleHasMultipleSaleLots && allSold;

    const enableSplit =
      selectedSaleLotIds.length === 1 &&
      !allSold &&
      selectedSaleLotsQuantity > 1;

    const enableSelectSaleLot = saleLots.length > 1;

    // Control whether the buttons for selling/pricing setting etc are enabled/disabled.
    const disabled =
      !editMode && (someSold || isNoSale(vendorId, buyerId) || allSold);

    // Get the first Agency out from the selected Sale Lots for creating temporary Buyers
    // This logic does not handle when a mixed Agency Pen is being sold - Known limitation
    const firstSelectedConsignmentId =
      selectedSaleLots.length > 0 && selectedSaleLots[0].consignment_id;
    const agencyId = agencyIdByConsignmentIdLookup[firstSelectedConsignmentId];

    const swipeRight = () => {
      hasPrev && incrementAuctionPen(false);
    };
    const swipeLeft = () => {
      hasNext && incrementAuctionPen(true);
    };

    return (
      <>
        <Wrapper>
          <Content>
            <Swipeable onSwipingRight={swipeRight} onSwipingLeft={swipeLeft}>
              <AuctionPenNavigation
                incrementAuctionPen={incrementAuctionPen}
                handleBack={handleBack}
                hasNext={hasNext}
                hasPrev={hasPrev}
              />
              <AuctionPenSummary
                totalSaleLotQuantity={counts.quantity}
                auctionPen={auctionPen}
                selectedSaleLotIds={selectedSaleLotIds}
                toggleView={this.toggleView}
                showSaleLots={showSaleLots}
                saleLots={saleLots}
                enableSplit={enableSplit}
                enableSelectSaleLot={enableSelectSaleLot}
              />
            </Swipeable>
            {showSaleLots || selectedSaleLotIds.length === 0 ? (
              <SaleLotsView
                toggleView={this.toggleView}
                totalSaleLotQuantity={counts.quantity}
                auctionPen={auctionPen}
                saleLots={saleLots}
                selectedSaleLotIds={selectedSaleLotIds}
                setSelectedSaleLots={this.setSelectedSaleLots}
                patchSaleLot={patchSaleLot}
                addSaleLot={addSaleLot}
              />
            ) : (
              <>
                <PricingSelect
                  allowHdCountEntry
                  handleNumPress={this.handleNumPress}
                  handleDeletePress={this.handleDeletePress}
                  isDisabled={disabled}
                  isSingleLot={selectedSaleLotIds.length === 1}
                  kilograms={kilograms}
                  price={price}
                  showMassEntry
                  showNumPad
                  selectedHdCount={selectedSaleLotQuantity}
                  selectedInput={selectedInput}
                  selectedPricingTypeId={selectedPricingType}
                  setSelectedInput={this.setSelectedInput}
                  setSelectedPricingType={this.handlePriceTypeClick}
                  totalHdCount={counts.quantity}
                  totalProgeny={counts.quantityProgeny}
                />

                {multipleBuyers ? (
                  <ViewSplitsCard onClick={this.toggleView}>
                    <div style={{ textAlign: "center" }}>
                      Multiple buyers.
                      <br />
                      Click to view split sale lots
                    </div>
                    <FontAwesomeIcon
                      icon={faExpandAlt}
                      style={{ marginLeft: 12 }}
                    />
                  </ViewSplitsCard>
                ) : (
                  <Column>
                    <BuyerPicker
                      agencyId={agencyId}
                      bidderSearchValue={bidderNumberSearch}
                      buyerId={buyerId}
                      vendorId={vendorId}
                      buyerWayId={buyerWayId}
                      isDisabled={disabled}
                      isBidderSearchSelected={
                        selectedInput === KeypadInput.BIDDER_SEARCH
                      }
                      onFocusBidderNumberSearch={() =>
                        this.setSelectedInput(KeypadInput.BIDDER_SEARCH)
                      }
                      onOpenAddBuyerWay={this.openAddBuyerWay}
                      onSelectBuyerValues={this.setBuyerValues}
                      openSearch={this.openBuyerSearch}
                      propertyId={propertyId}
                    />
                  </Column>
                )}
                {this.displayUnsellDialog(isUnsellDialog)}
                <ActionButtons
                  handleReset={this.handleReset}
                  handleUnSell={this.showUnsellDialog}
                  allSold={allSold}
                  setNoSale={this.setNoSale}
                  handleSell={this.handleSell}
                  enableSell={enableSell}
                  enableNoSale={!allSold}
                  enableUnSell={enableUnSell}
                  buyerId={buyerId}
                  price={parsePrice}
                  editMode={editMode}
                  enableEditMode={this.enableEditMode}
                />
              </>
            )}
          </Content>
        </Wrapper>

        {isAddBuyerWayVisible && (
          <AddBuyerWayModal
            agencyId={agencyId}
            buyerId={buyerId}
            closeSelf={this.closeAddBuyerWay}
            fullScreen
            setBuyerWayId={this.setBuyerWayId}
          />
        )}

        {isBuyerSearchVisible && (
          <BuyerSearchModal
            agencyId={agencyId}
            closeSelf={this.closeBuyerSearch}
            fullScreen
            setBuyerValues={this.setBuyerValues}
          />
        )}
      </>
    );
  }
}

AuctionPenSaleView.propTypes = {
  agencyIdByConsignmentIdLookup: PropTypes.object.isRequired,
  businesses: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  patchSaleLot: PropTypes.func.isRequired,
  createSaleLotComment: PropTypes.func.isRequired,
  addSaleLot: PropTypes.func.isRequired,
  auctionPen: PropTypes.object,
  saleLots: PropTypes.array,
  hasNext: PropTypes.bool,
  hasPrev: PropTypes.bool,
  incrementAuctionPen: PropTypes.func,
  roundId: PropTypes.number.isRequired,
  sale: PropTypes.object.isRequired,
  singleWeighWeightBySaleLotIdLookup: PropTypes.object,
};

export default AuctionPenSaleView;
