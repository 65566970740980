import React, { useLayoutEffect, useRef } from "react";

import {
  faArrowDown,
  faArrowUp,
  faCopy,
  faPencil,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";

import {
  closeConfirmModal,
  MasterRuleAction,
  openConfirmModal,
  openDispatchingModal,
} from "actions";

import { MultiButton } from "components/Button";
import { Warning } from "components/ErrorMessage";
import { Paper } from "components/Paper";
import { StatusWrapper } from "components/ScanningPenCards/Card/elements";

import { ModalTypes } from "constants/navigation";

import { ForStaff } from "containers/ForUserType";
import { CustomiseManagedRuleForm } from "containers/Settings/RuleBooks/Rules/ManagedRules";

import { getModalLink } from "lib/navigation";

import {
  getIsMoveDownEnabledByMasterRuleId,
  getIsMoveUpEnabledByMasterRuleId,
  getMasterRuleById,
  getRuleById,
} from "selectors";

export function MasterRuleLibraryCard(props) {
  const {
    masterRuleId,
    focusOnMount,
    ruleBookId,
    subscribedRuleId,
    namespace: ns,
  } = props;

  const thisCardRef = useRef();

  useLayoutEffect(() => {
    // Scroll into view if we are meant to be focused.
    if (thisCardRef.current && focusOnMount) {
      setTimeout(() => {
        if (thisCardRef.current && focusOnMount) {
          thisCardRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      }, 250);
    }
  }, [focusOnMount, thisCardRef]);

  const dispatch = useDispatch();

  const masterRule = useSelector(getMasterRuleById(masterRuleId));
  const subscribedRule = useSelector(getRuleById(subscribedRuleId));

  const isMoveUpEnabled = useSelector(
    getIsMoveUpEnabledByMasterRuleId(masterRuleId),
  );

  const isMoveDownEnabled = useSelector(
    getIsMoveDownEnabledByMasterRuleId(masterRuleId),
  );

  function onClickEditMasterRule() {
    // Create our own return to link with the open masterRuleId, so we scroll this
    // master rule back into view onClose.  See the useLayoutEffect above for the scroll.
    const returnTo = getModalLink(
      ModalTypes.RuleLibrary,
      {
        ruleBookId,
        masterRuleId,
      },
      null,
    );

    dispatch(
      openDispatchingModal(
        ModalTypes.EditMasterRule,
        returnTo,
        { masterRuleId },
        { masterRuleId },
      ),
    );
  }

  function onClickMoveUp() {
    dispatch(MasterRuleAction.moveUp(masterRuleId));
  }

  function onClickMoveDown() {
    dispatch(MasterRuleAction.moveDown(masterRuleId));
  }

  function onClickCopy() {
    dispatch(MasterRuleAction.copy(masterRuleId));
  }

  function onClickConfirmDeleteMasterRule() {
    dispatch(MasterRuleAction.delete(masterRuleId));

    dispatch(closeConfirmModal());
  }

  function onClickCancelConfirmDeleteMasterRule() {
    dispatch(closeConfirmModal());
  }

  function onClickDeleteMasterRule() {
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message: "Are you sure you want to delete this Master Rule?",
        actions: [
          {
            label: "Cancel",
            onClick: onClickCancelConfirmDeleteMasterRule,
            secondary: true,
          },
          {
            label: "Delete Master Rule",
            onClick: onClickConfirmDeleteMasterRule,
            secondary: false,
          },
        ],
      }),
    );
  }

  const options = [
    {
      title: "Edit Master Rule",
      isDisabled: false,
      onClick: onClickEditMasterRule,
      default: true,
      icon: faPencil,
      dataTour: "editMasterRule",
    },
    {
      title: "Copy",
      isDisabled: false,
      onClick: onClickCopy,
      default: false,
      icon: faCopy,
      dataTour: "copyMasterRule",
    },
    {
      title: "Move Up",
      isDisabled: !isMoveUpEnabled,
      onClick: onClickMoveUp,
      default: false,
      icon: faArrowUp,
      dataTour: "moveUpMasterRule",
    },
    {
      title: "Move Down",
      isDisabled: !isMoveDownEnabled,
      onClick: onClickMoveDown,
      default: false,
      icon: faArrowDown,
      dataTour: "moveDownMasterRule",
    },
    {
      title: "Delete",
      isDisabled: false,
      onClick: onClickDeleteMasterRule,
      default: false,
      icon: faTrash,
      dataTour: "deleteMasterRule",
    },
  ];

  let color = "primary";

  if (masterRule.is_deleted) {
    color = "errorRed";
  } else if (!subscribedRule) {
    color = "grayDisabled";
  } else if (masterRule.content !== subscribedRule.content) {
    color = "warningOrange";
  }

  return (
    <StatusWrapper color={color} ref={thisCardRef}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {masterRule.name}
          </Grid>
          <ForStaff>
            <Grid item container xs={4} justifyContent="flex-end">
              <MultiButton buttons={options} />
            </Grid>
            {masterRule.is_deleted ? (
              <Grid item xs={12}>
                <Warning>
                  This rule has been soft deleted. You may attempt to completely
                  delete it in admin, which will show any subscribed client
                  rules that need to be resolved first. If the rule has been
                  used to generate any charges, deletion will be blocked.
                </Warning>
              </Grid>
            ) : null}
          </ForStaff>

          <Grid item xs={12}>
            <blockquote>{masterRule.comment}</blockquote>
          </Grid>

          <CustomiseManagedRuleForm ruleBookId={ruleBookId} namespace={ns} />
        </Grid>
      </Paper>
    </StatusWrapper>
  );
}
