export const MasterRuleStatus = {
  NOT_MANAGED: "NOT_MANAGED",
  DELETED: "DELETED",
  IN_SYNC: "IN_SYNC",
  OUT_SYNC: "OUT_SYNC",
};

export const MasterRuleStatusTooltip = {
  [MasterRuleStatus.NOT_MANAGED]: {
    color: "gray9C",
    backgroundColor: "grayDisabled",
    tooltip: "This is not a Managed Rule",
  },
  [MasterRuleStatus.DELETED]: {
    color: "errorRed",
    backgroundColor: "grayDisabled",
    tooltip: "The Master Rule has been deleted.",
  },
  [MasterRuleStatus.IN_SYNC]: {
    color: "primary",
    backgroundColor: "grayDisabled",
    tooltip: "Managed Rule is up to date.",
  },
  [MasterRuleStatus.OUT_SYNC]: {
    color: "warning",
    backgroundColor: "grayDisabled",
    tooltip: "The Manged Rule's content is out of date with the Master Rule.",
  },
};

export const ruleInputLabelBySaleExportFieldMap = {
  '$["lots"][*]': "Lots",
  '$["manual_adjustments"][*]': "Sundries",
  '$["carriers"][*]': "Carriers",
  '$["vendors"][*]': "Vendors",
  '$["buyers"][*]': "Buyers",
  '$["consignments"][*]': "Consignments",
  '$["sales"][*]': "Sales",
  '$["stock_agents"][*]': "Stock Agents",
  '$["outside_agents"][*]': "Outside Agents",
  '$["rebate_agents"][*]': "Rebate Agents",
  '$["livestock_consultants"][*]': "Livestock Consultants",
};
