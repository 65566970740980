import React from "react";

import Grid from "@material-ui/core/Grid";
import { getIn, useFormikContext } from "formik";

import { PricingMethod } from "components/Billing/LedgerEntry/LedgerEntryPricingForm";
import {
  ControlledLifeCycleInput,
  DollarDisplayCentsInputProps,
  OptionTogglerField,
  withNamespace,
} from "components/Form/FormikControls";

import { GstMethod } from "constants/billing";

import { usePricingFormContext } from "hooks";

const GstMethodOptions = [
  { label: "Inclusive", value: GstMethod.GST_INCLUSIVE },
  {
    label: "Exclusive",
    value: GstMethod.GST_EXCLUSIVE,
  },
  { label: "Exempt", value: GstMethod.GST_EXEMPT },
  { label: "Fixed", value: GstMethod.GST_FIXED },
];

export function SundryPricingForm(props) {
  const {
    namespace: ns = "",
    readOnlyFields = [],
    onAfterPricingModelChanged,
  } = props;

  const { values } = useFormikContext();
  const fieldValues = getIn(values, ns);

  const pricingMethod = getIn(fieldValues, "pricingMethod");
  const gstMethod = getIn(fieldValues, "gstMethod");

  const {
    onAfterGstMethodChanged,
    onAfterTotalPriceChanged,
    onAfterTaxAmountChanged,
  } = usePricingFormContext(ns, onAfterPricingModelChanged);

  return (
    <>
      <Grid item xs={12}>
        <OptionTogglerField
          label="GST Method"
          labelPosition="top"
          name={withNamespace(ns, "gstMethod")}
          options={GstMethodOptions}
          onChangeExtra={onAfterGstMethodChanged}
          required
          disabled={readOnlyFields.includes("gstMethod")}
        />
      </Grid>

      {gstMethod !== GstMethod.GST_EXEMPT && (
        <Grid item xs={4}>
          <ControlledLifeCycleInput
            disabled={
              gstMethod !== GstMethod.GST_FIXED ||
              readOnlyFields.includes(withNamespace(ns, "taxAmount"))
            }
            label="GST Total"
            name={withNamespace(ns, "taxAmount")}
            onChangeExtra={onAfterTaxAmountChanged}
            required
            {...DollarDisplayCentsInputProps}
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <ControlledLifeCycleInput
          disabled={
            !(
              (gstMethod === GstMethod.GST_EXCLUSIVE ||
                gstMethod === GstMethod.GST_EXEMPT ||
                gstMethod === GstMethod.GST_FIXED) &&
              pricingMethod === PricingMethod.GROSS_PRICE
            ) || readOnlyFields.includes(withNamespace(ns, "subtotal"))
          }
          label={
            gstMethod !== GstMethod.GST_EXEMPT
              ? "Total Price (Ex. GST)"
              : "Total Price"
          }
          name={withNamespace(ns, "subtotal")}
          onChangeExtra={onAfterTotalPriceChanged}
          required
          {...DollarDisplayCentsInputProps}
        />
      </Grid>
      {gstMethod !== GstMethod.GST_EXEMPT && (
        <Grid item xs={4}>
          <ControlledLifeCycleInput
            disabled={
              !(
                gstMethod === GstMethod.GST_INCLUSIVE &&
                pricingMethod === PricingMethod.GROSS_PRICE
              ) || readOnlyFields.includes(withNamespace(ns, "totalInc"))
            }
            label="Total Price (Inc. GST)"
            name={withNamespace(ns, "totalInc")}
            onChangeExtra={onAfterTotalPriceChanged}
            required
            {...DollarDisplayCentsInputProps}
          />
        </Grid>
      )}
    </>
  );
}
