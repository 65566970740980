import React from "react";

import { get } from "lodash";
import { useSelector } from "react-redux";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";

import {
  BillingDocumentColId,
  BillingDocumentColumnDef,
  BusinessColId,
  BusinessColumnDef,
  FixWarningsActionColumn,
} from "constants/columnDefinitions";

import { selectBillingDocumentsWithWarningsAggridDataById } from "selectors";

const rowSelectionId = "billingDocument.id";
const getRowId = params => get(params.data, rowSelectionId);

const columnDefs = [
  BillingDocumentColumnDef[BillingDocumentColId.DOCUMENT_TYPE],
  BillingDocumentColumnDef[BillingDocumentColId.DOCUMENT_NUMBER],
  BusinessColumnDef[BusinessColId.NAME],
  BillingDocumentColumnDef[BillingDocumentColId.WARNINGS],
  FixWarningsActionColumn,
];

export const Warnings = () => {
  const rowData = useSelector(selectBillingDocumentsWithWarningsAggridDataById);

  return (
    <>
      <h3>Warnings</h3>
      <AgGridTable
        columnDefs={columnDefs}
        getRowId={getRowId}
        rowData={rowData}
        showGlobalSearchFilter={false}
        hideHeader
        WrapperComponent={CollapseTableWrapper}
        rowSelection="multiple"
      />
    </>
  );
};
