import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { SaleAction, synchronousAddConsignment } from "actions";

import ConsignmentForm from "components/ConsignmentForm";

import { ConsignmentModalSection } from "constants/navigation";

import { getConsignmentPayload } from "lib/consignments";
import { serializeLivestockSale } from "lib/sale";
import {
  dateTimeStringToDateTime,
  formatHeaderDateString,
} from "lib/timeFormats";
import toast from "lib/toast";

import {
  getActiveRole,
  getAgencyByDeploymentId,
  getAllAvailableSaleyards,
  getConsignableDeploymentSaleById,
  getConsignableSaleByDeploymentSaleId,
  getPrimaryAgency,
  getSpeciesById,
} from "selectors";

export const CreateConsignment = ({
  deploymentSaleId,
  formRef,
  livestockSaleId,
  setIsSubmitEnabled,
}) => {
  const activeRole = useSelector(getActiveRole);
  const selectedSale = useSelector(
    getConsignableSaleByDeploymentSaleId(deploymentSaleId),
  );
  const deploymentSale = useSelector(
    getConsignableDeploymentSaleById(deploymentSaleId),
  );
  const agency = useSelector(
    getAgencyByDeploymentId(deploymentSale.deployment_id),
  );

  const primaryAgency = useSelector(getPrimaryAgency);

  // FIXME Pull out any saleyard that crosses over with this agency.
  // until we have full saleyard agnostic business endpoints, they kind of don't quite line
  // up - we just want something to be able to ADD businesses through the consignment
  // form.  If they don't have any saleyards here, well, we have bigger problems.
  const saleyardName = useSelector(getAllAvailableSaleyards)?.[0]?.name;

  const dispatch = useDispatch();

  const handleSubmit = consignmentValues => {
    setIsSubmitEnabled(false);
    const onSuccess = () => {
      const id = selectedSale.livestockSaleId;
      dispatch(SaleAction.fetchAndOpen(id));
    };

    const onError = () => {
      setIsSubmitEnabled(true);
    };

    const onTimeout = () => {
      // Something really broke... throw up a message and let them resubmit.
      toast.error(
        "The server is taking some time to process your request.  Please try again later.",
      );
      setIsSubmitEnabled(true);
    };

    const consignmentPayload = getConsignmentPayload(consignmentValues);
    // TODO/FIXME - addConsignment->getSaleUrl still uses snake cased fields - remove the serializeLivestockSale when
    // that is updated.
    dispatch(
      synchronousAddConsignment(
        consignmentPayload,
        serializeLivestockSale(selectedSale),
        agency.id,
        { onSuccess, onError, onTimeout },
      ),
    );
  };

  const values = {
    consignedFromId:
      activeRole.deployments.length === 1
        ? activeRole.deployments[0].id
        : undefined,
    // This is needed so it will get passed down to the business pic selector, allowing creation of
    // businesses.
    agency_id: primaryAgency.agency_id,
  };
  const date = dateTimeStringToDateTime(selectedSale.date);
  const namedDate = formatHeaderDateString(date);

  const speciesName = useSelector(getSpeciesById(selectedSale.speciesId)).name;

  return (
    <>
      <h3>
        {`Creating a consignment ${agency ? `for ${agency?.name}` : ""} at ${
          selectedSale.saleyardName
        } 
        ${speciesName} Sale on ${namedDate}`}
      </h3>
      <ConsignmentForm
        livestockSaleId={livestockSaleId}
        handleSubmit={handleSubmit}
        isCreate
        restrictConsignedFrom
        showSaleLots={false}
        showClearingSection={false}
        setIsSubmitEnabled={setIsSubmitEnabled}
        formRef={formRef}
        values={values}
        deploymentSaleId={deploymentSaleId}
        selectedSaleType={selectedSale.saleType}
        saleyardName={saleyardName}
        section={ConsignmentModalSection.GENERAL}
        showNominations={false}
        requireConsignedFrom
      />
    </>
  );
};
