import { withNamespace } from "components/Form/FormikControls";

import { CommentColId } from "constants/columnDefinitions/comments";
import { EmailColId } from "constants/columnDefinitions/email";
import { SaleLotNamespaces } from "constants/saleLots";

const XERO = {
  CONTACT_NAME: "billingItem.xeroBusinessName",
  EMAIL_ADDRESS: "billingItem.contact.email",

  // Root from selector.
  PO_ADDRESS_LINE1: "po.line1",
  PO_ADDRESS_LINE2: "po.line2",
  PO_ADDRESS_LINE3: "po.line3",
  PO_ADDRESS_LINE4: "po.line4",
  PO_CITY: "po.city",
  PO_REGION: "po.region",
  PO_POSTAL_CODE: "po.postalCode",
  PO_COUNTRY: "po.country",

  // Root from selector
  SA_ADDRESS_LINE1: "sa.line1",
  SA_ADDRESS_LINE2: "sa.line2",
  SA_ADDRESS_LINE3: "sa.line3",
  SA_ADDRESS_LINE4: "sa.line4",
  SA_CITY: "sa.city",
  SA_REGION: "sa.region",
  SA_POSTAL_CODE: "sa.postalCode",
  SA_COUNTRY: "sa.country",

  // Root from selector
  INVOICE_NUMBER: "invoiceNumber",

  REFERENCE: "billingItem.reference",
  INVOICE_DATE: "billingItem.invoiceDate",
  DUE_DATE: "billingItem.dueDate",
  PLANNED_DATE: "billingItem.plannedDate",
  TOTAL: "billingItem.total",
  TAX_TOTAL: "billingItem.taxTotal",

  INVOICE_AMOUNT_PAID: "billingItem.invoiceAmountPaid",
  INVOICE_AMOUNT_DUE: "billingItem.invoiceAmountDue",
  INVENTORY_ITEM_CODE: "billingItem.inventoryItemCode",
  DESCRIPTION: "billingItem.description",
  QUANTITY: "billingItem.quantity",
  UNIT_AMOUNT: "billingItem.unitAmount",
  DISCOUNT: "billingItem.discount",
  LINE_AMOUNT: "billingItem.lineAmount",
  ACCOUNT_CODE: "billingItem.accountCode",
  TAX_TYPE: "billingItem.taxType",
  TAX_AMOUNT: "billingItem.taxAmount",
  TRACKING_NAME_1: "billingItem.tracking1.name",
  TRACKING_OPTION_1: "billingItem.tracking1.option",
  TRACKING_NAME_2: "billingItem.tracking2.name",
  TRACKING_OPTION_2: "billingItem.tracking2.option",
  CURRENCY: "billingItem.currency",
  TYPE: "billingItem.type",
  BRANDING_THEME: "billingItem.type", // this is the a rename of type, but we want both columns
};

const ConsignmentColId = {
  VENDOR_NUM: "vendorNumber",
  VENDOR_NAME: "vendor_name",
  BUSINESS_DISPLAY_NAME: "vendor.publicDisplayName",
  AGENCY_NAME: "livestockAgency.name",
  AGENCY_VENDOR_NUM: "consignment.agencyVendorNumber",
  AGENT_RELATIONSHIP: "vendor.relationships",
  TRANSIT_INSURANCE: "vendor.hasTransitInsurance",
  VENDOR_CONSIGNMENT_PIC: "vendor_property.PIC",
  CONSIGNED_FROM: "consignedFrom",
  BRANCH: "branchName",
  BRANDS: "d88fd149-c438-475f-b18d-49bcb2b5c511",
  RECEIVE_REF: "receiving_reference",
  RECEIVE_PEN: "receiving_pen",
  RECEIVAL_PENS: "receivalPens",
  RECEIVAL_LOTS_HEAD_COUNT: "receivalLotsHeadCount",
  RECEIVAL_LOTS_SCAN_COUNT: "receivalLotsScanCount",
  RECEIVAL_LOTS: "receivalLots",
  QUANTITY: "quantity",
  SCANNED_COUNT: "scannedCount",
  SCANNED_PERCENTAGE: "scannedPercentage",
  CONSIGNMENT_SCANNED_COUNT: "consignmentScannedCount",
  DRAFT_HC: "drafted",
  NVD_HC: "quantity_NVD",
  CONSIGNMENT_DIFF: "quantity_diff",
  NVD_RECEIVED: "isNVDAttached",
  NVD_ID: "NVD",
  ATTACHMENTS: "attachments",
  PIC_PROGRAMS: "nlisPrograms",
  LPA_STATUS: "lpaStatus",
  VENDOR_PIC_LPA_NUMBER: "vendor_property.lpaNumber",
  VENDOR_PIC_MSA_NUMBER: "vendor_property.msaNumber",
  DELIVERY_DATE: "carrierCharge.deliveryDate",
  ABN: "vendor.abn",
  ABN_CHECK: "f401995e-b38f-4e10-8ec1-5e489e5e8904",
  GST_STATUS: "vendor.isGSTRegistered",
  IS_HOBBY_FARMER: "vendor.isHobbyFarmer",
  CARRIER: "carrierCharge.carrier",
  HAS_ARRIVED: "hasArrived",
  CREATED: "created",
  LAST_MODIFIED: "last_modified",
  VENDOR_SHORT_CODE: "vendor_short_code",
  VENDOR_SHORT_CODE_SALEYARD: "vendor.shortCodeSaleyard",
  NOMINATION: "nomination",
  NOMINATION_CONSIGNING_DEPLOYMENT: "nominationConsigningDeployment.name",
  NOMINATION_DETAILS: "nominationDetails",
  NOMINATION_VENDOR: "nominationVendor.name",
  NOMINATION_HAS_ARRIVED: "nomination.hasArrived",
  VENDOR_SPLITS: "313a743d-46d3-418a-ba01-eef109f9d8bb",
  TOTAL_VALUE: "38056a70-0083-41e0-aa69-978d6066b5ef",
  UNIT_VALUE: "732eace3-62fe-44c6-9d22-bc05dd13a7f0",
  SOLD_QUANTITY: "f0d00c1f-bafc-47fd-a830-57fde2e412b2",
  WARNING: "037ec826-a25e-4e8f-8a71-221d80aebbdb",
};

export const RuleBookColId = {
  NAME: "64f7b297-7028-4269-8940-8ec28655d134",
};

export const IssuerColId = {
  ISSUER: "0f06f55c-e545-40d0-8034-5366d0363d68",
  ISSUER_NAME: "1ecae355-5e2f-4e5f-8c61-edb17ce0e150",
  ISSUER_XERO_INTEGRATION_BUSINESS: "b9007180-80bc-4e01-a999-9b08e4966c59",
};

export const RecipientColId = {
  RECIPIENT: "8ca6c430-0dfa-425a-b133-3aa0b3b919f9",
  RECIPIENT_NAME: "aa1bc1ad-b63f-4fc8-9c7e-832c4bb57258",
  RECIPIENT_XERO_INTEGRATION_BUSINESS: "d99e7d2d-ce5c-4d61-a904-375e35a3063f",
};

export const BillingTagsColId = {
  TAGS: "55c89f62-6628-4a0d-bb50-9b607414c1f9",
  TAG_NAMES: "47be2fc9-98c2-4914-82db-e3a7308eff50",
};

export const LivestockSaleColId = {
  TITLE: "livestockSale.title",
  SALE_TYPE: "62216b32-894d-43b8-a8ba-24be574b9fa3",
  SALE_SUB_TYPE: "7fc57e14-aae3-4a18-b49c-f7e2814ef9e4",
  DATE: "3f3daabb-87a4-4e38-aeaa-c08dc15046c3",
  SPECIES_NAME: "620394f7-a5a6-4f03-8691-14df594363be",
};

export const NominationDetailColId = {
  BREED: "fc93bd08-2eb6-4eeb-a3b7-a2fbe5c359a3",
  SEX: "2d1fc06d-1f32-41be-8659-7baf32a4b511",
  AGE: "b4a8041a-31c2-4d6f-ab91-4b3cffd6082d",
  WEIGHT: "487b2129-2e03-4ed5-bf90-b77bfee9e252",
  NOTES: "612b7489-5f68-4012-9e6d-ad9486a453f4",
  NOMINATION_TERM: "0a66df7f-5e11-4f38-b7a9-eac39577c33d",
};

const CattleDraftingAttributesColId = {
  ACCREDITATION_EU: "7a3715df-433a-4b6b-8327-d37775aae09d",
  ACCREDITATION_GRASS_FED: "24410a56-20e5-4b40-8102-2655f04bbb88",
  ACCREDITATION_PTIC: "d69abb35-fabc-44f6-b423-9332a5abc84c",
  ACCREDITATION_PCAS: "a6e7a04d-939a-4b4a-8ec8-e9687d11a8f2",
  ACCREDITATION_ANTIBIOTIC_FREE: "89c54b5e-f1be-4bbb-bf24-4ef23b2d7974",
  ACCREDITATION_NEE: "8b998cc5-0579-4f0b-9259-1b9c28895226",
  ACCREDITATION_JBAS: "2de1d310-3c6d-47f5-a84f-647ab959189f",
  ACCREDITATION_PTE: "0f8acd37-96dd-4ffa-a9ae-48b301e8413f",
  ACCREDITATION_ORGANIC: "254a9104-b53b-40e3-b360-33e6ba584c85",
  ACCREDITATION_STATION_MATED: "fb97b417-9403-4a19-aa00-66c4c2f76c58",
  ACCREDITATION_NOT_STATION_MATED: "d64b68db-0262-4dd7-9947-03442f7dec53",
  ACCREDITATION_BACKGROUNDED: "febb6552-4234-4c64-8304-4ab90b10491b",
  ACCREDITATION_FEEDER: "b65d5c50-9bc4-46e3-ab6a-c6d12822a85c",
  ACCREDITATION_GRAIN_FED_DAYS: "770747ee-9972-4425-8778-8d69deb65190",
};

const SheepDraftingAttributesColId = {
  ANIMAL_HEALTH_STATEMENT_OVERRIDE: "c16b3f02-bfff-4796-8421-bab7501a9524",
  SIX_IN_ONE: "bd563807-95b3-4a39-b225-5f63d416ba53",
  DRENCHED: "ed24f325-73bd-422a-bbdc-95ca58ec70c4",
  OJD_VAC: "a5ebf1c9-2bd9-41cb-9cea-24ddd9073100",
  GUDAIR_APPROVED: "748c8cd1-0548-463c-8487-05b9792f042d",
  B12_VAC: "3e00b153-c496-421f-a807-3b082cf8550e",
  BACKLINE: "ec29820c-1eb0-4f44-a1e0-04213d1c00c1",
  M_AND_TS: "d7641537-d5a5-49f6-9480-93b8a395f7b0",
  SA_ELIGIBLE: "8ca218a2-d71e-4a49-9bd6-0877fde658ad",
  PAIN_RELIEF: "e9872897-6515-44c3-80a6-076ce6310199",
  ACCREDITATION_ORGANIC: "4adc34ad-8490-49e6-94a5-0eaf2d636f41",
};

const DraftingAttributesColId = {
  CattleDraftingAttributes: CattleDraftingAttributesColId,
  SheepDraftingAttributes: SheepDraftingAttributesColId,
};

const SaleLotColId = {
  TAG_NUMBER: "draftingAttributes.tagNumber",
  ANIMAL_NAME: "draftingAttributes.animalName",
  ACCOUNT_NOTE: "6b01fafe-ca8e-4f12-97ee-f8a6f1a5f2d6",
  UNIT_PRICE: "c11fa90e-ddbe-47cb-b4e4-9103d167b0db",
  PRICING_TYPE: "9c4ede2e-bd6c-400f-bbd6-556f633f5de0",
  ESTIMATED_WEIGHT_RANGE: "ecb99246-ff99-4b2f-9334-c97d98bf953e",
  ESTIMATED_DRESSING_RANGE: "fad86946-66ff-46ca-b704-4ce4b0995922",
  PEN_SCAN_LOTS: "4698f205-d84c-4b35-963f-80a84403c0f9",
  VENDOR_SPLITS: "3acc4797-b6db-4907-80df-adf601e5433d",
  WARNING: "160e2d36-6ed5-41cc-a092-73a8595e9362",
};

const TotalColId = {
  LOW_DOLLARS_HEAD: "6260a772-7dc7-4143-a837-90ba7e8d7b73",
  AVG_DOLLARS_HEAD: "46ac436e-d15f-4f3c-b2d6-326fde5fe3f9",
  HIGH_DOLLARS_HEAD: "bc45f7e5-1ccf-48a9-907c-2afbb7a85c94",
  LOW_CENTS_KG: "37d1a9a0-b480-41b5-8c2d-e86b6c19d4d5",
  AVG_CENTS_KG: "dbee910c-d780-450a-a918-8257d599670b",
  HIGH_CENTS_KG: "d302f012-e77a-46e2-a100-c472fe1e1171",
  HEAD: "7d574b20-937e-4a94-8949-fb195bf25fc8",
  CENTS: "710bfae2-1031-4bc5-a947-02d105dfe679",
};

export const Column = {
  ABN: "ABN",
  AGENCY_NAME: "livestockAgency.name",
  AGENCY_VENDOR_NUM: "consignment.agencyVendorNumber",
  AGE_GROUP_NAME: "age_group_name",
  BRANDS: "040e8d7e-8dd0-4e15-ad4f-678eeba8e2c7",
  IS_ANGUS_VERIFIED: "isAngusVerified",
  NOT_ANGUS_VERIFIED_COUNT: "notAngusVerifiedCount",
  ATTACHMENTS: "attachments",
  AVERAGE_WEIGHT: "averageWeightFormatted",
  AVERAGE_WEIGHT_OUTLIER: "averageWeightFormattedOutlier",
  BIDDER_REGO_NUMBER: "bidder.registrationNumber",
  BRANCH: "branchName",
  BUYER_ALTERNATIVES: "buyer.alternatives",
  BREED_NAME: "breed_name",
  BUYER_NAME: "buyer_name",
  BUYER_PAYMENTS: "buyerPaymentCount",
  BUSINESS_DISPLAY_NAME: "vendor.publicDisplayName",
  BUYER_WAY_NAME: "buyer_way_name",
  BUYER_SHORT_CODE: "buyer_short_code",
  BUYER_SHORT_CODE_SALEYARD: "buyer.shortCodeSaleyard",
  CARRIER: "carrierCharge.carrier",
  CATEGORY_NAME: "category_name",
  COMMENTS: CommentColId.COMMENTS,
  CREATED: "created",
  CURRENT_PIC: "current_pic",
  CONSIGNED_FROM: "consignedFrom",
  DELIVERY_DATE: "carrierCharge.deliveryDate",
  DELIVERY_PEN: "deliveryPen",
  DESTINATION_PIC: "destinationProperty.PIC",
  DIFF: "diff",
  DRAFT_DEVICE: "draft_device",
  DRAFT_NAME: "draft_name",
  EID: "EID",
  EU_STATUS: "EU_status",
  EXEMPTION_REASON: "exemption_name",
  GRADE_NAME: "grade_name",
  GST_STATUS: "gstStatus",
  HAS_EXCEPTIONS: "hasExceptions",
  IS_NLIS_SIGHTED: "is_nlis_sighted",
  IMAGES: "images",
  INVOICE_TO_CODE: "invoiceToCode",
  INVOICE_TO_NAME: "invoiceToName",
  IS_SELLABLE: "isSellable",
  IS_TRANSFERRED: "isTransferred",
  IS_TAKEABLE: "isTakeable",
  XERO_VENDOR: "xeroVendor",
  XERO_VENDOR_NAME: "xeroVendor.name",
  XERO_BUYER: "xeroBuyer",
  XERO_BUYER_NAME: "xeroBuyer.name",
  XERO_TARGET: "xeroTarget",
  LABELS: "labels",
  LAST_MODIFIED: "last_modified",
  LIFETIME_TRACEABILITY: "lifetime_traceability",
  LOT_NUMBER: "lot_number",
  LOT_NUMBER_COMBINED: "lotNumberCombined",
  LOT_NUMBER_SUFFIX: "lotNumberSuffix",
  LPA_STATUS: "lpaStatus",
  MARKS: "marks",
  NLIS_ID: "nlis_id",
  NLIS_TAKE_ID: "latestTakeFileTransactionId",
  NLIS_SELL_ID: "latestSellFileTransactionId",
  NLIS_TAKE_STATUS: "scan.nlis_take_status",
  NLIS_SELL_STATUS: "scan.nlis_sell_status",
  NLIS_TAKE_TRANSFER_STATUS: "latestTakeTransfer.response_type",
  NLIS_TAKE_TRANSFER_MESSAGE: "latestTakeTransfer.message_no",
  NLIS_TAKE_TRANSFER_NVD: "latestTakeTransfer.NVD",
  NLIS_TAKE_TRANSFER_VENDOR_PIC: "latestTakeTransfer.vendor_pic",
  NLIS_SELL_TRANSFER_BUYER_PIC: "latestSellTransfer.buyer_pic",
  NLIS_SELL_TRANSFER_STATUS: "latestSellTransfer.response_type",
  NLIS_SELL_TRANSFER_MESSAGE: "latestSellTransfer.message_no",
  NOMINATION: "nomination",
  NOMINATION_HC: "nominationHd",
  NOMINATION_CONSIGNING_DEPLOYMENT: "nominationConsigningDeployment.name",
  NOMINATION_DETAILS: "nominationDetails",
  NOMINATION_VENDOR: "nominationVendor.name",
  NOMINATION_HAS_ARRIVED: "nomination.hasArrived",
  NOMINATION_GRAND_TOTAL: "nomination.grandTotal",
  NOMINATION_CONSIGNMENT_DIFF: "nomination.consignmentDiff",
  NOTE: "note",
  NVD: "consignment.NVD",
  NVD_ID: "NVD",
  OVERFLOW_PEN: "overflowPen",
  OPEN_AUCTION_COUNT: "openAuctionCount",
  PEN: "penName",
  PIC_PROGRAMS: "nlisPrograms",
  QUANTITY: "quantity",
  QUANTITY_DELIVERED: "quantity_delivered",
  QUANTITY_PROGENY: "quantityProgeny",
  QUANTITY_TAGS_USED: "quantityTagsUsed",
  PRICING_TYPE: "pricing_type_id",
  AGENT_RELATIONSHIP: "vendor.relationships",
  STATUS: "status",
  SALE_ROUND_NAME: "sale_round_name",
  SEX_NAME: "sex_name",
  SCAN_ERP_STATUS: "ERP_status",
  SCANNED_COUNT: "scannedCount",
  SCANNED_PERCENTAGE: "scannedPercentage",
  SCANNED_PERCENTAGE_FORMATTED: "scannedPercentageFormatted",
  SINGLE_WEIGH_SUM: "totalSingleWeighedWeight",
  SINGLE_WEIGH_DIFF: "totalSingleWeighedWeightDiff",
  SINGLE_WEIGH_SUM_OUTLIER: "totalSingleWeighedWeightOutlier",
  TOTALS: "totals",
  TARGET_BUSINESS_NAME: "targetBusiness.name",
  TIME_BULK_WEIGHED: "bulkWeighTime",
  SCAN_TIME_WEIGHED: "time_weighed",
  THIRD_PARTY_NAME: "thirdPartyName",
  TOTAL_PRICE: "total_price_cents",
  UNIT_PRICE: "unit_price",
  UNIT_PRICE_FORMATTED: "unitPriceFormatted",
  VENDOR_ALTERNATIVES: "vendor.alternatives",
  VENDOR_NAME: "vendor_name",
  VENDOR_PIC: "consignment.vendor_property.PIC",
  VENDOR_NUM: "vendorNumber",
  VENDOR_SHORT_CODE: "vendor_short_code",
  VENDOR_SHORT_CODE_SALEYARD: "vendor.shortCodeSaleyard",
  WEIGHED_COUNT: "weighedCount",
  WEIGHT: "totalWeightFormatted",
  WEIGHT_OUTLIER: "totalWeightOutlier",

  // The following are not in alphabetical order
  TOTAL_MASS_GRAMS: "totalMassGrams",
  TOTAL_MASS_GRAMS_OUTLIER: "totalMassGramsOutlier",
  IS_MANUALLY_WEIGHED: "a883f41d-21b1-42e0-afd6-e9e482a71b31",
  BUYER_NUMBER: "buyerNumber",
  IS_GST_EXEMPT: "isGSTExempt",
  ITEM_LOCATION: "itemLocation",
  HAS_PICKUP_ADDRESS: "hasPickupAddress",
  HAS_BUYER_POSTAL_ADDRESS: "hasBuyerPostalAddress",
  HAS_VENDOR_POSTAL_ADDRESS: "hasVendorPostalAddress",

  VENDOR_COMMISSION: "vendorCommission",
  LISTING_FEE: "listingFee",
  BUYERS_PREMIUM: "buyersPremium",

  EFFECTIVE_VENDOR_COMMISSION_PERCENT: "effectiveVendorCommissionPercent",
  EFFECTIVE_VENDOR_COMMISSION_CENTS: "effectiveVendorCommissionCents",
  EFFECTIVE_LISTING_FEE_CENTS: "effectiveListingFeeCents",
  EFFECTIVE_BUYERS_PREMIUM_PERCENT: "effectiveBuyersPremiumPercent",
  EFFECTIVE_BUYERS_PREMIUM_CENTS: "effectiveBuyersPremiumCents",

  RESERVE_PRICE: "reservePrice",
  STARTING_BID: "startingBid",
  BID_INCREMENT: "bidIncrement",
  ORIGINAL_LISTING_PRICE: "originalListingPrice",

  CLEARING_SALE_CATEGORY: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "category",
  ),
  CLEARING_SALE_COMMENTS: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "comments",
  ),
  CLEARING_SALE_HOURS: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "hours",
  ),
  CLEARING_SALE_INSPECTION: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "inspection",
  ),
  CLEARING_SALE_ITEM_CONDITION: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "itemCondition",
  ),
  CLEARING_SALE_ITEM_TYPE: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "itemType",
  ),
  CLEARING_SALE_MAKE: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "make",
  ),
  CLEARING_SALE_MODEL: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "model",
  ),
  CLEARING_SALE_NEW_USED: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "newUsed",
  ),
  CLEARING_SALE_ODOMETER: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "odometer",
  ),
  CLEARING_SALE_PPSR_COMMENTS: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "ppsrComments",
  ),
  CLEARING_SALE_PPSR_STATUS: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "ppsrStatus",
  ),
  CLEARING_SALE_PUBLIC_DESCRIPTION: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "publicDescription",
  ),
  CLEARING_SALE_REGISTRATION: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "registration",
  ),
  CLEARING_SALE_SELL_SAFE_CATEGORY: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "sellSafeCategory",
  ),
  CLEARING_SALE_STATUS: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "status",
  ),
  CLEARING_SALE_SUB_TYPE: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "subType",
  ),
  CLEARING_SALE_TITLE: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "title",
  ),
  CLEARING_SALE_VIN: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "vin",
  ),
  CLEARING_SALE_YEAR: withNamespace(
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
    "year",
  ),
  LISTING_ID: "listingId",
  SALEYARD_NAME: "3c8a292f-8759-4f79-a76a-d9f5949e4bc3",
  XERO,
  LivestockSaleColId,
  Consignment: ConsignmentColId,
  DraftingAttributes: DraftingAttributesColId,
  SaleLot: SaleLotColId,
  BillingTags: BillingTagsColId,
  Issuer: IssuerColId,
  Recipient: RecipientColId,
  RuleBook: RuleBookColId,
  Email: EmailColId,
  Totals: TotalColId,
};

// These are importantly different to the general ones (eg Quantity must be called Quantity, not Hd) so
// keep them separate.
export const XeroColumnDisplayName = {
  [Column.XERO.CONTACT_NAME]: "ContactName",
  [Column.XERO.EMAIL_ADDRESS]: "EmailAddress",
  [Column.XERO.PO_ADDRESS_LINE1]: "POAddressLine1",
  [Column.XERO.PO_ADDRESS_LINE2]: "POAddressLine2",
  [Column.XERO.PO_ADDRESS_LINE3]: "POAddressLine3",
  [Column.XERO.PO_ADDRESS_LINE4]: "POAddressLine4",
  [Column.XERO.PO_CITY]: "POCity",
  [Column.XERO.PO_REGION]: "PORegion",
  [Column.XERO.PO_POSTAL_CODE]: "POPostalCode",
  [Column.XERO.PO_COUNTRY]: "POCountry",

  [Column.XERO.SA_ADDRESS_LINE1]: "SAAddressLine1",
  [Column.XERO.SA_ADDRESS_LINE2]: "SAAddressLine2",
  [Column.XERO.SA_ADDRESS_LINE3]: "SAAddressLine3",
  [Column.XERO.SA_ADDRESS_LINE4]: "SAAddressLine4",
  [Column.XERO.SA_CITY]: "SACity",
  [Column.XERO.SA_REGION]: "SARegion",
  [Column.XERO.SA_POSTAL_CODE]: "SAPostalCode",
  [Column.XERO.SA_COUNTRY]: "SACountry",

  [Column.XERO.INVOICE_NUMBER]: "InvoiceNumber",
  [Column.XERO.REFERENCE]: "Reference",
  [Column.XERO.INVOICE_DATE]: "InvoiceDate",
  [Column.XERO.DUE_DATE]: "DueDate",
  [Column.XERO.PLANNED_DATE]: "PlannedDate",
  [Column.XERO.TOTAL]: "Total",
  [Column.XERO.TAX_TOTAL]: "TaxTotal",

  [Column.XERO.INVOICE_AMOUNT_PAID]: "InvoiceAmountPaid",
  [Column.XERO.INVOICE_AMOUNT_DUE]: "InvoiceAmountDue",
  [Column.XERO.INVENTORY_ITEM_CODE]: "InventoryItemCode",
  [Column.XERO.DESCRIPTION]: "Description",
  [Column.XERO.QUANTITY]: "Quantity",
  [Column.XERO.UNIT_AMOUNT]: "UnitAmount",
  [Column.XERO.DISCOUNT]: "Discount",
  [Column.XERO.LINE_AMOUNT]: "LineAmount",
  [Column.XERO.ACCOUNT_CODE]: "AccountCode",
  [Column.XERO.TAX_TYPE]: "TaxType",
  [Column.XERO.TAX_AMOUNT]: "TaxAmount",
  [Column.XERO.TRACKING_NAME_1]: "TrackingName1",
  [Column.XERO.TRACKING_OPTION_1]: "TrackingOption1",
  [Column.XERO.TRACKING_NAME_2]: "TrackingName2",
  [Column.XERO.TRACKING_OPTION_2]: "TrackingOption2",
  [Column.XERO.CURRENCY]: "Currency",
  [Column.XERO.TYPE]: "Type",
  [Column.XERO.BRANDING_THEME]: "BrandingTheme",
};

export const LivestockSaleColumnDisplayName = {
  [LivestockSaleColId.TITLE]: "Livestock Sale",
};

export const ColumnDisplayName = {
  [Column.AGE_GROUP_NAME]: "Age",
  [Column.NOT_ANGUS_VERIFIED_COUNT]: "Not Angus Verified Count",
  [Column.ATTACHMENTS]: "Attachments",
  [Column.AVERAGE_WEIGHT]: "Avg. Wt.",
  [Column.AVERAGE_WEIGHT_OUTLIER]: "In Range?",
  [Column.BID_INCREMENT]: "Bid Inc",
  [Column.BIDDER_REGO_NUMBER]: "Bidder #",
  [Column.BRANCH]: "Branch",
  [Column.BREED_NAME]: "Breed",
  [Column.BUYER_NAME]: "Buyer Name",
  [Column.BUYER_NUMBER]: "Buyer #",
  [Column.BUYER_PAYMENTS]: "Buyer Payments",
  [Column.BUYER_SHORT_CODE_SALEYARD]: "SY Ref",
  [Column.BUYER_SHORT_CODE]: "Agent Ref",
  [Column.BUYER_WAY_NAME]: "Buyer Way",
  [Column.BUYERS_PREMIUM]: "B Prem",
  [Column.CARRIER]: "Carrier",
  [Column.CATEGORY_NAME]: "Category",
  [Column.CLEARING_SALE_CATEGORY]: "Category",
  [Column.CLEARING_SALE_COMMENTS]: "Comments",
  [Column.CLEARING_SALE_HOURS]: "Hours",
  [Column.CLEARING_SALE_INSPECTION]: "Inspection",
  [Column.CLEARING_SALE_ITEM_CONDITION]: "Condition",
  [Column.CLEARING_SALE_ITEM_TYPE]: "Type",
  [Column.CLEARING_SALE_MAKE]: "Make",
  [Column.CLEARING_SALE_MODEL]: "Model",
  [Column.CLEARING_SALE_NEW_USED]: "New/Used",
  [Column.CLEARING_SALE_ODOMETER]: "Odometer",
  [Column.CLEARING_SALE_PPSR_COMMENTS]: "PPSR Comments",
  [Column.CLEARING_SALE_PPSR_STATUS]: "PPSR Status",
  [Column.CLEARING_SALE_PUBLIC_DESCRIPTION]: "Public Description",
  [Column.CLEARING_SALE_REGISTRATION]: "Registration",
  [Column.CLEARING_SALE_SELL_SAFE_CATEGORY]: "Sell Safe",
  [Column.CLEARING_SALE_STATUS]: "Status",
  [Column.CLEARING_SALE_SUB_TYPE]: "Sub Type",
  [Column.CLEARING_SALE_TITLE]: "Title",
  [Column.CLEARING_SALE_VIN]: "VIN",
  [Column.CLEARING_SALE_YEAR]: "Year",
  [Column.LISTING_ID]: "Listing ID",
  [Column.CONSIGNED_FROM]: "Consigned From",
  [Column.CURRENT_PIC]: "Current PIC",
  [Column.DELIVERY_PEN]: "Delivery Pen",
  [Column.DESTINATION_PIC]: "Buyer PIC",
  [Column.DIFF]: "Diff",
  [Column.DRAFT_DEVICE]: "Draft Device",
  [Column.DRAFT_NAME]: "Draft Name",
  [Column.EFFECTIVE_BUYERS_PREMIUM_CENTS]: "B Prem",
  [Column.EFFECTIVE_BUYERS_PREMIUM_PERCENT]: "B Prem %",
  [Column.EFFECTIVE_LISTING_FEE_CENTS]: "List Fee",
  [Column.EFFECTIVE_VENDOR_COMMISSION_CENTS]: "Vendor Comm.",
  [Column.EFFECTIVE_VENDOR_COMMISSION_PERCENT]: "Vendor Comm. %",
  [Column.EID]: "EID",
  [Column.EU_STATUS]: "EU",
  [Column.EXEMPTION_REASON]: "Exemption Reason",
  [Column.GRADE_NAME]: "Grade",
  [Column.HAS_BUYER_POSTAL_ADDRESS]: "Buyer Addr.",
  [Column.HAS_EXCEPTIONS]: "Has Exceptions",
  [Column.HAS_PICKUP_ADDRESS]: "Pickup Addr.",
  [Column.HAS_VENDOR_POSTAL_ADDRESS]: "Vendor Addr.",
  [Column.XERO_VENDOR]: "Vendor (Xero)",
  [Column.XERO_VENDOR_NAME]: "Vendor Name (Xero)",
  [Column.XERO_BUYER]: "Buyer (Xero)",
  [Column.XERO_BUYER_NAME]: "Buyer Name (Xero)",
  [Column.XERO_TARGET]: "Name (Xero)",
  [Column.IMAGES]: "Images",
  [Column.IS_ANGUS_VERIFIED]: "Angus Verified",
  [Column.IS_GST_EXEMPT]: "GST Exempt",
  [Column.IS_MANUALLY_WEIGHED]: "Manually Weighed",
  [Column.IS_SELLABLE]: "Is Sellable",
  [Column.IS_TAKEABLE]: "Is Takeable",
  [Column.ITEM_LOCATION]: "Item Location",
  [Column.LABELS]: "Labels",
  [Column.LIFETIME_TRACEABILITY]: "LT",
  [Column.LISTING_FEE]: "List Fee",
  [Column.LOT_NUMBER]: "Lot",
  [Column.LOT_NUMBER_SUFFIX]: "Lot# Suffix",
  [Column.LOT_NUMBER_COMBINED]: "Lot#",
  [Column.MARKS]: "Marks",
  [Column.NLIS_ID]: "NLIS Id",
  [Column.NLIS_SELL_ID]: "NLIS Sell ID",
  [Column.NLIS_SELL_STATUS]: "NLIS Sell Status",
  [Column.NLIS_TAKE_ID]: "NLIS Take ID",
  [Column.NLIS_TAKE_STATUS]: "NLIS Take Status",
  [Column.NOTE]: "Note",
  [Column.NOMINATION]: "Nomination",
  [Column.NOMINATION_HC]: "Nominated Hd",
  [Column.NOMINATION_CONSIGNING_DEPLOYMENT]: "Consigning Deployment",
  [Column.NOMINATION_DETAILS]: "Nomination Ref",
  [Column.NOMINATION_VENDOR]: "Vendor",
  [Column.NOMINATION_HAS_ARRIVED]: "Has Arrived",
  [Column.NVD]: "NVD",
  [Column.OPEN_AUCTION_COUNT]: "OA",
  [Column.OVERFLOW_PEN]: "Overflow Pen",
  [Column.ORIGINAL_LISTING_PRICE]: "Original Price",
  [Column.PEN]: "Pen",
  [Column.PRICING_TYPE]: "Pricing Type",
  [Column.QUANTITY_DELIVERED]: "Delivered",
  [Column.QUANTITY_PROGENY]: "Progeny",
  [Column.RESERVE_PRICE]: "Res Price",
  [Column.AGENT_RELATIONSHIP]: "Agent",
  [Column.SALE_ROUND_NAME]: "Sale Round",
  [Column.SCAN_ERP_STATUS]: "ERP",
  [Column.SEX_NAME]: "Sex",
  [Column.SINGLE_WEIGH_SUM]: "Single Weigh Sum",
  [Column.SINGLE_WEIGH_DIFF]: "Single Weigh Diff",
  [Column.SINGLE_WEIGH_SUM_OUTLIER]: "In Range?",
  [Column.STARTING_BID]: "Start Bid",
  [Column.STATUS]: "Status",
  [Column.TARGET_BUSINESS_NAME]: "Name (AgriNous)",
  [Column.THIRD_PARTY_NAME]: "Third Party",
  [Column.TIME_BULK_WEIGHED]: "Bulk Weigh Time",
  [Column.SCAN_TIME_WEIGHED]: "Weigh Time",
  [Column.TOTALS]: "Totals",
  [Column.TOTAL_MASS_GRAMS]: "Weight (Kg)",
  [Column.TOTAL_MASS_GRAMS_OUTLIER]: "In Range?",
  [Column.TOTAL_PRICE]: "Total Price",
  [Column.UNIT_PRICE]: "Unit Price",
  [Column.VENDOR_COMMISSION]: "Vendor Comm.",
  [Column.VENDOR_PIC]: "Vendor PIC",
  [Column.WEIGHED_COUNT]: "Weighed",
  [Column.WEIGHT]: "Tot. Wt.",
  [Column.WEIGHT_OUTLIER]: "In Range?",
};

export const LIVESTOCK_AGENT_COLUMNS_BLACKLIST = [Column.AGENCY_NAME];

const BUSINESS_COLUMNS_BLACKLIST = [
  Column.BUYER_SHORT_CODE_SALEYARD,
  Column.BUYER_SHORT_CODE,
  Column.LOT_NUMBER,
  Column.SCANNED_COUNT,
  Column.SCANNED_PERCENTAGE,
  Column.VENDOR_NAME,
  Column.VENDOR_SHORT_CODE,
  Column.VENDOR_SHORT_CODE_SALEYARD,
];

export const COMMERCIAL_COLUMNS_BLACKLIST = BUSINESS_COLUMNS_BLACKLIST;

export const COMPLIANCE_COLUMNS_BLACKLIST = BUSINESS_COLUMNS_BLACKLIST.concat([
  Column.TOTAL_PRICE,
  Column.UNIT_PRICE,
]);
