import React, { memo, useMemo } from "react";

import { useField } from "formik";
import * as Yup from "yup";

import DeploymentBusinessTable from "components/BusinessForm/DeploymentBusinessTable";
import { withNamespace } from "components/Form/FormikControls";

import {
  DeploymentEmailRecipientsHeader,
  DeploymentEmailRecipientsTable,
} from "./BusinessEmailRecipientsTable";
import { deploymentEmailRecipientBaseValidation } from "./DeploymentEmailRecipientForm";

export const deploymentEmailRecipientValidationFactory = (businessUsers = []) =>
  Yup.lazy(value =>
    Yup.object().shape({
      editingValue: deploymentEmailRecipientBaseValidation
        .shape({
          email: Yup.string()
            .email("Invalid Email")
            .required("Required")
            .test("is-email-unique", "That email already exists", email => {
              // Test the value in the email field against all of the Master Business's business users as
              // well as all of the other DeploymentEmailRecipients in the dirty Formik state
              const emailIdPairList = [
                ...(businessUsers || []),
                ...value.values,
              ].map(({ email, id }) => ({ email, id }));

              return (
                // When there is an email address entered
                Boolean(email) &&
                emailIdPairList
                  // Find all of the emails that match the entered value
                  .filter(emailId => emailId.email === email.trim())
                  // and check that every match is the existing entry
                  .every(emailId => emailId.id === value.editingValue.id)
              );
            }),
        })
        .nullable(),
    }),
  );

const DeploymentBusinessEmailRecipientsComponent = ({
  businessUsers,
  masterBusinessId,
  primaryContactEmail,
  namespace: ns,
  isOpen,
  onEndEditing,
  onStartEditing,
  onSetPrimaryContactEmail,
  onToggle,
}) => {
  const emailRecipientsNs = withNamespace(ns, "emailRecipients");
  const emailRecipients = useField(emailRecipientsNs)[0].value;

  const validationSchema = useMemo(
    () => deploymentEmailRecipientValidationFactory(businessUsers),
    [businessUsers],
  );

  return (
    <DeploymentBusinessTable
      headerComponent={DeploymentEmailRecipientsHeader}
      initialValuesList={emailRecipients}
      isOpen={isOpen}
      masterBusinessId={masterBusinessId}
      primaryContactEmail={primaryContactEmail}
      namespace={emailRecipientsNs}
      onEndEditing={onEndEditing}
      onStartEditing={onStartEditing}
      onSetPrimaryContactEmail={onSetPrimaryContactEmail}
      onToggle={onToggle}
      tableComponent={DeploymentEmailRecipientsTable}
      validationSchema={validationSchema}
      dataTour="emailCollapse"
    />
  );
};

export default memo(DeploymentBusinessEmailRecipientsComponent);
