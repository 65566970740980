import React, { useMemo } from "react";

import { uniqBy } from "lodash/array";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";

import TopBuyerGrid from "components/AuctionPenSaleView/BuyerGrid";
import { Column } from "components/Layout";

import { Species } from "constants/species";

import { isBusinessActive } from "lib/businesses";
import { caseInsensitiveCompare } from "lib/compare";
import { getLivestockSaleId } from "lib/navigation";

import {
  getAgencyBusinessById,
  selectPropertyEnrichedBusinessByBusinessIdLookup,
  selectRecentBuyers,
} from "selectors";

import BuyerDetail from "./BuyerDetail";
import BuyerWays from "./BuyerWays";

const BuyerSelect = ({
  agencyId,
  buyerId,
  buyerWayId,
  setBuyerValues,
  onOpenAddBuyerWay,
  disabled,
  speciesId,
}) => {
  const businesses = useSelector(
    selectPropertyEnrichedBusinessByBusinessIdLookup,
  );
  const recentBuyers = useSelector(selectRecentBuyers);

  const businessesList = useMemo(() => Object.values(businesses), [businesses]);
  const recentBuyersList = useMemo(
    () => Object.values(recentBuyers),
    [recentBuyers],
  );

  const buyer = useSelector(getAgencyBusinessById(buyerId, agencyId));

  const topBuyers = useMemo(() => {
    // Show top buyers and recently used buyers.
    const topBuyerFilter = Species.topBuyerFilter(speciesId);

    return uniqBy(
      [
        ...businessesList
          .filter(business => isBusinessActive(business, getLivestockSaleId()))
          .filter(topBuyerFilter)

          .sort((n0, n1) => caseInsensitiveCompare(n0.name, n1.name)),
        ...recentBuyersList
          .filter(business => isBusinessActive(business, getLivestockSaleId()))
          .filter(b => !topBuyerFilter(b))
          .sort((n0, n1) => caseInsensitiveCompare(n0.name, n1.name)),
      ],
      "id",
    );
  }, [businessesList, recentBuyersList, speciesId]);

  const { buyerWays, limited, name } = buyer || {};

  if (!buyerId) {
    return (
      <Column>
        <TopBuyerGrid setBuyerValues={setBuyerValues} buyers={topBuyers} />
      </Column>
    );
  }

  if (!buyerWayId) {
    return (
      <>
        <BuyerDetail
          disabled={disabled}
          unsetDetail={() =>
            setBuyerValues({
              buyerId: null,
              buyerWayId: null,
              propertyId: null,
            })
          }
          value={name}
        />
        {!disabled && (
          <BuyerWays
            disabled={disabled}
            ways={buyerWays || []}
            setBuyerWayId={buyerWayId =>
              setBuyerValues({
                buyerWayId,
              })
            }
            onClickAddBuyerWay={onOpenAddBuyerWay}
            loading={limited}
          />
        )}
      </>
    );
  }

  const selectedBuyerWay = buyerWays?.find(way => way.id === buyerWayId);

  return (
    <div>
      <BuyerDetail
        disabled={disabled}
        unsetDetail={() =>
          setBuyerValues({ buyerId: null, buyerWayId: null, propertyId: null })
        }
        value={buyer ? name : ""}
      />
      <BuyerDetail
        disabled={disabled}
        way
        unsetDetail={() => setBuyerValues({ buyerWayId: null })}
        value={selectedBuyerWay ? selectedBuyerWay.name : ""}
      />
    </div>
  );
};

BuyerSelect.propTypes = {
  agencyId: PropTypes.number,
  buyerId: PropTypes.string,
  buyerWayId: PropTypes.number,
  setBuyerValues: PropTypes.func,
  onOpenAddBuyerWay: PropTypes.func,
  disabled: PropTypes.bool,
  speciesId: PropTypes.number.isRequired,
};

export default BuyerSelect;
