import queryString from "query-string";

import { PAYMENT } from "constants/actionTypes";

import { serializePayment } from "lib/serializers/payments";
import { convertDateTimeToUTCDateTime } from "lib/timeFormats";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({
  changesSince,
  livestockSaleId,
  id,
  action,
  livestockSalesIdIn,
  createdGte,
  createdLte,
} = {}) => {
  return queryString.stringifyUrl(
    {
      url: urlCheckAndAddIdAndAction(`/v2/payments/`, id, action),
      query: {
        changesSince,
        livestock_sale_id: livestockSaleId,
        deployment_sale__livestock_sale__id__in: livestockSalesIdIn,
        created__gte: createdGte
          ? convertDateTimeToUTCDateTime(createdGte)
          : undefined,
        created__lte: createdLte
          ? convertDateTimeToUTCDateTime(createdLte)
          : undefined,
      },
    },
    { arrayFormat: "comma" },
  );
};

export const PaymentAction = offlineActionCreator(
  PAYMENT,
  serializePayment,
  urlCreator,
  false,
);
