import initHelpHero from "helphero";

import { isHelpHeroActive, getHelpHeroKey } from "./config";

let helpHero = null;

export function getHelpHero() {
  return helpHero;
}

if (isHelpHeroActive) {
  helpHero = initHelpHero(getHelpHeroKey());
}
