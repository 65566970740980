import React from "react";

import { useFormikContext } from "formik";

import {
  LivestockSalePricingTypeDisplayNameLkp,
  PricingTypes,
} from "constants/pricingTypes";

import { Input } from "./Input";

export const UnitPrice = ({ disabled, autoFocus, name }) => {
  const formikContext = useFormikContext();

  const { pricingTypeId } = formikContext.values;

  const pricingTypeAttributesLookup = {
    [PricingTypes.PER_KILO]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.PER_KILO],
      afterSymbol: "¢",
    },
    [PricingTypes.GROSS]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.GROSS],
      beforeSymbol: "$",
    },
    [PricingTypes.PER_HEAD]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.PER_HEAD],
      beforeSymbol: "$",
    },
  };

  const pricingTypeAttributs = pricingTypeAttributesLookup[pricingTypeId];

  return (
    <Input
      name={name}
      type="number"
      disabled={disabled}
      autoFocus={autoFocus}
      decimal
      {...pricingTypeAttributs}
    />
  );
};
