import { isArray, isEmpty, isObject, uniq } from "lodash";
import { createSelector } from "reselect";

import {
  getFieldsWithAlternativeValues,
  getNamespacedCompareFieldNames,
} from "components/BusinessForm/lib";

import {
  AlternativeComparableKeys,
  AlternativeType,
} from "constants/businesses";
import { IntegrationTypes } from "constants/integrations";

import { getDateFromISO8601DateString } from "lib/timeFormats";

import {
  getActiveLivestockAgentDeployment,
  getBusinesses,
  getDeployments,
  getProperties,
  getSaleyards,
  selectBuyerIdBySaleLotIdLookup,
  selectorCategorisedBusinessIdsFromCurrentSaleManualAdjustments,
  selectorInvoiceToBusinessIdBySaleLotIdLookup,
  selectorThirdPartyBusinessIdBySaleLotIdLookup,
  selectResolvedInterestSettingsByBusinessIdLookup,
  selectVendorCommissionBandsByDeploymentBusinessIdLookup,
  selectVendorIdBySaleLotIdLookup,
} from "selectors";

export const BusinessTypes = {
  BUYER: "Buyer",
  INVOICEE: "Invoicee",
  VENDOR: "Vendor",
  TRANSPORTER: "Transporter",
  THIRD_PARTY: "Third Party",
  SUNDRY_TO: "Sundry To",
  SUNDRY_FROM: "Sundry From",
};

const selectBusinessesIdsInSaleByType = createSelector(
  [
    selectVendorIdBySaleLotIdLookup,
    selectBuyerIdBySaleLotIdLookup,
    selectorInvoiceToBusinessIdBySaleLotIdLookup,
    selectorThirdPartyBusinessIdBySaleLotIdLookup,
    selectorCategorisedBusinessIdsFromCurrentSaleManualAdjustments,
  ],
  (
    vendorIdBySaleLotIdLookup,
    buyerIdBySaleLotIdLookup,
    invoiceToBusinessIdBySaleLotIdLookup,
    thirdPartyBusinessIdBySaleLotIdLookup,
    categorisedBusinessIdsFromCurrentSaleManualAdjustments,
  ) => {
    return {
      [BusinessTypes.BUYER]: uniq(Object.values(buyerIdBySaleLotIdLookup)),
      [BusinessTypes.INVOICEE]: uniq(
        Object.values(invoiceToBusinessIdBySaleLotIdLookup),
      ),
      [BusinessTypes.THIRD_PARTY]: uniq(
        Object.values(thirdPartyBusinessIdBySaleLotIdLookup),
      ),
      [BusinessTypes.VENDOR]: uniq(Object.values(vendorIdBySaleLotIdLookup)),
      [BusinessTypes.SUNDRY_FROM]: uniq(
        categorisedBusinessIdsFromCurrentSaleManualAdjustments.from.filter(
          Boolean,
        ),
      ),
      [BusinessTypes.SUNDRY_TO]: uniq(
        categorisedBusinessIdsFromCurrentSaleManualAdjustments.to.filter(
          Boolean,
        ),
      ),
    };
  },
);

export const expandAlternatives = (obj, deploymentsLookup, saleyardsLookup) => {
  if (obj.alternatives) {
    obj.alternatives = obj.alternatives.map(alternative => {
      if (alternative.source.type === AlternativeType.Deployment) {
        return Object.assign({}, alternative, {
          lastModified: alternative.lastModified
            ? getDateFromISO8601DateString(alternative.lastModified)
            : null,
          sourceName: deploymentsLookup[alternative.source.id]?.name,
        });
      } else {
        return Object.assign({}, alternative, {
          lastModified: alternative.lastModified
            ? getDateFromISO8601DateString(alternative.lastModified)
            : null,
          sourceName: saleyardsLookup[alternative.source.id]?.name,
        });
      }
    });
  }
  Object.keys(obj).forEach(key => {
    if (isArray(obj[key])) {
      obj[key] = obj[key].map(k =>
        isObject(k)
          ? expandAlternatives(k, deploymentsLookup, saleyardsLookup)
          : k,
      );
    }
  });

  return obj;
};

export const selectBusinessesInSaleByIdLookup = createSelector(
  [
    getBusinesses,
    getProperties,
    selectBusinessesIdsInSaleByType,
    getDeployments,
    getSaleyards,
    selectResolvedInterestSettingsByBusinessIdLookup,
    selectVendorCommissionBandsByDeploymentBusinessIdLookup,
    getActiveLivestockAgentDeployment,
  ],
  (
    businesses,
    properties,
    businessIdsByType,
    deploymentsLookup,
    saleyardsLookup,
    resolvedInterestSettingsByBusinessId,
    vendorCommissionsByDeploymentBusinessId,
    activeLivestockAgentDeployment,
  ) => {
    const result = {};

    Object.entries(businessIdsByType).forEach(([businessType, businessIds]) => {
      businessIds.forEach(businessId => {
        const business = expandAlternatives(
          { ...(businesses[businessId] || {}) },
          deploymentsLookup,
          saleyardsLookup,
        );

        if (isEmpty(business)) {
          return;
        }

        const lastModified = business.lastModified
          ? getDateFromISO8601DateString(business.lastModified)
          : null;
        const lastReviewed = business.lastReviewed
          ? getDateFromISO8601DateString(business.lastReviewed)
          : null;

        const hasAlternativeNewerThanReviewed = business.alternatives.some(
          altBusiness => altBusiness.lastModified > lastReviewed,
        );
        const hasAlternativeNewerThanModified = business.alternatives.some(
          altBusiness => altBusiness.lastModified > lastModified,
        );

        const outOfSyncFields = [].concat(
          getFieldsWithAlternativeValues(AlternativeComparableKeys, business),
          ...business.properties.map((property, idx) =>
            getFieldsWithAlternativeValues(
              getNamespacedCompareFieldNames(
                "properties",
                AlternativeComparableKeys,
                idx,
              ),
              property,
            ),
          ),
          ...business.emailRecipients.map((emailRecipient, idx) =>
            getFieldsWithAlternativeValues(
              getNamespacedCompareFieldNames(
                "emailRecipients",
                AlternativeComparableKeys,
                idx,
              ),
              emailRecipient,
            ),
          ),
        );

        if (businessId in result) {
          result[businessId].roleInSale.push(businessType);
        } else {
          result[businessId] = {
            business,
            roleInSale: [businessType],
            outOfSyncFields,
            lastModified,
            lastReviewed,
            hasAlternativeNewerThanReviewed,
            hasAlternativeNewerThanModified,
            xeroIntegrationBusiness: business.integrationBusinesses?.find(
              ib => ib.type === IntegrationTypes.Xero,
            ),
            deployment: activeLivestockAgentDeployment,
            interestSettings: resolvedInterestSettingsByBusinessId[businessId],
            vendorCommissionBands:
              vendorCommissionsByDeploymentBusinessId[
                business?.deploymentBusinessId
              ] || [],
          };
        }
      });
    });
    return result;
  },
);

export const getBusinessesInSaleById = businessId => state =>
  selectBusinessesInSaleByIdLookup(state)[businessId];

export const selectBusinessesInSaleAggridData = createSelector(
  [selectBusinessesInSaleByIdLookup],
  businessesInSaleLookup => Object.values(businessesInSaleLookup),
);
