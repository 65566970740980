import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { PenScanLotAction } from "actions";

import { ProgenyCount } from "components/AuctionPens/Cards/PrimaryInfoSet";
import Badge from "components/Badge";
import { MarkChips } from "components/Chip";
import { UnresolvedCommentIcon } from "components/Comments/Icon";
import { SmallFaIcon } from "components/FaIcon";
import { Button, SecondaryButton } from "components/Form";
import { OpenIndicator } from "components/Icons/CollapseIcon";
import { Column, Row } from "components/Layout";
import { DialogActions, DialogContent } from "components/MaterialDialog";
import { LotActionButton } from "components/ScanningPenCards/Card/ActionButton";

import { PenTypes } from "constants/auctionPens";
import { ModalTypes } from "constants/navigation";
import { PenScanLotStatusColors } from "constants/penScanLots";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { caseInsensitiveCompare } from "lib/compare";
import { pluralize } from "lib/pluralize";
import { getProgenyDisplayCount } from "lib/saleLot";

import {
  getAuctionPenById,
  getBusinessById,
  getConsignmentById,
  getContextByModalType,
  getPenScanLotById,
  getPenScanLotSaleLotData,
  getReceivalLotById,
  getSaleLotById,
  getSaleLotMappingGroupByIndex,
  getSaleLotMappingGroupsByPenScanLotId,
  getScanByEid,
  getScanMappingByIndex,
  getUnresolvedCommentCountByPenScanLotId,
  selectMappingStatusByPenScanLotIdLookup,
} from "selectors";

import { useToggle } from "hooks";

function Footer(props) {
  const { close } = props;

  const dispatch = useDispatch();

  function onClickAllocate() {
    dispatch(PenScanLotAction.allocateRequest());
    close();
  }

  return (
    <DialogActions>
      <SecondaryButton onClick={close}>Close</SecondaryButton>
      <Button onClick={onClickAllocate} type="button">
        Allocate Scans
      </Button>
    </DialogActions>
  );
}

function UnmappableScan(props) {
  const { isExpanded, mappingIndex, penScanLotId, scanMappingIndex } = props;

  const { eid } =
    useSelector(
      getScanMappingByIndex(penScanLotId, mappingIndex, scanMappingIndex),
    ) || {};

  const scan = useSelector(getScanByEid(eid)) || {};

  const receivalLot =
    useSelector(getReceivalLotById(scan.receival_lot_id)) || {};
  const receivalConsignment =
    useSelector(getConsignmentById(receivalLot.consignmentId)) || {};
  const receivalLotPen =
    useSelector(getAuctionPenById(receivalLot.receivalPenId)) || {};
  const receivalVendor = useSelector(
    getBusinessById(receivalConsignment.vendor_id),
  );

  return (
    <tr className={`data-row scan ${isExpanded ? "expanded" : "collapsed"}`}>
      <td>
        <div>
          <SmallFaIcon
            icon={faExclamationCircle}
            color="warning"
            title="This Scan cannot be allocated automatically."
          />
        </div>
      </td>
      <td>
        <div>{eid}</div>
      </td>
      <td colSpan={5}>
        <div />
      </td>
      <td>
        <div>{getAuctionPenDisplayName(receivalLotPen, "-")}</div>
      </td>
      <td>
        <div>{receivalVendor?.name || ""}</div>
      </td>
      <td>
        <div>
          <Row>
            <MarkChips marks={receivalLot.marks} />
          </Row>
        </div>
      </td>
      <td>
        <div />
      </td>
    </tr>
  );
}

function ScanMapping(props) {
  const { isExpanded, mappingIndex, penScanLotId, scanMappingIndex } = props;

  const { newSaleLotId, existingSaleLotId, eid } =
    useSelector(
      getScanMappingByIndex(penScanLotId, mappingIndex, scanMappingIndex),
    ) || [];
  const scan = useSelector(getScanByEid(eid)) || {};

  const newSaleLot =
    useSelector(state => getPenScanLotSaleLotData(state)[newSaleLotId]) || {};

  const receivalLot =
    useSelector(getReceivalLotById(scan.receival_lot_id)) || {};
  const receivalConsignment =
    useSelector(getConsignmentById(receivalLot.consignmentId)) || {};
  const receivalLotPen =
    useSelector(getAuctionPenById(receivalLot.receivalPenId)) || {};
  const receivalVendor = useSelector(
    getBusinessById(receivalConsignment.vendor_id),
  );

  const saleLot =
    useSelector(getSaleLotById(existingSaleLotId)) || newSaleLot || {};
  const saleLotConsignment =
    useSelector(getConsignmentById(saleLot.consignment_id)) || {};
  const saleLotPen =
    useSelector(getAuctionPenById(saleLot.auction_pen_id)) || {};
  const saleLotVendor = useSelector(
    getBusinessById(saleLotConsignment.vendor_id),
  );
  const saleLotBuyer = useSelector(getBusinessById(saleLot.buyer_id));

  return (
    <tr className={`data-row scan ${isExpanded ? "expanded" : "collapsed"}`}>
      <td>
        <div>
          {existingSaleLotId ? (
            <SmallFaIcon
              icon={faCheck}
              color="primary"
              title="This Scan will be added to an existing Sale Lot."
            />
          ) : (
            <SmallFaIcon
              icon={faPlus}
              color="success"
              title="A new Sale Lot is will be created in order to allocate this Scan."
            />
          )}
        </div>
      </td>
      <td>
        <div>{eid}</div>
      </td>
      <td>
        <div>
          {scanMappingIndex === 0
            ? getAuctionPenDisplayName(saleLotPen, "")
            : null}
        </div>
      </td>
      <td>
        <div>
          {scanMappingIndex === 0 ? (
            <>
              {saleLot.quantity}{" "}
              {saleLot.quantityProgeny > 0 && (
                <ProgenyCount>
                  {getProgenyDisplayCount(saleLot.quantityProgeny)}
                </ProgenyCount>
              )}
            </>
          ) : null}
        </div>
      </td>
      <td>
        <div>
          {scanMappingIndex === 0 ? (
            <Row>
              <MarkChips marks={saleLot.marks} />
            </Row>
          ) : null}
        </div>
      </td>
      <td>
        <div>{scanMappingIndex === 0 ? saleLotVendor?.name || "" : null}</div>
      </td>
      <td>
        <div>{scanMappingIndex === 0 ? saleLotBuyer?.name || "" : null}</div>
      </td>

      <td>
        <div>{getAuctionPenDisplayName(receivalLotPen, "-")}</div>
      </td>
      <td>
        <div>{receivalVendor?.name || ""}</div>
      </td>
      <td>
        <div>
          <Row>
            <MarkChips marks={receivalLot.marks} />
          </Row>
        </div>
      </td>
    </tr>
  );
}

function PenScanEidTbody(props) {
  const { penScanLotId, mappingGroup, isExpanded } = props;

  const scanMappings =
    useSelector(getSaleLotMappingGroupByIndex(penScanLotId, mappingGroup)) ||
    [];

  return scanMappings.map((scanMapping, index) => {
    const MappingComponent =
      scanMapping.newSaleLotId || scanMapping.existingSaleLotId
        ? ScanMapping
        : UnmappableScan;
    return (
      <MappingComponent
        penScanLotId={penScanLotId}
        mappingIndex={mappingGroup}
        key={scanMapping.eid}
        scanMappingIndex={index}
        isExpanded={isExpanded}
      />
    );
  });
}

function PenScanEidGroupTbody(props) {
  const { penScanLotId, isExpanded } = props;

  const mappingGroups =
    useSelector(getSaleLotMappingGroupsByPenScanLotId(penScanLotId)) || [];

  return (
    <tbody>
      <tr
        className={`heading-row scan ${isExpanded ? "expanded" : "collapsed"}`}
      >
        <th colSpan={2}>
          <div />
        </th>
        <th colSpan={6}>
          <div>Sale Lot</div>
        </th>
        <th colSpan={4}>
          <div>Receival Scan</div>
        </th>
      </tr>
      <tr
        className={`heading-row scan ${isExpanded ? "expanded" : "collapsed"}`}
      >
        <th colSpan={2}>
          <div />
        </th>
        <th>
          <div>Pen</div>
        </th>
        <th>
          <div>Hd</div>
        </th>
        <th>
          <div>Marks</div>
        </th>
        <th>
          <div>Vendor</div>
        </th>
        <th>
          <div>Buyer</div>
        </th>
        <th>
          <div>Pen</div>
        </th>
        <th>
          <div>Vendor</div>
        </th>
        <th>
          <div>Marks</div>
        </th>
      </tr>
      {mappingGroups.map((_ignored, index) => (
        <PenScanEidTbody
          key={index}
          penScanLotId={penScanLotId}
          isExpanded={isExpanded}
          mappingGroup={index}
        />
      ))}
    </tbody>
  );
}

function PenScanLotSummary(props) {
  const { penScanLotId } = props;
  const [isExpanded, toggleExpanded] = useToggle(false);

  const penScanLot = useSelector(getPenScanLotById(penScanLotId)) || {};
  const sellingPen = useSelector(getAuctionPenById(penScanLot.sellingPenId));
  const status = useSelector(
    state => selectMappingStatusByPenScanLotIdLookup(state)[penScanLotId],
  );

  const unresolvedCommentCount = useSelector(
    getUnresolvedCommentCountByPenScanLotId(penScanLotId),
  );

  const mappingGroups =
    useSelector(getSaleLotMappingGroupsByPenScanLotId(penScanLotId)) || [];

  const scanCount = mappingGroups.reduce(
    (count, mappingGroup) => count + mappingGroup.length,
    0,
  );
  if (!scanCount) {
    return null;
  }

  return (
    <>
      <tbody>
        <tr className="data-row pen-scan">
          <td>
            <Badge color={PenScanLotStatusColors[status]}>{status}</Badge>
          </td>
          <td>
            {scanCount} {pluralize("Scan", scanCount)}
          </td>
          <td>{getAuctionPenDisplayName(sellingPen)}</td>
          <td>{penScanLot.quantity}</td>
          <td>
            <Row>
              <MarkChips marks={penScanLot.marks} />
            </Row>
          </td>
          <td colSpan={4} />
          <td>
            <Column alignEnd>
              <UnresolvedCommentIcon
                unresolvedCommentCount={unresolvedCommentCount}
                size="2x"
              />
            </Column>
          </td>
          <td>
            <Column alignEnd>
              <LotActionButton
                scanLotId={penScanLotId}
                penType={PenTypes.SELLING}
              />
            </Column>
          </td>
          <td>
            {scanCount > 0 && (
              <OpenIndicator onClick={toggleExpanded} isOpen={isExpanded} />
            )}
          </td>
        </tr>
      </tbody>
      {mappingGroups.length > 0 && (
        <PenScanEidGroupTbody
          penScanLotId={penScanLotId}
          isExpanded={isExpanded}
        />
      )}
    </>
  );
}

const Table = styled.table(
  ({ theme }) => `
  border-spacing: ${theme.space[0]}px ${theme.space[0]}px; 
  height: 100%;
  width: 100%;

  tr.data-row {
    background-color: ${theme.colors.white};
  }

  tr.heading-row.pen-scan th,
  tr.heading-row.scan.expanded th {
    border-right: 1px solid ${theme.colors.surfaceBorder};
    border-top: 1px solid ${theme.colors.surfaceBorder};
  }
  tr.heading-row.pen-scan > :first-child,
  tr.heading-row.scan.expanded > :first-child,
  tr.data-row.pen td:first-child,
  tr.data-row.scan td:first-child  {
     border-left: 1px solid ${theme.colors.surfaceBorder};
   }

  tr.data-row > td:last-child {
    border-right: 1px solid ${theme.colors.surfaceBorder};
  }

  tr.heading-row:last-of-type > th {
    border-bottom: 1px solid ${theme.colors.surfaceBorder};
  }

  tr.data-row.pen-scan td,
  tr.data-row.scan.expanded td {
    border-right: 1px solid ${theme.colors.surfaceBackground};
    border-top: 1px solid ${theme.colors.surfaceBackground};
  }
  
  tr.data-row.expanded:last-child > td {
    border-bottom: 1px solid ${theme.colors.surfaceBorder};
  }

  tr.heading-row.pen-scan th,
  tr.data-row.pen-scan td, 
  tr.heading-row.scan.expanded th,
  tr.data-row.scan.expanded td {
    padding: ${theme.space[1]}px ${theme.space[2]}px;
  }  

  tr.data-row.scan.collapsed td 
  tr.heading-row.scan.collapsed th {
    border: none;
    padding: 0 0;
  } 

  tr.data-row.scan.collapsed td,
  tr.heading-row.scan.collapsed th {
    padding: 0 0;
  }

  tr.data-row.scan.collapsed td > div,
  tr.heading-row.scan.collapsed th > div {
    max-height: 0px;
    transition: ${theme.transitions[0]};
    overflow: hidden;
  }

  tr.expanded td > div {
    max-height: 400px;
    transition: ${theme.transitions[0]};
  }
`,
);

export function Summary(props) {
  const { onClose } = props;

  const context =
    useSelector(getContextByModalType(ModalTypes.AllocatePenScanLots)) || {};
  const { mapping = {} } = context;

  const statusByPenScanLotIdLookup = useSelector(
    selectMappingStatusByPenScanLotIdLookup,
  );

  const penScanLotIds = Object.keys(mapping).sort((a, b) =>
    caseInsensitiveCompare(
      statusByPenScanLotIdLookup[b],
      statusByPenScanLotIdLookup[a],
    ),
  );

  return (
    <>
      <DialogContent dividers>
        <Column
          background="surfaceBackground"
          minHeight="50px"
          padding={1}
          fullHeight
        >
          <Table>
            <thead>
              <tr className="heading-row pen-scan">
                <th colSpan={12}>Pen Scan</th>
              </tr>

              <tr className="heading-row pen-scan">
                <th>Status</th>
                <th>Scans</th>
                <th>Pen</th>
                <th>Hd</th>
                <th>Marks</th>
                <th colSpan={6}>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>

            {penScanLotIds.map(penScanLotId => (
              <PenScanLotSummary
                penScanLotId={penScanLotId}
                key={penScanLotId}
              />
            ))}
          </Table>
        </Column>
      </DialogContent>

      <Footer close={onClose} />
    </>
  );
}
