import React from "react";

import { Grid } from "@material-ui/core";
import { Form, useFormikContext } from "formik";
import * as Yup from "yup";

import { Button } from "components/Form";
import { Input } from "components/Form/FormikControls";
import { Column } from "components/Layout";
import { Paper } from "components/Paper";

import { NLISCredentialType } from "constants/nlis";

export const nlisValidationSchema = Yup.object().shape({
  nlis_user: Yup.string().required("Required").max(12),
  nlis_password: Yup.string(),
  nlis_email: Yup.string()
    .required("Required")
    .email("Please enter a valid email"),
});

function getNLISSaleyardIdLabel(credentialType) {
  switch (credentialType) {
    case NLISCredentialType.AGENT:
      return "Agency PIC";
    case NLISCredentialType.SCALE_OPERATOR:
      return "Scale ID";
    default:
      return "Saleyard ID (EUSY----)";
  }
}

const NLISForm = ({ isFetching, credentialType }) => {
  const { values, touched, isValid } = useFormikContext();

  return (
    <Form data-tour="nlis_form">
      <Column padding={2}>
        <Paper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                label="NLIS Username"
                name="nlis_user"
                tooltip="Enter your NLIS Username.  Note that this is different to your MyMLA Username."
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="NLIS Password"
                name="nlis_password"
                placeholder="**********"
                tooltip="Enter your NLIS Password.  Note that this is different to your MyMLA Password."
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="NLIS Email Address"
                name="nlis_email"
                type="email"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label={getNLISSaleyardIdLabel(credentialType)}
                name="nlis_saleyard_id"
                disabled={credentialType === NLISCredentialType.SCALE_OPERATOR}
              />
            </Grid>
            <Grid container item xs={12}>
              <Button
                data-tour="submit"
                type="submit"
                disabled={
                  isFetching ||
                  (!isValid &&
                    !(
                      Object.keys(touched).length === Object.keys(values).length
                    ))
                }
              >
                {isFetching ? "Checking Details..." : "Save"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Column>
    </Form>
  );
};

export default NLISForm;
