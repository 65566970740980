import { uniqWith } from "lodash";
import { intersection, uniqBy } from "lodash/array";
import flatten from "lodash/flatten";
import isEqual from "lodash/isEqual";
import { sumBy } from "lodash/math";
import uniq from "lodash/uniq";
import { put, select, takeEvery } from "redux-saga/effects";

import { printReceipt } from "actions";

import {
  generatePenWeighCardLandscapePdf,
  generatePenWeighCardPortraitPdf,
  generateSaleLotWeightSummaryPdf,
  SheepPenStoreCardpdf,
  SheepPlacardpdf,
} from "components/Pdfs";
import { preparePlacard } from "components/Pdfs/preparePlacard";

import {
  PRINT_BUYER_SUMMARY,
  PRINT_BUYER_WAY_PICK_LIST,
  PRINT_PLACARD,
  PRINT_PLACARD_V2,
  PRINT_PLACARD_V3,
  PRINT_PLACARD_V4,
  PRINT_PLACARD_V5,
  PRINT_SALE_LOT_WEIGHT_SUMMARY,
  PRINT_SHEEP_PEN_STORE_CARD,
  PRINT_SHEEP_PLACARD,
  PRINT_VENDOR_SUMMARY,
  PRINT_WEIGH_PEN_CARD_LANDSCAPE,
  PRINT_WEIGH_PEN_CARD_PORTRAIT,
} from "constants/actionTypes";
import { PlacardVersions } from "constants/pdf";
import { pricingTypeString } from "constants/pricingTypes";
import { saleLotStatuses } from "constants/sale";

import { calculateUnitPrice, formatWeightKg } from "lib";

import {
  comparePens,
  getAuctionPenDisplayName,
  sortByStartPen,
} from "lib/auctionPens";
import { getConsignmentsHgpDetails } from "lib/consignments";
import { getLPAStatusFromPrograms, isLpaAccredited } from "lib/properties";
import buyerSummaryTemplate from "lib/receiptTemplates/buyerSummary";
import buyerWayPickListTemplate from "lib/receiptTemplates/buyerWayPickList";
import vendorSummaryTemplate from "lib/receiptTemplates/vendorSummary";
import { getDateFromISO8601DateString } from "lib/timeFormats";

import {
  currentSaleSelector,
  getAgencyByConsignmentIdLookup,
  getAgencyIdsByAuctionPenId,
  getAuctionPens,
  getBusinesses,
  getConsignments,
  getCurrentSale,
  getDeployments,
  getFirstName,
  getLastName,
  getMinimalFormattedSaleLotById,
  getSaleById,
  getSaleLotIdsByAuctionPenId,
  getSaleLots,
  getSaleLotsBySale,
  getScansBySaleLotId,
  selectAgencyByConsignmentIdLookup,
  selectAgencyBySaleLotIdLookup,
  selectDeploymentBusinessVendorBySaleLotIdLookup,
  selectDeploymentIdByConsignmentIdLookup,
  selectDeploymentIdBySaleLotIdLookup,
  selectDeploymentMarkOrderLookup,
  selectFormattedSaleLotsBySaleLotId,
  selectIsVendorBredBySaleLotIdLookup,
  selectPrimaryMarkBySaleLotIdLookup,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotIdsByMarkOrder,
  selectDeploymentPlacardLogoUrlByDeploymentId,
  getSalePlacardLogo,
} from "selectors";

function* printBuyerWayPickList(action) {
  const state = yield select();

  const {
    buyerWay: { buyerWayName, saleLotIds },
  } = action;

  const formattedSaleLotsBySaleLotId =
    selectFormattedSaleLotsBySaleLotId(state);

  const saleLots = saleLotIds.map(
    saleLotId => formattedSaleLotsBySaleLotId[saleLotId],
  );

  // assume that all we have salelots, and that they are all from the sale livestock sale
  const { buyerName, agencyName } = saleLots[0];

  const sale = getCurrentSale(state);

  const user = `${getFirstName(state)} ${getLastName(state).substring(0, 1)}.`;

  const auctionPensByIdLookup = getAuctionPens(state);
  const saleLotsByIdLookup = getSaleLots(state);
  const selectedAuctionPens = uniqBy(saleLots, "auctionPenId").map(
    saleLot => auctionPensByIdLookup[saleLot.auctionPenId],
  );
  const saleLotIdsByAuctionPenIdLookup =
    selectSaleLotIdsByAuctionPenIdLookup(state);
  // Sort by start pen only, not selling order.
  const sortedAuctionPens = selectedAuctionPens.sort((a, b) =>
    comparePens(a.start_pen, b.start_pen),
  );

  const { penSummaries, totalCents, totalHdCount } = sortedAuctionPens.reduce(
    (acc, auctionPen) => {
      const penSaleLotIds = saleLotIdsByAuctionPenIdLookup[auctionPen.id];
      const penSaleLots = penSaleLotIds.map(
        saleLotId => saleLotsByIdLookup[saleLotId],
      );

      const totalPenHdCount = sumBy(penSaleLots, "quantity");

      const buyerWayPenSaleLotIds = intersection(penSaleLotIds, saleLotIds);
      const buyerWayPenSaleLots = buyerWayPenSaleLotIds.map(
        saleLotId => saleLotsByIdLookup[saleLotId],
      );

      const penName = getAuctionPenDisplayName(auctionPen);

      const { saleLotSummaries, buyerWayPenCents, buyerWayPenHdCount } =
        buyerWayPenSaleLots.reduce(
          (acc, saleLot) => {
            const hdCount = saleLot.quantity;

            acc.buyerWayPenHdCount += hdCount;
            acc.buyerWayPenCents += saleLot.total_price_cents;
            acc.saleLotSummaries.push({
              overflowPenName: saleLot.overflowPen,
              overflowHdCount: saleLot.overflowQuantity,
              pricingTypeId: saleLot.pricing_type_id,
              unitPriceCents: calculateUnitPrice(saleLot),
              hdCount,
            });

            return acc;
          },
          { saleLotSummaries: [], buyerWayPenCents: 0, buyerWayPenHdCount: 0 },
        );

      acc.penSummaries.push({
        buyerWayPenHdCount,
        buyerWayPenCents,
        penName,
        saleLotSummaries,
        totalPenHdCount,
      });

      acc.totalCents += buyerWayPenCents;
      acc.totalHdCount += buyerWayPenHdCount;
      return acc;
    },
    { penSummaries: [], totalCents: 0, totalHdCount: 0 },
  );
  const avgValue = totalHdCount > 0 ? totalCents / totalHdCount : 0;

  const printReceiptAction = printReceipt(
    buyerWayPickListTemplate(
      sale.saleyard_name,
      sale.livestocksale_id,
      sale.pricing_type_id,
      agencyName,
      user,
      buyerName,
      buyerWayName,
      avgValue,
      penSummaries,
      totalHdCount,
      totalCents,
    ),
  );

  yield put(printReceiptAction);
}

function* printBuyerSummary(action) {
  const state = yield select();
  const { saleLots } = action.buyer;

  // assume that all we have salelots, and that they are all from the sale livestock sale
  const { buyerName, agencyName } = saleLots[0];
  const sale = getCurrentSale(state);

  const user = `${getFirstName(state)} ${getLastName(state).substring(0, 1)}.`;

  let totalValue = 0;
  let totalHeadcount = 0;

  const lotSummary = saleLots.reduce((acc, saleLot) => {
    const {
      quantity,
      totalPrice,
      buyerWayName,
      buyerWayId,
      destinationProperty,
    } = saleLot;

    totalValue += totalPrice;
    totalHeadcount += quantity;

    const ctx = acc[buyerWayId];
    if (ctx) {
      const combinedTotal = totalPrice + ctx.totalPrice;
      const combinedHeadcount = quantity + ctx.headCount;
      const combinedAvg =
        combinedHeadcount > 0 ? combinedTotal / combinedHeadcount : 0;

      ctx.totalPrice = combinedTotal;
      ctx.avgPrice = combinedAvg;
      ctx.headCount = combinedHeadcount;
    } else {
      acc[buyerWayId] = {
        buyerWay: buyerWayName || "-",
        headCount: quantity,
        avgPrice: quantity > 0 ? totalPrice / quantity : 0,
        totalPrice,
        destinationProperty,
      };
    }

    return acc;
  }, []);

  const avgValue = totalHeadcount > 0 ? totalValue / totalHeadcount : 0;
  yield put(
    printReceipt(
      buyerSummaryTemplate(
        sale.saleyard_name,
        sale.livestocksale_id,
        agencyName,
        user,
        buyerName,
        Object.values(lotSummary),
        totalHeadcount,
        totalValue,
        avgValue,
      ),
    ),
  );
}

function* printVendorSummary(action) {
  // this really shouldn't be driven by a consignment id, but that's all that
  // available without refactoring the `ActionOptions` component
  const { consignmentId } = action;
  const state = yield select();
  const allConsignments = getConsignments(state);
  const agencyByConsignmentIdLookup = selectAgencyByConsignmentIdLookup(state);
  const allBusinesses = getBusinesses(state);
  const { vendor_id: vendorId, livestocksale_id: saleId } =
    allConsignments[consignmentId];
  const { name: vendorName } = allBusinesses[vendorId];
  const agencyName = agencyByConsignmentIdLookup[consignmentId];
  const vendorSaleLots = getSaleLotsBySale(state, { saleId }).filter(
    saleLot => saleLot.vendor_id === vendorId,
  );

  const { saleyard_name } = getSaleById(state, saleId);

  const user = `${getFirstName(state)} ${getLastName(state).substring(0, 1)}.`;

  let priceUnits = "";

  function summariseSaleLot(saleLot) {
    const {
      start_pen,
      end_pen,
      total_price_cents,
      quantity,
      pricing_type_id,
      status,
    } = saleLot;
    const totalPrice = total_price_cents / 100;
    // Will only show LivestockSale (Not Clearing Sale) Pricing Type display names
    priceUnits = pricingTypeString()(pricing_type_id);
    const penName = `${start_pen}${end_pen ? "+" : ""}`;
    return {
      penName,
      unitPrice: quantity > 0 ? totalPrice / quantity : 0,
      headCount: quantity,
      isNoSale: status === saleLotStatuses.NO_SALE,
    };
  }

  const roundList = sortByStartPen(vendorSaleLots).reduce(
    (saleRounds, saleLot) => {
      const roundName = saleLot.sale_round.name;
      const summarisedLot = summariseSaleLot(saleLot);
      const round = saleRounds.find(
        saleRound => saleRound.roundName === roundName,
      );
      const totalHeadcount = summarisedLot.headCount;
      const totalValue = summarisedLot.unitPrice * summarisedLot.headCount;
      const avgValue = summarisedLot.unitPrice;
      const avgHeadcount = avgValue > 0 ? totalHeadcount : 0;

      if (round) {
        const combinedTotal = totalValue + round.totalValue;
        const combinedHeadcount = totalHeadcount + round.totalHeadcount;
        const combinedAvgHeadcount = avgHeadcount + round.avgHeadcount;
        const combinedAvg =
          combinedAvgHeadcount > 0 ? combinedTotal / combinedAvgHeadcount : 0;

        round.totalHeadcount = combinedHeadcount;
        round.avgHeadcount = combinedAvgHeadcount;
        round.totalValue = combinedTotal;
        round.avgValue = combinedAvg;
        round.penList.push(summarisedLot);
      } else {
        saleRounds.push({
          roundName,
          totalHeadcount,
          totalValue,
          avgValue,
          avgHeadcount,
          penList: [summarisedLot],
        });
      }
      return saleRounds;
    },
    [],
  );

  const totalValue = roundList.reduce(
    (acc, round) => acc + round.totalValue,
    0,
  );
  const totalAvgHeadcount = roundList.reduce(
    (acc, round) => acc + round.avgHeadcount,
    0,
  );
  const totalHeadcount = roundList.reduce(
    (acc, round) => acc + round.totalHeadcount,
    0,
  );

  const avgValue = totalAvgHeadcount > 0 ? totalValue / totalAvgHeadcount : 0;
  yield put(
    printReceipt(
      vendorSummaryTemplate(
        saleyard_name,
        agencyName,
        user,
        saleId,
        vendorName,
        roundList,
        totalHeadcount,
        totalValue,
        avgValue,
        priceUnits,
      ),
    ),
  );
}

function* printSaleLotWeightSummary(action) {
  const { saleLotId, suppressBack } = action;
  const state = yield select();
  const sale = currentSaleSelector(state);
  const saleLot = getMinimalFormattedSaleLotById(state, { saleLotId });
  const agencyBySaleLotIdLookup = selectAgencyBySaleLotIdLookup(state);

  generateSaleLotWeightSummaryPdf(
    saleLot,
    sale,
    agencyBySaleLotIdLookup,
    suppressBack,
  );
}

// This is weird but how it is specified by wamia, sort by marks
function* printPenWeighCardPrinter(action) {
  const { auctionPenId, suppressBack, agentId, type } = action;
  const state = yield select();
  const livestockSale = currentSaleSelector(state);
  const isVendorBredBySaleLotId = selectIsVendorBredBySaleLotIdLookup(state);
  const saleLotIdsByAuctionPenId = selectSaleLotIdsByAuctionPenIdLookup(state);
  const formattedSaleLots = getSaleLotsBySale(state);
  const isLandscapeCard = type === PRINT_WEIGH_PEN_CARD_LANDSCAPE;
  const deploymentIdByConsignment =
    selectDeploymentIdByConsignmentIdLookup(state);
  const deploymentPlacardLogos =
    selectDeploymentPlacardLogoUrlByDeploymentId(state);

  const saleLogo = getSalePlacardLogo(state);

  const auctionPens = sortByStartPen(
    Object.values(getAuctionPens(state)).filter(auctionPen => {
      if (auctionPenId === null || auctionPen.id === auctionPenId) {
        return (saleLotIdsByAuctionPenId[auctionPen.id] || []).length > 0;
      }
      return false;
    }),
  );

  let deploymentSale;
  let pens = auctionPens;
  if (agentId) {
    const agentIdInt = parseInt(agentId, 10);
    pens = auctionPens.filter(pen =>
      getAgencyIdsByAuctionPenId(pen.id)(state).includes(agentIdInt),
    );
    deploymentSale = livestockSale?.deployment_sales?.filter(
      deploymentSale => deploymentSale.livestock_agency_id === agentIdInt,
    )[0];
  }

  const primaryMarkBySaleLotIdLookup =
    selectPrimaryMarkBySaleLotIdLookup(state);

  const saleLotIdsByMarksOrder = selectSaleLotIdsByMarkOrder(state);

  const auctionPenMarkLocations = pens.reduce((acc, auctionPen) => {
    const saleLotIds = getSaleLotIdsByAuctionPenId(auctionPen.id)(state);
    const sortedSaleLots = intersection(saleLotIdsByMarksOrder, saleLotIds);
    sortedSaleLots.forEach(saleLotId =>
      acc.push({
        auctionPen,
        marks: primaryMarkBySaleLotIdLookup[saleLotId]?.location || null,
      }),
    );
    return acc;
  }, []);

  function formatWeighPenCard({ auctionPen, marks = null }) {
    const saleLots = Object.values(formattedSaleLots)
      .filter(
        formattedSaleLot => formattedSaleLot.auction_pen_id === auctionPen.id,
      )
      .filter(
        formattedSaleLot =>
          isLandscapeCard ||
          (marks
            ? formattedSaleLot.marks.map(mark => mark.location).includes(marks)
            : !marks && formattedSaleLot.marks.length === 0),
      );
    const deploymentMarkOrder = selectDeploymentMarkOrderLookup(state);

    // When a lot has no scans it will add null as a scan, we need to filter them out
    const scans = flatten(
      saleLots.map(({ id }) => getScansBySaleLotId(id)(state)) || [],
    ).filter(Boolean);

    const saleLotsInMarkOrder = saleLots.reduce((acc, saleLot) => {
      const mappedMarks = saleLot.marks
        .map(mark => ({
          mark,
          order: parseInt(
            deploymentMarkOrder[
              deploymentIdByConsignment[saleLot.consignment_id]
            ][mark.location],
            10,
          ),
        }))
        .sort((a, b) => a.order - b.order);

      if (saleLot.id in acc) {
        acc[saleLot.id] = acc[saleLot.id].concat(mappedMarks);
      } else {
        acc[saleLot.id] = mappedMarks;
      }
      return acc;
    }, []);

    const consignments = uniqBy(
      saleLots.map(saleLot => saleLot.consignment),
      "id",
    );

    const agencies = uniqBy(consignments, "deployment_sale").map(consignment =>
      getAgencyByConsignmentIdLookup(consignment.id)(state),
    );
    const declarations = consignments.map(
      consignment => consignment.declaration,
    );

    const hasDeclarations = !declarations.every(
      declaration => declaration === null,
    );

    const hasScans = saleLots.some(saleLot => saleLot.scans?.length > 0);

    const lifetimeTraceableCount = scans.filter(
      scan => scan.lifetime_traceability === "Y",
    ).length;

    const lifeTimeTraceablePercentage =
      lifetimeTraceableCount === 0
        ? 0
        : (lifetimeTraceableCount / scans.length) * 100;

    const lpaStatus = uniq(
      consignments.map(consignment =>
        getLPAStatusFromPrograms(consignment.nlisPrograms),
      ),
    );

    const isLpa =
      lpaStatus.length === 1
        ? isLpaAccredited(lpaStatus[0])
          ? "Y"
          : "N"
        : "?";

    const headCount = sumBy(saleLots, "quantity");

    const averageWeight =
      headCount > 0
        ? formatWeightKg(
            sumBy(saleLots, "total_mass_grams") / headCount,
            true,
            false,
          )
        : 0;

    const totalWeight = formatWeightKg(
      sumBy(saleLots, "total_mass_grams"),
      true,
      false,
    );

    const sexNames = saleLots.map(saleLot => saleLot.sex_name);

    const vendorBredHeadCount = sumBy(
      saleLots.filter(({ id }) => isVendorBredBySaleLotId[id]),
      "quantity",
    );
    const vendorBredPercentage =
      vendorBredHeadCount === 0 ? 0 : (vendorBredHeadCount / headCount) * 100;

    const russianEligible = uniq(
      consignments.map(
        consignment =>
          consignment.declaration?.veterinary_treatment ||
          consignment.declaration?.treated_with_hgp ||
          "",
      ),
    );

    function getRussianEligible(hasDeclarations, russianEligible) {
      if (!hasDeclarations) {
        return " ";
      } else if (russianEligible.length > 1) {
        return "?";
      } else if (russianEligible[0]) {
        return "N";
      }
      return "Y";
    }

    const saudiEligible = uniq(
      consignments.map(
        consignment =>
          consignment.declaration?.fed_feed_containing_animal_fats || "",
      ),
    );

    const agencyShortNames = agencies.map(agency => agency.shortName || "");
    const districts = flatten(
      saleLots.map(saleLot => saleLot.consignment.vendor_property?.district),
    )
      .filter(Boolean)
      .join(",");

    const vendors = saleLots.map(saleLot => saleLot.vendorName);

    function getSaudiEligible(hasDeclarations, saudiEligible) {
      if (!hasDeclarations) {
        return " ";
      } else if (saudiEligible.length > 1) {
        return "?";
      } else if (saudiEligible[0]) {
        return "N";
      }
      return "Y";
    }

    return {
      agencyName:
        uniq(agencyShortNames).length === 1 ? agencyShortNames[0] : "Mixed",
      penName: getAuctionPenDisplayName(auctionPen),
      marks: marks || "",
      district: vendors.length > 1 ? "INTERLOT" : districts,
      isHgp: getConsignmentsHgpDetails(consignments),
      isVb:
        vendorBredPercentage === 100
          ? `Y`
          : vendorBredPercentage === 0 || !vendorBredPercentage
            ? "N"
            : `${Math.round(vendorBredPercentage)}%`,

      isLt: hasScans
        ? lifeTimeTraceablePercentage === 100
          ? "Y"
          : lifeTimeTraceablePercentage === 0 || !lifeTimeTraceablePercentage
            ? "N"
            : `${Math.round(lifeTimeTraceablePercentage)}%`
        : " ",
      isLpa,
      isRussianEligible: getRussianEligible(hasDeclarations, russianEligible),
      isSaudiEligible: getSaudiEligible(hasDeclarations, saudiEligible),
      hasScans,
      hasDeclarations,
      headCount,
      averageWeight: averageWeight || "",
      totalWeight: totalWeight || "",
      sexName: uniq(sexNames).length === 1 ? sexNames[0] : "Mixed",
      speciesId: saleLots[0].sale_round.species_id,
      saleLots,
      saleLotsInMarkOrder,
      footerData: {
        roundName: saleLots[0].sale_round.name,
        startPen: isLandscapeCard
          ? auctionPen.start_pen
          : saleLots[0].auction_pen.start_pen,
        saleyardName: livestockSale.saleyard_name,
        saleDate: getDateFromISO8601DateString(livestockSale.date),
      },
    };
  }

  if (isLandscapeCard) {
    const pensObjectArray = pens.reduce((acc, auctionPen) => {
      acc.push({ auctionPen });
      return acc;
    }, []);
    const logo =
      deploymentPlacardLogos[deploymentSale?.deployment_id] || saleLogo;
    generatePenWeighCardLandscapePdf(
      pensObjectArray.map(formatWeighPenCard),
      suppressBack,
      logo,
    );
  } else {
    generatePenWeighCardPortraitPdf(
      uniqWith(auctionPenMarkLocations.map(formatWeighPenCard), isEqual),
      livestockSale,
      suppressBack,
    );
  }
}

function* printPlacard(action) {
  const {
    version,
    saleLotId,
    round,
    suppressBack,
    agentId,
    includeWeight,
    size,
  } = action;
  const state = yield select();
  const sale = getCurrentSale(state);
  const saleLots = agentId
    ? getSaleLotsBySale(state).filter(
        saleLot => saleLot.livestockAgency.id === parseInt(agentId, 10),
      )
    : getSaleLotsBySale(state);
  const deploymentIdBySaleLotIdLookup =
    selectDeploymentIdBySaleLotIdLookup(state);
  const deployments = getDeployments(state);
  const deploymentPlacardLogos =
    selectDeploymentPlacardLogoUrlByDeploymentId(state);

  let selectedSaleLots = saleLots;
  if (round && saleLotId) {
    selectedSaleLots = saleLots.filter(
      saleLot => saleLot.sale_round_id === parseInt(round, 10),
    );
  } else if (saleLotId) {
    selectedSaleLots = [saleLots.find(saleLot => saleLot.id === saleLotId)];
  } else {
    selectedSaleLots = saleLots;
  }
  const vendorDeploymentBusinessBySaleLotIdLookup =
    selectDeploymentBusinessVendorBySaleLotIdLookup(state);
  const isVendorBredBySaleLotIdLookup =
    selectIsVendorBredBySaleLotIdLookup(state);

  switch (version) {
    case PlacardVersions.V2:
      preparePlacard(
        selectedSaleLots,
        sale,
        deploymentIdBySaleLotIdLookup,
        deployments,
        vendorDeploymentBusinessBySaleLotIdLookup,
        isVendorBredBySaleLotIdLookup,
        null,
        null,
        size,
        "landscape",
        PlacardVersions.V2,
        deploymentPlacardLogos,
      );
      break;
    case PlacardVersions.V3:
      preparePlacard(
        selectedSaleLots,
        sale,
        deploymentIdBySaleLotIdLookup,
        deployments,
        vendorDeploymentBusinessBySaleLotIdLookup,
        isVendorBredBySaleLotIdLookup,
        null,
        includeWeight,
        size,
        "portrait",
        PlacardVersions.V3,
        deploymentPlacardLogos,
      );
      break;
    case PlacardVersions.V4:
      preparePlacard(
        selectedSaleLots,
        sale,
        deploymentIdBySaleLotIdLookup,
        deployments,
        vendorDeploymentBusinessBySaleLotIdLookup,
        isVendorBredBySaleLotIdLookup,
        null,
        null,
        size,
        "landscape",
        PlacardVersions.V4,
        deploymentPlacardLogos,
      );
      break;
    case PlacardVersions.V5:
      preparePlacard(
        selectedSaleLots,
        sale,
        deploymentIdBySaleLotIdLookup,
        deployments,
        vendorDeploymentBusinessBySaleLotIdLookup,
        isVendorBredBySaleLotIdLookup,
        null,
        null,
        size,
        "landscape",
        PlacardVersions.V5,
        deploymentPlacardLogos,
      );
      break;
    default:
      preparePlacard(
        selectedSaleLots,
        sale,
        deploymentIdBySaleLotIdLookup,
        deployments,
        vendorDeploymentBusinessBySaleLotIdLookup,
        isVendorBredBySaleLotIdLookup,
        suppressBack,
        null,
        size,
        "landscape",
        PlacardVersions.V1,
        deploymentPlacardLogos,
      );
  }
}

function* printSheepPlacard(action) {
  const { saleLotId, round, agencyId } = action;
  const state = yield select();
  const sale = currentSaleSelector(state);
  const saleLots = agencyId
    ? getSaleLotsBySale(state).filter(
        saleLot => saleLot.livestockAgency.id === parseInt(agencyId, 10),
      )
    : getSaleLotsBySale(state);
  const deploymentIdBySaleLotIdLookup =
    selectDeploymentIdBySaleLotIdLookup(state);
  const deployments = getDeployments(state);
  const deploymentPlacardLogos =
    selectDeploymentPlacardLogoUrlByDeploymentId(state);

  let selectedSaleLots = "";
  if (round && saleLotId) {
    selectedSaleLots = saleLots.filter(
      saleLot => saleLot.sale_round_id === parseInt(round, 10),
    );
  } else if (saleLotId) {
    selectedSaleLots = [saleLots.find(saleLot => saleLot.id === saleLotId)];
  } else {
    selectedSaleLots = saleLots;
  }
  const vendorDeploymentBusinessBySaleLotIdLookup =
    selectDeploymentBusinessVendorBySaleLotIdLookup(state);
  SheepPlacardpdf(
    selectedSaleLots,
    sale,
    deploymentIdBySaleLotIdLookup,
    deployments,
    vendorDeploymentBusinessBySaleLotIdLookup,
    deploymentPlacardLogos,
  );
}

function* printSheepPenStoreCard(action) {
  const { saleLotId, round, agencyId } = action;
  const state = yield select();
  const sale = currentSaleSelector(state);
  const saleLots = agencyId
    ? getSaleLotsBySale(state).filter(
        saleLot => saleLot.livestockAgency.id === parseInt(agencyId, 10),
      )
    : getSaleLotsBySale(state);

  const deploymentIdBySaleLotIdLookup =
    selectDeploymentIdBySaleLotIdLookup(state);
  const deployments = getDeployments(state);
  const deploymentPlacardLogos =
    selectDeploymentPlacardLogoUrlByDeploymentId(state);
  const saleLogo = getSalePlacardLogo(state);
  let selectedSaleLots = "";
  if (round && saleLotId) {
    selectedSaleLots = saleLots.filter(
      saleLot => saleLot.sale_round_id === parseInt(round, 10),
    );
  } else if (saleLotId) {
    selectedSaleLots = [saleLots.find(saleLot => saleLot.id === saleLotId)];
  } else {
    selectedSaleLots = saleLots;
  }
  const vendorDeploymentBusinessBySaleLotIdLookup =
    selectDeploymentBusinessVendorBySaleLotIdLookup(state);
  SheepPenStoreCardpdf(
    selectedSaleLots,
    sale,
    deploymentIdBySaleLotIdLookup,
    deployments,
    vendorDeploymentBusinessBySaleLotIdLookup,
    deploymentPlacardLogos,
    saleLogo,
  );
}

export default function* sellFilesSaga() {
  yield takeEvery(PRINT_BUYER_WAY_PICK_LIST, printBuyerWayPickList);
  yield takeEvery(PRINT_BUYER_SUMMARY, printBuyerSummary);
  yield takeEvery(PRINT_VENDOR_SUMMARY, printVendorSummary);
  yield takeEvery(PRINT_SALE_LOT_WEIGHT_SUMMARY, printSaleLotWeightSummary);
  yield takeEvery(PRINT_WEIGH_PEN_CARD_PORTRAIT, printPenWeighCardPrinter);
  yield takeEvery(PRINT_WEIGH_PEN_CARD_LANDSCAPE, printPenWeighCardPrinter);
  yield takeEvery(PRINT_PLACARD, printPlacard);
  yield takeEvery(PRINT_PLACARD_V2, printPlacard);
  yield takeEvery(PRINT_PLACARD_V3, printPlacard);
  yield takeEvery(PRINT_PLACARD_V4, printPlacard);
  yield takeEvery(PRINT_PLACARD_V5, printPlacard);
  yield takeEvery(PRINT_SHEEP_PLACARD, printSheepPlacard);
  yield takeEvery(PRINT_SHEEP_PEN_STORE_CARD, printSheepPenStoreCard);
}
