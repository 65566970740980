import React, { useState } from "react";

import { Form, Formik } from "formik";
import uniq from "lodash/uniq";
import { useDispatch, useSelector } from "react-redux";

import { RuleAction } from "actions";

import { Button, SecondaryButton } from "components/Form";
import { useSubmitHandler } from "components/Form/FormikControls";
import { DialogActions, DialogContent } from "components/MaterialDialog";

import { ModalTypes } from "constants/navigation";
import { RuleBookPermissions } from "constants/permissions";

import { convertToExternalRule } from "containers/Settings/RuleBooks/export";
import { convertToInternalRule } from "containers/Settings/RuleBooks/import";
import { RuleForm } from "containers/Settings/RuleBooks/Rules/CustomRules";
import {
  RuleFieldSchema,
  useRuleFieldSchemaGenerator,
} from "containers/Settings/RuleBooks/schemaContext";

import {
  getContextByModalType,
  getLastOrderByRuleBookId,
  getRuleBookById,
  getRuleById,
  getRulesByRuleBookId,
  selectBillingTagOptions,
} from "selectors";

import { useHasPermission } from "hooks";

export function extractCategoryNames(rules) {
  return uniq(rules.map(rule => (rule.category || "").trim()));
}

export function extractGlCodes(rules) {
  return uniq(rules.map(rule => rule.gl_code.trim()));
}

export function EditCustomRuleForm(props) {
  const { onClose, ruleBookId } = props;
  const dispatch = useDispatch();

  const context = useSelector(getContextByModalType(ModalTypes.EditRule)) || {};
  const { ruleId = null, rule: templateRule = null } = context;

  const rule = useSelector(getRuleById(ruleId));

  const otherRulesInRuleBook =
    useSelector(getRulesByRuleBookId(ruleBookId)) || [];

  const schemaGenerator = useRuleFieldSchemaGenerator();
  const schema = schemaGenerator(false);

  const internalRules = otherRulesInRuleBook.map(externalRule =>
    convertToInternalRule(externalRule, schema),
  );

  const lastOrder = useSelector(getLastOrderByRuleBookId(ruleBookId));

  function onSubmit(values) {
    const externalRule = convertToExternalRule(
      { order: lastOrder + 1, ...values },
      schema,
    );

    if (ruleId) {
      dispatch(
        RuleAction.update(externalRule, {
          changeReason: "Updated from Rule Modal",
        }),
      );
    } else {
      dispatch(
        RuleAction.create(externalRule, {
          changeReason: "Created from Rule Modal",
        }),
      );
    }
    onClose();
  }

  const initialValues = {
    ...(rule ? convertToInternalRule(rule, schema) : null),
    ...templateRule,
    rule_book_id: ruleBookId,
  };

  const categories = extractCategoryNames(internalRules);
  const billingTags = useSelector(selectBillingTagOptions);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <DialogContent>
          <RuleFieldSchema value={schema}>
            <RuleForm
              categories={categories}
              billingTags={billingTags}
              namespace=""
            />
          </RuleFieldSchema>
        </DialogContent>
        <DialogActions data-tour="customRuleFormActions">
          <Footer onClose={onClose} ruleId={ruleId} ruleBookId={ruleBookId} />
        </DialogActions>
      </Form>
    </Formik>
  );
}

function Footer(props) {
  const { onClose, ruleBookId } = props;
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  useSubmitHandler(true, setIsSubmitEnabled);

  const hasUpdateRuleBookPermission = useHasPermission(
    getRuleBookById(ruleBookId),
    RuleBookPermissions.update,
  );

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      <Button
        type="submit"
        data-tour="save"
        disabled={!(isSubmitEnabled && hasUpdateRuleBookPermission)}
        title={
          !hasUpdateRuleBookPermission
            ? "You do not have permission to update Rules in this Rule Book"
            : "Update Rule"
        }
      >
        Save
      </Button>
    </>
  );
}
