import React, { useMemo } from "react";

import flatten from "lodash/flatten";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { setTableView } from "actions";

import Button from "components/Button";
import { ReactSelect } from "components/SearchableSelector";

import {
  getModificationTypesBySavedViewIdLookup,
  getOrderedSavedViewsByTable,
  getSelectedViewIdByTable,
  SavedViewModificationType,
  selectOwnerNameBySavedViewIdLookup,
} from "selectors";

const Select = styled(ReactSelect)`
  width: 250px;
  border-radius: 0px;
`;

export const ViewSelector = React.memo(({ tableName }) => {
  const dispatch = useDispatch();
  const selectedViewId =
    useSelector(getSelectedViewIdByTable(tableName)) || null;
  const savedViews = useSelector(getOrderedSavedViewsByTable(tableName));
  const ownerNameBySavedViewIdLookup = useSelector(
    selectOwnerNameBySavedViewIdLookup,
  );

  const modificationTypes = useSelector(
    getModificationTypesBySavedViewIdLookup(selectedViewId),
  );
  const isSelectedViewValid =
    selectedViewId != null &&
    savedViews?.some(savedView => savedView.id === selectedViewId);
  const isSelectedViewModified =
    Array.isArray(modificationTypes) && modificationTypes.length > 0;

  const options = useMemo(() => {
    const savedViewOptions = [];
    if (!selectedViewId || !isSelectedViewValid) {
      savedViewOptions.push({
        label: "Unsaved view",
        value: null,
      });
    }
    if (savedViews) {
      return savedViews.reduce((acc, savedView) => {
        const groupName = ownerNameBySavedViewIdLookup[savedView.id];
        const group = acc.find(
          savedViewGroup => savedViewGroup.label === groupName,
        );
        let groupList = [];
        if (!group) {
          acc.push({
            label: groupName,
            options: groupList,
          });
        } else {
          groupList = group.options;
        }
        if (savedView.id === selectedViewId && isSelectedViewModified) {
          if (modificationTypes.length > 1) {
            groupList.push({
              label: `${savedView.name} (Modified)`,
              value: savedView.id,
            });
          } else if (
            modificationTypes[0] === SavedViewModificationType.FILTERS
          ) {
            groupList.push({
              label: `${savedView.name} (Filter Modified)`,
              value: savedView.id,
            });
          } else if (modificationTypes[0] === SavedViewModificationType.STATE) {
            groupList.push({
              label: `${savedView.name} (Columns Modified)`,
              value: savedView.id,
            });
          }
        } else {
          groupList.push({
            label: savedView.name,
            value: savedView.id,
          });
        }
        return acc;
      }, savedViewOptions);
    }
    return savedViewOptions;
  }, [
    isSelectedViewModified,
    isSelectedViewValid,
    modificationTypes,
    ownerNameBySavedViewIdLookup,
    savedViews,
    selectedViewId,
  ]);

  function onChange(option) {
    dispatch(setTableView(tableName, option.value));
  }

  const value = flatten(
    options.map(optionGroup =>
      optionGroup.options ? optionGroup.options : [optionGroup],
    ),
  ).find(option => option.value === selectedViewId);

  function onClickReset() {
    if (!isSelectedViewValid) {
      return;
    }
    dispatch(setTableView(tableName, selectedViewId));
  }

  return (
    <div className="flex">
      <Select
        value={value}
        onChange={onChange}
        options={options}
        placeholder="Select a Saved View..."
        menuPortalTarget={document.body}
      />
      <Button
        inline
        margin={1}
        onClick={onClickReset}
        disabled={!isSelectedViewModified}
      >
        Reset
      </Button>
    </div>
  );
});
