import queryString from "query-string";

import { REPORT_JOB } from "constants/actionTypes";

import {
  serializeReportJob,
  serializeReportJobBulkCreate,
} from "lib/serializers/reportJob";

import {
  offlineActionCreator,
  offlineSubActionCreator,
  urlCheckAndAddIdAndAction,
} from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/report-jobs/", id, action),
    query: {
      changesSince,
    },
  });

export const ReportJobAction = offlineActionCreator(
  REPORT_JOB,
  serializeReportJob,
  urlCreator,
  false,
);

ReportJobAction.email = offlineSubActionCreator(REPORT_JOB.EMAIL, payload => {
  return urlCreator({
    id: payload.report_job_id,
    action: "email",
  });
});

ReportJobAction.createBulk = offlineSubActionCreator(
  REPORT_JOB.CREATE_BULK,
  () => urlCreator({ action: "bulk-create" }),
  "POST",
  serializeReportJobBulkCreate,
);

ReportJobAction.pusher_subscribe = () => {
  return {
    type: REPORT_JOB.PUSHER.ACTION,
  };
};

ReportJobAction.pusher_unsubscribe = () => {
  return {
    type: REPORT_JOB.PUSHER.RESET,
  };
};
