import React, { useMemo } from "react";

import { createFilterOptions } from "@material-ui/lab";
import sortBy from "lodash/sortBy";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { BusinessAction } from "actions";

import { Autocomplete, FILTER_LIMIT } from "components/Form/FormikControls";

import { BUYER, VENDOR } from "constants/businesses";
import { Species } from "constants/species";

import { EMPTY_OBJECT } from "lib";

import { isBusinessActive } from "lib/businesses";
import { caselessCompare } from "lib/compare";
import { getLivestockSaleId } from "lib/navigation";

import {
  getActiveBusinesses,
  getBusinesses,
  getCurrentSpeciesId,
  getIsSaleyardAdmin,
  getProperties,
  selectConsignmentIdsByVendorIdLookup,
  selectSaleLotIdsByBuyerIdLookup,
} from "selectors";

import {
  useFieldSetter,
  useFieldValue,
  useUpdateFormWithOfflineLookup,
} from "hooks";

const getOptionValue = option => option?.id;

export const BusinessField = ({
  autoFocus = false,
  disabled = false,
  label,
  name,
  onChangeExtra = undefined,
  sortRecentBusinessType = undefined,
  allowAddNew = true,
  activeOnly = false,
  inlineButton = null,
}) => {
  const propertyByIdLookup = useSelector(getProperties);

  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);

  // include buyer pics in the filter search
  const filter = createFilterOptions({
    matchFrom: "any",
    stringify: option => {
      const code = isSaleyardAdmin
        ? option.shortCodeSaleyard
        : option.shortCode;
      return `${option.name} ${code} ${option?.properties
        ?.map(property => propertyByIdLookup[property.id]?.PIC)
        .join(", ")}`;
    },
  });
  const dispatch = useDispatch();
  const setValue = useFieldSetter(name);
  const selectedBusinessId = useFieldValue(name);

  const currentSpecies = useSelector(getCurrentSpeciesId);

  const [tempBusinesses, setTempBusinesses] = React.useState([]);

  const addNewBusiness = businessName => {
    const tempId = uuidv4();
    dispatch(
      BusinessAction.create({
        id: tempId,
        name: businessName.trim(),
      }),
    );

    setTempBusinesses([...tempBusinesses, { id: tempId, name: businessName }]);
    // Add a temp option so its selected straight away.

    setValue(tempId);
  };

  const allBusinesses = useSelector(getBusinesses) || EMPTY_OBJECT;
  const activeBusinesses = useSelector(getActiveBusinesses) || EMPTY_OBJECT;
  const selectedBusiness = allBusinesses[selectedBusinessId];

  const businesses = activeOnly ? activeBusinesses : allBusinesses;

  const saleLotIdsByBuyerIdLookup = useSelector(
    selectSaleLotIdsByBuyerIdLookup,
  );

  const consignmentIdsByVendorIdLookup = useSelector(
    selectConsignmentIdsByVendorIdLookup,
  );

  // The label is defined by who's viewing it
  const getOptionLabel = useMemo(() => {
    return option => {
      if (option.label) {
        // Special for the 'add'
        return option.label;
      }
      const inActive = isBusinessActive(option, getLivestockSaleId())
        ? ""
        : " (INACTIVE)";
      const code = isSaleyardAdmin
        ? option.shortCodeSaleyard
        : option.shortCode;
      return `${code ? `(${code})` : ""} ${option.name}${inActive}`;
    };
  }, [isSaleyardAdmin]);

  // Sort options by top buyers first, then the relevant [saleyard|agent] code.
  const options = useMemo(() => {
    const allOptions = [...Object.values(businesses), ...tempBusinesses];

    // Allow for (now) inactive business on existing data
    if (
      selectedBusiness &&
      !isBusinessActive(selectedBusiness, getLivestockSaleId())
    ) {
      allOptions.push(selectedBusiness);
    }
    const sortOrder = [
      // In Sale already
      sortRecentBusinessType === BUYER
        ? o => saleLotIdsByBuyerIdLookup[o.id]
        : null,
      sortRecentBusinessType === VENDOR
        ? o => consignmentIdsByVendorIdLookup[o.id]
        : null,
      // Top Buyer (Invert, so that we sort true (1) to the top.)
      currentSpecies === Species.CATTLE ? o => !o.isTopBuyerCattle : null,
      currentSpecies === Species.SHEEP ? o => !o.isTopBuyerSheep : null,
      "name",
      // Short Code
      isSaleyardAdmin ? "shortCodeSaleyard" : "shortCode",
    ].filter(Boolean);
    return sortBy(allOptions, sortOrder);
  }, [
    businesses,
    tempBusinesses,
    sortRecentBusinessType,
    currentSpecies,
    isSaleyardAdmin,
    saleLotIdsByBuyerIdLookup,
    consignmentIdsByVendorIdLookup,
    selectedBusiness,
  ]);

  useUpdateFormWithOfflineLookup(name);

  // Adjust the filtering function to push a specific short code result to the top.
  // This is defined by the type of sale, and who is searching, so memoize the function call
  const filterOptions = useMemo(() => {
    const doesShortCodeMatch = (option, inputValue) =>
      isSaleyardAdmin
        ? caselessCompare(option.shortCodeSaleyard, inputValue)
        : caselessCompare(option.shortCode, inputValue);

    return (options, params) => {
      let filtered = filter(options, params).filter(o => !o.hide);

      const { inputValue } = params;

      // If we have a single match for a business short code, float it to the top.
      // (We don't know if a saleyard
      filtered = [
        ...filtered.filter(option => doesShortCodeMatch(option, inputValue)),
        ...filtered.filter(option => !doesShortCodeMatch(option, inputValue)),
      ];

      if (filtered.length > FILTER_LIMIT) {
        filtered = [
          {
            disabled: true,
            label: `Showing ${FILTER_LIMIT} of ${filtered.length} options`,
          },
          ...filtered.slice(0, FILTER_LIMIT),
        ];
      }

      // Suggest the creation of a new value
      if (allowAddNew && params.inputValue !== "") {
        filtered.push({
          inputValue: params.inputValue,
          label: `Add "${params.inputValue}"`,
        });
      }

      return filtered;
    };
  }, [isSaleyardAdmin, filter, allowAddNew]);

  return (
    <Autocomplete
      autoFocus={autoFocus}
      disabled={disabled}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      handleNew={addNewBusiness}
      label={label}
      name={name}
      inlineButton={inlineButton}
      onChangeExtra={onChangeExtra}
      options={options}
    />
  );
};
