import React from "react";

import { DialogActions, Grid } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { AuditLogTable } from "components/AuditLog/AuditLogTable";
import { Button } from "components/Form";
import { Dialog, DialogContent, DialogTitle } from "components/MaterialDialog";
import Property from "components/Property";

import { AuditLogTypes } from "constants/auditLog";

import { ForStaff } from "containers/ForUserType";
import { TranslatedSaleTypeLabel } from "containers/TranslatedSaleTypeLabel";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { closeAllHashModals, returnToLocation } from "lib/navigation";
import { getCombinedLotNumber } from "lib/saleLot";

import {
  getAuctionPenById,
  getBillingDocumentById,
  getBillingRunById,
  getBusinessById,
  getConsignmentById,
  getConsignmentsWithVendorProperties,
  getDeploymentIdBySaleLotId,
  getLedgerAccountById,
  getLedgerEntryById,
  getMasterRuleById,
  getPenScanLotById,
  getReceivalLotById,
  getRuleById,
  getSaleLotById,
  getVendorIdBySaleLotId,
  getWeighLotById,
  getWeighLotScanById,
} from "selectors";

const HeaderLabel = styled.span`
  font-weight: bold;
  padding: 0 6px 0 12px;
`;

const BodyArea = styled.div`
  ${({ theme }) => `
  background-color: ${theme.colors.collapseBackground};
  box-shadow: inset 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: 0 -${theme.space[2]}px;
`}
`;

const ConsignmentTitle = ({ consignmentId }) => {
  const consignment = useSelector(getConsignmentsWithVendorProperties).find(
    c => c.id === consignmentId,
  );
  if (!consignment) {
    return <span>Deleted Consignment</span>;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Vendor:</HeaderLabel> {consignment.vendorNumber}{" "}
        {consignment.vendor.name}
      </Grid>
      <Grid item xs={12}>
        <HeaderLabel>Hd:</HeaderLabel> {consignment.quantity}
      </Grid>
    </Grid>
  );
};

const SaleLotTitle = ({ saleLotId, returnTo }) => {
  const saleLot = useSelector(getSaleLotById(saleLotId)) || {};

  const vendor =
    useSelector(state =>
      getBusinessById(getVendorIdBySaleLotId(saleLotId)(state))(state),
    ) || {};
  const buyer = useSelector(getBusinessById(saleLot.buyer_id)) || {};
  const consignment =
    useSelector(getConsignmentById(saleLot.consignment_id)) || {};

  const deploymentId = useSelector(getDeploymentIdBySaleLotId(saleLotId));

  if (!saleLot) {
    return <span>Deleted Sale Lot</span>;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Sale Lot:</HeaderLabel> {getCombinedLotNumber(saleLot)}
      </Grid>
      <Grid item xs={12}>
        <HeaderLabel>Vendor:</HeaderLabel> {consignment.vendorNumber}{" "}
        {vendor.name}
      </Grid>
      <Grid item xs={12}>
        <HeaderLabel>Head:</HeaderLabel> {saleLot.quantity}
      </Grid>
      <Grid item xs={12}>
        <HeaderLabel>Buyer:</HeaderLabel>
        {saleLot.buyer_id ? (
          <>
            {buyer.name}{" "}
            <Property
              propertyId={saleLot.destinationPropertyId}
              masterBusinessId={saleLot.buyerId}
              deploymentId={deploymentId}
            />
            {saleLot.buyer_way?.name}
          </>
        ) : (
          "Unsold"
        )}
      </Grid>
      <Grid item xs={12}>
        <HeaderLabel>Base SaleLot:</HeaderLabel>
        <AuditLogLink
          auditLogType={AuditLogTypes.SALE_LOT}
          dataId={saleLotId}
          returnTo={returnTo}
        />
      </Grid>

      {isEmpty(saleLot.draftingAttributes) ? null : (
        <Grid item xs={12}>
          <HeaderLabel>Advanced Drafting:</HeaderLabel>
          <AuditLogLink
            auditLogType={AuditLogTypes.DRAFTING_ATTRIBUTES}
            dataId={saleLotId}
            returnTo={returnTo}
          />
        </Grid>
      )}
      {isEmpty(saleLot.clearingSaleAttributes) ? null : (
        <Grid item xs={12}>
          <HeaderLabel>Clearing Sale:</HeaderLabel>
          <AuditLogLink
            auditLogType={AuditLogTypes.CLEARING_SALE_ATTRIBUTES}
            dataId={saleLotId}
            returnTo={returnTo}
          />
        </Grid>
      )}
    </Grid>
  );
};
//

const SpecificBusinessTitle = ({ businessId, returnTo }) => {
  const business = useSelector(getBusinessById(businessId));

  if (!business) {
    return <span>Deleted Business</span>;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Business:</HeaderLabel> {business.name}
      </Grid>
      <ForStaff>
        <Grid item xs={12}>
          <HeaderLabel>Master Business:</HeaderLabel>
          <AuditLogLink
            auditLogType={AuditLogTypes.BUSINESS}
            dataId={businessId}
            returnTo={returnTo}
          />
        </Grid>
      </ForStaff>
    </Grid>
  );
};

const MasterRuleTitle = ({ masterRuleId }) => {
  const ruleName = useSelector(getMasterRuleById(masterRuleId))?.name;
  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Rule:</HeaderLabel> {ruleName}
      </Grid>
    </Grid>
  );
};

const RuleTitle = ({ ruleId }) => {
  const ruleName = useSelector(getRuleById(ruleId))?.name;
  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Rule:</HeaderLabel> {ruleName}
      </Grid>
    </Grid>
  );
};

const ScanTitle = ({ scanId }) => {
  const EID = useSelector(
    state =>
      Object.values(state.scanners.scans).find(s => s.id === scanId)?.EID,
  );
  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>EID:</HeaderLabel> {EID}
      </Grid>
    </Grid>
  );
};

const PenTitle = ({ penId }) => {
  const pen = useSelector(getAuctionPenById(penId));

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Pen:</HeaderLabel> {getAuctionPenDisplayName(pen)}
      </Grid>
    </Grid>
  );
};

const BillingRunTitle = ({ billingRunId }) => {
  const billingRun = useSelector(getBillingRunById(billingRunId));

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Billing Run:</HeaderLabel> {billingRun.name}
      </Grid>
    </Grid>
  );
};

const BillingDocumentTitle = ({ billingDocumentId }) => {
  const billingDocument = useSelector(
    getBillingDocumentById(billingDocumentId),
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Billing Document:</HeaderLabel> {billingDocument.type}{" "}
        {billingDocument.number}
      </Grid>
    </Grid>
  );
};

const LedgerAccountTitle = ({ ledgerAccountId }) => {
  const ledgerAccount = useSelector(getLedgerAccountById(ledgerAccountId));

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Ledger Account:</HeaderLabel> {ledgerAccount.code}
      </Grid>
    </Grid>
  );
};

const LedgerEntryTitle = ({ ledgerEntryId }) => {
  const ledgerEntry = useSelector(getLedgerEntryById(ledgerEntryId));

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Ledger Entry:</HeaderLabel> {ledgerEntry.ruleName}
      </Grid>
    </Grid>
  );
};

const ReceivalLotTitle = ({ receivalLotId }) => {
  const receivalLot = useSelector(getReceivalLotById(receivalLotId));
  const receivalPen = useSelector(getAuctionPenById(receivalLot.receivalPenId));

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Receival Lot:</HeaderLabel>{" "}
        {getAuctionPenDisplayName(receivalPen)} - {receivalLot.mark} (
        {receivalLot.quantity} Hd)
      </Grid>
    </Grid>
  );
};

const PenScanLotTitle = ({ penScanLotId }) => {
  const penScanLot = useSelector(getPenScanLotById(penScanLotId));
  const sellingPen = useSelector(getAuctionPenById(penScanLot.sellingPenId));

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Receival Lot:</HeaderLabel>{" "}
        {getAuctionPenDisplayName(sellingPen)} -{" "}
        {penScanLot.marks.map(mark => mark.location).join(", ")} (
        {penScanLot.quantity} Hd)
      </Grid>
    </Grid>
  );
};

const WeighLotTitle = ({ weighLotId }) => {
  const weighLot = useSelector(getWeighLotById(weighLotId)) || {};

  const { scaleName, lotNumber } = weighLot;

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Weigh Lot:</HeaderLabel> {lotNumber} - {scaleName}
      </Grid>
    </Grid>
  );
};

const WeighLotScanTitle = ({ weighLotScanId }) => {
  const weighLotScan = useSelector(getWeighLotScanById(weighLotScanId)) || {};

  const { eid } = weighLotScan;

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLabel>Weigh Lot Scan:</HeaderLabel> {eid}
      </Grid>
    </Grid>
  );
};

export const AuditLogModal = ({ returnTo, dataType, dataId, queryParams }) => {
  const onClose = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  let titleComponent = null;
  let subtitleComponent = null;
  switch (dataType) {
    case AuditLogTypes.CONSIGNMENT:
      titleComponent = (
        <>
          <TranslatedSaleTypeLabel label="Consignment" /> Change Log
        </>
      );
      subtitleComponent = <ConsignmentTitle consignmentId={dataId} />;
      break;
    case AuditLogTypes.SALE_LOT:
      titleComponent = "SaleLot Change Log";
      subtitleComponent = (
        <SaleLotTitle saleLotId={dataId} returnTo={returnTo} />
      );
      break;
    case AuditLogTypes.DRAFTING_ATTRIBUTES:
      titleComponent = "SaleLot Advanced Drafting Change Log";
      subtitleComponent = (
        <SaleLotTitle saleLotId={dataId} returnTo={returnTo} />
      );
      break;
    case AuditLogTypes.CLEARING_SALE_ATTRIBUTES:
      titleComponent = "SaleLot Clearing Sale Data Change Log";
      subtitleComponent = (
        <SaleLotTitle saleLotId={dataId} returnTo={returnTo} />
      );
      break;
    case AuditLogTypes.DEPLOYMENT_BUSINESS:
    case AuditLogTypes.SALEYARD_BUSINESS:
      titleComponent = "Business Change Log";
      subtitleComponent = <SpecificBusinessTitle businessId={dataId} />;
      break;
    case AuditLogTypes.RULE:
      titleComponent = "Rule Change Log";
      subtitleComponent = <RuleTitle ruleId={dataId} />;
      break;
    case AuditLogTypes.MASTER_RULE:
      titleComponent = "Master Rule Change Log";
      subtitleComponent = <MasterRuleTitle masterRuleId={dataId} />;
      break;
    case AuditLogTypes.SCAN:
      titleComponent = "Scan Change Log";
      subtitleComponent = <ScanTitle scanId={dataId} />;
      break;
    case AuditLogTypes.PEN:
      titleComponent = "Pen Change Log";
      subtitleComponent = <PenTitle penId={dataId} />;
      break;
    case AuditLogTypes.BILLING_RUN:
      titleComponent = "Billing Run Change Log";
      subtitleComponent = <BillingRunTitle billingRunId={dataId} />;
      break;

    case AuditLogTypes.BILLING_DOCUMENT:
      titleComponent = "Billing Document Change Log";
      subtitleComponent = <BillingDocumentTitle billingDocumentId={dataId} />;
      break;

    case AuditLogTypes.LEDGER_ENTRY:
      titleComponent = "Ledger Entry Change Log";
      subtitleComponent = <LedgerEntryTitle ledgerEntryId={dataId} />;
      break;

    case AuditLogTypes.RECEIVAL_LOT:
      titleComponent = "Receival Lot Change Log";
      subtitleComponent = <ReceivalLotTitle receivalLotId={dataId} />;
      break;

    case AuditLogTypes.PEN_SCAN_LOT:
      titleComponent = "Pen Scan Lot Change Log";
      subtitleComponent = <PenScanLotTitle penScanLotId={dataId} />;
      break;

    case AuditLogTypes.WEIGH_LOT:
      titleComponent = "Weigh Lot Change Log";
      subtitleComponent = <WeighLotTitle WeighLotId={dataId} />;
      break;

    case AuditLogTypes.WEIGH_LOT_SCAN:
      titleComponent = "Weigh Lot Scan Change Log";
      subtitleComponent = <WeighLotScanTitle WeighLotScanId={dataId} />;
      break;

    case AuditLogTypes.LEDGER_ACCOUNT:
      titleComponent = "Ledger Account Change Log";
      subtitleComponent = <LedgerAccountTitle ledgerAccountId={dataId} />;
      break;

    case AuditLogTypes.BUSINESS:
      titleComponent = "Master Business Change Log";
      break;

    default:
      titleComponent = "Unknown Data Type";
  }

  return (
    <Dialog open onClose={onClose} maxWidth="lg">
      <DialogTitle onClose={onClose}>{titleComponent}</DialogTitle>
      <DialogContent>
        {subtitleComponent}
        <BodyArea>
          <AuditLogTable
            dataType={dataType}
            dataId={dataId}
            queryParams={queryParams}
          />
        </BodyArea>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
