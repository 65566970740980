import React from "react";

import { useSelector } from "react-redux";

import AgGridTable from "components/AgGrid/AgGridContainer";
import TableWrapper from "components/AgGrid/TableWrapper";
import { Column } from "components/Layout";

import { AgGridTables } from "constants/aggrid";
import {
  BillingDocumentColId,
  BillingDocumentColumnDef,
  BusinessColId,
  BusinessColumnDef,
  CommissionColumnDef,
  LedgerEntryColumnDef,
} from "constants/columnDefinitions";

import { avgAggFunction } from "lib/agGrid/aggregators";

import { getActiveRole, getBillingTags, getProperties } from "selectors";

import { selectCommittedLedgerEntryData } from "selectors/aggrid/ledgerEntries";

const getRowId = params => params.data.ledgerEntry.id;
const aggFuncs = { anAggFunc: avgAggFunction };
const columnDefs = [
  {
    headerName: "Document",
    children: Object.values(BillingDocumentColumnDef).filter(
      ({ colId }) => colId !== BillingDocumentColId.ACTIONS,
    ),
  },
  {
    headerName: "Business",
    children: Object.values(BusinessColumnDef).filter(
      ({ colId }) => colId !== BusinessColId.ACTIONS,
    ),
  },
  {
    headerName: "Ledger Entry",
    children: Object.values(LedgerEntryColumnDef),
  },
  {
    headerName: "Commission",
    children: Object.values(CommissionColumnDef),
  },
];

export const LedgerEntryDetail = () => {
  const rowData = useSelector(selectCommittedLedgerEntryData);

  const billingTags = useSelector(getBillingTags);
  const userRoleSlug = useSelector(state => getActiveRole(state).slug);
  const propertyByIdLookup = useSelector(getProperties);
  const context = { billingTags, propertyByIdLookup, userRoleSlug };

  return (
    <Column fullHeight>
      <AgGridTable
        aggFuncs={aggFuncs}
        columnDefs={columnDefs}
        getRowId={getRowId}
        context={context}
        rowData={rowData}
        showGlobalSearchFilter={false}
        WrapperComponent={TableWrapper}
        tableName={AgGridTables.BILLING_LEDGER_ENTRIES_DETAIL}
      />
    </Column>
  );
};
