import { Big } from "big.js";
import { getIn, setIn, useFormikContext } from "formik";

import { PricingMethod } from "components/Billing/LedgerEntry/LedgerEntryPricingForm";

import { GstMethod } from "constants/billing";

export function calculateUnitPriceExPricingModel({
  unitAmount: unitAmountArg = "0",
  quantity: quantityArg = "1",
  isGstEnabled,
}) {
  // In a new form, if a user enters a unit price before the quantity,
  // or they clear the contents of the quantity default to a quantity of 1
  // otherwise the quantityArg should be a string
  const unitAmount = new Big(unitAmountArg);
  const quantity = new Big(quantityArg);
  const subtotal = unitAmount.times(quantity);
  const unitAmountInc = unitAmount.times(isGstEnabled ? 1.1 : 1);
  const taxAmount = subtotal.times(isGstEnabled ? 0.1 : 0);
  const totalInc = taxAmount.plus(subtotal);
  return {
    unitAmount,
    unitAmountInc,
    quantity,
    subtotal,
    taxAmount,
    totalInc,
  };
}

export function calculateTotalPriceExPricingModel({
  subtotal: totalArg = "0",
  quantity: quantityArg = "1",
  isGstEnabled,
}) {
  // In a new form, if a user enters a unit price before the quantity,
  // or they clear the contents of the quantity default to a quantity of 1
  // otherwise the quantityArg should be a string
  const subtotal = new Big(totalArg);
  const quantity = new Big(quantityArg);
  const unitAmount = new Big(0).eq(quantity)
    ? new Big(0)
    : subtotal.div(quantity);

  const unitAmountInc = unitAmount.times(isGstEnabled ? 1.1 : 1);
  const totalInc = subtotal.times(isGstEnabled ? 1.1 : 1);
  const taxAmount = subtotal.times(isGstEnabled ? 0.1 : 0);
  return {
    unitAmount,
    unitAmountInc,
    quantity,
    subtotal,
    taxAmount,
    totalInc,
  };
}

export const usePricingFormContext = (ns, onAfterPricingModelChanged) => {
  const { values, setValues } = useFormikContext();
  const fieldValues = getIn(values, ns);

  const pricingMethod = getIn(fieldValues, "pricingMethod");
  const gstMethod = getIn(fieldValues, "gstMethod");
  const unitAmount = getIn(fieldValues, "unitAmount");
  const unitAmountInc = getIn(fieldValues, "unitAmountInc");
  const quantity = getIn(fieldValues, "quantity");
  const subtotal = getIn(fieldValues, "subtotal");
  const totalInc = getIn(fieldValues, "totalInc");
  const taxAmount = getIn(fieldValues, "taxAmount");

  function setFieldValues(pricingModel) {
    const {
      unitAmount,
      unitAmountInc,
      quantity,
      subtotal,
      taxAmount,
      totalInc,
    } = pricingModel;
    const updatedFieldValues = {
      ...fieldValues,
      unitAmount: unitAmount.toFixed(2),
      unitAmountInc: unitAmountInc.toFixed(2),
      quantity: quantity.toFixed(4),
      subtotal: subtotal.toFixed(0),
      taxAmount: taxAmount.toFixed(0),
      totalInc: totalInc.toFixed(0),
    };
    setValues(
      ns !== "" ? setIn(values, ns, updatedFieldValues) : updatedFieldValues,
    );

    typeof onAfterPricingModelChanged === "function" &&
      onAfterPricingModelChanged(pricingModel);
  }

  function updateUnitPricingEx({ unitAmount, quantity, isGstEnabled }) {
    setFieldValues(
      calculateUnitPriceExPricingModel({ unitAmount, quantity, isGstEnabled }),
    );
  }

  function updateUnitPricingInc({
    unitAmount: unitAmountArg = "0",
    quantity: quantityArg = "1",
    isGstEnabled,
  }) {
    const unitAmountInc = new Big(unitAmountArg);
    const quantity = new Big(quantityArg || 1);
    const totalInc = unitAmountInc.times(quantity);
    const unitAmount = unitAmountInc.times(isGstEnabled ? 1 / 1.1 : 1);
    const subtotal = unitAmount.times(quantity || 1);
    const taxAmount = totalInc.times(isGstEnabled ? 0.1 / 1.1 : 0);

    setFieldValues({
      unitAmount,
      unitAmountInc,
      quantity,
      subtotal,
      taxAmount,
      totalInc,
    });
  }

  function updateTotalPricingFixed({
    subtotal: totalArg = "0",
    taxAmount: taxAmountArg = "1",
  }) {
    const subtotal = new Big(totalArg || 0);
    const taxAmount = new Big(taxAmountArg || 0);

    const updatedFieldValues = {
      ...fieldValues,
      subtotal: new Big(subtotal).toFixed(0),
      taxAmount: taxAmount.toFixed(0),
      totalInc: subtotal.plus(taxAmount).toFixed(0),
    };

    setValues(
      ns !== "" ? setIn(values, ns, updatedFieldValues) : updatedFieldValues,
    );
  }

  function updateTotalPricingEx({ subtotal, quantity, isGstEnabled }) {
    setFieldValues(
      calculateTotalPriceExPricingModel({
        subtotal,
        quantity,
        isGstEnabled,
      }),
    );
  }

  function updateTotalPricingInc({
    subtotal: totalArg = "0",
    quantity: quantityArg = "1",
    isGstEnabled,
  }) {
    const totalInc = new Big(totalArg);
    const quantity = new Big(quantityArg || 1);
    const unitAmountInc = totalInc.div(quantity || 1);
    const unitAmount = unitAmountInc.times(isGstEnabled ? 1 / 1.1 : 1);
    const subtotal = totalInc.times(isGstEnabled ? 1 / 1.1 : 1);
    const taxAmount = totalInc.times(isGstEnabled ? 0.1 / 1.1 : 0);

    setFieldValues({
      unitAmount,
      unitAmountInc,
      quantity,
      subtotal,
      taxAmount,
      totalInc,
    });
  }

  function onAfterGstMethodChanged(updatedGstMethod) {
    if (updatedGstMethod === GstMethod.GST_EXEMPT) {
      // When the GST Method changes 'from -> to':
      // Exempt -> Exclusive
      // Inclusive -> Exclusive
      const pricingModel = {
        unitAmount,
        quantity,
        isGstEnabled: false,
      };
      updateUnitPricingEx(pricingModel);
    } else if (
      gstMethod === GstMethod.GST_EXEMPT &&
      (updatedGstMethod === GstMethod.GST_INCLUSIVE ||
        updatedGstMethod === GstMethod.GST_EXCLUSIVE)
    ) {
      // When the GST Method changes 'from -> to':
      // Exempt -> Inclusive
      // Exempt -> Exclusive
      const pricingModel = {
        subtotal,
        quantity,
        isGstEnabled: true,
      };
      updateTotalPricingEx(pricingModel);
    }
  }

  function onAfterUnitPriceChanged(unitAmount) {
    if (unitAmount === null) {
      return;
    }
    const pricingModel = {
      unitAmount,
      quantity,
      isGstEnabled: gstMethod !== GstMethod.GST_EXEMPT,
    };
    if (quantity === null) {
      return;
    }
    if (gstMethod === GstMethod.GST_EXCLUSIVE) {
      updateUnitPricingEx(pricingModel);
    } else {
      updateUnitPricingInc(pricingModel);
    }
  }

  function onAfterQuantityChanged(quantity) {
    if (quantity === null) {
      return;
    }
    if (pricingMethod === PricingMethod.UNIT_PRICE) {
      const pricingModel = {
        unitAmount:
          gstMethod === GstMethod.GST_INCLUSIVE ? unitAmountInc : unitAmount,
        quantity,
        isGstEnabled: gstMethod !== GstMethod.GST_EXEMPT,
      };

      if (gstMethod === GstMethod.GST_EXCLUSIVE) {
        updateUnitPricingEx(pricingModel);
      } else {
        updateUnitPricingInc(pricingModel);
      }
    } else if (pricingMethod === PricingMethod.GROSS_PRICE) {
      const pricingModel = {
        subtotal: gstMethod === GstMethod.GST_INCLUSIVE ? totalInc : subtotal,
        quantity,
        isGstEnabled: gstMethod !== GstMethod.GST_EXEMPT,
      };
      if (gstMethod === GstMethod.GST_EXCLUSIVE) {
        updateTotalPricingEx(pricingModel);
      } else {
        updateTotalPricingInc(pricingModel);
      }
    }
  }

  function onAfterTotalPriceChanged(subtotal) {
    const pricingModel = {
      subtotal,
      quantity,
      isGstEnabled: gstMethod !== GstMethod.GST_EXEMPT,
    };
    if (subtotal === null || quantity === null) {
      return;
    }

    if (gstMethod === GstMethod.GST_FIXED) {
      updateTotalPricingFixed({ subtotal, taxAmount });
    } else if (gstMethod === GstMethod.GST_INCLUSIVE) {
      updateTotalPricingInc(pricingModel);
    } else {
      updateTotalPricingEx(pricingModel);
    }
  }

  function onAfterTaxAmountChanged(taxAmount) {
    updateTotalPricingFixed({ subtotal, taxAmount });
  }

  return {
    onAfterTotalPriceChanged,
    onAfterQuantityChanged,
    onAfterUnitPriceChanged,
    onAfterGstMethodChanged,
    onAfterTaxAmountChanged,
    updateTotalPricingInc,
    updateTotalPricingEx,
  };
};
