import { RuleValueFormat } from "constants/ruleBooks";

import {
  CUSTOM_QUERY_FIELD_ID,
  FieldValueType,
  ValueSource,
  ValueSourceExt,
} from "./constants";
import {
  getParentFieldId,
  getSchemaFieldValues,
  resolveAttributeValueType,
  resolveRelatedSchemaField,
  resolveRootSchemaField,
  RoundingMethod,
} from "./lib";

function convertToInternalSchemaField(
  dataSource,
  schema,
  parentFieldId = null,
) {
  const schemaField =
    parentFieldId !== null
      ? resolveRelatedSchemaField(dataSource.value, schema, parentFieldId)
      : resolveRootSchemaField(dataSource.value, schema);

  if (!schemaField && !dataSource.key) {
    return {
      source: ValueSource.FIELD,
      attribute: "",
      fieldId: CUSTOM_QUERY_FIELD_ID,
      customQuery: dataSource.value || "",
      valueType: dataSource.value_type || null,
    };
  } else if (!schemaField && dataSource.key) {
    return {
      source: ValueSource.FIELD,
      attribute: dataSource.key || "",
      fieldId: CUSTOM_QUERY_FIELD_ID,
      customQuery: dataSource.value || "",
      valueType: dataSource.value_type || null,
    };
  } else if (schemaField && dataSource.key) {
    return {
      source: ValueSource.FIELD,
      attribute: dataSource.key || "",
      fieldId: schemaField.id,
      customQuery: "",
      uniq: schemaField.uniq,
      valueType: resolveAttributeValueType(
        parentFieldId,
        schemaField.id,
        dataSource.key,
        schema,
      ),
    };
  } else if (schemaField.relations || schemaField.properties) {
    return {
      source: ValueSource.FIELD,
      attribute: "",
      fieldId: schemaField.id,
      customQuery: "",
      uniq: schemaField.uniq,
      valueType: null,
    };
  }
  return {
    source: ValueSource.FIELD,
    attribute: schemaField.id,
    fieldId: "",
    customQuery: "",
    uniq: schemaField.uniq,
    valueType: dataSource.value_type || schemaField.type || null,
  };
}

function convertToInternalConstantField(dataSource) {
  return {
    source: ValueSource.CONSTANT,
    value: dataSource.value === undefined ? "" : dataSource.value,
    valueType: dataSource.value_type || null,
  };
}

function convertToInternalSumField(
  dataSource,
  schema,
  parentFieldId,
  criteriaFieldIds,
) {
  const index =
    typeof dataSource.criteria_output_index === "number"
      ? dataSource.criteria_output_index.toString()
      : "";

  return {
    source: ValueSource.SUM,
    attribute: dataSource.value,
    index,
    valueType:
      dataSource.value_type ||
      resolveAttributeValueType(
        null,
        criteriaFieldIds[index],
        dataSource.value,
        schema,
      ) ||
      null,
  };
}

function convertToInternalCountField(dataSource) {
  const index =
    typeof dataSource.criteria_output_index === "number"
      ? dataSource.criteria_output_index.toString()
      : "";
  return {
    source: ValueSource.COUNT,
    index,
    valueType: dataSource.value_type || FieldValueType.INTEGER,
  };
}

function convertToInternalGlobalBusinessField(dataSource) {
  return {
    source: ValueSource.GLOBAL_BUSINESS,
    value: dataSource.value === undefined ? "" : dataSource.value,
    valueType: dataSource.value_type || FieldValueType.BUSINESS,
  };
}

function convertToInternalDataSource(
  dataSource,
  schema,
  parentFieldId,
  criteriaFieldIds = [],
) {
  if (dataSource === undefined) {
    return dataSource;
  } else if (dataSource.source === ValueSourceExt.FIELD) {
    return convertToInternalSchemaField(dataSource, schema, parentFieldId);
  } else if (dataSource.source === ValueSourceExt.CONSTANT) {
    return convertToInternalConstantField(dataSource, schema);
  } else if (dataSource.source === ValueSourceExt.SUM) {
    return convertToInternalSumField(
      dataSource,
      schema,
      parentFieldId,
      criteriaFieldIds,
    );
  } else if (dataSource.source === ValueSourceExt.COUNT) {
    return convertToInternalCountField(dataSource, schema, parentFieldId);
  } else if (dataSource.source === ValueSource.GLOBAL_BUSINESS) {
    return convertToInternalGlobalBusinessField(dataSource, schema);
  }
  // eslint-disable-next-line no-console
  console.error(
    'Unknown data source type "%s", passing through',
    dataSource.source,
  );
  return dataSource;
}

function parseBooleanValue(value) {
  if (value === "true" || value === true || value === 1) {
    return "true";
  } else if (value === "false" || value === false || value === 0) {
    return "false";
  }
  // eslint-disable-next-line no-console
  console.warn("Unexpected Boolean value %s", value);
  // this is not expected
  return value;
}

function parseValue(valueType, value) {
  if (valueType === FieldValueType.BOOLEAN) {
    return parseBooleanValue(value);
  }
  return value;
}

export function convertToInternalCheck(check, schema, parentFieldId) {
  const {
    value: externalValue,
    values: externalValues,
    ...externalCheck
  } = check;
  const internalCheck = { ...externalCheck };
  if (externalValue !== undefined && !Array.isArray(externalValues)) {
    // Migrate a v1 Rule Book `value` attr to `values`
    internalCheck.values = [externalValue];
  } else if (Array.isArray(externalValues) && externalValues.length > 0) {
    internalCheck.values = externalValues;
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      "Invalid Check schema: `value` was undefined and `values` was not an array, or was empty. Defaulting to an empty array",
      check,
    );
    internalCheck.values = [""];
  }

  internalCheck.field = convertToInternalDataSource(
    internalCheck.field,
    schema,
    parentFieldId,
  );
  if (
    internalCheck.field.source === ValueSource.FIELD &&
    // This check is fairly save as we have JUST resolved the field from a schema field
    internalCheck.field.fieldId !== CUSTOM_QUERY_FIELD_ID
  ) {
    // Perform all type conversion here
    const field = getSchemaFieldValues(
      internalCheck.field.fieldId || parentFieldId,
      schema,
      false,
    ).find(
      field => field.isProperty && field.id === internalCheck.field.attribute,
    );
    if (field) {
      internalCheck.values = internalCheck.values.map(value =>
        parseValue(field.type, value),
      );
    }
  }
  return internalCheck;
}

export function convertToInternalRelatedDataQuery(
  relatedDataQuery,
  schema,
  parentFieldId,
) {
  if (!relatedDataQuery) {
    return {
      fieldId: null,
      customQuery: "",
      valueType: null,
    };
  }
  const relatedQueryField = resolveRelatedSchemaField(
    relatedDataQuery.path,
    schema,
    parentFieldId,
  );

  const fieldId = relatedQueryField?.id || CUSTOM_QUERY_FIELD_ID;

  return {
    fieldId,
    via: relatedQueryField?.via,
    customQuery: fieldId === CUSTOM_QUERY_FIELD_ID ? relatedDataQuery.path : "",
    valueType: null,
  };
}

export function convertToInternalCriteria(criteria, schema, parentFieldId) {
  const { checks, ...internalCriteria } = criteria;

  internalCriteria.query = internalCriteria.query
    ? convertToInternalRelatedDataQuery(
        internalCriteria.query,
        schema,
        parentFieldId,
      )
    : null;

  const checkParentFieldId = getParentFieldId(
    internalCriteria.query,
    parentFieldId,
  );
  const checkGroups =
    checks.length > 0 ? (Array.isArray(checks[0]) ? checks : [checks]) : [];

  internalCriteria.checkGroups = checkGroups.map(checkGroup =>
    checkGroup.map(check =>
      convertToInternalCheck(check, schema, checkParentFieldId),
    ),
  );

  return internalCriteria;
}

export function convertToInternalValue(
  ruleValue,
  schema,
  parentFieldId,
  criteriaFieldIds,
) {
  const internalRuleValue = {
    amount: convertToInternalDataSource(
      ruleValue.amount,
      schema,
      parentFieldId,
      criteriaFieldIds,
    ),
    units: convertToInternalDataSource(
      ruleValue.units,
      schema,
      parentFieldId,
      criteriaFieldIds,
    ),
  };

  if (ruleValue.offset) {
    // `offset` was originally the unit price offset, which was deprecated 03/2022.
    // This has been superseded by the combination of `offset` (total price offset) and
    // `unit_offset`, which results in a `calculated_units` after first applying the `unit_limit`.
    internalRuleValue.offset = convertToInternalDataSource(
      ruleValue.offset,
      schema,
      parentFieldId,
      criteriaFieldIds,
    );
  } else {
    internalRuleValue.offset = convertToInternalConstantField({ value: 0 });
  }

  if (ruleValue.unit_offset) {
    // `unit_offset` was introduced as part of migrating away from having a _calculated_, _clamped_
    // and _offset_ unit price in favor of applying clamping, and offset to the unit count instead and
    // provided a total price offset in 03/2022
    internalRuleValue.unit_offset = convertToInternalDataSource(
      ruleValue.unit_offset,
      schema,
      parentFieldId,
      criteriaFieldIds,
    );
  } else {
    internalRuleValue.unit_offset = convertToInternalConstantField({
      value: 0,
    });
  }

  if (ruleValue.unit_limit) {
    // `limit`, which was originally a unit price limit, was deprecated 03/2022 in favour of a unit count limit.
    internalRuleValue.unit_limit = convertToInternalDataSource(
      ruleValue.unit_limit,
      schema,
      parentFieldId,
      criteriaFieldIds,
    );
  } else {
    internalRuleValue.unit_limit = convertToInternalConstantField({ value: 0 });
  }
  internalRuleValue.rounding_strategy =
    ruleValue.rounding_strategy === undefined
      ? RoundingMethod.NONE
      : ruleValue.rounding_strategy;

  internalRuleValue.rounding_value =
    ruleValue.rounding_value === undefined ? 1 : ruleValue.rounding_value;
  internalRuleValue.output_as_single_unit =
    ruleValue.output_as_single_unit === undefined
      ? false
      : ruleValue.output_as_single_unit;

  return internalRuleValue;
}

function convertToInternalFee(fee, schema, parentFieldId, criteriaFieldIds) {
  const internalFee = {
    apportionFromSource: fee.apportion_from_source,
    enabledQuery: convertToInternalDataSource(
      fee.enabled_query,
      schema,
      parentFieldId,
      criteriaFieldIds,
    ),
    name: fee.name,
    operation: fee.operation,
    operationInput: convertToInternalDataSource(
      fee.operation_input,
      schema,
      parentFieldId,
      criteriaFieldIds,
    ),
  };

  if (typeof fee.source_index === "number") {
    internalFee.sourceIndex = fee.source_index;
  }

  return internalFee;
}

export function convertToInternalRule(rule, schema) {
  const ruleContent = JSON.parse(rule.content || "null");
  const internalRule = {
    ...ruleContent,
    ...rule,
  };

  internalRule.input_field = convertToInternalSchemaField(
    { value: ruleContent.input_field },
    schema,
  );

  const parentFieldId = internalRule.input_field.fieldId;

  const criteriaFieldIds = [];

  internalRule.criteria = internalRule.criteria.map(externalCriteria => {
    const internalCriteria = convertToInternalCriteria(
      externalCriteria,
      schema,
      parentFieldId,
      criteriaFieldIds,
    );
    const criteriaFieldId = internalCriteria.query
      ? internalCriteria.query.fieldId
      : parentFieldId;
    criteriaFieldIds.push(criteriaFieldId);
    return internalCriteria;
  });

  internalRule.from_business = convertToInternalDataSource(
    ruleContent.from_business,
    schema,
    parentFieldId,
  );
  internalRule.to_business = convertToInternalDataSource(
    ruleContent.to_business,
    schema,
    parentFieldId,
  );

  internalRule.value = convertToInternalValue(
    ruleContent.value,
    schema,
    parentFieldId,
    criteriaFieldIds,
  );

  const fees = Array.isArray(ruleContent.fees) ? ruleContent.fees : [];

  internalRule.fees = fees.map(fee =>
    convertToInternalFee(fee, schema, parentFieldId, criteriaFieldIds),
  );

  // Migrate older Rules to have a Category
  internalRule.category = ruleContent.category || "";
  internalRule.billing_tags = ruleContent.billing_tags || [];

  internalRule.quantity_output_format =
    internalRule.quantity_output_format || RuleValueFormat.UNITS;

  internalRule.quantity_raw_format =
    internalRule.quantity_raw_format || RuleValueFormat.UNITS;

  internalRule.unit_amount_output_format =
    internalRule.unit_amount_output_format || RuleValueFormat.DOLLARS;

  internalRule.unit_amount_raw_format =
    internalRule.unit_amount_raw_format || RuleValueFormat.CENTS;

  // Multiplier allows multiplication of the output - multiplying by 1 has 0 effect, so migrate old rules to that.
  internalRule.price_multiplier =
    ruleContent.price_multiplier === undefined
      ? 1
      : ruleContent.price_multiplier;

  return internalRule;
}
