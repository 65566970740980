export const AuditLogTypes = {
  ALTERNATIVES: "alternatives",
  BILLING_DOCUMENT: "billing_document",
  BILLING_RUN: "billing_run",
  BUSINESS: "business",
  CLEARING_SALE_ATTRIBUTES: "clearing_sale_attributes",
  CONSIGNMENT: "consignment",
  DEPLOYMENT_BUSINESS: "deployment_business",
  SALEYARD_BUSINESS: "saleyard_business",
  DRAFTING_ATTRIBUTES: "drafting_attributes",
  LEDGER_ACCOUNT: "ledger_account",
  MASTER_RULE: "master_rule",
  LEDGER_ENTRY: "ledger_entry",
  PEN: "pen",
  PEN_SCAN_LOT: "pen_scan_lot",
  RECEIVAL_LOT: "receival_lot",
  RULE: "rule",
  SALE_LOT: "sale_lot",
  SCAN: "scan",
  WEIGH_LOT: "weigh_lot",
  WEIGH_LOT_SCAN: "weigh_lot_scan",
};

export const AuditLogHistoryTypes = {
  "-": "Removed",
  "+": "Created",
  "~": "Updated",
};

export const AuditLogFieldNames = {
  [AuditLogTypes.SCAN]: {
    ERP_status: "ERP Status",
    EU_status: "EU Status",
    animal: "RFID", // Ehh?
    cbid: "Internal ID",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    created: "Created",
    last_modified: "Last Modified",
    deployment_sale: "Agency", // vom
    current_auction_pen: "Current Auction Pen",
    current_pic: "Current PIC", // A string value of the PIC
    current_property: "Current PIC Reference", // The reference to the Property object
    drafting: "Scanner",
    id: "Internal Id",
    is_manually_weighed: "Is Manually Weighed",
    last_nlis_check: "Last NLIS Check",
    lifetime_traceability: "Lifetime Traceable",
    nlis_p2p_status: "NLIS P2P Status",
    nlis_sell_status: "NLIS Sell Status",
    nlis_take_status: "NLIS Take Status",
    sale_lot: "SaleLot",
    time_weighed: "Time Weighed",
    total_mass_grams: "Animal Weight", // eh.
  },
  [AuditLogTypes.DEPLOYMENT_BUSINESS]: {
    address: "Address",
    agent_commission_rate: "Agent Commission Rate",
    branch: "Branch",
    business: "Global Business Name",
    cbid: "Internal Id",
    commission_offset: "Commission Offset",
    created: "Created",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    default_consigning_deployment: "Default Consigning Agency",
    deployment: "Agency",
    deployment_business_name: "Name",
    has_debtor_insurance: "Has Debtor Insurance",
    has_transit_insurance: "Has Transit Insurance",
    id: "Internal Id",
    is_active: "Is Active",
    is_top_buyer_cattle: "Is Cattle Buyer",
    is_top_buyer_sheep: "Is Top Sheep Buyer",
    last_modified: "Last Modified",
    last_reviewed: "Last Reviewed",
    name: "Name",
    notes: "Notes",
    public_display_name: "Public Display Name",
    short_code: "Short Code - Agency",
    short_code_auctions_plus: "Short Code - Auctions Plus",
    short_code_saleyard: "Short Code - Saleyard",
    should_charge_gst_override: "Should Charge GST Override",
    slug: "Unique Identifier",
  },
  [AuditLogTypes.BUSINESS]: {
    abn: "ABN",
    abr_last_checked: "ABR Last Checked",
    acn: "ACN",
    banking_details: "Banking Details",
    block_merging: "Block Merging",
    cbid: "Internal Id",
    created: "Created",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    deployment: "Deployment",
    duplicate_of: "Duplicate Of",
    gst_registered_from: "GST Registered From",
    gst_registered_to: "GST Registered To",
    id: "Internal Id",
    is_buyer: "Is Buyer",
    is_feedlot: "Is Feedlot",
    is_global: "Is Global",
    is_gst_registered: "Is GST Registered",
    is_hobby_farmer: "Is Hobby Farmer",
    is_live_exporter: "Is Live Exporter",
    is_meat_processor_commercial_buyer: "Is Meat Processor Commercial Buyer",
    is_published: "Is Published",
    is_transporter: "Is Transporter",
    is_vendor: "Is Vendor",
    is_visible_globally: "Is Visible Globally",
    last_modified: "Last Modified",
    livestock_agent: "Livestock Agent",
    name: "Name",
    trading_name: "Trading Name",
  },
  [AuditLogTypes.SALEYARD_BUSINESS]: {
    address: "Address",
    agent_commission_rate: "Agent Commission Rate",
    branch: "Branch",
    business: "Global Business Name",
    cbid: "Internal Id",
    commission_offset: "Commission Offset",
    created: "Created",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    default_consigning_deployment: "Default Consigning Agency",
    deployment: "Agency",
    deployment_business_name: "Name",
    has_debtor_insurance: "Has Debtor Insurance",
    has_transit_insurance: "Has Transit Insurance",
    id: "Internal Id",
    is_active: "Is Active",
    is_top_buyer_cattle: "Is Cattle Buyer",
    is_top_buyer_sheep: "Is Top Sheep Buyer",
    last_modified: "Last Modified",
    last_reviewed: "Last Reviewed",
    name: "Name",
    notes: "Notes",
    public_display_name: "Public Display Name",
    short_code: "Short Code - Agency",
    short_code_auctions_plus: "Short Code - Auctions Plus",
    short_code_saleyard: "Short Code - Saleyard",
    should_charge_gst_override: "Should Charge GST Override",
    slug: "Unique Identifier",
  },
  [AuditLogTypes.CLEARING_SALE_ATTRIBUTES]: {
    category: "Category",
    comments: "Comments",
    created: "Created",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    hours: "Hours",
    id: "Internal Id",
    inspection: "Inspection",
    item_condition: "Item Condition",
    item_type: "Item Type",
    last_modified: "Last Modified",
    make: "Make",
    model: "Model",
    new_used: "New/Used",
    odometer: "Odometer",
    ppsr_comments: "PPSR Comments",
    ppsr_status: "PPSR Status",
    public_description: "Public Description",
    registration: "Registration",
    salelot: "SaleLot",
    statu: "Status",
    sub_type: "Sub Type",
    title: "Title",
    vin: "VIN",
    year: "Year",
  },
  [AuditLogTypes.DRAFTING_ATTRIBUTES]: {
    accreditation_antibiotic_free: "Accredited Antibiotic Free",
    accreditation_backgrounded: "Accredited Backgrounded",
    accreditation_eu: "Accredited EU ",
    accreditation_grass_fed: "Accredited Grass Fed",
    accreditation_jbas: "Accredited JBAS",
    accreditation_nee: "Accredited NEE",
    accreditation_organic: "Accredited Organic",
    accreditation_pcas: "Accredited PCAS",
    accreditation_pte: "Accredited PTE",
    accreditation_ptic: "Accredited PTIC",
    advanced_breed_1: "Advanced Breed 1",
    advanced_breed_1_hd: "Advanced Breed 1 Head",
    advanced_breed_2: "Advanced Breed 2",
    advanced_breed_2_hd: "Advanced Breed 2 Head",
    advanced_breed_3: "Advanced Breed 3",
    advanced_breed_3_hd: "Advanced Breed 3 Head",
    age_range_time_unit: "Age Range Time Unit",
    animal_health_statement_override:
      "Animal Health Statement Supplied Override",
    b12_vac: "B12 Vac",
    backline: "Backline",
    bloodline: "Bloodline",
    by_sire: "By Sire",
    condition_forward: "Condition: Forward",
    condition_prime: "Condition: Prime",
    condition_store: "Condition: Store",
    created: "Created",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    dentition_0: "Dentition Lamb Head",
    dentition_2: "Dentition 2T Head",
    dentition_4: "Dentition 4T Head",
    dentition_6: "Dentition 6T Head",
    dentition_8: "Dentition 8T Head",
    dentition_broken: "Dentition Broken Head",
    depastured: "Depastured",
    drenched: "Drenched",
    dressing_percent: "Dressing %",
    drop_end: "Drop End",
    drop_start: "Drop Start",
    est_carcass_weight: "Estimated Carcass Weight",
    fat_score_1: "Fat Score 1 Head",
    fat_score_2: "Fat Score 2 Head",
    fat_score_3: "Fat Score 3 Head",
    fat_score_4: "Fat Score 4 Head",
    fat_score_5: "Fat Score 5 Head",
    fat_score_6: "Fat Score 6 Head",
    feeder: "AssessedFeeder",
    frame_structure: "Frame Structure",
    grain_fed_days: "Grain Fed Days",
    gudair_approved: "Gudair Approved",
    horn_dehorned: "Horned - Dehorned Head",
    horn_horned: "Horned - Horned Head",
    horn_polled: "Horned - Polled Head",
    horn_scurred: "Horned - Scurred Head",
    horn_tipped: "Horned - Tipped Head",
    id: "Internal Id",
    is_estimated_weight: "Is Estimated Weight",
    is_not_mouthed: "Is Not Mouthed",
    is_weaned: "Is Weaned",
    joined_end: "Joined End",
    joined_start: "Joined Start",
    last_modified: "Last Modified",
    m_and_ts: "M and Ts",
    max_age: "Age Maximum",
    max_mass_grams: "Weight Maximum",
    min_age: "Age Minimum",
    min_mass_grams: "Weight Minimum",
    muscle_score_1: "Muscle Score 1 Head",
    muscle_score_2: "Muscle Score 2 Head",
    muscle_score_3: "Muscle Score 3 Head",
    muscle_score_4: "Muscle Score 4 Head",
    muscle_score_5: "Muscle Score 5 Head",
    not_station_mated: "Not Station Mated",
    ojd_vac: "OJD Vac",
    pain_relief: "Pain Relief",
    part_of_run: "Part of Run",
    preg_status: "Preg Status",
    public_description: "Public Description",
    public_fault_description: "Public Fault Description",
    sa_eligible: "Saudi Arabia Eligible",
    salelot: "SaleLot",
    shearing: "Shearing",
    shearing_status: "Shearing Status",
    sil: "SIL",
    six_in_one: "6-in-1",
    station_mated: "Station Mated",
    tag_number: "Tag Number",
    vendor_bred_override: "Is Vendor Bred Override",
    weigh_location: "Weigh Location",
    x_bloodline: "X-Bloodline",
  },
  [AuditLogTypes.SALE_LOT]: {
    age: "Age",
    auction_pen: "Auction Pen",
    bid_increment: "Bid Increment",
    breed: "Breed",
    buyer: "Buyer",
    buyer_number: "Buyer Number",
    buyer_way: "Buyer Way",
    buyers_premium: "Buyers Premium",
    category: "Category",
    cbid: "Internal ID",
    clearing_sale_attributes: "Clearing Sale Attributes",
    comment_thread: "Comments",
    consignment: "Consignment",
    created: "Created",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    delivery_pen: "Delivery Pen",
    destination_property: "Buyer PIC",
    drafting_attributes: "Drafting Attributes",
    earliest_pickup_date: "Earliest Pickup Date",
    estimated_average_mass_grams: "Estimated Average Weight",
    exemption: "Exemption",
    first_sold: "First Sold",
    grade: "Grade",
    id: "Internal ID",
    invoice_to_business: "Invoice To",
    is_gst_exempt: "Is GST Exempt",
    last_modified: "Last Modified",
    last_scans_detroy_time_client: "Last Scan Deleted Time",
    last_scans_detroy_time_database: "Last Scan Deleted Server Time",
    latest_pickup_date: "Latest Pickup Date",
    listing_fee: "Listing Fee",
    listing_id: "Listing ID",
    lot_number: "Lot Number",
    lot_number_suffix: "Lot Number Suffix",
    note: "Note",
    old_lot_number: "Old Lot Number",
    original_listing_price: "Original Listing Price",
    overflow_pen: "Overflow Pen",
    overflow_quantity: "Overflow Pen Head",
    pickup_address: "Pickup Address",
    pickup_notes: "Pickup Notes",
    pricing_type: "Sold As",
    quantity: "Head",
    quantity_delivered: "Delivered Head",
    quantity_progeny: "Head - Progeny",
    quantity_tags_used: "Saleyard Tags Used",
    reserve_price: "Reserve Price",
    sale_round: "Sale Round",
    salelot_type: "Type",
    sex: "Sex",
    starting_bid: "Starting Bid",
    third_party: "Third Party",
    time_weighed: "Time Weighed",
    total_mass_grams: "Weight",
    total_price_cents: "Price (Total)",
    youtube_link: "YouTube Link",
  },
  [AuditLogTypes.CONSIGNMENT]: {
    Declarationbobbycalfdeclaration_0720: "Bobby Calf NVD",
    Declarationcattledeclaration: "Cattle NVD",
    Declarationeucattledeclaration: "EU Cattle NVD",
    Declarationsheepdeclaration: "Sheep NVD",
    brands: "Brands",
    NVD: "NVD Number",
    NVD_status: "NVD Status",
    carrier_charge: "Carrier Charge",
    cbid: "Internal ID",
    consigned_from: "Consigned From",
    created: "Created",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    declaration_id: "Internal NVD Id",
    declaration_type: "Declaration Type",
    deployment_sale: "Agency", // vom
    earliest_pickup_date: "Earliest Pickup Date",
    has_arrived: "Has Arrived",
    id: "Internal ID",
    last_modified: "Last Modified",
    latest_pickup_date: "Latest Pickup Date",
    nomination: "Nomination",
    notes: "Notes",
    pickup_address: "Pickup Address",
    quantity: "Head",
    quantity_NVD: "NVD Head",
    quantity_dead: "Head Dead",
    receiving_pen: "Receiving Pen",
    receiving_reference: "Receiving Reference",
    use_vendor_address_as_pickup_address:
      "Use Vendor Address As Pickup Address",
    vendor: "Vendor",
    vendor_commission: "Vendor Commission",
    vendor_note: "Vendor Note",
    vendor_number: "Vendor Number",
    vendor_property: "Vendor PIC",
  },
  [AuditLogTypes.ALTERNATIVES]: {
    hasDebtorInsurance: "Has Debtor Insurance",
    hasTransitInsurance: "Has Transit Insurance",
    isActive: "Is Active",
    isTopBuyerCattle: "Is Top Buyer Cattle",
    isTopBuyerSheep: "Is Top Buyer Sheep",
    name: "Name",
    publicDisplayName: "Public Display Name",
    shortCode: "Agent Reference",
    shortCodeAuctionsPlus: "Auctions Plus Short Code",
    shortCodeSaleyard: "Saleyard Reference",
    slug: "List2Sell Agent Ref",
    first_name: "First Name",
    last_name: "Last Name",
    phone_number: "Phone Number",
    is_commercial_report_recipient: "Is Commercial Report Recipient",
    is_compliance_report_recipient: "Is Compliance Report Recipient",
    is_envd_recipient: "Is eNVD Recipient",
  },
  [AuditLogTypes.BILLING_RUN]: {
    comments: "comments",
    ledger_entries_generated_datetime: "Ledger Entries Generated Date and Time",
    name: "Name",
    status: "Status",
    rule_book: "Rule Book",
    deployment: "Deployment",
    export_snapshot: "Export Snapshot",
    exported_at_datetime: "Exported At",
    rule_book_snapshot: "Rule Book Snapshot",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    created: "Created",
    last_modified: "Last Modified",
  },
  [AuditLogTypes.BILLING_DOCUMENT]: {
    status: "Status",
    billing_run: "Billing Run",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    created: "Created",
    last_modified: "Last Modified",
    issuer_business: "Issuer",
    recipient_business: "Recipient",
    gl_code: "GL Code",
    number: "Number",
    type: "Type",
    xero_branding_theme: "Xero Branding Theme",
    id: "Internal ID",
    cbid: "Internal ID",
  },
  [AuditLogTypes.LEDGER_ACCOUNT]: {
    name: "Name",
    code: "Code",
    description: "Description",
    master_ledger_account: "Master Ledger Account",
    xero_account_id: "Xero ID",
    status: "Status",
    account_type: "Account Type",
    cbid: "Internal ID",
    id: "Internal ID",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    created: "Created",
    last_modified: "Last Modified",
    deployment: "Deployment",
    saleyard: "Saleyard",
  },
  [AuditLogTypes.LEDGER_ENTRY]: {
    category: "Category",
    description: "Description",
    document: "Document",
    gl_code: "GL Code",
    include_if_zero: "Include If Zero",
    invoice_category: "Invoice Category",
    is_modified: "Is Modified",
    item_id: "Item ID",
    item_type: "Item Type",
    mincenous_id: "MinceNous ID",
    mincenous_ledger_entry: "MinceNous Ledger Entry",
    notes: "Notes",
    quantity: "Quantity",
    rule_name: "Rule Name",
    tax_amount: "Tax Amount",
    tax_type: "Tax Type",
    total: "Total",
    unit_amount: "Unit Amount",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    created: "Created",
    last_modified: "Last Modified",
    cbid: "Internal ID",
    id: "Internal ID",
    status: "Status",
    subtotal: "Subtotal",
    version: "Internal Version",
  },

  [AuditLogTypes.RECEIVAL_LOT]: {
    cbid: "Internal ID",
    comment_thread: "Comments",
    consignment: "Consignment",
    created: "Created",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    id: "Internal ID",
    last_modified: "Last Modified",
    livestock_sale: "Livestock Sale",
    mark: "Mark",
    quantity: "Quantity",
    receival_pen: "Receival Pen",
  },
  [AuditLogTypes.PEN_SCAN_LOT]: {
    cbid: "Internal ID",
    comment_thread: "Comments",
    deployment: "Agency",
    is_locked: "Is Locked",
    sale_round: "Sale Round",
    created: "Created",
    database_created: "Server Created",
    database_last_modified: "Server Last Modified",
    id: "Internal ID",
    last_modified: "Last Modified",
    livestock_sale: "Livestock Sale",
    marks: "Marks",
    quantity: "Quantity",
    selling_pen: "Selling Pen",
  },
  [AuditLogTypes.WEIGH_LOT]: {
    cbid: "Internal ID",
    comment_thread: "Comments",
    lot_number: "Lot Number",
    weigh_datetime: "Weigh Date Time",
    file_name: "File Name",
    livestock_sale: "Livestock Sale",
    scale_name: "Scale Name",
    total_mass_grams: "Total Mass Grams",
    last_modified: "Last Modified",
  },
  [AuditLogTypes.WEIGH_LOT_SCAN]: {
    cbid: "Internal ID",
    comment_thread: "Comments",
    eid: "EID",
    status: "Status",
    total_mass_grams: "Total Mass Grams",
    weigh_lot_id: "Weigh Lot Internal ID",
    last_modified: "Last Modified",
  },
};
