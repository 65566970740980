import React, { memo } from "react";

import {
  faCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { requestPropertyERPCheck } from "actions";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { SubtleBadge } from "components/Badge";
import { Chip } from "components/Chip";
import { Button, CollapseLabel, FormCollapse } from "components/Form";
import { Column, Row } from "components/Layout";
import { AddDetails } from "components/NlisAddDetails";
import { BoldText, Subtitle } from "components/Text";

import { getStatus } from "constants/erpCodes";
import { NlisProgram } from "constants/nlis";
import { userTypes } from "constants/users";

import { getLPAStatusFromPrograms, isLpaAccredited } from "lib/properties";
import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

import {
  getConsignmentById,
  getDeploymentSaleById,
  getHasEnteredNlisCreds,
  selectIsUserOfType,
} from "selectors";

const FormContainer = styled.div`
  margin-left: 18px;
  width: 100%;
`;

const InfoContainer = styled.div`
  width: 50%;
  margin-right: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    width: 100%;
    min-width: 50%;
    margin-bottom: 10px;
  }
`;

const Header = memo(({ consignmentId }) => {
  const consignment = useSelector(getConsignmentById(consignmentId)) || {};
  const { nlisPrograms = [] } = consignment;
  const lpaStatus = getLPAStatusFromPrograms(nlisPrograms);

  const isNLISAwareUser = useSelector(
    selectIsUserOfType([userTypes.SALEYARD_ADMIN, userTypes.LIVESTOCK_AGENT]),
  );
  const hasEnteredNLISCredentials = useSelector(getHasEnteredNlisCreds);

  const showAddNLISButton = !hasEnteredNLISCredentials && isNLISAwareUser;

  return (
    <Row>
      <SubtleBadge>NLIS</SubtleBadge>
      {consignment.vendor_property_id && (
        <CollapseLabel>
          <Chip>
            LPA &nbsp; <LPAStatus status={lpaStatus} />
          </Chip>
          {nlisPrograms.map(({ code, status }) => (
            <Chip key={`${code}:${status}`}>
              {code}:{status}
            </Chip>
          ))}
        </CollapseLabel>
      )}
      <AddDetails isVisible={showAddNLISButton} />
    </Row>
  );
});

function LPAStatus({ status }) {
  if (status) {
    if (isLpaAccredited(status)) {
      return <FaIcon icon={faCheck} color="success" />;
    } else {
      return <FaIcon icon={faExclamationCircle} color="warning" />;
    }
  }
  return "No Data - We will perform a check soon";
}

export const NLISCollapse = props => {
  const { isOpen, onToggle, consignmentId } = props;
  const consignment = useSelector(getConsignmentById(consignmentId)) || {};
  const { nlisPrograms = [] } = consignment;
  const lpaStatus = getLPAStatusFromPrograms(nlisPrograms);

  const dispatch = useDispatch();

  const doERPCheck = () =>
    dispatch(requestPropertyERPCheck(consignment.vendor_property_id));

  const isConfirmed = useSelector(
    getDeploymentSaleById(consignment.deployment_sale),
  )?.is_confirmed;

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<Header consignmentId={consignmentId} />}
    >
      <FormContainer>
        {!consignment.vendor_property_id ? (
          <div>Set the vendors PIC to view the NLIS ERP Status.</div>
        ) : (
          <>
            <Row justifyBetween>
              <InfoContainer data-tour="lpaStatus">
                <Subtitle>LPA Status</Subtitle>
                <BoldText>
                  <LPAStatus status={lpaStatus} />
                </BoldText>
              </InfoContainer>
              <Column>
                {isConfirmed &&
                  "Sale is confirmed, updates have been disabled."}
                <Button
                  type="button"
                  onClick={doERPCheck}
                  disabled={isConfirmed}
                >
                  Recheck PIC status
                </Button>
                <div>
                  Last Checked{" "}
                  {consignment.nlisProgramsLastUpdated
                    ? formatUTCToLocalDateTimeString(
                        consignment.nlisProgramsLastUpdated,
                      )
                    : "-"}
                </div>
              </Column>
            </Row>
            {nlisPrograms
              .filter(program => program.code !== NlisProgram.LPA)
              .map(({ code, status }) => (
                <React.Fragment key={`${code}${status}`}>
                  <InfoContainer data-tour="programStatus">
                    <Subtitle>Program Code</Subtitle>
                    <BoldText>{code}</BoldText>
                  </InfoContainer>
                  <InfoContainer data-tour="status">
                    <Subtitle>Status Code</Subtitle>
                    <BoldText>{status}</BoldText>
                  </InfoContainer>
                  <InfoContainer data-tour="statusDescription">
                    <Subtitle>Status Code Description</Subtitle>
                    <BoldText>{getStatus(`${code}${status}`)}</BoldText>
                  </InfoContainer>
                </React.Fragment>
              ))}
          </>
        )}
      </FormContainer>
    </FormCollapse>
  );
};

NLISCollapse.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  consignmentId: PropTypes.string,
};
