import React from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "lodash";
import { useSelector } from "react-redux";

import { ClickableColumn } from "lib/agGrid/columns/columnStyles";
import { openEditBusinessModal } from "lib/navigation";

import { getBusinesses } from "selectors";

// Takes a list of business ids, and renders the names as clickable links.
export const BusinessesRenderer = ({ node, colDef, data }) => {
  const value = get(data, colDef.field);
  const businessByIdLookup = useSelector(getBusinesses);

  if (node.group || !value) {
    return null;
  }

  return value.map(businessId => {
    const businessName = businessByIdLookup[businessId]?.name;
    return (
      <ClickableColumn
        key={businessId}
        onClick={() => openEditBusinessModal(businessId)}
      >
        {businessName}
        <FontAwesomeIcon icon={faPencil} />
      </ClickableColumn>
    );
  });
};
