import { flatten, sum } from "lodash";
import { createSelector } from "reselect";

import { IntegrationTypes } from "constants/integrations";
import { saleLotStatuses } from "constants/sale";

import {
  getAuctionPens,
  getBusinesses,
  getConsignmentsWithAttachments,
  getDeployments,
  getNominations,
  getReceivalLots,
  getSaleLots,
  getStatusBySaleLotId,
  selectActiveNominationTermByIdLookup,
  selectDeploymentBusinessVendorByConsignmentIdLookup,
  selectDisplayedAgentByVendorId,
  selectEidsByReceivalLotIdLookup,
  selectFilteredConsignmentIds,
  selectOutOfSyncFieldNamesByBusinessId,
  selectReceivalLotIdsByConsignmentIdLookup,
  selectReceivalPenNamesByConsignmentId,
  selectResolvedSaleLotCommentCountByConsignmentIdLookup,
  selectUnresolvedSaleLotCommentCountByConsignmentIdLookup,
  selectVendorSplitConsignmentIdsByParentConsignmentIdLookup,
} from "selectors";

export const selectConsignmentValueByConsignmentIdLookup = createSelector(
  [getSaleLots, getStatusBySaleLotId],
  (saleLots, statusBySaleLotIdLookup) => {
    return Object.values(saleLots)
      .filter(
        saleLot =>
          statusBySaleLotIdLookup[saleLot.id] !== saleLotStatuses.NO_SALE,
      )
      .reduce((acc, saleLot) => {
        const consignmentId = saleLot.consignment_id;
        if (acc[consignmentId]) {
          acc[consignmentId].totalPriceCents += saleLot.total_price_cents;
          acc[consignmentId].quantity += saleLot.quantity;
        } else {
          acc[consignmentId] = {};
          acc[consignmentId].totalPriceCents = saleLot.total_price_cents;
          acc[consignmentId].quantity = saleLot.quantity;
        }

        acc[consignmentId].unitPrice =
          acc[consignmentId].quantity > 0
            ? acc[consignmentId].totalPriceCents / acc[consignmentId].quantity
            : 0;
        return acc;
      }, {});
  },
);

export const selectGeneralConsignmentsAggridData = createSelector(
  [
    getConsignmentsWithAttachments,
    selectUnresolvedSaleLotCommentCountByConsignmentIdLookup,
    selectResolvedSaleLotCommentCountByConsignmentIdLookup,
    selectDeploymentBusinessVendorByConsignmentIdLookup,
    selectDisplayedAgentByVendorId,
    getBusinesses,
    selectFilteredConsignmentIds,
    getNominations,
    selectActiveNominationTermByIdLookup,
    getDeployments,
    selectOutOfSyncFieldNamesByBusinessId,
    selectVendorSplitConsignmentIdsByParentConsignmentIdLookup,
    selectConsignmentValueByConsignmentIdLookup,
    selectReceivalPenNamesByConsignmentId,
    selectReceivalLotIdsByConsignmentIdLookup,
    getReceivalLots,
    selectEidsByReceivalLotIdLookup,
    getAuctionPens,
  ],
  (
    formattedConsignments,
    unresolvedSaleLotCommentCountByConsignmentIdLookup,
    resolvedSaleLotCommentCountByConsignmentIdLookup,
    deploymentBusinessVendorByConsignmentIdLookup,
    relationshipAgent,
    businesses,
    filteredConsignmentIds,
    nominations,
    nominationTerms,
    deployments,
    outOfSyncByBusinessIdLookup,
    vendorSplitConsignmentIdsByParentConsignmentIdLookup,
    consignmentValueByConsignmentIdLookup,
    receivalPenNamesByConsignmentId,
    receivalLotIdsByConsignmentIdLookup,
    receivalLotByIdLookup,
    eidsByReceivalLotLookup,
    auctionPenByIdLookup,
  ) =>
    formattedConsignments
      .filter(consignment => filteredConsignmentIds.includes(consignment.id))
      .map(formattedConsignment => {
        const { vendor = {} } = formattedConsignment;
        const vendorId = vendor?.id;
        const relationshipAgentText = relationshipAgent[vendorId];
        const nomination = nominations[formattedConsignment.nominationId] || {};
        const nominationDetails = nomination.nominationDetails || [];
        const { integrationBusinesses: vendorIntegrationBusinesses = [] } =
          vendor;

        const receivalLots =
          receivalLotIdsByConsignmentIdLookup[formattedConsignment.id]?.map(
            receivalLotId => receivalLotByIdLookup[receivalLotId],
          ) || [];

        const receivalLotsHeadCount = sum(
          receivalLots.map(receivalLot => receivalLot.quantity),
        );

        const receivalLotEids =
          flatten(
            receivalLots.map(
              receivalLot => eidsByReceivalLotLookup[receivalLot.id],
            ),
          ) || [];

        const receivalLotsScanCount = receivalLotEids.length;

        let receivalLotQuantity = 0;

        const formattedReceivalLots = receivalLots.map(receivalLot => {
          receivalLotQuantity += receivalLot.quantity;
          const eids = eidsByReceivalLotLookup[receivalLot.id] || [];
          const receivalPen = auctionPenByIdLookup[receivalLot.receivalPenId];
          const scansCount = eids.length || 0;
          return Object.assign(
            {
              title: `${receivalPen?.start_pen} ${scansCount} / ${receivalLot?.quantity}`,
            },
            receivalLot,
          );
        });

        // "Highlight Consignment Rules
        // Consignment Hd not equal to total receival lot Hd or
        // Consignment Hd not equal to Receival Lot SCAN Count or
        // Receival Lot Hd not equal to Receival Lot Scan Count or
        // Consignments not mapped to any Receival Lots"
        const consignmentHd = formattedConsignment.quantity;

        let rowWarning = null;

        if (consignmentHd !== receivalLotQuantity) {
          rowWarning = "Consignment Head Not Equal To Receival Lots Head";
        } else if (consignmentHd !== receivalLotsScanCount) {
          rowWarning = "Consignment Head Not Equal To Receival Lots Scan Count";
        } else if (receivalLotQuantity !== receivalLotsScanCount) {
          rowWarning =
            "Receival Lot Head Not Equal To Receival Lots Scan Count";
        } else if (!receivalLots.length) {
          rowWarning = "Consignment does not have a receival lot";
        }

        return Object.assign({}, formattedConsignment, {
          resolvedSaleLotCommentCount:
            resolvedSaleLotCommentCountByConsignmentIdLookup[
              formattedConsignment.id
            ],
          unresolvedSaleLotCommentCount:
            unresolvedSaleLotCommentCountByConsignmentIdLookup[
              formattedConsignment.id
            ],
          vendor:
            deploymentBusinessVendorByConsignmentIdLookup[
              formattedConsignment.id
            ],
          relationshipAgent: relationshipAgentText,
          carrierCharge: Object.assign({}, formattedConsignment.carrierCharge, {
            carrierName:
              businesses[formattedConsignment.carrierCharge?.carrier]?.name ||
              "",
          }),
          vendorDataOutOfSync: outOfSyncByBusinessIdLookup[vendorId],
          nomination,
          nominationVendor: businesses[nomination?.vendorId],
          nominationConsigningDeployment:
            deployments[nomination.consigningDeploymentId],
          nominationDetails: nominationDetails.map(nominationTerm =>
            Object.assign({}, nominationTerm, {
              termName: nominationTerms[nominationTerm.nominationTermId]?.name,
            }),
          ),
          xeroVendor: vendorIntegrationBusinesses.find(
            integrationBusiness =>
              integrationBusiness.type === IntegrationTypes.Xero,
          ),
          vendorSplitCount:
            vendorSplitConsignmentIdsByParentConsignmentIdLookup[
              formattedConsignment.id
            ]?.length || 0,
          totalPriceCents:
            consignmentValueByConsignmentIdLookup[formattedConsignment.id]
              ?.totalPriceCents || 0,
          unitPrice:
            consignmentValueByConsignmentIdLookup[formattedConsignment.id]
              ?.unitPrice || 0,
          soldQuantity:
            consignmentValueByConsignmentIdLookup[formattedConsignment.id]
              ?.quantity || 0,
          receivalPens:
            receivalPenNamesByConsignmentId[formattedConsignment.id].join(", "),
          receivalLotsHeadCount,
          receivalLotsScanCount,
          receivalLots: formattedReceivalLots,
          rowWarning,
        });
      }),
);
