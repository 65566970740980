import React, { memo, useMemo } from "react";

import { Form, Formik, useField } from "formik";

import { FormCollapse } from "components/Form";
import { Column } from "components/Layout";

function noop() {}

const DeploymentBusinessTableComponent = ({
  masterBusinessId,
  primaryContactEmail,
  namespace: ns,
  isOpen,
  onEndEditing = noop,
  onStartEditing = noop,
  onSetPrimaryContactEmail,
  onToggle,
  headerComponent: HeaderComponent,
  tableComponent: TableComponent,
  validationSchema,
  initialValuesList,
  dataTour,
}) => {
  const { setValue } = useField(ns)[2];

  const initialValues = useMemo(
    () => ({
      editingValue: null,
      values: initialValuesList,
    }),
    [initialValuesList],
  );

  function onSubmitForm(formValues) {
    setValue(formValues.values);
    onEndEditing();
  }

  function onResetForm() {
    onEndEditing();
  }

  const header = (
    <HeaderComponent
      masterBusinessId={masterBusinessId}
      namespace={ns}
      primaryContactEmail={primaryContactEmail}
    />
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      id={ns}
      dataTour={dataTour}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        onReset={onResetForm}
        validationSchema={validationSchema}
      >
        <Column fullWidth>
          <Form>
            <TableComponent
              masterBusinessId={masterBusinessId}
              primaryContactEmail={primaryContactEmail}
              onStartEditing={onStartEditing}
              onEndEditing={onEndEditing}
              onSetPrimaryContactEmail={onSetPrimaryContactEmail}
            />
          </Form>
        </Column>
      </Formik>
    </FormCollapse>
  );
};

export default memo(DeploymentBusinessTableComponent);
