import React, { useState } from "react";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { setSetting, WeighLotAction } from "actions";

import { Button, SecondaryButton } from "components/Form";
import { Fieldset } from "components/Form/Fieldset";
import { useSubmitHandler } from "components/Form/FormikControls";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { ApiModel } from "constants/loading";
import { LivestockSalePermissions } from "constants/permissions";
import { Settings } from "constants/settings";

import { getSetting } from "selectors";

import { useHasLivestockSalePermission } from "hooks";

import { ImportWeighLotsForm } from "./ImportWeighLotsForm";
import { ValidationSchema } from "./validationSchema";

export function Footer(props) {
  const { onClose } = props;
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  useSubmitHandler(true, setIsSubmitEnabled);

  const hasCreatePermission = useHasLivestockSalePermission(
    LivestockSalePermissions.canAddWeighLot,
  );

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      <Button
        type="submit"
        disabled={!isSubmitEnabled || !hasCreatePermission}
        title={
          !hasCreatePermission
            ? "You do not have permission to Import Weigh Lots"
            : "Import Weigh Lots CSV file"
        }
      >
        Import
      </Button>
    </>
  );
}

export function ImportWeighLotsModal(props) {
  const { onClose } = props;
  const dispatch = useDispatch();
  const stateScaleName = useSelector(getSetting(Settings.weighLotScaleName));

  const initialValues = {
    file: null,
    fileContents: null,
    scaleName: stateScaleName,
  };

  function onSubmit(values) {
    if (values.scaleName !== stateScaleName) {
      dispatch(setSetting(Settings.weighLotScaleName, values.scaleName));
    }

    dispatch(
      WeighLotAction.import(
        values.file.name,
        values.fileContents,
        values.scaleName,
      ),
    );
    onClose();
  }

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>Import Weigh Lots</DialogTitle>
      <WaitForSync requiredData={[ApiModel.WEIGH_LOTS, ApiModel.SALEYARDS]}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          <Form data-tour="import-weigh-lots-form">
            <DialogContent dividers>
              <Fieldset>
                <ImportWeighLotsForm />
              </Fieldset>
            </DialogContent>
            <DialogActions>
              <Footer onClose={onClose} />
            </DialogActions>
          </Form>
        </Formik>
      </WaitForSync>
    </ZoomyDialog>
  );
}
