import React from "react";

import { faCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import {
  faArrowDown,
  faArrowUp,
  faCopy,
  faFileEdit,
  faSignOut,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  closeConfirmModal,
  MasterRuleBookAction,
  openConfirmModal,
  openDispatchingModal,
  RuleAction,
  RuleBookAction,
} from "actions";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { MultiButton } from "components/Button";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Paper } from "components/Paper";
import { StatusWrapper } from "components/ScanningPenCards/Card/elements";

import { ModalTypes } from "constants/navigation";
import { colors } from "constants/theme";

import { pluralize } from "lib/pluralize";

import {
  getIsMoveDownEnabledByRuleId,
  getIsMoveUpEnabledByRuleId,
  getMasterRuleBookById,
  getMasterRuleById,
  getMasterRuleIdsByMasterRuleBookId,
  getRuleById,
  getRulesByRuleBookId,
  selectMasterRuleBookIdByMasterRuleIdLookup,
} from "selectors";

import AgriNousActiveLogo from "img/AgriNousActiveLogo";

export function RuleCardLogo(props) {
  const { isManagedRule = false } = props;
  return <AgriNousActiveLogo isActive={isManagedRule} />;
}

export function RuleCard(props) {
  const { ruleId, statusColorName } = props;

  const dispatch = useDispatch();
  const rule = useSelector(getRuleById(ruleId)) || {};
  const masterRuleBookIdByMasterRuleIdLookup = useSelector(
    selectMasterRuleBookIdByMasterRuleIdLookup,
  );

  const isMoveUpEnabled = useSelector(getIsMoveUpEnabledByRuleId(ruleId));

  const isMoveDownEnabled = useSelector(getIsMoveDownEnabledByRuleId(ruleId));

  const ruleBookManagedRuleCount =
    useSelector(state => {
      // For this Managed Rule, count all the Managed Rules in this Rule's Rule Book belonging to the same Master Rule Book
      const masterRule = getMasterRuleById(rule.master_rule_id)(state) || {};
      const masterRuleBook =
        getMasterRuleBookById(masterRule.rule_book_id)(state) || {};
      const masterRuleIds =
        getMasterRuleIdsByMasterRuleBookId(masterRuleBook.id)(state) || [];

      const rules = getRulesByRuleBookId(rule.rule_book_id)(state) || [];

      return rules.filter(rule => masterRuleIds.includes(rule.master_rule_id))
        .length;
    }) || 0;

  const masterRuleBookName =
    useSelector(state => {
      const masterRule = getMasterRuleById(rule.master_rule_id)(state) || {};

      const masterRuleBook =
        getMasterRuleBookById(masterRule.rule_book_id)(state) || {};
      return masterRuleBook.name;
    }) || "";

  const isManagedRule = Boolean(rule.master_rule_id);

  function onClickMoveUp() {
    dispatch(RuleAction.moveUp(ruleId));
  }

  function onClickMoveDown() {
    dispatch(RuleAction.moveDown(ruleId));
  }

  function onClickEdit() {
    dispatch(
      openDispatchingModal(
        ModalTypes.EditRule,
        window.location.hash,
        { ruleId },
        { ruleId },
      ),
    );
  }

  function onClickCopy() {
    dispatch(RuleAction.copy(ruleId));
  }

  function onClickConfirmDelete() {
    dispatch(RuleAction.delete(ruleId));
    dispatch(closeConfirmModal());
  }

  function onClickCancelDelete() {
    dispatch(closeConfirmModal());
  }

  function onClickDelete() {
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message: "Are you sure you want to delete the Rule?",
        actions: [
          {
            label: "Cancel",
            onClick: onClickCancelDelete,
            secondary: true,
          },
          {
            label: "Delete Rule",
            onClick: onClickConfirmDelete,
            secondary: false,
          },
        ],
      }),
    );
  }

  function onClickConfirmUnsubscribe() {
    dispatch(
      RuleBookAction.unsubscribeFromMasterRuleBook(
        rule.rule_book_id,
        masterRuleBookIdByMasterRuleIdLookup[rule.master_rule_id],
      ),
    );

    dispatch(closeConfirmModal());
  }

  function onClickCancelUnsubscribe() {
    dispatch(closeConfirmModal());
  }

  function onClickUnsubscribe() {
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message: `Are you sure you want to remove this and ${ruleBookManagedRuleCount} other ${pluralize(
          "rule",
          ruleBookManagedRuleCount,
        )} from the "${masterRuleBookName}" Master Rule Book?`,
        actions: [
          {
            label: "Cancel",
            onClick: onClickCancelUnsubscribe,
            secondary: true,
          },
          {
            label: "Unsubscribe from Master Rule Book",
            onClick: onClickConfirmUnsubscribe,
            secondary: false,
          },
        ],
      }),
    );
  }
  function onClickUpdateRulebook() {
    dispatch(
      MasterRuleBookAction.updateManagedRule(
        masterRuleBookIdByMasterRuleIdLookup[rule.master_rule_id],
        rule.rule_book_id,
        rule.id,
      ),
    );
  }

  const buttons = [
    {
      title: isManagedRule ? "Customise" : "Edit",
      isDisabled: false,
      onClick: onClickEdit,
      default: true,
      icon: faFileEdit,
      dataTour: "editRule",
    },
    {
      title: "Copy",
      isDisabled: isManagedRule,
      onClick: onClickCopy,
      default: false,
      icon: faCopy,
      dataTour: "copyRule",
    },
    {
      title: "Delete",
      isDisabled: isManagedRule,
      onClick: onClickDelete,
      default: false,
      icon: faTrash,
      dataTour: "deleteRule",
    },
    {
      title: "Unsubscribe",
      isDisabled: !isManagedRule,
      onClick: onClickUnsubscribe,
      default: false,
      icon: faSignOut,
      dataTour: "unsubscribe",
    },
    {
      title: "Update",
      isDisabled: !isManagedRule,
      onClick: onClickUpdateRulebook,
      default: false,
      icon: faSync,
      dataTour: "updateRuleBook",
    },
  ];

  const masterRule = useSelector(getMasterRuleById(rule.master_rule_id)) || {};

  let color = "primary";
  let tooltip = "Managed Rule is up to date";
  if (!isManagedRule) {
    color = colors.grayDisabled;
    tooltip = "This is not a Managed Rule";
  } else if (masterRule.is_deleted) {
    color = colors.errorRed;
    tooltip = "The Master Rule has been deleted";
  } else if (rule.content !== masterRule.content) {
    color = colors.warning;
    tooltip = "The Manged Rule's content is out of date with the Master Rule";
  }

  return (
    <StatusWrapper color={statusColorName} data-tour={rule.name}>
      <Paper>
        <Grid container alignItems="center">
          <Grid item container md={9} lg={10} xl={11} alignItems="center">
            <RuleCardLogo isManagedRule={isManagedRule} />
            <Tooltip title={tooltip}>
              <FaIcon icon={faCircle} color={color} style={{ fontSize: 8 }} />
            </Tooltip>
            <IconButton
              aria-label="Move Up"
              disabled={!isMoveUpEnabled}
              onClick={onClickMoveUp}
            >
              <FontAwesomeIcon icon={faArrowUp} size="xs" />
            </IconButton>
            <IconButton
              aria-label="Move Down"
              disabled={!isMoveDownEnabled}
              onClick={onClickMoveDown}
            >
              <FontAwesomeIcon icon={faArrowDown} size="xs" />
            </IconButton>
            <span>{rule.name}</span>
          </Grid>
          <Grid item md={3} lg={2} xl={1}>
            <MultiButton buttons={buttons} />
          </Grid>
        </Grid>
      </Paper>
    </StatusWrapper>
  );
}

export const TitledRule = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  ::before,
  ::after {
    content: "";
    flex: 1;
    border-bottom: 2px groove threedface;
  }

  :not(:empty)::before {
    margin-right: 1em;
  }

  :not(:empty)::after {
    margin-left: 1em;
  }
`;
