// stop isFetching and isError keys from being persisted
import localforage from "localforage";
import { createTransform } from "redux-persist";

const blacklistTransform = createTransform(inboundState => {
  const newInboundState = { ...inboundState };
  delete newInboundState.isFetching;
  delete newInboundState.isError;
  delete newInboundState.authentication?.isFetching;
  delete newInboundState.authentication?.isError;
  delete newInboundState.authentication?.isNLISFetching;
  delete newInboundState.connectedDeviceId;
  delete newInboundState.availableDevices;
  delete newInboundState.isHubConnected;
  delete newInboundState.context;
  delete newInboundState.allSalesLoaded;
  return newInboundState;
});

// Configure the localforage instance to store
const reduxPersistConfig = {
  storeName: "redux_persist_data",
  description:
    "Contains the local version of the redux store to enable store and forward capabilites",
  name: "agrinous-store-forward",
};

const reduxPersistStorageInstance =
  localforage.createInstance(reduxPersistConfig);

export const persistOptions = {
  blacklist: ["screen", "synced", "confirmModal"],
  transforms: [blacklistTransform],
  serialize: false,
  storage: reduxPersistStorageInstance,
};
