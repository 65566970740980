import { BIDDER_REGISTRATION } from "constants/actionTypes";

export function requestBidderRegistrations(saleyardName, saleId) {
  return {
    type: BIDDER_REGISTRATION.FETCH_BULK.REQUEST,
    saleyardName,
    saleId,
  };
}

export function receiveBidderRegistrations(registrations) {
  return {
    type: BIDDER_REGISTRATION.FETCH_BULK.SUCCESS,
    registrations,
  };
}

export function requestBidderRegistrationsError(error) {
  return {
    type: BIDDER_REGISTRATION.FETCH_BULK.FAILURE,
    error,
  };
}
