import React, { useState } from "react";

import { useSelector } from "react-redux";

import { Button, LabelArea } from "components/Form";
import IdConnectedList from "components/IdConnectedList";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MessageBox from "components/MessageBox";

import { ApiModel } from "constants/loading";

import { openImportAuctionsPlusCSV } from "lib/navigation";

import {
  getCurrentSale,
  getKeywordLookups,
  selectAlphabeticalBuyerIds,
} from "selectors";

import { BuyerCard } from "./BuyerCard";

function EmptyBuyers() {
  return <MessageBox>No Lots sold yet.</MessageBox>;
}

export const BuyersComponent = () => {
  const sale = useSelector(getCurrentSale);

  const buyerIds = useSelector(selectAlphabeticalBuyerIds);
  const [searchText, setSearchText] = useState("");

  function onBasicSearchTextChanged(e) {
    setSearchText(e.target.value.trim());
  }
  const businessKeywordLookup = useSelector(getKeywordLookups).businesses;

  let filteredIds = [];
  if (searchText.length) {
    const matchingBusinessIdsLookup = Object.keys(businessKeywordLookup).reduce(
      (acc, lookupToken) => {
        if (lookupToken.includes(searchText)) {
          businessKeywordLookup[lookupToken].forEach(b => {
            acc[b.business.id] = true;
          });
        }
        return acc;
      },
      {},
    );
    filteredIds = buyerIds.filter(id => matchingBusinessIdsLookup[id]);
  } else {
    filteredIds = buyerIds;
  }

  return (
    <Column>
      <Row padding={2}>
        <LabelArea>Search Buyers:&nbsp;</LabelArea>
        <input
          className="p-2"
          name="basicSearch"
          onChange={onBasicSearchTextChanged}
          type="text"
        />
      </Row>
      <Row padding={1}>
        <Button onClick={() => openImportAuctionsPlusCSV(sale)}>
          Import AuctionsPlus Results
        </Button>
      </Row>
      <IdConnectedList
        component={BuyerCard}
        orderedIds={filteredIds}
        emptyComponent={EmptyBuyers}
      />
    </Column>
  );
};

// Wait For Sync Wrapper
export const Buyers = () => {
  return (
    <WaitForSync
      requiredData={[
        ApiModel.SALE_LOTS,
        ApiModel.CONSIGNMENTS,
        ApiModel.BUSINESSES,
      ]}
    >
      <BuyersComponent />
    </WaitForSync>
  );
};
