import { isEmpty } from "lodash";

import {
  ADD_CONSIGNMENT_COMMIT,
  ADD_PROPERTY_COMMIT,
  ADD_SALE_LOT_COMMIT,
  AUCTION_PEN,
  BUSINESS,
  CHECKPOINT,
  COMMENT,
  DRAFTING_INFORMATION,
  LIVESTOCK_AGENT,
  MANUAL_ADJUSTMENT,
  NOMINATION,
  PEN_SCAN_LOT,
  RECEIVAL_LOT,
  SALE_LOT,
  SALEYARD_ADMIN,
  SELL_FILE,
  SELL_REVERSAL,
  TAKE_FILE,
  TAKE_REVERSAL,
  UPDATE_DEPLOYMENT_LABELS,
  UPLOAD_SCANS_COMMIT,
  WEIGH_LOT,
  WEIGH_LOT_SCAN,
} from "constants/actionTypes";

const isUUIDRegex =
  /([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/;

const shouldUpdateTempId = (realId, tempId) =>
  tempId !== realId && typeof tempId === "string" && tempId.match(isUUIDRegex);

// Stores locally generated, optimistic ids -> server responded real id references.
// Note that this is used to 'walk over' payloads.  We should NEVER, EVER have a
// tempId that might look like non-unique data (like, say, an integer).
const offlineTemp = (state = {}, action) => {
  switch (action.type) {
    case ADD_CONSIGNMENT_COMMIT:
    case ADD_PROPERTY_COMMIT:
    case ADD_SALE_LOT_COMMIT:
    case AUCTION_PEN.CREATE.SUCCESS:
    case BUSINESS.CREATE.SUCCESS:
    case CHECKPOINT.CREATE.SUCCESS:
    case COMMENT.CREATE.SUCCESS:
    case DRAFTING_INFORMATION.CREATE.SUCCESS:
    case LIVESTOCK_AGENT.CREATE.SUCCESS:
    case MANUAL_ADJUSTMENT.CREATE.SUCCESS:
    case NOMINATION.CREATE.SUCCESS:
    case RECEIVAL_LOT.CREATE.SUCCESS:
    case PEN_SCAN_LOT.CREATE.SUCCESS:
    case SALEYARD_ADMIN.CREATE.SUCCESS:
    case SELL_FILE.CREATE.SUCCESS:
    case SELL_REVERSAL.CREATE.SUCCESS:
    case TAKE_FILE.CREATE.SUCCESS:
    case TAKE_REVERSAL.CREATE.SUCCESS:
    case WEIGH_LOT.CREATE.SUCCESS:
    case WEIGH_LOT_SCAN.CREATE.SUCCESS: {
      const { payload, meta } = action;
      const tempId = action.tempId || meta.tempId;
      const realId = payload[meta.realIdKey || "id"];
      if (shouldUpdateTempId(realId, tempId)) {
        return {
          ...state,
          [tempId]: realId,
        };
      } else {
        return state;
      }
    }

    case SALE_LOT.UPDATE.SUCCESS: {
      // The buyer way can be created from the patch sale lot.
      const { payload, meta } = action;
      if (payload.buyer_way) {
        const tempId = meta.buyerWayId;
        const realId = payload.buyer_way.id;

        if (shouldUpdateTempId(realId, tempId)) {
          return {
            ...state,
            [tempId]: realId,
          };
        }
      }

      return state;
    }

    case UPLOAD_SCANS_COMMIT: {
      const { payload, meta } = action;
      const updates = meta.scans.reduce((acc, temp, index) => {
        if (shouldUpdateTempId(payload.scans[index].id, temp.id)) {
          acc[temp.id] = payload.scans[index].id;
        }
        return acc;
      }, {});

      if (!isEmpty(updates)) {
        return {
          ...state,
          ...updates,
        };
      } else {
        return state;
      }
    }

    case UPDATE_DEPLOYMENT_LABELS.COMMIT: {
      const { payload, meta } = action;

      const updates = meta.request.reduce((acc, temp, index) => {
        if (shouldUpdateTempId(payload[index].id, temp.id)) {
          acc[temp.id] = payload[index].id;
        }
        return acc;
      }, {});

      if (!isEmpty(updates)) {
        return {
          ...state,
          ...updates,
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};

export default offlineTemp;
