import React from "react";

import {
  faExclamationCircle,
  faDollarCircle,
} from "@fortawesome/pro-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Row } from "components/Layout";

import { ModalTypes } from "constants/navigation";

import { openModalLink } from "lib/navigation";

export const hasChangesRenderer = ({ data }) => {
  if (!data) {
    return null;
  }
  const { hasCosmeticChanges, hasFinancialChanges } = data.billingDocument;
  const openReviewBillingDocumentModal = () =>
    openModalLink(ModalTypes.BillingDocumentReview, {
      id: data.billingDocument.id,
    });

  const toolTipTextCreator = (type, value) => {
    if (value) {
      return `${type} changes have been made`;
    }
    return `${type} changes have not been made`;
  };

  return (
    <Row justifyAround>
      <Tooltip title={toolTipTextCreator("Financial", hasFinancialChanges)}>
        <FaIcon
          icon={faDollarCircle}
          onClick={hasFinancialChanges ? openReviewBillingDocumentModal : null}
          color={hasFinancialChanges ? "warning" : "gray78"}
        />
      </Tooltip>
      <Tooltip title={toolTipTextCreator("Cosmetic", hasCosmeticChanges)}>
        <FaIcon
          icon={faExclamationCircle}
          onClick={hasCosmeticChanges ? openReviewBillingDocumentModal : null}
          color={hasCosmeticChanges ? "warning" : "gray78"}
        />
      </Tooltip>
    </Row>
  );
};
