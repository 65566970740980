import React from "react";

import { getIn, setIn, useFormikContext } from "formik";
import { useSelector } from "react-redux";

import {
  QuickOptionsWithSelect,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";

import { SaleTypes } from "constants/sale";

import { getBestMatchingProductId, productFields } from "lib/products";

import { currentSaleSelector, selectCurrentDeploymentIds } from "selectors";

import { getProductsByDeploymentId } from "selectors/speciesAttributes";

const getProductQuickSelectKey = saleType => {
  switch (saleType) {
    case SaleTypes.PADDOCK:
      return "quick_select_paddock";
    case SaleTypes.OVER_HOOKS:
      return "quick_select_hooks";
    default:
      return "quick_select";
  }
};

interface ProductSelectFieldProps {
  ns?: string;
  deploymentId?: number;
  disabled?: boolean;
  hideQuickSelect?: boolean;
  bestMatchAutoSelect?: boolean;
}

export const ProductSelectField = React.memo(
  ({
    ns = null,
    deploymentId = null,
    disabled = false,
    hideQuickSelect = false,
    bestMatchAutoSelect = true,
  }: ProductSelectFieldProps): React.JSX.Element => {
    const formik = useFormikContext<{
      product_id: number;
      sale_round_id: number;
    }>();
    const { setFieldValue, values, setValues } = formik;
    const defaultDeploymentId = useSelector(selectCurrentDeploymentIds)[0];
    const selectedDeploymentId = deploymentId || defaultDeploymentId;
    const products = useSelector(
      getProductsByDeploymentId(selectedDeploymentId),
    );
    const currentProductId = getIn(values, withNamespace(ns, "product_id"));
    const productValues = React.useMemo(
      () =>
        productFields.reduce((acc, cur) => {
          acc[cur] = getIn(values, withNamespace(ns, cur));
          return acc;
        }, {}),
      [values, ns],
    );

    // Build the list of options for the sale type (and round if applicable)
    const currentSale = useSelector(currentSaleSelector) || {};
    const saleType = currentSale.sale_type;
    const quickSelectKey = getProductQuickSelectKey(saleType);
    const saleRoundId = values.sale_round_id;
    const productOptions = React.useMemo(() => {
      let filteredProducts = products;
      if (![SaleTypes.OVER_HOOKS, SaleTypes.PADDOCK].includes(saleType)) {
        filteredProducts = filteredProducts.filter(
          p =>
            p.sale_rounds.includes(saleRoundId) || p.sale_rounds.length === 0,
        );
      }
      return filteredProducts.map(p => ({
        label: `${p.name} ${p[quickSelectKey] ? `(${p.quick_code})` : ""}`,
        value: p.id,
        quick_code: p[quickSelectKey] && p.quick_code,
      }));
    }, [products, quickSelectKey, saleRoundId, saleType]);

    // if bestMatchAutoSelect is enabled and any value changes, check that the most applicable product is selected.
    // For example Product X has breed 1, the user selects breed 2, so Product X is no longer valid.
    React.useEffect(() => {
      if (bestMatchAutoSelect) {
        const bestMatchingProductId = getBestMatchingProductId(
          productValues,
          products,
          currentProductId,
        );
        if (bestMatchingProductId !== currentProductId) {
          setFieldValue(withNamespace(ns, "product_id"), bestMatchingProductId);
        }
      }
    }, [
      products,
      ns,
      setFieldValue,
      productValues,
      currentProductId,
      bestMatchAutoSelect,
    ]);

    const setProduct = productId => {
      const product = products.find(p => p.id === productId);
      if (product) {
        let newValues = { ...values, product_id: productId };
        productFields.forEach(field => {
          newValues = setIn(
            newValues,
            withNamespace(ns, field),
            product[field],
          );
        });
        setValues(newValues);
      }
    };

    if (hideQuickSelect) {
      // Hide quick select and don't return the select inside a grid.
      return (
        <SelectField
          label="Description"
          name={withNamespace(ns, "product_id")}
          options={productOptions}
          isClearable
          onChangeExtra={setProduct}
          disabled={disabled}
          menuPortalTarget={document.body}
        />
      );
    }

    return (
      <QuickOptionsWithSelect
        ns={ns}
        name="product_id"
        label="Description"
        options={productOptions}
        onChangeExtra={setProduct}
        disabled={disabled}
      />
    );
  },
);
