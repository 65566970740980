export const PusherTypes = {
  BULK_UPDATE: "bulk_update",
  CREATE_AGENCY_PRODUCT: "create_agency_product",
  CREATE_ATTACHMENTS: "create_attachments",
  CREATE_BIDDER_REGISTRATION: "create_bidder_registration",
  CREATE_BUSINESS: "create_business",
  CREATE_CONSIGNMENT: "create_consignment",
  CREATE_DRAFTING_INFORMATION: "create_drafting_information",
  CREATE_PAYMENT: "create_payment",
  CREATE_PROPERTY: "create_property",
  CREATE_SALE_LOT: "create_sale_lot",
  CREATE_SCANS: "create_scans",
  DELETE_AGENCY_PRODUCT: "delete_agency_product",
  DELETE_ATTACHMENT: "delete_attachment",
  DELETE_CONSIGNMENT: "delete_consignment",
  DELETE_PAYMENT: "delete_payment",
  DELETE_SALE_LOT: "delete_sale_lot",
  DELETE_SCAN: "delete_scan",
  REFRESH_AUCTIONPENS: "refresh_auctionpens",
  REFRESH_BILLING_DOCUMENTS: "refresh_billing_documents",
  REFRESH_BILLING_LEDGER_ENTRIES: "refresh_billing_ledger_entries",
  REFRESH_BILLING_RUNS: "refresh_billing_runs",
  REFRESH_BUSINESSES: "refresh_businesses",
  REFRESH_BUSINESSES_V2: "refresh_businesses_v2",
  REFRESH_CHECKPOINTS: "refresh_checkpoints",
  REFRESH_COMMENTS: "refresh_comments",
  REFRESH_CONSIGNMENTS: "refresh_consignments",
  REFRESH_DEPLOYMENTS: "refresh_deployments",
  REFRESH_DEPLOYMENT_ATTRIBUTES: "refresh_deployment_attributes",
  REFRESH_ECONTRACTS: "refresh_econtracts",
  REFRESH_EMAILS: "refresh_emails",
  REFRESH_ENVD_DOCUMENTS: "refresh_envd_documents",
  REFRESH_INTEGRATION_CREDENTIALS: "refresh_integration_credentials",
  REFRESH_INTEREST_SETTINGS: "refresh_interest_settings",
  REFRESH_LEDGER_ACCOUNTS: "refresh_ledger_accounts",
  REFRESH_MANUAL_ADJUSTMENTS: "refresh_manual_adjustments",
  REFRESH_MASTER_LEDGER_ACCOUNTS: "refresh_master_ledger_accounts",
  REFRESH_MASTER_RULES: "refresh_master_rules",
  REFRESH_MASTER_RULE_BOOKS: "refresh_master_rule_books",
  REFRESH_NLIS_NVD_UPLOAD: "refresh_nlis_nvd_upload",
  REFRESH_NLIS_P2P_FILE: "refresh_nlis_p2p_file",
  REFRESH_NLIS_P2P_REVERSAL: "refresh_nlis_p2p_reversal",
  REFRESH_NLIS_SELL_FILE: "refresh_nlis_sell_file",
  REFRESH_NLIS_SELL_FILE_CORRECTION_UPDATE:
    "refresh_nlis_sell_file_correction_update",
  REFRESH_NLIS_SELL_REVERSAL: "refresh_nlis_sell_reversal",
  REFRESH_NLIS_TAKE_FILE: "refresh_nlis_take_file",
  REFRESH_NLIS_TAKE_REVERSAL: "refresh_nlis_take_reversal",
  REFRESH_NOMINATIONS: "refresh_nominations",
  REFRESH_PEN_SCAN_LOTS: "refresh_pen_scan_lots",
  REFRESH_PROPERTIES: "refresh_properties",
  REFRESH_RECEIVAL_LOTS: "refresh_receival_lots",
  REFRESH_REPORT_JOBS: "refresh_report_jobs",
  REFRESH_RULES: "refresh_rules",
  REFRESH_RULE_BOOKS: "refresh_rule_books",
  REFRESH_SALES: "refresh_sales",
  REFRESH_SALE_LOTS: "refresh_sale_lots",
  REFRESH_SAVED_VIEWS: "refresh_saved_views",
  REFRESH_SCANS: "refresh_scans",
  REFRESH_SINGLE_WEIGH: "refresh_single_weigh",
  REFRESH_TRADING_TERMS: "refresh_trading_terms",
  REFRESH_VENDOR_COMMISSION_BANDS: "refresh_vendor_commissions",
  REFRESH_DEFAULT_VENDOR_SPLITS: "refresh_default_vendor_splits",
  REFRESH_SALE_VENDOR_SPLITS: "refresh_sale_vendor_splits",
  REFRESH_WEIGH_LOTS: "refresh_weigh_lots",
  REFRESH_WEIGH_LOT_SCANS: "refresh_weigh_lot_scans",
  UPDATE_AGENCY_PRODUCT: "update_agency_product",
  UPDATE_ATTACHMENT: "update_attachment",
  UPDATE_ATTACHMENTS: "update_attachments",
  UPDATE_BIDDER_REGISTRATION: "update_bidder_registration",
  UPDATE_BUSINESS: "update_business",
  UPDATE_CONSIGNMENT: "update_consignment",
  UPDATE_PAYMENT: "update_payment",
  UPDATE_PROPERTY: "update_property",
  UPDATE_SALE_LOT: "update_sale_lot",
};

// The following pusher messages do not contain any unique information in the
// payload, so mark them for deduplicating.
export const deduplicatePusherMessageTypes = [
  PusherTypes.REFRESH_AUCTIONPENS,
  PusherTypes.REFRESH_BILLING_RUNS,
  PusherTypes.REFRESH_BILLING_DOCUMENTS,
  PusherTypes.REFRESH_BILLING_LEDGER_ENTRIES,
  PusherTypes.REFRESH_BUSINESSES,
  PusherTypes.REFRESH_BUSINESSES_V2,
  PusherTypes.REFRESH_COMMENTS,
  PusherTypes.REFRESH_CONSIGNMENTS,
  PusherTypes.REFRESH_MANUAL_ADJUSTMENTS,
  PusherTypes.REFRESH_NOMINATIONS,
  PusherTypes.REFRESH_PROPERTIES,
  PusherTypes.REFRESH_EMAILS,
  PusherTypes.REFRESH_RECEIVAL_LOTS,
  PusherTypes.REFRESH_REPORT_JOBS,
  PusherTypes.REFRESH_PEN_SCAN_LOTS,
  PusherTypes.REFRESH_SALE_LOTS,
  PusherTypes.REFRESH_SCANS,
  PusherTypes.REFRESH_SINGLE_WEIGH,
  PusherTypes.REFRESH_VENDOR_COMMISSION_BANDS,
  PusherTypes.REFRESH_SALE_VENDOR_SPLITS,
  PusherTypes.REFRESH_DEFAULT_VENDOR_SPLITS,
  PusherTypes.REFRESH_WEIGH_LOTS,
  PusherTypes.REFRESH_WEIGH_LOT_SCANS,
];
