import { kebabCase } from "lodash";
import { put, select, takeEvery, call } from "redux-saga/effects";

import { BillingDocumentAction, billingDocumentUrlCreator } from "actions";

import {
  BILLING_DOCUMENT,
  BILLING_RUN,
  INTEGRATION_CREDENTIAL,
} from "constants/actionTypes";
import { IntegrationTypes } from "constants/integrations";

import { downloadFileBlob } from "lib";

import { fetchWithFullyQualifiedUrl } from "lib/fetch";
import toast from "lib/toast";

import {
  getActiveRole,
  getBillingRunById,
  getBillingDocumentById,
  selectIntegrationCredentialIdByTypeAndDeploymentIdLookup,
} from "selectors";

import { checkIntegrationActive } from "./integrationCredentials";

function* onSubscribeBillingRun(action) {
  const { id } = action;
  yield put(BillingDocumentAction.request({ billingRunId: id }));
}

function fetchBillingDocumentFactory(
  urlAction,
  reportName,
  extraQueryParams = {},
) {
  return function* onFetchBillingDocumentFile(action) {
    const { billingRunId } = action;
    const state = yield select();
    const userRole = getActiveRole(state).slug;

    const url = billingDocumentUrlCreator({
      action: urlAction,
      billingRunId,
      userRole,
      extraQueryParams,
    });

    const toastMessage = `Generating ${reportName} Export...`;
    toast.syncing(toastMessage, {
      autoClose: false,
    });
    const response = yield fetchWithFullyQualifiedUrl(url);
    if (response.ok) {
      const fileBlob = yield response.blob();
      const contentDisposition = response.headers.get("content-disposition");
      // Extract the server generated file name
      const fileName = contentDisposition.match(/filename="(.+)"/)[1];

      toast.dismiss(kebabCase(toastMessage));
      downloadFileBlob(fileName, fileBlob);
    } else {
      // If we get here, assume it's a json response with an error...
      const responseText = yield response.json();
      toast.error(`Export Failed: ${responseText}`);
    }
  };
}

function* onExportXeroFail() {
  const state = yield select();
  yield put(
    BillingDocumentAction.request({
      billingRunId: state.billingRuns.activeBillingRunId,
    }),
  );
}

export function* checkXeroActive(action) {
  const state = yield select();
  const { props, nextFunction } = action;
  const { billingDocumentIds } = props;
  const billingDocument = getBillingDocumentById(billingDocumentIds[0])(state);
  const billingRun = getBillingRunById(billingDocument.billingRunId)(state);
  const integrationCredentialId =
    selectIntegrationCredentialIdByTypeAndDeploymentIdLookup(state)[
      IntegrationTypes.Xero
    ][billingRun.deploymentId];
  yield call(
    checkIntegrationActive,
    integrationCredentialId,
    nextFunction,
    billingDocumentIds,
  );
}

function* onSyncDocumentSuccess() {
  yield put(BillingDocumentAction.request());
}

export default function* billingDocumentsSaga() {
  yield takeEvery(BILLING_RUN.SUBSCRIBE.ACTION, onSubscribeBillingRun);
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_XERO_BILL_CSV.ACTION,
    fetchBillingDocumentFactory("xero-bill-csv", "Xero Account Sale/RCTI CSV"),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_XERO_INVOICE_CSV.ACTION,
    fetchBillingDocumentFactory("xero-invoice-csv", "Xero Invoice CSV"),
  );
  yield takeEvery(
    BILLING_DOCUMENT.EXPORT_XERO_INVOICE.FAILURE,
    onExportXeroFail,
  );
  yield takeEvery(BILLING_DOCUMENT.EXPORT_XERO_INVOICE.ACTION, checkXeroActive);
  yield takeEvery(
    INTEGRATION_CREDENTIAL.SYNC_DOCUMENTS.SUCCESS,
    onSyncDocumentSuccess,
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_CUSTOMER_EXPORT.ACTION,
    fetchBillingDocumentFactory("myob-customers", "MYOB Customer Card CSV"),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_SUPPLIER_EXPORT.ACTION,
    fetchBillingDocumentFactory("myob-suppliers", "MYOB Supplier Card CSV"),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_SALES_EXPORT.ACTION,
    fetchBillingDocumentFactory("myob-sale", "MYOB Sale CSV"),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_PURCHASES_EXPORT.ACTION,
    fetchBillingDocumentFactory("myob-purchase", "MYOB Purchase CSV"),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_CLASSIC_SALES_EXPORT.ACTION,
    fetchBillingDocumentFactory("myob-sale", "MYOB Sale CSV", {
      myob: "classic",
    }),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_CLASSIC_PURCHASES_EXPORT.ACTION,
    fetchBillingDocumentFactory("myob-purchase", "MYOB Purchase CSV", {
      myob: "classic",
    }),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_SALEYARD_BULK_BILLING_XML.ACTION,
    fetchBillingDocumentFactory("bulk-billing", "Saleyard Bulk Billing XML", {
      report_type: "saleyard",
    }),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_AGENT_BULK_BILLING_XML.ACTION,
    fetchBillingDocumentFactory("bulk-billing", "Agent Bulk Billing XML", {
      report_type: "agent",
    }),
  );
}
