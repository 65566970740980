import React from "react";

import { Grid, useMediaQuery } from "@material-ui/core";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { Button, SecondaryButton } from "components/Form";
import { Link } from "components/Link";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import MessageBox from "components/MessageBox";
import { KillSheetForm } from "components/PrivateSales/KillSheetForm";

import { SaleLotModalSection } from "constants/navigation";

import { getEditSaleLotHashRoute } from "lib/navigation";

import { getSkinsSexId } from "selectors/sexes";

import { useTheme } from "hooks";

const decimalValidation = Yup.number()
  .positive("must be a > 0")
  .typeError("invalid decimal");

const quantityValidation = Yup.number()
  .typeError("invalid number")
  .integer("must be a whole number")
  .required("required");

const validationSchema = Yup.object().shape({
  saleLots: Yup.array().of(
    Yup.object().shape({
      quantity: quantityValidation,
      total_mass_grams: decimalValidation,
      unit_price: decimalValidation,
    }),
  ),
});

function Modal({
  onClose,
  saleLots,
  consignment,
  referenceSaleLot,
  handleSubmit,
}) {
  const theme = useTheme();
  const dialogTitle = `Kill Sheet Entry - Hd ${consignment?.quantity}`;
  const fullScreen = useMediaQuery(`(max-width: ${theme.breakpoints[1]}px)`);
  const skinsSexId = useSelector(getSkinsSexId);

  const nonSkinsSaleLots = saleLots.filter(
    saleLot => saleLot.sex_id !== skinsSexId,
  );

  const skinsSaleLot = saleLots.find(saleLot => saleLot.sex_id === skinsSexId);

  const skinPrice =
    skinsSaleLot?.total_price_cents / skinsSaleLot?.quantity || 0;

  return (
    <Formik
      initialValues={{
        saleLots: nonSkinsSaleLots,
        skinsSaleLotId: skinsSaleLot?.id,
        skinQuantity: skinsSaleLot?.quantity,
        skin_price: skinPrice,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formikProps => {
        const killSheetDisabled =
          // There must be a buyer ...
          (referenceSaleLot && !referenceSaleLot?.buyer_id) ||
          // And some species information on the salelot.
          !(
            referenceSaleLot?.sex_id ||
            referenceSaleLot?.age_id ||
            referenceSaleLot?.breed_id
          );

        const submitDisabled =
          !(formikProps.isValid || formikProps.submitCount === 0) ||
          killSheetDisabled;

        return (
          <Dialog
            open
            onClose={onClose}
            fullScreen={fullScreen}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle onClose={onClose}>{dialogTitle}</DialogTitle>
            <DialogContent dividers>
              {killSheetDisabled ? (
                <MessageBox>
                  <Link
                    to={`#${getEditSaleLotHashRoute(
                      referenceSaleLot?.id,
                      SaleLotModalSection.GENERAL,
                      null,
                      consignment?.id,
                      null,
                    )}`}
                  >
                    The first drafted lot must contain species info and a buyer,
                    click here to edit.
                  </Link>
                </MessageBox>
              ) : (
                <Grid container item xs={12} spacing={2}>
                  <KillSheetForm />
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={onClose}>Close</SecondaryButton>
              <Button
                disabled={submitDisabled}
                onClick={formikProps.handleSubmit}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
}

export default Modal;
