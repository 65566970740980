import React from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components/macro";

import { connectToDevice, connectToHub } from "actions";

import { Clickable, SlimButton } from "components/Button";
import { LargeErrorMessage } from "components/ErrorMessage";
import { SecondaryButton } from "components/Form";
import { Column, Row } from "components/Layout";

import { getIsHubConnected } from "selectors";

import PlayStoreBadge from "img/google-play-badge.png";

import { ExplanationText, Title } from "./Elements";
import ScannerSelectInline from "./ScannerSelectInline";

class ScannerSelect extends React.Component {
  state = {
    isDeviceConnectionError: false,
  };

  componentDidUpdate(prevProps) {
    const { isHubConnected } = this.props;
    // If the hub has transitioned from offline to online, clear and connection errors
    if (isHubConnected && !prevProps.isHubConnected) {
      this.clearConnectionError();
    }
  }

  onClickCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  onConnectionFailed = () => {
    this.setConnectionError();
  };

  setConnectionError() {
    this.setState({ isDeviceConnectionError: true });
  }

  fallbackToStore = () => {
    window.location.replace("market://details?id=com.agrinous.hub");
  };

  openApp = () => {
    window.location.replace("agrinous.hub.app://");
  };

  triggerAppOpen = () => {
    this.openApp();
    setTimeout(this.fallbackToStore, 250);
  };

  onClickConnect = () => {
    this.clearConnectionError();
  };

  clearConnectionError() {
    this.setState({ isDeviceConnectionError: false });
  }

  render() {
    const { isDeviceConnectionError } = this.state;
    const { isHubConnected, connectToHub } = this.props;

    return (
      <Column alignCenter style={{ width: "100%" }}>
        <Column alignCenter style={{ flexGrow: 1 }}>
          <ErrorMessageWrapper>
            {isHubConnected ? (
              <LargeErrorMessage visible={isDeviceConnectionError}>
                Could not connect to chosen scanner, please check connections
                and retry
              </LargeErrorMessage>
            ) : (
              <LargeErrorMessage>
                Could not connect to hub app, please make sure it is installed
                and opened
                <Row alignCenter>
                  <Clickable onClick={this.triggerAppOpen}>
                    <img
                      alt="triggerAppOpen"
                      height={64}
                      src={PlayStoreBadge}
                      style={{ marginRight: "20px" }}
                    />
                  </Clickable>
                  <SlimButton onClick={connectToHub} style={{ height: "32px" }}>
                    Retry
                  </SlimButton>
                </Row>
              </LargeErrorMessage>
            )}
          </ErrorMessageWrapper>
          <div>
            <Title>Choose Scanner</Title>
          </div>
          <SelectWrapper>
            <ScannerSelectInline
              onAfterSelectScanner={this.onClickConnect}
              onDeviceConnectFailed={this.onConnectionFailed}
              showFailedMessage={false}
            />
          </SelectWrapper>
          <ExplanationText>
            <p>
              If the EID reader is not in this list it means the it has not been
              paired to this device. Please close the app, pair the scanner and
              return to the app.
            </p>
            <p>
              Call <a href="tel:0480 039 299">0480 039 299</a> for assistance
            </p>
          </ExplanationText>
        </Column>
        <ButtonWrapper>
          <SecondaryButton type="button" onClick={this.onClickCancel}>
            Cancel
          </SecondaryButton>
        </ButtonWrapper>
      </Column>
    );
  }
}

ScannerSelect.propTypes = {
  connectToHub: PropTypes.func.isRequired,
  isHubConnected: PropTypes.bool,
  onCancel: PropTypes.func,
};

const mapStateToProps = state => ({
  isHubConnected: getIsHubConnected(state),
});

const mapDispatchToProps = {
  connectToDevice,
  connectToHub,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScannerSelect);

const SelectWrapper = styled.div`
  width: 260px;
  height: 36px;
`;

const ErrorMessageWrapper = styled.div`
  max-width: 350px;
  margin: 60px 12px 20px 12px;
`;

const ButtonWrapper = styled(Row)`
  width: 50%;
  place-self: flex-start;
  padding: ${({ theme }) => theme.space[2]}px;
`;
