import {
  GET_PROPERTIES,
  GET_PROPERTIES_CHANGES,
  GET_PROPERTIES_SUCCESS,
  GET_PROPERTIES_CHANGES_SUCCESS,
  GET_PROPERTIES_FAILURE,
  ADD_PROPERTY_FROM_SOCKET,
  UPDATE_PROPERTY_FROM_SOCKET,
  UPDATE_PROPERTIES,
  RECONCILE_BUYER_WAY_DEPLOYMENT_BUSINESS_PROPERTY_NAMES,
} from "constants/actionTypes";

export function requestProperties() {
  return {
    type: GET_PROPERTIES,
  };
}

export function requestPropertiesChanges() {
  return {
    type: GET_PROPERTIES_CHANGES,
  };
}

export function receiveProperties(properties, lastModifiedTimestamp) {
  return {
    type: GET_PROPERTIES_SUCCESS,
    properties,
    lastModifiedTimestamp,
  };
}

export function receivePropertiesChanges(properties, lastModifiedTimestamp) {
  return {
    type: GET_PROPERTIES_CHANGES_SUCCESS,
    properties,
    lastModifiedTimestamp,
  };
}

export function requestPropertiesError() {
  return {
    type: GET_PROPERTIES_FAILURE,
  };
}

export const addPropertyFromSocket = message => {
  const property = message.object;
  return {
    type: ADD_PROPERTY_FROM_SOCKET,
    payload: property,
    meta: {
      property,
    },
  };
};

export const updatePropertyFromSocket = message => {
  const property = message.object;
  return {
    type: UPDATE_PROPERTY_FROM_SOCKET,
    payload: property,
    meta: {
      property,
    },
  };
};

export const updateProperties = message => {
  const properties = message;
  return {
    type: UPDATE_PROPERTIES,
    properties,
  };
};

export const reconcileBuyerWayDeploymentBusinessPropertyNames = (
  saleLotIds,
  options,
) => {
  return {
    type: RECONCILE_BUYER_WAY_DEPLOYMENT_BUSINESS_PROPERTY_NAMES,
    saleLotIds,
    options,
  };
};
