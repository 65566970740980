import { ColumnType } from "components/AgGrid/constants";

import { ACTION_COLUMN_NAME } from "constants/aggrid";
import {
  BillingDocumentColId,
  BillingDocumentColumnDef,
  BusinessColId,
  BusinessColumnDef,
  LivestockSalesColumnDef,
  RetainedProceedsColumnDef,
} from "constants/columnDefinitions";

import { formatters, getters } from "lib/agGrid";

import { BillingDocumentActionRenderer } from "./renderers";

const filterColIdsFromColumnDef = (colDefs, excludeColIds) =>
  Object.values(colDefs).filter(obj => !excludeColIds.includes(obj.colId));

export const MoneyTableColId = {
  SHOW_ON_OUTSTANDING_SETTLEMENT_REPORT: "fbe7d485-58c4-4766-9257-b6937490c6a7",
};

export const InterestSettingsColId = {
  INTEREST_RATE: "c71ebd89-2178-4b7c-8f5a-99a9ba0de935",
  DAYS_OVERDUE_THRESHOLD: "32e0a9a9-3ebd-4231-9cbb-322fbc4a71ca",
  CALCULATED_INTEREST: "38d566af-75c5-4061-b474-712b2059f6ef",
};

export const InterestSettingsColumnDef = {
  [InterestSettingsColId.INTEREST_RATE]: {
    colId: InterestSettingsColId.INTEREST_RATE,
    field: "interestSettings.interestRate",
    headerName: "Interest Rate",
    type: ColumnType.PERCENTAGE,
  },
  [InterestSettingsColId.DAYS_OVERDUE_THRESHOLD]: {
    colId: InterestSettingsColId.DAYS_OVERDUE_THRESHOLD,
    field: "interestSettings.daysOverdueThreshold",
    headerName: "Days Overdue Threshold",
    type: ColumnType.NUMERIC,
  },
  [InterestSettingsColId.CALCULATED_INTEREST]: {
    colId: InterestSettingsColId.CALCULATED_INTEREST,
    field: "interestSettings.calculatedInterest",
    headerName: "Suggested Interest ($)",
    width: 75,
    type: ColumnType.CURRENCY,
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsCreditDebitFormatter,
  },
};

export const MoneyColumnDefinitions = showInterestColumns =>
  [
    {
      headerName: "Document",
      children: filterColIdsFromColumnDef(BillingDocumentColumnDef, [
        BillingDocumentColId.ACTIONS,
        BillingDocumentColId.DOCUMENT_DOWNLOAD,
      ]),
    },
    {
      headerName: "Retained Proceeds",
      children: Object.values(RetainedProceedsColumnDef),
    },
    {
      headerName: "Business",
      children: filterColIdsFromColumnDef(BusinessColumnDef, [
        BusinessColId.ACTIONS,
      ]),
    },
    showInterestColumns && {
      headerName: "Interest Settings",
      children: Object.values(InterestSettingsColumnDef),
    },
    {
      headerName: "Sale(s)",
      children: Object.values(LivestockSalesColumnDef),
    },
    {
      colId: MoneyTableColId.SHOW_ON_OUTSTANDING_SETTLEMENT_REPORT,
      field: "showOnOutstandingSettlementReport",
      headerName: "Show On Outstanding Settlement Report",
      type: ColumnType.BOOLEAN,
    },
    {
      colId: BillingDocumentColId.ACTIONS,
      field: "billingDocument.id",
      headerName: ACTION_COLUMN_NAME,
      valueGetter: ({ data }) => {
        if (!data) {
          return null;
        }
        return [
          data.billingDocument.id,
          data.billingDocument.status,
          data.billingDocument.hasFinancialChanges ||
            data.billingDocument.hasCosmeticChanges,
        ].join("-");
      },
      cellRenderer: BillingDocumentActionRenderer,
      pinned: "right",
      width: 130,
    },
  ].filter(Boolean);
