import React from "react";

import {
  faCloudUploadAlt,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Paper,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";

import { importFile, processImportFile, updateFileImport } from "actions";

import { Button, DeleteButton, IconButton } from "components/Form";
import { UploadIcon } from "components/Importer/components";
import { FileImportField } from "components/Importer/FileImportField";
import { Row } from "components/Layout";

import { TranslatedSaleTypeLabel } from "containers/TranslatedSaleTypeLabel";

import { openOverview } from "lib/navigation";

import { MapBuyerTable, MapVendorTable } from "./BusinessImportComponents";
import { MapProductTable } from "./ProductImportComponents";
import { MapRoundTable } from "./RoundImportComponents";

const ImportTab = {
  ACKNOWLEDGEMENT: 0,
  SELECT_FILE: 1,
  VENDORS: 2,
  PRODUCTS: 3,
  BUYERS: 4,
  SALE_ROUNDS: 5,
  UPLOAD: 6,
};

export const GenericMultiFileTypeImporterSection = {
  BUYERS: "BUYERS",
  PRODUCTS: "PRODUCTS",
  SALE_ROUNDS: "SALE_ROUNDS",
  VENDORS: "VENDORS",
};

export function GenericMultiFileTypeImporter({
  importName,
  fileTypes,
  fileDescription,
  type,
  sections = [],
  HelpTextComponent,
}) {
  const dispatch = useDispatch();

  const fileState = useSelector(state => state?.importers?.[importName]);
  const {
    buyers,
    file: existingFile,
    isDataLossAcknowledged,
    isImportRunning,
    selectedTab,
    products,
    rounds,
    vendors,
  } = fileState;

  const isUsingBuyersSection = sections.includes(
    GenericMultiFileTypeImporterSection.BUYERS,
  );
  const isUsingProductsSection = sections.includes(
    GenericMultiFileTypeImporterSection.PRODUCTS,
  );
  const isUsingSaleRoundsSection = sections.includes(
    GenericMultiFileTypeImporterSection.SALE_ROUNDS,
  );
  const isUsingVendorsSection = sections.includes(
    GenericMultiFileTypeImporterSection.VENDORS,
  );

  const vendorsCount = vendors?.length || 0;
  const mappedVendorsCount =
    vendors?.filter(vendorMapping => vendorMapping.vendorId).length || 0;
  const productsCount = products?.length || 0;
  const mappedProductsCount =
    products?.filter(productMapping => productMapping.productId).length || 0;

  const buyersCount = buyers?.length || 0;
  const mappedBuyersCount =
    buyers?.filter(buyerMapping => buyerMapping.buyerId).length || 0;

  const roundsCount = rounds?.length || 0;
  const mappedRoundsCount =
    rounds?.filter(roundMapping => roundMapping.roundId).length || 0;

  const { name, sale } = existingFile || {};
  const isImportEnabled =
    isDataLossAcknowledged &&
    sale &&
    (!isUsingProductsSection || mappedProductsCount === productsCount) &&
    (!isUsingVendorsSection || mappedVendorsCount === vendorsCount) &&
    (!isUsingBuyersSection || mappedBuyersCount === buyersCount) &&
    (!isUsingSaleRoundsSection || mappedRoundsCount === roundsCount);

  function setDataLossAcknowledged() {
    dispatch(
      updateFileImport(
        {
          isDataLossAcknowledged: true,
          selectedTab: ImportTab.SELECT_FILE,
        },
        type,
      ),
    );
  }

  function selectTab(selectedTab) {
    if (isDataLossAcknowledged) {
      dispatch(
        updateFileImport(
          {
            selectedTab,
          },
          type,
        ),
      );
    }
  }

  return (
    <>
      <Paper square>
        <Stepper nonLinear activeStep={selectedTab} alternativeLabel>
          <Step key={ImportTab.ACKNOWLEDGEMENT}>
            <StepButton completed={isDataLossAcknowledged}>
              Acknowledgement
            </StepButton>
          </Step>
          <Step key={ImportTab.SELECT_FILE}>
            <StepButton
              disabled={!isDataLossAcknowledged}
              completed={isDataLossAcknowledged && Boolean(existingFile)}
              onClick={() => selectTab(ImportTab.SELECT_FILE)}
            >
              {existingFile ? `${name} Selected` : "Select File"}
            </StepButton>
          </Step>
          {isUsingVendorsSection && (
            <Step key={ImportTab.VENDORS}>
              <StepButton
                optional={`${mappedVendorsCount}/${vendorsCount}`}
                onClick={() => selectTab(ImportTab.VENDORS)}
                completed={
                  isDataLossAcknowledged &&
                  sale &&
                  mappedVendorsCount === vendorsCount
                }
                disabled={!isDataLossAcknowledged || !sale}
              >
                Map Vendors
              </StepButton>
            </Step>
          )}

          {isUsingProductsSection && (
            <Step key={ImportTab.PRODUCTS}>
              <StepButton
                optional={`${mappedProductsCount}/${productsCount}`}
                onClick={() => selectTab(ImportTab.PRODUCTS)}
                completed={
                  isDataLossAcknowledged &&
                  sale &&
                  mappedProductsCount === productsCount
                }
                disabled={!isDataLossAcknowledged || !sale}
              >
                Map Products
              </StepButton>
            </Step>
          )}
          {isUsingBuyersSection && (
            <Step key={ImportTab.BUYERS}>
              <StepButton
                optional={`${mappedBuyersCount}/${buyersCount}`}
                onClick={() => selectTab(ImportTab.BUYERS)}
                completed={
                  isDataLossAcknowledged &&
                  sale &&
                  mappedBuyersCount === buyersCount
                }
                disabled={!isDataLossAcknowledged || !sale}
              >
                Map Buyers
              </StepButton>
            </Step>
          )}

          {isUsingSaleRoundsSection && (
            <Step key={ImportTab.SALE_ROUNDS}>
              <StepButton
                optional={`${mappedRoundsCount}/${roundsCount}`}
                onClick={() => selectTab(ImportTab.SALE_ROUNDS)}
                completed={
                  isDataLossAcknowledged &&
                  sale &&
                  mappedRoundsCount === roundsCount
                }
                disabled={!isDataLossAcknowledged || !sale}
              >
                Map Rounds
              </StepButton>
            </Step>
          )}

          <Step key={ImportTab.UPLOAD}>
            {isImportEnabled ? (
              isImportRunning ? (
                <StepButton icon={<CircularProgress />}>Importing</StepButton>
              ) : (
                <StepButton
                  icon={<UploadIcon icon={faCloudUploadAlt} />}
                  onClick={() => dispatch(importFile(type))}
                >
                  Import Sale Data
                </StepButton>
              )
            ) : (
              <StepLabel error>Import Sale Data</StepLabel>
            )}
          </Step>
        </Stepper>
      </Paper>
      {selectedTab === ImportTab.ACKNOWLEDGEMENT && (
        <Container>
          <Card>
            <CardContent>
              <h2>Remove and replace all content in this Sale</h2>
              <p>
                Importing a Sale will replace all existing information in the
                Sale including:
              </p>
              <ul>
                <li>Consignments</li>
                <li>Media and Attachments (including NVDs)</li>
                <li>Sale Lots</li>
                <li>Scans</li>
              </ul>
              <CardActions>
                <Button onClick={() => openOverview()}>
                  Return to <TranslatedSaleTypeLabel label="Overview" />
                </Button>
                <DeleteButton
                  onClick={() => {
                    setDataLossAcknowledged();
                  }}
                >
                  Progress to Import
                </DeleteButton>
              </CardActions>
            </CardContent>
          </Card>
        </Container>
      )}
      {selectedTab === ImportTab.SELECT_FILE && (
        <>
          {type.DOWNLOAD_SAMPLE && (
            <Row>
              <p className="text-left">
                <IconButton
                  icon={faFileDownload}
                  onClick={() =>
                    dispatch({ type: type.DOWNLOAD_SAMPLE.REQUEST })
                  }
                  type="button"
                >
                  Download Template File
                </IconButton>
              </p>
              <HelpTextComponent />
            </Row>
          )}
          <FileImportField
            accept={fileTypes}
            existingFileName={name}
            fileDescription={fileDescription}
            processFile={(file, fileContents) =>
              dispatch(processImportFile(file, fileContents, type))
            }
          />
        </>
      )}
      {selectedTab === ImportTab.VENDORS && (
        <MapVendorTable importName={importName} type={type} />
      )}
      {selectedTab === ImportTab.PRODUCTS && (
        <MapProductTable importName={importName} type={type} />
      )}
      {selectedTab === ImportTab.BUYERS && (
        <MapBuyerTable importName={importName} type={type} />
      )}
      {selectedTab === ImportTab.SALE_ROUNDS && (
        <MapRoundTable importName={importName} type={type} />
      )}
    </>
  );
}
