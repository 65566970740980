import React, { memo, useCallback, useRef } from "react";

import { Grid } from "@material-ui/core";
import { getIn, setIn, useFormikContext } from "formik";
import PropTypes from "prop-types";

import { AuctionsPlusMakeInput } from "components/AuctionsPlusMakeField";
import {
  Label,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";
import { DataTour } from "components/HelpHeroDataTour";
import { Column } from "components/Layout";
import { InputSelect } from "components/SearchableSelector/ReactSelect";

import { getAuctionsPlusItemsByManufacturer } from "constants/auctionsPlusSchema";

const ALL_ITEMS = [];
const ITEMS_BY_START_CHAR = [];
const ALL_ITEMS_LOOKUP = {};
const MODEL_OPTIONS_BY_MAKE = {};

for (const [make, models] of Object.entries(
  getAuctionsPlusItemsByManufacturer(),
)) {
  const modelOptions = [];
  for (const model of models) {
    const displayValue = `${make} ${model}`;
    ALL_ITEMS.push(displayValue);
    const startChar = displayValue.charAt(0).toUpperCase();
    if (!Array.isArray(ITEMS_BY_START_CHAR[startChar])) {
      ITEMS_BY_START_CHAR[startChar] = [];
    }
    ITEMS_BY_START_CHAR[startChar].push(displayValue);

    ALL_ITEMS_LOOKUP[displayValue] = {
      make,
      model,
    };
    modelOptions.push({
      label: model,
      value: model,
    });
  }

  MODEL_OPTIONS_BY_MAKE[make] = modelOptions;
}

const EMPTY_OPTIONS = [];

function createDataListEl(dataListId, options) {
  const datalistDocFragment = document.createDocumentFragment();

  const datalistEl = document.createElement("datalist");
  datalistEl.id = dataListId;
  datalistDocFragment.appendChild(datalistEl);
  for (const listItemOption of options) {
    const optionEl = document.createElement("option");
    optionEl.value = listItemOption;
    datalistEl.appendChild(optionEl);
  }
  return datalistDocFragment;
}

function allocateDataList(startChar) {
  if (!document.getElementById("itemsDataList")) {
    const options = ITEMS_BY_START_CHAR[startChar.toUpperCase()] || [];
    document.body.appendChild(createDataListEl("itemsDataList", options));
  }
}

function releaseDataList() {
  const listElement = document.getElementById("itemsDataList");
  if (listElement) {
    document.body.removeChild(listElement);
  }
}

function AuctionsPlusItemSearchComponent(props) {
  // FIXME only one instance of this component should ever appear on the screen at once.
  // If we ever need more than that, we need to adjust the useEffect (which allocates and frees the datalist)
  // to handle multiple references to the datalist, via some refCount or something like that
  const { onSelectCategoryValues } = props;
  const startCharRef = useRef(null);

  function onChange(event) {
    const nextValue = ALL_ITEMS_LOOKUP[event.target.value];
    if (event.target.value) {
      const startChar = event.target.value.charAt(0).toUpperCase();
      if (startCharRef.current !== startChar) {
        startCharRef.current = startChar;
        setTimeout(() => releaseDataList(), 0);
      }
      setTimeout(() => allocateDataList(startChar), 0);
    } else if (document.getElementById("itemsDataList")) {
      startCharRef.current = null;
      setTimeout(() => releaseDataList(), 0);
    }

    if (nextValue) {
      typeof onSelectCategoryValues === "function" &&
        onSelectCategoryValues(nextValue);
      event.target.value = "";
      startCharRef.current = null;
      event.target.blur();
    }
  }

  return (
    <Column fullWidth>
      <DataTour dataTour="findAVehicle">
        <Label
          htmlFor="auctionsPlusItemSearch"
          tooltip="Search for a (Make and Model) to automatically fill the item"
        >
          Find a Vehicle
        </Label>

        <InputSelect type="search" list="itemsDataList" onChange={onChange} />
      </DataTour>
    </Column>
  );
}
const AuctionsPlusItemSearch = memo(AuctionsPlusItemSearchComponent);

const AuctionsPlusMakeField = props => {
  const { name, namespace: ns, onSelectMake } = props;

  const { values } = useFormikContext();

  const value = getIn(values, withNamespace(ns, name));

  return (
    <Column fullWidth>
      <DataTour dataTour="make">
        <Label htmlFor={withNamespace(ns, name)}>Make</Label>
        <AuctionsPlusMakeInput value={value} onSelectMake={onSelectMake} />
      </DataTour>
    </Column>
  );
};

const AuctionsPlusModelField = props => {
  const { make, name, namespace: ns } = props;

  return (
    <SelectField
      label="Model"
      name={withNamespace(ns, name)}
      options={MODEL_OPTIONS_BY_MAKE[make] || EMPTY_OPTIONS}
    />
  );
};

function AuctionsPlusClearingSaleModelFormComponent(props) {
  const { namespace: ns = "" } = props;

  const { setValues, values } = useFormikContext();

  // store the values as a ref so as not to invalidate the callbacks on every render,
  // whilst also providing access to the latest values
  const formikValuesRef = useRef();
  formikValuesRef.current = values;

  /**
   * Update all of the values from the selected item in the search
   */
  const onSelectCategoryValues = useCallback(
    ({ make, model }) => {
      let nextValues = { ...formikValuesRef.current };

      // clear the `category` field
      nextValues = setIn(nextValues, withNamespace(ns, "make"), make);

      // clear the `itemType` field
      nextValues = setIn(nextValues, withNamespace(ns, "model"), model);

      // update the formik context with the new values
      setValues(nextValues);
    },
    [formikValuesRef, ns, setValues],
  );

  /**
   * Clear the itemType and the subType when selecting a new category
   */
  const onSelectMake = useCallback(
    make => {
      let nextValues = { ...formikValuesRef.current };

      // clear the `category` field
      nextValues = setIn(nextValues, withNamespace(ns, "make"), make);

      // clear the `itemType` field
      nextValues = setIn(nextValues, withNamespace(ns, "model"), null);

      // update the formik context with the new values
      setValues(nextValues);
    },
    [formikValuesRef, ns, setValues],
  );

  // extract the category and itemType from the values, used for passing into the
  // `AuctionsPlusItemTypeField` and `AuctionsPlusSubTypeField` components so
  // that they can filter the available options
  const make = getIn(values, withNamespace(ns, "make"));

  return (
    <>
      <Grid item xs={12}>
        <AuctionsPlusItemSearch
          onSelectCategoryValues={onSelectCategoryValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AuctionsPlusMakeField
          namespace={ns}
          name="make"
          onSelectMake={onSelectMake}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AuctionsPlusModelField make={make} namespace={ns} name="model" />
      </Grid>
    </>
  );
}

AuctionsPlusClearingSaleModelFormComponent.propTypes = {
  namespace: PropTypes.string,
};

export default memo(AuctionsPlusClearingSaleModelFormComponent);
