import React, { useCallback, useMemo, useState } from "react";

import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { BillingDocumentAction, LedgerEntryAction } from "actions";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import {
  LedgerEntryForm,
  ShowOnInvoiceAs,
} from "components/Billing/LedgerEntry/LedgerEntryForm";
import { PricingMethod } from "components/Billing/LedgerEntry/LedgerEntryPricingForm";
import { DefaultInitialLedgerEntryValues } from "components/Billing/LedgerEntry/lib";
import { ValidationSchema } from "components/Billing/LedgerEntry/validationSchema";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Button, DeleteButton, SecondaryButton } from "components/Form";
import { Fieldset } from "components/Form/Fieldset";
import { useSubmitHandler } from "components/Form/FormikControls";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { AuditLogTypes } from "constants/auditLog";
import { GstMethod } from "constants/billing";
import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import { DeploymentPermissions } from "constants/permissions";

import { EMPTY_OBJECT } from "lib";

import {
  getIsReadOnlyByBillingDocumentId,
  getLedgerEntryById,
  selectRoleCurrentDeployments,
} from "selectors";

import { useModalAdapter, useSomeHasPermission } from "hooks";
import { calculateTotalPriceExPricingModel } from "hooks/usePricingFormContext";

// TODO Add in the Audit Log functionality when time permits
// import AuditLogLink from "components/AuditLog/AuditLogLink";
// import { AuditLogTypes } from "constants/auditLog";

export function LedgerEntryModalAdapter(props) {
  const [hashParams, returnTo, onClose] = useModalAdapter(
    ModalTypes.EditLedgerEntry,
    props,
  );

  const { ledgerEntryId } = hashParams;

  const hasLedgerEntryFeaturePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.featureBillingRun,
  );

  if (!hasLedgerEntryFeaturePermission) {
    return null;
  }
  return (
    <WaitForSync requiredData={ApiModel.RULES}>
      <LedgerEntryModal
        ledgerEntryId={ledgerEntryId}
        onClose={onClose}
        returnTo={returnTo}
      />
    </WaitForSync>
  );
}

export function Footer(props) {
  const { onClose, onDelete, isEdit = false, isReadOnly } = props;
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);

  useSubmitHandler(false, setIsSubmitEnabled);

  const openConfirmDialog = useCallback(() => {
    setIsOpenConfirmDialog(true);
  }, [setIsOpenConfirmDialog]);

  const closeConfirmDialog = useCallback(() => {
    setIsOpenConfirmDialog(false);
  }, [setIsOpenConfirmDialog]);

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      {isEdit && (
        <DeleteButton
          data-tour="delete"
          type="button"
          onClick={openConfirmDialog}
          disabled={isReadOnly}
        >
          Delete
        </DeleteButton>
      )}
      <Button
        data-tour={isEdit ? "update" : "create"}
        type="submit"
        disabled={isReadOnly || !isSubmitEnabled}
      >
        {isEdit ? "Update" : "Create"}
      </Button>
      <ConfirmDialog
        title="Confirm Delete Ledger Entry"
        message={
          "Deleting this Ledger Entry will prevent it from appearing in the associated invoice and may affect monthly reporting. " +
          "It can be re-created by re-generating the Billing Run Ledger Entries. " +
          "If this is not desired outcome you may wish to use the 'Set to $0.00' button instead."
        }
        isOpen={isOpenConfirmDialog}
        onCancel={closeConfirmDialog}
        onDelete={onDelete}
      />
    </>
  );
}

function getGstMethodFromLedgerEntry(ledgerEntry) {
  // Try to deduce a gst method based on the data we have available.

  // If there is no tax, flag as gst exempt
  if (+ledgerEntry.taxAmount === 0) {
    return GstMethod.GST_EXEMPT;
  }

  // If the unit price x the quantity = the total price ex, GST is on top
  if (
    +ledgerEntry.unitAmount * +ledgerEntry.quantity ===
    +ledgerEntry.subtotal
  ) {
    return GstMethod.GST_EXCLUSIVE;
  }

  // Otherwise, it should be an inclusive value.
  return GstMethod.GST_INCLUSIVE;
}

function getDynamicDefaultsForLedgerEntry(ledgerEntry) {
  if (ledgerEntry === null) {
    return {
      ledgerEntry: {
        id: uuidv4(),
        showOnInvoiceAs: null,
      },
      pricingModel: {
        pricingMethod: null,
        gstMethod: null,
      },
    };
  }

  const pricingModelEx = calculateTotalPriceExPricingModel({
    subtotal: ledgerEntry.subtotal,
    quantity: ledgerEntry.quantity,
    isGstEnabled: !!+ledgerEntry.taxAmount,
  });
  return {
    ledgerEntry: {
      showOnInvoiceAs:
        ledgerEntry.invoiceCategory === null ||
        ledgerEntry.invoiceCategory === ""
          ? ShowOnInvoiceAs.INDIVIDUAL
          : ShowOnInvoiceAs.GROUPED,
      invoiceCategory:
        ledgerEntry.invoiceCategory === "" ? null : ledgerEntry.invoiceCategory,
    },
    pricingModel: {
      pricingMethod: PricingMethod.UNIT_PRICE,
      gstMethod: getGstMethodFromLedgerEntry(ledgerEntry),
      unitAmount: pricingModelEx.unitAmount.toFixed(4),
      unitAmountInc: pricingModelEx.unitAmountInc.toFixed(4),
      quantity: pricingModelEx.quantity.toFixed(4),
      subtotal: pricingModelEx.subtotal.toFixed(2),
      taxAmount: pricingModelEx.taxAmount.toFixed(2),
      totalInc: pricingModelEx.totalInc.toFixed(2),
    },
  };
}

export function LedgerEntryModal(props) {
  const { ledgerEntryId, onClose } = props;
  const dispatch = useDispatch();
  const ledgerEntry =
    useSelector(getLedgerEntryById(ledgerEntryId)) || EMPTY_OBJECT;

  const isEdit = !isEmpty(ledgerEntry);
  const isReadOnly = useSelector(
    getIsReadOnlyByBillingDocumentId(ledgerEntry.documentId),
  );

  const initialValues = useMemo(() => {
    const dynamicDefaults = getDynamicDefaultsForLedgerEntry(ledgerEntry);

    return {
      billingDocument: null,
      ledgerEntry: {
        // Apply static defaults,
        ...DefaultInitialLedgerEntryValues,
        // Apply dynamic defaults
        ...dynamicDefaults.ledgerEntry,
        // Apply values from the existing Ledger Entry
        ...ledgerEntry,
        // Overwrite with sane next defaults
        isModified: true,
        notes: "",
      },
      pricingModel: dynamicDefaults.pricingModel,
    };
  }, [ledgerEntry]);

  const onClickDelete = useCallback(() => {
    dispatch(LedgerEntryAction.delete(ledgerEntryId));
    onClose();
  }, [dispatch, ledgerEntryId, onClose]);

  function onSubmit(values) {
    if (isEdit) {
      const {
        billingDocument: billingDocumentValues,
        ledgerEntry: ledgerEntryValues,
        pricingModel: pricingModelValues,
      } = values;
      if (billingDocumentValues !== null) {
        dispatch(BillingDocumentAction.update(billingDocumentValues));
      }
      // Unit amount stored, and unit amount displayed on this form are
      // kinda different - the internal value is basically the "Source data", without
      // tax implications.
      // On this form it is broken into parts.
      // So work backward
      // - if GST exclusive, it's the total ex gst
      // - if GST inclusive, it's the total inc gst
      // - if GST exempt, total inc gst == total ex gst, so it's either
      const unitAmount =
        pricingModelValues.gstMethod === GstMethod.GST_EXCLUSIVE
          ? pricingModelValues.unitAmount
          : pricingModelValues.unitAmountInc;

      const ledgerEntry = {
        ...ledgerEntryValues,
        taxAmount: pricingModelValues.taxAmount,
        unitAmount,
        quantity: pricingModelValues.quantity,
        subtotal: pricingModelValues.subtotal,
      };
      dispatch(
        LedgerEntryAction.update(ledgerEntry, {
          changeReason: ledgerEntry.notes,
        }),
      );
    } else {
      dispatch(LedgerEntryAction.create(values));
    }
    onClose();
  }

  function zeroLedgerEntry(ledgerEntryId) {
    dispatch(
      LedgerEntryAction.update(
        {
          id: ledgerEntryId,
          includeIfZero: false,
          isModified: true,
          notes: "Ledger Entry was set to $0.00",
          quantity: 0,
          taxAmount: 0,
          subtotal: 0,
          unitAmount: 0,
        },
        { changeReason: "Zeroed with 'Set to $0.00' button" },
      ),
    );
    onClose();
  }

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>
        {ledgerEntryId && (
          <AuditLogLink
            auditLogType={AuditLogTypes.LEDGER_ENTRY}
            dataId={ledgerEntryId}
            returnTo={window.location.hash}
          />
        )}
        {isEdit ? "Edit" : "Create"} Ledger Entry
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
        key={ledgerEntryId}
      >
        <Form data-tour="billing-run-form">
          <DialogContent dividers>
            <Fieldset disabled={isReadOnly}>
              <LedgerEntryForm
                ledgerEntryId={ledgerEntryId}
                zeroLedgerEntry={zeroLedgerEntry}
              />
            </Fieldset>
          </DialogContent>
          <DialogActions>
            <Footer
              isEdit={isEdit}
              isReadOnly={isReadOnly}
              onDelete={onClickDelete}
              onClose={onClose}
            />
          </DialogActions>
        </Form>
      </Formik>
    </ZoomyDialog>
  );
}
