import React, { useMemo, useState } from "react";

import { useField } from "formik";
import { isEmpty, uniqBy } from "lodash";
import sortBy from "lodash/sortBy";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addPropertyToBusiness } from "actions";

import { Autocomplete } from "components/Form/FormikControls";

import { validatePIC } from "lib/PICValidator";

import {
  getProperties,
  getPropertiesByBusinessId,
  getPropertyEnrichedBusinessByBusinessId,
} from "selectors";

import { useFieldHelpers, useUpdateFormWithOfflineLookup } from "hooks";

const getOptionValue = option => option?.id;

export const PICField = ({
  name,
  onChangeExtra = undefined,
  label,
  businessId,
  suggestedValueFieldName,
  disabled,
  staticOptionLabel,
}) => {
  const { setValue, setError, setTouched } = useFieldHelpers(name);

  const business = useSelector(
    getPropertyEnrichedBusinessByBusinessId(businessId),
  );

  const stateOptions = sortBy(
    useSelector(getPropertiesByBusinessId(businessId)),
    ["PIC"],
  );
  const properties = useSelector(getProperties);
  const dispatch = useDispatch();

  useUpdateFormWithOfflineLookup(name);
  const [tempOptions, setTempOptions] = useState([]);

  const addNewProperty = PIC => {
    const uppercasePIC = PIC.toUpperCase();
    if (!validatePIC(uppercasePIC)) {
      const tempInvalidOption = { PIC: uppercasePIC, hide: true };
      // Add a temp options that is not visible in the list so we dont get warnings about the
      // selected option not existing from material ui.
      setTempOptions([...tempOptions, tempInvalidOption]);
      setTouched(true, false)
        .then(() => setValue(tempInvalidOption, false))
        .then(() => setError("Invalid PIC", false));
      return;
    }

    // For now just add PIC, but we could just as easily open another modal.
    const propertyId =
      Object.values(properties).find(p => p.PIC === uppercasePIC)?.id ||
      uuidv4();

    dispatch(
      addPropertyToBusiness(
        {
          id: propertyId,
          PIC: uppercasePIC,
          name: "",
        },
        business.id,
      ),
    );

    setValue(propertyId, true);
  };

  const options = useMemo(
    () => uniqBy([...stateOptions, ...tempOptions], "PIC"),
    [stateOptions, tempOptions],
  );

  const getOptionLabel = option =>
    staticOptionLabel ||
    `${
      business.defaultProperty?.PIC &&
      option.PIC === business.defaultProperty?.PIC
        ? "* "
        : ""
    }${[(option.PIC || "").toUpperCase(), option.name]
      .filter(Boolean)
      .join(" - ")}` ||
    "";

  return (
    <Autocomplete
      name={name}
      clearOnBlur
      selectOnFocus
      handleHomeEndKeys
      label={label}
      options={options}
      onChangeExtra={onChangeExtra}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      handleNew={addNewProperty}
      suggestedValueFieldName={suggestedValueFieldName}
      disabled={disabled || isEmpty(business)}
    />
  );
};

export const BusinessPICField = ({
  businessField,
  disabled = false,
  label,
  name,
  onChangeExtra = undefined,
  staticOptionLabel,
}) => {
  const [{ value: businessId }] = useField(businessField);
  return (
    <PICField
      name={name}
      onChangeExtra={onChangeExtra}
      label={label}
      businessId={businessId}
      key={businessId}
      disabled={disabled}
      staticOptionLabel={staticOptionLabel}
    />
  );
};
