import {
  getStringOfLastXMonths,
  getStringOfLast12Months,
} from "lib/timeFormats";

export const UNIX_START_TIME = "0.0";

export const datePrefills = {
  lastDay: "Last 24 Hours",
  last7Days: "Last 7 Days",
  last30Days: "Last 30 Days",
  last90Days: "Last 90 Days",
  lastMonth: `Last Month (${getStringOfLastXMonths(1)})`,
  last3Months: `Last 3 Months (${getStringOfLastXMonths(3)})`,
  last12Months: `Last 12 Months (${getStringOfLast12Months()})`,
  currentCalendarYear: "Current Calendar Year",
  lastCalendarYear: "Previous Calendar Year",
  currentFinancialYear: "Current Financial Year",
  lastFinancialYear: "Previous Financial Year",
};

// Coalesce into options, preserving a logical sort order.
export const datePrefillOptions = [
  datePrefills.lastDay,
  datePrefills.last7Days,
  datePrefills.last30Days,
  datePrefills.last90Days,
  datePrefills.lastMonth,
  datePrefills.last3Months,
].map(df => ({ value: df, label: df }));

export const datePrefillOptionsWithYears = [
  datePrefills.lastDay,
  datePrefills.last7Days,
  datePrefills.last30Days,
  datePrefills.last90Days,
  datePrefills.lastMonth,
  datePrefills.last3Months,
  datePrefills.last12Months,
  datePrefills.currentCalendarYear,
  datePrefills.lastCalendarYear,
  datePrefills.currentFinancialYear,
  datePrefills.lastFinancialYear,
].map(df => ({ value: df, label: df }));

export const datePrefillDays = {
  [datePrefills.lastDay]: 1,
  [datePrefills.last7Days]: 7,
  [datePrefills.last30Days]: 30,
  [datePrefills.last90Days]: 90,
};

export const datePrefillMonths = {
  [datePrefills.lastMonth]: 1,
  [datePrefills.last3Months]: 3,
  [datePrefills.last12Months]: 12,
};

export const datePrefillCalendarYears = {
  [datePrefills.currentCalendarYear]: 0,
  [datePrefills.lastCalendarYear]: 1,
};

export const datePrefillFinancialYears = {
  [datePrefills.currentFinancialYear]: 0,
  [datePrefills.lastFinancialYear]: 1,
};
