import * as Yup from "yup";

import { userTypes as userType } from "constants/users";

const newUserValidationShape = {
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
  phoneNumber: Yup.string(),
  userLevelId: Yup.number().required("User level is required."),
  email: Yup.string().email().required("Email is required."),
};

const livestockAgentValidationShape = {
  deploymentId: Yup.number().required("A deployment is required."),
};

const saleyardAdminValidationShape = {
  saleyardIds: Yup.array()
    .min(1, "One or more saleyards are required.")
    .required("One or more saleyards are required."),
};

const newLivestockAgentValidationSchema = Yup.object().shape({
  ...newUserValidationShape,
  ...livestockAgentValidationShape,
});

const existingLivestockAgentValidationSchema = Yup.object().shape({
  ...livestockAgentValidationShape,
});

const existingSaleyardAdminValidationSchema = Yup.object().shape({
  ...saleyardAdminValidationShape,
});

const newSaleyardAdminValidationSchema = Yup.object().shape({
  ...newUserValidationShape,
  ...saleyardAdminValidationShape,
});

export function getValidationSchema(userRoleType, userRoleId) {
  if (userRoleType === userType.LIVESTOCK_AGENT) {
    if (userRoleId) {
      return existingLivestockAgentValidationSchema;
    } else {
      return newLivestockAgentValidationSchema;
    }
  } else if (userRoleType === userType.SALEYARD_ADMIN) {
    if (userRoleId) {
      return existingSaleyardAdminValidationSchema;
    } else {
      return newSaleyardAdminValidationSchema;
    }
  }
}
