import React from "react";

import {
  faCheckCircle,
  faExclamationCircle,
  faHistory,
  faPencil,
  faPlusCircle,
  faTimesCircle,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

import { WeighLotAction, WeighLotScanAction } from "actions";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";
import Badge from "components/Badge";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { EditScansModal } from "components/EditScansModal/EditScansModal";
import MapWeighLotScanTableModal from "components/MapWeighLotScanTableModal/MapWeighLotScanTableModal";

import { AgGridTables } from "constants/aggrid";
import { AuditLogTypes } from "constants/auditLog";
import { AgGridActionButtons } from "constants/columnDefinitions/actions";
import { colors } from "constants/theme";
import {
  NonEidLinkStatuses,
  UnIgnorableStatuses,
  UnKeepableStatuses,
  WeighLotScanStatus,
  WeighLotStatus,
} from "constants/weighScanning";

import { openAuditLogModal } from "lib/navigation";
import { buildWeighLotScanStatusDetails } from "lib/weighLotScan";

import {
  getIgnorableWeighLotScansByWeighLotId,
  getKeepableWeighLotScansByWeighLotId,
  getScanByEid,
  getScanByWeighLotScanId,
} from "selectors";

import { useBoolean } from "hooks";

export const WeighLotScanStatusRenderer = ({ value, data }) => {
  if (!data) {
    return null;
  }

  const status = buildWeighLotScanStatusDetails(value);

  return (
    <Badge color={status.colour}>
      {status.showWarning && (
        <FontAwesomeIcon icon={faExclamationCircle} color={colors.white} />
      )}{" "}
      {status.status}
    </Badge>
  );
};

export const WeighLotStatusRenderer = ({ value }) => {
  if (!value) {
    return null;
  }

  let status = "";
  let colour = "";

  if (value === WeighLotStatus.UNRESOLVED) {
    colour = "error";
    status = "Unresolved";
  } else {
    colour = "success";
    status = "Resolved";
  }

  return (
    <Badge color={colour}>
      {" "}
      {status === "Unresolved" && (
        <FontAwesomeIcon icon={faExclamationCircle} color={colors.white} />
      )}{" "}
      {status}
    </Badge>
  );
};

export const WeighLotScanStatusesRenderer = ({ value, data }) => {
  if (!data) {
    return null;
  }

  return (
    <>
      {value.map(status => {
        const statusValues = buildWeighLotScanStatusDetails(status);
        return (
          <React.Fragment key={statusValues.status}>
            <Badge color={statusValues.colour}>
              {statusValues.showWarning && (
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  color={colors.white}
                />
              )}{" "}
              {statusValues.status}
            </Badge>{" "}
          </React.Fragment>
        );
      })}
    </>
  );
};

export function WeighLotActionsColumnRenderer(props) {
  const { data } = props;

  const dispatch = useDispatch();

  const [
    isConfirmDeleteWeighLotShowing,
    showConfirmDeleteWeighLot,
    hideConfirmDeleteWeighLot,
  ] = useBoolean(false);

  const { weighLot = {} } = data;

  const handleKeep = () => {
    dispatch(WeighLotAction.keep(weighLot.id));
  };

  const handleIgnore = () => {
    dispatch(WeighLotAction.ignore(weighLot.id));
  };

  const handleDeleteWithScans = () => {
    dispatch(WeighLotAction.deleteWithScans(weighLot.id));
  };

  const handleDelete = () => showConfirmDeleteWeighLot();

  const handleOpenWeighLotAuditLog = () =>
    openAuditLogModal(AuditLogTypes.WEIGH_LOT, weighLot.id);

  const isKeepEnabled = useSelector(
    state =>
      getKeepableWeighLotScansByWeighLotId(weighLot.id)(state)?.length > 0,
  );

  const isIgnoreEnabled = useSelector(
    state =>
      getIgnorableWeighLotScansByWeighLotId(weighLot.id)(state)?.length > 0,
  );

  if (!data) {
    return null;
  }

  const actions = [
    {
      onClick: handleKeep,
      dataTour: "KeepWeighLot",
      icon: faCheckCircle,
      title: " Keep",
      isDisabled: !isKeepEnabled,
    },
    {
      onClick: handleIgnore,
      dataTour: `ignoreWeighLot`,
      icon: faTimesCircle,
      title: " Ignore",
      isDisabled: !isIgnoreEnabled,
    },
    {
      onClick: handleDelete,
      dataTour: "deleteWeighLot",
      icon: faTrash,
      title: " Delete",
    },
    {
      onClick: handleOpenWeighLotAuditLog,
      dataTour: "weighLotAuditLog",
      icon: faHistory,
      title: " ",
    },
  ];

  return (
    <>
      <AgGridActionButtons actions={actions} />
      {isConfirmDeleteWeighLotShowing && (
        <ConfirmDialog
          title={createModalTitle("this Weigh Lot and it's Weigh Lot Scans")}
          isOpen
          onCancel={() => hideConfirmDeleteWeighLot()}
          onDelete={handleDeleteWithScans}
          message="Deleting this Weigh Lot will delete all the Weigh Lot Scans associated to this Weigh Lot. Weigh Lots and their Weigh Lot Scans can be re-added by importing them again. Weights already attributed to Scans and Sale Lots will not be affected."
        />
      )}
    </>
  );
}

export function WeighLotScanActionsColumnRenderer(props) {
  const { data, node } = props;

  const [selectSaleLotOpen, openSelectSaleLot, closeSelectSaleLot] =
    useBoolean(false);
  const [isEditScansOpen, openEditScans, closeEditScans] = useBoolean(false);
  const [isSelectScanOpen, openSelectScan, closeSelectScan] = useBoolean(false);

  const { id, status } = data || {};

  const rowData = node.data;

  const { eid } = rowData;

  const scan = useSelector(getScanByWeighLotScanId(id));

  const weighLotScanEidDoesExistInSale = !!useSelector(getScanByEid(eid));

  const dispatch = useDispatch();

  if (!data || status === WeighLotScanStatus.DEDUPLICATED) {
    return null;
  }

  const handleKeep = () => {
    dispatch(WeighLotScanAction.keep(id));
  };

  const handleIgnore = () => {
    dispatch(WeighLotScanAction.ignore(id));
  };

  const handleAddScanToSale = () => {
    dispatch(WeighLotScanAction.addScanToSale(id));
  };

  const handleOpenWeighLotScanAuditLog = () =>
    openAuditLogModal(AuditLogTypes.WEIGH_LOT_SCAN, id);

  return (
    <>
      <ActionButtonContainer>
        <>
          {weighLotScanEidDoesExistInSale &&
            !NonEidLinkStatuses.includes(status) && (
              <ActionButton
                data-tour="KeepWeighLotScan"
                type="button"
                onClick={handleKeep}
                disabled={UnKeepableStatuses.includes(status)}
              >
                <FontAwesomeIcon icon={faCheckCircle} /> Keep
              </ActionButton>
            )}
          <ActionButton
            data-tour="ignoreWeighLotScan"
            type="button"
            onClick={handleIgnore}
            disabled={UnIgnorableStatuses.includes(status)}
          >
            <FontAwesomeIcon icon={faTimesCircle} /> Ignore
          </ActionButton>
          {!!scan && (
            <ActionButton
              data-tour="editWeighLotScan"
              type="button"
              onClick={openEditScans}
            >
              <FontAwesomeIcon icon={faPencil} />
              &nbsp; Edit Scan
            </ActionButton>
          )}
        </>
        {!weighLotScanEidDoesExistInSale && (
          <>
            {status !== WeighLotScanStatus.MANUALLY_MAPPED && (
              <ActionButton
                data-tour="addScanToSale"
                type="button"
                onClick={handleAddScanToSale}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Add To Sale
              </ActionButton>
            )}

            <ActionButton
              data-tour="mapWeighLotScanToScan"
              type="button"
              onClick={openSelectScan}
            >
              <FontAwesomeIcon icon={faPlusCircle} />{" "}
              {status !== WeighLotScanStatus.MANUALLY_MAPPED ? "" : "Re-"}Map To
              Scan
            </ActionButton>
            <ActionButton
              data-tour="addWeighLotScanWeightToSaleLot"
              type="button"
              onClick={openSelectSaleLot}
            >
              <FontAwesomeIcon icon={faPlusCircle} />{" "}
              {status !== WeighLotScanStatus.MAPPED_TO_SALE_LOT ? "" : "Re-"}
              Add To Sale Lot
            </ActionButton>
          </>
        )}
        <ActionButton
          data-tour="weighLotScanAuditLog"
          type="button"
          onClick={handleOpenWeighLotScanAuditLog}
        >
          <FontAwesomeIcon icon={faHistory} />
        </ActionButton>
      </ActionButtonContainer>
      {isEditScansOpen && (
        <EditScansModal eids={[eid]} closeSelf={closeEditScans} />
      )}

      {isSelectScanOpen && (
        <MapWeighLotScanTableModal
          tableName={AgGridTables.SCAN}
          weighLotScanId={id}
          closeSelf={closeSelectScan}
        />
      )}
      {selectSaleLotOpen && (
        <MapWeighLotScanTableModal
          tableName={AgGridTables.SALE_LOT}
          weighLotScanId={id}
          closeSelf={closeSelectSaleLot}
        />
      )}
    </>
  );
}
