import PropTypes from "prop-types";
import styled from "styled-components/macro";

import StatusText from "./StatusText";
import Subtitle from "./Subtitle";

export const Text = styled.span(
  ({
    theme,
    color,
    error,
    success,
    center,
    block,
    fontSize,
    marginHorizontal,
    italic,
  }) => {
    let fontColor = color;
    if (success) {
      fontColor = "success";
    } else if (error) {
      fontColor = "error";
    }
    return `
  color: ${theme.colors[fontColor] || "inherit"};      
    ${center ? "text-align: center;" : ""}
    ${
      block
        ? `width: 100%; display: block; margin: ${theme.space[2]}px 0; `
        : ""
    }
    ${fontSize ? `font-size: ${theme.fontSizes[fontSize]}px` : ""};

    ${
      typeof marginHorizontal === "number"
        ? `
        margin-left: ${theme.space[marginHorizontal]}px;
        margin-right: ${theme.space[marginHorizontal]}px;
      `
        : ""
    }

    ${italic ? `font-style: italic` : ""}
      `;
  },
);

export const XsHeading = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.alpha}px;
  text-transform: uppercase;
  ${props => (props.bold ? "font-weight: bold;" : "")};
`;

export const SmHeading = styled(Text)`
  text-transform: uppercase;
  ${props => (props.bold ? "font-weight: bold;" : "")};
`;
SmHeading.propTypes = {
  bold: PropTypes.bool,
  color: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  center: PropTypes.bool,
  block: PropTypes.bool,
};

export const TitleText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.epsilon}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const BigText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.epsilon}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const MediumText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const BoldText = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const SmallText = styled.div(
  ({ theme, padding, justifyContent }) => ` 
      font-size:  ${theme.fontSizes.alpha}px;
      ${padding ? `padding: ${theme.space[padding]}px;` : ""}
      ${justifyContent ? `justify-content: ${justifyContent};` : ""}
      display: flex;
      flex: 1;
      `,
);

export { Subtitle, StatusText };
