import { BusinessColId, BusinessColumnDef } from "constants/columnDefinitions";
import {
  ManualAdjustmentColId,
  ManualAdjustmentColumnDef,
} from "constants/columnDefinitions/manualAdjustment";

export const columnDefs = [
  ...Object.values(ManualAdjustmentColumnDef),
  {
    ...BusinessColumnDef[BusinessColId.NAME],
    colId: ManualAdjustmentColId.FROM_BUSINESS,
    field: "fromBusiness.name",
    headerName: "From Business",
    cellRendererParams: {
      businessKey: "manualAdjustment.fromBusinessId",
    },
  },

  {
    ...BusinessColumnDef[BusinessColId.NAME],
    colId: ManualAdjustmentColId.TO_BUSINESS,
    field: "toBusiness.name",
    headerName: "To Business",
    cellRendererParams: {
      businessKey: "manualAdjustment.toBusinessId",
    },
  },
];
