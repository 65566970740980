import React from "react";

import { printPlacardV4 } from "actions";

import { Accreditation } from "constants/draftingAttributes";
import { PageSize, PlacardVersions } from "constants/pdf";

import { toTitleCase } from "lib";

import { sortByRoundAndPen } from "lib/auctionPens";
import { addSpaces, blackBox, fontSize, getVendorName, tick } from "lib/pdf";
import { getMarksLocationString } from "lib/saleLot";
import { formatTime } from "lib/timeFormats";

import agrinousLogo from "img/AgriNous-logo-black-white-base64";

import { RenderPlacard } from "./RenderPlacard";

export const getContentForPlacardV4 = (
  saleLots,
  deploymentIdBySaleLotIdLookup,
  deployments,
  vendorDeploymentBusinessBySaleLotIdLookup,
  isVendorBredBySaleLotIdLookup,
) => {
  const contents = [];
  sortByRoundAndPen(saleLots).forEach((saleLot, index) => {
    const deploymentBusiness =
      vendorDeploymentBusinessBySaleLotIdLookup[saleLot.id];

    const vendorName = getVendorName(saleLot, deploymentBusiness);
    const deploymentId = deploymentIdBySaleLotIdLookup[saleLot.id];
    const deployment = deployments[deploymentId];

    // text maps to determine the font size using fontSize()
    const commentTextMap = {
      45: 60,
      60: 50,
      120: 40,
      200: 40,
      500: 35,
    };

    let spaces = 9;
    if (!saleLot.vendor.defaultProperty) {
      spaces += 2;
    }
    if (!getMarksLocationString(saleLot)) {
      spaces += 4;
    }

    const descriptionTextMap = {
      15: 100,
      100: 85,
    };

    contents.push([
      {
        layout: "noBorders",
        table: {
          widths: [1140],
          body: [
            [""],
            ...addSpaces(6),
            [
              {
                width: 90,
                text: `${vendorName.substring(0, 20)}`,
                fontSize: 60,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: saleLot.quantity
                  ? `${saleLot.quantity} ${saleLot.sex_name} ${
                      saleLot.averageWeightFormattedRounded
                        ? ` @ ${saleLot.averageWeightFormattedRounded} Kg`
                        : ""
                    }`
                  : "-",
                fontSize: fontSize(
                  saleLot.quantity
                    ? `${saleLot.quantity} ${saleLot.sex_name} ${
                        saleLot.averageWeightFormattedRounded
                          ? ` @ ${saleLot.averageWeightFormattedRounded} Kg`
                          : ""
                      }`
                    : "-",
                  false,
                  descriptionTextMap,
                ),
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: saleLot.consignment?.vendor_property?.PIC || " ",
                fontSize: 65,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: getMarksLocationString(saleLot),
                fontSize: fontSize(
                  getMarksLocationString(saleLot),
                  false,
                  commentTextMap,
                ),
                alignment: "center",
                bold: true,
              },
            ],
            ...addSpaces(spaces),
            [
              {
                text: saleLot?.draftingAttributes
                  ? saleLot.draftingAttributes?.publicDescription
                  : "",
                fontSize: fontSize(
                  saleLot?.draftingAttributes
                    ? saleLot.draftingAttributes?.publicDescription
                    : "",
                  false,
                  commentTextMap,
                ),
                alignment: "center",
                bold: true,
                margin: [35, 0, 0, 0],
              },
            ],
          ],
        },
      },
      {
        columns: [
          {
            canvas: [
              {
                type: "rect",
                x: 20,
                y: 15,
                w: 1136,
                h: 790,
                lineWidth: 10,
                lineColor: deployment.corporateColor
                  ? deployment.corporateColor
                  : "#000000",
              },
            ],
            absolutePosition: { x: 4, y: 4 },
          },
          blackBox(
            "Vendor \nBred",
            500,
            508,
            deployment,
            40,
            45,
            215,
            false,
            175,
          ),
          tick(isVendorBredBySaleLotIdLookup[saleLot.id] === true, 215, 508),
          blackBox(
            "Grass \nFed",
            500,
            508,
            deployment,
            320,
            325,
            495,
            false,
            175,
          ),
          tick(
            saleLot.draftingAttributes?.accreditationGrassFed ===
              Accreditation.ALL,
            495,
            508,
          ),
          blackBox("PTIC", 500, 525, deployment, 610, 615, 785, false, 175),
          tick(
            saleLot.draftingAttributes?.accreditationPTIC === Accreditation.ALL,
            785,
            508,
          ),
          blackBox("Weaned", 500, 525, deployment, 890, 895, 1065, false, 175),
          tick(saleLot.draftingAttributes?.isWeaned, 1065, 508),
          {
            image: `data:image/jpeg;base64,${agrinousLogo}`,
            width: 100,
            absolutePosition: { x: 30, y: 778 },
          },
          {
            text: `${toTitleCase(
              saleLot.livestockAgency ? saleLot.livestockAgency.name : "",
            )} Pen: ${saleLot.penName ? saleLot.penName : ""} ${
              saleLot.vendor.shortCode ? saleLot.vendor.shortCode : ""
            } ${saleLot.vendor_name ? saleLot.vendor_name : ""}  ${formatTime(
              Date.now(),
            )}    -    TO BE SOLD AS DESCRIBED BY THE VENDOR, PARTICULARS FOR INFORMATION ONLY BUT NOT GUARANTEED.`,
            fontSize: 8,
            absolutePosition: { x: 140, y: 788 },
            // Insert a page break if there is another page.
            pageBreak: saleLots.length > index + 1 ? "after" : undefined,
          },
          {
            image: `logo_${deployment.id}`,
            absolutePosition: { x: 500, y: 40 },
            width: 800 * 0.8,
            height: 110 * 0.8,
          },
          {
            text: `Pen ${
              saleLot.auction_pen
                ? `${saleLot.auction_pen.start_pen}${
                    saleLot.auction_pen.end_pen
                      ? `-${saleLot.auction_pen?.end_pen}`
                      : ""
                  }`
                : ""
            }`,
            fontSize: 75,
            bold: true,
            absolutePosition: { x: 50, y: 30 },
          },
        ],
      },
    ]);
  });
  return contents;
};

export const RenderPlacardV4 = () => {
  return (
    <RenderPlacard
      action={printPlacardV4}
      version={PlacardVersions.V4}
      size={PageSize.A3}
    />
  );
};
