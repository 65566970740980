import React from "react";

import { Grid } from "@material-ui/core";
import { getIn, useFormikContext } from "formik";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import { BidderNumberInput } from "components/Form/BidderNumberInput";
import { BuyerAwareBuyerWayField } from "components/Form/Fields";
import {
  CheckBox,
  Input,
  IntegerPriceInput,
  PercentageInput,
  withNamespace,
} from "components/Form/FormikControls";

import { BUYER } from "constants/businesses";
import { SaleTypes } from "constants/sale";

import {
  ForClearingSale,
  ForLivestockAuction,
  ForLivestockAuctionAndPaddock,
  ForPaddock,
  ForSaleType,
} from "containers/ForSaleType";

import { formatPercentage, getDollarPriceStringFromCents } from "lib";

import { getCurrentSale, selectCurrentDeploymentSales } from "selectors";

import { useGetBuyerWayFromBidder } from "hooks";

import { isPricingTypeFormDirty, PricingType } from "./PricingType";

const businessRoles = [BUYER];

export function isSellingFormDirty(initialValues, values, namespace = "") {
  return (
    ![
      withNamespace(namespace, "bidIncrement"),
      withNamespace(namespace, "buyer_id"),
      withNamespace(namespace, "buyerNumber"),
      withNamespace(namespace, "buyer_way"),
      withNamespace(namespace, "buyersPremium"),
      withNamespace(namespace, "destination_property_id"),
      withNamespace(namespace, "isGSTExempt"),
      withNamespace(namespace, "listingFee"),
      withNamespace(namespace, "reservePrice"),
      withNamespace(namespace, "startingBid"),
      withNamespace(namespace, "thirdPartyId"),
      withNamespace(namespace, "invoiceToBusinessId"),
    ].every(key => {
      const a = get(values, key);
      const b = get(initialValues, key);
      return (
        a === b || (!a && !b) // used for all optional values, which may show up as "", or null, or undefined;
      );
    }) || isPricingTypeFormDirty(initialValues, values, namespace)
  );
}

function SellingSaleLotFormComponent(props) {
  const { deploymentSaleId, namespace: ns, quantity, readOnly } = props;
  const isUsingRegisteredBidders =
    useSelector(getCurrentSale)?.using_registered_bidders;
  const formikProps = useFormikContext();
  const getBuyerWayFromBidder = useGetBuyerWayFromBidder();

  const { values, setFieldValue } = formikProps;

  const defaultListingFeeCents = useSelector(
    state =>
      selectCurrentDeploymentSales(state)[deploymentSaleId]
        ?.default_listing_fee_cents,
  );

  const defaultBuyersPremium =
    useSelector(
      state =>
        selectCurrentDeploymentSales(state)[deploymentSaleId]
          ?.default_buyers_premium,
    ) || 0;

  function clearBuyerWay(businessId) {
    if (businessId !== getIn(values, withNamespace(ns, "buyer_id"))) {
      setFieldValue(withNamespace(ns, "buyer_way"), null);
    }
  }

  function onExistingBidderChanged(newBidder) {
    if (values.buyer_id !== String(newBidder.businessId)) {
      const buyerWay = getBuyerWayFromBidder(newBidder);

      setFieldValue(withNamespace(ns, "buyer_id"), newBidder.businessId);

      setFieldValue(
        withNamespace(ns, "buyer_way"),
        buyerWay.id ? buyerWay : null,
      );
      setFieldValue(
        withNamespace(ns, "destination_property_id"),
        newBidder.defaultPropertyId,
      );
    }
  }

  const saleType = useSelector(getCurrentSale)?.sale_type;

  return (
    <>
      <ForClearingSale>
        <Grid item xs={12} md={6}>
          <IntegerPriceInput
            beforeSymbol="$"
            decimalPlaces={2}
            label="Listing Fee"
            name="listingFee"
            placeholder={getDollarPriceStringFromCents(defaultListingFeeCents)}
            tooltip={`The Listing Fee for this Lot. If left blank, this Lot will use the default for the Sale ($${getDollarPriceStringFromCents(
              defaultListingFeeCents,
            )}).`}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <IntegerPriceInput
            label="Reserve Price"
            name="reservePrice"
            disabled={readOnly}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <IntegerPriceInput
            label="Starting Bid"
            name="startingBid"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <IntegerPriceInput
            label="Bid Increment"
            name="bidIncrement"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <IntegerPriceInput
            label="Original Listing Price"
            name="originalListingPrice"
            disabled={readOnly}
          />
        </Grid>
      </ForClearingSale>
      <Grid xs={12} item>
        <StateAwareBusinessPICSelector
          label="Buyer"
          businessFieldName="buyer_id"
          propertyFieldName="destination_property_id"
          formikProps={formikProps}
          businessRoles={businessRoles}
          onAfterSelect={clearBuyerWay}
          disabled={saleType === SaleTypes.OVER_HOOKS || readOnly}
        />
      </Grid>
      <ForLivestockAuctionAndPaddock>
        <Grid
          item
          xs={12}
          md={
            saleType === SaleTypes.PADDOCK
              ? 12
              : isUsingRegisteredBidders
                ? 4
                : 6
          }
        >
          <BuyerAwareBuyerWayField
            name={withNamespace(ns, "buyer_way")}
            label="Buyer Way"
            buyerField={withNamespace(ns, "buyer_id")}
            readOnly={readOnly}
          />
        </Grid>
        {isUsingRegisteredBidders && (
          <Grid item xs={12} md={4}>
            <BidderNumberInput
              disabled={readOnly}
              onExistingBidderChanged={onExistingBidderChanged}
            />
          </Grid>
        )}
      </ForLivestockAuctionAndPaddock>
      <ForLivestockAuction>
        <Grid item xs={12} md={isUsingRegisteredBidders ? 4 : 6}>
          <StateAwareBusinessPICSelector
            label="Third Party"
            tooltip="Commission Buyer"
            businessFieldName={withNamespace(ns, "thirdPartyId")}
            formikProps={formikProps}
            businessRoles={businessRoles}
            optional
            clearable
            disabled={readOnly}
          />
        </Grid>
      </ForLivestockAuction>
      <ForClearingSale>
        <Grid item xs={12} md={6}>
          {isUsingRegisteredBidders ? (
            <BidderNumberInput
              disabled={readOnly}
              onExistingBidderChanged={onExistingBidderChanged}
            />
          ) : (
            <Input
              label="Buyer Number"
              name="buyerNumber"
              type="number"
              disabled={readOnly}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentageInput
            label="Buyers Premium"
            name="buyersPremium"
            placeholder={formatPercentage(defaultBuyersPremium, {
              includeSymbol: false,
            })}
            tooltip={`The Buyer's Premium for this Lot. If left blank, this Lot will use the default for the Sale (${formatPercentage(
              defaultBuyersPremium,
            )}).`}
            disabled={readOnly}
          />
        </Grid>
      </ForClearingSale>

      <PricingType namespace={ns} quantity={quantity} readOnly={readOnly} />
      <ForPaddock>
        <Grid item xs={12}>
          <IntegerPriceInput
            label="Reserve Price"
            name="reservePrice"
            disabled={readOnly}
          />
        </Grid>
      </ForPaddock>

      <Grid item xs={12} md={12}>
        <StateAwareBusinessPICSelector
          label="Invoice To"
          businessFieldName={withNamespace(ns, "invoiceToBusinessId")}
          formikProps={formikProps}
          businessRoles={businessRoles}
          optional
          clearable
        />
      </Grid>

      <Grid item xs={6} md={6} container justifyContent="center">
        <CheckBox
          label="GST Exempt"
          labelPosition="top"
          name="isGSTExempt"
          disabled={readOnly}
        />
      </Grid>
      <ForSaleType saleType={SaleTypes.EXTERNAL_AGENT_SALE}>
        <Grid item xs={6} md={6} container justifyContent="center">
          <Input
            label="Account Note"
            name={withNamespace(ns, "accountNote")}
            disabled={readOnly}
          />
        </Grid>
      </ForSaleType>
    </>
  );
}
SellingSaleLotFormComponent.propTypes = {
  deploymentSaleId: PropTypes.number,
  quantity: PropTypes.number,
  namespace: PropTypes.string,
};

export default SellingSaleLotFormComponent;
