import { createSelector } from "reselect";

import { getMasterRuleStatus } from "lib/rules";

import {
  getBillingTags,
  getMasterRuleBooks,
  getMasterRules,
  getRules,
  selectRuleIdsByRuleBookIdLookup,
} from "selectors";

import { selectMappedLedgerAccountByCodeLookup } from "selectors/ledgerAccounts";

export const selectParsedRuleContentByRuleIdLookup = createSelector(
  [getRules],
  ruleLookup =>
    Object.values(ruleLookup).reduce((acc, { id, content }) => {
      acc[id] = JSON.parse(content) || {};
      return acc;
    }, {}),
);

export const selectRulesAggridDataByRuleBookIdLookup = createSelector(
  [
    selectRuleIdsByRuleBookIdLookup,
    getRules,
    getMasterRules,
    getMasterRuleBooks,
    getBillingTags,
    selectMappedLedgerAccountByCodeLookup,
    selectParsedRuleContentByRuleIdLookup,
  ],
  (
    ruleIdsByRuleBookIdLookup,
    ruleLookup,
    masterRuleLookup,
    masterRuleBookLookup,
    billingTagLookup,
    mappedLedgerAccountByCodeLookup,
    parsedRuleContentByRuleIdLookup,
  ) => {
    return Object.entries(ruleIdsByRuleBookIdLookup).reduce(
      (acc, [ruleBookId, ruleIds]) => {
        acc[ruleBookId] = ruleIds.map(ruleId => {
          const rule = ruleLookup[ruleId];
          const masterRule =
            masterRuleLookup[ruleLookup[ruleId]?.master_rule_id];
          const parsedContent = parsedRuleContentByRuleIdLookup[ruleId];

          parsedContent.billing_tags = parsedContent.billing_tags?.map(
            billingTagId => billingTagLookup[billingTagId]?.name,
          );

          const masterRuleStatus = getMasterRuleStatus(rule, masterRule);

          return {
            rule: {
              ...rule,
              parsedContent,
            },
            masterRule,
            masterRuleStatus,
            masterRuleBook: masterRuleBookLookup[masterRule?.rule_book_id],
            ledgerAccount: mappedLedgerAccountByCodeLookup[rule.gl_code],
          };
        });
        return acc;
      },
      {},
    );
  },
);
