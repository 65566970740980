import { kebabCase } from "lodash";
import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import {
  addSaleFailure,
  addSaleSuccess,
  deleteSaleFailure,
  deleteSaleSuccess,
  receiveConsignableSales,
  requestConsignableSalesError,
  SaleAction,
  setCurrentLivestockSaleId,
  updateSale,
} from "actions";

import { patchDeploymentSale } from "actions/offline/deploymentSale";

import {
  ADD_SALE,
  ADD_SALE_SUCCESS,
  DELETE_CURRENT_SALE,
  GET_CONSIGNABLE_SALES,
  GET_DASHBOARD_DATA,
  SALE,
  UPDATE_SALE_AND_DEPLOYMENT_SALES,
} from "constants/actionTypes";
import { SaleTypes } from "constants/sale";

import {
  getLivestockSaleId,
  getSaleEntryRoute,
  openConsignmentsTable,
  openEditConsignmentModal,
} from "lib/navigation";
import toast from "lib/toast";

import { getConsignments, getCurrentSale, getSaleLots } from "selectors";

import history from "appHistory";

import { api } from "./api";

const requestText = "Creating Sale";

function* postSale({ sale }) {
  toast.syncing(requestText, { autoClose: false });
  try {
    const url = `/v2/sales/`;
    const body = { ...sale };
    delete body.saleyardName;
    const salePromise = yield call(api.post, url, body);
    yield put(addSaleSuccess(yield salePromise));
  } catch (e) {
    toast.dismiss(kebabCase(requestText));
    yield put(addSaleFailure(e.statusText));
    toast.error("Error adding a new sale");
  }
}

function afterSale({ sale }) {
  toast.dismiss(kebabCase(requestText));
  const { sale_type } = sale;
  let message = `${sale.saleyard_name}`;
  if (sale.saleyard_name !== sale.sale_type) {
    message = `${message} ${sale.sale_type}`;
  } else {
    message = `${message} sale`;
  }
  message = `${message} successfully added.`;
  toast.success(message);

  history.push(
    getSaleEntryRoute(
      sale.saleyard_name,
      sale.livestocksale_id,
      sale.sale_type,
    ),
  );
  if (sale_type === SaleTypes.PADDOCK) {
    // if we create a paddock sale, we want to direct the user
    // straight to the consignment form
    openConsignmentsTable();
    openEditConsignmentModal();
  }
}

function* deleteCurrentSale() {
  const state = yield select();
  const sale = getCurrentSale(state);
  const saleLots = getSaleLots(state);
  const consignments = getConsignments(state);

  const errorMessage = name =>
    `You can’t delete a sale that contains any ${name}`;

  if (Object.values(saleLots).length > 0) {
    toast.error(errorMessage("lots"));
  } else if (Object.values(consignments).length > 0) {
    if (sale.sale_type?.includes(SaleTypes.CLEARING)) {
      toast.error(errorMessage("vendors"));
    } else {
      toast.error(errorMessage("consignments"));
    }
  } else {
    try {
      const promise = yield call(
        api.destroy,
        `/v2/sales/${getLivestockSaleId()}`,
      );
      yield promise;
      yield put(deleteSaleSuccess(getLivestockSaleId()));
      toast.success(`sale #${getLivestockSaleId()} successfully removed.`);
    } catch (e) {
      yield put(deleteSaleFailure(e));
      toast.error(`Error removing sale #${getLivestockSaleId()}`);
    }
  }
}

function* fetchDashboardData() {
  // const data = yield call(api.get, "/v2/dashboard/");
  // yield put(receiveDashboardData(yield data));
  // TODO: Improve performance and re-implement this feature.
}

function* fetchConsignableSales(action) {
  try {
    const consignableSalesPromise = yield call(
      api.get,
      `/v2/sales/consignable_sales/`,
    );
    yield put(receiveConsignableSales(yield consignableSalesPromise));
  } catch (e) {
    yield call(api.handleFetchError, e, "consignable sales", action);
    yield put(requestConsignableSalesError(e));
  }
}

function* fetchAndOpen({ saleId }) {
  yield put(SaleAction.requestOne({ id: saleId }));

  const { success } = yield race({
    success: take(SALE.FETCH.SUCCESS),
    timeout: delay(10000),
  });

  if (success) {
    yield put(setCurrentLivestockSaleId(saleId, true));
  }
}

function* handleUpdateSaleAndDeploymentSale(action) {
  const { livestockSaleId, salePayload, deploymentSales } = action;

  for (const deploymentSale of deploymentSales) {
    yield put(
      patchDeploymentSale(
        deploymentSale.payload,
        deploymentSale.deploymentSaleId,
        livestockSaleId,
        {
          updateExistingSaleLotExportSites:
            deploymentSale.payload?.updateExistingSaleLotExportSites,
        },
      ),
    );
  }

  yield put(updateSale(salePayload, livestockSaleId));
}

export default function* rootSaga() {
  yield takeEvery(GET_CONSIGNABLE_SALES.REQUEST, fetchConsignableSales);
  yield takeLatest(GET_DASHBOARD_DATA.REQUEST, fetchDashboardData);
  yield takeEvery(ADD_SALE, postSale);
  yield takeEvery(ADD_SALE_SUCCESS, afterSale);
  yield takeEvery(DELETE_CURRENT_SALE, deleteCurrentSale);
  yield takeEvery(SALE.FETCH_AND_OPEN.REQUEST, fetchAndOpen);

  yield takeEvery(
    UPDATE_SALE_AND_DEPLOYMENT_SALES,
    handleUpdateSaleAndDeploymentSale,
  );
}
