import React from "react";

import { faImage } from "@fortawesome/pro-duotone-svg-icons";
import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Badge from "components/Badge";
import { Paper } from "components/Paper";
import { ReadOnly } from "components/ReadOnly";
import { ShowMore } from "components/ShowMore";

import { LotStatus } from "constants/clearingSaleAttributes";
import { SaleLotModalSection } from "constants/navigation";

import { formatDecimal } from "lib";

import { openEditSaleLotModal } from "lib/navigation";
import { getCombinedLotNumber } from "lib/saleLot";

import {
  getBusinessById,
  getHasWriteAccessInCurrentSale,
  getSaleLotById,
  getThumbnailUrlBySaleLotId,
  getVendorIdBySaleLotId,
} from "selectors";

import { useTheme } from "hooks";

import { SaleLotMenu } from "./CardOptionsMenu";
import { Title } from "./Title";

const Thumbnail = styled.img`
  max-height: auto;
  width: 100%;
`;

const ClearingSaleStatusColourMap = {
  [LotStatus.PENDING]: "pending",
  [LotStatus.APPROVED]: "approved",
  [LotStatus.ACTIVE]: "activeColour",
  [LotStatus.WITHDRAWN]: "withdrawn",
  [LotStatus.PASSED_IN]: "passedIn",
  [LotStatus.SOLD]: "sold",
  [LotStatus.PAID]: "paid",
  [LotStatus.FOR_SALE]: "forSale",
  [LotStatus.NO_BIDS]: "noBids",
  [LotStatus.WANTED]: "wanted",
};

const NoAttachments = styled(FontAwesomeIcon)`
  ${({ theme, readOnly }) => `
  
font-size: ${theme.fontSizes.eta}px;
color: ${theme.colors.primary};
${
  !readOnly
    ? `
  cursor: pointer;
  &:hover {
    color: ${theme.colors.primaryHighlight};
  }`
    : ""
}`}
`;

const SortHandle = styled(FontAwesomeIcon)(
  ({ theme, color }) => `
    color: ${theme.colors[color]};
    font-size: 24px;
    margin-right: 12px;
  `,
);

const ListingIdText = styled.div`
  font-size: 12px;
  display: flex;
  font-style: italic;
  font-weight: normal;
`;

const Handle = ({ color }) => <SortHandle icon={faBars} color={color} />;

export const LotCard = React.memo(
  ({ saleLotId, readOnly, showVendor, showAll }) => {
    const saleLot = useSelector(getSaleLotById(saleLotId));
    const thumbnailUrl = useSelector(getThumbnailUrlBySaleLotId(saleLotId));
    const vendorId = useSelector(getVendorIdBySaleLotId(saleLotId));
    const vendor = useSelector(getBusinessById(vendorId));
    const theme = useTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints[0]}px)`);
    const hasWriteAccessInCurrentSale = useSelector(
      getHasWriteAccessInCurrentSale,
    );
    // if consigned and is pending show
    // else if consigned and is not pending, edit only for deployment who created sale
    const allowEditActions =
      hasWriteAccessInCurrentSale ||
      saleLot.clearingSaleAttributes.status === LotStatus.PENDING;

    const {
      comments = "",
      publicDescription = "",
      status,
      title,
    } = saleLot?.clearingSaleAttributes || {};

    const gotoAttachments = () => {
      !readOnly && openEditSaleLotModal(saleLotId, SaleLotModalSection.MEDIA);
    };

    const Comments = () => (
      <span>
        <b>Comments:</b> {comments}
      </span>
    );
    return saleLot ? (
      <Paper data-tour={title}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10} container direction="row" alignItems="center">
            {isMobile && <Handle color="primary" />}
            <Badge color={ClearingSaleStatusColourMap[status]}>{status}</Badge>
            <Title>
              {showVendor && <div>{vendor?.name}</div>}
              Lot {getCombinedLotNumber(saleLot)}: {title}{" "}
              <ListingIdText>
                Listing Id: {saleLot.listingId.toUpperCase()}
              </ListingIdText>
            </Title>
          </Grid>
          <Grid
            item
            xs={2}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <ReadOnly readOnly={!allowEditActions}>
              <SaleLotMenu saleLotId={saleLotId} />
            </ReadOnly>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} md={1}>
            {thumbnailUrl ? (
              <Thumbnail src={thumbnailUrl} onClick={gotoAttachments} />
            ) : (
              <NoAttachments
                icon={faImage}
                onClick={gotoAttachments}
                readOnly={readOnly}
              />
            )}
          </Grid>
          <Grid item xs={4} md={5}>
            <ShowMore
              showAll={showAll}
              limit={100}
              text={publicDescription}
              extraWhenExpanded={comments && <Comments />}
            />
          </Grid>
          <Grid item xs={4} md={6}>
            <div>Starting Bid: ${formatDecimal(saleLot.startingBid / 100)}</div>
            <div>Reserve: ${formatDecimal(saleLot.reservePrice / 100)}</div>
            <div>Increment: ${formatDecimal(saleLot.bidIncrement / 100)}</div>
            <div>
              Final Bid: ${formatDecimal(saleLot.total_price_cents / 100)}
            </div>
          </Grid>
        </Grid>
      </Paper>
    ) : null;
  },
);
