import React from "react";

import { useSelector } from "react-redux";

import { Button } from "components/Form";

import { getActiveRole, getCurrentSale } from "selectors";

export const SaleExport = () => {
  const livestockSaleId = useSelector(getCurrentSale).livestocksale_id;
  const userRoleSlug = useSelector(getActiveRole).slug;

  const openExportInNewWindow = () =>
    window.open(
      `/v2/export/?format=json&livestocksale-id__in=${livestockSaleId}&output_format=html&user_role=${userRoleSlug}`,
    );

  return (
    <Button onClick={openExportInNewWindow}>(Staff) View Sale Export</Button>
  );
};
