import React from "react";

import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { EmailAction } from "actions/email";
import { ReportJobAction } from "actions/reportJob";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { Column } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { TitleText } from "components/Text";

import { AgGridTables } from "constants/aggrid";
import { ReportJobColumnDef } from "constants/columnDefinitions/reportJob";
import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";

import { EmailDetailGridOptions } from "lib/agGrid/columnDefinitions/email";
import { openModalLink } from "lib/navigation";

import { getBusinesses, getSales } from "selectors";

import { selectReportJobAggridData } from "selectors/aggrid/reportJob";

import { useMountEffect } from "hooks";

const getRowId = ({ data }) => data.reportJob.id;

const columnDefs = Object.values(ReportJobColumnDef);

export const ReportJobList = () => {
  const businessByIdLookup = useSelector(getBusinesses);
  const saleByIdLookup = useSelector(getSales);
  const data = useSelector(selectReportJobAggridData);

  const additionalActions = [
    {
      title: "Generate",
      icon: faGear,
      onClick: () => openModalLink(ModalTypes.MultiSaleReports, {}),
      dataTour: "openGenerateReportModal",
    },
  ];

  const detailCellRendererParams = {
    detailGridOptions: EmailDetailGridOptions,
    getDetailRowData: ({ data, successCallback }) => {
      successCallback(data.reportJob.emails);
    },
  };

  return (
    <Column fullHeight>
      <Box p={2}>
        <TitleText>Reports</TitleText>
      </Box>
      <AgGridTable
        additionalActions={additionalActions}
        columnDefs={columnDefs}
        context={{ businessByIdLookup, saleByIdLookup }}
        detailCellRendererParams={detailCellRendererParams}
        getRowId={getRowId}
        masterDetail
        rowData={data}
        tableName={AgGridTables.REPORT_JOB}
      />
    </Column>
  );
};

export const ReportJobListWrapper = () => {
  const dispatch = useDispatch();
  useMountEffect(() => {
    dispatch(ReportJobAction.pusher_subscribe());
    dispatch(ReportJobAction.request());
    dispatch(EmailAction.request({ hasReportJob: true }));

    return () => {
      dispatch(ReportJobAction.pusher_unsubscribe());
    };
  }, [dispatch]);
  return (
    <WaitForSync
      requiredData={[
        ApiModel.REPORT_JOBS,
        ApiModel.BUSINESSES,
        ApiModel.SALES,
        ApiModel.EMAIL,
      ]}
    >
      <ReportJobList />
    </WaitForSync>
  );
};
