import React, { useState } from "react";

import {
  faLock,
  faLockOpen,
  faRecycle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMapMarker,
  faMicroscope,
  faPencil,
} from "@fortawesome/pro-solid-svg-icons";
import { scheduleRetry } from "@redux-offline/redux-offline/lib/actions";
import { useDispatch, useSelector } from "react-redux";

import { bulkUpdateScanNLISStatus, requestDashBoardDataChanges } from "actions";

import { patchDeploymentSale } from "actions/offline/deploymentSale";

import { ContextMenuPopoverButton } from "components/Button/MultiButton";
import { Row } from "components/Layout";

import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";

import {
  getLivestockSaleId,
  openBusinessAlternatives,
  openBusinessesInSale,
  openEditCheckpointModal,
  openRuleTester,
  openSaleModal,
} from "lib/navigation";
import toast from "lib/toast";

import {
  currentSaleSelector,
  getActiveLivestockAgentDeployment,
  getIsLivestockAgent,
  getIsNominationsLocked,
  getIsStaff,
} from "selectors";

import {
  useHasBusinessAlternativesPermission,
  useHasDeploymentPermission,
  useHasSaleyardOrDeploymentPermission,
} from "hooks";

export function SaleActionsButton({ isMobile }) {
  const dispatch = useDispatch();

  const isLivestockAgent = useSelector(getIsLivestockAgent);
  const isStaff = useSelector(getIsStaff);

  const sale = useSelector(currentSaleSelector);
  const deploymentSale = sale.deployment_sales[0];
  const nominationsLocked = useSelector(getIsNominationsLocked);

  const deploymentId = useSelector(state =>
    isLivestockAgent ? getActiveLivestockAgentDeployment(state).id : null,
  );

  const hasBusinessAlternativesPermission =
    useHasBusinessAlternativesPermission();
  const hasBillingPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureBilling,
  );

  const hasCreateCheckpointPermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.canCreateCheckpoint,
    SaleyardPermissions.canCreateCheckpoint,
  );

  const [isPromptButtonEnabled, setIsPromptButtonEnabled] = useState(true);
  const [isUpdateScansButtonEnabled, setIsUpdateScansButtonEnabled] =
    useState(true);

  const hasCanLockNominationsPermission = useHasDeploymentPermission(
    DeploymentPermissions.canLockNominations,
  );

  const handlePromptSync = () => {
    toast.success("Prompting changes since synchronization");
    dispatch(requestDashBoardDataChanges(sale, sale.saleyard_name));
    setIsPromptButtonEnabled(false);
    setTimeout(() => {
      setIsPromptButtonEnabled(true);
    }, 10000);
  };

  const handleUpdateScanNLISStatus = () => {
    dispatch(bulkUpdateScanNLISStatus());
    setIsUpdateScansButtonEnabled(false);
    setTimeout(() => {
      setIsUpdateScansButtonEnabled(true);
    }, 10000);
  };

  const handleRunQueue = () => {
    dispatch(scheduleRetry());
  };

  const handleOpenRuleTester = () => {
    openRuleTester("", [getLivestockSaleId()]);
  };

  const handleLockNominations = () => {
    dispatch(
      patchDeploymentSale(
        { is_nominations_locked: !nominationsLocked },
        deploymentSale.deployment_sale_id,
        getLivestockSaleId(),
      ),
    );
  };

  const buttons = [
    {
      title: "Edit Sale",
      icon: faPencil,
      onClick: () => openSaleModal(getLivestockSaleId()),
    },
    hasBusinessAlternativesPermission && {
      title: `Business In Sale`,
      onClick: openBusinessesInSale,
    },
    hasBusinessAlternativesPermission && {
      title: `Business Alternatives`,
      onClick: openBusinessAlternatives,
      default: true,
    },

    hasCreateCheckpointPermission && {
      title: "Add Checkpoint",
      icon: faMapMarker,
      onClick: () => openEditCheckpointModal(null, { deploymentId }),
    },
    isStaff && {
      title: "Run Changes Since",
      icon: faRecycle,
      onClick: handlePromptSync,
      disabled: isPromptButtonEnabled,
    },

    isStaff && {
      title: "Trigger Retry Queue",
      icon: faRecycle,
      onClick: handleRunQueue,
    },

    isStaff && {
      title: "Refresh Scans",
      icon: faRecycle,
      onClick: handleUpdateScanNLISStatus,
      disabled: !isUpdateScansButtonEnabled,
    },

    hasBillingPermission && {
      title: "Test RuleBook",
      icon: faMicroscope,
      onClick: handleOpenRuleTester,
    },
    hasCanLockNominationsPermission && {
      title: `${nominationsLocked ? "Unlock" : "Lock"} Nominations`,
      icon: nominationsLocked ? faLock : faLockOpen,
      onClick: handleLockNominations,
    },
  ].filter(Boolean);

  return (
    <Row alignStart paddingVertical={isMobile ? 0 : 2}>
      <ContextMenuPopoverButton buttons={buttons} />
    </Row>
  );
}
