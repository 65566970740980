import React from "react";

import { PropTypes } from "prop-types";

import AgGrid from "components/AgGrid/AgGrid";
import { CompositeFilter } from "components/AgGrid/filters/compositeFilter";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { Button, SecondaryButton } from "components/Form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { ACTION_COLUMN_NAME } from "constants/aggrid";

import { actionsColumnRenderer } from "./Actions";

const defaultColDef = {
  sortable: true,
  resizable: true,
};

const components = {
  [CompositeFilter.componentName]: CompositeFilter,
};

export const BusinessTableConfiguration = {
  SG8XML: 0,
  AuctionsPlusCSV: 1,
};

const BusinessTableColumns = {
  [BusinessTableConfiguration.SG8XML]: [
    {
      headerName: "Business Name",
      field: "name",
    },
    {
      headerName: "Agent Code",
      field: "shortCode",
    },
    {
      headerName: "SY Code",
      field: "shortCodeSaleyard",
    },

    {
      headerName: "Default PIC",
      field: "defaultProperty.PIC",
    },
    {
      headerName: "PICS",
      valueGetter: ({ data }) => data.properties.map(p => p.PIC),
    },
    {
      headerName: "Branch",
      field: "branchName",
    },
  ],
  [BusinessTableConfiguration.AuctionsPlusCSV]: [
    {
      headerName: "Business Name",
      field: "name",
    },
    {
      headerName: "AuctionsPlus Short Code",
      field: "shortCodeAuctionsPlus",
    },
    {
      headerName: "Town",
      field: "address.locality",
    },

    {
      headerName: "State",
      field: "address.state",
    },
  ],
};

const checkIsOverrideFunctions = {
  [BusinessTableConfiguration.SG8XML]: data => data.shortCodeSaleyard,
  [BusinessTableConfiguration.AuctionsPlusCSV]: data =>
    data.shortCodeAuctionsPlus,
};

const confirmOverrideMessages = {
  [BusinessTableConfiguration.SG8XML]: (business, data) =>
    `Are you sure you want to override the current saleyard code "${business.shortCodeSaleyard}" with "${data.businessShortCode}"`,
  [BusinessTableConfiguration.AuctionsPlusCSV]: (business, data) =>
    `Are you sure you want to override the current AuctionsPlus Short Code "${business.shortCodeAuctionsPlus}" for "${business.name}" with "${data.auctionsPlusId}"`,
};

const BusinessTable = ({
  data,
  importData,
  mapBusiness,
  tableConfiguration,
}) => {
  const [confirmOverride, setConfirmOverride] = React.useState(false);

  const columnDefs = React.useMemo(() => {
    return [
      ...BusinessTableColumns[
        tableConfiguration || BusinessTableConfiguration.SG8XML
      ],
      {
        headerName: ACTION_COLUMN_NAME,
        cellRenderer: actionsColumnRenderer,
        pinned: "right",
        cellRendererParams: {
          checkIsOverride: checkIsOverrideFunctions[tableConfiguration],
          setConfirmOverride,
          mapBusiness,
        },
      },
    ];
  }, [mapBusiness, tableConfiguration]);

  const clearConfirmingBusiness = () => setConfirmOverride(false);
  return (
    <>
      <CollapseTableWrapper>
        <AgGrid
          domLayout="autoHeight"
          rowData={data}
          suppressColumnVirtualisation
          suppressScrollOnNewData
          pagination
          paginationPageSize={400}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowBuffer={10}
          components={components}
        />
      </CollapseTableWrapper>

      {confirmOverride && (
        <Dialog open onClose={clearConfirmingBusiness} maxWidth="sm" fullWidth>
          <DialogTitle id="form-dialog-title" onClose={clearConfirmingBusiness}>
            Are you sure
          </DialogTitle>
          <DialogContent dividers>
            <div>
              {confirmOverrideMessages[tableConfiguration](
                confirmOverride,
                importData,
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={clearConfirmingBusiness}>
              Cancel
            </SecondaryButton>
            <Button onClick={() => mapBusiness(confirmOverride)}>Yes</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

BusinessTable.propTypes = {
  mapBusiness: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default BusinessTable;
