import React from "react";

import { faExternalLink } from "@fortawesome/pro-duotone-svg-icons";
import { IconButton } from "@material-ui/core";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { Row } from "components/Layout";
import ReportsDropdown from "components/ReportsDropdown";
import { CellCentPriceDollars, HeaderRightAlign } from "components/Table";

import { colors } from "constants/theme";

import { openOverview } from "lib/navigation";

import { DENTITION_REASONING } from "selectors";

import { ReportTabActions, ReportFavouriteButton } from "./ReportTabActions";

const favouritesColumns = (header, accessor) => [
  {
    maxWidth: 40,
    Cell: row => <ReportFavouriteButton slug={row.original.slug} />,
  },
  {
    Header: header,
    accessor,
  },
  {
    Header: "Report",
    accessor: "name",
  },
  {
    style: { justifyContent: "center" },
    width: 170,
    Cell: row => <ReportTabActions row={row.original} />,
  },
];

const agentReport = {
  columnsAgentWithAgency: [
    {
      maxWidth: 40,
      Cell: row => <ReportFavouriteButton slug={row.original.slug} />,
    },
    {
      Header: "Agency",
      accessor: "agency",
    },
    {
      Header: "Report",
      accessor: "name",
    },
    {
      style: { justifyContent: "center" },
      width: 170,
      Cell: row => <ReportTabActions row={row.original} />,
    },
  ],
  columnsAgent: selectedAgent =>
    [
      {
        Header: "Agent",
        accessor: "business_name",
      },
      selectedAgent.value && {
        Header: "Vendor",
        accessor: "vendor_name",
      },
      selectedAgent.value && {
        Header: "Hd",
        accessor: "head_count",
        maxWidth: 50,
      },
      selectedAgent.value && {
        Header: <HeaderRightAlign>Unit Price</HeaderRightAlign>,
        accessor: "unit_price",
        Cell: CellCentPriceDollars,
      },
      selectedAgent.value && {
        Header: <HeaderRightAlign>Total Price</HeaderRightAlign>,
        accessor: "total_price",
        Cell: CellCentPriceDollars,
      },
      {
        style: { justifyContent: "center" },
        header: "Agent Reports",
        width: 170,
        Cell: row => {
          // If there is no business_cbid it's the Grouped Report
          // show the actions buttons instead
          if (row.original.business_cbid === null) {
            return <ReportTabActions row={row.original.links[0]} />;
          }
          return (
            <Row>
              <ReportsDropdown
                options={row.original.links}
                businessId={row.original.business_cbid}
                title={
                  row.original.vendor_name
                    ? "Download Vendor Report"
                    : "Download Agent Report"
                }
              />
            </Row>
          );
        },
      },
    ].filter(Boolean),
  columnsGenericFile: [
    {
      maxWidth: 40,
      Cell: row => <ReportFavouriteButton slug={row.original.slug} />,
    },
    {
      accessor: "name",
    },
    {
      style: { justifyContent: "center" },
      width: 170,
      Cell: row => <ReportTabActions row={row.original} />,
    },
  ],
  columnsBranches: [
    {
      maxWidth: 40,
      Cell: row => <ReportFavouriteButton slug={row.original.slug} />,
    },
    {
      accessor: "name",
    },
    {
      style: { justifyContent: "center" },
      width: 170,
      Cell: row => <ReportTabActions row={row.original} />,
    },
  ],
  columnsBuyers: [
    {
      Header: "Buyer",
      accessor: "business_name",
    },
    {
      Header: "Hd",
      accessor: "head_count",
      maxWidth: 50,
    },
    {
      Header: <HeaderRightAlign>Unit Price</HeaderRightAlign>,
      accessor: "unit_price",
      Cell: CellCentPriceDollars,
    },
    {
      Header: <HeaderRightAlign>Total Price</HeaderRightAlign>,
      accessor: "total_price",
      Cell: CellCentPriceDollars,
    },
    {
      style: { justifyContent: "center" },
      Cell: row => {
        return (
          <Row>
            <ReportsDropdown
              options={row.original.links}
              businessId={row.original.business_cbid}
              title="Download Buyer Report"
            />
          </Row>
        );
      },
    },
  ],
  columnsVendors: [
    {
      Header: "Vendor",
      accessor: "business_name",
    },
    {
      Header: "Hd",
      accessor: "head_count",
      maxWidth: 50,
    },
    {
      Header: <HeaderRightAlign>Unit Price</HeaderRightAlign>,
      accessor: "unit_price",
      Cell: CellCentPriceDollars,
    },
    {
      Header: <HeaderRightAlign>Total Price</HeaderRightAlign>,
      accessor: "total_price",
      Cell: CellCentPriceDollars,
    },
    {
      style: { justifyContent: "center" },
      Cell: row => (
        <Row>
          <ReportsDropdown
            options={row.original.links}
            title="Download Vendor Report"
            businessId={row.original.business_cbid}
          />
        </Row>
      ),
    },
  ],
  columnsSaleFiles: [
    {
      maxWidth: 40,
      Cell: row => <ReportFavouriteButton slug={row.original.slug} />,
    },
    {
      accessor: "name",
    },
    {
      style: { justifyContent: "center" },
      width: 170,
      Cell: row => {
        if (row.original.unavailable_reason === DENTITION_REASONING) {
          return (
            <div>
              {row.original.unavailable_params.join(" and ")} Not Completed
              <IconButton onClick={() => openOverview()}>
                <FaIcon icon={faExternalLink} color={colors.primary} />
              </IconButton>
            </div>
          );
        } else if (row.original.unavailable_reason) {
          return row.original.unavailable_reason;
        } else if (row.original.unavailable) {
          return "This report is currently unavailable.";
        } else {
          return <ReportTabActions row={row.original} />;
        }
      },
    },
  ],
  columnsNLIS: [
    {
      maxWidth: 40,
      Cell: row => <ReportFavouriteButton slug={row.original.slug} />,
    },
    {
      accessor: "name",
    },
    {
      style: { justifyContent: "center" },
      width: 170,
      Cell: row => {
        if (row.original.unavailable) {
          if (row.original.unavailable_reason) {
            return row.original.unavailable_reason;
          }
          return "This report is currently unavailable.";
        }
        return <ReportTabActions row={row.original} />;
      },
    },
  ],
  columnsAgenciesFavourites: favouritesColumns("Agency", "agency"),
  columnsBuyersFavourites: favouritesColumns("Buyer", "business_name"),
  columnsVendorsFavourites: favouritesColumns("Vendor", "business_name"),
  columnsGeneralFavourites: favouritesColumns("", "agency"),
};
export { agentReport };
