import React from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { PaddedIcon } from "components/Layout";

import { sortNumericalStrings } from "lib";

const buyerWayNameKeySelector = obj => obj.name;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 6px;
`;

const Card = styled.div`
  box-shadow: ${({ theme }) => theme.shadows[2]};
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  margin: 6px;
  padding: 12px;
  background-color: #fff;
`;

const AddBuyerWay = styled(FontAwesomeIcon)`
  margin: 6px 6px 6px 6px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 24px;
  cursor: pointer;
`;

const BlueText = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

const BuyerWays = ({ ways, setBuyerWayId, onClickAddBuyerWay, loading }) => {
  const wayCards = sortNumericalStrings(ways, buyerWayNameKeySelector)
    .filter(buyerWay => buyerWay.isShown !== false)
    .map(way => (
      <Card
        data-tour={way.name}
        key={way.id}
        onClick={() => setBuyerWayId(way.id)}
      >
        {way.name}
      </Card>
    ));

  return (
    <Wrapper>
      {loading && <Card>Loading...</Card>}
      {wayCards}
      <PaddedIcon onClick={onClickAddBuyerWay}>
        <AddBuyerWay data-tour="addBuyerWay" icon={faPlus} />
        {wayCards.length === 0 && <BlueText>Add Buyer Way</BlueText>}
      </PaddedIcon>
    </Wrapper>
  );
};

BuyerWays.propTypes = {
  ways: PropTypes.array,
  setBuyerWayId: PropTypes.func.isRequired,
  onClickAddBuyerWay: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default BuyerWays;
