import { isEmpty } from "lodash";
import { all, call, put, select, take, takeLatest } from "redux-saga/effects";

import {
  BusinessAction,
  BusinessUserRoleAction,
  checkUserAuthError,
  checkUserAuthSuccess,
  clearLocalState,
  CurrentUserAction,
  LivestockAgentRoleAction,
  loginError,
  loginSuccess,
  logoutError,
  logoutSuccess,
  nlisSignUpError,
  receiveNLISSignUp,
  receiveSaleDefinitionsSuccess,
  receiveWatcherEntry,
  resetOfflineState,
  SaleWatcherRoleAction,
  SaleyardAdminRoleAction,
  ScaleOperatorRoleAction,
  setActiveRole,
  setConcurrentUserBlock,
  setGeoBlock,
  setSetting,
  storeGeoData,
} from "actions";

import {
  API_RESPONSE,
  CHECK_USER_AUTH,
  CURRENT_USER,
  FETCH_GEO_DATA,
  GET_DEFAULT_ROUNDS_AND_SALE_TYPES,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  NLIS_SIGN_UP_REQUEST,
  NLIS_SIGN_UP_SUCCESS,
  REQUEST_WATCHER_ENTRY,
  RESTORE_SESSION,
  SALE,
  SEND_TO_USER_OVERVIEW,
  SET_ACTIVE_ROLE,
  SET_CURRENT_LIVESTOCKSALE,
  USER_ROLES,
} from "constants/actionTypes";
import { NLISCredentialType } from "constants/nlis";
import { Settings } from "constants/settings";
import { userTypes } from "constants/users";
import { WATCHER_STATES } from "constants/watcher";

import { removeBlankString } from "lib";

import { getReloadActionFromSlug, getWatcherRole } from "lib/auth";
import { prepareFetch, responseToJson } from "lib/fetch";
import { isHelpHeroActive } from "lib/helpHero/config";
import { identifyUserToHelpHero } from "lib/helpHero/identify";
import { isLogRocketActive } from "lib/logRocket/config";
import { identifyUserToLogRocket } from "lib/logRocket/identify";
import {
  getDashboardRoute,
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getUserOverviewRoute,
  getWatchRoute,
} from "lib/navigation";
import { isSentryActive } from "lib/sentry/config";
import { identifyUserToSentry } from "lib/sentry/identify";
import { formatISO8601DateString } from "lib/timeFormats";

import {
  getActiveRole,
  getBusinesses,
  getConcurrentUserBlock,
  getCurrentSale,
  getCurrentUser,
  getGeoBlock,
  getIsBusinessUser,
  getIsFetchingBusinesses,
  getIsOnline,
  getIsSaleWatcher,
  getOnlineStatus,
  getRoleBySlug,
  getSaleById,
  getSales,
  getSetting,
} from "selectors";

import history from "appHistory";
import { api, refreshJWTAndRetry } from "sagas/api";

function* setExternalServicesUser(userRole = null) {
  const state = yield select();

  const activeRole =
    userRole === null
      ? getActiveRole(state)
      : // if the caller has provided a userRole, use that
        userRole;
  const currentUser = getCurrentUser(state);
  // If we're still loading the current user, there is absolutely nothing to do here.
  if (!currentUser || !currentUser.user_id) {
    return;
  }
  if (isLogRocketActive) {
    identifyUserToLogRocket(currentUser);
  }

  // If we're still loading the active role, there is very little to do here
  if (!activeRole || isEmpty(activeRole) || activeRole.syncing) {
    return;
  }

  if (isSentryActive) {
    identifyUserToSentry(currentUser, activeRole);
  }
  if (isHelpHeroActive) {
    identifyUserToHelpHero(currentUser, activeRole);
  }
}

function* logoutUser() {
  try {
    // Stop all requests happening, get the auth details we have (specifically the jwt, so we're authenticated
    // when we call logout), then, clear it locally, so it's "logged out" when offline.
    // If we use the sucess/fail as a flag for being logged out, we end up waiting for the request.
    window.stop();
    const {
      auth: { authentication },
    } = yield select();
    yield put(clearLocalState());
    yield api.destroy("/v2/auth/session/", authentication);
    yield put(logoutSuccess());
    yield put(resetOfflineState());
  } catch (e) {
    yield put(logoutError(e.message));
  }
}

function* requestLogin(action) {
  try {
    const { creds, returnTo } = action;
    const { email, password } = creds;
    const options = {
      body: JSON.stringify({ email, password, hide_token: true }),
      method: "POST",
    };

    const response = yield prepareFetch("/v2/auth/login/", options);
    const responsePayload = yield responseToJson(response);

    const { token, refresh_token } = responsePayload;

    // Store the tokens.
    yield put(loginSuccess(token, refresh_token));

    // If the login specified a returnTo use that.
    // Be mindful that we don't want to send users to a place that requires prefilled data, as we should
    // have a pretty much empty state at this point
    if (returnTo) {
      history.push(returnTo);
    }
  } catch (e) {
    if (e.code === 400) {
      yield put(
        loginError({
          message: "Incorrect email or password",
          showWifiIcon: false,
        }),
      );
    } else {
      yield put(
        loginError({
          message: "Could not login - check connection",
          showWifiIcon: true,
        }),
      );
    }
  }
}

export function* checkUserAuth(action) {
  const { errorUrl } = action;
  const checkJWTUrl = "/v2/auth/check-jwt/";
  try {
    const options = {
      method: "GET",
    };
    const response = yield prepareFetch(checkJWTUrl, options);
    const responsePayload = yield responseToJson(response);

    const { token, refresh_token } = responsePayload;

    // Store the tokens.
    yield put(checkUserAuthSuccess(token, refresh_token));
  } catch (e) {
    if (e.code === 401) {
      // Redirect to /acccounts/login/ or similar URL to start login flow again
      const url = errorUrl || "/accounts/login/";
      history.push(url);
    } else {
      yield put(
        checkUserAuthError({
          message: "Unable to automatically login using current JWT tokens.",
        }),
      );
    }
  }
}

export function* checkWatcher(action) {
  const { livestocksale_id, verification, email } = action;

  // TODO - high level validation on the livestocksale_id / verification to make sure
  // it's worth submitting.
  const body = { email };
  try {
    // If we're submitting an email, use POST, otherwise it's a GET.
    const method = email ? api.post : api.get;
    const promise = yield call(
      method,
      `/v2/auth/watcher/${livestocksale_id}/${verification}/`,
      body,
    );
    const { status, auth_data, livestock_sale } = yield promise;

    yield put(receiveWatcherEntry(status, auth_data, livestock_sale));

    if (status === WATCHER_STATES.JWT_REFRESH_REQUIRED) {
      yield call(refreshJWTAndRetry, action);
    } else if (status === WATCHER_STATES.SEND_TO_SALE) {
      // If we got back updated user data, handle that like a login.
      let user_data;
      if (auth_data) {
        const { user, token, refresh_token } = auth_data;
        yield put(loginSuccess(user, token, refresh_token));
        user_data = user;
      } else {
        // Otherwise, the current state we have is deemed up to date, use that.
        user_data = (yield select()).auth;
      }
      if (
        user_data.activeRole &&
        user_data.activeRole.type === userTypes.SALEYARD_ADMIN
      ) {
        history.push(getWatchRoute(livestock_sale));
      } else {
        yield put(setActiveRole(getWatcherRole(user_data).slug));
        history.push(getWatchRoute(livestock_sale));
      }
    }
    // Other actions occur in the view.
  } catch (e) {
    // The request failed for (reasons)
    if (e.status === 404) {
      // Likely an invalid formatted verification or sale id.
      yield put(receiveWatcherEntry(WATCHER_STATES.SALE_NOT_FOUND));
    } else if (e.status === 401) {
      // If the JWT is totally broke, we may get a 401.
      yield call(refreshJWTAndRetry, action);
    } else if (e.status === 400) {
      // If the verification failed, let the user know it was a bad link.
      yield put(
        receiveWatcherEntry(
          WATCHER_STATES.ERROR,
          undefined,
          undefined,
          "Invalid link - please check the source and try again.",
        ),
      );
    } else {
      // Other failures ... try again later?
      yield put(
        receiveWatcherEntry(
          WATCHER_STATES.ERROR,
          undefined,
          undefined,
          "Error connecting you to the sale.  Please try again later.",
        ),
      );
    }
  }
}

export function* nlisSignUp(action) {
  const { creds, options = null } = action;
  const {
    nlis_user,
    nlis_password,
    nlis_email,
    nlis_saleyard_id,
    credentialType,
  } = creds;

  const credentialTypeEndpoint =
    credentialType === NLISCredentialType.AGENT ? "agent" : "saleyard";
  const url = `/v2/user/nlis/${credentialTypeEndpoint}/`;
  const body = {
    nlis_user,
    nlis_password,
    nlis_email,
    nlis_saleyard_id,
  };
  const { onSuccess, onError } = {
    ...options,
  };
  try {
    const nlisPromise = yield api.post(url, removeBlankString(body));
    const signUpResult = yield nlisPromise;

    typeof onSuccess === "function" && onSuccess(signUpResult);
    yield put(receiveNLISSignUp(signUpResult, credentialType, options));
  } catch (e) {
    try {
      typeof onError === "function" && onError(e);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("There was an error", e);
    }
    yield put(nlisSignUpError(e, options));
  }
}

function* fetchDefaultRoundsAndSaleTypes(action) {
  try {
    const [defaultRounds, saleTypes] = yield all([
      yield call(api.get, "/v2/defaultrounds/"),
      yield call(api.get, "/v2/saletypes/"),
    ]);
    yield put(receiveSaleDefinitionsSuccess({ saleTypes, defaultRounds }));
  } catch (e) {
    yield call(api.handleFetchError, e, "sale and default round data", action);
  }
}

const getUserLocation = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      location => resolve(location),
      error => reject(error),
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );
  });

function* handleFetchGeoData() {
  try {
    const location = yield call(getUserLocation);
    yield put(
      storeGeoData({
        timestamp: location.timestamp,
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        accuracy: location.coords.accuracy,
        error: null,
      }),
    );
  } catch (e) {
    yield put(storeGeoData({ error: e.message }));
  }
}

function* clearBlocks() {
  // If we had a successful API response, we must be inside geo fences.
  const state = yield select();

  if (getGeoBlock(state)) {
    // Perhaps toast here to let you know you're in the right area?
    yield put(setGeoBlock(null));
  }

  if (getConcurrentUserBlock(state)) {
    yield put(setConcurrentUserBlock(null));
  }
}

function* loadSale(sale) {
  // Open the sale if we have a different sale open.
  const state = yield select();
  const currentSale = getCurrentSale(state);
  // Dont redirect if we are on pages that are not sale based.  Eg. Contacts/Settings.
  // The /saleyard path is an exception to this.  When logged in as a user that has many roles,
  // the role picker is rendered at /saleyard; when the user changes role in this instance we
  // DO want to redirect to the sale.
  const allowRedirect = !["settings", "businesses"].some(page =>
    window.location.href.endsWith(page),
  );

  if (
    allowRedirect &&
    sale.livestocksale_id !== currentSale?.livestocksale_id
  ) {
    history.push(
      getSaleyardAuctionRoute(sale.saleyard_name, sale.livestocksale_id),
    );
  }
}

function* maybeLoadSale(action) {
  /*
    This saga should run after receiving a list of sales (we can only make a decision after we have the 
      list of sales) and is used to either
    - Load the sticky sale if one is set that the current role has access to.
    - If there is no sticky sale, load today's sale if there is one.
    - Or send them to the saleyard home screen and DON'T load a sale.

    We need to ignore any GET sales responses that are cache hits because it's possible that
    there is a sale that will come in the changes since that is our sticky sale or today's sale.

    We also need to ignore any GET sales request that originated from a periodic fetch (ie pusher or heartbeat),
    we don't want to change sale or refresh data again because the user would more than likely be in the
    middle of something.

    Also - only fire from a URL where it's useful to be pushed away from - already in the sale, or in a settings/similar
    page is just intrusive!

    TLDR: On refreshing the page or changing roles, guess what sale the user wants to see and load it.

  */

  // For a cached response, not much to do.
  if (action?.meta?.cacheHit) {
    return;
  }

  // If the action explicitly forbids changing sales (specifically related to changes since, but may have others)
  if (action?.meta?.dontChangeSale) {
    return;
  }

  const state = yield select();

  const allSalesLoaded = getSetting(Settings.allSalesLoaded)(state);

  // If not fetching all sales and all sales are set to loaded, set it false
  if (action?.meta?.fetchAll || action?.meta?.options?.fetchAll) {
    // it's the get more sale button.
    yield put(setSetting(Settings.allSalesLoaded, true));
    return;
  } else if (allSalesLoaded) {
    yield put(setSetting(Settings.allSalesLoaded, false));
  }

  const {
    location: { pathname },
  } = history;

  // Only actually fire if the user is at:
  // `/app/` (the role picker)
  // `/saleyard/.*` (in a sale)
  if (pathname !== "/app/" && !pathname.startsWith("/saleyard/")) {
    return;
  }

  const sales = getSales(state);
  const stickySaleSetting = getSetting(Settings.stickySale)(state);

  if (stickySaleSetting) {
    const stickySale = Object.values(sales).find(
      sale => sale.livestocksale_id === stickySaleSetting.livestockSaleId,
    );
    // If there is a sticky sale set and I dont have access to it, clear it.
    if (!stickySale) {
      yield put(setSetting(Settings.stickySale, null));
    } else if (getLivestockSaleId() === stickySale.livestocksale_id) {
      // don't redirect if already on a page within the sticky sale
      return;
    } else {
      // Load the sticky sale!
      history.push(
        `${getSaleyardAuctionRoute(
          stickySale.saleyard_name,
          stickySale.livestocksale_id,
        )}`,
      );

      return;
    }
  }
  // If we dont have a sticky sale set that we have access to, load an appropriate sale.
  const today = formatISO8601DateString(new Date());
  const salesToday = Object.values(sales).filter(sale => sale.date === today);
  if (salesToday.length === 1) {
    // If there is a sale today, send them to the sale
    // which will handle the refreshing of the rest of the context data.
    yield loadSale(salesToday[0]);
  } else {
    // If they don't have sales today (or multiple), send them to the dashboard!
    history.push(getDashboardRoute());
  }
}

function* handleSetNewRole(action) {
  const { role } = action;
  const state = yield select();

  // Flag whether or not the role has changed, or been enhanced as this drives fetching role based data.
  const activeRole = getActiveRole(state);
  const hasRoleChanged = role.slug !== activeRole.slug;

  const { changesOutboxSize } = getOnlineStatus(state);
  if (changesOutboxSize === 0) {
    yield put({ type: SET_ACTIVE_ROLE.REQUEST, role, hasRoleChanged });
  } else {
    yield put({
      type: SET_ACTIVE_ROLE.FAILURE,
      reason: "You have pending changes. Please wait before switching roles",
    });
  }
}

function* onSetCurrentSaleAction({
  livestockSaleId,
  shouldNavigate,
  requestLivestockSaleData,
}) {
  // Make sure the user is online before changing sales.

  const state = yield select();
  const isOnline = getIsOnline(state);

  if (!livestockSaleId) {
    // If there is no livestock sale Id, its implied that we are clearing the current livestock sale.
    yield put({ type: SET_CURRENT_LIVESTOCKSALE.SUCCESS, livestockSaleId });
  } else if (isOnline) {
    yield put({
      type: SET_CURRENT_LIVESTOCKSALE.REQUEST,
      livestockSaleId,
      shouldNavigate,
      requestLivestockSaleData,
    });
  } else {
    yield put({
      type: SET_CURRENT_LIVESTOCKSALE.FAILURE,
      reason: "You must be online to change Sales.",
    });
  }
}
function* onSetCurrentSaleRequest({
  livestockSaleId,
  shouldNavigate,
  requestLivestockSaleData,
}) {
  const state = yield select();

  const livestockSale = getSaleById(livestockSaleId)(state);
  if (livestockSale) {
    const { saleyard_name: saleyardName } = livestockSale;

    const isSaleWatcher = getIsSaleWatcher(state);
    const noBusinesses = Object.keys(getBusinesses(state)).length === 0;
    const isBusinessUser = getIsBusinessUser(state);
    const isFetchingBusinesses = getIsFetchingBusinesses(state);
    if (isSaleWatcher || isBusinessUser) {
      const stickySaleSetting = getSetting(Settings.stickySale)(state);
      const stickySale =
        getSaleById(stickySaleSetting?.livestockSaleId)(state) || {};
      // If the saleyard for the new sale is different than the previous one and our active role fetches data based on
      // the saleyard, do that here.
      if (
        (stickySale.saleyard_id !== livestockSale.saleyard_id || // Also fire the request off if there are no businesses...
          noBusinesses) &&
        !isFetchingBusinesses
      ) {
        yield put(BusinessAction.request({ saleyardNameFilter: saleyardName }));
      }
    }

    if (shouldNavigate && getLivestockSaleId() !== livestockSaleId) {
      history.push(`${getSaleyardAuctionRoute(saleyardName, livestockSaleId)}`);
    }

    yield put(
      setSetting(Settings.stickySale, {
        livestockSaleId,
        stickySetAt: formatISO8601DateString(new Date()),
      }),
    );

    yield put({
      type: SET_CURRENT_LIVESTOCKSALE.SUCCESS,
      livestockSaleId,
      requestLivestockSaleData,
    });
  } else {
    yield put({
      type: SET_CURRENT_LIVESTOCKSALE.FAILURE,
      reason: "Could not find sale.",
    });
  }
}

function* onSetActiveRoleRequest(action) {
  const { hasRoleChanged } = action;
  const state = yield select();
  const userRole = getActiveRole(state);
  // Connect up our external services (LogRocket, DataDog, HelpHero, Sentry)
  if (userRole.syncing) {
    // SET_ACTIVE_ROLE.ACTION is called when the user role is "backfilled"
    const { userRole } = yield take(SET_ACTIVE_ROLE.ACTION);
    yield setExternalServicesUser(userRole);
  } else {
    yield setExternalServicesUser();
  }
  yield put({ type: SET_ACTIVE_ROLE.SUCCESS, hasRoleChanged });
}

function* onRestoreSession() {
  const state = yield select();
  const activeRole = getActiveRole(state);
  const currentUser = getCurrentUser(state);
  if (!isEmpty(currentUser) && !isEmpty(activeRole)) {
    // Identify the user to our external services when restoring the session with a user are role,
    yield setExternalServicesUser();
  }
  if (!activeRole?.syncing) {
    yield put({ type: SET_ACTIVE_ROLE.RESET, hasRoleChanged: true });
  }
}

function doSendToUserOverview() {
  history.push(getUserOverviewRoute());
}

function* onLoginOrAuthSuccess() {
  // Kick off getting the roles available
  // TOOD - maybe we handle the redirect to next page on current user, instead of login...
  yield put(CurrentUserAction.request({ selectOnComplete: true }));
  yield put(SaleyardAdminRoleAction.request());
  yield put(LivestockAgentRoleAction.request());
  yield put(SaleWatcherRoleAction.request());
  yield put(ScaleOperatorRoleAction.request());
  yield put(BusinessUserRoleAction.request());
}

function* onCurrentUserSuccess(action) {
  // Run when we get the single role.

  if (action.meta.options.selectOnComplete) {
    // If we know all the details of the business, pop them in there, otherwise just the slug, and a `syncing` flag
    // so we know we're not yet a fully fledged dataset.
    const state = yield select();
    const role = getRoleBySlug(action.payload.default_role_slug)(state) || {
      slug: action.payload.default_role_slug,
      syncing: true,
    };
    yield put(setActiveRole(role));
  }
  // If we've just got the user, we may have more info to share with logrocket et al.
  yield setExternalServicesUser();
}

function* backFillActiveRole() {
  // If we receive details about roles, see if we have a half-filled active role (ie, with just the slug) go and
  // set the full details.
  const state = yield select();
  if (!state.auth.activeRole.id) {
    const role = getRoleBySlug(state.auth.activeRole.slug)(state);
    if (role) {
      // Set it, but don't send to overview or anything - we might already be on the way.
      yield put(setActiveRole(role, false));
    }
  }
}

function* onNlisSignupSuccess() {
  // A bit broken, but until we action AP-702 is actioned and nlis credentials are reflected
  // on a deployment/saleyard, this is a bit of a chip in to make it work.
  // The reducers take care of updating an NLIS credential on the active user, but the library of other
  // users that use the same credential will all need updating, including ones that are NOT the active user,
  // so let's just re-fetch all users of the same type as the active user.
  const state = yield select();
  const activeUser = getActiveRole(state);
  if (activeUser?.slug) {
    const reloadAction = getReloadActionFromSlug(activeUser.slug);
    if (reloadAction) {
      yield put(reloadAction());
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(CHECK_USER_AUTH.REQUEST, checkUserAuth);
  yield takeLatest(LOGIN_REQUEST, requestLogin);
  yield takeLatest(LOGOUT_REQUEST, logoutUser);
  yield takeLatest(
    [CHECK_USER_AUTH.SUCCESS, LOGIN_SUCCESS],
    onLoginOrAuthSuccess,
  );
  yield takeLatest(CURRENT_USER.FETCH_BULK.SUCCESS, onCurrentUserSuccess);
  yield takeLatest(
    [
      USER_ROLES.SALEYARD_ADMIN.FETCH_BULK.SUCCESS,
      USER_ROLES.LIVESTOCK_AGENT.FETCH_BULK.SUCCESS,
      USER_ROLES.SCALE_OPERATOR.FETCH_BULK.SUCCESS,
      USER_ROLES.BUSINESS_USER.FETCH_BULK.SUCCESS,
      USER_ROLES.SALE_WATCHER.FETCH_BULK.SUCCESS,
    ],
    backFillActiveRole,
  );

  yield takeLatest(NLIS_SIGN_UP_REQUEST, nlisSignUp);
  yield takeLatest(NLIS_SIGN_UP_SUCCESS, onNlisSignupSuccess);
  yield takeLatest(FETCH_GEO_DATA, handleFetchGeoData);
  yield takeLatest(API_RESPONSE.FETCH.SUCCESS, clearBlocks);

  yield takeLatest(SET_ACTIVE_ROLE.ACTION, handleSetNewRole);
  yield takeLatest(SET_CURRENT_LIVESTOCKSALE.ACTION, onSetCurrentSaleAction);
  yield takeLatest(SET_CURRENT_LIVESTOCKSALE.REQUEST, onSetCurrentSaleRequest);
  yield takeLatest(SET_ACTIVE_ROLE.REQUEST, onSetActiveRoleRequest);
  yield takeLatest(RESTORE_SESSION, onRestoreSession);
  yield takeLatest(SALE.FETCH_BULK.SUCCESS, maybeLoadSale);
  yield takeLatest(SALE.FETCH_CHANGES.SUCCESS, maybeLoadSale);
  yield takeLatest(SEND_TO_USER_OVERVIEW, doSendToUserOverview);
  yield takeLatest(
    GET_DEFAULT_ROUNDS_AND_SALE_TYPES,
    fetchDefaultRoundsAndSaleTypes,
  );

  yield takeLatest(REQUEST_WATCHER_ENTRY, checkWatcher);
}
