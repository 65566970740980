// should return a property object
export const getDefaultProperty = (business, saleyardId) => {
  /* Find a default property for a given saleyard.
  Generally this should only be used when within a sale context, and we have an explicit saleyard.
  Outside of this context (eg, edit business) we need to be aware there are potentially many saleyards at play. 
   */
  let result = null;
  if (business && business.properties) {
    if (business.properties.length === 1) {
      result = business.properties[0];
    } else if (business.defaultDestinations) {
      const default_destination_for_saleyard =
        business.defaultDestinations.find(
          destination => destination.saleyardId === saleyardId,
        );
      if (default_destination_for_saleyard) {
        result = business.properties.find(
          property =>
            property.id === default_destination_for_saleyard.propertyId,
        );
      }
    }
  }
  return result;
};

const NLIS_LPA_PROGRAM_CODE = "LPA";
export const getLPAStatusFromPrograms = programs =>
  programs.find(program => program.code === NLIS_LPA_PROGRAM_CODE)?.status ||
  "";

export const isLpaAccredited = lpaCode => ["A", "A1"].includes(lpaCode);
