import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import { updateModalContext } from "actions";

import { useModalAdapter } from "hooks";

function DispatchingHashModalAdapterComponent(props) {
  const { modalComponent: ModalComponent, modalType } = props;

  const [hashParams, _ignored, onClose] = useModalAdapter(modalType, props);
  const dispatch = useDispatch();

  useEffect(() => {
    // When the URL changes mark the supplied modal as open, and push the hash params to state.
    dispatch(updateModalContext(modalType, { isOpen: true, ...hashParams }));
  }, [dispatch, hashParams, modalType]);
  useEffect(() => {
    return () => {
      // When the modal is closed dispatch an action to state that this type is closed.
      dispatch(updateModalContext(modalType, { isOpen: false }));
    };
  }, [dispatch, modalType]);

  return <ModalComponent onClose={onClose} />;
}

export default DispatchingHashModalAdapterComponent;
