import React, { useEffect } from "react";

import { Divider, Grid, ListItem, ListSubheader } from "@material-ui/core";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import { isEmpty, orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import { BillingRunAction } from "actions";

import { SaleExport } from "components/BillingWorkFlow/SaleExport";
import { Warnings } from "components/BillingWorkFlow/Warnings";
import { EditButton, SlimButton } from "components/Button";
import { Warning } from "components/ErrorMessage";
import { Button, FormSectionGrid } from "components/Form";
import { Column, Row } from "components/Layout";
import MessageBox from "components/MessageBox";
import { Paper } from "components/Paper";

import { ModalTypes } from "constants/navigation";

import { ForStaff } from "containers/ForUserType";

import {
  getLivestockSaleId,
  openLedgerEntryDetail,
  openModalLink,
  openSaleModal,
} from "lib/navigation";
import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

import {
  getActiveBillingRunId,
  getBillingRuns,
  getCurrentDeploymentSalesList,
  getIsFetchingAnyBilling,
} from "selectors";

import { getCurrentDefaultTradingTerms } from "selectors/tradingTerms";

const Table = styled.table`
  border-collapse: collapse;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;

  tr td {
    padding: 8px;
  }
`;

const StyledList = styled(List)`
  overflow-y: scroll;
`;

const BillingRunSummary = ({ billingRun, editBillingRun }) => {
  // Get active terms from the sale.
  const deploymentSale = useSelector(getCurrentDeploymentSalesList)?.[0];

  const { buyer_terms: buyerTerms, vendor_terms: vendorTerms } = deploymentSale;

  // default terms for the sale
  const { buyerTerms: defaultBuyerTerms, vendorTerms: defaultVendorTerms } =
    useSelector(getCurrentDefaultTradingTerms) || {};

  const defaultTermsOverridden =
    (defaultBuyerTerms && buyerTerms !== defaultBuyerTerms) ||
    (defaultVendorTerms && vendorTerms !== defaultVendorTerms);

  const openEditRuleBook = () =>
    openModalLink(ModalTypes.RuleBook, {
      id: billingRun.ruleBookId,
    });

  return (
    <Paper>
      <Row justifyBetween justifyCenter alignCenter>
        <Typography variant="h6" component="h1">
          {billingRun.name}
        </Typography>
        <EditButton onClick={editBillingRun} />
      </Row>
      <Table>
        <tbody>
          <tr>
            <td>Status:</td>
            <td>{billingRun.status}</td>
          </tr>
          <tr>
            <td>Last Run:</td>
            <td>
              {formatUTCToLocalDateTimeString(
                billingRun.ledgerEntriesGeneratedDateTime,
              )}
            </td>
          </tr>
          <tr>
            <td>Exported At:</td>
            <td>
              {billingRun.exportedAtDateTime
                ? formatUTCToLocalDateTimeString(billingRun.exportedAtDateTime)
                : "-"}
            </td>
          </tr>
          <tr>
            <td>Comments:</td>
            <td>{billingRun.comments}</td>
          </tr>
          <tr>
            <td>
              <b>Trading Terms</b>
            </td>
            <td>
              <SlimButton onClick={() => openSaleModal(getLivestockSaleId())}>
                Edit
              </SlimButton>
            </td>
          </tr>
          <tr>
            <td>Vendor Terms:</td>
            <td>{vendorTerms}</td>
          </tr>
          <tr>
            <td>Buyer Terms:</td>
            <td>{buyerTerms} </td>
          </tr>
          {defaultTermsOverridden && (
            <tr>
              <td colSpan={2}>
                <Warning>
                  The default terms for this sale are different than the terms
                  used. (Vendor: {defaultVendorTerms}, Buyer:{" "}
                  {defaultBuyerTerms})
                </Warning>
              </td>
            </tr>
          )}
          <tr>
            <td>
              <Button onClick={openLedgerEntryDetail}>
                Ledger Entry Detail
              </Button>
              <Button onClick={openEditRuleBook}>Edit Rule Book</Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <Warnings />
    </Paper>
  );
};

export const Overview = React.memo(() => {
  const billingRuns = useSelector(getBillingRuns);
  const location = useLocation();

  const activeBillingRunId = useSelector(getActiveBillingRunId);

  const billingRun = billingRuns[activeBillingRunId];
  const dispatch = useDispatch();

  useEffect(() => {
    // scroll to top whenever the active billing run changes!
    document.getElementById("main-wrapper").scroll(0, 0);
  }, [activeBillingRunId]);

  const selectBillingRunId = billingRunId => {
    if (isEmpty(billingRunId)) {
      dispatch(BillingRunAction.unsubscribe());
    } else {
      dispatch(BillingRunAction.subscribe(billingRunId));
    }
  };

  const openBillingRunForm = billingRunId => {
    openModalLink(
      ModalTypes.EditBillingRun,
      {
        billingRunId,
      },
      location.pathname + location.search,
    );
  };

  const editBillingRun = () => {
    openBillingRunForm(activeBillingRunId);
  };
  const createBillingRun = () => openBillingRunForm();

  const isFetching = useSelector(getIsFetchingAnyBilling);

  if (isEmpty(billingRuns)) {
    return (
      <Paper>
        <MessageBox>
          <Column>
            No Billing Runs have been created for this sale yet.
            <Button onClick={createBillingRun}>Create one now</Button>
          </Column>
        </MessageBox>
      </Paper>
    );
  }

  return (
    <FormSectionGrid container spacing={2}>
      <Grid item xs={4}>
        <Paper>
          <StyledList subheader={<ListSubheader>Billing Runs</ListSubheader>}>
            {orderBy(
              Object.values(billingRuns),
              "ledgerEntriesGeneratedDateTime",
              "desc",
            ).map((br, index) => (
              <React.Fragment key={br.id}>
                <ListItem
                  data-tour={`billingRun${index}`}
                  selected={br.id === activeBillingRunId}
                  disabled={isFetching}
                  onClick={() => !isFetching && selectBillingRunId(br.id)}
                  button
                >
                  <Column>
                    <div className="font-normal">{br.name}</div>
                    <div className="font-light">
                      Last run:{" "}
                      {br.ledgerEntriesGeneratedDateTime &&
                        formatUTCToLocalDateTimeString(
                          br.ledgerEntriesGeneratedDateTime,
                        )}
                    </div>
                  </Column>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
            <ListItem>
              <Button onClick={createBillingRun}>Create New</Button>
            </ListItem>
          </StyledList>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        {billingRun ? (
          <BillingRunSummary
            billingRun={billingRun}
            editBillingRun={() => editBillingRun(activeBillingRunId)}
          />
        ) : (
          <Paper>
            <MessageBox>No Billing Run Selected</MessageBox>
            <Button onClick={createBillingRun}>Create</Button>
          </Paper>
        )}
        <ForStaff>
          <SaleExport />
        </ForStaff>
      </Grid>
    </FormSectionGrid>
  );
});
