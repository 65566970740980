import React, { memo, useCallback, useRef } from "react";

import { Grid, TextField } from "@material-ui/core";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import { getIn, setIn, useFormikContext } from "formik";
import PropTypes from "prop-types";

import {
  Autocomplete,
  Label,
  withNamespace,
} from "components/Form/FormikControls";
import { DataTour } from "components/HelpHeroDataTour";
import { Column } from "components/Layout";

import { getAuctionsPlusItemTypeByCategory } from "constants/auctionsPlusSchema";

import { caseInsensitiveCompare } from "lib/compare";

function stringToOption(value) {
  return {
    label: value,
    value,
  };
}

function calculateCategoryOptions() {
  const categoryNames = Object.keys(getAuctionsPlusItemTypeByCategory()).sort();
  const categoryOptions = categoryNames.map(stringToOption);

  // generate the options for `Type`
  const itemTypeOptions = categoryNames.reduce((acc, categoryName) => {
    const itemTypes = getAuctionsPlusItemTypeByCategory()[categoryName];
    const itemTypeNames = Object.keys(itemTypes).sort();
    acc[categoryName] = itemTypeNames.map(stringToOption);
    return acc;
  }, {});

  // generate the options for `Sub Type`
  const subTypeOptions = categoryNames.reduce((acc, categoryName) => {
    const itemTypes = getAuctionsPlusItemTypeByCategory()[categoryName];
    const itemTypeNames = Object.keys(itemTypes).sort();

    itemTypeNames.forEach(itemTypeName => {
      const subTypes = itemTypes[itemTypeName];
      acc[`${categoryName}_${itemTypeName}`] = subTypes.map(stringToOption);
    });
    return acc;
  }, {});

  return {
    categoryOptions,
    itemTypeOptions,
    subTypeOptions,
  };
}

function calculateAllCategoryItemsOptions() {
  return (
    Object.entries(getAuctionsPlusItemTypeByCategory())
      .reduce(
        (acc, [categoryName, categoryItems]) =>
          acc.concat(
            // Add the Category itself as an option
            [
              {
                label: `${categoryName}`,
                value: {
                  category: categoryName,
                  type: null,
                  subType: null,
                },
              },
            ],
            // process the nested Item Types
            Object.entries(categoryItems).reduce(
              (acc, [typeName, subTypes]) =>
                acc.concat(
                  // Add the Item Type Name itself as an option
                  [
                    {
                      label: `${categoryName} > ${typeName}`,
                      value: {
                        category: categoryName,
                        type: typeName,
                        subType: null,
                      },
                    },
                  ],
                  // Add each of the Sub Type Names as options
                  subTypes.map(subTypeName => ({
                    label: `${categoryName} > ${typeName} > ${subTypeName}`,
                    value: {
                      category: categoryName,
                      type: typeName,
                      subType: subTypeName,
                    },
                  })),
                ),
              [],
            ),
          ),
        [],
      )
      // sort all of the options alphabetically by their labels
      .sort((a, b) => caseInsensitiveCompare(a.label, b.label))
  );
}
const auctionsPlusDescriptionSchema = calculateCategoryOptions();
const auctionsPlusAllDescriptionsListOptions =
  calculateAllCategoryItemsOptions();

const EMPTY_OPTIONS = [];

function getSubTypeOptions(category, itemType) {
  return (
    auctionsPlusDescriptionSchema.subTypeOptions[`${category}_${itemType}`] ||
    EMPTY_OPTIONS
  );
}

function AuctionsPlusCategorySearch(props) {
  const { onSelectCategoryValues } = props;

  function onChange(e, selectedItem) {
    selectedItem &&
      typeof onSelectCategoryValues === "function" &&
      onSelectCategoryValues(selectedItem.value);
  }
  const inputRef = useRef(null);

  return (
    <Column fullWidth>
      <DataTour dataTour="findACategoryType">
        <Label
          htmlFor="auctionsPlusCategorySearch"
          tooltip="Search for an AuctionsPlus Listing Category to automatically fill the description"
        >
          Find a Category/Type
        </Label>

        <MuiAutocomplete
          onChange={onChange}
          options={auctionsPlusAllDescriptionsListOptions}
          getOptionLabel={option => option.label}
          value={null}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Select..."
              inputRef={inputRef}
            />
          )}
        />
      </DataTour>
    </Column>
  );
}

const AuctionsPlusCategoryField = props => {
  const { name, namespace: ns, onAfterChange } = props;
  return (
    <Autocomplete
      label="Category"
      name={withNamespace(ns, name)}
      options={auctionsPlusDescriptionSchema.categoryOptions}
      onChangeExtra={onAfterChange}
      filterOptions={undefined}
      required
      getOptionValue={option => option?.value}
      placeholder="Select..."
    />
  );
};

const AuctionsPlusItemTypeField = props => {
  const { category, name, namespace: ns, onAfterChange } = props;
  return (
    <Autocomplete
      label="Type"
      name={withNamespace(ns, name)}
      options={
        auctionsPlusDescriptionSchema.itemTypeOptions[category] || EMPTY_OPTIONS
      }
      onChangeExtra={onAfterChange}
      required
      filterOptions={undefined}
      getOptionValue={option => option?.value}
      placeholder="Select..."
    />
  );
};

const AuctionsPlusSubTypeField = props => {
  const { category, itemType, name, namespace: ns, onAfterChange } = props;
  return (
    <Autocomplete
      label="Sub Type"
      name={withNamespace(ns, name)}
      options={getSubTypeOptions(category, itemType)}
      onChangeExtra={onAfterChange}
      required
      filterOptions={undefined}
      getOptionValue={option => option?.value}
      placeholder="Select..."
    />
  );
};

function AuctionsPlusClearingSaleDescriptionFormComponent(props) {
  const { namespace: ns = "" } = props;

  const { setValues, values } = useFormikContext();

  // store the values as a ref so as not to invalidate the callbacks on every render,
  // whilst also providing access to the latest values
  const formikValuesRef = useRef();
  formikValuesRef.current = values;

  /**
   * Update all of the values from the selected item in the search
   */
  const onSelectCategoryValues = useCallback(
    ({ category, type, subType }) => {
      let nextValues = { ...formikValuesRef.current };

      // clear the `category` field
      nextValues = setIn(nextValues, withNamespace(ns, "category"), category);

      // clear the `itemType` field
      nextValues = setIn(nextValues, withNamespace(ns, "itemType"), type);

      // clear the `subType` field
      nextValues = setIn(nextValues, withNamespace(ns, "subType"), subType);

      // update the formik context with the new values
      setValues(nextValues);
    },
    [formikValuesRef, ns, setValues],
  );

  /**
   * Clear the itemType and the subType when selecting a new category
   */
  const onCategoryChanged = useCallback(() => {
    let nextValues = { ...formikValuesRef.current };
    // clear the `itemType` field
    nextValues = setIn(nextValues, withNamespace(ns, "itemType"), null);

    // clear the `subType` field
    nextValues = setIn(nextValues, withNamespace(ns, "subType"), null);

    // update the formik context with the new values
    setValues(nextValues);
  }, [formikValuesRef, ns, setValues]);

  /**
   * Clear the subType when selecting a new itemType
   */
  const onTypeChanged = useCallback(() => {
    let nextValues = { ...formikValuesRef.current };

    // clear the `subType` field
    nextValues = setIn(nextValues, withNamespace(ns, "subType"), null);

    // update the formik context with the new values
    setValues(nextValues);
  }, [formikValuesRef, ns, setValues]);

  // extract the category and itemType from the values, used for passing into the
  // `AuctionsPlusItemTypeField` and `AuctionsPlusSubTypeField` components so
  // that they can filter the available options
  const category = getIn(values, withNamespace(ns, "category"));
  const itemType = getIn(values, withNamespace(ns, "itemType"));

  return (
    <>
      <Grid item xs={12}>
        <AuctionsPlusCategorySearch
          onSelectCategoryValues={onSelectCategoryValues}
        />
      </Grid>
      <Grid item xs={12}>
        <AuctionsPlusCategoryField
          namespace={ns}
          name="category"
          onAfterChange={onCategoryChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <AuctionsPlusItemTypeField
          category={category}
          namespace={ns}
          name="itemType"
          onAfterChange={onTypeChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <AuctionsPlusSubTypeField
          category={category}
          itemType={itemType}
          namespace={ns}
          name="subType"
        />
      </Grid>
    </>
  );
}

AuctionsPlusClearingSaleDescriptionFormComponent.propTypes = {
  namespace: PropTypes.string,
};

export default memo(AuctionsPlusClearingSaleDescriptionFormComponent);
