import { offlineActionCreator, urlCheckAndAddIdAndAction } from "actions/lib";

import {
  ADD_SALE,
  ADD_SALE_FAILURE,
  ADD_SALE_SUCCESS,
  DELETE_CURRENT_SALE,
  DELETE_SALE_FAILURE,
  DELETE_SALE_SUCCESS,
  DEPLOYMENT_SALE,
  GET_CONSIGNABLE_SALES,
  GET_DASHBOARD_DATA,
  GET_SALE_DEFINITIONS,
  SET_CURRENT_LIVESTOCKSALE,
  UPDATE_SALE_AND_DEPLOYMENT_SALES,
} from "constants/actionTypes";

import { getLivestockSaleId } from "lib/navigation";
import { serializeLivestockSale } from "lib/sale";

export const setCurrentLivestockSaleId = (
  livestockSaleId,
  shouldNavigate,
  requestLivestockSaleData = true,
) => ({
  type: SET_CURRENT_LIVESTOCKSALE.ACTION,
  livestockSaleId,
  shouldNavigate,
  requestLivestockSaleData,
});

export function requestConsignableSales() {
  return {
    type: GET_CONSIGNABLE_SALES.REQUEST,
  };
}
export function receiveConsignableSales(sales) {
  return {
    type: GET_CONSIGNABLE_SALES.SUCCESS,
    sales,
  };
}
export function requestConsignableSalesError(error) {
  return {
    type: GET_CONSIGNABLE_SALES.FAILURE,
    error,
  };
}

export function requestSaleDefinitions() {
  return {
    type: GET_SALE_DEFINITIONS,
  };
}

export function addSale(sale, saleYard) {
  return {
    type: ADD_SALE,
    sale: { saleyardName: saleYard?.name, ...sale },
  };
}

export function addSaleSuccess(sale) {
  return {
    type: ADD_SALE_SUCCESS,
    sale,
  };
}

export function addSaleFailure(error) {
  return {
    type: ADD_SALE_FAILURE,
    error,
  };
}

export function deleteCurrentSale() {
  return {
    type: DELETE_CURRENT_SALE,
  };
}

export function deleteSaleSuccess(saleID) {
  return {
    type: DELETE_SALE_SUCCESS,
    saleID,
  };
}

export function deleteSaleFailure(error) {
  return {
    type: DELETE_SALE_FAILURE,
    error,
  };
}

export const requestDashboardData = () => {
  return {
    type: GET_DASHBOARD_DATA.REQUEST,
  };
};

export const receiveDashboardData = dashboard => {
  return {
    type: GET_DASHBOARD_DATA.SUCCESS,
    dashboard,
  };
};

export function updateSaleAndDeploymentSales(
  salePayload,
  livestockSaleId,
  deploymentSales,
) {
  return {
    type: UPDATE_SALE_AND_DEPLOYMENT_SALES,
    livestockSaleId,
    salePayload,
    deploymentSales,
  };
}

const urlCreator = ({ id, action } = {}) =>
  urlCheckAndAddIdAndAction("/v2/sales/", id, action);

export const DeploymentSaleAction = offlineActionCreator(
  DEPLOYMENT_SALE,
  serializeLivestockSale,
  urlCreator,
  true,
);

DeploymentSaleAction.bulkUpdateLotNoToPenNo = function bulkUpdateLotNoToPenNo(
  deploymentSaleId,
) {
  const payload = {
    deployment_sale_id: deploymentSaleId,
  };
  const options = {
    id: getLivestockSaleId(),
    action: "bulk_update_lots_to_pens",
  };
  return {
    type: DEPLOYMENT_SALE.BULK_UPDATE_LOT_NO_TO_PEN_NO.REQUEST,
    meta: {
      offline: {
        effect: {
          url: urlCreator(options),
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: DEPLOYMENT_SALE.BULK_UPDATE_LOT_NO_TO_PEN_NO.SUCCESS,
        },
        rollback: {
          type: DEPLOYMENT_SALE.BULK_UPDATE_LOT_NO_TO_PEN_NO.FAILURE,
        },
      },
    },
  };
};
