import { SelectField } from "components/Form/FormikControls";
import React from "react";
import { EMPTY_ARRAY } from "lib";

interface RuleOutputFormatFieldProps {
  name: string;
  options?: SelectFieldOption[];
}

export function RuleOutputFormatField(
  props: RuleOutputFormatFieldProps,
): React.JSX.Element {
  const { name, options = EMPTY_ARRAY } = props;

  return (
    <SelectField
      name={name}
      required
      options={options}
      menuPortalTarget={document.body}
    />
  );
}
