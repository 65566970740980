import React, { useMemo, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, MenuItem, Popover as MuiPopover } from "@material-ui/core";
import styled from "styled-components/macro";

import { StyledInput as Input } from "components/Form/FormikControls/layout";

const ButtonIcon = styled(FontAwesomeIcon)(
  ({ theme, color }) => `
    color: ${color ? theme.colors[color] : theme.colors.white};
    margin: ${theme.space[0]}px ${theme.space[1]}px ${theme.space[0]}px ${
      theme.space[0]
    }px;
  `,
);

const FilterInput = styled(Input)`
  text-align: center;
  ::placeholder {
    color: ${({ theme }) => theme.colors.primary};
  }
  justify-self: center;
`;

const Popover = styled(({ restrictHeightSubNav: ignored, ...popoverAttrs }) => (
  <MuiPopover {...popoverAttrs} />
))`
  max-height: ${({ restrictHeightSubNav }) =>
    restrictHeightSubNav ? "350px" : "none"};
`;

const Title = styled.span`
  font-weight: ${({ bold }) => (bold ? `bold` : ``)};
`;

export const SubMenuPopover = ({
  subNavItems = [],
  anchorEl,
  includeSearchSubNav = false,
  restrictHeightSubNav = false,
  open,
  onClose,
}) => {
  const [searchText, setSearchText] = React.useState("");

  useEffect(() => setSearchText(""), [open]);

  const filteredSubNavItems = useMemo(
    () =>
      subNavItems.filter(item =>
        item.title.toLowerCase().includes(searchText.toLowerCase()),
      ),
    [subNavItems, searchText],
  );

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      restrictHeightSubNav={restrictHeightSubNav}
    >
      {includeSearchSubNav && subNavItems.length > 5 && (
        <FilterInput
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          placeholder="Search"
          onClick={e => e.stopPropagation()}
          autoFocus
        />
      )}
      {filteredSubNavItems.map(
        ({
          onClick,
          title,
          titleBold,
          icon,
          isDisabled,
          dataTour = "",
          dividerAfter = false,
          iconColor = "black",
        }) => (
          <React.Fragment key={title}>
            <MenuItem
              onClick={e => {
                if (!isDisabled) {
                  onClose();
                  onClick && onClick(e);
                }
              }}
              disabled={isDisabled}
              data-tour={dataTour}
            >
              {icon && <ButtonIcon icon={icon} color={iconColor} />}
              <Title bold={titleBold}>{title}</Title>
            </MenuItem>
            {dividerAfter ? <Divider /> : null}
          </React.Fragment>
        ),
      )}{" "}
    </Popover>
  );
};
