import React from "react";

import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { FieldArray, useFormikContext } from "formik";
import sum from "lodash/sum";
import sumBy from "lodash/sumBy";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { deleteSaleLot } from "actions";

import SlimButton from "components/Button/SlimButton";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import {
  ControlledLifeCycleInput,
  DollarDisplayCentsInputProps,
  Input,
  KilogramDisplayGramsInputProps,
  OptionTogglerField,
} from "components/Form/FormikControls";
import { Row } from "components/Layout";

import { PricingTypes } from "constants/pricingTypes";

import { ForSheep } from "containers/ForSpecies";

import { calculateTotalPriceCents, formatDecimal } from "lib";

const ColouredIconButton = styled(IconButton)`
  && {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray78};
    color: ${({ theme }) => theme.colors.gray78};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    width: 3rem;
    height: 3rem;
  }
`;

const calculateTotalPriceDollars = (saleLots, pricingType) =>
  sum([
    ...saleLots.map(s => {
      return calculateTotalPriceCents({
        ...s,
        unitPrice: s.unit_price,
        pricing_type_id: pricingType,
        total_mass_grams: +s.total_mass_grams,
      });
    }),
  ]) / 100 || 0;

export function KillSheetForm() {
  const formikProps = useFormikContext();

  const [confirmDeleteSaleLot, setConfirmDeleteSaleLot] = React.useState(false);
  const dispatch = useDispatch();

  const handleDeleteSaleLot = saleLot => {
    dispatch(deleteSaleLot(saleLot));
  };

  const { values } = formikProps;

  const { saleLots } = values;
  const referenceSaleLot = saleLots[0];

  const pricingType = referenceSaleLot?.pricing_type_id || 0;

  const totalQuantity = sumBy(saleLots, "quantity") || 0;

  const totalPrice = calculateTotalPriceDollars(saleLots, pricingType);

  const allSaleLots = values.saleLots
    .concat(values.skinsSaleLot)
    .filter(Boolean);

  const totalWeightGramsWithSkins = sumBy(
    allSaleLots,
    lot => +lot.total_mass_grams,
  );

  const totalWeightGrams = sumBy(saleLots, lot => +lot.total_mass_grams);
  const averageWeightGrams =
    totalQuantity === 0 ? 0 : totalWeightGrams / totalQuantity;
  const averagePricePerKg =
    totalWeightGrams === 0 ? 0 : totalPrice / (totalWeightGrams / 1000);

  const dollarsPerHead = totalQuantity === 0 ? 0 : totalPrice / totalQuantity;

  const skinQuantity =
    values.skinQuantity || values.skinQuantity === 0
      ? values.skinQuantity
      : sum(values.saleLots.map(s => s.quantity));

  // convert skinValue to cents so it can be applied to total_price_cents on the skins sale lot
  const skinValue = (values.skin_price * skinQuantity) / 100;

  const totalPriceSkins = totalPrice + skinValue;
  const averagePricePerKgSkins =
    totalWeightGrams === 0
      ? 0
      : totalPriceSkins / totalWeightGramsWithSkins / 1000;

  // this is the price per animal (not per skin) - we want to calculate the entire price against the animal quantity
  const dollarsPerHeadSkins =
    totalQuantity === 0 ? 0 : totalPriceSkins / totalQuantity;

  const removeSaleLotAtIndex = index => {
    formikProps.setFieldValue(
      "saleLots",
      values.saleLots.filter((s, i) => i !== index),
    );
  };

  const handleClickDelete = (saleLot, index) => {
    if (!saleLot.id) {
      removeSaleLotAtIndex(index);
    } else {
      setConfirmDeleteSaleLot(saleLot);
    }
  };

  const closeConfirmDialog = () => setConfirmDeleteSaleLot(null);

  return (
    <>
      <FieldArray name="saleLots">
        {arrayHelpers => (
          <>
            <Grid xs={12} item>
              <OptionTogglerField
                name="saleLots[0].pricing_type_id"
                options={PricingTypes.all()}
              />
            </Grid>

            {saleLots.map((saleLot, i) => {
              return (
                <React.Fragment key={saleLot.id || i}>
                  <Grid item xs={3}>
                    <Input
                      label={i === 0 ? "Headcount" : undefined}
                      name={`saleLots[${i}]quantity`}
                      required={i === 0}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ControlledLifeCycleInput
                      {...KilogramDisplayGramsInputProps}
                      label={i === 0 ? "Weight (kg)" : undefined}
                      name={`saleLots[${i}]total_mass_grams`}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      label={
                        i === 0 ? PricingTypes.toString(pricingType) : undefined
                      }
                      name={`saleLots[${i}]unit_price`}
                      type="number"
                      decimal
                      afterSymbol={
                        pricingType === PricingTypes.PER_KILO ? "¢" : null
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <ColouredIconButton
                      onClick={() => handleClickDelete(saleLot, i)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </ColouredIconButton>
                  </Grid>
                </React.Fragment>
              );
            })}

            <Grid item xs={12}>
              <Row justifyEnd>
                <SlimButton
                  onClick={() =>
                    arrayHelpers.push({
                      quantity: "",
                      total_mass_grams: "",
                      cents_per_kilo: "",
                    })
                  }
                  type="button"
                >
                  Add line
                </SlimButton>
              </Row>
            </Grid>

            <Grid item xs={4}>
              <Input
                disabled
                name="total_quantity"
                align="right"
                label="Total"
                overrideValue={totalQuantity}
              />
            </Grid>

            <Grid item xs={4}>
              <Input
                disabled
                name="total_weight"
                align="right"
                label="Total Weight"
                afterSymbol="kg"
                overrideValue={(totalWeightGrams / 1000).toFixed(2)}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                disabled
                name="total_price"
                align="right"
                label="Total Price"
                beforeSymbol="$"
                overrideValue={formatDecimal(totalPrice)}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                disabled
                name="dollarsPerHead"
                align="right"
                label="$/Hd"
                overrideValue={formatDecimal(dollarsPerHead)}
              />
            </Grid>

            <Grid item xs={4}>
              <Input
                disabled
                name="averageWeight"
                align="right"
                label="Avg Weight"
                afterSymbol="kg"
                overrideValue={(averageWeightGrams / 1000).toFixed(2)}
              />
            </Grid>

            <Grid item xs={4}>
              <Input
                disabled
                name="averagePricePerKg"
                align="right"
                label="Avg $/kg"
                overrideValue={formatDecimal(averagePricePerKg)}
              />
            </Grid>

            <ForSheep>
              <Grid item xs={4}>
                <Input
                  label="Skin Qty"
                  name="skinQuantity"
                  overrideValue={skinQuantity}
                  align="right"
                />
              </Grid>
              <Grid item xs={4}>
                <ControlledLifeCycleInput
                  {...DollarDisplayCentsInputProps}
                  label="Skin Price"
                  name="skin_price"
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  label="Skin Value"
                  name="skinValue"
                  beforeSymbol="$"
                  disabled
                  overrideValue={formatDecimal(skinValue)}
                  align="right"
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled
                  name="averagePricePerKgSkins"
                  align="right"
                  label="Avg $/kg (inc skins)"
                  overrideValue={formatDecimal(averagePricePerKgSkins)}
                />
              </Grid>

              <Grid item xs={4}>
                <Input
                  disabled
                  name="dollarsPerHeadSkins"
                  align="right"
                  label="$/Hd (inc skins)"
                  overrideValue={formatDecimal(dollarsPerHeadSkins)}
                />
              </Grid>

              <Grid item xs={4}>
                <Input
                  disabled
                  name="totalPriceSkins"
                  align="right"
                  label="Total (inc skins)"
                  overrideValue={formatDecimal(totalPriceSkins)}
                />
              </Grid>
            </ForSheep>
          </>
        )}
      </FieldArray>
      <ConfirmDialog
        title={createModalTitle("this item")}
        isOpen={confirmDeleteSaleLot}
        onCancel={closeConfirmDialog}
        onDelete={() => {
          handleDeleteSaleLot(confirmDeleteSaleLot);
          closeConfirmDialog();
        }}
      />
    </>
  );
}
