import { ColumnType } from "components/AgGrid/constants";

export const MasterRuleColId = {
  COMMENT: "2fdaba6a-5994-4bb0-9463-4024d7558f92",
  CREATED: "63bc355b-6f20-413f-a1ec-926e60c89a6a",
  GL_CODE: "5b112c75-511f-4460-85a4-5802e88510b4",
  INVOICE_LINE_ITEM_TEMPLATE: "00bf9af8-ec97-4ba8-8cf3-1b719f96e405",
  LAST_MODIFIED: "489c6da5-9128-49d4-8af6-847575190a0d",
  MASTER_RULE_ID: "e3856d0b-c6b7-4341-9156-d8f8ee57ec04",
  NAME: "a901f1b9-1a05-4071-ab3e-a4281ac7e460",
  ORDER: "320c701b-9cfb-4dca-8670-0a81bbd3478f",
  RULE_BOOK_ID: "d775b5d7-b379-4103-83c5-d24b086e552b",
  TITLE_TEMPLATE: "fd8705b2-e76b-4fab-8772-3e724e93cf1f",
  MASTER_RULE_BOOK: "3a317be0-6dfb-4769-9fbf-d837ab7a3b53",
};

export const MasterRuleColumnDefinitions = {
  [MasterRuleColId.NAME]: {
    colId: MasterRuleColId.NAME,
    field: "masterRule.name",
    headerName: "Name",
    width: 90,
    type: ColumnType.STRING,
  },
  [MasterRuleColId.GL_CODE]: {
    colId: MasterRuleColId.GL_CODE,
    field: "masterRule.gl_code",
    headerName: "GL Code",
    width: 90,
    type: ColumnType.STRING,
  },
  [MasterRuleColId.CREATED]: {
    colId: MasterRuleColId.CREATED,
    field: "masterRule.created",
    headerName: "Created",
    width: 90,
    type: ColumnType.UTC_LOCAL_DATE_TIME,
  },
  [MasterRuleColId.LAST_MODIFIED]: {
    colId: MasterRuleColId.LAST_MODIFIED,
    field: "masterRule.last_modified",
    headerName: "Last Modified",
    width: 90,
    type: ColumnType.UTC_LOCAL_DATE_TIME,
  },
  [MasterRuleColId.INVOICE_LINE_ITEM_TEMPLATE]: {
    colId: MasterRuleColId.INVOICE_LINE_ITEM_TEMPLATE,
    field: "masterRule.invoice_line_item_template",
    headerName: "Grouped Title",
    width: 90,
    type: ColumnType.STRING,
  },
  [MasterRuleColId.TITLE_TEMPLATE]: {
    colId: MasterRuleColId.TITLE_TEMPLATE,
    field: "masterRule.title_template",
    headerName: "Title",
    width: 90,
    type: ColumnType.STRING,
  },
  [MasterRuleColId.MASTER_RULE_BOOK]: {
    colId: MasterRuleColId.MASTER_RULE_BOOK,
    field: "masterRuleBook.name",
    headerName: "Master Rule Book",
    width: 90,
    type: ColumnType.STRING,
  },
  [MasterRuleColId.ORDER]: {
    colId: MasterRuleColId.ORDER,
    field: "masterRuleBook.order",
    headerName: "Order",
    width: 90,
    type: ColumnType.NUMERIC,
  },
};
