import React, { memo, useContext, useEffect } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import {
  GeneralSaleLotFormComponent,
  isGeneralFormDirty,
} from "components/GeneralSaleLotForm";
import generalValidationShape from "components/GeneralSaleLotForm/validationSchema";
import PenningSaleLotForm, {
  isPenningFormDirty,
} from "components/PenningSaleLotForm";
import penningFormValidationSchema from "components/PenningSaleLotForm/validationSchema";

import { PenTypes } from "constants/auctionPens";
import { Settings } from "constants/settings";

import { WeighBridgeSaleDispatchContext } from "containers/WeighbridgeSaleView/WeighBridgeSaleContext";

import { expandAuctionPen } from "lib/auctionPens";

import {
  currentSaleSelector,
  getAuctionPenById,
  getNestedConsignments,
  getNextEmptyPenNumber,
  getSaleLotById,
  getSetting,
  getSpeciesAttributeOptions,
  selectCurrentDeploymentSales,
} from "selectors";

import InlineConsignmentSelect from "./InlineConsignmentSelect";

const validationSchema = Yup.object().shape({
  // A bit yucky - but consignment id could be an agency id.
  consignment_id: Yup.lazy(value =>
    typeof value === "number"
      ? Yup.number().required("A consignment must be selected")
      : Yup.string().required("A consignment must be selected"),
  ),
  ...generalValidationShape,
  auction_pen: Yup.object().shape(penningFormValidationSchema),
});

function SaleLotFormInnerComponent(props) {
  const { contextUpdateSaleLot, options = {} } = props;
  const {
    showDeliveryPen = true,
    showAuctionPenAtTop = false,
    includeCreateUnknownConsignment = false,
    includeCreateConsignmentButton = false,
    ignoreHeadCountForVendorSearch = false,
  } = options;

  const formikProps = useFormikContext();
  const { errors, touched, values, initialValues } = formikProps;

  const { sale_round_id: saleRoundId } = values;

  const speciesAttributes = useSelector(getSpeciesAttributeOptions);
  const consignments = useSelector(getNestedConsignments);

  const deploymentSales = useSelector(selectCurrentDeploymentSales);

  // The first time we add the unknown business consignment, we use the same machinery,
  // but infer what the value refers to by checking its type.  If its a string/uuid, its
  // consignment, if its a number, its a livestock agency id.
  const isConsignmentId = value => value && typeof value === "string";
  const isLivestockAgencyId = value => value && typeof value === "number";

  const isFormValid = Object.keys(errors).length === 0;
  useEffect(() => {
    const isNew = !initialValues.id;
    if (isNew) {
      contextUpdateSaleLot(values, isFormValid);
    } else if (
      Object.keys(touched).length > 0 &&
      (isPenningFormDirty(initialValues, values, "auction_pen") ||
        isPenningFormDirty(initialValues, values, "deliveryPen") ||
        isGeneralFormDirty(initialValues, values) ||
        values.consignment_id !== initialValues.consignment_id)
    ) {
      const touchedValues = Object.keys(values)
        .filter(key => touched[key] && values?.[key] !== initialValues?.[key])
        .reduce((acc, key) => {
          acc[key] = values[key];
          return acc;
        }, {});
      contextUpdateSaleLot(touchedValues, isFormValid);
    } else {
      contextUpdateSaleLot(null, false, false);
    }
  }, [initialValues, isFormValid, touched, contextUpdateSaleLot, values]);

  let deploymentId;
  if (isConsignmentId(values.consignment_id)) {
    const consignment =
      consignments.find(c => c.id === values.consignment_id) || {};
    deploymentId = deploymentSales[consignment.deployment_sale]?.deployment_id;
  } else if (isLivestockAgencyId(values.consignment_id)) {
    deploymentId = Object.values(deploymentSales).find(
      ds => ds.livestock_agency_id === values.consignment_id,
    )?.deployment_id;
  }

  return (
    <Form>
      <Grid container spacing={2}>
        {showAuctionPenAtTop ? (
          <Grid item xs={12} container spacing={1}>
            <PenningSaleLotForm
              label="Auction Pen"
              saleRoundId={saleRoundId}
              saleLotId={initialValues.id}
              namespace="auction_pen"
              penType={PenTypes.SELLING}
            />
          </Grid>
        ) : null}

        <InlineConsignmentSelect
          includeCreateConsignmentButton={includeCreateConsignmentButton}
          includeCreateUnknownConsignment={includeCreateUnknownConsignment}
          ignoreHeadCountForVendorSearch={ignoreHeadCountForVendorSearch}
        />
        <GeneralSaleLotFormComponent
          speciesAttributes={speciesAttributes}
          deploymentId={deploymentId}
          isNew={!initialValues.id}
        />
        {showAuctionPenAtTop ? null : (
          <Grid item xs={12} container spacing={1}>
            <PenningSaleLotForm
              label="Auction Pen"
              saleRoundId={saleRoundId}
              saleLotId={initialValues.id}
              namespace="auction_pen"
              penType={PenTypes.SELLING}
            />
          </Grid>
        )}
        {showDeliveryPen ? (
          <Grid item xs={12} container spacing={1}>
            <PenningSaleLotForm
              label="Delivery Pen"
              saleRoundId={saleRoundId}
              saleLotId={initialValues.id}
              namespace="deliveryPen"
              penType={PenTypes.DELIVERY}
              showOwnerPrompt
            />
          </Grid>
        ) : null}
      </Grid>
    </Form>
  );
}
const SaleLotFormInner = memo(SaleLotFormInnerComponent);
SaleLotFormInner.propTypes = {
  rounds: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.any.isRequired,
    }),
  ),
  contextUpdateSaleLot: PropTypes.func,
};

export function InlineCreateSaleLotComponent(props) {
  const {
    saleLotId,
    contextUpdateSaleLot,
    initialValues: suppliedInitialValues = {},
    options,
  } = props;
  const saleLot = useSelector(getSaleLotById(saleLotId));
  const { auction_pen_id: auctionPenId, deliveryPenId } = saleLot || {};
  const auctionPen = useSelector(getAuctionPenById(auctionPenId));
  const deliveryPen = useSelector(getAuctionPenById(deliveryPenId));
  const rounds = useSelector(currentSaleSelector).rounds || [];
  const sale = useSelector(currentSaleSelector);
  const lastUsedRound = useSelector(getSetting(Settings.round));
  const nextPenNumber = useSelector(getNextEmptyPenNumber);

  const { autoPopulateNextPen = false } = options;

  const initialPen = autoPopulateNextPen ? nextPenNumber : null;

  const defaultRoundId =
    rounds.length === 1
      ? rounds[0].id // When there is only one round available, we don't have much choice, so preselect it
      : lastUsedRound; // Prefer auto selecting the last used round when available. this could also be null

  const initialPenningValues = {};
  if (auctionPen) {
    initialPenningValues.auction_pen = expandAuctionPen(auctionPen);
  }
  if (deliveryPen) {
    initialPenningValues.deliveryPen = expandAuctionPen(deliveryPen);
  }

  const initialValues = {
    livestocksale_id: sale.livestocksale_id,
    pricing_type_id: sale.pricing_type_id,
    sale_round_id: defaultRoundId,
    quantity: 0,
    consignment_id: "",
    breed_id: null,
    sex_id: null,
    grade_id: null,
    age_id: null,
    category_id: null,
    exemption_id: null,
    note: "",
    marks: [],
    quantityProgeny: 0,
    auction_pen: {
      start_pen: initialPen,
    },
    ...saleLot, // override any of the defaults with the values from the selected Sale Lot
    ...initialPenningValues,
    ...suppliedInitialValues,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      <SaleLotFormInner
        rounds={rounds}
        contextUpdateSaleLot={contextUpdateSaleLot}
        options={options}
      />
    </Formik>
  );
}

export function InlineCreateSaleLotComponentWithContext(props) {
  const {
    saleLotId,
    ignoreHeadCountForVendorSearch = false,
    showAuctionPenAtTop = false,
  } = props;
  const { contextUpdateSaleLot } = useContext(WeighBridgeSaleDispatchContext);

  return (
    <InlineCreateSaleLotComponent
      contextUpdateSaleLot={contextUpdateSaleLot}
      saleLotId={saleLotId}
      options={{
        includeCreateConsignmentButton: true,
        ignoreHeadCountForVendorSearch,
        showAuctionPenAtTop,
      }}
    />
  );
}

export default memo(InlineCreateSaleLotComponentWithContext);
