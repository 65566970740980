import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { addSaleLot, patchSaleLot, SaleLotAction } from "actions";

import AuctionPenSaleView from "components/AuctionPenSaleView";
import HashRoute from "components/HashRoute";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MergeSaleLotModal from "components/MergeSaleLotModal/Adapter";
import SplitSaleLotModal from "components/SplitLotModal/Adapter";

import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import { Settings } from "constants/settings";

import { isSoldOrNoSale } from "lib/auctionPens";
import { getSaleyardAuctionRoute } from "lib/navigation";

import {
  currentSaleSelector,
  getAuctionPenWithSaleLots,
  getNextAuctionPenIdByAuctionPenId,
  getPreviousAuctionPenIdByAuctionPenId,
  getSaleLotsByAuctionPenAndRoundSelector,
  getSelectedSaleyard,
  getSetting,
  selectAgencyIdByConsignmentIdLookup,
  selectPropertyEnrichedBusinessByBusinessIdLookup,
  selectSingleWeighWeightBySaleLotIdLookup,
} from "selectors";

function AuctionPen() {
  const dispatch = useDispatch();

  const onPatchSaleLot = payload => {
    dispatch(patchSaleLot(payload));
  };
  const onAddSaleLot = (payload, id) => {
    dispatch(addSaleLot(payload, id));
  };
  const onCreateSaleLotComment = (id, payload) => {
    dispatch(SaleLotAction.comment(id, payload));
  };

  const params = useParams();
  const { saleyard, saleId, roundId, auctionPenId } = params;
  const history = useHistory();

  const agencyIdByConsignmentIdLookup = useSelector(
    selectAgencyIdByConsignmentIdLookup,
  );
  const auctionPen = useSelector(state =>
    getAuctionPenWithSaleLots(state, auctionPenId),
  );
  const saleLots = useSelector(state =>
    getSaleLotsByAuctionPenAndRoundSelector(state, params),
  );
  const businesses = useSelector(
    selectPropertyEnrichedBusinessByBusinessIdLookup,
  );
  const nextAuctionPenId = useSelector(state =>
    getNextAuctionPenIdByAuctionPenId(auctionPenId)(state, { roundId }),
  );
  const nextUrl = nextAuctionPenId
    ? getSaleyardAuctionRoute(saleyard, saleId, roundId, nextAuctionPenId)
    : null;
  const prevAuctionPenId = useSelector(state =>
    getPreviousAuctionPenIdByAuctionPenId(auctionPenId)(state, { roundId }),
  );
  const prevUrl = prevAuctionPenId
    ? getSaleyardAuctionRoute(saleyard, saleId, roundId, prevAuctionPenId)
    : null;

  const sale = useSelector(currentSaleSelector);
  const { id: saleyardId } = useSelector(getSelectedSaleyard);
  const singleWeighWeightBySaleLotIdLookup = useSelector(
    selectSingleWeighWeightBySaleLotIdLookup,
  );
  const offlineLookup = useSelector(state => state.offlineTemp);

  const mode = useSelector(getSetting(Settings.auctionScreenMode));

  const saleyardAuctionRoute = getSaleyardAuctionRoute(
    saleyard,
    saleId,
    roundId,
  );
  const incrementAuctionPen = useCallback(
    increment => {
      if ((increment && nextUrl) || (!increment && prevUrl)) {
        history.push({
          pathname: increment ? nextUrl : prevUrl,
          state: { direction: increment ? "right" : "left" },
        });
      } else {
        history.push(saleyardAuctionRoute);
      }
    },
    [nextUrl, prevUrl, history, saleyardAuctionRoute],
  );

  // Calculate this to a simple comparable type outside the useEffect below, otherwise we get a scrolling effect
  // with every change to the sale lots making the effect fire.
  const allSold = useMemo(() => saleLots.every(isSoldOrNoSale), [saleLots]);

  const auctionPenRef = useRef();
  // If all lots in this pen are sold, go to the next one.
  useEffect(() => {
    // If the auction pen has not changed, but the sale lots have, and they're all sold, skip to the next one.
    if (auctionPenId && auctionPenRef.current === auctionPenId && allSold) {
      incrementAuctionPen(true);
    }

    auctionPenRef.current = auctionPenId;
  }, [auctionPenId, auctionPenRef, allSold, incrementAuctionPen]);

  // Feel a bit hacky, but on initial page load, there will be missing data and there MUST be some businesses.
  if (isEmpty(businesses)) {
    return null;
  }

  const handleBack = () => {
    history.push(getSaleyardAuctionRoute(saleyard, saleId, roundId));
  };

  return (
    <WaitForSync
      requiredData={[
        ApiModel.AGENCIES,
        ApiModel.AUCTION_PENS,
        ApiModel.BUSINESSES,
        ApiModel.CONSIGNMENTS,
        ApiModel.DEPLOYMENTS,
        ApiModel.ROUNDS,
        ApiModel.SALE_LOTS,
        ApiModel.SALES,
      ]}
    >
      <AuctionPenSaleView
        agencyIdByConsignmentIdLookup={agencyIdByConsignmentIdLookup}
        auctionPen={auctionPen}
        saleLots={saleLots}
        businesses={businesses}
        patchSaleLot={onPatchSaleLot}
        createSaleLotComment={onCreateSaleLotComment}
        addSaleLot={onAddSaleLot}
        handleBack={handleBack}
        hasNext={!!nextUrl}
        hasPrev={!!prevUrl}
        incrementAuctionPen={incrementAuctionPen}
        roundId={parseInt(roundId, 10)}
        sale={sale}
        offlineLookup={offlineLookup}
        singleWeighWeightBySaleLotIdLookup={singleWeighWeightBySaleLotIdLookup}
        mode={mode}
      />
      <HashRoute
        hash={ModalTypes.SplitSaleLot}
        component={SplitSaleLotModal}
        componentProps={{ saleyardId }}
      />
      <HashRoute hash={ModalTypes.MergeSaleLot} component={MergeSaleLotModal} />
    </WaitForSync>
  );
}

export default AuctionPen;
