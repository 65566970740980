import React, { useMemo } from "react";

import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Divider, Grid } from "@material-ui/core";
import { FieldArray, useField, useFormikContext } from "formik";
import { isObject, sortBy, uniqBy } from "lodash";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { SlimButton } from "components/Button";
import { ButtonIcon } from "components/Button/FontAwesomeButton";
import { Autocomplete } from "components/Form/FormikControls/Autocomplete";
import { Error } from "components/Form/FormikControls/Error";
import { Input, PercentageInput } from "components/Form/FormikControls/Input";
import { Label } from "components/Form/FormikControls/Label";
import { Required } from "components/Form/FormikControls/layout";
import { withNamespace } from "components/Form/FormikControls/lib";
import { Column } from "components/Layout";

import {
  VendorCommissionBandOptions,
  VendorCommissionBandTypes,
} from "constants/vendorCommissionBands";

import { validatePercentage } from "lib/validators";

import { getSaleSubTypes, getSpecies } from "selectors";

import { useFieldValue } from "hooks";

const getOptionValue = option => option.value;

export const vendorCommissionBandsValidation = Yup.array()
  .nullable()
  .of(
    Yup.object().shape({
      saleSubTypeId: Yup.number().nullable(true),
      commissionType: Yup.string().required("Required"),
      commissionValue: validatePercentage(12, 6).required("Required"),
    }),
  )
  .test(
    "non-dupe",
    "You may only specify one commission rate per sale sub type.",
    value => uniqBy(value, "saleSubTypeId").length === (value?.length || 0),
  );

function VendorCommissionBand(props) {
  const { index, name, namespace, removeRow } = props;

  const saleSubTypes = useSelector(getSaleSubTypes);
  const species = useSelector(getSpecies);

  const subTypeOptions = useMemo(() => {
    return [
      {
        value: null,
        label: "Default for all Sale Sub Types",
      },
    ].concat(
      sortBy(
        Object.values(saleSubTypes).map(s => ({
          label: `${species[s.speciesId].name} - ${s.name}`,
          value: s.id,
        })),
        "label",
      ),
    );
  }, [species, saleSubTypes]);

  const [{ value: commissionType }] = useField(
    `${withNamespace(namespace, name)}.${index}.commissionType`,
  );

  return (
    <>
      <Grid item xs={6} md={4}>
        <Autocomplete
          name={`${withNamespace(namespace, name)}.${index}.saleSubTypeId`}
          options={subTypeOptions}
          getOptionValue={getOptionValue}
          disableClearable
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Autocomplete
          name={`${withNamespace(namespace, name)}.${index}.commissionType`}
          options={VendorCommissionBandOptions}
          required
          getOptionValue={getOptionValue}
          disableClearable
        />
      </Grid>
      <Grid item xs={6} md={3}>
        {commissionType === VendorCommissionBandTypes.DollarsPerHead ? (
          <Input
            name={`${withNamespace(namespace, name)}.${index}.commissionValue`}
            beforeSymbol="$"
            multiplier={100}
            decimalPlaces={3}
            decimal
          />
        ) : (
          <PercentageInput
            name={`${withNamespace(namespace, name)}.${index}.commissionValue`}
          />
        )}
      </Grid>
      <Grid item xs={6} md={2} container justifyContent="flex-end">
        <SlimButton
          onClick={() => removeRow(index)}
          color="deleteRed"
          type="button"
        >
          <ButtonIcon icon={faTrash} />
          &nbsp;Remove
        </SlimButton>
      </Grid>
      <Grid xs={12} item>
        <Divider />
      </Grid>
    </>
  );
}

const generateDefaultBand = () => ({
  id: uuidv4(),
  saleSubTypeId: null,
  commissionType: VendorCommissionBandTypes.Percentage,
  commissionValue: 0,
});

export function VendorCommissionBands(props) {
  const {
    namespace = "",
    name = "vendorCommissionBands",
    label = "Vendor Commissions",
    tooltip,
  } = props;

  const value = useFieldValue(withNamespace(namespace, name)) || [];
  const { errors } = useFormikContext();
  const error = errors[withNamespace(namespace, name)];

  return (
    <Column fullWidth fullHeight>
      {label ? <Label tooltip={tooltip}>{label}</Label> : null}
      <Column fullWidth fullHeight paddingHorizontal={3}>
        <FieldArray name={withNamespace(namespace, name)}>
          {arrayHelpers => {
            const addNew = () => {
              arrayHelpers.push(generateDefaultBand());
            };
            return (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SlimButton onClick={addNew} type="button">
                    <ButtonIcon icon={faPlus} />
                    &nbsp;New Commission Rate
                  </SlimButton>
                </Grid>
                {!isObject(error) && error ? (
                  <Grid item xs={12}>
                    <Error>{error}</Error>
                  </Grid>
                ) : null}
                {value.length > 0 ? (
                  <>
                    <Grid item xs={6} md={4}>
                      <Label>Sale Sub Type</Label>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Label>
                        Commission Type
                        <Required />
                      </Label>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Label>
                        Commission Value
                        <Required />
                      </Label>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      &nbsp;
                    </Grid>
                    <Grid xs={12} item>
                      <Divider />
                    </Grid>
                    {value.map((vendorCommissionBand, idx) => (
                      <VendorCommissionBand
                        index={idx}
                        removeRow={arrayHelpers.remove}
                        name={name}
                        key={idx}
                      />
                    ))}
                  </>
                ) : null}
              </Grid>
            );
          }}
        </FieldArray>
      </Column>
    </Column>
  );
}
