import { isEmpty, max, min } from "lodash";
import { flatten } from "lodash/array";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import uniq from "lodash/uniq";
import { createSelector } from "reselect";

import { PenTypes } from "constants/auctionPens";
import { DeclarationTypes, DocumentTypes } from "constants/documentTypes";
import { Accreditation } from "constants/draftingAttributes";
import { IntegrationTypes } from "constants/integrations";
import { PricingTypes } from "constants/pricingTypes";
import { ScanStatus } from "constants/scanner";

import {
  calculateAveragePriceAndFormat,
  calculateAverageWeightAndFormat,
  calculateUnitPriceCents,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  formatDecimal,
  getDollarPriceStringFromCents,
  getUnitPriceString,
} from "lib";

import { AddressDisplayFormat, formatAddress } from "lib/address";
import {
  auctionPenSellingOrderSort,
  expandAuctionPen,
  getAuctionPenDisplayName,
  isSoldOrIsDeliveredWithNonZeroPrice,
} from "lib/auctionPens";
import { uniqueByPropertyFilter } from "lib/filters";
import {
  doesSaleLotHaveOverflowPen,
  getAverageWeightFormatted,
  getAverageWeightGrams,
  getAverageWeightKg,
  getBuyerHashFromSaleLot,
  getCombinedLotNumber,
  getMarksLocationString,
  getOpenAuctionCount,
  getSaleLotScannedStatus,
  getSaleLotStatus,
  getScanStatusThreshold,
  getTotalWeightFormatted,
  saleLotHasException,
} from "lib/saleLot";

import {
  createLookupCombiner,
  createLookupSelectors,
  getCurrentSale,
  getCurrentSaleyard,
  getSellFileBySaleLotId,
  getVendorSplitConsignments,
  getVendorSplitSaleLots,
  selectAgencyByConsignmentIdLookup,
  selectAttachmentIdsBySaleLotIdLookup,
  selectBidderIdBySaleLotIdLookup,
  selectDeploymentIdByConsignmentIdLookup,
  selectDeploymentIdBySaleLotIdLookup,
  selectFilteredSaleLotIds,
  selectIsWeighedBySaleLotIdLookup,
  selectNameByDeploymentIdLookup,
  selectPaymentIdsByFromBusinessIdLookup,
  selectSaleLotIdsByDeliveryPenIdLookup,
  selectScannedCountBySaleLotIdLookup,
  selectSellFileBySaleLotIdLookup,
  selectSingleWeighWeightBySaleLotIdLookup,
  selectTakeFilesByConsignmentIdLookup,
  selectUnknownConsignmentIdsByDeploymentSaleIdLookup,
  selectVendorSplitConsignmentIdsByParentConsignmentIdLookup,
  selectVendorSplitSaleLotIdsByParentSaleLotIdLookup,
} from "selectors";

import { selectPropertyEnrichedBusinessByBusinessIdLookup } from "selectors/businesses";
import { skipCategoryIdsSelector } from "selectors/categories";
import {
  formatSaleLot,
  getConsignmentId,
  getStatusForSaleLots,
  scansWithHasIssue,
} from "selectors/lib";
import {
  scansBySaleLotSelector,
  selectCurrentSaleRounds,
  selectDeploymentSaleBySaleLotIdLookup,
} from "selectors/sales";
import { getSkinsSexId } from "selectors/sexes";
import { selectDeploymentMarkOrderLookup } from "selectors/speciesAttributes";

import {
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotIdsByBuyerIdLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectSaleLotIdsByRoundIdLookup,
} from "./indexes";
import {
  getAges,
  getAuctionPens,
  getBidders,
  getBreeds,
  getBusinesses,
  getCategories,
  getConsignments,
  getDressingRanges,
  getExemptions,
  getFiles,
  getGrades,
  getProperties,
  getSaleLots,
  getSales,
  getSaleyardScanSaleLots,
  getScans,
  getSexes,
  getWeightRanges,
} from "./root";

const getAuctionPenIdParam = (_, props) => props.auctionPenId;

export const getSaleLotById = saleLotId => state =>
  getSaleLots(state)[saleLotId] || null;

export const getSaleyardScanSaleLotById = saleLotId => state =>
  getSaleyardScanSaleLots(state)[saleLotId] || null;

export const getVendorSplitSaleLotById = saleLotId => state =>
  getVendorSplitSaleLots(state)[saleLotId] || null;

export const getSaleyardScanSaleLotByConsignmentId = consignmentId => state =>
  Object.values(getSaleyardScanSaleLots(state)).find(
    saleLot => saleLot.consignmentId === consignmentId,
  ) || null;

export const getSaleLotIdsByConsignmentId = consignmentId => state =>
  selectSaleLotIdsByConsignmentIdLookup(state)[consignmentId] || null;

/**
 * Returns a list of Sale Lot Ids, keyed by Pen Id
 * Requires:
 *  - `Auction Pens`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array[string]>}
 */
export const selectSaleLotIdsByPenId = createSelector(
  [
    getAuctionPens,
    selectSaleLotIdsByAuctionPenIdLookup,
    selectSaleLotIdsByDeliveryPenIdLookup,
  ],
  (
    pensByIdLookup,
    saleLotIdsBySellingPenIdLookup,
    saleLotIdsByDeliveryPenIdLookup,
  ) =>
    Object.keys(pensByIdLookup).reduce((acc, penId) => {
      const sellingPenSaleLotIds = saleLotIdsBySellingPenIdLookup[penId];
      const deliveryPenSaleLotIds = saleLotIdsByDeliveryPenIdLookup[penId];

      if (!sellingPenSaleLotIds && !deliveryPenSaleLotIds) {
        acc[penId] = EMPTY_ARRAY;
      } else if (!deliveryPenSaleLotIds) {
        acc[penId] = sellingPenSaleLotIds;
      } else if (!sellingPenSaleLotIds) {
        acc[penId] = deliveryPenSaleLotIds;
      } else {
        acc[penId] = Array.from(
          new Set([].concat(sellingPenSaleLotIds, sellingPenSaleLotIds)),
        );
      }

      return acc;
    }, {}),
);

export const getSaleLotIdsByPenId = penId => state =>
  selectSaleLotIdsByPenId(state)[penId] || null;

export const getSaleLotIdsByAuctionPenId = auctionPenId => state =>
  selectSaleLotIdsByAuctionPenIdLookup(state)[auctionPenId] || null;

export const getSaleLotIdsByPenIdAndPenType = (penId, penType) => state => {
  if (penType === PenTypes.DELIVERY) {
    return selectSaleLotIdsByDeliveryPenIdLookup(state)[penId];
  } else if (penType === PenTypes.SELLING) {
    return selectSaleLotIdsByAuctionPenIdLookup(state)[penId];
  }
  return null;
};

export const getSaleLotIdsByDeliveryPenId = auctionPenId => state =>
  selectSaleLotIdsByDeliveryPenIdLookup(state)[auctionPenId] || EMPTY_ARRAY;

export const getSaleLotIdsByRoundId = roundId => state =>
  selectSaleLotIdsByRoundIdLookup(state)[roundId] || null;

export const getSaleLotIdsByBuyerId = buyerId => state =>
  selectSaleLotIdsByBuyerIdLookup(state)[buyerId] || null;

export const getSpeciesInfo = state => ({
  ages: getAges(state),
  breeds: getBreeds(state),
  sexes: getSexes(state),
  categories: getCategories(state),
  exemptions: getExemptions(state),
});

export const selectMarkOrderBySaleLotIdLookup = createSelector(
  [
    selectDeploymentMarkOrderLookup,
    selectDeploymentIdByConsignmentIdLookup,
    getSaleLots,
  ],
  (deploymentMarkOrderLookup, deploymentIdByConsignmentIdLookup, saleLots) => {
    return Object.values(saleLots).reduce((acc, saleLot) => {
      const deploymentId =
        deploymentIdByConsignmentIdLookup[saleLot.consignment_id];

      // If there are no deployment marks or the salelot has no marks, set to 0
      if (
        !deploymentMarkOrderLookup[deploymentId] ||
        saleLot.marks.length === 0
      ) {
        acc[saleLot.id] = 0;
        return acc;
      }
      const markOrder = min(
        saleLot.marks.map(
          mark => deploymentMarkOrderLookup[deploymentId][mark.location],
        ),
      );
      acc[saleLot.id] = markOrder;
      return acc;
    }, {});
  },
);

export const getSaleLotsByAuctionPenAndRoundSelector = createSelector(
  [
    getSaleLots,
    getConsignments,
    getAuctionPenIdParam,
    getBusinesses,
    getSpeciesInfo,
    getSales,
    selectMarkOrderBySaleLotIdLookup,
    scansBySaleLotSelector,
  ],
  (
    saleLots,
    consignments,
    auctionPenId,
    businesses,
    speciesInfo,
    sales,
    markOrderBySaleLotIdLookup,
    scansBySalelotId,
  ) => {
    const auctionPenSaleLots = Object.values(saleLots).filter(
      s => s.auction_pen_id === auctionPenId,
    );
    const result = auctionPenSaleLots.map(lot => {
      const consignment = consignments[lot.consignment_id] || {};
      const scans = scansBySalelotId[lot.id];
      const markOrder = markOrderBySaleLotIdLookup[lot.id];

      return Object.assign(
        {
          status: getSaleLotStatus(lot, consignment),
          buyer: businesses[lot.buyer_id] || {},
          vendor: businesses[lot.vendor_id] || {},
          saleyardId: sales[lot.livestocksale_id].saleyard_id,
          age: speciesInfo.ages[lot.age_id] || {},
          breed: speciesInfo.breeds[lot.breed_id] || {},
          sex: speciesInfo.sexes[lot.sex_id] || {},
          scans,
          consignment,
          markOrder,
        },
        lot,
      );
    });

    return sortBy(result, "markOrder");
  },
);

export const getAuctionPensAnnotatedForSaleWatcher = createSelector(
  [getAuctionPens, getSaleLots, skipCategoryIdsSelector],
  (auctionPens, saleLots, skipCategoryIds) =>
    Object.entries(auctionPens).reduce((pens, [auctionPenId, auctionPen]) => {
      const saleLotsInPen = Object.values(saleLots).filter(
        saleLot =>
          saleLot.auction_pen_id === auctionPenId &&
          !skipCategoryIds.includes(saleLot.category_id),
      );

      pens[auctionPenId] = {
        ...auctionPen,
        penName: getAuctionPenDisplayName(auctionPen),
        lotCount: saleLotsInPen.length,
        headCount: sumBy(saleLotsInPen, "quantity"),
      };
      return pens;
    }, {}),
);

export const auctionPensSellingOrderSelector = createSelector(
  [getAuctionPens],
  auctionPens =>
    Object.values(auctionPens)
      .sort(auctionPenSellingOrderSort)
      .map(auctionPen => auctionPen.id),
);

export const getSaleLotId = (state, props) =>
  props.saleLotId || props.match?.params.saleLotId;

const saleLotByIdSelector = createSelector(
  [getSaleLots, getSaleLotId],
  (saleLots, saleLotId) => {
    try {
      return saleLots[saleLotId];
    } catch (e) {
      return EMPTY_OBJECT;
    }
  },
);
export const saleLotScans = createSelector(
  [getScans, getSaleLotId],
  (scans, saleLotId) => {
    try {
      return Object.values(scans).filter(
        scan => scan.sale_lot_id === saleLotId,
      );
    } catch (e) {
      return EMPTY_ARRAY;
    }
  },
);
export const getMinimalFormattedSaleLotById = createSelector(
  [
    saleLotByIdSelector,
    getBusinesses,
    getProperties,
    getSales,
    getAuctionPens,
    saleLotScans,
    getConsignments,
    getFiles,
    state => getCurrentSale(state).species_id,
    getCurrentSaleyard,
  ],
  (
    saleLot,
    businesses,
    properties,
    sales,
    auctionPens,
    scans,
    consignments,
    attachments,
    speciesId,
    currentSaleYard,
  ) => {
    try {
      const scansWithIssues = scansWithHasIssue(saleLot, scans);
      return {
        ...saleLot,
        buyer: businesses[saleLot.buyer_id] || {},
        buyer_way_id:
          saleLot.buyer_way && saleLot.buyer_way.id ? saleLot.buyer_way.id : -1,
        vendor: businesses[saleLot.vendor_id] || {},
        destinationProperty: properties[saleLot.destination_property_id] || {},
        saleyardId: sales[saleLot.livestocksale_id].saleyard_id,
        auction_pen: expandAuctionPen(
          auctionPens[saleLot.auction_pen_id],
          PenTypes.SELLING,
        ),
        deliveryPen: expandAuctionPen(
          auctionPens[saleLot.deliveryPenId],
          PenTypes.DELIVERY,
        ),
        unit_price: parseFloat(getUnitPriceString(saleLot)),
        quantity_scanned: scans.length,
        scannedStatus: getSaleLotScannedStatus(
          saleLot,
          scans.length,
          getScanStatusThreshold(speciesId, currentSaleYard),
        ),
        scans: scansWithIssues,
        numIssues: scansWithIssues.filter(s => s.hasIssues).length,
        consignment: consignments[saleLot.consignment_id] || {},
        attachments:
          saleLot.attachments?.map(a => attachments[a]).filter(Boolean) || [],
      };
    } catch (e) {
      return {};
    }
  },
);
export const getPreviousSaleLotById = (state, props) => {
  try {
    const { consignmentId } = props;
    const { saleLots } = state.saleLots;
    return Object.values(saleLots)
      .filter(lot => lot.consignment_id === consignmentId)
      .sort(
        (lot0, lot1) => Date.parse(lot1.created) - Date.parse(lot0.created),
      )[0];
  } catch (e) {
    return null;
  }
};

export const getSaleLotsBySale = createSelector(
  [
    getSaleLots,
    selectPropertyEnrichedBusinessByBusinessIdLookup,
    getProperties,
    getGrades,
    getBreeds,
    getAges,
    getSexes,
    getCategories,
    getExemptions,
    getAuctionPensAnnotatedForSaleWatcher,
    selectCurrentSaleRounds,
    getConsignments,
    scansBySaleLotSelector,
    selectTakeFilesByConsignmentIdLookup,
    selectSellFileBySaleLotIdLookup,
    getFiles,
    selectAgencyByConsignmentIdLookup,
    selectBidderIdBySaleLotIdLookup,
    selectMarkOrderBySaleLotIdLookup,
    getBidders,
    selectDeploymentSaleBySaleLotIdLookup,
    selectPaymentIdsByFromBusinessIdLookup,
    selectAttachmentIdsBySaleLotIdLookup,
    selectNameByDeploymentIdLookup,
    selectSingleWeighWeightBySaleLotIdLookup,
    getCurrentSale,
    getCurrentSaleyard,
    getWeightRanges,
    getDressingRanges,
  ],
  (
    saleLots,
    businesses,
    properties,
    grades,
    breeds,
    ages,
    sexes,
    categories,
    exemptions,
    auctionPens,
    rounds,
    consignments,
    scansBySaleLot,
    takeFilesByConsignmentIdLookup,
    sellFiles,
    attachments,
    agencyByConsignmentIdLookup,
    bidderBySaleLotIdLookup,
    markOrderBySaleLotIdLookup,
    bidders,
    deploymentSalesBySaleLotIdLookup,
    payments,
    attachmentIdsBySaleLotIdLookup,
    deploymentNameById,
    singleWeighWeight,
    currentSale,
    currentSaleyard,
    weightRanges,
    dressingRanges,
  ) => {
    const result = Object.values(saleLots).map(lot => {
      const buyerPaymentCount = payments[lot.buyer_id]?.length || 0;
      const consignment = consignments[lot.consignment_id] || {};
      const livestockAgency = agencyByConsignmentIdLookup[lot.consignment_id];
      const scannedCount = scansBySaleLot[lot.id]
        ? scansBySaleLot[lot.id].length
        : 0;
      const scans = scansBySaleLot[lot.id];
      const totalSingleWeighedWeight = singleWeighWeight[lot.id];
      const totalSingleWeighedWeightDiff = totalSingleWeighedWeight
        ? totalSingleWeighedWeight - lot.total_mass_grams
        : 0;
      const erpMessages = [];
      if (scans) {
        scans.forEach(scan => {
          erpMessages.push(scan.ERP_status);
        });
      }
      const { quantityProgeny } = lot;
      const EIDNLISDetails = (scansBySaleLot[lot.id] || []).reduce(
        (dataset, scan) => {
          if (scan.EU_status) {
            dataset.EUStatus.add(scan.EU_status);
          }
          if (scan.ERP_status) {
            dataset.ERPStatus.add(scan.ERP_status);
          }
          if (scan.lifetime_traceability) {
            dataset.lifetimeTraceable.add(scan.lifetime_traceability);
          }
          return dataset;
        },
        {
          EUStatus: new Set(),
          ERPStatus: new Set(),
          lifetimeTraceable: new Set(),
        },
      );

      let nlisSellFileStatus = null;
      let nlisSellFileId = null;
      const sellFile = getSellFileBySaleLotId(sellFiles, lot.id);
      if (sellFile !== null) {
        nlisSellFileStatus = sellFile.status;
        nlisSellFileId = sellFile.transaction_id;
      } else {
        nlisSellFileStatus = lot.nlis_sell_file_status;
      }

      let nlisTakeFileStatus = null;
      let nlisTakeFileId = null;
      const consignmentTakeFiles =
        takeFilesByConsignmentIdLookup[lot.consignment_id];
      if (
        // This will be an array if the Consignments have loaded, or if the TakeFiles
        // have loaded and there is a TakeFile for this SaleLot's Consignment
        Array.isArray(consignmentTakeFiles) &&
        consignmentTakeFiles.length > 0
      ) {
        nlisTakeFileStatus = consignmentTakeFiles[0].status;
        nlisTakeFileId = consignmentTakeFiles[0].transaction_id;
      } else {
        nlisTakeFileStatus = consignment.nlis_take_file_status;
      }
      const auctionPen = auctionPens[lot.auction_pen_id];
      const deliveryPen = auctionPens[lot.deliveryPenId];
      const unitPrice = getUnitPriceString(lot);
      const scannedPercentage =
        lot.quantity > 0 || quantityProgeny > 0
          ? scannedCount / (lot.quantity + quantityProgeny)
          : 0;
      const round = rounds[lot.sale_round_id];
      let indPricePerKilo;
      if (
        lot.total_price_cents &&
        lot.pricing_type_id !== PricingTypes.PER_KILO &&
        (lot.total_mass_grams || lot.estimatedAverageMassGrams)
      ) {
        indPricePerKilo = getDollarPriceStringFromCents(
          lot.total_price_cents /
            (lot.total_mass_grams / 1000 ||
              (lot.estimatedAverageMassGrams * lot.quantity) / 1000),
        );
      }
      let indCentsPerKilo;
      let centsPerKilo;
      let dollarsPerHead;
      if (lot.total_price_cents) {
        if (lot.pricing_type_id === PricingTypes.PER_KILO) {
          centsPerKilo = calculateUnitPriceCents(lot);
        } else if (lot.total_mass_grams) {
          centsPerKilo = Math.floor(
            lot.total_price_cents / (lot.total_mass_grams / 1000),
          );
        } else if (lot.estimatedAverageMassGrams) {
          indCentsPerKilo = Math.floor(
            lot.total_price_cents /
              ((lot.estimatedAverageMassGrams / 1000) * lot.quantity),
          );
        }

        if (lot.pricing_type_id === PricingTypes.PER_HEAD) {
          dollarsPerHead = unitPrice;
        } else if (lot.quantity) {
          // unitPrice is a string, so lets keep consistent.
          dollarsPerHead = `${lot.total_price_cents / lot.quantity / 100}`;
        }
      }

      const imageCount =
        attachmentIdsBySaleLotIdLookup[lot.id]?.filter(
          attachmentId =>
            attachments[attachmentId]?.type === DocumentTypes.IMAGE,
        ).length || 0;

      const hasPickupAddress = consignment.useVendorAddressAsPickupAddress
        ? Boolean(businesses[consignment.vendor_id]?.address)
        : Boolean(consignment.pickupAddress);
      const hasBuyerPostalAddress = Boolean(businesses[lot.buyer_id]?.address);
      const hasVendorPostalAddress = Boolean(
        businesses[consignment.vendor_id]?.address,
      );
      const vendorNumber = consignment.vendorNumber || null;
      const { livestock_agency_code } = consignment;

      const itemLocation =
        hasPickupAddress || hasVendorPostalAddress
          ? formatAddress(
              consignment.pickupAddress ||
                businesses[consignment.vendor_id]?.address,
              AddressDisplayFormat.CITY_STATE,
            )
          : "";

      const {
        default_buyers_premium: defaultBuyersPremium,
        default_listing_fee_cents: defaultListingFeeCents,
        default_vendor_commission: defaultVendorCommission,
      } = deploymentSalesBySaleLotIdLookup[lot.id] || {};

      const effectiveVendorCommissionPercent =
        consignment.vendorCommission || defaultVendorCommission;
      const effectiveVendorCommissionCents =
        parseFloat(effectiveVendorCommissionPercent) *
        parseFloat(lot.total_price_cents);

      const effectiveListingFeeCents = lot.listingFee || defaultListingFeeCents;
      // This should eventually be a decimal value from the backend e.g. 0.25
      const effectiveBuyersPremiumPercent =
        lot.buyersPremium / 100 || defaultBuyersPremium;
      const effectiveBuyersPremiumCents =
        parseFloat(lot.total_price_cents) *
        (parseFloat(effectiveBuyersPremiumPercent) / 100);

      const gstStatus = businesses[consignment.vendor_id]?.isGSTRegistered;
      const consignedFrom =
        deploymentNameById[consignment.consignedFromId] || "";

      const vendor = businesses[consignment?.vendor_id] || null;

      const openAuctionCount = getOpenAuctionCount(lot);
      // If they've gone over the bulk weigh, the lot has a time weighed - this says they're all weighed.
      // Otherwise check how many have been single weighed.

      const weighedCount = lot.timeWeighed
        ? lot.quantity
        : scans?.reduce(
            (acc, scan) => (acc += scan.total_mass_grams ? 1 : 0),
            0,
          ) || 0;

      const saleyardId = currentSale.saleyard_id;
      const speciesId = currentSale.species_id;

      const buyer = businesses[lot.buyer_id] || {};

      const weightRange = weightRanges[lot.estimatedAverageWeightId] || {};
      const weightRangeString = weightRange.weightRangeMinKg
        ? `${weightRange.details} ${weightRange.weightRangeMinKg}${
            !weightRange.weightRangeMaxKg
              ? "+"
              : ` - ${weightRange.weightRangeMaxKg}`
          }kg`
        : "";
      const dressingRange = dressingRanges[lot.dressingRangeId] || {};

      const dressingRangeString = dressingRange.dressingRangeMinPercent
        ? `${dressingRange.dressingRangeMinPercent} ${
            !dressingRange.dressingRangeMaxPercent
              ? "+"
              : ` - ${dressingRange.dressingRangeMaxPercent}`
          }%`
        : "";
      return Object.assign({}, lot, {
        age: ages[lot.age_id] || {},
        age_group_name: ages[lot.age_id]?.name || "",
        attachments:
          attachmentIdsBySaleLotIdLookup[lot.id]
            ?.map(a => attachments[a])
            .filter(Boolean) || [],
        indPricePerKilo,

        bidder: bidders[bidderBySaleLotIdLookup[lot.id]] || {},
        breed: breeds[lot.breed_id] || {},
        breed_name: breeds[lot.breed_id]?.name || "",
        bulkWeighTime: lot.timeWeighed,
        buyer,
        buyerPaymentCount,
        buyer_name: businesses[lot.buyer_id]
          ? businesses[lot.buyer_id].name
          : "",
        buyer_short_code: businesses[lot.buyer_id]
          ? businesses[lot.buyer_id].shortCode
          : "",
        buyer_way_id: lot.buyer_way && lot.buyer_way.id ? lot.buyer_way.id : -1,
        buyer_way_name: lot.buyer_way ? lot.buyer_way.name : "",

        category: categories[lot.category_id] || {},
        category_name: categories[lot.category_id]
          ? categories[lot.category_id].name
          : "",

        exemption: exemptions[lot.exemption_id] || {},
        exemption_name: exemptions[lot.exemption_id]
          ? exemptions[lot.exemption_id].name
          : "",

        destinationProperty: properties[lot.destination_property_id] || {},
        deliveryPen,

        grade: grades[lot.grade_id] || {},
        grade_name: grades[lot.grade_id]?.name || "",
        imageCount,

        auction_pen: auctionPen,
        penName: auctionPen?.penName || "",
        start_pen: auctionPen?.start_pen || "",
        end_pen: auctionPen?.end_pen || "",

        lotNumberCombined: getCombinedLotNumber(lot),

        price: getUnitPriceString(lot),
        price_unit: PricingTypes.toString(lot.pricing_type_id),
        quantityProgeny,

        saleyardId,
        sale_round: round || {},
        sale_round_name: round?.name || "",
        scannedStatus: getSaleLotScannedStatus(
          lot,
          scannedCount,
          getScanStatusThreshold(speciesId, currentSaleyard),
        ),
        sex: sexes[lot.sex_id] || {},
        sex_name: sexes[lot.sex_id]?.name || "",
        status: getSaleLotStatus(lot, consignment),

        total_weight: formatDecimal(lot.total_mass_grams / 1000),

        // :'(
        average_weight: getAverageWeightKg(lot),
        averageWeightGrams: getAverageWeightGrams(lot),
        averageWeightFormatted: getAverageWeightFormatted(lot),
        averageWeightFormattedRounded: getAverageWeightFormatted(lot, {
          round: true,
        }),
        totalWeightFormatted: getTotalWeightFormatted(lot),
        centsPerKilo,
        indCentsPerKilo,
        dollarsPerHead,

        thirdPartyName:
          lot.thirdPartyId && businesses[lot.thirdPartyId]
            ? businesses[lot.thirdPartyId].name
            : "",
        // invoiceTo is a better field structure than third party, but, we don't want all the bells - just the name!
        invoiceToBusiness:
          lot.invoiceToBusinessId && businesses[lot.invoiceToBusinessId]
            ? { name: businesses[lot.invoiceToBusinessId].name }
            : {},

        unit_price: parseFloat(unitPrice),
        unitPriceFormatted:
          lot.pricing_type_id === PricingTypes.PER_KILO
            ? `\xA2 ${unitPrice}`
            : `$${unitPrice}`,

        vendor: vendor || {},
        vendor_name: vendor ? vendor.name : "",
        vendor_short: vendor ? vendor.shortCode : "",
        vendor_short_code: vendor ? vendor.shortCode : "",
        vendorDisplayName: vendor ? vendor.publicDisplayName : "",
        vendorNumber,
        livestock_agency_code,
        isSold: isSoldOrIsDeliveredWithNonZeroPrice(lot),
        hasExceptions: saleLotHasException(lot),
        isDelivered:
          lot.quantity_delivered && lot.quantity_delivered === lot.quantity,
        scannedCount,
        scannedPercentage,
        scans,
        erpMessages,
        scannedPercentageFormatted: `${(scannedPercentage * 100).toFixed(2)}%`,
        totalSingleWeighedWeight,
        totalSingleWeighedWeightDiff,
        consignment: Object.assign({}, consignment, {
          vendor_property: properties[consignment.vendor_property_id] || {},
        }),
        diff: lot.quantity_delivered - (lot.quantity + lot.quantityProgeny),
        nlisSellFileStatus,
        nlisSellFileId,
        nlisTakeFileStatus,
        nlisTakeFileId,
        livestockAgency,
        EIDNLISDetails,
        markOrder: markOrderBySaleLotIdLookup[lot.id],
        hasBuyerPostalAddress,
        hasVendorPostalAddress,
        hasPickupAddress,
        itemLocation,
        effectiveVendorCommissionPercent,
        effectiveVendorCommissionCents,
        effectiveListingFeeCents,
        effectiveBuyersPremiumPercent,
        effectiveBuyersPremiumCents,
        gstStatus,
        consignedFrom,
        weighedCount,
        openAuctionCount,
        weightRangeFormatted: weightRangeString,
        dressingRangeFormatted: dressingRangeString,
        weightRange,
        dressingRange,
      });
    });
    if (result.length) {
      return result;
    }
    return EMPTY_ARRAY;
  },
);

export const getConsignmentSaleLots = createSelector(
  [getConsignmentId, getSaleLotsBySale],
  (consignmentId, saleLots) =>
    Object.values(saleLots).filter(lot => lot.consignment_id === consignmentId),
);

export const getConsignmentSaleLotsSortedByWeight = createSelector(
  [getConsignmentSaleLots],
  saleLots =>
    sortBy(saleLots, s =>
      s.quantity !== 0 && s.total_mass_grams !== 0
        ? s.total_mass_grams / (s.total_mass_grams / s.quantity)
        : 0,
    ),
);

const groupSaleLotsByBuyerWay = saleLots => {
  const groupedByBuyerWay = groupBy(saleLots, "buyerWayName");
  return Object.values(groupedByBuyerWay).map(saleLots => ({
    id: saleLots[0].buyerWayId,
    buyerWayName: saleLots[0].buyerWayName || "-",
    buyerWayRawName: saleLots[0].buyerWayName || undefined,
    saleLots,
    quantity: sumBy(saleLots, "quantity"),
    quantityDelivered: sumBy(saleLots, "quantityDelivered") || 0,
    status: getStatusForSaleLots(saleLots),
    hasExceptions: saleLots.some(saleLot => saleLotHasException(saleLot)),
    hasOverflowPen: saleLots.some(saleLot =>
      doesSaleLotHaveOverflowPen(saleLot),
    ),
    isAnyWeighed: saleLots.some(saleLot => saleLot.isWeighed),
    isAnyUnweighed: saleLots.some(saleLot => !saleLot.isWeighed),
    PICs: saleLots
      .filter(sl => sl.destinationProperty)
      .map(sl => sl.destinationProperty),
  }));
};
const groupSaleLotsByBuyerAndWay = (
  saleLots,
  agencyByConsignmentIdLookup = {},
  isWeighedBySaleLotIdLookup,
  currentSale,
) => {
  const filteredSaleLots = saleLots
    .filter(saleLot => saleLot.buyer && !!saleLot.buyer.name)
    .map(sl =>
      formatSaleLot(
        sl,
        {},
        agencyByConsignmentIdLookup,
        {},
        null,
        isWeighedBySaleLotIdLookup,
        currentSale,
      ),
    );

  const groupedSaleLots = groupBy(filteredSaleLots, "buyerId");
  return Object.values(groupedSaleLots).map(saleLots => ({
    id: saleLots[0].buyerId || "",
    saleLots,
    buyerName: saleLots[0].buyerName,
    masterName: saleLots[0].buyer.name,
    hasAgriNousUser: saleLots[0].hasAgriNousUser,
    quantity: sumBy(saleLots, "quantity"),
    averageWeight: calculateAverageWeightAndFormat(saleLots),
    averageCost: calculateAveragePriceAndFormat(
      saleLots,
      currentSale?.pricing_type_id,
    ),
    isAnyWeighed: saleLots.some(saleLot => saleLot.isWeighed),
    isAnyUnweighed: saleLots.some(saleLot => !saleLot.isWeighed),
    species: currentSale?.species_id || 0,
    quantityDelivered: sumBy(saleLots, "quantityDelivered") || 0,
    status: getStatusForSaleLots(saleLots),
    hasExceptions: saleLots.some(saleLot => saleLotHasException(saleLot)),
    hasOverflowPen: saleLots.some(saleLot =>
      doesSaleLotHaveOverflowPen(saleLot),
    ),
    buyerWays: groupSaleLotsByBuyerWay(saleLots),
    PICs: saleLots
      .filter(uniqueByPropertyFilter("destinationProperty"))
      .map(sl => sl.destinationProperty),
    thirdPartyName: saleLots[0].thirdPartyName,
  }));
};
export const groupSaleLotsByThirdPartyBuyerAndBuyerWay = (
  saleLots,
  agencyByConsignmentIdLookup,
  isWeighedBySaleLotIdLookup,
  currentSale,
) => {
  const groupedSaleLots = groupBy(saleLots, "thirdPartyId");
  return Object.values(groupedSaleLots).map(tpSaleLots => {
    const buyers = groupSaleLotsByBuyerAndWay(
      tpSaleLots,
      agencyByConsignmentIdLookup,
      isWeighedBySaleLotIdLookup,
      currentSale,
    );
    return {
      id: tpSaleLots[0].thirdPartyId,
      thirdPartyName: tpSaleLots[0].thirdPartyName,
      quantity: sumBy(buyers, "quantity"),
      quantityDelivered: sumBy(buyers, "quantityDelivered") || 0,
      status: getStatusForSaleLots(buyers),
      hasExceptions: buyers.some(buyer => buyer.hasExceptions),
      hasOverflowPen: buyers.some(buyer => buyer.hasOverflowPen),
      buyers,
    };
  });
};

export const getSaleLotsByBuyer = createSelector(
  [
    getSaleLotsBySale,
    selectAgencyByConsignmentIdLookup,
    selectIsWeighedBySaleLotIdLookup,
    getCurrentSale,
    selectFilteredSaleLotIds,
  ],
  (
    saleLots,
    agencyByConsignmentIdLookup,
    isWeighedBySaleLotIdLookup,
    currentSale,
    filteredSaleLotIds,
  ) =>
    groupSaleLotsByThirdPartyBuyerAndBuyerWay(
      saleLots.filter(saleLot => filteredSaleLotIds.includes(saleLot.id)),
      agencyByConsignmentIdLookup,
      isWeighedBySaleLotIdLookup,
      currentSale,
    ),
);
export const getSaleLotsByBuyerByRound = createSelector(
  [
    getSaleLotsBySale,
    selectAgencyByConsignmentIdLookup,
    selectIsWeighedBySaleLotIdLookup,
    getCurrentSale,
    selectFilteredSaleLotIds,
  ],
  (
    saleLots,
    agencyByConsignmentIdLookup,
    isWeighedBySaleLotIdLookup,
    currentSale,
    filteredSaleLotIds,
  ) => {
    const saleLotsByRound = groupBy(saleLots, "sale_round_id");
    const rounds = Object.keys(saleLotsByRound);
    return rounds.reduce((acc, round) => {
      acc[round] = groupSaleLotsByThirdPartyBuyerAndBuyerWay(
        saleLotsByRound[round].filter(saleLot =>
          filteredSaleLotIds.includes(saleLot.id),
        ),
        agencyByConsignmentIdLookup,
        isWeighedBySaleLotIdLookup,
        currentSale,
      );
      return acc;
    }, {});
  },
);

export const selectSaleLotIdByCombinedLotNumberLookup = createSelector(
  [getSaleLots],
  saleLots =>
    Object.values(saleLots).reduce((acc, saleLot) => {
      acc[getCombinedLotNumber(saleLot)] = saleLot.id;
      return acc;
    }, {}),
);

export const getSaleLotIdByCombinedLotNumber = combinedLotNumber => state =>
  selectSaleLotIdByCombinedLotNumberLookup(state)[combinedLotNumber] || null;

// Create a lookup of { buyerId : consignmentId : [saleLotIds] }
export const selectConsignmentIdsSaleLotIdsByBuyerIdLookup = createSelector(
  [getSaleLots],
  saleLots => {
    return Object.values(saleLots).reduce(
      (acc, saleLot) => ({
        ...acc,
        [saleLot.buyer_id]: {
          ...acc[saleLot.buyer_id],
          [saleLot.consignment_id]: [
            ...(acc[saleLot.buyer_id]?.[saleLot.consignment_id] || EMPTY_ARRAY),
            saleLot.id,
          ],
        },
      }),
      {},
    );
  },
);

export const selectUnknownSaleLotIdsByDeploymentSaleIdLookup = createSelector(
  [
    selectUnknownConsignmentIdsByDeploymentSaleIdLookup,
    selectSaleLotIdsByConsignmentIdLookup,
  ],
  (
    unknownConsignmentIdsByDeploymentSaleIdLookup,
    saleLotIdsByConsignmentIdLookup,
  ) =>
    Object.entries(unknownConsignmentIdsByDeploymentSaleIdLookup).reduce(
      (lookup, [deploymentSaleId, unknownConsignmentIds]) => {
        lookup[deploymentSaleId] = flatten(
          unknownConsignmentIds.map(
            consignmentId =>
              saleLotIdsByConsignmentIdLookup[consignmentId] || EMPTY_ARRAY,
          ),
        );
        return lookup;
      },
      {},
    ),
);

export const getUnknownSaleLotIdsByDeploymentSaleId =
  deploymentSaleId => state =>
    selectUnknownSaleLotIdsByDeploymentSaleIdLookup(state)[deploymentSaleId];

export const selectIsVendorBredBySaleLotIdLookup = createSelector(
  [getSaleLots, getConsignments],
  (saleLots, consignments) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const consignment = consignments[saleLot.consignment_id] || {};
      if (saleLot.draftingAttributes?.vendorBredOverride) {
        acc[saleLotId] =
          saleLot.draftingAttributes?.vendorBredOverride === Accreditation.ALL;
      } else {
        acc[saleLotId] = consignment.declaration?.owned_since_birth;
      }
      return acc;
    }, {}),
);

export const selectIsEuEligibleBySaleLotIdLookup = createSelector(
  [getSaleLots, getConsignments],
  (saleLots, consignments) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const consignment = consignments[saleLot.consignment_id] || {};
      if (saleLot.draftingAttributes?.accreditationEU) {
        acc[saleLotId] =
          saleLot.draftingAttributes?.accreditationEU === Accreditation.ALL;
      } else {
        acc[saleLotId] =
          consignment.declaration?.VERSION === DeclarationTypes.E0413 ||
          consignment.declaration?.VERSION === DeclarationTypes.E0720;
      }
      return acc;
    }, {}),
);

export const getIsVendorBredBySaleLotId = saleLotId => state =>
  selectIsVendorBredBySaleLotIdLookup(state)[saleLotId] || null;

export const selectSaleLotIdsByThirdPartyAndBuyerWayLookup = createSelector(
  [getSaleLots],
  saleLots =>
    Object.entries(saleLots).reduce((lookup, [saleLotId, saleLot]) => {
      const thirdPartyId = saleLot.thirdPartyId || null;
      const buyerHash = getBuyerHashFromSaleLot(saleLot);
      if (!lookup[thirdPartyId]) {
        lookup[thirdPartyId] = {};
      }
      if (!lookup[thirdPartyId][buyerHash]) {
        lookup[thirdPartyId][buyerHash] = [];
      }
      lookup[thirdPartyId][buyerHash].push(saleLotId);
      return lookup;
    }, {}),
);

export const getSaleLotIdsByThirdPartyAndBuyerWayLookup =
  (thirdPartyId, buyerHash) => state =>
    selectSaleLotIdsByThirdPartyAndBuyerWayLookup(state)[thirdPartyId][
      buyerHash
    ] || EMPTY_ARRAY;

function statusBySaleLotIdReducer(saleLot, consignments) {
  return getSaleLotStatus(saleLot, consignments[saleLot.consignment_id]);
}

export const [selectStatusBySaleLotIdLookup, getStatusBySaleLotId] =
  createLookupSelectors(
    [getSaleLots, getConsignments],
    createLookupCombiner(statusBySaleLotIdReducer),
  );
export const selectBuyerHashBySaleLotIdLookup = createSelector(
  [getSaleLots],
  saleLots =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      acc[saleLotId] = getBuyerHashFromSaleLot(saleLot);
      return acc;
    }, {}),
);

export const getBuyerHashBySaleLotId = saleLotId => state =>
  selectBuyerHashBySaleLotIdLookup(state)[saleLotId] || null;

export const selectSaleLotIdsByBuyerHashLookup = createSelector(
  [selectBuyerHashBySaleLotIdLookup],
  buyerAndBuyerWayHashBySaleLotIdLookup =>
    Object.entries(buyerAndBuyerWayHashBySaleLotIdLookup).reduce(
      (acc, [saleLotId, buyerAndBuyerWayHash]) => {
        const saleLotIds = acc[buyerAndBuyerWayHash] || [];
        saleLotIds.push(saleLotId);
        acc[buyerAndBuyerWayHash] = saleLotIds;
        return acc;
      },
      {},
    ),
);

function thirdPartyIdsByBuyerHashCombiner(saleLotIds, saleLots) {
  return uniq(saleLotIds.map(saleLotId => saleLots[saleLotId].thirdPartyId));
}

export const [
  selectThirdPartyIdsByBuyerHashLookup,
  getThirdPartyIdsByBuyerHash,
] = createLookupSelectors(
  [selectSaleLotIdsByBuyerHashLookup, getSaleLots],
  createLookupCombiner(thirdPartyIdsByBuyerHashCombiner),
);

export const selectPrimaryMarkBySaleLotIdLookup = createSelector(
  [
    selectDeploymentMarkOrderLookup,
    selectDeploymentIdByConsignmentIdLookup,
    getSaleLots,
  ],
  (deploymentMarkOrderLookup, deploymentIdByConsignmentIdLookup, saleLots) => {
    return Object.values(saleLots).reduce((acc, saleLot) => {
      const deploymentId =
        deploymentIdByConsignmentIdLookup[saleLot.consignment_id];
      // If there are no deployment marks or the salelot has no marks, set to null
      if (
        !deploymentMarkOrderLookup[deploymentId] ||
        saleLot?.marks.length === 0
      ) {
        acc[saleLot.id] = null;
        return acc;
      }
      const marksWithOrder = saleLot.marks.map(mark => ({
        mark,
        order: deploymentMarkOrderLookup[deploymentId][mark.location],
      }));
      if (marksWithOrder.length === 0) {
        acc[saleLot.id] = null;
      } else {
        acc[saleLot.id] = sortBy(marksWithOrder, "order")[0].mark;
      }
      return acc;
    }, {});
  },
);

const imageCountBySaleLotIdReducer = (attachmentIds, attachments) =>
  attachmentIds.filter(
    attachmentId => attachments[attachmentId]?.type === DocumentTypes.IMAGE,
  ).length;

export const [selectImageCountBySaleLotIdLookup, getImageCountBySaleLotId] =
  createLookupSelectors(
    [selectAttachmentIdsBySaleLotIdLookup, getFiles],
    createLookupCombiner(imageCountBySaleLotIdReducer),
    0,
  );

const scannedStatusBySaleLotIdLookup = (
  saleLot,
  scannedCountBySaleLotIdLookup,
  currentSale,
  currentSaleyard,
) =>
  getSaleLotScannedStatus(
    saleLot,
    scannedCountBySaleLotIdLookup[saleLot.id],
    getScanStatusThreshold(currentSale.species_id, currentSaleyard),
  );

export const [
  selectScannedStatusBySaleLotIdLookup,
  getScannedStatusBySaleLotId,
] = createLookupSelectors(
  [
    getSaleLots,
    selectScannedCountBySaleLotIdLookup,
    getCurrentSale,
    getCurrentSaleyard,
  ],
  createLookupCombiner(scannedStatusBySaleLotIdLookup),
  ScanStatus.PASS,
);

export const selectUnpennedSaleLotIdsBySaleRoundIdLookup = createSelector(
  [getSaleLots, selectSaleLotIdsByAuctionPenIdLookup],
  (saleLots, saleLotIdsByAuctionPenIdLookup) => {
    return (saleLotIdsByAuctionPenIdLookup.null || [])
      .map(id => saleLots[id])
      .reduce((acc, cur) => {
        if (acc[cur.sale_round_id]) {
          acc[cur.sale_round_id].push(cur.id);
        } else {
          acc[cur.sale_round_id] = [cur.id];
        }

        return acc;
      }, {});
  },
);

export const getUnpennedSaleLotIdsBySaleRoundId = saleRoundId => state =>
  selectUnpennedSaleLotIdsBySaleRoundIdLookup(state)[saleRoundId] || null;

export const selectSaleLotIdsByMarkOrder = createSelector(
  [
    getSaleLots,
    selectPrimaryMarkBySaleLotIdLookup,
    selectDeploymentMarkOrderLookup,
    selectDeploymentIdBySaleLotIdLookup,
  ],
  (
    saleLots,
    primaryMarkBySaleLotIdLookup,
    deploymentMarkOrderLookup,
    deploymentIdBySaleLotId,
  ) => {
    return Object.values(saleLots)
      .sort((a, b) => {
        if (!isEmpty(deploymentMarkOrderLookup)) {
          const aOrder = deploymentMarkOrderLookup[
            deploymentIdBySaleLotId[a.id]
          ]
            ? deploymentMarkOrderLookup[deploymentIdBySaleLotId[a.id]][
                primaryMarkBySaleLotIdLookup[a.id]?.location
              ]
            : 0;
          const bOrder = deploymentMarkOrderLookup[
            deploymentIdBySaleLotId[b.id]
          ]
            ? deploymentMarkOrderLookup[deploymentIdBySaleLotId[b.id]][
                primaryMarkBySaleLotIdLookup[b.id]?.location
              ]
            : 0;
          if (typeof aOrder !== "number" && typeof bOrder !== "number") {
            return 0;
          } else if (typeof bOrder !== "number") {
            return -1;
          } else if (typeof aOrder !== "number") {
            return 0;
          }
          return aOrder - bOrder;
        } else {
          return 0;
        }
      })
      .map(obj => obj.id);
  },
);

export const getVendorSplitSaleLotIdsByParentSaleLotId = saleLotId => state =>
  selectVendorSplitSaleLotIdsByParentSaleLotIdLookup(state)[saleLotId] ||
  EMPTY_ARRAY;

const maxVendorSplitSubLotNumberReducer = (
  vendorSplitSaleLotIds,
  vendorSplitSaleLots,
) =>
  max(
    vendorSplitSaleLotIds.map(
      vendorSplitSaleLotId =>
        vendorSplitSaleLots[vendorSplitSaleLotId].subLotNumber || 0,
    ),
  );

export const [
  selectMaxVendorSplitSubLotNumberByParentSaleLotIdLookup,
  getMaxVendorSplitSubLotNumberByParentSaleLotId,
] = createLookupSelectors(
  [selectVendorSplitSaleLotIdsByParentSaleLotIdLookup, getVendorSplitSaleLots],
  createLookupCombiner(maxVendorSplitSubLotNumberReducer),
  0,
);

const selectSelectableVendorSplitConsignmentOptionsBySaleLotIdLookup =
  createSelector(
    [
      selectVendorSplitSaleLotIdsByParentSaleLotIdLookup,
      selectVendorSplitConsignmentIdsByParentConsignmentIdLookup,
      getSaleLots,
      getBusinesses,
      getVendorSplitConsignments,
    ],
    (
      vendorSplitSaleLotIdsByParentSaleLotIdLookup,
      vendorSplitConsignmentIdsByParentConsignmentIdLookup,
      saleLots,
      businesses,
      vendorSplitConsignments,
    ) => {
      return Object.values(saleLots).reduce((acc, saleLot) => {
        const saleLotId = saleLot.id;
        acc[saleLotId] =
          vendorSplitConsignmentIdsByParentConsignmentIdLookup[
            saleLot.consignment_id
          ]?.map(vendorSplitConsignmentId => ({
            label:
              businesses[
                vendorSplitConsignments[vendorSplitConsignmentId]?.vendor_id
              ]?.name,
            value: vendorSplitConsignmentId,
          })) || [];

        return acc;
      }, {});
    },
  );

export const getSelectableVendorSplitConsignmentOptionsBySaleLotId =
  saleLotId => state =>
    selectSelectableVendorSplitConsignmentOptionsBySaleLotIdLookup(state)[
      saleLotId
    ] || EMPTY_ARRAY;

function splitTotalsByConsignmentIdReducer(
  saleLotIds,
  saleLots,
  vendorSplitSaleLotIdsByParentSaleLotIdLookup,
) {
  return saleLotIds.reduce(
    (acc, saleLotId) => {
      if (vendorSplitSaleLotIdsByParentSaleLotIdLookup[saleLotId]?.length) {
        acc.splitSaleLots += 1;
        acc.splitQuantity += saleLots[saleLotId].quantity;
      } else {
        acc.unSplitSaleLots += 1;
        acc.unSplitQuantity += saleLots[saleLotId].quantity;
      }
      return acc;
    },
    {
      splitQuantity: 0,
      splitSaleLots: 0,
      unSplitQuantity: 0,
      unSplitSaleLots: 0,
    },
  );
}

export const [
  selectSplitTotalsByConsignmentIdLookup,
  getSplitTotalsByConsignmentIdLookup,
] = createLookupSelectors(
  [
    selectSaleLotIdsByConsignmentIdLookup,
    getSaleLots,
    selectVendorSplitSaleLotIdsByParentSaleLotIdLookup,
  ],
  createLookupCombiner(splitTotalsByConsignmentIdReducer),
);

export const xeroBusinessesBySaleLotIdReducer = (saleLots, businesses) =>
  Object.values(saleLots).reduce((acc, saleLot) => {
    const { buyer = {}, vendor = {} } = saleLot;
    const invoiceToBusiness = businesses[saleLot.invoiceToBusinessId] || null;
    const xeroBuyerBusiness = invoiceToBusiness || buyer;
    const { integrationBusinesses: buyerIntegrationBusinesses = [] } =
      xeroBuyerBusiness;
    const { integrationBusinesses: vendorIntegrationBusinesses = [] } = vendor;
    const xeroBuyer = buyerIntegrationBusinesses.find(
      integrationBusiness => integrationBusiness.type === IntegrationTypes.Xero,
    );
    const xeroVendor = vendorIntegrationBusinesses.find(
      integrationBusiness => integrationBusiness.type === IntegrationTypes.Xero,
    );
    acc[saleLot.id] = { buyer: xeroBuyer, vendor: xeroVendor };
    return acc;
  }, {});

export const [
  selectXeroBusinessesBySaleLotIdLookup,
  getXeroBusinessesBySaleLotId,
] = createLookupSelectors(
  [getSaleLotsBySale, getBusinesses],
  xeroBusinessesBySaleLotIdReducer,
);

const createLabel = (saleLot, consignment, vendor, property, pen) => {
  let label = `#${saleLot.lot_number}: `;

  if (pen) {
    label += `Pen ${getAuctionPenDisplayName(pen)} `;
  }
  label += `Hd. ${saleLot.quantity} `;
  label += getMarksLocationString(saleLot);

  return label;
};
export const selectSaleLotOptions = createSelector(
  [getSaleLots, getConsignments, getBusinesses, getProperties, getAuctionPens],
  (
    saleLotByIdLookup,
    consignmentByIdLookup,
    businessesByIdLookup,
    propertyByIdLookup,
    penByIdLookup,
  ) => {
    return sortBy(
      Object.values(saleLotByIdLookup).map(saleLot => {
        const consignment = consignmentByIdLookup[saleLot.consignment_id] || {};
        const vendor = businessesByIdLookup[consignment.vendor_id];
        const vendorProperty =
          propertyByIdLookup[consignment.vendor_property_id] || {};
        const sellingPen = penByIdLookup[saleLot.auction_pen_id];
        return {
          value: saleLot.id,
          vendorName: `${
            consignment.livestock_agency_code
          }${consignment.vendorNumber || ""} ${
            vendor?.name
          } ${consignment.NVD || ""} ${vendorProperty.PIC || ""}`,
          label: createLabel(
            saleLot,
            consignment,
            vendor,
            vendorProperty,
            sellingPen,
          ),
        };
      }),
      "vendorName",
    );
  },
);

export const getNonSkinsSaleLots = createSelector(
  [getSaleLots, getSkinsSexId],
  (saleLotsByIdLookup, skinsSexId) => {
    return Object.values(saleLotsByIdLookup).filter(
      saleLot => saleLot.sex_id !== skinsSexId,
    );
  },
);
