import React from "react";

import { Field, getIn } from "formik";
import { useSelector } from "react-redux";

import { SelectField, withNamespace } from "components/Form/FormikControls";
import { RuleFieldToolTip } from "components/Form/FormikControls/Tooltip";
import { Row } from "components/Layout";

import { groupedLineItemToolTipText } from "constants/billing";
import { RuleValueFormatOptionsByRawValueFormat } from "constants/ruleBooks";

import { RuleOutputFormatField } from "containers/Settings/RuleBooks/Rules/CustomRules/RuleOutputFormatField";

import { selectLedgerAccountOptionsForRuleBuilder } from "selectors";

import { useFieldValue } from "hooks";

export function CustomiseManagedRuleForm(props) {
  const { namespace: ns } = props;

  const LedgerAccountOptions = useSelector(
    selectLedgerAccountOptionsForRuleBuilder,
  );

  const value = useFieldValue(ns);

  const unitAmountRawFormat = getIn(value, "unit_amount_raw_format");
  const unitAmountOutputFormatOptions =
    RuleValueFormatOptionsByRawValueFormat[unitAmountRawFormat] || [];

  const quantityRawFormat = getIn(value, "quantity_raw_format");
  const quantityOutputFormatOptions =
    RuleValueFormatOptionsByRawValueFormat[quantityRawFormat] || [];

  return (
    <>
      <Row full alignCenter paddingVertical={1}>
        <span>Name:</span>&nbsp;
        <Field
          data-tour="name"
          className="p-2 flex-1"
          name={withNamespace(ns, "name")}
        />
      </Row>

      <Row full alignCenter paddingVertical={1}>
        <span>Comment:</span>&nbsp;
        <Field
          data-tour="comment"
          className="p-2 flex-1"
          name={withNamespace(ns, "comment")}
          as="textarea"
        />
      </Row>
      <Row full alignCenter paddingVertical={1}>
        <span>Title Template:</span>&nbsp;
        <Field
          data-tour="titleTemplate"
          className="p-2 flex-1"
          name={withNamespace(ns, "title_template")}
        />
      </Row>
      <Row full alignCenter paddingVertical={1}>
        <span>Document Grouped Line Description:</span>&nbsp;
        <RuleFieldToolTip title={groupedLineItemToolTipText}>
          <Field
            data-tour="invoiceLineItemTemplate"
            className="p-2 flex-1"
            name={withNamespace(ns, "invoice_line_item_template")}
          />
        </RuleFieldToolTip>
      </Row>
      <Row full alignCenter paddingVertical={1}>
        <span>GL Code:</span>&nbsp;
        <SelectField
          data-tour="glCode"
          name={withNamespace(ns, "gl_code")}
          required
          options={LedgerAccountOptions}
          menuPortalTarget={document.body}
        />
      </Row>

      <span>Units Format: </span>
      <RuleOutputFormatField
        name="quantity_output_format"
        options={quantityOutputFormatOptions}
      />
      <span>Unit Price Format: </span>
      <RuleOutputFormatField
        name="unit_amount_output_format"
        options={unitAmountOutputFormatOptions}
      />
    </>
  );
}
