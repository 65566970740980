import { sumBy } from "lodash";
import isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { patchSaleLot } from "actions";

import { addSaleLot } from "actions/offline";

import { PricingTypes } from "constants/pricingTypes";

import { calculateTotalPriceCents, removeNulls } from "lib";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

import {
  getConsignments,
  getConsignmentSaleLotsSortedByWeight,
} from "selectors";

import { getSkinsSexId } from "selectors/sexes";

import Dialog from "./dialog";

const mapStateToProps = (state, props) => {
  const { consignmentId } = props;

  return {
    saleLots: getConsignmentSaleLotsSortedByWeight(state, consignmentId),
    consignmentId,
    consignment: getConsignments(state)[consignmentId],
    skinsSexId: getSkinsSexId(state),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { returnTo } = ownProps;
  const { addSaleLot, patchSaleLot } = dispatchProps;
  const { saleLots, consignment, skinsSexId } = stateProps;
  const referenceSaleLot = saleLots[0];

  const saleLotsWithDecimals = saleLots.map(s => {
    return {
      ...s,
      dollars_per_kilo: +(
        s.total_price_cents /
        100 /
        s.total_mass_grams /
        1000
      ).toFixed(2),
      total_mass_grams: s.total_mass_grams,
    };
  });

  const onClose = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  const handleSubmit = values => {
    // on submit we need to assess lot values and update them as needed
    // and at the same time create or update a lot with the sex of skins.
    // when the quantity is updated for any non skin lots the quantity of the
    // skins lot should be updated to match. (Though this can also be edited)
    // There should only ever be one skins lot for each consignment.

    const { saleLots, skinsSaleLotId, skin_price, skinQuantity } = values;

    const pricingType = saleLots[0].pricing_type_id;

    saleLots.forEach(sl => {
      const totalPriceCents = calculateTotalPriceCents({
        unitPrice: sl.unit_price ? parseFloat(sl.unit_price) : 0,
        quantity: sl.quantity,
        pricing_type_id: pricingType,
        total_mass_grams: sl.total_mass_grams,
      });

      const formValuesForPatch = {
        id: sl.id,
        quantity: sl.quantity,
        pricing_type_id: pricingType,
        total_price_cents: totalPriceCents,
        total_mass_grams: sl.total_mass_grams,
      };

      const saleLotWithIntegers = {
        ...sl,
        ...formValuesForPatch,
      };

      if (sl.id) {
        // Only patch the salelot if it has been updated.
        const original = saleLotsWithDecimals.find(
          saleLot => saleLot.id === saleLotWithIntegers.id,
        );

        if (!isEqual(saleLotWithIntegers, original)) {
          // Only update the values that are in the form.
          patchSaleLot(formValuesForPatch);
        }
      } else {
        const tempSaleLotId = uuidv4();
        const payload = removeNulls({
          ...referenceSaleLot,
          ...saleLotWithIntegers,
        });
        addSaleLot(payload, tempSaleLotId);
      }
    });
    // create or update skins lot
    const skinsQuantity = skinQuantity || sumBy(saleLots, "quantity");

    if (skinsSaleLotId) {
      patchSaleLot({
        id: skinsSaleLotId,
        quantity: skinsQuantity,
        total_price_cents: skin_price * skinsQuantity,
        pricing_type_id: PricingTypes.PER_HEAD,
      });
    } else {
      const skinsPayload = removeNulls({
        ...referenceSaleLot,
        total_price_cents: skin_price * skinsQuantity,
        quantity: skinsQuantity,
        sex_id: skinsSexId,
        breed_id: null,
        pricing_type_id: PricingTypes.PER_HEAD,
      });
      const skinsTempSaleLotId = uuidv4();
      addSaleLot(skinsPayload, skinsTempSaleLotId);
    }
    onClose();
  };

  return {
    handleSubmit,
    saleLots: saleLotsWithDecimals,
    onClose,
    consignment,
    referenceSaleLot,
  };
};

const mapDispatchToProps = {
  addSaleLot,
  patchSaleLot,
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Dialog);
