import { v4 } from "uuid";

import { RuleValueFormat } from "constants/ruleBooks";

import toast from "lib/toast";

import {
  ALL_VALUES_FILTER_PATH,
  CUSTOM_QUERY_FIELD_ID,
  FieldValueType,
  ValueSource,
  ValueSourceExt,
} from "./constants";
import {
  getSchemaField,
  getSchemaFieldValues,
  getValueFormatByFieldType,
} from "./lib";

export function convertToExternalSchemaFieldDataSource(
  internalDataSource,
  schema,
  parentFieldId,
) {
  const { customQuery, attribute, fieldId, uniq } = internalDataSource;
  if (
    fieldId === CUSTOM_QUERY_FIELD_ID
    // this will always be CUSTOM_QUERY_FIELD_ID when the parentFieldId is also a CUSTOM_QUERY_FIELD_ID
  ) {
    return {
      source: ValueSourceExt.FIELD,
      value: customQuery,
      key: attribute,
      value_type: internalDataSource.valueType,
    };
  } else if (fieldId && parentFieldId) {
    const relation = getSchemaField(parentFieldId, schema).relations.find(
      relation => (uniq ? relation.uniq === uniq : relation.id === fieldId),
    );

    return {
      key: attribute,
      source: ValueSourceExt.FIELD,
      value: relation.via,
      uniq: relation.uniq,
      value_type: internalDataSource.valueType,
    };
  } else if (fieldId) {
    const schemaField = getSchemaField(fieldId, schema);
    return {
      key: attribute,
      source: ValueSourceExt.FIELD,
      value: `${schemaField.path}${ALL_VALUES_FILTER_PATH}`,
      uniq: schemaField.uniq,
      value_type: internalDataSource.valueType,
    };
  }

  return {
    source: ValueSourceExt.FIELD,
    value: attribute,
    value_type: internalDataSource.valueType,
  };
}

function convertToExternalCriteriaOutputIndex(internalIndex) {
  const typeOfIndex = typeof internalIndex;
  const criteriaOutputIndex =
    typeOfIndex === "string"
      ? parseInt(internalIndex, 10)
      : typeOfIndex === "number"
        ? internalIndex
        : null;
  if (
    typeof criteriaOutputIndex !== "number" ||
    Number.isNaN(criteriaOutputIndex)
  ) {
    // eslint-disable-next-line no-console
    console.error(
      `Could not resolve criteria output index ${typeOfIndex} "${internalIndex}", ignoring`,
    );
    return;
  }
  return criteriaOutputIndex;
}

export function convertToExternalCountDataSource(internalDataSource) {
  return {
    source: ValueSourceExt.COUNT,
    criteria_output_index: convertToExternalCriteriaOutputIndex(
      internalDataSource.index,
    ),
  };
}

export function convertToExternalSumDataSource(internalDataSource) {
  return {
    source: ValueSourceExt.SUM,
    value: internalDataSource.attribute,
    criteria_output_index: convertToExternalCriteriaOutputIndex(
      internalDataSource.index,
    ),
    value_type: internalDataSource.valueType,
  };
}

export function convertToExternalConstantDataSource(internalDataSource) {
  return {
    source: ValueSourceExt.CONSTANT,
    value: internalDataSource.value,
    value_type: internalDataSource.valueType,
  };
}

export function convertToExternalGlobalBusinessDataSource(internalDataSource) {
  return {
    source: ValueSourceExt.GLOBAL_BUSINESS,
    value: internalDataSource.value,
    value_type: internalDataSource.valueType,
  };
}

function convertToExternalDataSource(dataSource, schema, parentFieldId) {
  if (dataSource.source === ValueSource.FIELD) {
    return convertToExternalSchemaFieldDataSource(
      dataSource,
      schema,
      parentFieldId,
    );
  } else if (dataSource.source === ValueSource.CONSTANT) {
    return convertToExternalConstantDataSource(dataSource);
  } else if (dataSource.source === ValueSource.SUM) {
    return convertToExternalSumDataSource(dataSource);
  } else if (dataSource.source === ValueSource.COUNT) {
    return convertToExternalCountDataSource(dataSource);
  } else if (dataSource.source === ValueSource.GLOBAL_BUSINESS) {
    return convertToExternalGlobalBusinessDataSource(dataSource);
  }
  // eslint-disable-next-line no-console
  console.error(
    'Unknown data source type "%s", passing through',
    dataSource.source,
  );
  return dataSource;
}

function parseBooleanCheckValue(fieldValue) {
  // the source of the data could be an `<option>` value, which returns as a string
  // or an `input type="checkbox"`, which returns a boolean.
  if (fieldValue === "true" || fieldValue === true || fieldValue === 1) {
    return true;
  } else if (
    fieldValue === "false" ||
    fieldValue === false ||
    fieldValue === 0
  ) {
    return false;
  }
  // eslint-disable-next-line no-console
  console.warn("Unexpected Boolean value %s", fieldValue);
  // this is not expected
  return fieldValue;
}

function parseCheckValue(fieldType, fieldValue) {
  if (
    fieldType === FieldValueType.BOOLEAN ||
    fieldType === FieldValueType.GST_APPLICABILITY_BOOLEAN
  ) {
    return parseBooleanCheckValue(fieldValue);
  }
  return fieldValue;
}

export function convertToExternalCheck(
  internalCheck,
  index,
  parentFieldId,
  schema,
) {
  const externalCheck = {
    comparator: internalCheck.comparator,
    field: convertToExternalDataSource(
      internalCheck.field,
      schema,
      parentFieldId,
    ),
    values: internalCheck.values,
  };
  if (
    internalCheck.field.source === ValueSource.FIELD &&
    // This check is fairly save as we have JUST resolved the field from a schema field
    internalCheck.field.fieldId !== CUSTOM_QUERY_FIELD_ID
  ) {
    // Perform all type conversion here
    const field = getSchemaFieldValues(
      internalCheck.field.fieldId || parentFieldId,
      schema,
      false,
    ).find(
      field => field.isProperty && field.id === internalCheck.field.attribute,
    );
    if (field) {
      externalCheck.values = internalCheck.values.map(value =>
        parseCheckValue(field.type, value),
      );
    }
  }
  return externalCheck;
}

export function convertToExternalChecks(
  internalCheckGroups,
  parentFieldId,
  schema,
) {
  return internalCheckGroups.map((internalChecks, index) =>
    internalChecks.map(internalCheck =>
      convertToExternalCheck(internalCheck, index, parentFieldId, schema),
    ),
  );
}

export function convertToExternalCriterion(
  internalCriterion,
  index,
  schema,
  parentFieldId,
) {
  let checkParentFieldId = parentFieldId;

  let query;
  if (
    internalCriterion.query &&
    internalCriterion.query.fieldId &&
    internalCriterion.query.fieldId !== CUSTOM_QUERY_FIELD_ID
  ) {
    const internalQuery = internalCriterion.query;

    const rootField = getSchemaField(parentFieldId, schema);
    const criterionRoot = getSchemaField(internalQuery.fieldId, schema);

    if (internalQuery.via) {
      query = {
        path: `${internalQuery.via}${ALL_VALUES_FILTER_PATH}`,
        query_field: internalQuery.via,
        input_field: rootField.idAttribute,
      };

      checkParentFieldId = criterionRoot.id;
    } else {
      const relationRoot = criterionRoot.relations.find(
        field => field.id === parentFieldId,
      );

      query = {
        path: `${criterionRoot.path}${ALL_VALUES_FILTER_PATH}`,
        query_field: relationRoot.via,
        input_field: rootField.idAttribute,
      };

      checkParentFieldId = internalQuery.fieldId;
    }
  }

  return {
    checks: convertToExternalChecks(
      internalCriterion.checkGroups,
      checkParentFieldId,
      schema,
    ),
    filter: true,
    query,
  };
}

export function convertToExternalCriteria(
  internalCriteria,
  schema,
  parentFieldId,
) {
  return internalCriteria.map((internalCriterion, index) =>
    convertToExternalCriterion(internalCriterion, index, schema, parentFieldId),
  );
}
export function convertToExternalFee(
  internalFee,
  index,
  schema,
  parentFieldId,
) {
  const fee = {
    apportion_from_source: internalFee.apportionFromSource,
    enabled_query: convertToExternalDataSource(
      internalFee.enabledQuery,
      schema,
      parentFieldId,
    ),
    name: internalFee.name,
    operation: internalFee.operation,
    operation_input: convertToExternalDataSource(
      internalFee.operationInput,
      schema,
      parentFieldId,
    ),
  };
  if (
    typeof internalFee.sourceIndex === "number" &&
    internalFee.sourceIndex >= 0 &&
    internalFee.sourceIndex < index + 1
  ) {
    fee.source_index = internalFee.sourceIndex;
  }
  return fee;
}

export function convertToExternalFees(internalFees, schema, parentFieldId) {
  return internalFees.map((internalFee, index) =>
    convertToExternalFee(internalFee, index, schema, parentFieldId),
  );
}

export function convertToExternalRule(internalRule, schema) {
  // TODO determine this from inputDataSource
  const parentFieldId = internalRule.input_field.fieldId;

  const rootDataSource = getSchemaField(
    internalRule.input_field.fieldId,
    schema,
  );
  const isRootDataSourceCustomQuery = rootDataSource === null;

  const inputDataSchemaFieldIdSource = isRootDataSourceCustomQuery
    ? convertToExternalSchemaFieldDataSource(
        {
          fieldId: CUSTOM_QUERY_FIELD_ID,
          customQuery: "id",
        },
        schema,
      )
    : convertToExternalSchemaFieldDataSource(
        {
          fieldId: CUSTOM_QUERY_FIELD_ID,
          customQuery: rootDataSource.idAttribute,
        },
        schema,
      );

  const inputDataSource = convertToExternalDataSource(
    internalRule.input_field,
    schema,
  );

  const contentObject = {
    category: internalRule.category,
    criteria: convertToExternalCriteria(
      internalRule.criteria,
      schema,
      parentFieldId,
    ),
    from_business: convertToExternalDataSource(
      internalRule.from_business,
      schema,
      parentFieldId,
    ),
    fees: convertToExternalFees(internalRule.fees, schema, parentFieldId),
    id_field: inputDataSchemaFieldIdSource,
    input_field: inputDataSource.value,
    price_multiplier: internalRule.price_multiplier,
    include_if_zero: internalRule.include_if_zero || false,
    xero_branding_theme: internalRule.xero_branding_theme,
    billing_tags: internalRule.billing_tags,
    tax_type: internalRule.tax_type,
    to_business: convertToExternalDataSource(
      internalRule.to_business,
      schema,
      parentFieldId,
    ),
    value: {
      amount: convertToExternalDataSource(
        internalRule.value.amount,
        schema,
        parentFieldId,
      ),
      offset: convertToExternalDataSource(
        internalRule.value.offset,
        schema,
        parentFieldId,
      ),
      rounding_strategy: internalRule.value.rounding_strategy,
      rounding_value: internalRule.value.rounding_value,
      output_as_single_unit: internalRule.value.output_as_single_unit,
      units: convertToExternalDataSource(
        internalRule.value.units,
        schema,
        parentFieldId,
      ),
      unit_limit: convertToExternalDataSource(
        internalRule.value.unit_limit,
        schema,
        parentFieldId,
      ),
      unit_offset: convertToExternalDataSource(
        internalRule.value.unit_offset,
        schema,
        parentFieldId,
      ),
    },
  };

  const quantityOutputFormat =
    internalRule.quantity_output_format || RuleValueFormat.UNITS;
  const unitAmountOutputFormat =
    internalRule.unit_amount_output_format || RuleValueFormat.UNITS;

  const quantityRawFormat = getValueFormatByFieldType(
    contentObject.value.units.value_type,
  );
  const unitAmountRawFormat = getValueFormatByFieldType(
    contentObject.value.amount.value_type,
    RuleValueFormat.CENTS,
  );

  try {
    return {
      comment: internalRule.comment,
      content: JSON.stringify(contentObject),
      quantity_output_format: quantityOutputFormat,
      quantity_raw_format: quantityRawFormat,
      unit_amount_output_format: unitAmountOutputFormat,
      unit_amount_raw_format: unitAmountRawFormat,
      gl_code: internalRule.gl_code,
      id: internalRule.id || v4(),
      invoice_line_item_template: internalRule.invoice_line_item_template,
      master_rule_id: internalRule.master_rule_id,
      name: internalRule.name,
      order: internalRule.order,
      rule_book_id: internalRule.rule_book_id,
      title_template: internalRule.title_template,
    };
  } catch (e) {
    toast.error(`Error saving rule ${internalRule.name}`);
  }
}
