import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  bulkUpdateSaleLotsSerializer,
  patchBidderRegistrationOffline,
  receiveBidderRegistrations,
  requestBidderRegistrationsError,
} from "actions";

import { BIDDER_REGISTRATION } from "constants/actionTypes";

import { confirmDialogWithSubAction } from "lib/confirmDialog";
import { getSaleUrl } from "lib/navigation";
import { pluralize } from "lib/pluralize";

import { getBidderById, selectBidderIdBySaleLotIdLookup } from "selectors";

import { api } from "./api";

function* fetchBidderRegistrations(action) {
  try {
    const { saleyardName: saleyard_name, saleId: livestocksale_id } = action;
    const saleUrl = getSaleUrl({ saleyard_name, livestocksale_id });
    const url = `${saleUrl}/registered-bidders/`;
    const registrationsPromise = yield call(api.get, url);
    const registrations = yield registrationsPromise;
    yield put(receiveBidderRegistrations(registrations));
  } catch (e) {
    yield call(api.handleFetchError, e, "bidder registrations", action);
    yield put(requestBidderRegistrationsError(e.statusText));
  }
}

function* promptUpdateBidderSaleLots(bidder) {
  const state = yield select();
  const bidderIdBySaleLotIdLookup = selectBidderIdBySaleLotIdLookup(state);
  // Get all of the SaleLot Ids currently attributed to the bidder being updated
  const saleLotIds = Object.entries(bidderIdBySaleLotIdLookup)
    .filter(([_ignored, bidderId]) => bidder.id === bidderId)
    .map(([saleLotId]) => saleLotId);
  if (saleLotIds.length === 0) {
    return;
  }

  const existingBidder = getBidderById(bidder.id)(state);
  if (
    bidder.businessId === existingBidder.businessId &&
    bidder.buyerWay.toLowerCase() === existingBidder.buyerWay.toLowerCase()
  ) {
    return;
  }

  // Build the dialog title
  const dialogTitle = `Update existing Sale ${pluralize(
    "Lot",
    saleLotIds.length,
  )}?`;

  // Build the prompt message
  const dialogMessage = `Would you like to update the ${
    saleLotIds.length
  } Sale ${pluralize("Lot", saleLotIds.length)} already sold to Bidder ${
    bidder.registrationNumber
  }? Not updating the Sale ${pluralize(
    "Lot",
    saleLotIds.length,
  )} will mean the link to the purchasing Bidder will need to be updated manually.`;

  const saleLotsPatch = saleLotIds.map(id => ({
    id,
    buyer_id: bidder.businessId,
    buyer_way: bidder.buyerWay
      ? {
          name: bidder.buyerWay,
        }
      : null,
  }));

  // Prompt the user to update all of Sale Lots currently attributed to this Bidder Rego to match the new Buyer and BuyerWay Name
  yield confirmDialogWithSubAction(
    {
      title: dialogTitle,
      message: dialogMessage,
    },
    bulkUpdateSaleLotsSerializer(saleLotsPatch),
  );
}

function* onPatchBidderRegistration(action) {
  const { payload, options } = action;

  const { suppressUpdateSaleLotsPrompt = false } = options || {};

  if (!suppressUpdateSaleLotsPrompt) {
    yield promptUpdateBidderSaleLots(payload);
  }

  yield put(patchBidderRegistrationOffline(payload.id, payload, options));
}

function* rootSaga() {
  yield takeEvery(
    BIDDER_REGISTRATION.FETCH_BULK.REQUEST,
    fetchBidderRegistrations,
  );
  yield takeEvery(BIDDER_REGISTRATION.UPDATE.ACTION, onPatchBidderRegistration);
}

export default rootSaga;
