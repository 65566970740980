import queryString from "query-string";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "actions/lib";

import {
  SALE,
  UPDATE_SALE,
  UPDATE_SALE_FAILURE,
  UPDATE_SALE_SUCCESS,
} from "constants/actionTypes";

import { serializeSale } from "lib/serializers/sale";

const urlCreator = ({ changesSince, id, action, fetchAll = false } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/sales/", id, action),
    query: {
      changesSince,
      all: fetchAll ? true : undefined,
    },
  });

export const SaleAction = offlineActionCreator(
  SALE,
  serializeSale,
  urlCreator,
  false,
);

SaleAction.fetchAndOpen = saleId => {
  return {
    type: SALE.FETCH_AND_OPEN.REQUEST,
    saleId,
  };
};

export function updateSale(payload, livestockSaleId) {
  const url = `/v2/sales/${livestockSaleId}/`;

  const meta = {
    payload,
    livestockSaleId,
  };

  return {
    type: UPDATE_SALE,
    meta: {
      offline: {
        effect: {
          url,
          method: "PATCH",
          body: JSON.stringify(payload),
        },
        commit: {
          type: UPDATE_SALE_SUCCESS,
          meta,
        },
        rollback: {
          type: UPDATE_SALE_FAILURE,
          meta,
        },
      },
    },
  };
}
