import React, { useState } from "react";

import { get } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { FlexWrapper } from "components/AgGrid/TableWrapper";
import { SelectRecipients } from "components/BillingWorkFlow/Send/SelectRecipients";
import { Column, Row } from "components/Layout";

import { AgGridTables } from "constants/aggrid";
import {
  BillingDocumentColId,
  BillingDocumentColumnDef,
  BusinessColId,
  BusinessColumnDef,
} from "constants/columnDefinitions";

import { makeSelectable } from "lib/agGrid";
import { EmailDetailGridOptions } from "lib/agGrid/columnDefinitions/email";
import { getBounceCount, getErrorCount } from "lib/email";

import { selectBillingDocumentsAggridData } from "selectors";

const rowSelectionId = "billingDocument.id";
const getRowId = params => get(params.data, rowSelectionId);

const FlexTableContainer = styled.div(
  ({ theme }) => `
  position: relative;
  flex: 1;
  border-top: 2px solid ${theme.colors.gray44};
`,
);

const RightColumn = styled.div(
  ({ theme }) => `
  display: flex;
  position: relative;
  width: 50%;
  flex-direction: column;
  border-left: 2px solid ${theme.colors.gray44};
  overflow: scroll
`,
);

export const columnDefs = [
  makeSelectable(BillingDocumentColumnDef[BillingDocumentColId.DOCUMENT_TYPE]),
  BillingDocumentColumnDef[BillingDocumentColId.DOCUMENT_NUMBER],
  BusinessColumnDef[BusinessColId.NAME],
  BusinessColumnDef[BusinessColId.XERO_INTEGRATION_BUSINESS],
  BillingDocumentColumnDef[BillingDocumentColId.STATUS],
  BillingDocumentColumnDef[BillingDocumentColId.EMAIL_COUNT],
];

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: "agMultiColumnFilter",
  enableCellChangeFlash: true,
  floatingFilter: false,
  flex: true,
};

const LeftColumn = styled(Column)`
  resize: horizontal;
  overflow: auto;
  width: 50%;
`;

const RowClassRules = {
  "row-warning": props => {
    const { data } = props;
    return (
      getBounceCount(data?.emails || []) + getErrorCount(data?.emails || []) > 0
    );
  },
};

export const Send = () => {
  const [flagged, setFlagged] = useState([]);

  const rowData = useSelector(selectBillingDocumentsAggridData);

  const [renderKey, setRenderKey] = useState(1);

  const deselectAll = () => {
    // Use component key to force re-initialization of the aggrid component which will deselect all.
    setRenderKey(renderKey + 1);
    setFlagged([]);
  };

  const detailCellRendererParams = React.useMemo(() => {
    return {
      detailGridOptions: EmailDetailGridOptions,
      getDetailRowData: params => {
        params.successCallback(params.data.emails);
      },
    };
  }, []);

  return (
    <Row full>
      <LeftColumn>
        <FlexTableContainer>
          <AgGridTable
            columnDefs={columnDefs}
            getRowId={getRowId}
            rowSelectionId={rowSelectionId}
            rowData={rowData}
            showGlobalSearchFilter={false}
            hideHeader
            WrapperComponent={FlexWrapper}
            pagination={false}
            rowSelection="multiple"
            onRowSelectionChanged={setFlagged}
            masterDetail
            detailCellRendererParams={detailCellRendererParams}
            defaultColDef={defaultColDef}
            key={renderKey}
            tableName={AgGridTables.BILLING_DOCUMENTS_SEND}
            rowClassRules={RowClassRules}
            groupSelectsChildren
          />
        </FlexTableContainer>
      </LeftColumn>
      <RightColumn>
        <SelectRecipients billingDocuments={flagged} onClose={deselectAll} />
      </RightColumn>
    </Row>
  );
};
